/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import {
  DataWrapped,
  OrderParam,
  Paginated,
  FetchedContact as Contact,
  GeoLocation,
} from '@rtt-libs/types';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { filter } from 'lodash';
import { keyBy } from 'lodash/fp';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { Product } from './assortment';
import { Brand, FetchedBrandAPI, transformSimpleErrorMessage } from './mappers';
import { Rtt } from './rtt';

dayjs.extend(minMax);

class PrimitiveDiscountApi<T extends string | number = string | number> {
  target_id: T;
  discount: number;

  constructor(discount: PrimitiveDiscount<T>) {
    this.discount = discount.discount;
    this.target_id = discount.targetId;
  }
}

class PrimitiveDiscount<T extends string | number = string | number> {
  targetId: T;
  /** discount value in % */
  discount: number;

  constructor(discount: PrimitiveDiscountApi<T>) {
    this.discount = discount.discount;
    this.targetId = discount.target_id;
  }
}

type DiscountTargetType = 'category' | 'product' | 'brand';

type FetchedDiscount<
  T extends DiscountTargetType = DiscountTargetType
> = PrimitiveDiscountApi<string> & {
  id: number;
  discount_id: number;
  target_type: T;
  /** DateTime ISO */
  created_at: string;
  product: T extends 'product' ? Product : undefined;
  brand: T extends 'brand' ? FetchedBrandAPI : undefined;
};

export type DiscountProduct = Pick<Product, 'id' | 'sku' | 'title'>;

export type DiscountBrand = Brand;

export class Discount<
  T extends DiscountTargetType = DiscountTargetType
> extends PrimitiveDiscount {
  id: number;
  discountId: number;
  targetType: T;
  /** DateTime ISO */
  createdAt: string;
  product: T extends 'product' ? DiscountProduct : undefined;
  brand: T extends 'brand' ? DiscountBrand : undefined;

  constructor(discount: FetchedDiscount<T>) {
    super(discount);
    this.id = discount.id;
    this.discountId = discount.discount_id;
    this.targetType = discount.target_type;
    this.createdAt = discount.created_at;
    this.product = discount.product;

    this.brand = (discount.brand
      ? new Brand(discount.brand as FetchedBrandAPI)
      : undefined) as T extends 'brand' ? DiscountBrand : undefined;
  }
}

type FetchedRttDiscount = {
  id: number;
  rtt_id: Rtt['id'];
  discount: number;
  /** DateTime ISO */
  created_at: string;
  category_discounts: DataWrapped<FetchedDiscount<'category'>[]>;
  product_discounts: DataWrapped<FetchedDiscount<'product'>[]>;
  brand_discounts: DataWrapped<FetchedDiscount<'brand'>[]>;
  rtt?: Rtt;
};

export class RttDiscount implements Record<string, unknown> {
  id: number;
  rttId: Rtt['id'];
  discount: number;
  createdAt: string;
  categoryDiscounts: Discount<'category'>[];
  productDiscounts: Discount<'product'>[];
  brandDiscounts: Discount<'brand'>[];
  rttName: string;
  [key: string]: unknown;

  constructor(rttDiscount: FetchedRttDiscount) {
    this.id = rttDiscount.id;
    this.rttId = rttDiscount.rtt_id;
    this.discount = rttDiscount.discount;
    this.createdAt = rttDiscount.created_at;
    this.categoryDiscounts = rttDiscount.category_discounts.data.map(
      discount => new Discount(discount),
    );
    this.productDiscounts = rttDiscount.product_discounts.data.map(
      discount => new Discount(discount),
    );
    this.brandDiscounts = rttDiscount.brand_discounts.data.map(
      discount => new Discount(discount),
    );
    this.rttName = rttDiscount.rtt?.name ?? `РТТ - ${rttDiscount.id}`;
  }
}

type DiscountSearchParamsAPI = Partial<{
  rtt_id: Rtt['id'];
  sort: ('discount' | 'rtt_id') & string;
  order: OrderParam;
  per_page: number;
  page: number;
}>;

export type DiscountsSearchParams = Partial<{
  rttId: Rtt['id'];
  orderBy: ('discount' | 'rttId') & string;
  order: OrderParam;
  perPage: number;
  page: number;
}>;

type CreateDiscountValuesApi = {
  rtt_id: Rtt['id'];
  discount: number;
  category_discounts?: PrimitiveDiscountApi[];
  product_discounts?: PrimitiveDiscountApi[];
  brand_discounts?: PrimitiveDiscountApi[];
};

export type CreateDiscountValues = {
  rtt: DiscountRtt;
  discount: number;
  categoryDiscounts?: Record<number, number>;
  productDiscounts?: Record<string, number>;
  brandDiscounts?: Record<string, number>;
};

type FetchedDiscountRtt = {
  id: number;
  name: string;
  address: string;
  geo: GeoLocation;
  /** ISO DateTime string */
  created_at: string;
  contact: Contact;
  order_qty: number;
  /** ISO DateTime string */
  order_last_date: string;
  discount_id: Discount['id'];
};

export class DiscountRtt {
  id: number;
  name: string;
  discountId: Discount['id'] | null;

  constructor(rtt: FetchedDiscountRtt) {
    this.id = rtt.id;
    this.name = rtt.name;
    this.discountId = rtt.discount_id;
  }
}

/*
 * Discounts API handlers
 */

export const getDiscounts = (params?: DiscountsSearchParams) =>
  api
    .get<Paginated<FetchedRttDiscount>>(ENDPOINTS.discountsSearch, {
      params: params && mapSearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapDiscountArray))
    .catch(transformSimpleErrorMessage);

export const getDiscountDetails = (id: RttDiscount['id']) =>
  api
    .get<FetchedRttDiscount>(`${ENDPOINTS.discounts}/${id}`)
    .then(({ data }) => {
      const discount = new RttDiscount(data);
      return {
        data: discount,
        meta: {
          products: getProductsFromEditRttDiscount(discount),
          brands: getBrandsFromEditRttDiscount(discount),
        },
      };
    })
    .catch(transformSimpleErrorMessage);

export const getAllDiscounts = () =>
  api
    .get<DataWrapped<FetchedRttDiscount[]>>(ENDPOINTS.discountsSearch)
    .then(({ data }) => mapDiscountArray(data.data))
    .catch(transformSimpleErrorMessage);

export const createDiscount = (values: CreateDiscountValues) =>
  api
    .post<FetchedRttDiscount>(
      ENDPOINTS.discounts,
      mapCreateDiscountValues(values),
    )
    .then(({ data }) => new RttDiscount(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateDiscountErrors);
    });

export const editDiscount = (
  id: RttDiscount['id'],
  values: CreateDiscountValues,
) =>
  api
    .put<FetchedRttDiscount>(
      `${ENDPOINTS.discounts}/${id}`,
      mapCreateDiscountValues(values),
    )
    .then(({ data }) => new RttDiscount(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateDiscountErrors);
    });

export const deleteDiscount = (id: RttDiscount['id']) =>
  api.delete(`${ENDPOINTS.discounts}/${id}`).catch(transformSimpleErrorMessage);

export const getDiscountRttList = (search?: string) =>
  api
    .get<DataWrapped<FetchedDiscountRtt[]>>(ENDPOINTS.discountsRttList, {
      params: { search },
    })
    .then(({ data }) => mapDiscountRttList(data.data));
/*
 * Discount Mappers
 */

function mapSearchParams(
  params: DiscountsSearchParams,
): DiscountSearchParamsAPI {
  return {
    rtt_id: params.rttId,
    order: params.order,
    sort: params.orderBy === 'rttId' ? 'rtt_id' : 'discount',
    page: params.page,
    per_page: params.perPage,
  };
}

function mapDiscountArray(discounts: FetchedRttDiscount[]) {
  try {
    return discounts.map(discount => new RttDiscount(discount));
  } catch (e) {
    throw new Error('Order mapping error');
  }
}

function mapCreateDiscountValues({
  rtt,
  discount,
  categoryDiscounts,
  productDiscounts,
  brandDiscounts,
}: CreateDiscountValues): CreateDiscountValuesApi {
  return {
    discount,
    rtt_id: rtt?.id,
    category_discounts: mapDiscounts(categoryDiscounts),
    product_discounts: mapDiscounts(productDiscounts),
    brand_discounts: mapDiscounts(brandDiscounts),
  };
}

const transformCreateDiscountErrors: TransformAPIError<
  Record<keyof CreateDiscountValuesApi, string | string[]>,
  Record<keyof CreateDiscountValues, string>
> = function transformCreateDiscountErrors(e) {
  return {
    rtt: e.rtt_id?.toString(),
    discount: e.discount?.toString(),
    categoryDiscounts: e.category_discounts?.toString(),
    productDiscounts: e.product_discounts?.toString(),
  };
};

function mapDiscounts<T extends string | number = string | number>(
  discounts?: Record<T, number>,
): PrimitiveDiscountApi<T>[] | undefined {
  if (!discounts) return undefined;

  return filter(
    Object.entries<number>(discounts).map(([targetId, discount]) =>
      !Number.isNaN(Number(discount))
        ? new PrimitiveDiscountApi({
            targetId,
            discount,
          })
        : undefined,
    ),
  ) as PrimitiveDiscountApi<T>[];
}

function getProductsFromEditRttDiscount(discount: RttDiscount) {
  return keyBy(
    'id',
    // Take only required fields
    discount.productDiscounts.map(({ product: { id, sku, title } }) => ({
      id,
      sku,
      title,
    })),
  );
}

function getBrandsFromEditRttDiscount(discount: RttDiscount) {
  return keyBy(
    'id',
    discount.brandDiscounts.map(({ brand: { id, isActive, title } }) => ({
      id,
      isActive,
      title,
    })),
  );
}

function mapDiscountRttList(rttList: FetchedDiscountRtt[]) {
  return rttList.map(rtt => new DiscountRtt(rtt));
}
