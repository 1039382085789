import { merge } from 'lodash';
import { keyBy } from 'lodash/fp';
import type { Reducer } from 'redux';
import type { Rtt, SearchList } from '../../api/rtt';
import type { RttAreas } from '../../api/rttAreas';
import type { Actions as PriceTypeActions } from '../../priceTypes/duck/actions';
import { SET_RTT_PRICE_TYPES_SUCCESS } from '../../priceTypes/duck/types';
import type { Actions } from './actions';
import * as types from './types';
import type { BrandManagerConnection } from '../../types';

const initialState = {
  loading: false,
  collection: {} as Record<Rtt['id'], Rtt>,
  currentRtt: null as Rtt | null,
  areas: {} as Record<RttAreas['id'], RttAreas>,
  byPhoneName: {} as SearchList,
  searchValue: '' as string,
  listIdsAreas: [] as RttAreas['id'][],
  listIds: [] as Rtt['id'][],
  error: null as string | null,
  managerList: [] as BrandManagerConnection[],
  managerListLoading: false,
  managerListError: null as null | string,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions | PriceTypeActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_RTT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentRtt: action.payload,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    }
    case types.GET_RTT_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        currentRtt: null,
      };
    }
    case types.GET_RTT_SUCCESS:
      return {
        ...state,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        byPhoneName: action.payload.reduce(
          (byPhoneName, searchField) => ({
            ...byPhoneName,
            [searchField.name
              .toLowerCase()
              .concat('&&&', searchField.contact.phone)]: searchField.id,
          }),
          {},
        ),
        listIds: action.payload.map(rtt => rtt.id),
        loading: false,
      };
    case types.GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: { ...state.areas, ...keyBy('id', action.payload) },
        listIdsAreas: action.payload.map(area => area.id),
      };
    case types.GET_AREAS_REQUEST:
    case types.GET_RTT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_RTT_DETAILS_FAILURE:
    case types.GET_RTT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SET_RTT_SEARCH_SUCCESS:
      return {
        ...state,
        searchValue: action.payload,
      };
    case types.RTT_CHANGE_BALANCE_STATE_SUCCESS:
      return {
        ...state,
        currentRtt: state.currentRtt && {
          ...state.currentRtt,
          isBalanceEnabled: action.payload.isBalanceEnabled,
        },
        collection: {
          ...state.collection,
          [action.payload.id]: {
            ...state.collection[action.payload.id],
            isBalanceEnabled: action.payload.isBalanceEnabled,
          },
        },
      };

    case SET_RTT_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        collection: merge({}, state.collection, action.payload),
        currentRtt:
          state.currentRtt?.id !== parseInt(Object.keys(action.payload)[0], 10)
            ? state.currentRtt
            : merge({}, state.currentRtt, action.payload[state.currentRtt.id]),
      };

    case types.RTT_MANAGER_LIST_GET_REQUEST:
    case types.RTT_MANAGER_LIST_ALL_GET_REQUEST:
      return {
        ...state,
        managerListLoading: true,
        managerListError: null,
      };
    case types.RTT_MANAGER_LIST_GET_SUCCESS:
    case types.RTT_MANAGER_LIST_ALL_GET_SUCCESS:
      return {
        ...state,
        managerListLoading: false,
        managerList: action.payload.managerList,
      };
    case types.RTT_MANAGER_LIST_GET_FAILURE:
    case types.RTT_MANAGER_LIST_ALL_GET_FAILURE:
      return {
        ...state,
        managerListLoading: false,
        managerListError: action.payload,
      };

    case types.RTT_MANAGER_LIST_CHANGE_REQUEST:
      return {
        ...state,
        managerListError: null,
      };
    case types.RTT_MANAGER_LIST_CHANGE_SUCCESS:
      return {
        ...state,
        managerList: action.payload.managerList,
      };
    case types.RTT_MANAGER_LIST_CHANGE_FAILURE:
      return {
        ...state,
        managerListError: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
