import DayjsUtils from '@date-io/dayjs';
import { Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuspenseIndicator } from '../../../common';
import { getAllManagersOfRttRequest } from '../../../rtt/duck/actions';
import {
  getClosestDeliveryDayRequest,
  getOrderDetailsRequest,
} from '../../duck/actions';
import { selectOrderDetailsById } from '../../duck/selectors';
import OrderDetails from './OrderDetails';

interface Props {
  id: string;
}

const OrderDetailsContainer: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(selectOrderDetailsById(id));

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  const rttId = data?.rttId;

  useEffect(() => {
    if (!rttId) {
      dispatch(getOrderDetailsRequest(id));
    } else {
      dispatch(getClosestDeliveryDayRequest(rttId));
      dispatch(getAllManagersOfRttRequest(rttId));
    }
  }, [dispatch, id, rttId]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <Paper>
        {loading || !data ? (
          <SuspenseIndicator />
        ) : (
          <OrderDetails order={data} />
        )}
      </Paper>
    </MuiPickersUtilsProvider>
  );
};

export default OrderDetailsContainer;
