import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import {
  FieldWithMessages as Field,
  LoadingButton,
  TextFieldAdapter,
} from '@rtt-libs/views';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CreatePriceTypeValues } from '../../../api/priceTypes';

type Props = FormRenderProps<CreatePriceTypeValues> & { onClose(): void };

const CreatePriceTypesForm: React.FC<Props> = ({
  handleSubmit,
  submitError,
  onClose,
  submitting,
}) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="add-moderator-dialog-title">
        {t('distributor.priceTypes.add.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('distributor.priceTypes.add.dialogDescription')}
        </DialogContentText>
        <Field
          autoFocus
          margin="dense"
          name="title"
          type="text"
          fullWidth
          required
          component={TextFieldAdapter}
          label={t('distributor.priceTypes.add.name')}
          placeholder={t('distributor.priceTypes.add.name')}
        />
        <Field
          component={TextFieldAdapter}
          name="coefficient"
          margin="dense"
          type="number"
          inputProps={{
            step: 0.5,
            min: 0,
          }}
          fullWidth
          required
          label={t('distributor.priceTypes.add.percent')}
        />
        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('controls.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={submitting}
        >
          {t('controls.add')}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default CreatePriceTypesForm;
