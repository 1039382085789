import { Alert, AlertTitle } from '@material-ui/lab';
import {
  ColumnConfig,
  PropsForFilterRow,
  TableWithHistory,
} from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MULTI_EXPORT_INITIAL_CHECKED } from '../ExportTypesRow/ExportCheckboxList';

type Props = {
  entries: Record<string, unknown>[];
  loading: boolean;
  columnConfig: ColumnConfig[];
  onStateChange: (state: {}) => void;
  filterRow?(props: PropsForFilterRow): React.ReactElement;
};

const ExportDocumentsTable: React.FC<Props> = ({
  entries,
  columnConfig,
  loading = false,
  onStateChange,
  filterRow,
}) => {
  const [t] = useTranslation();

  const initial = useMemo(
    () =>
      ({
        type: MULTI_EXPORT_INITIAL_CHECKED,
        // TODO: fix table types
      } as { search?: string }),
    [],
  );

  return (
    <TableWithHistory
      filterRow={filterRow}
      loading={loading}
      entries={entries}
      columnsConfig={columnConfig}
      onStateChange={onStateChange}
      withoutPagination
      initial={initial}
      emptyMessage={
        <Alert role="alert" severity="info">
          <AlertTitle>{t('common.emptyTable')}</AlertTitle>
          {t('common.emptyTableDescription')}
        </Alert>
      }
    />
  );
};

export default ExportDocumentsTable;
