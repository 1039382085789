export const BRANDS_GET_REQUEST = `@rtt[D]/BRANDS_GET_REQUEST` as const;
export const BRANDS_GET_SUCCESS = `@rtt[D]/BRANDS_GET_SUCCESS` as const;
export const BRANDS_GET_FAILURE = `@rtt[D]/BRANDS_GET_FAILURE` as const;

export const BRANDS_GET_OPTIONS_REQUEST = `@rtt[D]/BRANDS_GET_OPTIONS_REQUEST` as const;
export const BRANDS_GET_OPTIONS_SUCCESS = `@rtt[D]/BRANDS_GET_OPTIONS_SUCCESS` as const;
export const BRANDS_GET_OPTIONS_FAILURE = `@rtt[D]/BRANDS_GET_OPTIONS_FAILURE` as const;

export const BRANDS_CREATE_REQUEST = `@rtt[D]/BRANDS_CREATE_REQUEST` as const;
export const BRANDS_CREATE_SUCCESS = `@rtt[D]/BRANDS_CREATE_SUCCESS` as const;
export const BRANDS_CREATE_FAILURE = `@rtt[D]/BRANDS_CREATE_FAILURE` as const;

export const BRANDS_EDIT_REQUEST = `@rtt[D]/BRANDS_EDIT_REQUEST` as const;
export const BRANDS_EDIT_SUCCESS = `@rtt[D]/BRANDS_EDIT_SUCCESS` as const;
export const BRANDS_EDIT_FAILURE = `@rtt[D]/BRANDS_EDIT_FAILURE` as const;

export const BRANDS_CHANGE_STATUS_REQUEST = `@rtt[D]/BRANDS_CHANGE_STATUS_REQUEST` as const;
export const BRANDS_CHANGE_STATUS_SUCCESS = `@rtt[D]/BRANDS_CHANGE_STATUS_SUCCESS` as const;
export const BRANDS_CHANGE_STATUS_FAILURE = `@rtt[D]/BRANDS_CHANGE_STATUS_FAILURE` as const;

export const BRANDS_CLEAN_UP = `@rtt[D]/BRANDS_CLEAN_UP` as const;
