import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { Pagination } from '@rtt-libs/types';
import { createSelector } from 'reselect';
import { PriceType } from '../../api/priceTypes';
import { branchName } from './const';
import { BranchState } from './reducer';

export type PartialRootState = Record<typeof branchName, BranchState>;

const mapToOptions = ({ id, title }: PriceType) => ({
  value: id,
  label: title,
});

export const getPriceTypesCollectionSel: Selector<
  PartialRootState,
  Record<PriceType['id'], PriceType>
> = get([branchName, 'collection']);

export const getAllPriceTypesSel = createSelector(
  getPriceTypesCollectionSel,
  collection => Object.values(collection),
);

export const getPriceTypesLoadingStateSel: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'loading']);

export const getPriceTypesErrorSel: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

export const getPriceTypesOptionsSel: Selector<
  PartialRootState,
  { value: number; label: string }[]
> = createSelector([getAllPriceTypesSel], priceTypes =>
  priceTypes.map(mapToOptions),
);

export const getPriceTypesSearchCollectionSel: Selector<
  PartialRootState,
  Record<PriceType['id'], PriceType>
> = get([branchName, 'searchCollection']);

const getPriceTypesIdsSel: Selector<PartialRootState, PriceType['id'][]> = get([
  branchName,
  'listIds',
]);

const selectPriceTypesPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectPriceTypes: Selector<
  PartialRootState,
  {
    data: PriceType[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    getPriceTypesSearchCollectionSel,
    getPriceTypesIdsSel,
    selectPriceTypesPagination,
    getPriceTypesLoadingStateSel,
    getPriceTypesErrorSel,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);
