import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import React from 'react';
import { EditStaffActions, staffTableConfig } from '../../common/staff';
import { types } from '../duck';
import type { Supervisor } from '../types';
import { AssignedManagersCell } from './Cells';
import EditSupervisorContainer from './EditSupervisorContainer';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const supervisorsTableConfig = (t: TFunction): ColumnConfig[] => [
  ...staffTableConfig(t),

  {
    id: 'managerList',
    label: t('distributor.tables.employees.managerList.label'),
    options: {
      align: 'center',
      sort: false,
      component: AssignedManagersCell,
    },
  },
  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: Supervisor) => entry,
      component: ({ id, value }) => (
        <EditStaffActions
          id={String(id)}
          value={value as Supervisor}
          statusActionTypes={{
            start: types.SUPERVISORS_CHANGE_STATUS_REQUEST,
            resolve: types.SUPERVISORS_CHANGE_STATUS_SUCCESS,
            reject: types.SUPERVISORS_CHANGE_STATUS_FAILURE,
          }}
          container={EditSupervisorContainer}
        />
      ),
      sort: false,
    },
  },
];

export default supervisorsTableConfig;
