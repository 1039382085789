export const GET_DRIVERS_REQUEST = '@rtt[D]/GET_DRIVERS_REQUEST' as const;
export const GET_DRIVERS_SUCCESS = '@rtt[D]/GET_DRIVERS_SUCCESS' as const;
export const GET_DRIVERS_FAILURE = '@rtt[D]/GET_DRIVERS_FAILURE' as const;

export const GET_MANAGERS_REQUEST = '@rtt[D]/GET_MANAGERS_REQUEST' as const;
export const GET_MANAGERS_SUCCESS = '@rtt[D]/GET_MANAGERS_SUCCESS' as const;
export const GET_MANAGERS_FAILURE = '@rtt[D]/GET_MANAGERS_FAILURE' as const;

export const EMPLOYEES_SEARCH_REQUEST = '@rtt[D]/EMPLOYEES_SEARCH_REQUEST' as const;
export const EMPLOYEES_SEARCH_SUCCESS = '@rtt[D]/EMPLOYEES_SEARCH_SUCCESS' as const;
export const EMPLOYEES_SEARCH_FAILURE = '@rtt[D]/EMPLOYEES_SEARCH_FAILURE' as const;

export const EMPLOYEE_CREATE_REQUEST = '@rtt[D]/EMPLOYEE_CREATE_REQUEST' as const;
export const EMPLOYEE_CREATE_SUCCESS = '@rtt[D]/EMPLOYEE_CREATE_SUCCESS' as const;
export const EMPLOYEE_CREATE_FAILURE = '@rtt[D]/EMPLOYEE_CREATE_FAILURE' as const;

export const EMPLOYEE_EDIT_REQUEST = '@rtt[D]/EMPLOYEE_EDIT_REQUEST' as const;
export const EMPLOYEE_EDIT_SUCCESS = '@rtt[D]/EMPLOYEE_EDIT_SUCCESS' as const;
export const EMPLOYEE_EDIT_FAILURE = '@rtt[D]/EMPLOYEE_EDIT_FAILURE' as const;

export const EMPLOYEE_CHANGE_STATUS_REQUEST = '@rtt[D]/EMPLOYEE_CHANGE_STATUS_REQUEST' as const;
export const EMPLOYEE_CHANGE_STATUS_SUCCESS = '@rtt[D]/EMPLOYEE_CHANGE_STATUS_SUCCESS' as const;
export const EMPLOYEE_CHANGE_STATUS_FAILURE = '@rtt[D]/EMPLOYEE_CHANGE_STATUS_FAILURE' as const;
