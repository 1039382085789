import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ARIA_MODAL_LABEL = 'add products to list';

type Props = {
  /**
   * Wrap main content in DialogContent MUI component.
   * Use another Dialog family components.
   * `closeModal` fn used to close the modal - assign it to some close button.
   */
  renderModalContent: (closeModal: () => void) => React.ReactNode;
  disabled?: boolean;
};

const ToggleProductAddModal: React.FC<Props> = ({
  disabled,
  renderModalContent,
}) => {
  const [t] = useTranslation();

  const [opened, changeModalState] = useState(false);

  const closeModal = useCallback(() => changeModalState(false), [
    changeModalState,
  ]);

  return (
    <>
      <Box clone width={160}>
        <Button
          disabled={disabled || opened}
          onClick={() => {
            changeModalState(true);
          }}
          startIcon={<AddIcon />}
        >
          {t('controls.add')}
        </Button>
      </Box>

      <Dialog
        open={opened}
        onClose={closeModal}
        aria-labelledby={ARIA_MODAL_LABEL}
        maxWidth="md"
      >
        <DialogTitle id={ARIA_MODAL_LABEL}>
          {t('distributor.orders.details.addProductTitle')}
        </DialogTitle>

        {renderModalContent(closeModal)}
      </Dialog>
    </>
  );
};

export default ToggleProductAddModal;

type ProductAddModalActionButtonsProps = {
  closeModal: () => void;
  submit?: () => unknown;
};
export const ProductAddModalActionButtons = ({
  closeModal,
  submit,
}: ProductAddModalActionButtonsProps) => {
  const [t] = useTranslation();

  return (
    <DialogActions>
      <Button onClick={closeModal}>{t('controls.cancel')}</Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={submit}
      >
        {t('controls.add')}
      </Button>
    </DialogActions>
  );
};
