import type { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import { keyBy } from 'lodash/fp';
import type { Reducer } from 'redux';
import type { Actions as AgreedAssortmentActions } from '../../agreedAssortments/duck/actions';
import { AGREED_ASSORTMENT_GET_DETAILS_SUCCESS } from '../../agreedAssortments/duck/types';
import type { AgreedAssortmentItem } from '../../agreedAssortments/types';
import type { EnhancedProduct } from '../../api/orders';
import type { Actions as BalanceActions } from '../../balances/duck/actions';
import { BALANCE_CREATE_SUCCESS } from '../../balances/duck/types';
import type { ProductInfo, ReturnProductInfo } from '../types';
import type { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  error: null as string | null,
  returnCollection: {} as Record<string, ReturnProductInfo>,
  returnIdList: [] as string[],
  collection: {} as
    | Record<string, ProductInfo>
    | Record<string, AgreedAssortmentItem['product']>
    | Record<string, EnhancedProduct>,
  idList: [] as string[],
  pagination: {} as Pagination,
};

export type BranchState = typeof initialState;

const productsReducer: Reducer<
  BranchState,
  Actions | AgreedAssortmentActions | BalanceActions
> = (state = initialState, action) => {
  switch (action.type) {
    case types.PRODUCTS_AS_RTT_SEARCH_REQUEST:
    case types.PRODUCTS_PRICELESS_SEARCH_REQUEST:
    case types.PRODUCTS_RETURN_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.PRODUCTS_AS_RTT_SEARCH_SUCCESS:
    case types.PRODUCTS_PRICELESS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...(state.collection as Record<string, ProductInfo>),
          ...keyBy('id', action.payload),
        },
        idList: action.payload.map(item => item.id),
        pagination: action.meta.pagination,
      };
    case types.PRODUCTS_RETURN_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        returnCollection: {
          ...state.returnCollection,
          ...keyBy('hash', action.payload),
        },
        returnIdList: action.payload.map(item => item.hash),
        pagination: action.meta.pagination,
      };
    case types.PRODUCTS_AS_RTT_SEARCH_FAILURE:
    case types.PRODUCTS_PRICELESS_SEARCH_FAILURE:
    case types.PRODUCTS_RETURN_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload[FORM_ERROR] ?? null,
      };

    case AGREED_ASSORTMENT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        collection: {
          ...(state.collection as Record<
            string,
            AgreedAssortmentItem['product']
          >),
          ...keyBy(
            'id',
            action.payload.items.map(item => item.product),
          ),
        },
      };
    case BALANCE_CREATE_SUCCESS:
      return {
        ...state,
        collection: {
          ...(state.collection as Record<string, EnhancedProduct>),
          ...keyBy('id', action.payload.products),
        },
      };

    case types.PRODUCTS_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default productsReducer;
