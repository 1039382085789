import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import {
  getSettingsExport,
  editSettingsExport,
} from '../../api/settingsExport';
import {
  getSettingsOrders,
  editSettingsOrders,
} from '../../api/settingsOrders';

function* getSettingsExportWorker() {
  try {
    const data = yield call(getSettingsExport);

    yield all([put(actions.getSettingsExportSuccess(data))]);
  } catch (e) {
    yield put(actions.getSettingsExportFailure(e.message));
  }
}

function* getSettingsOrdersWorker() {
  try {
    const data = yield call(getSettingsOrders);

    yield all([put(actions.getSettingsOrdersSuccess(data))]);
  } catch (e) {
    yield put(actions.getSettingsOrdersFailure(e.message));
  }
}

function* editSettingsWorker({
  payload,
}: ReturnType<typeof actions.editSettingsExportRequest>) {
  try {
    const data = yield call(editSettingsExport, payload);

    yield all([
      put(actions.editSettingsExportSuccess(data)),
      put(actions.editSettingsExportResponse()),
    ]);
  } catch (e) {
    yield put(actions.editSettingsExportResponse(e));
  }
}

function* editSettingsOrdersWorker({
  payload,
}: ReturnType<typeof actions.editSettingsOrdersRequest>) {
  try {
    const data = yield call(editSettingsOrders, payload);

    yield all([
      put(actions.editSettingsOrdersSuccess(data)),
      put(actions.editSettingsOrdersResponse()),
    ]);
  } catch (e) {
    yield put(actions.editSettingsOrdersResponse(e));
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_SETTINGS_EXPORT_REQUEST, getSettingsExportWorker);
  yield takeLatest(types.GET_SETTINGS_ORDERS_REQUEST, getSettingsOrdersWorker);
  yield takeLatest(types.EDIT_SETTINGS_EXPORT_REQUEST, editSettingsWorker);
  yield takeLatest(
    types.EDIT_SETTINGS_ORDERS_REQUEST,
    editSettingsOrdersWorker,
  );
}
