import { Box, Grid, TextField } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { FilterMenu } from '@rtt-libs/views';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { BrandSearchParams } from '../types';
import CreateBrandContainer from './CreateBrandContainer';
import CreateButton from './CreateButton';

type Props = {
  onChange(params: Partial<BrandSearchParams>): void;
};

const initialFilterValues = { isActive: '' };

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = React.memo(({ onChange }) => {
  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ title: e.target.value?.trim() || undefined });
  };

  const onFilterChange = (values: Record<string, string>) => {
    onChange({
      isActive: values.isActive === '' ? undefined : !!+values.isActive,
    });
  };

  const filters = [
    {
      name: 'isActive',
      title: t('distributor.tables.employees.isActive.label'),
      options: [
        {
          value: '',
          label: t('common.resetOption'),
        },
        {
          value: '1',
          label: t('distributor.tables.employees.isActive.active'),
        },
        {
          value: '0',
          label: t('distributor.tables.employees.isActive.inactive'),
        },
      ],
    },
  ];

  return (
    <Box marginBottom={2}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <FilterMenu
            filters={filters}
            onChange={onFilterChange}
            initialValues={initialFilterValues}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="title"
            label={t('common.search')}
            onChange={onSearchChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={2} />

        <Grid item xs={3}>
          <CreateButton container={CreateBrandContainer} />
        </Grid>
      </Grid>
    </Box>
  );
});

export default FilterRow;
