const routes = {
  balances: '/balances',
  balanceCreate: '/balances/create',
  brands: '/brands',
  categories: '/categories',
  chats: '/chats',
  support: '/support',
  massMail: '/mass-mail',
  rtt: '/rtt',
  profile: '/profile',
  settings: '/settings',
  priceTypes: '/price-types',
  staff: {
    common: '/staff',
    administrators: '/staff/administrators',
    supervisors: '/staff/supervisors',
    employees: '/staff/employees',
  },
  supervisorManagers: '/managers',
  importExport: {
    common: '/import-export',
    orders: '/import-export/orders',
    returns: '/import-export/returns',
    balances: '/import-export/balances',
  },
  uploadAssortment: '/import-export/upload-assortment',
  territories: '/territories',
  finishRegistration: '/finish_registration',
  home: '/',
  orders: '/orders',
  orderNew: '/orders/new',
  returns: '/returns',
  returnsNew: '/returns/new',
  orderFilters: {
    toPerform: 'to-perform',
    history: 'history',
  },
  products: '/assortment',
  productNew: '/assortment/new',
  productFilters: {
    inactive: 'inactive',
    invalid: 'invalid',
  },
  sales: '/sales',
  salesNew: '/sales/new',
  discounts: '/discounts',
  discountsNew: '/discounts/new',
  agreedAssortmentCreate: '/agreed_assortments/create',
  agreedAssortments: '/agreed_assortments',
} as const;

export default routes;
