import { ColumnConfig, Status } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import type { Staff } from '../types';
import { StaffName, StaffContacts, StaffPassword } from './Cells';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const staffTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'lastName',
    label: t('distributor.tables.staff.name', 'Name'),
    options: {
      format: (_: unknown, entry: Staff) =>
        `${entry.lastName} ${entry.firstName}`,
      component: StaffName,
    },
  },
  {
    id: 'contacts',
    label: t('distributor.tables.staff.contacts', 'Contacts'),
    options: {
      format: (_: unknown, entry: Staff) => entry,
      sort: false,
      component: StaffContacts,
    },
  },
  {
    id: 'login',
    label: t('distributor.tables.staff.login', 'login'),
  },
  {
    id: 'password',
    label: t('distributor.tables.staff.password', 'password'),
    options: {
      sort: false,
      component: StaffPassword,
    },
  },
  {
    id: 'isActive',
    label: t('distributor.tables.staff.isActive.label', 'isActive'),
    options: {
      align: 'center',
      format: value => {
        const status = value ? 'active' : 'deactivated';
        return { value: status, title: t(`status.${status}`) };
      },
      component: Status,
    },
  },
];

export default staffTableConfig;
