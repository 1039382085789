import { Chip, createStyles, makeStyles } from '@material-ui/core';
import { OverlayView } from '@react-google-maps/api';
import React from 'react';

type Props = {
  position: google.maps.LatLngLiteral | google.maps.LatLng;
};

const useStyles = makeStyles(() =>
  createStyles({
    areaLabel: {
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translate(-50%, -50%)',
    },
  }),
);

// TODO: duplicates `apps/distributor/src/territories/view/AreaLabel.tsx`
const AreaLabel: React.FC<Props> = ({ children, position }) => {
  const classes = useStyles();

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Chip className={classes.areaLabel} label={children} variant="outlined" />
    </OverlayView>
  );
};

export default AreaLabel;
