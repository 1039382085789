export const GET_RTT_REQUEST = `@rtt[D]/GET_RTT_REQUEST` as const;
export const GET_RTT_SUCCESS = `@rtt[D]/GET_RTT_SUCCESS` as const;
export const GET_RTT_FAILURE = `@rtt[D]/GET_RTT_FAILURE` as const;

export const GET_RTT_DETAILS_REQUEST = `@rtt[D]/GET_RTT_DETAILS_REQUEST` as const;
export const GET_RTT_DETAILS_SUCCESS = `@rtt[D]/GET_RTT_DETAILS_SUCCESS` as const;
export const GET_RTT_DETAILS_FAILURE = `@rtt[D]/GET_RTT_DETAILS_FAILURE` as const;

export const SET_RTT_SEARCH_SUCCESS = `@rtt[D]/SET_RTT_SEARCH_SUCCESS` as const;

export const GET_AREAS_REQUEST = `@rtt[D]/GET_AREAS_REQUEST` as const;
export const GET_AREAS_SUCCESS = `@rtt[D]/GET_AREAS_SUCCESS` as const;
export const GET_AREAS_FAILURE = `@rtt[D]/GET_AREAS_FAILURE` as const;

export const RTT_CHANGE_BALANCE_STATE_REQUEST = `@rtt[D]/RTT_CHANGE_BALANCE_STATE_REQUEST` as const;
export const RTT_CHANGE_BALANCE_STATE_SUCCESS = `@rtt[D]/RTT_CHANGE_BALANCE_STATE_SUCCESS` as const;
export const RTT_CHANGE_BALANCE_STATE_FAILURE = `@rtt[D]/RTT_CHANGE_BALANCE_STATE_FAILURE` as const;

export const RTT_MANAGER_LIST_GET_REQUEST = `@rtt[D]/RTT_MANAGER_LIST_GET_REQUEST` as const;
export const RTT_MANAGER_LIST_GET_SUCCESS = `@rtt[D]/RTT_MANAGER_LIST_GET_SUCCESS` as const;
export const RTT_MANAGER_LIST_GET_FAILURE = `@rtt[D]/RTT_MANAGER_LIST_GET_FAILURE` as const;

export const RTT_MANAGER_LIST_ALL_GET_REQUEST = `@rtt[D]/RTT_MANAGER_LIST_ALL_GET_REQUEST` as const;
export const RTT_MANAGER_LIST_ALL_GET_SUCCESS = `@rtt[D]/RTT_MANAGER_LIST_ALL_GET_SUCCESS` as const;
export const RTT_MANAGER_LIST_ALL_GET_FAILURE = `@rtt[D]/RTT_MANAGER_LIST_ALL_GET_FAILURE` as const;

export const RTT_MANAGER_LIST_CHANGE_REQUEST = `@rtt[D]/RTT_MANAGER_LIST_CHANGE_REQUEST` as const;
export const RTT_MANAGER_LIST_CHANGE_SUCCESS = `@rtt[D]/RTT_MANAGER_LIST_CHANGE_SUCCESS` as const;
export const RTT_MANAGER_LIST_CHANGE_FAILURE = `@rtt[D]/RTT_MANAGER_LIST_CHANGE_FAILURE` as const;
