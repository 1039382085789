import {
  createStyles,
  Fade,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

type Props = {
  loading?: boolean;
};

const useStyles = makeStyles(
  createStyles({
    loader: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1200,
    },
  }),
);

const TopLoadingProgress: React.FC<Props> = ({ loading }) => {
  const classes = useStyles();

  return (
    <Fade in={loading}>
      <LinearProgress className={classes.loader} />
    </Fade>
  );
};

export default TopLoadingProgress;
