import {
  CategoriesItemDictionary,
  CategoryItemId,
  CategoryItem,
} from '@rtt-libs/types';
import { Reducer } from 'redux';
import { SearchList } from '../../api/categories';
import { Actions } from './actions';
import { mainReducerKey } from './const';
import * as TYPES from './types';

const initialState = {
  loading: false,
  [mainReducerKey]: {} as CategoriesItemDictionary,
  availableCategories: {} as CategoriesItemDictionary,
  allCategories: {} as CategoriesItemDictionary,
  byNameId: {} as SearchList,
  searchValue: '' as string,
  /* keys is child category id, values - its parent */
  parentCategories: {} as Record<CategoryItemId, CategoryItemId>,
  error: null as string | null,
};

const parentCategoriesReducer = (
  acc: Record<CategoryItemId, CategoryItemId>,
  { id: parentId = 'root', children: childrenIds }: CategoryItem,
): Record<CategoryItemId, CategoryItemId> => {
  let parentCategoriesSlice: Record<CategoryItemId, CategoryItemId> = {};

  childrenIds.forEach(id => {
    parentCategoriesSlice = {
      ...parentCategoriesSlice,
      [id]: parentId,
    };
  });
  return { ...acc, ...parentCategoriesSlice };
};

export type State = typeof initialState;

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CATEGORIES_REQUEST:
    case TYPES.CATEGORIES_AVAILABLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: action.payload,
      };
    case TYPES.SET_CATEGORIES_SEARCH_SUCCESS:
      return {
        ...state,
        searchValue: action.payload,
      };
    case TYPES.ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: action.payload,
      };
    case TYPES.CATEGORIES_AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        availableCategories: action.payload,
        parentCategories: Object.values(action.payload).reduce(
          parentCategoriesReducer,
          {},
        ),
        byNameId: Object.values(action.payload)
          .filter(category => category.data)
          .reduce(
            (byNameId, searchField) => ({
              ...byNameId,
              [searchField.data.title
                .toLowerCase()
                .concat(`&&&${searchField.id}`)]: searchField.id,
            }),
            {},
          ),
      };
    case TYPES.CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
