import { LinearProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import { getBalanceSettingsRequest } from '../duck/actions';
import { selectBalanceSettingsData } from '../duck/selectors';
import EditBalanceSettingsForm from './EditBalanceSettingsForm';

const BalanceSettingsContainer = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const { values, loading, error } = useSelector(selectBalanceSettingsData);

  useEffect(() => {
    dispatch(getBalanceSettingsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  return (
    <>
      {loading && <LinearProgress />}
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.BALANCE_SETTINGS_EDIT_REQUEST}
        resolve={types.BALANCE_SETTINGS_EDIT_SUCCESS}
        reject={types.BALANCE_SETTINGS_EDIT_FAILURE}
      >
        {onSubmit => (
          <Form
            onSubmit={v => {
              return onSubmit(v)
                .then(() =>
                  enqueueSnackbar(
                    t('distributor.balanceSettings.successEdit'),
                    {
                      variant: 'success',
                    },
                  ),
                )
                .catch(e => {
                  enqueueSnackbar(
                    `${t('distributor.balanceSettings.errorEdit')}: ${
                      e[FORM_ERROR] || e.message
                    }`,
                    {
                      variant: 'error',
                    },
                  );
                  return e;
                });
            }}
            initialValues={values}
            component={EditBalanceSettingsForm}
          />
        )}
      </MakeAsyncFunction>
    </>
  );
};

export default BalanceSettingsContainer;
