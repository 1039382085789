import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { Balance } from '../../types';
import ExportCell from './ExportCell';

const balancesExportTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'exportStatus',
    label: t('distributor.tables.orders.exportStatus', 'Exported'),
    options: {
      sort: false,
      component: ExportCell,
    },
  },
  {
    id: 'id',
    label: t('distributor.tables.rtt.idContracted'),
    options: {
      sort: false,
    },
  },
  {
    id: 'rttId',
    label: t('distributor.tables.rtt.name'),
    options: {
      sort: false,
      format: (_: unknown, entry: Balance) => entry.rtt.name,
    },
  },
  {
    id: 'createdAt',
    label: t('distributor.tables.headings.createdAt'),
    options: {
      align: 'right',
      format: (value: string) => t('formattedDateTime', { date: value }),
    },
  },
];

export default balancesExportTableConfig;
