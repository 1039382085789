import { CircularProgress, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React from 'react';
import { GOOGLE_MAP_API_KEY } from '../../environment';
import DrawingPolygon from './DrawingPolygon';
import Polygons from './Polygons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fillContainer: {
      height: '100%',
    },
  }),
);

const MAP_LIBRARIES = ['drawing'];

/** center of Zaporizhzhia */
const MAP_INITIAL_CENTER = {
  lat: 47.834,
  lng: 35.11,
};

const Map: React.FC = () => {
  const classes = useStyles();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const renderMap = () => {
    return (
      <Paper className={classes.fillContainer}>
        <GoogleMap
          id="data-example"
          zoom={12}
          center={MAP_INITIAL_CENTER}
          mapContainerClassName={classes.fillContainer}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          <DrawingPolygon />
          <Polygons />
        </GoogleMap>
      </Paper>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <CircularProgress />;
};

export default Map;
