import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { useAuth } from '@rtt-libs/auth';
import { FORM_ERROR } from 'final-form';
import { mapKeys } from 'lodash/fp';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { EditorsInfo, OrderSummary } from '../../../common';
import PromptDirtyForm from '../../../common/PromptDirtyForm';
import { PERMISSIONS } from '../../../rules';
import { promiseListener } from '../../../setup';
import { PAYLOAD_GROUPED_KEY } from '../../constants';
import { types } from '../../duck';
import type * as OrderTypes from '../../types';
import {
  parseOrderGroupIdKey,
  ShippingDateField,
  transformOrderGroupIdKey,
  useDetailsDecryptionState,
} from '../commonOrders';
import OrderComments from '../commonOrders/OrderComments';
import OrderProductsTable from '../commonOrders/OrderProductsTable';
import DeepEqualProductCheckFields from '../commonOrders/OrderProductsTable/DeepEqualProductCheckFields';
import ProductOrderSelector from '../commonOrders/OrderProductsTable/ProductOrderSelector';
import OrderTotal from '../commonOrders/OrderTotal';
import { FormHoister } from '../commonOrders/useHoistedForm';
import { CreateReturnOrderLink } from '../ReturnOrderCreate';

interface Props {
  order: OrderTypes.Order;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

const OrderDetails: React.FC<Props> = ({ order }) => {
  const orderId = order.id;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [t] = useTranslation();
  const { isPermittedTo } = useAuth();

  const {
    products,
    originTotal,
    total,
    payloadLoading,
    shouldTotalDecrypt,
    shouldOriginTotalDecrypt,
    shopInfo,
  } = useDetailsDecryptionState(orderId);

  const initialValues = useMemo(() => {
    const {
      id,
      shippingDate,
      managerDescription,
      rttDescription,
      managerId,
    } = order;

    return {
      id,
      shippingDate,
      rttDescription,
      [PAYLOAD_GROUPED_KEY]: {
        [transformOrderGroupIdKey(managerId || 0)]: {
          payload: { products, shopInfo },
          managerDescription,
        },
      },
    };
  }, [products, shopInfo, order]);

  const onFulfillment = () => {
    enqueueSnackbar(t('distributor.orders.details.successEditMessage'), {
      variant: 'success',
    });
  };

  const onRejection = (
    errors?: Partial<OrderTypes.OrderEditValues & { [FORM_ERROR]: string }>,
  ) => {
    enqueueSnackbar(
      errors?.shippingDate ||
        // TODO: make inner errors of combined payload
        // errors?.managerDescription ||
        // errors?.payload ||
        errors?.[FORM_ERROR] ||
        t('common.errorOccurred'),
      {
        variant: 'error',
      },
    );
    return errors;
  };

  const readOnly = !(
    order.status.value === 'processing' &&
    isPermittedTo(PERMISSIONS.NAV_ORDER_NEW)
  );

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.ORDER_EDIT_REQUEST}
      resolve={types.ORDER_EDIT_SUCCESS}
      reject={types.ORDER_EDIT_FAILURE}
    >
      {onSubmit => (
        <Form
          subscription={{ submitting: true }}
          initialValues={initialValues}
          onSubmit={values => {
            const withParsedKeys = mapKeys(
              groupKey => parseOrderGroupIdKey(groupKey)[0],
              values[PAYLOAD_GROUPED_KEY],
            );

            return onSubmit({
              ...values,
              [PAYLOAD_GROUPED_KEY]: withParsedKeys,
            }).then(onFulfillment, onRejection);
          }}
        >
          {({ handleSubmit, submitting }) => (
            <Paper className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <FormHoister />

                <PromptDirtyForm />

                <DeepEqualProductCheckFields />

                <Grid container spacing={3}>
                  {!shouldTotalDecrypt && !shouldOriginTotalDecrypt && (
                    <OrderSummary
                      status={order.status}
                      createdAt={order.createdAt}
                      consumer={order.rtt as OrderTypes.RttInfo}
                      title={orderId}
                      titleBadge={
                        <EditorsInfo
                          creator={order.creator}
                          lastEditor={order.lastEditor}
                          manager={order.manager}
                        />
                      }
                      totalElement={
                        <OrderTotal
                          value={total}
                          originTotal={originTotal ?? 0}
                          totalOnly={readOnly}
                        />
                      }
                    />
                  )}

                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ShippingDateField
                        required
                        disabled={readOnly || submitting}
                      />

                      {order.status.value === 'submit' &&
                        isPermittedTo(PERMISSIONS.NAV_RETURN_NEW) && (
                          <CreateReturnOrderLink
                            variant="outlined"
                            size="small"
                            orderId={orderId}
                            rttId={order.rttId}
                          >
                            {t('distributor.returnOrders.new.pageTitle')}
                          </CreateReturnOrderLink>
                        )}
                    </Box>
                  </Grid>

                  {payloadLoading ? (
                    <CircularProgress />
                  ) : (
                    <OrderProductsTable
                      hideResetSection
                      showEditButton={!readOnly}
                      returnedItems={order.returnedItems}
                      submitting={submitting}
                      productSelection={
                        <ProductOrderSelector
                          rttId={order.rttId}
                          managerId={order.managerId ?? undefined}
                          disabled={submitting}
                        />
                      }
                      comments={name => (
                        <OrderComments
                          readOnly={readOnly}
                          managerDescriptionFieldName={name}
                        />
                      )}
                    />
                  )}
                </Grid>
              </form>
            </Paper>
          )}
        </Form>
      )}
    </MakeAsyncFunction>
  );
};

export default OrderDetails;
