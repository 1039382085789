import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import { restrictions } from '@rtt-libs/constants';
import {
  FieldWithMessages as Field,
  LoadingButton,
  NumberFieldAdapter,
} from '@rtt-libs/views';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CreateDiscountValues } from '../../../api/discounts';
import {
  DiscountBrands,
  DiscountCategories,
} from '../../../common/discountsSales';
import PromptDirtyForm from '../../../common/PromptDirtyForm';
import { selectDiscountBrands } from '../../duck/selectors';
import DiscountProducts from './DiscountProducts';
import RttField from './RttField';

const DiscountCreate: React.FC<FormRenderProps<CreateDiscountValues>> = ({
  handleSubmit,
  error,
  submitError,
  dirtySinceLastSubmit,
  submitting,
  initialValues,
}) => {
  const [t] = useTranslation();

  const discountBrands = useSelector(selectDiscountBrands);

  const showError = (submitError && !dirtySinceLastSubmit) || error;

  return (
    <Box p={2} component={Paper}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <RttField readOnly={!!initialValues} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box maxWidth={200}>
              <Field
                name="discount"
                component={NumberFieldAdapter}
                label={t('distributor.discounts.discount')}
                required
                inputProps={restrictions.DISCOUNT_INPUTS_VALIDATIONS}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                fullWidth
                validate={(
                  value: number,
                  values: Partial<CreateDiscountValues>,
                ) => {
                  if (
                    value <= 0 &&
                    !values.categoryDiscounts &&
                    !values.brandDiscounts &&
                    !values.productDiscounts
                  ) {
                    return t('distributor.discounts.zeroValueError');
                  }
                  return undefined;
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box my={2}>
          <Grid
            container
            justify="space-between"
            item
            xs={12}
            spacing={2}
            alignItems="center"
          >
            <DiscountCategories messagesDomain="discounts" />
          </Grid>
        </Box>

        <Box my={2}>
          <Grid
            container
            justify="space-between"
            item
            xs={12}
            spacing={2}
            alignItems="center"
          >
            <DiscountBrands
              messagesDomain="discounts"
              discountBrands={discountBrands}
            />
          </Grid>
        </Box>

        <Box my={2}>
          <Grid
            container
            justify="space-between"
            item
            xs={12}
            spacing={2}
            alignItems="center"
          >
            <DiscountProducts />
          </Grid>
        </Box>

        <PromptDirtyForm />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={submitting}
            >
              {t('controls.save')}
            </LoadingButton>

            {showError && (
              <FormHelperText error>{error || submitError}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default DiscountCreate;
