import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  changeBrandStatusAPI,
  createBrandAPI,
  editBrandAPI,
  getAvailableBrandListAPI,
  getBrandListAPI,
} from '../../api/brands';
import type { Brand } from '../../types';
import * as actions from './actions';
import * as types from './types';

function* getBrandsWorker({
  payload,
}: ReturnType<typeof actions.getBrandsRequest>) {
  try {
    const data: Brand[] = yield call(getBrandListAPI, payload);

    yield all([put(actions.getBrandsSuccess(data))]);
  } catch (e) {
    yield put(actions.getBrandsFailure(e.message));
  }
}

function* getBrandsOptionsWorker({
  payload: { showAll, isActive },
}: ReturnType<typeof actions.getBrandsOptionsRequest>) {
  try {
    let data = [] as Brand[];
    if (showAll) {
      data = yield call(getBrandListAPI, { isActive });
    } else {
      data = yield call(getAvailableBrandListAPI, isActive);
    }

    yield all([put(actions.getBrandsSuccess(data))]);
  } catch (e) {
    yield put(actions.getBrandsFailure(e.message));
  }
}

function* createBrandWorker({
  payload,
}: ReturnType<typeof actions.createBrandRequest>) {
  try {
    const brand: Brand = yield call(createBrandAPI, payload);

    yield put(actions.createBrandSuccess(brand));
  } catch (errors) {
    yield put(actions.createBrandFailure(errors));
  }
}

function* editBrandWorker({
  payload,
}: ReturnType<typeof actions.editBrandRequest>) {
  try {
    const brand: Brand = yield call(editBrandAPI, payload);

    yield put(actions.editBrandSuccess(brand));
  } catch (errors) {
    yield put(actions.editBrandFailure(errors));
  }
}

function* changeBrandStatusWorker({
  payload,
}: ReturnType<typeof actions.changeStatusBrandRequest>) {
  try {
    const brand: Brand = yield call(changeBrandStatusAPI, payload);

    yield all([put(actions.changeStatusBrandSuccess(brand))]);
  } catch (e) {
    yield put(actions.changeStatusBrandFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.BRANDS_GET_REQUEST, getBrandsWorker);
  yield takeLatest(types.BRANDS_GET_OPTIONS_REQUEST, getBrandsOptionsWorker);
  yield takeLatest(types.BRANDS_CREATE_REQUEST, createBrandWorker);
  yield takeLatest(types.BRANDS_EDIT_REQUEST, editBrandWorker);
  yield takeLatest(types.BRANDS_CHANGE_STATUS_REQUEST, changeBrandStatusWorker);
}
