import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonProps,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AddAssortmentProductButtonModalProps = {
  children?(onClose: () => void): React.ReactNode;
  render?(onClose: () => void): React.ReactNode;
};

const AddAssortmentProductButtonModal = ({
  children,
  render,
  ...rest
}: AddAssortmentProductButtonModalProps & ButtonProps) => {
  const [t] = useTranslation();

  const [opened, changeModalState] = useState(false);

  const onClose = useCallback(() => {
    changeModalState(false);
  }, [changeModalState]);

  return (
    <>
      <Button
        disabled={opened}
        startIcon={<AddIcon />}
        {...rest}
        onClick={() => {
          changeModalState(true);
        }}
      >
        {t('controls.add')}
      </Button>

      <Dialog
        open={opened}
        onClose={() => changeModalState(false)}
        aria-labelledby="modal-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="modal-title">
          {t('distributor.orders.details.addProductTitle')}
        </DialogTitle>
        <DialogContent>
          {render?.(onClose) || children?.(onClose)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => changeModalState(false)}>
            {t('controls.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAssortmentProductButtonModal;
