/* eslint-disable @typescript-eslint/camelcase */

import { ENDPOINTS } from '../environment';
import api from './apiSetup';

type FinalFormResponse = {
  id: number | string;
  name: string;
  address: string;
  is_verified: boolean;
  contact?: {
    email: string;
    first_name: string;
    last_name: string;
  };
};

const updateDistributor = (id: number, data: FormData) =>
  api.post<FinalFormResponse>(
    `${ENDPOINTS.distributor}/${id}/${ENDPOINTS.updateDistributor}`,
    data,
  );

export default updateDistributor;
