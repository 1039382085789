import { Box, CircularProgress, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearExportStatus, getExportStatusRequest } from '../duck/actions';
import { selectExportStatus } from '../duck/selectors';
import type { ExportResultType } from '../types';
import ExportButton from './ExportButton';
import ExportDownloadLink from './ExportDownloadLink';
import ExportStatusDisplay from './ExportStatusDisplay';

interface Props {
  type: ExportResultType;
}

const ExportStatusContainer = ({ type }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExportStatusRequest(type));

    return () => {
      dispatch(clearExportStatus());
    };
  }, [dispatch, type]);

  const { status, loading, error, downloadLink } = useSelector(
    selectExportStatus,
  );

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  return (
    <Box display="block">
      <ExportButton
        type={type}
        loading={loading}
        processing={!!status.createdAt && !status.isDone}
      />
      <Box textAlign="right">
        <Fade in={loading}>
          <CircularProgress size="0.7rem" />
        </Fade>
        <ExportStatusDisplay status={status} />
        <ExportDownloadLink
          href={downloadLink}
          tokenUrl={status.src}
          type={type}
        />
      </Box>
    </Box>
  );
};

export default ExportStatusContainer;
