import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAgreedAssortmentsRequest } from '../../duck/actions';
import { selectAgreedAssortmentsData } from '../../duck/selectors';
import { AgreedAssortmentTableParams } from '../../types';
import AgreedAssortmentList from './AgreedAssortmentList';

const AgreedAssortmentContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { data, loading, error, pagination } = useSelector(
    selectAgreedAssortmentsData,
  );

  const onTableStateChange = useCallback(
    (options: Partial<AgreedAssortmentTableParams>) => {
      dispatch(getAgreedAssortmentsRequest(options));
    },
    [dispatch],
  );

  if (error && !loading) {
    enqueueSnackbar(error, {
      variant: 'error',
    });
  }

  return (
    <AgreedAssortmentList
      assortments={data}
      loading={loading}
      pagination={pagination}
      onStateChange={onTableStateChange}
    />
  );
};

export default AgreedAssortmentContainer;
