/*
 * Original: https://usehooks.com/useAsync
 */

import { useCallback, useEffect, useState } from 'react';

export const useAsync = <Payload, T, E>(
  asyncFunction: (payload?: Payload) => Promise<T>,
  immediate = false,
  initialPayload?: Payload,
) => {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);

  /**
   * The execute function wraps asyncFunction and
   * handles setting state for pending, value, and error.
   * useCallback ensures the below useEffect is not called
   * on every render, but only if asyncFunction changes.
   */
  const execute = useCallback(
    (data?: Payload) => {
      setPending(true);
      setValue(null);
      setError(null);
      return asyncFunction(data)
        .then(response => setValue(response))
        .catch((e: E) => setError(e))
        .finally(() => setPending(false));
    },
    [asyncFunction],
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute(initialPayload);
    }
  }, [execute, immediate, initialPayload]);

  return { execute, pending, value, error };
};
