import { ORDER_STATUSES } from '@rtt-libs/constants';
import type { ExportResultType, ExportChannel } from './exports/types';

export const BASE_API_URL = process.env.REACT_APP_API_URI;

export const REDUX_APP_ID = '@rtt/distr';

export const ROUTE_WITHOUT_PRIVILEGES = '/';
export const LOGIN_PAGE = `${process.env.REACT_APP_URI_PROTOCOL}${
  process.env.REACT_APP_SUBDOMAIN_LANDING
    ? `${process.env.REACT_APP_SUBDOMAIN_LANDING}.`
    : ''
}${process.env.REACT_APP_ROOT_DOMAIN}/login`;

export const NOT_ACTIVATED_STATUSES = ['new', 'declined', 'updated'];

export const ENDPOINTS = {
  profile: 'distributor',
  categories: 'categories',
  distributor: 'distributor',
  finishReg: 'distributor/create?include=contact',
  territories: 'manage/areas',
  updateDistributor: 'update?include=contact,documents,categories',
  administrators: 'manage/administrators',
  supervisors: 'manage/supervisors',
  supervisorAssignmentManagers: '/manage/supervisors/list/managers/available',
  employees: 'manage/employees',
  employeesStatusSuffix: 'status/change',
  exportData: 'manage/export',
  exportMixed: (channel: ExportChannel) => `manage/export/${channel}`,
  exportMultiStatus: 'manage/export/status',
  exportDataStatus: (
    type: ExportResultType,
    id?: string,
    fileStep?: 'token' | 'download',
  ) =>
    [`manage/export/status/${type}`, id, fileStep].filter(str => str).join('/'),
  exportStart: (
    type: 'orders' | Exclude<ExportResultType, 'selling'>,
    channel: ExportChannel,
  ) => [`manage/export/${type}`, channel].join('/'),
  priceTypes: 'manage/pricetypes/all',
  priceTypesSearch: 'manage/pricetypes',
  priceTypesRttAssign: 'manage/pricetypes/assign/rtt',
  assortment: 'manage/products',
  uploadAssortment: 'manage/import/products/chunk',
  updateAssortment: 'manage/import/products/chunk/only-update',
  listCategories: 'list/categories',
  listCurrencies: 'list/currencies',
  listMeasurements: 'list/measurements',
  orders: 'manage/orders',
  ordersByIds: 'manage/orders/search',
  orderProducts: (rttId: number | string) =>
    `manage/orders/rtt/${rttId}/products`,
  orderDeliveryDays: (rttId: number) =>
    `manage/orders/rtt/${rttId}/delivery-days`,
  productsPriceless: 'manage/products/list/priceless',
  returnOrderProducts: 'manage/order/return/products',
  returnOrders: 'manage/order/return',
  returnOrdersProducts: (rttId: number) =>
    `manage/order/return/rtt/${rttId}/products`,
  returnOrdersStatus: (orderId: string) =>
    `manage/orders/return/${orderId}/status`,
  returnOrdersEdit: (id: string) => `manage/orders/return/${id}`,
  returnOrderCreate: (orderId: string) => `manage/orders/${orderId}/return`,
  keys: 'keys',
  keysByRtt: 'keys/rtt',
  rtt: 'map/rtt',
  mapRtt: 'map/area',
  sales: 'manage/sales',
  salesSearch: 'manage/sales/search',
  settings: 'manage/settings',
  settingsExport: 'manage/settings/export/order',
  settingsExportTestFtp: 'manage/settings/export/order/test',
  settingsOrders: 'manage/settings?include=categories',
  supervisorManagers: 'manage/supervisors/list/managers',
  discounts: 'manage/discounts',
  discountsSearch: 'manage/discounts/search',
  discountsRttList: 'manage/discounts/rtt/list',
  balances: 'balances',
  balancesProducts: (rttId: number | string) =>
    `manage/balances/rtt/${rttId}/products`,
  balanceRttFreeList: 'balances/rtt',
  balanceRttList: 'balances/rtt/enabled',
  agreedProducts: (id: number | string) => `balances/products/rtt/${id}`,
  balanceRtt: (id: number | string) => `balances/rtt/${id}/enable`,
  balanceSettings: 'balances/settings',
  agreedAssortments: 'balances/agreed-assortments',
  downloadFiles: 'download/manager',
  brands: 'manage/brands',
  brandsAvailable: 'manage/brands/available/list',
  brandsUpdate: (id: number, changeStatus?: boolean) =>
    `manage/brands/${id}${changeStatus ? '/status' : ''}`,
  brandManagersByRtt: (id: number) => `manage/rtt/${id}/managers`,
  rttManagerList: (id: number) => `list/rtt/${id}/manager-list`,
};

export const APK_DISTR_LATEST = `${process.env.REACT_APP_URI_PROTOCOL}${process.env.REACT_APP_SUBDOMAIN_DISTRIBUTOR}.${process.env.REACT_APP_ROOT_DOMAIN}/${ENDPOINTS.downloadFiles}/release/OSA-manager-latest.apk`;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const GREEN_MARKER =
  'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
export const RED_MARKER =
  'http://maps.google.com/mapfiles/ms/icons/red-dot.png';

export const ORDER_STATUSES_TO_PERFORM = [
  ORDER_STATUSES[0],
  ORDER_STATUSES[1],
  ORDER_STATUSES[3],
  ORDER_STATUSES[5],
  ORDER_STATUSES[6],
  ORDER_STATUSES[7],
] as (
  | 'new'
  | 'pending'
  | 'processing'
  | 'requires_confirm'
  | 'declined'
  | 'approved'
)[];

export const SHOW_HIDDEN_FEATURE =
  process.env.REACT_APP_SHOW_FEATURES === 'true';

export const MOCK_API_CALLS = process.env.REACT_APP_MOCK_API_CALLS === 'true';
