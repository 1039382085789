import { keyBy } from 'lodash/fp';
import type { Reducer } from 'redux';
import type { SupervisorManager } from '../types';
import type { Actions } from './actions';
import * as types from './types';

// This part off state may be just list list of Managers without normalization
// but kept for consistency
const initialState = {
  loading: true,
  collection: {} as Record<SupervisorManager['id'], SupervisorManager>,
  error: null as string | null,
  idList: [] as SupervisorManager['id'][],
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.SUPERVISOR_MANAGERS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.SUPERVISOR_MANAGERS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(supervisorManager => supervisorManager.id),
      };

    case types.SUPERVISOR_MANAGERS_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
