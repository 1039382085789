export const DISCOUNT_SEARCH_REQUEST = `@rtt[D]/DISCOUNT_SEARCH_REQUEST` as const;
export const DISCOUNT_SEARCH_SUCCESS = `@rtt[D]/DISCOUNT_SEARCH_SUCCESS` as const;
export const DISCOUNT_SEARCH_FAILURE = `@rtt[D]/DISCOUNT_SEARCH_FAILURE` as const;

export const DISCOUNT_GET_ALL_REQUEST = `@rtt[D]/DISCOUNT_GET_ALL_REQUEST` as const;
export const DISCOUNT_GET_ALL_SUCCESS = `@rtt[D]/DISCOUNT_GET_ALL_SUCCESS` as const;
export const DISCOUNT_GET_ALL_FAILURE = `@rtt[D]/DISCOUNT_GET_ALL_FAILURE` as const;

export const DISCOUNT_CREATE_REQUEST = `@rtt[D]/DISCOUNT_CREATE_REQUEST` as const;
export const DISCOUNT_CREATE_RESPONSE = `@rtt[D]/DISCOUNT_CREATE_RESPONSE` as const;
export const DISCOUNT_CREATE_SUCCESS = `@rtt[D]/DISCOUNT_CREATE_SUCCESS` as const;
export const DISCOUNT_CREATE_FAILURE = `@rtt[D]/DISCOUNT_CREATE_FAILURE` as const;

export const DISCOUNT_DETAILS_REQUEST = `@rtt[D]/DISCOUNT_DETAILS_REQUEST` as const;
export const DISCOUNT_DETAILS_SUCCESS = `@rtt[D]/DISCOUNT_DETAILS_SUCCESS` as const;
export const DISCOUNT_DETAILS_FAILURE = `@rtt[D]/DISCOUNT_DETAILS_FAILURE` as const;

export const DISCOUNT_EDIT_REQUEST = `@rtt[D]/DISCOUNT_EDIT_REQUEST` as const;
export const DISCOUNT_EDIT_RESPONSE = `@rtt[D]/DISCOUNT_EDIT_RESPONSE` as const;
export const DISCOUNT_EDIT_SUCCESS = `@rtt[D]/DISCOUNT_EDIT_SUCCESS` as const;
export const DISCOUNT_EDIT_FAILURE = `@rtt[D]/DISCOUNT_EDIT_FAILURE` as const;

export const DISCOUNT_DELETE_REQUEST = `@rtt[D]/DISCOUNT_DELETE_REQUEST` as const;
export const DISCOUNT_DELETE_SUCCESS = `@rtt[D]/DISCOUNT_DELETE_SUCCESS` as const;
export const DISCOUNT_DELETE_FAILURE = `@rtt[D]/DISCOUNT_DELETE_FAILURE` as const;

export const DISCOUNT_RTT_REQUEST = `@rtt[D]/DISCOUNT_RTT_REQUEST` as const;
export const DISCOUNT_RTT_SUCCESS = `@rtt[D]/DISCOUNT_RTT_SUCCESS` as const;
export const DISCOUNT_RTT_FAILURE = `@rtt[D]/DISCOUNT_RTT_FAILURE` as const;
