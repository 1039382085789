import { Grid } from '@material-ui/core';
import { DatePickerProps, DateTimePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useMemo } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-calculate';

dayjs.extend(utc);

export const DateTimeFilterInput: React.FC<DatePickerProps> = props => {
  const [t] = useTranslation();

  const commonProps = useMemo(
    () => ({
      format: 'DD.MM.YYYY HH:mm',
      ampm: false,
      maxDate: new Date(),
      fullWidth: true,
      emptyLabel: t('validation.emptyLabel'),
      invalidDateMessage: t('validation.invalidDateMessage'),
      invalidLabel: t('validation.invalidLabel'),
      maxDateMessage: t('validation.maxDateMessage'),
      minDateMessage: t('validation.minDateMessage'),
    }),
    [t],
  );

  const toolbarLabels = useMemo(
    () => ({
      cancelLabel: t('controls.cancel'),
      clearLabel: t('controls.clear'),
      todayLabel: t('controls.today'),
    }),
    [t],
  );

  // eslint-disable-next-line react/destructuring-assignment
  if (props.variant === 'inline') {
    return <DateTimePicker disableToolbar {...commonProps} {...props} />;
  }

  return (
    <DateTimePicker clearable {...commonProps} {...toolbarLabels} {...props} />
  );
};

const DateTimeAdapter = ({
  input: { name, onChange, value, type, ...restInput },
  meta,
  helperText,
  ...props
}: DatePickerProps & FieldRenderProps<Dayjs>) => {
  return (
    <DateTimeFilterInput
      name={name}
      value={value}
      maxDate={dayjs()}
      error={meta.invalid}
      helperText={(meta.touched && meta.error) || helperText}
      clearable={false}
      {...restInput}
      {...props}
      onChange={onChange}
    />
  );
};

type DatetimeFormSlice = {
  datetimeStart: Dayjs;
  datetimeEnd: Dayjs;
};

const validateDatetimeRangeField = (
  diffMinutesFn: (value: Dayjs, allValues: DatetimeFormSlice) => number,
  messages: { valueMissing: string; outOfRange: string; negativeDiff: string },
) => (value: Dayjs, allValues: object) => {
  // required
  if (!value) {
    return messages.valueMissing;
  }

  const diffMinutes = diffMinutesFn(value, allValues as DatetimeFormSlice);
  // max period is 24h
  if (diffMinutes > 1_440 /* minutes in a day */) {
    return messages.outOfRange;
  }
  // shouldn't over
  if (diffMinutes < 0) {
    return messages.negativeDiff;
  }

  return undefined;
};

const datetimeFieldNames = {
  start: 'datetimeStart',
  end: 'datetimeEnd',
} as const;

type DatetimeFields = {
  [datetimeFieldNames.start]: Dayjs;
  [datetimeFieldNames.end]: Dayjs;
};

export const aDayCalculator = createDecorator(
  {
    field: datetimeFieldNames.start,
    updates: {
      [datetimeFieldNames.end]: (
        datetimeStartValue: Dayjs,
        allValues?: Partial<DatetimeFields>,
      ) => {
        const datetimeEndValue = allValues?.[datetimeFieldNames.end]?.isValid()
          ? (allValues?.[datetimeFieldNames.end] as Dayjs)
          : dayjs();

        const diffMinutes = datetimeEndValue.diff(datetimeStartValue, 'minute');

        if (diffMinutes > 1_440) {
          return datetimeStartValue.add(1_440, 'minute');
        }
        if (diffMinutes < 0) {
          return datetimeStartValue.add(1_440, 'minute');
        }

        return datetimeEndValue;
      },
    },
  },
  {
    field: datetimeFieldNames.end,
    updates: {
      [datetimeFieldNames.start]: (
        datetimeEndValue: Dayjs,
        allValues?: Partial<DatetimeFields>,
      ) => {
        const datetimeStartValue = allValues?.[
          datetimeFieldNames.start
        ]?.isValid()
          ? (allValues?.[datetimeFieldNames.start] as Dayjs)
          : dayjs().subtract(1, 'day');

        const diffMinutes = datetimeEndValue.diff(datetimeStartValue, 'minute');

        if (diffMinutes > 1_440) {
          return datetimeEndValue.subtract(1_440, 'minute');
        }
        if (diffMinutes < 0) {
          return datetimeEndValue.subtract(1_440, 'minute');
        }

        return datetimeStartValue;
      },
    },
  },
);

const DateTimeFields = () => {
  const [t] = useTranslation();

  return (
    <Grid container item spacing={1} xs={12} md={8} justify="space-around">
      <Grid item xs={6} lg={4}>
        <Field
          component={DateTimeAdapter}
          name={datetimeFieldNames.start}
          required
          label={t('distributor.export.datetimeStartLabel')}
          helperText={t('distributor.export.datetimeHelperText')}
          validate={validateDatetimeRangeField(
            (value, { datetimeEnd }) => datetimeEnd.diff(value, 'minute'),
            {
              valueMissing: t('validation.valueMissing'),
              outOfRange: t('validation.rangeUnderflow'),
              negativeDiff: t('validation.maxDateMessage'),
            },
          )}
        />
      </Grid>

      <Grid item xs={6} lg={4}>
        <Field
          component={DateTimeAdapter}
          name={datetimeFieldNames.end}
          required
          label={t('distributor.export.datetimeEndLabel')}
          validate={validateDatetimeRangeField(
            (value, { datetimeStart }) => value.diff(datetimeStart, 'minute'),
            {
              valueMissing: t('validation.valueMissing'),
              outOfRange: t('validation.rangeOverflow'),
              negativeDiff: t('validation.minDateMessage'),
            },
          )}
        />
      </Grid>
    </Grid>
  );
};

export default DateTimeFields;
