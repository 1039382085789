import { FormControlLabel, Switch, FormHelperText } from '@material-ui/core';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

const SwitchAdapter: React.FC<
  FieldRenderProps<boolean> & {
    inputProps?: { [k: string]: unknown };
    label?: React.ReactNode;
  }
> = props => {
  const {
    // type prop is omitted to prevent typechecking error
    input: { name, onChange, value, type, ...restInput },
    meta,
    inputProps = {},
    label,
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const innerInputProps = {
    ...restInput,
    ...inputProps,
  };

  return (
    <>
      <FormControlLabel
        {...rest}
        name={name}
        checked={!!value}
        label={label}
        control={
          <Switch onChange={onChange} value={value} {...innerInputProps} />
        }
      />
      {showError && (
        <FormHelperText error>{meta.error || meta.submitError}</FormHelperText>
      )}
    </>
  );
};

export default SwitchAdapter;
