import {
  Box,
  FormHelperText,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { TablePagination } from '@rtt-libs/views';
import { debounce, pickBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProductAddList } from '../../../common';
import { ProductSearchParams } from '../../../ordersRefactored/types';
import { searchProductsAsRttRequest } from '../../../productsAddition/duck/actions';
import { selectProductsForRtt } from '../../../productsAddition/duck/selectors';

interface Props {
  rttId?: number;
}

const PRODUCTS_COUNT = 10;

const AddProductContainer: React.FC<Props> = ({ rttId }) => {
  const dispatch = useDispatch();
  const [displayOptions, changeOptions] = useState<ProductSearchParams>({
    perPage: PRODUCTS_COUNT,
  });

  useEffect(() => {
    if (rttId) {
      dispatch(
        searchProductsAsRttRequest(rttId, {
          ...displayOptions,
        }),
      );
    }
  }, [dispatch, rttId, displayOptions]);

  const onChange = useCallback((params: Partial<ProductSearchParams>) => {
    changeOptions(prevParams => pickBy({ ...prevParams, page: 1, ...params }));
  }, []);

  const { data, pagination, loading, error } = useSelector(
    selectProductsForRtt,
  );

  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  const localeLabels = {
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  if (error) return <FormHelperText error>{error}</FormHelperText>;

  return (
    <>
      <TextField
        name="search"
        label={t('distributor.rtt.search', 'Search')}
        onChange={onSearchChange}
        fullWidth
      />
      <Box minHeight={8}>{loading && <LinearProgress />}</Box>
      {!data.length && !loading && (
        <Typography variant="h6" color="textSecondary">
          {t('distributor.orders.add.noProducts')}
        </Typography>
      )}
      <ProductAddList data={data} />
      <TablePagination
        count={pagination.total || 0}
        onChange={onChange}
        initialRowsPerPage={PRODUCTS_COUNT}
        paginationProps={{
          labelDisplayedRows: localeLabels.displayedRows,
          labelRowsPerPage: localeLabels.rowsPerPage,
        }}
      />
    </>
  );
};

export default AddProductContainer;
