import { Pagination } from '@rtt-libs/types';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createAgreedAssortment,
  deleteAgreedAssortment,
  editAgreedAssortment,
  fetchRttOptions,
  getAgreedAssortmentById,
  getAgreedAssortments,
} from '../../api/agreedAssortments';
import {
  AgreedAssortment,
  AgreedAssortmentWithItems,
  RttSelectOption,
} from '../types';
import * as actions from './actions';
import * as types from './types';

function* listWorker({
  payload,
}: ReturnType<typeof actions.getAgreedAssortmentsRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: AgreedAssortment[];
      meta: { pagination: Pagination };
    } = yield call(getAgreedAssortments, payload);

    yield put(actions.getAgreedAssortmentsSuccess(data, meta));
  } catch (e) {
    yield put(actions.getAgreedAssortmentsFailure(e.message));
  }
}

function* detailsWorker({
  payload,
}: ReturnType<typeof actions.getAgreedAssortmentDetailsRequest>) {
  try {
    const data: AgreedAssortmentWithItems = yield call(
      getAgreedAssortmentById,
      payload,
    );

    yield put(actions.getAgreedAssortmentDetailsSuccess(data));
  } catch (e) {
    yield put(actions.getAgreedAssortmentDetailsFailure(e.message));
  }
}

function* createWorker({
  payload,
}: ReturnType<typeof actions.createAgreedAssortmentsRequest>) {
  try {
    const data: AgreedAssortmentWithItems = yield call(
      createAgreedAssortment,
      payload,
    );

    yield put(actions.createAgreedAssortmentsSuccess(data));
  } catch (e) {
    yield put(actions.createAgreedAssortmentsFailure(e));
  }
}

function* editWorker({
  payload,
}: ReturnType<typeof actions.editAgreedAssortmentsRequest>) {
  try {
    const data: AgreedAssortmentWithItems = yield call(
      editAgreedAssortment,
      payload,
    );

    yield put(actions.editAgreedAssortmentsSuccess(data));
  } catch (e) {
    yield put(actions.editAgreedAssortmentsFailure(e));
  }
}

function* deleteWorker({
  payload,
  meta: { cb, messages },
}: ReturnType<typeof actions.deleteAgreedAssortmentRequest>) {
  try {
    yield call(deleteAgreedAssortment, payload);

    yield put(actions.deleteAgreedAssortmentSuccess(payload));
    // eslint-disable-next-line no-unused-expressions
    cb?.(messages?.success, { variant: 'success' });
  } catch (e) {
    yield put(actions.deleteAgreedAssortmentFailure(payload, e));
    // eslint-disable-next-line no-unused-expressions
    cb?.(messages?.error, { variant: 'error' });
  }
}

function* fetchRttWorker() {
  try {
    const data: RttSelectOption[] = yield call(fetchRttOptions);

    yield put(actions.fetchAgreedAssortmentsRttSuccess(data));
  } catch (e) {
    yield put(actions.fetchAgreedAssortmentsRttFailure(e));
  }
}

export default function* watcher() {
  yield takeLatest(types.AGREED_ASSORTMENT_GET_LIST_REQUEST, listWorker);
  yield takeLatest(types.AGREED_ASSORTMENT_GET_DETAILS_REQUEST, detailsWorker);
  yield takeLatest(types.AGREED_ASSORTMENT_CREATE_REQUEST, createWorker);
  yield takeLatest(types.AGREED_ASSORTMENT_FETCH_RTT_REQUEST, fetchRttWorker);
  yield takeLatest(types.AGREED_ASSORTMENT_EDIT_REQUEST, editWorker);
  yield takeEvery(types.AGREED_ASSORTMENT_DELETE_REQUEST, deleteWorker);
}
