export const GET_ASSORTMENT_REQUEST = `@rtt[D]/GET_ASSORTMENT_REQUEST` as const;
export const GET_ASSORTMENT_SUCCESS = `@rtt[D]/GET_ASSORTMENT_SUCCESS` as const;
export const GET_ASSORTMENT_FAILURE = `@rtt[D]/GET_ASSORTMENT_FAILURE` as const;

export const GET_PRODUCT_REQUEST = `@rtt[D]/GET_PRODUCT_REQUEST` as const;
export const GET_PRODUCT_SUCCESS = `@rtt[D]/GET_PRODUCT_SUCCESS` as const;
export const GET_PRODUCT_FAILURE = `@rtt[D]/GET_PRODUCT_FAILURE` as const;

export const POST_PRODUCT_REQUEST = `@rtt[D]/POST_PRODUCT_REQUEST` as const;
export const POST_PRODUCT_SUCCESS = `@rtt[D]/POST_PRODUCT_SUCCESS` as const;
export const POST_PRODUCT_FAILURE = `@rtt[D]/POST_PRODUCT_FAILURE` as const;

export const GET_OPTIONS_PRODUCT_REQUEST = `@rtt[D]/GET_OPTIONS_PRODUCT_REQUEST` as const;
export const GET_OPTIONS_PRODUCT_SUCCESS = `@rtt[D]/GET_OPTIONS_PRODUCT_SUCCESS` as const;
export const GET_OPTIONS_PRODUCT_FAILURE = `@rtt[D]/GET_OPTIONS_PRODUCT_FAILURE` as const;
