import {
  CategoriesItemDictionary,
  CategoryItem,
  CategoryItemId,
} from '@rtt-libs/types';
import { intersection, mapValues, pick } from 'lodash/fp';
import get from 'lodash/fp/get';
import { createSelector, Selector } from 'reselect';
import { branchName, mainReducerKey } from './const';
import { State } from './reducer';

type PartialRootState = {
  [branchName]: State;
};

const ROOT_CATEGORY_ID = 'root' as const;

const getAllCategoriesSel: Selector<
  PartialRootState,
  CategoriesItemDictionary
> = get([branchName, mainReducerKey]);

const getAllAndDeleteCategoriesSel: Selector<
  PartialRootState,
  CategoriesItemDictionary
> = get([branchName, 'allCategories']);

export const selectAvailableCategory: Selector<
  PartialRootState,
  CategoriesItemDictionary
> = get([branchName, 'availableCategories']);

export const getRootCategoriesSel = createSelector<
  PartialRootState,
  CategoriesItemDictionary,
  CategoryItem[]
>(
  getAllCategoriesSel,
  categories =>
    categories[ROOT_CATEGORY_ID]?.children.map(id => categories[id]) ?? [],
);

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

const getSearchValue: Selector<PartialRootState, string> = get([
  branchName,
  'searchValue',
]);

const selectParentIds: Selector<
  PartialRootState,
  Record<CategoryItemId, CategoryItemId>
> = get([branchName, 'parentCategories']);

const getSearchList: Selector<
  PartialRootState,
  { [key: string]: CategoryItemId }
> = get([branchName, 'byNameId']);

export const selectAllCategories: Selector<
  PartialRootState,
  {
    allCategories: CategoriesItemDictionary;
  }
> = createSelector([getAllAndDeleteCategoriesSel], allCategories => ({
  allCategories,
}));

export const selectAllAvailableCategories = createSelector<
  PartialRootState,
  CategoriesItemDictionary,
  CategoryItem[]
>(getAllCategoriesSel, categories =>
  Object.values(categories).filter(category => category.data),
);

export const selectAvailableCategories = createSelector<
  PartialRootState,
  CategoriesItemDictionary,
  CategoryItem[]
>(getAllCategoriesSel, categories => Object.values(categories));

export const selectCategoryTree = createSelector(
  [selectAvailableCategory],
  categories => ({
    rootId: ROOT_CATEGORY_ID,
    items: categories,
  }),
);

const getSearchCategories: Selector<
  PartialRootState,
  CategoryItemId[]
> = createSelector(
  [getSearchList, getSearchValue],
  (searchArray, searchValue) => {
    const idsArray = [] as CategoryItemId[];
    Object.entries(searchArray).forEach(
      ([key, value]) =>
        key.includes(searchValue.toLowerCase()) && idsArray.push(value),
    );
    return idsArray;
  },
);

export const selectSearchCategories: Selector<
  PartialRootState,
  {
    data: CategoryItem[];
    loading: boolean;
  }
> = createSelector(
  [selectAvailableCategory, getSearchCategories, getLoadingStateSel],
  (collection, dataSearch, loading) => ({
    data: dataSearch.map(id => collection[id]),
    loading,
  }),
);

export const selectSearchedCategoryTree = createSelector(
  selectAvailableCategory,
  getSearchCategories,
  selectParentIds,
  (categories, filteredIds, parentsMap) => {
    const parentIds = [] as CategoryItemId[];
    filteredIds.forEach(addMissingParentIds(parentsMap, parentIds));

    const childIds = [] as CategoryItemId[];
    filteredIds.forEach(addMissingChildIds(categories, childIds));

    const items = pick(
      filteredIds.concat(parentIds, childIds, ROOT_CATEGORY_ID),
      categories,
    );

    return {
      rootId: ROOT_CATEGORY_ID,
      items: cleanUpChildrenIds(items, parentIds),
    };
  },
);

/*
 * Handlers for filtering categories tree
 */

const addMissingParentIds = (
  parentsMap: Record<CategoryItemId, CategoryItemId>,
  parents: CategoryItemId[],
) =>
  function addMissingParentIdsWithParents(
    childId: CategoryItemId,
    _: unknown,
    filtered: CategoryItemId[],
  ) {
    const parentId = parentsMap[childId];

    if (parentId === ROOT_CATEGORY_ID) return;

    if (!filtered.includes(parentId) && !parents.includes(parentId)) {
      parents.push(parentId);

      addMissingParentIdsWithParents(parentId, null, filtered);
    }
  };

const addMissingChildIds = (
  categories: Record<CategoryItemId, CategoryItem>,
  childIds: CategoryItemId[],
) =>
  function addMissingChildIdsWithCategories(childId: CategoryItemId) {
    const { children } = categories[childId];

    childIds.push(...children);

    // eslint-disable-next-line no-unused-expressions
    children?.forEach(id => {
      addMissingChildIdsWithCategories(id);
    });
  };

function cleanUpChildrenIds(
  items: Record<CategoryItemId, CategoryItem>,
  parentIds: CategoryItemId[],
) {
  const keys = Object.values(items).map(i => i.id);

  const intersectWithFullList = intersection(keys);

  return mapValues(({ children, ...rest }) => {
    return {
      ...rest,
      children: intersectWithFullList(children),
      isExpanded: parentIds.includes(rest.id),
    };
  }, items);
}

export const selectCategoriesTreeData = createSelector(
  selectSearchedCategoryTree,
  getLoadingStateSel,
  getErrorSel,
  (data, loading, error) => ({ data, loading, error }),
);
