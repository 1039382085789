/* eslint-disable @typescript-eslint/camelcase */

import { ENDPOINTS } from '../environment';
import { getAuthToken } from '../cookieHandlers';
import api from '../api/setup';

export type ChangePassword = {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const changePassword = (values: ChangePassword) => {
  const token = getAuthToken();

  return api.post(
    ENDPOINTS.changePassword,
    {
      ...mapChangePasswordValues(values),
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

function mapChangePasswordValues(values: ChangePassword) {
  return {
    password: values.password,
    new_password: values.newPassword,
    new_password_confirmation: values.newPasswordConfirmation,
  };
}
