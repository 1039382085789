import DayjsUtils from '@date-io/dayjs';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { restrictions } from '@rtt-libs/constants';
import {
  FieldWithMessages,
  LoadingButton,
  NumberFieldAdapter,
} from '@rtt-libs/views';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { Field, FieldRenderProps, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import type { BalanceSettings } from '../types';

dayjs.extend(utc);

const EditBalanceSettingsForm = ({
  handleSubmit,
  submitting,
  pristine,
  error,
  form,
}: FormRenderProps<BalanceSettings>) => {
  const [t] = useTranslation();

  return (
    <Box clone p={2}>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box width={235}>
                <FieldWithMessages
                  name="coefWBalance"
                  label={t('distributor.balanceSettings.labels.coefWBalance')}
                  component={NumberFieldAdapter}
                  inputProps={{
                    min: 0,
                    step: 0.001,
                    max: restrictions.PRODUCT_QTY_MAX_VALUE,
                  }}
                  required
                  fullWidth
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box width={235}>
                <FieldWithMessages
                  name="coefWoBalance"
                  label={t('distributor.balanceSettings.labels.coefWoBalance')}
                  component={NumberFieldAdapter}
                  inputProps={{
                    min: 0,
                    step: 0.001,
                    max: restrictions.PRODUCT_QTY_MAX_VALUE,
                  }}
                  required
                  fullWidth
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <Field
                  allowNull
                  name="timeNotification"
                  parse={(date: Dayjs | null) => {
                    return date?.utc().format('HH:mm');
                  }}
                  format={(value: string | undefined) => {
                    return (value && dayjs.utc(value, 'HH:mm').local()) || null;
                  }}
                  validate={(value: string | undefined) => {
                    return !value ? t('validation.valueMissing') : undefined;
                  }}
                >
                  {({
                    input,
                    meta,
                    ...rest
                  }: FieldRenderProps<Dayjs | null>) => {
                    const showError =
                      ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                        meta.error) &&
                      meta.touched;

                    /** round to hours */
                    const roundMinutes = (value: Dayjs) =>
                      value.minute(Math.floor(value.minute() / 30) * 60);

                    return (
                      <KeyboardTimePicker
                        views={['hours']}
                        autoOk
                        minutesStep={60}
                        required
                        ampm={false}
                        variant="inline"
                        label={t(
                          'distributor.balanceSettings.labels.timeNotification',
                        )}
                        value={input.value}
                        onChange={(value, strValue) => {
                          // do not update value before it with placeholder
                          if (!strValue?.includes('_')) {
                            // force update value
                            // value is string - if rounding to 0< the value will be the same string,
                            // so we need a force update
                            input.onChange(dayjs());
                            input.onChange(value && roundMinutes(value));
                          }
                        }}
                        helperText={meta.error}
                        error={showError}
                      />
                    );
                  }}
                </Field>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Box width={235}>
                <FieldWithMessages
                  name="productListHistoryDays"
                  label={t(
                    'distributor.balanceSettings.labels.productListHistoryDays',
                  )}
                  helperText={t(
                    'distributor.balanceSettings.labels.productListHistoryDaysHelperText',
                  )}
                  component={NumberFieldAdapter}
                  inputProps={{
                    min: 1,
                    step: 1,
                    max: 730,
                  }}
                  validate={value => {
                    return /^\d*$/.test(value)
                      ? undefined
                      : t('validation.notInteger');
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: t('product.units.days'),
                  }}
                  required
                  fullWidth
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={submitting}
                disabled={pristine || error || submitting}
              >
                {t('controls.save')}
              </LoadingButton>

              <Box clone ml={1}>
                <Button
                  type="reset"
                  disabled={pristine}
                  onClick={() => form.reset()}
                >
                  {t('controls.clear')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default EditBalanceSettingsForm;
