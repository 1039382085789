import { PAYLOAD_GROUPED_KEY } from '../../../constants';

export const makeGroupedFieldName = (groupId: string | number) =>
  `${PAYLOAD_GROUPED_KEY}.${groupId}`;

export const makeProductsPayloadFieldNamePrefix = (groupId: string | number) =>
  `${makeGroupedFieldName(groupId)}.payload.products`;

export const makeShopInfoPayloadFieldNamePrefix = (groupId: string | number) =>
  `${makeGroupedFieldName(groupId)}.payload.shopInfo`;

export const makeManagerDescriptionFieldName = (groupId: string | number) =>
  `${makeGroupedFieldName(groupId)}.managerDescription`;
