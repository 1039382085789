import { PRODUCTS_DEFAULTS } from '@rtt-libs/constants';
import type { OrderEditValues } from '../types';

export const ORDERED_PRODUCT_QTY_DEFAULT_VALUE = PRODUCTS_DEFAULTS.ORDER_QTY;
export const ORDERED_PRODUCT_WEIGHT_DEFAULT_VALUE =
  PRODUCTS_DEFAULTS.ORDER_WEIGHT;
export const SWITCH_AVAILABLE_DEFAULT_VALUE =
  PRODUCTS_DEFAULTS.SWITCH_AVAILABLE;
export const NULL_ORDERWEIGHT_DEFAULT_VALUE =
  PRODUCTS_DEFAULTS.NULL_ORDERWEIGHT;

const RTT_MIN_ORDER_QTY = 1;

export const isOrderCreationAccessed = (qty?: number): boolean => {
  return (qty ?? 0) >= RTT_MIN_ORDER_QTY;
};

export const isUnableToSetOrderSubmitStatus = (
  formValues: Partial<OrderEditValues>,
): boolean => !!formValues.shippingDate;
