import { CheckMark, ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import React from 'react';
import { Brand } from '../../types';
import BrandActionsMenu from './ActionsMenu';
import * as types from '../duck/types';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const brandsTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'id',
    label: t('distributor.tables.brands.id', 'ID'),
    options: {
      sort: false,
    },
  },
  {
    id: 'title',
    label: t('distributor.tables.brands.title', 'Title'),
    options: {
      sort: true,
    },
  },
  {
    id: 'isActive',
    label: t('distributor.tables.brands.activeStatus', 'Active status'),
    options: {
      sort: true,
      align: 'center',
      component: CheckMark,
    },
  },

  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: Brand) => entry,
      component: ({ id, value }) => (
        <BrandActionsMenu
          id={String(id)}
          value={value as Brand}
          actionTypes={{
            start: types.BRANDS_CHANGE_STATUS_REQUEST,
            resolve: types.BRANDS_CHANGE_STATUS_SUCCESS,
            reject: types.BRANDS_CHANGE_STATUS_FAILURE,
          }}
        />
      ),
      sort: false,
    },
  },
];

export default brandsTableConfig;
