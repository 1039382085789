import { useSnackbar } from 'notistack';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import type { BrandEditFormValues } from '../types';
import BrandDetailsForm from './BrandDetailsForm';
import BrandFormFields from './BrandFormFields';

type Props = {
  initialValues: BrandEditFormValues;
  onClose(): void;
};

const EditBrandContainer: React.FC<Props> = ({ onClose, initialValues }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.BRANDS_EDIT_REQUEST}
      resolve={types.BRANDS_EDIT_SUCCESS}
      reject={types.BRANDS_EDIT_FAILURE}
    >
      {onSubmit => (
        <Form
          initialValues={initialValues}
          onSubmit={async (values: BrandEditFormValues) => {
            try {
              await onSubmit(values);
              onClose();

              enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                variant: 'success',
              });

              return undefined;
            } catch (errors) {
              enqueueSnackbar(t('common.snackbars.saveFailed'), {
                variant: 'error',
              });

              return errors;
            }
          }}
          render={props => (
            <BrandDetailsForm
              onClose={onClose}
              heading={t('distributor.brands.edit.dialogTitle')}
              submitLabel={t('controls.edit')}
              {...props}
            >
              <Field name="id" component="input" type="hidden" />

              <BrandFormFields />
            </BrandDetailsForm>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default EditBrandContainer;
