import { CategoryItem, CategoryItemId, Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import {
  AssortmentGetParams,
  Currency,
  Product,
  ProductUpdate,
} from '../../api/assortment';
import * as types from './types';

export const getAssortmentRequest = (
  params?: Partial<AssortmentGetParams>,
) => ({
  type: types.GET_ASSORTMENT_REQUEST,
  payload: params,
});

export const getAssortmentSuccess = (
  payload: Product[],
  meta: { pagination: Pagination },
) => ({
  type: types.GET_ASSORTMENT_SUCCESS,
  payload,
  meta,
});

export const getAssortmentFailure = (error: string) => ({
  type: types.GET_ASSORTMENT_FAILURE,
  payload: error,
});

export const getProductRequest = (id: Product['id']) => ({
  type: types.GET_PRODUCT_REQUEST,
  payload: id,
});

export const getProductSuccess = (payload: Product) => ({
  type: types.GET_PRODUCT_SUCCESS,
  payload,
});

export const getProductFailure = (error: string) => ({
  type: types.GET_PRODUCT_FAILURE,
  payload: error,
});

export const postProductRequest = (payload: {
  id?: Product['id'];
  values: Partial<ProductUpdate>;
}) => ({
  type: types.POST_PRODUCT_REQUEST,
  payload,
});

export const postProductSuccess = (payload: Product) => ({
  type: types.POST_PRODUCT_SUCCESS,
  payload,
});

export const postProductFailure = (
  submissionErrors?: Partial<
    Record<keyof ProductUpdate, string> & { [FORM_ERROR]: string }
  >,
) => ({
  type: types.POST_PRODUCT_FAILURE,
  payload: submissionErrors,
});

export const getOptionsProductRequest = () => ({
  type: types.GET_OPTIONS_PRODUCT_REQUEST,
});

export const getOptionsProductSuccess = (payload: {
  categoryList: Record<CategoryItemId, CategoryItem>;
  currencyList: Currency[];
}) => ({
  type: types.GET_OPTIONS_PRODUCT_SUCCESS,
  payload,
});

export const getOptionsProductFailure = (error: string) => ({
  type: types.GET_OPTIONS_PRODUCT_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof getAssortmentRequest
  | typeof getAssortmentSuccess
  | typeof getAssortmentFailure
  | typeof getProductRequest
  | typeof getProductSuccess
  | typeof getProductFailure
  | typeof postProductRequest
  | typeof postProductSuccess
  | typeof postProductFailure
  | typeof getOptionsProductRequest
  | typeof getOptionsProductSuccess
  | typeof getOptionsProductFailure
>;
