import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as types from '../types';
import {
  decryptOriginTotals,
  decryptPayloads,
  decryptTotals,
} from './encryptionWorkers';
import {
  closestDeliveryDayWorker,
  minTotalWorker,
  orderDetailsWorker,
  ordersGetWorker,
  returnOrderDetailsWorker,
  returnOrdersGetWorker,
} from './fetchWorkers';
import {
  getStatusesWorker,
  setReturnStatusWorker,
  setStatusWorker,
} from './statusesWorker';
import {
  createOrderWorker,
  createReturnOrderWorker,
  editOrderWorker,
  editReturnOrderWorker,
} from './uploadWorkers';

export function* watcher() {
  yield takeLatest(types.ORDERS_GET_REQUEST, ordersGetWorker);
  yield takeLatest(types.ORDER_DETAILS_GET_REQUEST, orderDetailsWorker);
  yield takeLatest(types.ORDER_STATUSES_GET_REQUEST, getStatusesWorker);
  yield takeLatest(types.ORDER_STATUSES_SET_REQUEST, setStatusWorker);
  yield takeLatest(types.RETURN_STATUSES_SET_REQUEST, setReturnStatusWorker);
  yield takeLatest(types.ORDER_EDIT_REQUEST, editOrderWorker);
  yield takeLatest(types.ORDER_CREATE_REQUEST, createOrderWorker);
  yield takeLatest(
    types.ORDER_DELIVERY_CLOSES_DAY_REQUEST,
    closestDeliveryDayWorker,
  );
  yield takeLatest(types.ORDER_MIN_TOTAL_REQUEST, minTotalWorker);
  yield takeEvery(types.ORDER_DECRYPT_TOTAL_REQUEST, decryptTotals);
  yield takeEvery(types.ORDER_DECRYPT_ORIGINTOTAL_REQUEST, decryptOriginTotals);
  yield takeEvery(types.ORDER_DECRYPT_PAYLOAD_REQUEST, decryptPayloads);
  yield takeLatest(types.RETURNS_GET_REQUEST, returnOrdersGetWorker);
  yield takeLatest(types.RETURN_DETAILS_GET_REQUEST, returnOrderDetailsWorker);
  yield takeLatest(types.RETURN_EDIT_REQUEST, editReturnOrderWorker);
  yield takeLatest(types.RETURN_CREATE_REQUEST, createReturnOrderWorker);
}
