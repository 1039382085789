import { Box, CircularProgress, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsExportRequest } from '../../../../settings/duck/actions';
import { isExportSettingsUnset } from '../../../../settings/duck/selectors';
import {
  clearExportStatus,
  getExportStatusRequest,
  startMultiExportRequest,
} from '../../../duck/actions';
import { selectExportStatus } from '../../../duck/selectors';
import type { ExportChannel, ExportResultType } from '../../../types';
import SplitButton from '../../components/SplitButton';
import ExportDownloadLink from '../../ExportDownloadLink';
import ExportStatusDisplay from '../../ExportStatusDisplay';

const ExportMultiButton = () => {
  const {
    values: { type = [] },
  } = useFormState<{ type?: ExportResultType[] }>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExportStatusRequest('mixed'));

    return () => {
      dispatch(clearExportStatus());
    };
  }, [dispatch]);

  const { status, loading, error, downloadLink } = useSelector(
    selectExportStatus,
  );

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  return (
    <Box display="block">
      <ExportButton types={type} disabled={!type.length || loading} />
      <Box textAlign="right">
        <Fade in={loading}>
          <CircularProgress size="0.7rem" />
        </Fade>
        <ExportStatusDisplay status={status} />
        <ExportDownloadLink
          href={downloadLink}
          tokenUrl={status.src}
          type={Array.isArray(type) ? 'mixed' : type}
        />
      </Box>
    </Box>
  );
};

export default ExportMultiButton;

interface Props {
  types: ExportResultType[];
  loading?: boolean;
  disabled?: boolean;
}

const ExportButton = ({ types, loading, disabled }: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettingsExportRequest());
  }, [dispatch]);

  const isSettingsUnset = useSelector(isExportSettingsUnset);

  const handleChannelClick = (channel: ExportChannel) => () =>
    dispatch(startMultiExportRequest(types, channel));

  return (
    <SplitButton
      loading={loading}
      disabled={disabled}
      options={[
        {
          label: t('distributor.export.buttonLabel'),
          onClick: handleChannelClick('download'),
        },
        {
          label: t('distributor.export.buttonSendLabel'),
          onClick: handleChannelClick('send'),
          disabled: isSettingsUnset,
          tooltip: t('distributor.export.buttonSendTooltip'),
        },
      ]}
    />
  );
};
