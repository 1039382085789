import React from 'react';
import { Polygon, useGoogleMap } from '@react-google-maps/api';
import { DataAreas } from '@rtt-libs/types';

import AreaLabel from './AreaLabel';
import { POLYGON_COLORS } from './constant';

type Props = {
  dataAreas: DataAreas[];
};

const DEFAULT_OPTIONS = {
  fillColor: POLYGON_COLORS.view,
  strokeColor: POLYGON_COLORS.viewStroke,
  fillOpacity: 0.25,
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  geodesic: false,
  zIndex: 1,
};

const extendBoundByPaths = (
  bounds: google.maps.LatLngBounds,
  area: DataAreas['polygon'],
) => {
  area.forEach(path => bounds.extend(path));
};

const getCenter = (
  mapInstance: google.maps.Map | null,
  areaToCenter: DataAreas['polygon'],
) => {
  if (mapInstance) {
    const bounds = new window.google.maps.LatLngBounds();
    extendBoundByPaths(bounds, areaToCenter);

    return bounds.getCenter();
  }

  return areaToCenter[0];
};

const Polygons: React.FC<Props> = ({ dataAreas }) => {
  const map = useGoogleMap();

  const renderPolygons = () => {
    return (
      <>
        {dataAreas.map(({ id, title, polygon }) => {
          return (
            <React.Fragment key={`PolygonKey-${id}`}>
              <Polygon options={DEFAULT_OPTIONS} path={polygon} />
              <AreaLabel position={getCenter(map, polygon)}>{title}</AreaLabel>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return renderPolygons();
};

export default Polygons;
