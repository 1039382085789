/* eslint-disable @typescript-eslint/camelcase */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { sendMassMailAPI } from '../../api/sendMassMail';
import * as actions from './actions';
import * as types from './types';

function* sendMassMailWorker({
  payload,
}: ReturnType<typeof actions.sendMassMailRequest>) {
  try {
    yield call(sendMassMailAPI, payload);

    yield all([put(actions.sendMassMailSuccess())]);
  } catch (e) {
    yield put(actions.sendMassMailFailure(e));
  }
}

export default function* watcher() {
  yield takeLatest(types.CHATS_MASS_MAIL_REQUEST, sendMassMailWorker);
}
