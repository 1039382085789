/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import {
  AttachmentDocumentType,
  CategoryItem,
  DataWrapped,
  Measurement,
  OrderParam,
  Paginated,
} from '@rtt-libs/types';
import trim from 'lodash/fp/trim';
import { isNullOrUndefined } from 'util';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { Brand, FetchedBrandAPI } from './mappers';

type StatisticsStatus = 'finished' | 'in_progress' | 'error';

type FetchedAssortmentStatistics = {
  created_qty: number;
  updated_qty: number;
  skipped_qty: number;
  invalid_qty: number;
  status: StatisticsStatus;
  message?: string;
};

export class AssortmentStatistics {
  created: number;
  updated: number;
  skipped: number;
  invalid: number;
  status: StatisticsStatus;
  message?: string;

  constructor(fetched: FetchedAssortmentStatistics) {
    this.created = fetched.created_qty;
    this.updated = fetched.updated_qty;
    this.skipped = fetched.skipped_qty;
    this.invalid = fetched.invalid_qty;
    this.message = fetched.message;
    this.status = fetched.status;
  }
}

type Manufacturer = string | null;

export type Currency = {
  id: number;
  title: string;
  sign: string;
};

export type FetchedProduct = {
  id: string;
  sku: string;
  is_active: boolean;
  is_invalid: boolean;
  title: string;
  description: string | null;
  weight: number | null;
  price: number | null;
  expiration_days: number | null;
  manufacturer: Manufacturer;
  category?: CategoryItem;
  price_currency: Currency;
  sale_measurement?: Measurement;
  image?: AttachmentDocumentType;
  additional_info: Record<string, unknown> | null;
  is_revocable: boolean;
  orders?: string[];
  brand?: FetchedBrandAPI | null;
  brand_id?: FetchedBrandAPI['id'];
};

export class Product {
  id: string;
  sku: string;
  isActive: boolean;
  isInvalid: boolean;
  title: string;
  description: string;
  weight?: number | null;
  price?: number;
  expirationDays?: number;
  manufacturer: Manufacturer;
  category?: CategoryItem;
  priceCurrency: Currency;
  saleMeasurement?: Measurement;
  image?: AttachmentDocumentType;
  additionalInfo: Record<string, unknown> | null;
  isRevocable: boolean;
  orders?: string[];
  brand?: Brand;
  brandId: Brand['id'];
  // Added to make compatible to Record<string, unknown> (used in Tables)
  [key: string]: unknown;

  constructor(fetchedProduct: FetchedProduct) {
    this.id = fetchedProduct.id;
    this.sku = fetchedProduct.sku;
    this.isActive = fetchedProduct.is_active;
    this.isInvalid = fetchedProduct.is_invalid;
    this.title = fetchedProduct.title;
    this.description = fetchedProduct.description ?? '';
    this.weight = fetchedProduct.weight ?? undefined;
    this.price = fetchedProduct.price ?? undefined;
    this.expirationDays = fetchedProduct.expiration_days ?? undefined;
    this.manufacturer = fetchedProduct.manufacturer;
    this.category = fetchedProduct.category;
    this.priceCurrency = fetchedProduct.price_currency;
    this.saleMeasurement = fetchedProduct.sale_measurement;
    this.image = fetchedProduct.image;
    this.additionalInfo = fetchedProduct.additional_info;
    this.isRevocable = fetchedProduct.is_revocable;
    this.orders = fetchedProduct.orders;
    this.brand = fetchedProduct.brand
      ? new Brand(fetchedProduct.brand)
      : undefined;
    // TODO: quick fix - replace with actual brand id
    this.brandId = fetchedProduct.brand_id ?? fetchedProduct.brand?.id ?? 0;
  }
}

export type AssortmentGetParams = {
  perPage: number;
  page: number;
  sort: string;
  order: OrderParam;
  search: string;
  isActive: boolean;
  isInvalid: boolean;
  brandId: number;
};

export type ProductUpdate = {
  sku: string;
  title: string;
  description: string;
  weight?: number | null;
  price: number;
  expirationDays: number;
  manufacturer: Manufacturer;
  categoryId?: CategoryItem['id'];
  priceCurrencyId: Currency['id'];
  saleMeasurement: Measurement;
  image: File[];
  additionalInfo: Record<string, unknown>;
  isActive: boolean;
  isRevocable: boolean;
  brand?: { id: number; name: string; isActive: boolean };
};

export const getUploadAssortmentStatistics = () =>
  api
    .get<FetchedAssortmentStatistics>(ENDPOINTS.uploadAssortment)
    .then(({ data }) => new AssortmentStatistics(data));

export const getAssortment = (params: Partial<AssortmentGetParams> = {}) =>
  api
    .get<Paginated<FetchedProduct>>(ENDPOINTS.assortment, {
      params: mapAssortmentGetParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, bulkMapProduct));

export const getProduct = (id: Product['id']) =>
  api
    .get<FetchedProduct>(`${ENDPOINTS.assortment}/${id}`)
    .then(({ data }) => new Product(data));

export const updateProduct = async (
  product: Partial<ProductUpdate>,
  id?: Product['id'],
) => {
  const formData = new FormData();
  const mappedProduct = mapProductUpdate(product);

  Object.entries(mappedProduct).forEach(([key, value]) => {
    if (!isNullOrUndefined(value)) {
      if (value instanceof Blob || typeof value === 'string') {
        formData.append(key, value);
      } else if (typeof value === 'boolean') {
        formData.append(key, value ? '1' : '0');
      } else if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value.toString());
      }
    }
    // TODO: check how to set undefined value in common ifElse
    if (key === 'description' && value === undefined) {
      formData.append(key, '');
    }
  });

  try {
    const { data } = await api.post<FetchedProduct>(
      id ? `${ENDPOINTS.assortment}/${id}` : ENDPOINTS.assortment,
      formData,
    );
    return new Product(data);
  } catch (e) {
    throw formErrorTransform(e, transformUpdateProductErrors);
  }
};

export const getCurrencyList = () =>
  api
    .get<DataWrapped<Currency[]>>(ENDPOINTS.listCurrencies)
    .then(({ data }) => data.data);

export const getMeasurementList = () =>
  api
    .get<DataWrapped<Measurement[]>>(ENDPOINTS.listMeasurements)
    .then(({ data }) => data.data);

type UpdateProductAPIParams = {
  sku: string;
  title: string;
  manufacturer: Manufacturer;
  category_id: number | string;
  sale_measurement: Measurement;
  price_currency_id: number;
  image: File;
  weight: number | string;
  price: number;
  description: string;
  expiration_days: number;
  additional_info: Record<string, unknown>;
  is_active: boolean;
  is_revocable: boolean;
  brand_id: number;
};

const transformUpdateProductErrors: TransformAPIError<
  Record<keyof UpdateProductAPIParams, string | string[]>,
  Record<keyof ProductUpdate, string>
> = function transformUpdateProductErrors(e) {
  return {
    sku: e.sku && e.sku.toString(),
    title: e.title && e.title.toString(),
    manufacturer: e.manufacturer && e.manufacturer.toString(),
    categoryId: e.category_id && e.category_id.toString(),
    saleMeasurement: e.sale_measurement && e.sale_measurement.toString(),
    priceCurrencyId: e.price_currency_id && e.price_currency_id.toString(),
    image: e.image && e.image.toString(),
    weight: e.weight && e.weight.toString(),
    price: e.price && e.price.toString(),
    description: e.description && e.description.toString(),
    expirationDays: e.expiration_days && e.expiration_days.toString(),
    additionalInfo: e.additional_info && e.additional_info.toString(),
    isActive: e.is_active && e.is_active.toString(),
    isRevocable: e.is_revocable && e.is_revocable.toString(),
    brand: e.brand_id?.toString(),
  };
};

function mapAssortmentGetParams({
  perPage,
  page,
  sort,
  order,
  search,
  isActive,
  isInvalid,
}: Partial<AssortmentGetParams>) {
  return {
    per_page: perPage,
    page,
    sort,
    order,
    search: (typeof search === 'string' && trim(search)) || undefined,
    is_active: isActive === undefined ? undefined : (isActive && 1) || 0,
    is_invalid: isInvalid === undefined ? undefined : (isInvalid && 1) || 0,
  };
}

function bulkMapProduct(distrArray: FetchedProduct[]): Product[] {
  return distrArray.map(product => new Product(product));
}

function mapProductUpdate(
  productValues: Partial<ProductUpdate>,
): Partial<UpdateProductAPIParams> {
  return {
    sku: productValues.sku,
    title: productValues.title,
    manufacturer: productValues.manufacturer,
    category_id: productValues.categoryId,
    sale_measurement: productValues.saleMeasurement,
    price_currency_id: productValues.priceCurrencyId,
    image: productValues.image && productValues.image[0],
    weight: productValues.weight || '',
    price: productValues.price,
    description: productValues.description,
    expiration_days: productValues.expirationDays,
    additional_info: productValues.additionalInfo,
    is_active: productValues.isActive,
    is_revocable: productValues.isRevocable,
    brand_id: productValues.brand?.id,
  };
}
