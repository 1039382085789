/* eslint-disable @typescript-eslint/camelcase */
import { genKeyName } from '@rtt-libs/auth';
import { difference, isEqual, keys, mapValues } from 'lodash/fp';
import type { Task } from 'redux-saga';
import { all, call, fork, join, put, select } from 'redux-saga/effects';
import {
  createOrder,
  createReturnOrder,
  OrderedPayloadProduct,
  ReturnedPayloadProduct,
  updateOrder,
  updateReturnOrder,
} from '../../../api/ordersRefactored';
import logger from '../../../utils/logger';
import { PAYLOAD_GROUPED_KEY } from '../../constants';
import type * as OrderTypes from '../../types';
import { parseOrderGroupIdKey } from '../../view/commonOrders';
import * as actions from '../actions';
import {
  selectOrderPayload,
  selectReturnOrderDetailsById,
  selectSingleOrder,
} from '../selectors';
import { encryptPayload, getKeysByRttWorker } from './encryptionWorkers';

export function* editOrderWorker({
  payload: formValues,
}: ReturnType<typeof actions.editOrderRequest>) {
  try {
    const { data: order }: { data: OrderTypes.Order } = yield select(
      selectSingleOrder(formValues.id),
    );

    // XXX: there isn't a case when 2 orders should be edited at the same time
    const formValuesComments = Object.values(formValues[PAYLOAD_GROUPED_KEY])
      .map(data => data?.managerDescription)
      .join('\n');

    const {
      secrets,
      payloadEncrypted,
    } = yield* prepareEncryptedPayloadOfEditedOrder(
      OrderedPayloadProduct,
      formValues,
    );

    const isPayloadUpdated = !!payloadEncrypted;

    const updatedOrder: OrderTypes.Order = yield call(
      updateOrder,
      formValues.id,
      {
        distributorId: order.distributorId,
        shippingDate: formValues.shippingDate,
        // TODO: check, if undefined payload value create empty string after encryption. Otherwise change prepare generator
        // payload: orderPayload ? payloadEncrypted : order.payload,
        payload: isPayloadUpdated ? payloadEncrypted : order.payload,
        managerDescription: formValuesComments,
      },
      secrets,
    );

    yield all([
      put(actions.editOrderSuccess(updatedOrder)),
      put(actions.decryptOrderTotalRequest([updatedOrder.id])),
      put(actions.decryptOrderOriginTotalRequest([updatedOrder.id])),
      put(actions.decryptOrderPayloadRequest([updatedOrder.id])),
    ]);

    if (isPayloadUpdated) {
      yield put(actions.getAvailableStatusesRequest(updatedOrder.id));
    }
  } catch (e) {
    yield put(actions.editOrderFailure(e));
  }
}

export function* editReturnOrderWorker({
  payload: formValues,
}: ReturnType<typeof actions.editReturnRequest>) {
  try {
    const { data: order }: { data: OrderTypes.ReturnOrder } = yield select(
      selectReturnOrderDetailsById(formValues.id),
    );

    // XXX: there isn't a case when 2 orders should be edited at the same time
    const formValuesComments = Object.values(formValues[PAYLOAD_GROUPED_KEY])
      .map(data => data?.managerDescription)
      .join('\n');

    const {
      secrets,
      payloadEncrypted,
    } = yield* prepareEncryptedPayloadOfEditedOrder(
      ReturnedPayloadProduct,
      formValues,
    );

    const isPayloadUpdated = !!payloadEncrypted;

    const updatedOrder: OrderTypes.ReturnOrder = yield call(
      updateReturnOrder,
      formValues.id,
      {
        distributorId: order.distributorId,
        shippingDate: formValues.shippingDate,
        // TODO: check, if undefined payload value create empty string after encryption. Otherwise change prepare generator
        // payload: orderPayload ? payloadEncrypted : order.payload,
        payload: isPayloadUpdated ? payloadEncrypted : order.payload,
        managerDescription: formValuesComments,
      },
      secrets,
    );

    yield all([
      put(actions.editReturnSuccess(updatedOrder)),
      put(actions.decryptOrderTotalRequest([updatedOrder.id])),
      put(actions.decryptOrderOriginTotalRequest([updatedOrder.id])),
      put(actions.decryptOrderPayloadRequest([updatedOrder.id])),
    ]);

    if (isPayloadUpdated) {
      yield put(actions.getAvailableStatusesRequest(updatedOrder.id));
    }
  } catch (e) {
    yield put(actions.editReturnFailure(e));
  }
}

function* prepareEncryptedPayloadOfEditedOrder(
  ProductConstructor:
    | (new (product: OrderTypes.OrderedProduct) => OrderedPayloadProduct)
    | (new (product: OrderTypes.ReturnedProduct) => ReturnedPayloadProduct),
  formValues: OrderTypes.OrderEditValues,
  // FIXME: split typings
) {
  const {
    data: initialPayload /* decrypted */,
  }: {
    data: OrderTypes.DecryptedPayload<OrderTypes.OrderedProduct>;
  } = yield select(selectOrderPayload(formValues.id));

  // XXX: there isn't a case when 2 orders should be edited at the same time
  const formValueProducts = Object.values(
    formValues[PAYLOAD_GROUPED_KEY],
  ).reduce(
    (prev, data) => ({ ...prev, ...data?.payload?.products }),
    {} as Record<string, OrderTypes.OrderedProduct>,
  );

  const isProductsChanged = !isEqual(
    initialPayload.products,
    formValueProducts,
  );

  let orderPayload:
    | {
        products: OrderTypes.OrderedPayloadProduct[];
        shop_info?: OrderTypes.ShopInfo;
        [key: string]: unknown;
      }
    | undefined;

  if (isProductsChanged) {
    const {
      products: initialProducts,
      shopInfo,
      ...restPayload
    } = initialPayload;

    const addedProductIds = getAddedProductIds(
      formValueProducts,
      initialProducts,
    );

    orderPayload = {
      products: mapProductsToPayloadProducts(
        ProductConstructor,
        markProductAsAddedByManager(formValueProducts, addedProductIds),
      ),
      shop_info: shopInfo,
      ...restPayload,
    };

    // TODO: [testing] remove after testing of orders & returns
    logger(orderPayload);
  }

  /*
   * If products values or qty wasn't changed, used initial encoded payload,
   * but generate secret check for assign by active key
   *
   * TODO: add check and refresh payload encoded value if key was dead
   */
  const { secrets, payloadEncrypted } = yield* encryptPayload(
    formValues.id,
    orderPayload,
  );

  return {
    secrets,
    payloadEncrypted: isProductsChanged ? payloadEncrypted : '',
  };
}

function getAddedProductIds(
  edited: Record<string, OrderTypes.OrderedProduct>,
  initial: Record<string, OrderTypes.OrderedProduct>,
): string[] {
  return difference(keys(edited), keys(initial));
}

function markProductAsAddedByManager<T extends OrderTypes.OrderedProduct>(
  products: Record<string, T>,
  ids: string[] = [],
) {
  return mapValues(product => {
    if (ids.includes(product.id)) {
      return {
        ...product,
        addedByManager: true,
      };
    }
    return product;
  }, products);
}

function mapProductsToPayloadProducts<
  T extends OrderedPayloadProduct,
  U extends OrderTypes.OrderedProduct
>(
  ProductConstructor: new (product: U) => T,
  products: Record<string, U> | Record<string, U>,
): T[] {
  return Object.values(products).map(product => {
    const resultProduct = new ProductConstructor(product);
    if (product.saleMeasurement === 'unit') {
      resultProduct.qty = +product.qty;
      resultProduct.order_weight = product.qty * (product.weight ?? 0);
    }
    if (product.saleMeasurement === 'weight') {
      resultProduct.order_weight = +product.orderWeight;
      if (product.weight) {
        resultProduct.qty = Math.round(product.orderWeight / product.weight);
      }
    }
    return resultProduct;
  });
}

export function* createOrderWorker({
  payload: formValues,
}: ReturnType<typeof actions.createOrderRequest>) {
  try {
    yield* iterativeOrderCreate(
      OrderedPayloadProduct,
      requestCreateOrder,
      formValues,
    );

    yield put(actions.createOrderSuccess());
  } catch (e) {
    yield put(actions.createOrderFailure(e));
  }
}

export function* createReturnOrderWorker({
  payload: formValues,
}: ReturnType<typeof actions.createReturnRequest>) {
  try {
    yield* iterativeOrderCreate(
      ReturnedPayloadProduct,
      requestCreateReturnOrder,
      formValues,
    );

    yield put(actions.createReturnSuccess());
  } catch (e) {
    yield put(actions.createReturnFailure(e));
  }
}

function* iterativeOrderCreate<
  T extends OrderTypes.OrderedPayloadProduct,
  U extends OrderTypes.OrderedProduct,
  FV extends OrderTypes.OrderCreateValues<U>
>(
  ProductConstructor: new (product: U) => T,
  requestSaga: (
    encryptedOrder: OrderTypes.NewOrder,
    secrets: OrderTypes.SecretCheck,
    orderId: T extends OrderTypes.ReturnedPayloadProduct ? string : undefined,
  ) => void,
  formValues: FV,
) {
  // XXX: Used task for parallel execution of sagas
  // encrypt next order while submitting to API
  let requestForkTask: Task | undefined;

  const { rttId, byGroupId, ...otherValues } = formValues;

  // eslint-disable-next-line no-restricted-syntax
  for (const groupKey in byGroupId) {
    if (Object.prototype.hasOwnProperty.call(byGroupId, groupKey)) {
      const [managerId, orderId] = parseOrderGroupIdKey(groupKey);

      const { payload, managerDescription, suggestOrderId } = byGroupId[
        groupKey
      ];
      const { products, shopInfo, ...restPayload } = payload;

      const orderPayload: {
        products: T[];
        shop_info?: OrderTypes.ShopInfo;
        [key: string]: unknown;
      } = {
        products: mapProductsToPayloadProducts<T, U>(
          ProductConstructor,
          markProductAsAddedByManager(payload.products, keys(payload.products)),
        ),
        shop_info: shopInfo,
        ...restPayload,
      };

      // TODO: [testing] remove after testing of orders & returns
      logger(orderPayload);

      const {
        secrets,
        payloadEncrypted,
      }: {
        secrets: OrderTypes.SecretCheck;
        payloadEncrypted: string;
      } = yield encryptPayload(
        genKeyName(rttId, managerId),
        orderPayload,
        getKeysByRttWorker,
      );

      requestForkTask = yield parallelizeRequests(
        requestForkTask,
        requestSaga,
        {
          ...otherValues,
          suggestOrderId,
          payload: payloadEncrypted,
          managerDescription,
        },
        secrets,
        (orderId || undefined) as T extends OrderTypes.ReturnedPayloadProduct
          ? string
          : undefined,
      );
    }
  }

  if (requestForkTask) yield join(requestForkTask);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* parallelizeRequests<Fn extends (...args: any[]) => any>(
  requestForkTask: Task | undefined,
  requestSaga: Fn,
  ...args: Parameters<Fn>
) {
  if (requestForkTask) {
    yield join(requestForkTask);
  }

  return yield fork<Fn>(requestSaga, ...args);
}

function* requestCreateOrder(
  encryptedOrder: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
) {
  const createdOrder: OrderTypes.Order = yield call(
    createOrder,
    encryptedOrder,
    secrets,
  );

  yield put(actions.createOrderByManagerSuccess(createdOrder));
  yield put(actions.decryptOrderTotalRequest([createdOrder.id]));
}

function* requestCreateReturnOrder(
  encryptedOrder: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
  orderId: string,
) {
  const createdOrder: OrderTypes.ReturnOrder = yield call(
    createReturnOrder,
    orderId,
    encryptedOrder,
    secrets,
  );

  yield put(actions.createReturnByManagerSuccess(createdOrder));
  yield put(actions.decryptOrderTotalRequest([createdOrder.id]));
}
