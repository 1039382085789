import { Button } from '@material-ui/core';
import { LoadingButton } from '@rtt-libs/views';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const Controls = () => {
  const [t] = useTranslation();
  const {
    submitting,
    pristine,
    dirty,
    hasValidationErrors,
    hasSubmitErrors,
    dirtySinceLastSubmit,
  } = useFormState();
  const form = useForm();

  return (
    <>
      <LoadingButton
        loading={submitting}
        type="submit"
        variant="contained"
        color="primary"
        disabled={
          hasValidationErrors ||
          pristine ||
          submitting ||
          (hasSubmitErrors && !dirtySinceLastSubmit)
        }
      >
        {t('controls.save')}
      </LoadingButton>

      {dirty && (
        <Button
          type="button"
          onClick={() => form.reset()}
          disabled={submitting || pristine}
        >
          {t('controls.reset')}
        </Button>
      )}
    </>
  );
};

export default Controls;
