/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, useState } from 'react';
import { FormHelperText, makeStyles, createStyles } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'none',
    },
  }),
);

const LogotypeDownload: React.FC<
  FieldRenderProps<File> & {
    inputProps?: { [k: string]: unknown };
  }
> = ({ input, meta, inputProps, ...rest }) => {
  const classes = useStyles();
  const [validationError, setValidationError] = useState<string | null>(null);
  const { onBlur, onFocus } = input;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValidationError(null);
    const { files } = event.target;
    input.onChange((files as FileList)[0]);
  };

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <>
      <input
        onBlur={onBlur}
        onFocus={onFocus}
        {...rest}
        type="file"
        onChange={onChange}
        className={classes.root}
        {...inputProps}
      />

      {(showError || validationError) && (
        <FormHelperText error>
          {meta.error || meta.submitError || validationError}
        </FormHelperText>
      )}
    </>
  );
};

export default LogotypeDownload;
