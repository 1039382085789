import pickBy from 'lodash/pickBy';
import React, { useCallback, useState } from 'react';
import { BrandSearchParams } from '../types';
import BrandsTable from './BrandsTable';
import { useBrands } from './shared';

const BrandListContainer: React.FC = () => {
  const [state, changeState] = useState<Partial<BrandSearchParams>>();

  const { data, loading } = useBrands(state);

  const onTableStateChange = useCallback(
    (options: Partial<BrandSearchParams>) => {
      changeState(prevState =>
        pickBy({ ...prevState, ...options }, (v: unknown) => v !== undefined),
      );
    },
    [],
  );

  return (
    <BrandsTable
      brands={data}
      loading={loading}
      onStateChange={onTableStateChange}
    />
  );
};

export default BrandListContainer;
