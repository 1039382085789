import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ProductInfo } from '../../types';

type ProductListWithClickCallbackProps = {
  data: ProductInfo[];
  existingIds: string[];
  onClick(product: ProductInfo): void;
};

const ProductListWithClickCallback = ({
  data,
  existingIds,
  onClick,
}: ProductListWithClickCallbackProps) => {
  const [t] = useTranslation();

  return (
    <List>
      {data.map(product => {
        const labelId = `product-${product.sku}`;

        return (
          <ListItem
            key={product.id}
            role={undefined}
            dense
            button
            onClick={() => onClick(product)}
            disabled={existingIds.includes(product.id)}
          >
            <ListItemText
              id={labelId}
              primary={product.title}
              secondary={`${t('product.skuContracted')}: ${product.sku}`}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ProductListWithClickCallback;
