import {
  Box,
  Button,
  ButtonGroup,
  FormHelperText,
  IconButton,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import { restrictions } from '@rtt-libs/constants';
import { TFunction } from 'i18next';
import round from 'lodash/round';
import React, { useContext } from 'react';
import { Field, FieldInputProps, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EnhancedProduct } from '../../../api/orders';
import EditContext from '../OrderDetails/EditContext';

const roundInputValue = (value: number) => round(value, 3);

type Props = {
  id: string | number | symbol;
  value?: unknown;
  onRemoveItem?: (productIds: string[], orderId?: string) => void;
  ignoreWeight?: boolean;
  forceEnable?: boolean;
  fieldNamePrefix?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPadding: {
      padding: theme.spacing(1),
      '&:last-of-type': {
        borderLeft: 'none',
      },
    },
    input: {
      borderRadius: 0,
      textAlign: 'center',
      minWidth: 56,
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&.Mui-disabled': {
        color: theme.palette.text.primary,
      },
    },
  }),
);

const RemoveRippleProps = ({ children }: { children: React.ReactElement }) => {
  const child = React.Children.only(children);

  const { disableFocusRipple, disableRipple, ...childProps } = child.props;
  return React.createElement(child.type, childProps);
};

const NumberInput: React.FC<
  Omit<OutlinedInputProps, 'labelWidth' | 'onChange' | 'value'> & {
    value?: number;
    helperText?: string;
    error?: boolean;
    onChange: FieldInputProps<number>['onChange'];
    stepMultiplier?: number;
  }
> = ({
  name,
  helperText,
  error,
  inputProps,
  onChange,
  value,
  disabled,
  endAdornment,
  stepMultiplier = 1,
}) => {
  const classes = useStyles();

  const parsedValue = value ? parseFloat(String(value)) : 0;
  const step = (inputProps?.step || 1) * stepMultiplier;

  return (
    <>
      <ButtonGroup variant="outlined" disabled={disabled}>
        <Button
          className={classes.buttonPadding}
          onClick={() => {
            if (parsedValue > (inputProps?.max || Infinity)) {
              return onChange(inputProps?.max || parsedValue);
            }
            return onChange(
              roundInputValue(
                Math.ceil(roundInputValue(parsedValue / step - 1)) * step,
              ),
            );
          }}
          disabled={!value || value <= (inputProps?.min || 0)}
        >
          <RemoveIcon />
        </Button>

        <RemoveRippleProps>
          <OutlinedInput
            name={name}
            labelWidth={0}
            className={classes.input}
            value={value}
            disabled={disabled}
            margin="dense"
            inputProps={{
              'aria-label': 'description',
              min: 0,
              step: 1,
              className: classes.input,
              ...inputProps,
            }}
            type="number"
            onChange={onChange}
            endAdornment={endAdornment}
          />
        </RemoveRippleProps>

        <Button
          className={classes.buttonPadding}
          onClick={() => {
            onChange(
              parsedValue < 0
                ? inputProps?.min || 0
                : roundInputValue(
                    Math.floor(roundInputValue(parsedValue / step + 1)) * step,
                  ),
            );
          }}
          disabled={parsedValue >= inputProps?.max}
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
      <Box position="absolute">
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Box>
    </>
  );
};

export const NumberInputAdapter: React.FC<
  FieldRenderProps<number> & {
    inputProps?: { [k: string]: unknown };
    stepMultiplier?: number;
  }
> = props => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    inputProps = {},
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const innerInputProps = {
    ...restInput,
    ...inputProps,
  };

  return (
    <NumberInput
      {...rest}
      name={name}
      helperText={showError ? meta.error || meta.submitError : ''}
      error={showError}
      inputProps={innerInputProps}
      onChange={onChange}
      value={value}
    />
  );
};

const NumberInputField: React.FC<Props> = ({
  id,
  value,
  onRemoveItem,
  ignoreWeight,
  forceEnable,
  fieldNamePrefix = 'payload.products',
}) => {
  const product = value as EnhancedProduct;
  const orderId = useContext(EditContext);
  const [t] = useTranslation();

  const handleRemoveProduct = () => {
    // eslint-disable-next-line no-unused-expressions
    onRemoveItem?.([product.id], orderId ?? undefined);
  };

  let step = 1;
  let fieldPart = 'qty';
  let defaultValue = product?.maxQty;
  let adornment = t('product.values.unit');
  let minValue = 1;
  if (product.saleMeasurement === 'weight') {
    fieldPart = 'orderWeight';
    defaultValue = product?.maxOrderWeight || defaultValue;
    step = ignoreWeight ? 0.001 : product.weight ?? 0.001;
    adornment = t('product.values.weight');
    minValue = 0.001;
  }

  const isFieldDisabled = !forceEnable && !orderId;

  const fieldName = `${fieldNamePrefix}.${id.toString()}.${fieldPart}`;

  return (
    <Box position="relative">
      <Field
        name={fieldName}
        type="number"
        component={NumberInputAdapter}
        defaultValue={defaultValue}
        inputProps={{
          'aria-label': 'qty',
          step,
          min: minValue || 0,
          max: restrictions.PRODUCT_QTY_MAX_VALUE && defaultValue,
          required: true,
        }}
        endAdornment={adornment}
        disabled={isFieldDisabled || !defaultValue}
        required
        // TODO: used custom validation because of bug in react-final-form-html5-validation
        // @see https://github.com/final-form/react-final-form-html5-validation/issues/13
        validate={validateCountInput(
          t,
          true,
          step,
          minValue,
          restrictions.PRODUCT_QTY_MAX_VALUE && defaultValue,
        )}
      />
      {product.manuallyAdded && (
        <Box position="absolute" top="0" left="-3rem">
          <IconButton onClick={handleRemoveProduct}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default NumberInputField;

export function validateCountInput(
  t: TFunction,
  required?: boolean,
  step?: number,
  minValue = 0,
  maxValue = restrictions.PRODUCT_QTY_MAX_VALUE,
) {
  return (v: number) => {
    if (required && v === undefined) return t('validation.valueMissing');
    if (Number.isNaN(parseFloat(String(v)))) return t('validation.badInput');
    if (step !== undefined && round(v / step, 5) % 1)
      return t('validation.stepMismatch');
    if (v < minValue) return t('validation.rangeUnderflow');
    if (v > maxValue) return t('validation.rangeOverflow');
    return undefined;
  };
}
