/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import { OrderParam, Paginated } from '@rtt-libs/types';
import snakeCase from 'lodash/snakeCase';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { RttPriceTypeSetValue } from '../priceTypes/types';

type PriceTypeId = number;

type FetchedPriceType = {
  id: PriceTypeId;
  title: string;
  coefficient: number;
  created_at: string;
};

export type CreatePriceTypeValues = {
  title: string;
  coefficient: number;
};

export type EditPriceTypeValues = {
  id: PriceTypeId;
  title: string;
  coefficient: number;
};

export type DeletePriceTypeValues = {
  id: PriceTypeId;
};

type SortValue = 'title' | 'coefficient';

export type SearchParams = Partial<{
  orderBy: string;
  title: string;
  order: OrderParam;
  sort: SortValue | string;
  perPage: number;
  page: number;
}>;

export type GetAllParams = Partial<{
  perPage: number;
  page: number;
}>;

type APISearchParams = Partial<{
  title?: string;
  order?: OrderParam;
  sort?: SortValue | string;
  per_page?: number;
  page?: number;
}>;

export class PriceType {
  id: PriceTypeId;
  title: string;
  coefficient: number;
  createdAt: string;
  [key: string]: unknown;

  constructor(priceType: FetchedPriceType) {
    this.id = priceType.id;
    this.title = priceType.title;
    this.coefficient = priceType.coefficient;
    this.createdAt = priceType.created_at;
  }
}

export interface FetchedRttPriceType {
  /** PriceType assign link id */
  id: string;
  price_type: FetchedPriceType;
  pricetype_id: PriceTypeId;
  rtt_id: number;
}

export interface FetchedRttPriceTypeField {
  price_type?: FetchedRttPriceType;
}

const mapPriceTypesArray = (priceTypesArray: FetchedPriceType[]) =>
  priceTypesArray.map(priceType => new PriceType(priceType));

export const getAllPriceTypes = () =>
  api
    .get<{ data: FetchedPriceType[] }>(ENDPOINTS.priceTypes)
    .then(({ data }) => mapPriceTypesArray(data.data));

export const searchPriceTypes = (params: GetAllParams) =>
  api
    .get<Paginated<FetchedPriceType>>(ENDPOINTS.priceTypesSearch, {
      params: mapAPISearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapPriceTypesArray));

export const createPriceType = (values: CreatePriceTypeValues) =>
  api
    .post<FetchedPriceType>(
      ENDPOINTS.priceTypesSearch,
      mapCreatePriceTypeValues(values),
    )
    .then(({ data }) => new PriceType(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateEmployeeErrors);
    });

export const editPriceType = (values: EditPriceTypeValues) =>
  api
    .put<FetchedPriceType>(
      `${ENDPOINTS.priceTypesSearch}/${values.id}`,
      mapEditPriceTypeValues(values),
    )
    .then(({ data }) => new PriceType(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateEmployeeErrors);
    });

export const deletePriceType = (values: DeletePriceTypeValues) =>
  api
    .delete<FetchedPriceType>(`${ENDPOINTS.priceTypesSearch}/${values.id}`)
    .catch(e => {
      throw formErrorTransform(e, transformCreateEmployeeErrors);
    });

export const setRttPriceType = (list: RttPriceTypeSetValue[]) =>
  api
    .post<{ status: string }>(ENDPOINTS.priceTypesRttAssign, {
      list: mapRttPriceTypes(list),
    })
    .catch(e => {
      throw formErrorTransform(e, transformRttPriceTypeErrors);
    });

function mapAPISearchParams(params: SearchParams): APISearchParams {
  return {
    title: params.title,
    sort: params.orderBy && snakeCase(params.orderBy),
    order: params.order,
    per_page: params.perPage,
    page: params.page,
  };
}

function mapCreatePriceTypeValues(
  values: CreatePriceTypeValues,
): CreatePriceTypeValues {
  return {
    title: values.title,
    coefficient: values.coefficient,
  };
}

function mapEditPriceTypeValues(
  values: EditPriceTypeValues,
): EditPriceTypeValues {
  return {
    id: values.id,
    title: values.title,
    coefficient: values.coefficient,
  };
}

function mapRttPriceTypes(list: RttPriceTypeSetValue[]) {
  return list.map(({ rttId, priceTypeId }) => ({
    rtt_id: rttId,
    pricetype_id: priceTypeId,
  }));
}

const transformCreateEmployeeErrors: TransformAPIError<
  Record<keyof CreatePriceTypeValues, string | string[]>,
  Record<keyof CreatePriceTypeValues, string>
> = function transformCreateEmployeeErrors(e) {
  return {
    title: e.title?.toString(),
    coefficient: e.coefficient?.toString(),
  };
};

const transformRttPriceTypeErrors: TransformAPIError<
  Record<string, string | string[]>,
  Record<'priceTypeId', string>
> = function transformRttPriceTypeErrors(e) {
  return {
    priceTypeId: Object.values(e)[0]?.toString(),
  };
};
