import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsExportRequest } from '../../settings/duck/actions';
import { isExportSettingsUnset } from '../../settings/duck/selectors';
import { startExportRequest } from '../duck/actions';
import { ExportResultType } from '../types';
import SplitButton from './components/SplitButton';

interface Props {
  type: ExportResultType;
  loading?: boolean;
  processing?: boolean;
}

const ExportButton = ({ type, loading, processing }: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettingsExportRequest());
  }, [dispatch]);

  const isSettingsUnset = useSelector(isExportSettingsUnset);

  return (
    <SplitButton
      loading={loading || processing}
      options={[
        {
          label: t('distributor.export.buttonLabel'),
          onClick: () => dispatch(startExportRequest(type, 'download')),
        },
        {
          label: t('distributor.export.buttonSendLabel'),
          onClick: () => dispatch(startExportRequest(type, 'send')),
          disabled: isSettingsUnset,
          tooltip: t('distributor.export.buttonSendTooltip'),
        },
      ]}
    />
  );
};

export default ExportButton;
