import { Pagination } from '@rtt-libs/types';
import { keyBy } from 'lodash/fp';
import { Reducer } from 'redux';
import type { AssignedManager, Supervisor } from '../types';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: true,
  collection: {} as Record<Supervisor['id'], Supervisor>,
  error: null as string | null,
  idList: [] as Supervisor['id'][],
  pagination: {} as Pagination,

  availableManagers: [] as AssignedManager[],
  managersLoading: false,
  managersError: null as null | string,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.SUPERVISORS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.SUPERVISORS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(employee => employee.id),
        pagination: action.meta.pagination,
      };
    case types.SUPERVISORS_CREATE_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        idList: [action.payload.id, ...state.idList],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case types.SUPERVISORS_EDIT_SUCCESS:
    case types.SUPERVISORS_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };

    case types.SUPERVISORS_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.SUPERVISORS_GET_AVAILABLE_MANAGERS_REQUEST:
      return {
        ...state,
        managersLoading: true,
        managersError: null,
      };
    case types.SUPERVISORS_GET_AVAILABLE_MANAGERS_SUCCESS:
      return {
        ...state,
        managersLoading: false,
        availableManagers: action.payload,
      };
    case types.SUPERVISORS_GET_AVAILABLE_MANAGERS_FAILURE:
      return {
        ...state,
        managersLoading: false,
        managersError: action.payload,
      };
    case types.SUPERVISORS_GET_AVAILABLE_MANAGERS_CLEANUP:
      return {
        ...state,
        availableManagers: initialState.availableManagers,
        managersError: initialState.managersError,
        managersLoading: initialState.managersLoading,
      };

    default:
      return state;
  }
};

export default reducer;
