import type { OrderStatusValue, Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import type * as OrderTypes from '../types';
import * as types from './types';

export const getOrdersRequest = (params?: OrderTypes.OrderSearchParams) => ({
  type: types.ORDERS_GET_REQUEST,
  payload: params,
});

export const getOrdersSuccess = (
  payload: OrderTypes.Order[],
  meta: { pagination: Pagination },
) => ({
  type: types.ORDERS_GET_SUCCESS,
  payload,
  meta,
});

export const getOrdersFailure = (error: string) => ({
  type: types.ORDERS_GET_FAILURE,
  payload: error,
});

export const getOrderDetailsRequest = (orderId: string) => ({
  type: types.ORDER_DETAILS_GET_REQUEST,
  payload: orderId,
});

export const getOrderDetailsSuccess = (payload: OrderTypes.Order) => ({
  type: types.ORDER_DETAILS_GET_SUCCESS,
  payload,
});

export const getOrderDetailsFailure = (error: string) => ({
  type: types.ORDER_DETAILS_GET_FAILURE,
  payload: error,
});

export const getAvailableStatusesRequest = (orderId: string) => ({
  type: types.ORDER_STATUSES_GET_REQUEST,
  payload: orderId,
});

export const getAvailableStatusesSuccess = (payload: OrderStatusValue[]) => ({
  type: types.ORDER_STATUSES_GET_SUCCESS,
  payload,
});

export const getAvailableStatusesFailure = (error: string) => ({
  type: types.ORDER_STATUSES_GET_FAILURE,
  payload: error,
});

export const setOrderStatusRequest = (
  orderId: string,
  status: OrderStatusValue,
) => ({
  type: types.ORDER_STATUSES_SET_REQUEST,
  payload: { orderId, status },
});

export const setOrderStatusSuccess = (payload: OrderTypes.Order) => ({
  type: types.ORDER_STATUSES_SET_SUCCESS,
  payload,
});

export const setOrderStatusFailure = (error: string) => ({
  type: types.ORDER_STATUSES_SET_FAILURE,
  payload: error,
});

export const editOrderRequest = (values: OrderTypes.OrderEditValues) => ({
  type: types.ORDER_EDIT_REQUEST,
  payload: values,
});

export const editOrderSuccess = (payload: OrderTypes.Order) => ({
  type: types.ORDER_EDIT_SUCCESS,
  payload,
});

export const editOrderFailure = (
  submissionErrors: Partial<
    OrderTypes.OrderEditValues & { [FORM_ERROR]: string }
  >,
) => ({
  type: types.ORDER_EDIT_FAILURE,
  payload: submissionErrors,
});

export const getClosestDeliveryDayRequest = (
  rttId: OrderTypes.Order['rttId'],
) => ({
  type: types.ORDER_DELIVERY_CLOSES_DAY_REQUEST,
  payload: rttId,
});

export const getClosestDeliveryDaySuccess = (date: string) => ({
  type: types.ORDER_DELIVERY_CLOSES_DAY_SUCCESS,
  payload: date,
});

export const getClosestDeliveryDayFailure = (date: string, error?: string) => ({
  type: types.ORDER_DELIVERY_CLOSES_DAY_FAILURE,
  payload: date,
  meta: error,
});

export const createOrderRequest = (values: OrderTypes.OrderCreateValues) => ({
  type: types.ORDER_CREATE_REQUEST,
  payload: values,
});

export const createOrderByManagerSuccess = (payload: OrderTypes.Order) => ({
  type: types.ORDER_CREATE_BY_MANAGER_SUCCESS,
  payload,
});

export const createOrderSuccess = () => ({
  type: types.ORDER_CREATE_SUCCESS,
});

export const createOrderFailure = (
  submissionErrors: Partial<
    OrderTypes.OrderCreateValues & { [FORM_ERROR]: string } & {
      orderId: string;
    }
  >,
) => ({
  type: types.ORDER_CREATE_FAILURE,
  payload: submissionErrors,
});

// TODO: [settings domain]: move to settings domain after it implementation
export const getMinTotalOrderRequest = () => ({
  type: types.ORDER_MIN_TOTAL_REQUEST,
});

export const getMinTotalOrderSuccess = (minTotal: number) => ({
  type: types.ORDER_MIN_TOTAL_SUCCESS,
  payload: minTotal,
});

export const getMinTotalOrderFailure = (error: string) => ({
  type: types.ORDER_MIN_TOTAL_FAILURE,
  payload: error,
});

export const getOrdersByIdsSuccess = (
  payload: Record<string, OrderTypes.Order>,
) => ({
  type: types.ORDERS_BY_IDS_SUCCESS,
  payload,
});

export const getOrdersByIdsFailure = (error: string) => ({
  type: types.ORDERS_BY_IDS_FAILURE,
  payload: error,
});

// TODO: These decrypt requests can be combined into one
// with additional arguments of needed data, like:
// (
//   decryptTarget:
//     |  'total' | 'originTotal' | 'payload'
//     | ('total' | 'originTotal' | 'payload')[],
//   orderIds: Order['id'][],
// ) => Action;

export const decryptOrderTotalRequest = (
  orderIds: OrderTypes.Order['id'][],
) => ({
  type: types.ORDER_DECRYPT_TOTAL_REQUEST,
  payload: orderIds,
});

export const decryptOrderTotalSuccess = (
  totals: Record<OrderTypes.Order['id'], number>,
) => ({
  type: types.ORDER_DECRYPT_TOTAL_SUCCESS,
  payload: totals,
});

export const decryptOrderTotalFailure = (
  totalErrors: Record<OrderTypes.Order['id'], string>,
) => ({
  type: types.ORDER_DECRYPT_TOTAL_FAILURE,
  payload: totalErrors,
});

export const decryptOrderOriginTotalRequest = (
  orderIds: OrderTypes.Order['id'][],
) => ({
  type: types.ORDER_DECRYPT_ORIGINTOTAL_REQUEST,
  payload: orderIds,
});

export const decryptOrderOriginTotalSuccess = (
  originTotals: Record<OrderTypes.Order['id'], number>,
) => ({
  type: types.ORDER_DECRYPT_ORIGINTOTAL_SUCCESS,
  payload: originTotals,
});

export const decryptOrderOriginTotalFailure = (
  originTotalErrors: Record<OrderTypes.Order['id'], string>,
) => ({
  type: types.ORDER_DECRYPT_ORIGINTOTAL_FAILURE,
  payload: originTotalErrors,
});

export const decryptOrderPayloadRequest = (
  orderIds: OrderTypes.Order['id'][],
) => ({
  type: types.ORDER_DECRYPT_PAYLOAD_REQUEST,
  payload: orderIds,
});

export const decryptOrderPayloadSuccess = (
  payload: Record<OrderTypes.Order['id'], OrderTypes.DecryptedPayload>,
) => ({
  type: types.ORDER_DECRYPT_PAYLOAD_SUCCESS,
  payload,
});

export const decryptOrderPayloadFailure = (
  payloadErrors: Record<OrderTypes.Order['id'], string>,
) => ({
  type: types.ORDER_DECRYPT_PAYLOAD_FAILURE,
  payload: payloadErrors,
});

export type Actions = ReturnType<
  | typeof getOrdersRequest
  | typeof getOrdersSuccess
  | typeof getOrdersFailure
  | typeof getOrderDetailsRequest
  | typeof getOrderDetailsSuccess
  | typeof getOrderDetailsFailure
  | typeof getAvailableStatusesRequest
  | typeof getAvailableStatusesSuccess
  | typeof getAvailableStatusesFailure
  | typeof setOrderStatusRequest
  | typeof setOrderStatusSuccess
  | typeof setOrderStatusFailure
  | typeof editOrderRequest
  | typeof editOrderSuccess
  | typeof editOrderFailure
  | typeof getClosestDeliveryDayRequest
  | typeof getClosestDeliveryDaySuccess
  | typeof getClosestDeliveryDayFailure
  | typeof createOrderRequest
  | typeof createOrderByManagerSuccess
  | typeof createOrderSuccess
  | typeof createOrderFailure
  | typeof getMinTotalOrderRequest
  | typeof getMinTotalOrderSuccess
  | typeof getMinTotalOrderFailure
  | typeof getOrdersByIdsSuccess
  | typeof getOrdersByIdsFailure
  | typeof decryptOrderTotalRequest
  | typeof decryptOrderTotalSuccess
  | typeof decryptOrderTotalFailure
  | typeof decryptOrderOriginTotalRequest
  | typeof decryptOrderOriginTotalSuccess
  | typeof decryptOrderOriginTotalFailure
  | typeof decryptOrderPayloadRequest
  | typeof decryptOrderPayloadSuccess
  | typeof decryptOrderPayloadFailure
>;

/*
 * Return orders actions
 */

export const getReturnOrdersRequest = (
  params?: OrderTypes.OrderSearchParams,
) => ({
  type: types.RETURNS_GET_REQUEST,
  payload: params,
});

export const getReturnOrdersSuccess = (
  payload: OrderTypes.ReturnOrder[],
  meta: { pagination: Pagination },
) => ({
  type: types.RETURNS_GET_SUCCESS,
  payload,
  meta,
});

export const getReturnOrdersFailure = (error: string) => ({
  type: types.RETURNS_GET_FAILURE,
  payload: error,
});

export const getReturnOrderDetailsRequest = (orderId: string) => ({
  type: types.RETURN_DETAILS_GET_REQUEST,
  payload: orderId,
});

export const getReturnOrderDetailsSuccess = (
  payload: OrderTypes.ReturnOrder,
) => ({
  type: types.RETURN_DETAILS_GET_SUCCESS,
  payload,
});

export const getReturnOrderDetailsFailure = (error: string) => ({
  type: types.RETURN_DETAILS_GET_FAILURE,
  payload: error,
});

export const setReturnOrderStatusRequest = (
  returnId: string,
  status: OrderStatusValue,
) => ({
  type: types.RETURN_STATUSES_SET_REQUEST,
  payload: { returnId, status },
});

export const setReturnOrderStatusSuccess = (
  payload: OrderTypes.ReturnOrder,
) => ({
  type: types.RETURN_STATUSES_SET_SUCCESS,
  payload,
});

export const setReturnOrderStatusFailure = (error: string) => ({
  type: types.RETURN_STATUSES_SET_FAILURE,
  payload: error,
});

export const editReturnRequest = (values: OrderTypes.ReturnEditValues) => ({
  type: types.RETURN_EDIT_REQUEST,
  payload: values,
});

export const editReturnSuccess = (payload: OrderTypes.ReturnOrder) => ({
  type: types.RETURN_EDIT_SUCCESS,
  payload,
});

export const editReturnFailure = (
  submissionErrors?: Partial<
    OrderTypes.ReturnEditValues & { [FORM_ERROR]: string }
  >,
) => ({
  type: types.RETURN_EDIT_FAILURE,
  payload: submissionErrors,
});

export const createReturnRequest = (values: OrderTypes.ReturnCreateValues) => ({
  type: types.RETURN_CREATE_REQUEST,
  payload: values,
});

export const createReturnSuccess = () => ({
  type: types.RETURN_CREATE_SUCCESS,
});

export const createReturnByManagerSuccess = (
  payload: OrderTypes.ReturnOrder,
) => ({
  type: types.RETURN_CREATE_BY_MANAGER_SUCCESS,
  payload,
});

export const createReturnFailure = (
  submissionErrors?: Partial<
    OrderTypes.ReturnCreateValues & { [FORM_ERROR]: string }
  >,
) => ({
  type: types.RETURN_CREATE_FAILURE,
  payload: submissionErrors,
});

export type ReturnAction = ReturnType<
  | typeof getReturnOrdersRequest
  | typeof getReturnOrdersSuccess
  | typeof getReturnOrdersFailure
  | typeof getReturnOrderDetailsRequest
  | typeof getReturnOrderDetailsSuccess
  | typeof getReturnOrderDetailsFailure
  | typeof setReturnOrderStatusRequest
  | typeof setReturnOrderStatusSuccess
  | typeof setReturnOrderStatusFailure
  | typeof editReturnRequest
  | typeof editReturnSuccess
  | typeof editReturnFailure
  | typeof createReturnRequest
  | typeof createReturnSuccess
  | typeof createReturnByManagerSuccess
  | typeof createReturnFailure
>;
