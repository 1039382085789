export const SALE_SEARCH_REQUEST = `@rtt[D]/SALE_SEARCH_REQUEST` as const;
export const SALE_SEARCH_SUCCESS = `@rtt[D]/SALE_SEARCH_SUCCESS` as const;
export const SALE_SEARCH_FAILURE = `@rtt[D]/SALE_SEARCH_FAILURE` as const;

export const SALE_GET_ALL_REQUEST = `@rtt[D]/SALE_GET_ALL_REQUEST` as const;
export const SALE_GET_ALL_SUCCESS = `@rtt[D]/SALE_GET_ALL_SUCCESS` as const;
export const SALE_GET_ALL_FAILURE = `@rtt[D]/SALE_GET_ALL_FAILURE` as const;

export const SALE_CREATE_REQUEST = `@rtt[D]/SALE_CREATE_REQUEST` as const;
export const SALE_CREATE_RESPONSE = `@rtt[D]/SALE_CREATE_RESPONSE` as const;
export const SALE_CREATE_SUCCESS = `@rtt[D]/SALE_CREATE_SUCCESS` as const;
export const SALE_CREATE_FAILURE = `@rtt[D]/SALE_CREATE_FAILURE` as const;

export const SALE_DETAILS_REQUEST = `@rtt[D]/SALE_DETAILS_REQUEST` as const;
export const SALE_DETAILS_SUCCESS = `@rtt[D]/SALE_DETAILS_SUCCESS` as const;
export const SALE_DETAILS_FAILURE = `@rtt[D]/SALE_DETAILS_FAILURE` as const;

export const SALE_EDIT_REQUEST = `@rtt[D]/SALE_EDIT_REQUEST` as const;
export const SALE_EDIT_RESPONSE = `@rtt[D]/SALE_EDIT_RESPONSE` as const;
export const SALE_EDIT_SUCCESS = `@rtt[D]/SALE_EDIT_SUCCESS` as const;
export const SALE_EDIT_FAILURE = `@rtt[D]/SALE_EDIT_FAILURE` as const;

export const SALE_DELETE_REQUEST = `@rtt[D]/SALE_DELETE_REQUEST` as const;
export const SALE_DELETE_SUCCESS = `@rtt[D]/SALE_DELETE_SUCCESS` as const;
export const SALE_DELETE_FAILURE = `@rtt[D]/SALE_DELETE_FAILURE` as const;
