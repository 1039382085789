export const CATEGORIES_REQUEST = '@rtt[D]/CATEGORIES_REQUEST' as const;
export const CATEGORIES_SUCCESS = '@rtt[D]/CATEGORIES_SUCCESS' as const;
export const CATEGORIES_FAILURE = '@rtt[D]/CATEGORIES_FAILURE' as const;

export const ALL_CATEGORIES_REQUEST = '@rtt[D]/ALL_CATEGORIES_REQUEST' as const;
export const ALL_CATEGORIES_SUCCESS = '@rtt[D]/ALL_CATEGORIES_SUCCESS' as const;
export const ALL_CATEGORIES_FAILURE = '@rtt[D]/ALL_CATEGORIES_FAILURE' as const;

export const CATEGORIES_AVAILABLE_REQUEST = '@rtt[D]/CATEGORIES_AVAILABLE_REQUEST' as const;
export const CATEGORIES_AVAILABLE_SUCCESS = '@rtt[D]/CATEGORIES_AVAILABLE_SUCCESS' as const;

export const SET_CATEGORIES_SEARCH_SUCCESS = '@rtt[D]/SET_CATEGORIES_SEARCH_SUCCESS' as const;
