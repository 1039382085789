import { restrictions } from '@rtt-libs/constants';
import { isNil, pickBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssortmentGetParams, Product } from '../../../api/assortment';
import { getAssortmentRequest } from '../../../assortment/duck/actions';
import { getAssortmentDataSel } from '../../../assortment/duck/selectors';
import useSnackbarError from '../../../common/useSnackbarError';
import { ProductInfo, ProductSearchParams } from '../../types';
import {
  PaginatedProductSearch,
  ProductListWithClickCallback,
} from '../ProductAddCommon';

type Props = {
  existingIds: Product['id'][];
  onSubmit(product: Product): void;
  onClose(): void;
};

const AddAssortmentProductContainer: React.FC<Props & { onClose(): void }> = ({
  existingIds,
  onSubmit,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [displayOptions, changeOptions] = useState<
    Partial<AssortmentGetParams>
  >({
    perPage: restrictions.PRODUCTS_LIST_DISPLAY_COUNT,
    isActive: true,
    isInvalid: false,
  });

  useEffect(() => {
    dispatch(getAssortmentRequest(displayOptions));
  }, [dispatch, displayOptions]);

  const handleProductClick = useCallback(
    (product: Product) => {
      onSubmit(product);
      onClose();
    },
    [onSubmit, onClose],
  );

  const onChange = useCallback((params: Partial<ProductSearchParams>) => {
    changeOptions(prevParams =>
      pickBy({ ...prevParams, page: 1, ...params }, value => !isNil(value)),
    );
  }, []);

  const { products: data, pagination, loading, error } = useSelector(
    getAssortmentDataSel,
  );

  useSnackbarError(error);

  return (
    <PaginatedProductSearch
      isEmpty={!data.length}
      loading={loading}
      error={error}
      pagination={pagination}
      onChange={onChange}
    >
      <ProductListWithClickCallback
        data={data as ProductInfo[]}
        existingIds={existingIds}
        onClick={handleProductClick as (product: ProductInfo) => void}
      />
    </PaginatedProductSearch>
  );
};

export default AddAssortmentProductContainer;
