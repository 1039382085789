import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import React from 'react';
import { EntryId } from './types';

type Props = {
  value?: unknown;
  id: EntryId;
};

const CheckMark: React.FC<Props> = ({ value }) => {
  if (value === undefined) {
    return <IndeterminateCheckBoxIcon />;
  }

  return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
};

export default CheckMark;
