import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusValue } from '../../../api/orders';
import { ChangeStatusButtons } from '../../../common';
import ConfirmStatusChangeDialog from '../../../common/orders/ChangeOrderStatus/ConfirmStatusChangeDialog';
import {
  getAvailableStatusesRequest,
  setOrderStatusRequest,
} from '../../duck/actions';
import { selectAvailableStatuses } from '../../duck/selectors';
import { useSubmitBeforeAction } from '../commonOrders/useHoistedForm';

interface Props {
  id: string;
}

const ChangeOrderStatusContainer: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(selectAvailableStatuses);

  useEffect(() => {
    dispatch(getAvailableStatusesRequest(id));
  }, [dispatch, id]);

  const changeStatus = useCallback(
    (status: StatusValue) => {
      dispatch(setOrderStatusRequest(id, status));
    },
    [dispatch, id],
  );

  const {
    onChange,
    onClose,
    confirmState,
    disabledOrderStatus,
  } = useSubmitBeforeAction(changeStatus);

  return (
    <>
      <ChangeStatusButtons
        availableStatuses={data}
        loading={loading || confirmState.loading}
        error={error || undefined}
        onStatusChange={onChange}
        disabledStatus={disabledOrderStatus}
      />

      <ConfirmStatusChangeDialog
        message={confirmState?.message}
        isOpen={confirmState.open}
        loading={confirmState.loading}
        onClose={onClose}
        onConfirm={confirmState?.onConfirm}
      />
    </>
  );
};

export default ChangeOrderStatusContainer;
