import { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import {
  CreateEmployeeValues,
  EditEmployeeValues,
  Employee,
  SearchParams,
} from '../../api/employees';
import * as types from './types';

export const getDriversRequest = () => ({
  type: types.GET_DRIVERS_REQUEST,
});

export const getDriversSuccess = (payload: Employee[]) => ({
  type: types.GET_DRIVERS_SUCCESS,
  payload,
});

export const getDriversFailure = (error: string) => ({
  type: types.GET_DRIVERS_FAILURE,
  payload: error,
});

export const getManagersRequest = () => ({
  type: types.GET_MANAGERS_REQUEST,
});

export const getManagersSuccess = (payload: Employee[]) => ({
  type: types.GET_MANAGERS_SUCCESS,
  payload,
});

export const getManagersFailure = (error: string) => ({
  type: types.GET_MANAGERS_FAILURE,
  payload: error,
});

export const searchEmployeesRequest = (params: SearchParams) => ({
  type: types.EMPLOYEES_SEARCH_REQUEST,
  payload: params,
});

export const searchEmployeesSuccess = (
  payload: Employee[],
  meta: { pagination: Pagination },
) => ({
  type: types.EMPLOYEES_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchEmployeesFailure = (error: string) => ({
  type: types.EMPLOYEES_SEARCH_FAILURE,
  payload: error,
});

export const createEmployeeRequest = (values: CreateEmployeeValues) => ({
  type: types.EMPLOYEE_CREATE_REQUEST,
  payload: values,
});

export const createEmployeeSuccess = (payload: Employee) => ({
  type: types.EMPLOYEE_CREATE_SUCCESS,
  payload,
});

export const createEmployeeFailure = (
  submissionErrors?: Partial<CreateEmployeeValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.EMPLOYEE_CREATE_FAILURE,
  payload: submissionErrors,
});

export const editEmployeeRequest = (values: EditEmployeeValues) => ({
  type: types.EMPLOYEE_EDIT_REQUEST,
  payload: values,
});

export const editEmployeeSuccess = (payload: Employee) => ({
  type: types.EMPLOYEE_EDIT_SUCCESS,
  payload,
});

export const editEmployeeFailure = (
  submissionErrors?: Partial<EditEmployeeValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.EMPLOYEE_EDIT_FAILURE,
  payload: submissionErrors,
});

export const changeStatusEmployeeRequest = (
  id: Employee['id'],
  status: boolean,
) => ({
  type: types.EMPLOYEE_CHANGE_STATUS_REQUEST,
  payload: {
    id,
    status,
  },
});

export const changeStatusEmployeeSuccess = (payload: Employee) => ({
  type: types.EMPLOYEE_CHANGE_STATUS_SUCCESS,
  payload,
});

export const changeStatusEmployeeFailure = (error: string) => ({
  type: types.EMPLOYEE_CHANGE_STATUS_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof getDriversRequest>
  | ReturnType<typeof getDriversSuccess>
  | ReturnType<typeof getDriversFailure>
  | ReturnType<typeof getManagersRequest>
  | ReturnType<typeof getManagersSuccess>
  | ReturnType<typeof getManagersFailure>
  | ReturnType<typeof searchEmployeesRequest>
  | ReturnType<typeof searchEmployeesSuccess>
  | ReturnType<typeof searchEmployeesFailure>
  | ReturnType<typeof createEmployeeRequest>
  | ReturnType<typeof createEmployeeSuccess>
  | ReturnType<typeof createEmployeeFailure>
  | ReturnType<typeof editEmployeeRequest>
  | ReturnType<typeof editEmployeeSuccess>
  | ReturnType<typeof editEmployeeFailure>
  | ReturnType<typeof changeStatusEmployeeRequest>
  | ReturnType<typeof changeStatusEmployeeSuccess>
  | ReturnType<typeof changeStatusEmployeeFailure>;
