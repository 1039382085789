import { AsyncAutocomplete } from '@rtt-libs/views';
import React, { useCallback, useEffect } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandsRequest } from '../../../brands/duck/actions';
import {
  selectBrandsLoading,
  selectSortedBrands,
} from '../../../brands/duck/selectors';
import type { Brand } from '../../../types';

const BRAND_FIELD_NAME = 'brand';

interface Props {
  defaultValue?: Brand;
}

const BrandAutocompleteField: React.FC<Props> = ({ defaultValue }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(selectBrandsLoading);
  const data = useSelector(selectSortedBrands);

  const fetchOptions = useCallback(() => {
    if (data.length > 0) return undefined;

    return dispatch(getBrandsRequest());
  }, [dispatch, data]);

  useEffect(() => {
    dispatch(getBrandsRequest());
  }, [dispatch]);

  // default value set to prevent validation error on field after blur without changing value of select
  const { meta } = useField(BRAND_FIELD_NAME);

  const defaultSetValue = (defaultValue ||
    (meta.initial && data.find(({ id }) => id === meta.initial.id))) as
    | number
    | string
    | undefined;

  return (
    <AsyncAutocomplete
      fetchOptions={fetchOptions}
      options={
        data.length
          ? data
          : [
              meta.initial as {
                id: number | string;
                name: string;
                isActive: boolean;
              },
            ]
      }
      loading={loading}
      name={BRAND_FIELD_NAME}
      defaultValue={defaultSetValue}
      textFieldProps={{ required: true, margin: 'dense' }}
      label={t('product.brand')}
      disableClearable
      blurOnSelect
      groupBy={option => (option.isActive ? '' : t('common.disabled'))}
    />
  );
};

export default BrandAutocompleteField;
