import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { financialString } from '../../../../utils';
import { selectOrderTotal } from '../../../duck/selectors';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const TotalCell: React.FC<Props> = ({ id }) => {
  const { data: totalDecrypted, loading, error } = useSelector(
    selectOrderTotal(String(id)),
  );

  const [t] = useTranslation();

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (loading || totalDecrypted === undefined) {
    return <Skeleton width="6rem" />;
  }

  return (
    <Typography>
      {financialString(totalDecrypted)}
      {'\u00A0'}
      {t('common.mainCurrency')}
    </Typography>
  );
};

export default TotalCell;
