import * as jsts from 'jsts';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';
import {
  getEmployeesCollectionSel,
  PartialRootState as EmployeesState,
} from '../../employees/duck/selectors';
import {
  getPriceTypesCollectionSel,
  PartialRootState as PriceTypesState,
} from '../../priceTypes/duck/selectors';
import type {
  BrandManagerConnection,
  GeometryFactoryTypeUpdated,
} from '../../types';
import { branchName } from './const';
import { Area } from './model';
import { BranchState } from './reducer';

export type PartialRootState = Record<typeof branchName, BranchState>;
export type CombinedState = PartialRootState & EmployeesState & PriceTypesState;

const getAreasCollectionSel: Selector<
  PartialRootState,
  Record<Area['id'], Area>
> = get([branchName, 'collection']);

const getAreasOrderSel: Selector<PartialRootState, Area['id'][]> = get([
  branchName,
  'order',
]);

export const getAllAreasSel: Selector<
  PartialRootState,
  Area[]
> = createSelector(
  [getAreasCollectionSel, getAreasOrderSel],
  (collection, order) => order.map(id => collection[id]),
);

/**
 * Selector: Areas instance upgraded with jsts Polygon
 */
export const getAllAreasWithPolygon: Selector<
  PartialRootState,
  (Area & { polygon: jsts.geom.Polygon })[]
> = createSelector([getAllAreasSel], areas => {
  return areas.map(area => {
    /**
     * geometryFactory instance of jsts lib to format path of area.
     */
    const geometryFactory = new jsts.geom.GeometryFactory() as GeometryFactoryTypeUpdated;
    try {
      const shell = area.paths.map(
        coord => new jsts.geom.Coordinate(coord.lat, coord.lng),
      );
      return {
        ...area,
        polygon: geometryFactory.createPolygon(shell),
      };
    } catch (e) {
      const line = area.paths.map(
        coord => new jsts.geom.Coordinate(coord.lat, coord.lng),
      );
      const shell = geometryFactory.createLineString(line.concat(line[0]));

      if (shell.isClosed()) {
        return { ...area, polygon: geometryFactory.createPolygon(shell) };
      }
      return { ...area, polygon: geometryFactory.createPolygon() };
    }
  });
});

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

type AreaValues = {
  driver: string;
  manager: string;
  priceType: string;
  managerList: BrandManagerConnection[];
};

// TODO: make wrapper function to hide CombinedState from component
export const getAreaValuesSel: (
  state: CombinedState,
  area: Area,
) => AreaValues = createSelector(
  getEmployeesCollectionSel,
  getPriceTypesCollectionSel,
  (_: CombinedState, area: Area) => area,
  (employees, priceTypes, { driverId, managerList, priceTypeId }) => {
    const driver = employees[driverId];
    // FIXME: rewrite to get all managers with all brands
    const manager = employees[managerList[0]?.managerId];
    const priceType = priceTypes[priceTypeId];

    return {
      managerList,
      driver: driver && `${driver.firstName} ${driver.lastName}`,
      manager: manager && `${manager.firstName} ${manager.lastName}`,
      priceType: priceType && priceType.title,
    };
  },
);

export const getAreaById: ParametricSelector<
  PartialRootState,
  number,
  Area
> = createSelector(
  [getAreasCollectionSel, (_: PartialRootState, id: number) => id],
  (collection, id) => collection[id],
);

const selectDeletionError: Selector<PartialRootState, string | null> = get([
  branchName,
  'deletionError',
]);

const selectDeletionDoneState: Selector<PartialRootState, boolean> = get([
  branchName,
  'deletionDone',
]);

const selectDeletionLoadingState: ParametricSelector<
  PartialRootState,
  { id: number },
  boolean
> = (state, { id }) => get([branchName, 'deletionIds'], state).includes(id);

const selectAreaDeletionState = createSelector(
  [selectDeletionError, selectDeletionLoadingState, selectDeletionDoneState],
  (error, loading, done) => ({ error, loading, done }),
);

export const selectAreaDeletionStateById = (id: number) => (
  state: PartialRootState,
) => selectAreaDeletionState(state, { id });

export const getManagersByAreaIds: ParametricSelector<
  PartialRootState,
  number[],
  number[]
> = createSelector(
  [getAreasCollectionSel, (_: PartialRootState, ids: number[]) => ids],
  (collection, areaIds) =>
    // FIXME: get all managers with their brands
    areaIds.map(areaId => collection[areaId].managerList[0]?.managerId),
);

const mapToOptions = ({ id, title }: Area = {} as Area) => ({
  value: id,
  label: title,
});

export const selectAreaOptions: Selector<
  PartialRootState,
  { value: number; label: string }[]
> = createSelector([getAllAreasSel], areas => areas.map(mapToOptions));
