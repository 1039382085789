import { CategoryItem, CategoryItemId, Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import { Reducer } from 'redux';
import { Currency, Product } from '../../api/assortment';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  collection: [] as Product[],
  current: null as Product | null,
  pagination: {} as Pagination,
  error: null as string | null,
  categoryList: {} as Record<CategoryItemId, CategoryItem>,
  currencyList: [] as Currency[],
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_ASSORTMENT_REQUEST:
    case types.POST_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_PRODUCT_REQUEST:
      return {
        ...state,
        current: null,
        loading: true,
        error: null,
      };
    case types.GET_ASSORTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: action.payload,
        pagination: action.meta.pagination,
      };
    case types.GET_PRODUCT_SUCCESS:
    case types.POST_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload,
      };
    case types.GET_ASSORTMENT_FAILURE:
    case types.GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.POST_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload?.[FORM_ERROR] ?? null,
      };
    case types.GET_OPTIONS_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
