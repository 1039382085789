import { Box, Paper, Typography } from '@material-ui/core';
import { EnhancedPaginatedTable } from '@rtt-libs/views';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceDetailsRequest } from '../../duck/actions';
import { selectBalance } from '../../duck/selectors';
import { Balance } from '../../types';
import BalanceInfo from './BalanceInfo';
import productTableConfig from './productTableConfig';
import RttDetailsTopRow from './RttDetailsTopRow';
import { EditorsInfo } from '../../../common';

type Props = {
  id: Balance['id'];
};

const BalanceDetailsContainer: React.FC<Props> = ({ id }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: balance, loading, error } = useSelector(selectBalance(id));

  const columnsConfig = productTableConfig(t);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!balance) {
      dispatch(getBalanceDetailsRequest(id));
    }
  }, [balance, dispatch, id]);

  const emptyProductsMessage = t(
    'distributor.balances.edit.emptyProductsMessage',
  );

  return (
    <Box p={2} component={Paper}>
      {!balance ? (
        <>
          <BalanceInfo.Loading />
          <RttDetailsTopRow.Loading />
        </>
      ) : (
        <>
          <BalanceInfo
            id={id}
            createdAt={balance.createdAt}
            skuQty={balance.skuQty}
            headingBadge={
              <EditorsInfo
                manager={balance.manager}
                creator={balance.creator}
                lastEditor={balance.lastEditor}
              />
            }
          />
          <RttDetailsTopRow rtt={balance.rtt} />
        </>
      )}

      <Box mt={1}>
        {!loading && !balance?.products ? (
          <Typography>{emptyProductsMessage}</Typography>
        ) : (
          <Paper variant="outlined">
            <EnhancedPaginatedTable
              entryIdKey="productId"
              loading={loading}
              entries={
                ((balance?.products || []) as unknown) as Record<
                  string,
                  unknown
                >[]
              }
              columnsConfig={columnsConfig}
              withoutPagination
            />
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default BalanceDetailsContainer;
