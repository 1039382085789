import {
  createStyles,
  makeStyles,
  TableCell,
  TextField,
  Theme,
} from '@material-ui/core';
import {
  CheckboxAdapter,
  ColumnConfig,
  FieldWithMessages,
  TableRow,
  TextFieldAdapter,
} from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReturnedProduct } from '../../../types';
import { makeProductsPayloadFieldNamePrefix } from '../OrderProductsTable/generateFieldName';

type Props = {
  data: ReturnedProduct[];
  onClick?(id: string | number): void;
  entryIdKey?: keyof ReturnedProduct;
  columnsConfig: ColumnConfig<keyof ReturnedProduct>[];
  readOnly?: boolean;
  groupId: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainRow: {
      '& > *': {
        borderBottom: 'none',
      },
    },
  }),
);

const TableRowListCustom: React.FC<Props> = ({
  data,
  onClick,
  entryIdKey = 'id' as const,
  columnsConfig,
  readOnly,
  groupId,
}) => {
  const classes = useStyles();

  return (
    <>
      {data.map(entry => {
        const key = String(entry[entryIdKey]);
        const handleClick = () => {
          if (typeof onClick === 'function') onClick(key);
        };

        return (
          <React.Fragment key={key}>
            <TableRow
              onClick={onClick && handleClick}
              className={classes.mainRow}
            >
              {columnsConfig.map(
                ({
                  id,
                  options: { align, format, component: Component } = {},
                }) => {
                  let value: unknown = entry[id];
                  value = format ? format(value, entry) : value;

                  return (
                    <TableCell key={id} align={align}>
                      {Component ? (
                        <Component id={key} value={value} />
                      ) : (
                        (value as React.ReactNode)
                      )}
                    </TableCell>
                  );
                },
              )}
            </TableRow>

            <TableRow>
              <TableCell colSpan={columnsConfig.length - 2}>
                <ProductReturnComment
                  name={`${makeProductsPayloadFieldNamePrefix(groupId)}.${
                    entry.id
                  }.returnNote`}
                  entry={entry}
                  readOnly={readOnly}
                />
              </TableCell>
              <TableCell colSpan={2}>
                <DefectiveField
                  name={`${makeProductsPayloadFieldNamePrefix(groupId)}.${
                    entry.id
                  }.isDefective`}
                  entry={entry}
                  readOnly={readOnly}
                />
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TableRowListCustom;

type ProductReturnCommentProps = {
  name: string;
  entry: ReturnedProduct;
  readOnly?: boolean;
};

const ProductReturnComment = ({
  readOnly,
  name,
  entry,
}: ProductReturnCommentProps) => {
  const [t] = useTranslation();

  if (readOnly) {
    return (
      <TextField
        variant="outlined"
        label={t('distributor.returnOrders.details.returnNote.label')}
        value={entry.returnNote}
        margin="dense"
        disabled={!entry.returnNote}
        placeholder={t(
          'distributor.returnOrders.details.returnNote.placeholder',
        )}
        fullWidth
        InputProps={{
          readOnly: true,
        }}
      />
    );
  }

  return (
    <FieldWithMessages
      name={name}
      component={TextFieldAdapter}
      variant="outlined"
      label={t('distributor.returnOrders.create.returnNote.label')}
      margin="dense"
      fullWidth
      required={!entry.isRevocable}
    />
  );
};

type DefectiveFieldProps = {
  name: string;
  entry: ReturnedProduct;
  readOnly?: boolean;
};

const DefectiveField = ({ readOnly, name, entry }: DefectiveFieldProps) => {
  const [t] = useTranslation();

  return (
    <FieldWithMessages
      name={name}
      component={CheckboxAdapter}
      variant="outlined"
      label={t('distributor.returnOrders.details.defective.label')}
      margin="dense"
      fullWidth
      type="checkbox"
      defaultValue={!entry.isRevocable}
      disabled={readOnly || !entry.isRevocable}
      validate={value => {
        if (!entry.isRevocable && !value) {
          return t('validation.valueMissing');
        }
        return undefined;
      }}
    />
  );
};
