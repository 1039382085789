import type { PayloadGroupedKey } from '../constants';
import type { OrderedProduct, OrderCreateValues } from '../types';

type ProductsResult = Record<OrderedProduct['id'], OrderedProduct>;

export const collectProductsFromPayloadByManagerIds = (
  byGroupId: OrderCreateValues[PayloadGroupedKey],
): ProductsResult => {
  return Object.values(byGroupId).reduce(
    (prev, combinedPayload) => ({
      ...prev,
      ...combinedPayload?.payload?.products,
    }),
    {} as ProductsResult,
  );
};
