import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { PriceType } from '../../api/priceTypes';
import { EditPriceTypesActions } from './EditPriceTypes';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const priceTypesTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'title',
    label: t('distributor.tables.priceTypes.name', 'Title'),
  },
  {
    id: 'coefficient',
    label: t('distributor.tables.priceTypes.percent', 'Percent'),
    options: {
      format: (coefficient: unknown) => `${coefficient} %`,
      sort: false,
    },
  },
  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: PriceType) => entry,
      component: EditPriceTypesActions,
      sort: false,
    },
  },
];

export default priceTypesTableConfig;
