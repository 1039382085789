import { Pagination } from '@rtt-libs/types';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';
import { Employee } from '../../api/employees';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

const mapToOptions = (
  { id, firstName, lastName }: Employee = {} as Employee,
) => ({
  value: id,
  label: `${firstName} ${lastName}`,
});

export const getEmployeesCollectionSel: Selector<
  PartialRootState,
  Record<Employee['id'], Employee>
> = get([branchName, 'collection']);

const getEmployeeIdsSel: Selector<PartialRootState, Employee['id'][]> = get([
  branchName,
  'listIds',
]);

export const selectEmployeesError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

const getEmployeesLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
  'employees',
]);

const selectEmployeePagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectEmployees: Selector<
  PartialRootState,
  {
    data: Employee[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    getEmployeesCollectionSel,
    getEmployeeIdsSel,
    selectEmployeePagination,
    getEmployeesLoadingSel,
    selectEmployeesError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);

export const getAllDriversSel: Selector<PartialRootState, Employee[]> = (
  state: PartialRootState,
) => state[branchName].driverIds.map(id => state[branchName].collection[id]);

export const getAllManagersSel: Selector<PartialRootState, Employee[]> = (
  state: PartialRootState,
) => state[branchName].managerIds.map(id => state[branchName].collection[id]);

export const getDriversLoadingStateSel: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'loading', 'drivers']);

export const getDriversErrorSel: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

export const getManagersLoadingStateSel: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'loading', 'mangers']);

export const getManagersErrorSel: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

export const getDriversOptionsSel: Selector<
  PartialRootState,
  { value: number; label: string }[]
> = createSelector([getAllDriversSel], employees =>
  employees.map(mapToOptions),
);

export const getManagersOptionsSel: ParametricSelector<
  PartialRootState,
  number | number[] | undefined,
  { value: number; label: string }[]
> = createSelector(
  [
    getAllManagersSel,
    (_: PartialRootState, included?: number | number[]) => included,
  ],
  employees => employees.map(mapToOptions),
);

export const selectManagerOptions = (included?: number | number[]) => (
  state: PartialRootState,
) => getManagersOptionsSel(state, included);
