import { Box, Paper, Typography } from '@material-ui/core';
import { EnhancedPaginatedTable } from '@rtt-libs/views';
import { difference, identity, isEqual, sortBy } from 'lodash/fp';
import at from 'lodash/fp/at';
import keys from 'lodash/keys';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsOrderedRequest } from '../../duck/actions';
import {
  agreedProductsData,
  selectProductCollection,
  selectProductListOrderedState,
} from '../../duck/selectors';
import productTableConfig from './productTableConfig';

const BalanceProductTable = () => {
  const [t] = useTranslation();

  const {
    input: { value },
  } = useField<Record<string, number>>('balances', {
    subscription: { value: true },
  });

  const [productIds, setProductIds] = useState<string[]>([]);

  useEffect(() => {
    const valueProductIds = keys(value);

    setProductIds(prevProductIds => {
      if (
        isEqual(
          sortBy(identity, prevProductIds),
          sortBy(identity, valueProductIds),
        )
      ) {
        return prevProductIds;
      }

      return valueProductIds;
    });
  }, [value]);

  const {
    input: { value: rttId },
  } = useField<number>('rttId', {
    subscription: { value: true },
  });

  const { loading, error } = useSelector(agreedProductsData);
  const productCollection = useSelector(selectProductCollection);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  const products = at(productIds, productCollection);

  const [productWithoutOrderedState, setOrdered] = useState<string[]>([]);

  const orderedStates = useSelector(selectProductListOrderedState);

  useEffect(() => {
    const productsWithoutOrdered = products
      .filter(
        p =>
          !p.ordered &&
          !orderedStates.loadings[p.id] &&
          !orderedStates.errors[p.id],
      )
      .map(p => p.id);

    setOrdered(prevProducts => {
      if (
        isEqual(
          sortBy(identity, prevProducts),
          sortBy(identity, productsWithoutOrdered),
        )
      ) {
        return prevProducts;
      }

      return difference(productsWithoutOrdered, prevProducts);
    });
  }, [products, orderedStates]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (productWithoutOrderedState.length)
      dispatch(getProductsOrderedRequest(rttId, productWithoutOrderedState));
  }, [dispatch, rttId, productWithoutOrderedState]);

  const emptyProductsMessage = t(
    'distributor.balances.create.emptyProductsMessage',
  );

  const columnsConfig = useMemo(() => productTableConfig(t), [t]);

  return (
    <Box mt={1}>
      {!loading && !products.length ? (
        <Typography>{emptyProductsMessage}</Typography>
      ) : (
        <Paper variant="outlined">
          <EnhancedPaginatedTable
            loading={loading}
            entries={(products as unknown) as Record<string, unknown>[]}
            columnsConfig={columnsConfig}
            withoutPagination
          />
        </Paper>
      )}
    </Box>
  );
};

export default BalanceProductTable;
