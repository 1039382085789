import { LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value: number | null;
};

const UploadProgress: React.FC<Props> = ({ value }) => {
  const [t] = useTranslation();

  if (value === null) return null;

  return (
    <>
      <LinearProgress value={value} variant="determinate" />
      <Typography variant="caption">
        {t('distributor.assortment.upload.progress')} ({value}%)
      </Typography>
    </>
  );
};

export default UploadProgress;
