import { Dialog } from '@material-ui/core';
import React from 'react';
import EditPriceTypesContainer from './EditPriceTypesContainer';

type Props = {
  open: boolean;
  onClose(): void;
};

const EditPriceTypesDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="edit-priceTypes-dialog-title"
    >
      <EditPriceTypesContainer onClose={onClose} />
    </Dialog>
  );
};

export default EditPriceTypesDialog;
