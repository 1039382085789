/* eslint-disable @typescript-eslint/no-empty-interface , @typescript-eslint/camelcase */

import { mapPaginatedData, someBooleanToInt } from '@rtt-libs/api-services';
import type { Measurement, OrderParam, Paginated } from '@rtt-libs/types';
import trim from 'lodash/fp/trim';
import { ENDPOINTS } from '../environment';
import type {
  FetchedProductInfo,
  ProductInfo as IProductInfo,
  ProductSearchParams,
  ReturnProductInfo,
} from '../productsAddition/types';
import api from './apiSetup';
import { transformSimpleErrorMessage } from './mappers';

export interface ProductInfo extends IProductInfo {}

type APIProductSearchParams = Partial<{
  search: string;
  category_id: number[];
  sort: 'sku' | 'title';
  order: OrderParam;
  page: number;
  per_page: number;
  order_id: string;
  is_active: 0 | 1;
  is_invalid: 0 | 1;
  manager_id: number;
  brand_id: number;
}>;

export class ProductInfo {
  constructor(product: FetchedProductInfo) {
    this.id = product.id;
    this.image = product.image;
    this.isRevocable = product.is_revocable ?? true;
    this.price = product.price;
    this.saleMeasurement = product.sale_measurement;
    this.sku = product.sku;
    this.title = product.title;
    this.weight = product.weight ?? undefined;
    // Mapped to fix misconception in different representation of Product
    this.saleMeasurement =
      ((this.saleMeasurement as unknown) as {
        type: Measurement;
      })?.type || this.saleMeasurement;
    this.brandId = product.brand_id;
  }
}

export const searchProductsForRtt = (
  rttId: number,
  params?: ProductSearchParams,
) =>
  api
    .get<Paginated<FetchedProductInfo>>(ENDPOINTS.orderProducts(rttId), {
      params: params && mapProductSearchParams(params),
    })
    .then(data =>
      mapPaginatedData(data.data, products =>
        products.map(product => new ProductInfo(product)),
      ),
    )
    .catch(transformSimpleErrorMessage);

export const searchProductsForReturnAPI = (
  rttId: number,
  params?: ProductSearchParams,
) =>
  api
    .get<Paginated<ReturnProductInfo>>(ENDPOINTS.returnOrderProducts, {
      params: {
        rtt_id: rttId,
        ...(params && mapProductSearchParams(params)),
      },
    })
    .then(data => mapPaginatedData(data.data))
    .catch(transformSimpleErrorMessage);

export const searchProductsPricelessAPI = (params?: ProductSearchParams) =>
  api
    .get<Paginated<FetchedProductInfo>>(ENDPOINTS.productsPriceless, {
      params: params && mapProductSearchParams(params),
    })
    .then(data =>
      mapPaginatedData(data.data, products =>
        products.map(product => new ProductInfo(product)),
      ),
    )
    .catch(transformSimpleErrorMessage);

function mapProductSearchParams(
  params: ProductSearchParams,
): APIProductSearchParams {
  return {
    sort: params.orderBy,
    order: params.order,
    per_page: params.perPage,
    page: params.page,
    category_id: params.categoryIds,
    search:
      (typeof params.search === 'string' && trim(params.search)) || undefined,
    order_id: params.orderId,
    is_active: someBooleanToInt(params.isActive),
    is_invalid: someBooleanToInt(params.isInvalid),
    manager_id: params.managerId,
    brand_id: params.brandId,
  };
}
