import { Pagination } from '@rtt-libs/types';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  createPriceType,
  deletePriceType,
  editPriceType,
  getAllPriceTypes,
  PriceType,
  searchPriceTypes,
  setRttPriceType,
} from '../../api/priceTypes';
import * as actions from './actions';
import * as types from './types';
import { getPriceTypesCollectionSel } from './selectors';

function* worker() {
  try {
    const priceTypes: PriceType[] = yield call(getAllPriceTypes);

    yield all([put(actions.getPriceTypesSuccess(priceTypes))]);
  } catch (e) {
    yield put(actions.getPriceTypesFailure(e.message));
  }
}

function* searchPriceTypesWorker({
  payload,
}: ReturnType<typeof actions.searchPriceTypesRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: PriceType[]; meta: { pagination: Pagination } } = yield call(
      searchPriceTypes,
      payload,
    );
    yield all([put(actions.searchPriceTypesSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.searchPriceTypesFailure(e.message));
  }
}

function* createPriceTypeWorker({
  payload,
}: ReturnType<typeof actions.createPriceTypeRequest>) {
  try {
    const priceTypes: PriceType = yield call(createPriceType, payload);

    yield all([
      put(actions.createPriceTypeSuccess(priceTypes)),
      put(actions.createPriceTypeResponse()),
    ]);
  } catch (errors) {
    yield put(actions.createPriceTypeResponse(errors));
  }
}
function* editPriceTypeWorker({
  payload,
}: ReturnType<typeof actions.editPriceTypeRequest>) {
  try {
    const priceTypes: PriceType = yield call(editPriceType, payload);

    yield all([
      put(actions.editPriceTypeSuccess(priceTypes)),
      put(actions.editPriceTypeResponse()),
    ]);
  } catch (errors) {
    yield put(actions.editPriceTypeResponse(errors));
  }
}

function* deletePriceTypeWorker({
  payload,
}: ReturnType<typeof actions.deletePriceTypeRequest>) {
  try {
    yield call(deletePriceType, payload);

    yield all([
      put(actions.deletePriceTypeSuccess(payload.id)),
      put(actions.deletePriceTypeResponse()),
    ]);
  } catch (errors) {
    yield put(actions.deletePriceTypeResponse(errors));
  }
}

function* setRttPriceTypeWorker({
  payload,
}: ReturnType<typeof actions.setRttPriceTypeRequest>) {
  try {
    yield call(setRttPriceType, payload);

    const priceTypeCollection: Record<
      PriceType['id'],
      PriceType
    > = yield select(getPriceTypesCollectionSel);

    const updatedRttPriceTypes = payload.reduce(
      (acc, { rttId, priceTypeId }) => {
        const id = priceTypeId ?? null;
        acc[rttId] = {
          priceType: id === null ? null : priceTypeCollection[id],
        };
        return acc;
      },
      {} as Record<number, { priceType: PriceType | null }>,
    );

    yield put(actions.setRttPriceTypeSuccess(updatedRttPriceTypes));
  } catch (errors) {
    yield put(actions.setRttPriceTypeFailure(errors));
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_PRICE_TYPES_REQUEST, worker);
  yield takeLatest(types.SEARCH_PRICE_TYPES_REQUEST, searchPriceTypesWorker);
  yield takeLatest(types.CREATE_PRICE_TYPES_REQUEST, createPriceTypeWorker);
  yield takeLatest(types.EDIT_PRICE_TYPES_REQUEST, editPriceTypeWorker);
  yield takeLatest(types.DELETE_PRICE_TYPES_REQUEST, deletePriceTypeWorker);
  yield takeLatest(types.SET_RTT_PRICE_TYPES_REQUEST, setRttPriceTypeWorker);
}
