/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
  someBooleanToInt,
} from '@rtt-libs/api-services';
import {
  DataWrapped,
  GeoLocation,
  OrderParam,
  Paginated,
} from '@rtt-libs/types';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { filter } from 'lodash';
import { keyBy } from 'lodash/fp';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { Product } from './assortment';
import { Brand, FetchedBrandAPI, transformSimpleErrorMessage } from './mappers';

dayjs.extend(minMax);

class PrimitiveDiscountApi<T extends string | number = string | number> {
  target_id: T;
  discount: number;

  constructor(discount: PrimitiveDiscount<T>) {
    this.discount = discount.discount;
    this.target_id = discount.targetId;
  }
}

class PrimitiveDiscount<T extends string | number = string | number> {
  targetId: T;
  /** discount value in % */
  discount: number;

  constructor(discount: PrimitiveDiscountApi<T>) {
    this.discount = discount.discount;
    this.targetId = discount.target_id;
  }
}

type DiscountTargetType = 'category' | 'product' | 'brand';

type FetchedDiscount<
  T extends DiscountTargetType = DiscountTargetType
> = PrimitiveDiscountApi<string> & {
  id: number;
  sale_id: number;
  target_type: T;
  /** DateTime ISO */
  created_at: string;
  product: T extends 'product' ? Product : undefined;
  brand: T extends 'brand' ? FetchedBrandAPI : undefined;
};

export type DiscountProduct = Pick<Product, 'id' | 'sku' | 'title'>;
export type DiscountBrand = Brand;

export class Discount<
  T extends DiscountTargetType = DiscountTargetType
> extends PrimitiveDiscount {
  id: number;
  saleId: number;
  targetType: T;
  /** DateTime ISO */
  createdAt: string;
  product: T extends 'product' ? DiscountProduct : undefined;
  brand: T extends 'brand' ? DiscountBrand : undefined;

  constructor(discount: FetchedDiscount<T>) {
    super(discount);
    this.id = discount.id;
    this.saleId = discount.sale_id;
    this.targetType = discount.target_type;
    this.createdAt = discount.created_at;
    this.product = discount.product;

    this.brand = (discount.brand
      ? new Brand(discount.brand as FetchedBrandAPI)
      : undefined) as T extends 'brand' ? DiscountBrand : undefined;
  }
}

type Area = {
  id: number;
  title: string;
  polygon: GeoLocation[];
};

type FetchedSale = {
  id: number;
  title: string;
  /** Date ISO */
  start_date: string;
  /** Date ISO */
  end_date: string;
  /** DateTime ISO */
  created_at: string;
  category_discounts: DataWrapped<FetchedDiscount<'category'>[]>;
  product_discounts: DataWrapped<FetchedDiscount<'product'>[]>;
  brand_discounts: DataWrapped<FetchedDiscount<'brand'>[]>;
  areas: DataWrapped<Area[]>;
};

export class Sale implements Record<string, unknown> {
  id: number;
  title: string;
  /** Date ISO */
  startDate: string;
  /** Date ISO */
  endDate: string;
  /** DateTime ISO */
  createdAt: string;
  categoryDiscounts: Discount<'category'>[];
  productDiscounts: Discount<'product'>[];
  brandDiscounts: Discount<'brand'>[];
  areas: DataWrapped<Area[]>;
  [key: string]: unknown;

  constructor(sale: FetchedSale) {
    this.id = sale.id;
    this.title = sale.title;
    this.startDate = sale.start_date;
    this.endDate = sale.end_date;
    this.createdAt = sale.created_at;
    this.categoryDiscounts = sale.category_discounts.data.map(
      discount => new Discount(discount),
    );
    this.productDiscounts = sale.product_discounts.data.map(
      discount => new Discount(discount),
    );
    this.brandDiscounts = sale.brand_discounts.data.map(
      discount => new Discount(discount),
    );
    this.areas = sale.areas;
  }
}

type SalesSearchParamsAPI = Partial<{
  title: string;
  /** formatted like YYYY-MM-DD */
  start_date: string;
  /** formatted like YYYY-MM-DD */
  end_date: string;
  sort: 'title' & string;
  order: OrderParam;
  per_page: number;
  page: number;
  is_active: 0 | 1;
}>;

type SaleStatus = 'active' | 'finished';

export type SalesSearchParams = Partial<{
  search: string;
  /** formatted like YYYY-MM-DD */
  startDate: string;
  /** formatted like YYYY-MM-DD */
  endDate: string;
  orderBy: 'title' & string;
  order: OrderParam;
  perPage: number;
  page: number;
  status: SaleStatus;
}>;

type CreateSaleValuesApi = {
  title: string;
  areas?: Area['id'][];
  /** DateTime ISO */
  start_date: string;
  /** DateTime ISO */
  end_date: string;
  category_discounts?: PrimitiveDiscountApi[];
  product_discounts?: PrimitiveDiscountApi[];
  brand_discounts?: PrimitiveDiscountApi[];
};

export type CreateSaleValues = {
  title: string;
  areas?: Area['id'][];
  /** DateTime ISO */
  startDate: string;
  /** DateTime ISO */
  endDate: string;
  categoryDiscounts?: Record<number, number>;
  productDiscounts?: Record<string, number>;
  brandDiscounts?: Record<number, number>;
};

/*
 * Sales API handlers
 */

export const getSales = (params?: SalesSearchParams) =>
  api
    .get<Paginated<FetchedSale>>(ENDPOINTS.salesSearch, {
      params: params && mapSearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapSaleArray))
    .catch(transformSimpleErrorMessage);

export const getSaleDetails = (id: Sale['id']) =>
  api
    .get<FetchedSale>(`${ENDPOINTS.sales}/${id}`)
    .then(({ data }) => {
      const sale = new Sale(data);
      return {
        data: sale,
        meta: {
          products: getProductsFromEditSale(sale),
          brands: getBrandsFromEditSale(sale),
        },
      };
    })
    .catch(transformSimpleErrorMessage);

export const getAllSales = () =>
  api
    .get<DataWrapped<FetchedSale[]>>(ENDPOINTS.salesSearch)
    .then(({ data }) => mapSaleArray(data.data))
    .catch(transformSimpleErrorMessage);

export const createSale = (values: CreateSaleValues) =>
  api
    .post<FetchedSale>(ENDPOINTS.sales, mapCreateSaleValues(values))
    .then(({ data }) => new Sale(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateSaleErrors);
    });

export const editSale = (id: Sale['id'], values: CreateSaleValues) =>
  api
    .put<FetchedSale>(`${ENDPOINTS.sales}/${id}`, mapCreateSaleValues(values))
    .then(({ data }) => new Sale(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateSaleErrors);
    });

export const deleteSale = (id: Sale['id']) =>
  api.delete(`${ENDPOINTS.sales}/${id}`).catch(transformSimpleErrorMessage);

/*
 * Sale Mappers
 */

function mapSearchParams(params: SalesSearchParams): SalesSearchParamsAPI {
  return {
    end_date: params.endDate,
    start_date: params.startDate,
    order: params.order,
    sort: params.orderBy,
    page: params.page,
    per_page: params.perPage,
    title: params.search,
    is_active: someBooleanToInt(params.status && params.status === 'active'),
  };
}

function mapSaleArray(sales: FetchedSale[]) {
  try {
    return sales.map(sale => new Sale(sale));
  } catch (e) {
    throw new Error('Order mapping error');
  }
}

function mapCreateSaleValues({
  title,
  areas,
  categoryDiscounts,
  endDate,
  startDate,
  productDiscounts,
  brandDiscounts,
}: CreateSaleValues): CreateSaleValuesApi {
  return {
    title,
    start_date: startDate,
    end_date: endDate,
    areas,
    category_discounts: mapDiscounts(categoryDiscounts),
    product_discounts: mapDiscounts(productDiscounts),
    brand_discounts: mapDiscounts(brandDiscounts),
  };
}

const transformCreateSaleErrors: TransformAPIError<
  Record<keyof CreateSaleValuesApi, string | string[]>,
  Record<keyof CreateSaleValues, string>
> = function transformCreateSaleErrors(e) {
  return {
    title: e.title?.toString(),
    areas: e.areas?.toString(),
    startDate: e.start_date?.toString(),
    endDate: e.end_date?.toString(),
    categoryDiscounts: e.category_discounts?.toString(),
    productDiscounts: e.product_discounts?.toString(),
    brandDiscounts: e.brand_discounts?.toString(),
  };
};

function mapDiscounts<T extends string | number = string | number>(
  discounts?: Record<T, number>,
): PrimitiveDiscountApi<T>[] | undefined {
  if (!discounts) return undefined;

  return filter(
    Object.entries<number>(discounts).map(([targetId, discount]) =>
      !Number.isNaN(Number(discount))
        ? new PrimitiveDiscountApi({
            targetId,
            discount,
          })
        : undefined,
    ),
  ) as PrimitiveDiscountApi<T>[];
}

function getProductsFromEditSale(sale: Sale) {
  return keyBy(
    'id',
    // Take only required fields
    sale.productDiscounts.map(({ product: { id, sku, title } }) => ({
      id,
      sku,
      title,
    })),
  );
}

function getBrandsFromEditSale(sale: Sale) {
  return keyBy(
    'id',
    sale.brandDiscounts.map(({ brand }) => brand),
  );
}
