import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import React from 'react';
import { EditStaffActions, staffTableConfig } from '../../common/staff';
import { types } from '../duck';
import type { Administrator } from '../types';
import EditAdministratorContainer from './EditAdministratorContainer';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const administratorsTableConfig = (t: TFunction): ColumnConfig[] => [
  ...staffTableConfig(t),

  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: Administrator) => entry,
      component: ({ id, value }) => (
        <EditStaffActions
          id={String(id)}
          value={value as Administrator}
          statusActionTypes={{
            start: types.ADMINISTRATORS_CHANGE_STATUS_REQUEST,
            resolve: types.ADMINISTRATORS_CHANGE_STATUS_SUCCESS,
            reject: types.ADMINISTRATORS_CHANGE_STATUS_FAILURE,
          }}
          container={EditAdministratorContainer}
        />
      ),
      sort: false,
    },
  },
];

export default administratorsTableConfig;
