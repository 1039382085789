/* eslint-disable @typescript-eslint/camelcase */
import { ROLES, UserInfoType } from '@rtt-libs/auth';
import { AttachmentDocumentType } from '@rtt-libs/types';
import { AxiosResponse } from 'axios';
import { isEqual, xor } from 'lodash/fp';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import getDistributorInfo, {
  DistributorInfoResponse,
} from '../../api/distributorInfo';
import finishReg, { FinalFormResponse } from '../../api/finishReg';
import updateDistributor from '../../api/updateDistributor';
import formErrorTransform from '../../formErrorTransform';
import { DocumentPreviewType, FinishFormValues } from '../types';
import * as actions from './actions';
import { getInitialValuesSel } from './selectors';
import * as TYPES from './types';

const extractThumbnail = (related: { data: AttachmentDocumentType[] }) => {
  const thumbnails = related.data.filter(({ is_thumbnail }) => is_thumbnail);

  if (thumbnails.length > 0) {
    return { thumbSrc: thumbnails[0].src, thumbSize: thumbnails[0].thumb_size };
  }
  return {};
};

const mapInitialValues = (data: DistributorInfoResponse): FinishFormValues => ({
  id: data.id,
  address: data.address,
  categories: data.categories || [],
  email: data.contact ? data.contact.email : '',
  firstName: data.contact ? data.contact.first_name : '',
  lastName: data.contact ? data.contact.last_name : '',
  name: data.name,
  documents: data.documents
    ? data.documents.map(({ id, src, thumb_size, name, related }) => ({
        id,
        src,
        name,
        size: thumb_size,
        ...extractThumbnail(related),
      }))
    : [],
});

function* regWorker({ payload }: ReturnType<typeof actions.finishRegRequest>) {
  try {
    const data = new FormData();
    const mappedPayload = {
      name: payload.name,
      address: payload.address,
      email: payload.email,
      'categories[]': payload.categories,
      'documents[]': payload.documents,
      first_name: payload.firstName,
      last_name: payload.lastName,
    };

    Object.entries(mappedPayload).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (value as (string | number | File)[]).forEach(valueItem => {
          data.append(key, valueItem as string | Blob);
        });
      } else {
        data.append(key, value);
      }
    });

    const { data: response }: { data: FinalFormResponse } = yield call(
      finishReg,
      data,
    );

    const user = mapRegResponseToUserInfo(response);

    yield all([put(actions.finishRegSuccess(user))]);
  } catch (e) {
    yield put(actions.finishRegFailure(formErrorTransform(e)));
  }
}

function* updateWorker({
  payload,
}: ReturnType<typeof actions.updateRegRequest>) {
  try {
    const {
      id: distributorId,
      ...initialValues
    }: Partial<FinishFormValues> = yield select(getInitialValuesSel) ||
      ({} as FinishFormValues);

    if (distributorId === undefined) {
      throw new Error("Distributor id can't be undefined");
    }

    const documentsChanged = (xor(
      initialValues?.documents,
      payload.documents,
    ) as unknown) as (File | DocumentPreviewType)[];
    const addedDocuments = documentsChanged.filter(
      doc => doc instanceof File,
    ) as File[];
    const deletedDocuments = documentsChanged
      .filter(doc => !(doc instanceof File))
      .map(doc => (doc as DocumentPreviewType).id);

    const data = new FormData();
    const mappedPayload = {
      name: payload.name,
      address: payload.address,
      email: payload.email,
      'categories[]': isEqual(initialValues?.categories, payload.categories)
        ? undefined
        : payload.categories,
      'documents_added[]': addedDocuments,
      'documents_deleted[]': deletedDocuments,
      first_name: payload.firstName,
      last_name: payload.lastName,
    };

    Object.entries(mappedPayload).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (value as (string | number | File)[]).forEach(valueItem => {
          data.append(key, valueItem as string | Blob);
        });
      } else if (value !== undefined) {
        data.append(key, value);
      }
    });

    const {
      data: updatedInfo,
    }: AxiosResponse<DistributorInfoResponse> = yield call(
      updateDistributor,
      distributorId,
      data,
    );

    const updatedValues = mapInitialValues(updatedInfo);

    yield all([
      put(actions.updateRegSuccess(mapRegResponseToUserInfo(updatedInfo))),
      put(
        actions.initialRegValuesSuccess({
          values: updatedValues,
          status: updatedInfo.status.value,
          notices: updatedInfo.notices?.notices ?? null,
        }),
      ),
    ]);
  } catch (e) {
    yield put(actions.updateRegFailure(formErrorTransform(e)));
  }
}

function* initialValuesWorker() {
  try {
    const { data }: AxiosResponse<DistributorInfoResponse> = yield call(
      getDistributorInfo,
    );

    const initialValues = mapInitialValues(data);

    yield put(
      actions.initialRegValuesSuccess({
        values: initialValues,
        status: data.status.value,
        notices: data.notices ? data.notices.notices : null,
      }),
    );
  } catch (e) {
    yield put(actions.initialRegValuesFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TYPES.FINISH_REG_REQUEST, regWorker);
  yield takeLatest(TYPES.UPDATE_REG_REQUEST, updateWorker);
  yield takeLatest(
    TYPES.FINISH_REG_INITIAL_VALUES_REQUEST,
    initialValuesWorker,
  );
}

function mapRegResponseToUserInfo(
  response: Omit<FinalFormResponse, 'is_verified'>,
): UserInfoType {
  return {
    id: response.id,
    phone: null,
    login: null,
    email: response.contact?.email ?? null,
    first_name: response.contact?.first_name ?? null,
    last_name: response.contact?.last_name ?? null,
    is_active: true,
    is_company_creation_required: false,
    is_company_verified: false,
    role: ROLES.DISTR_OWNER,
    company: {
      id: response.id,
      status: 'new',
      name: response.name,
    },
    locale: null,
  };
}
