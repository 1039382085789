import { FieldWithMessages, TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BrandFormFields = () => {
  const [t] = useTranslation();

  return (
    <FieldWithMessages
      name="title"
      label={t('common.title')}
      component={TextFieldAdapter}
      fullWidth
      required
    />
  );
};

export default BrandFormFields;
