import { Pagination } from '@rtt-libs/types';
import { keyBy } from 'lodash/fp';
import { Reducer } from 'redux';
import type { Administrator } from '../types';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: true,
  collection: {} as Record<Administrator['id'], Administrator>,
  error: null as string | null,
  idList: [] as Administrator['id'][],
  pagination: {} as Pagination,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.ADMINISTRATORS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.ADMINISTRATORS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(administrator => administrator.id),
        pagination: action.meta.pagination,
      };
    case types.ADMINISTRATORS_CREATE_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        idList: [action.payload.id, ...state.idList],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case types.ADMINISTRATORS_EDIT_SUCCESS:
    case types.ADMINISTRATORS_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };

    case types.ADMINISTRATORS_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
