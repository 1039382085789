import type { OrderStatusValue, Pagination } from '@rtt-libs/types';
import { difference, keyBy, omit, union } from 'lodash/fp';
import keys from 'lodash/keys';
import { combineReducers, Reducer } from 'redux';
import { withExportsReducer } from '../../exports/duck/reducer';
import type * as OrderTypes from '../types';
import type { Actions, ReturnAction } from './actions';
import { returnOrdersCollectionSliceKey } from './const';
import * as types from './types';

/**
 * Statuses slice
 */
const initialStatusesState = {
  loading: false,
  data: [] as OrderStatusValue[],
  error: null as string | null,
};

const statusesReducer: Reducer<
  typeof initialStatusesState,
  Actions | ReturnAction
> = (state = initialStatusesState, action) => {
  switch (action.type) {
    case types.ORDER_STATUSES_GET_REQUEST:
    case types.ORDER_STATUSES_SET_REQUEST:
    case types.RETURN_STATUSES_SET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ORDER_STATUSES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.ORDER_STATUSES_SET_SUCCESS:
    case types.RETURN_STATUSES_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.ORDER_STATUSES_GET_FAILURE:
    case types.ORDER_STATUSES_SET_FAILURE:
    case types.RETURN_STATUSES_SET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

/**
 * DeliveryDays slice
 */
const initialDeliveryDaysState = {
  date: undefined as string | undefined,
  error: null as string | null,
  loading: false,
};

const deliveryDaysReducer: Reducer<typeof initialDeliveryDaysState, Actions> = (
  state = initialDeliveryDaysState,
  action,
) => {
  switch (action.type) {
    case types.ORDER_DELIVERY_CLOSES_DAY_REQUEST:
      return {
        ...state,
        date: undefined,
        loading: true,
      };
    case types.ORDER_DELIVERY_CLOSES_DAY_SUCCESS:
      return {
        ...state,
        date: action.payload,
        loading: false,
      };
    case types.ORDER_DELIVERY_CLOSES_DAY_FAILURE:
      return {
        ...state,
        date: action.payload,
        error: action.meta ?? null,
        loading: false,
      };
    default:
      return state;
  }
};

/**
 * Decrypted slice
 */
const initialDecryptedState = {
  collections: {
    totals: {} as Record<OrderTypes.Order['id'], number>,
    originTotals: {} as Record<OrderTypes.Order['id'], number>,
    payloads: {} as Record<OrderTypes.Order['id'], OrderTypes.DecryptedPayload>,
  },
  processing: {
    totals: [] as OrderTypes.Order['id'][],
    originTotals: [] as OrderTypes.Order['id'][],
    payloads: [] as OrderTypes.Order['id'][],
  },
  errors: {
    totals: {} as Record<OrderTypes.Order['id'], string>,
    originTotals: {} as Record<OrderTypes.Order['id'], string>,
    payloads: {} as Record<OrderTypes.Order['id'], string>,
  },
};

const updateDecryptStateOnRequest = (
  sliceName: keyof typeof initialDecryptedState['collections'],
  state: typeof initialDecryptedState,
  actionPayload: string[],
) => ({
  ...state,
  processing: {
    ...state.processing,
    [sliceName]: union(state.processing[sliceName], actionPayload),
  },
});

const updateDecryptStateOnSuccess = <Payload>(
  sliceName: keyof typeof initialDecryptedState['collections'],
  state: typeof initialDecryptedState,
  actionPayload: Payload,
) => ({
  ...state,
  collections: {
    ...state.collections,
    [sliceName]: {
      ...state.collections[sliceName],
      ...actionPayload,
    },
  },
  processing: {
    ...state.processing,
    [sliceName]: difference(state.processing[sliceName], keys(actionPayload)),
  },
  errors: {
    ...state.errors,
    [sliceName]: omit(keys(actionPayload), state.errors[sliceName]),
  },
});

const updateDecryptStateOnFailure = <Payload>(
  sliceName: keyof typeof initialDecryptedState['collections'],
  state: typeof initialDecryptedState,
  actionPayload: Payload,
) => ({
  ...state,
  processing: {
    ...state.processing,
    [sliceName]: difference(state.processing[sliceName], keys(actionPayload)),
  },
  errors: {
    ...state.errors,
    [sliceName]: {
      ...state.errors[sliceName],
      ...actionPayload,
    },
  },
});

const decryptedReducer: Reducer<typeof initialDecryptedState, Actions> = (
  state = initialDecryptedState,
  action,
) => {
  switch (action.type) {
    case types.ORDER_DECRYPT_TOTAL_REQUEST:
      return updateDecryptStateOnRequest('totals', state, action.payload);
    case types.ORDER_DECRYPT_ORIGINTOTAL_REQUEST:
      return updateDecryptStateOnRequest('originTotals', state, action.payload);
    case types.ORDER_DECRYPT_PAYLOAD_REQUEST:
      return updateDecryptStateOnRequest('payloads', state, action.payload);

    case types.ORDER_DECRYPT_TOTAL_SUCCESS:
      return updateDecryptStateOnSuccess('totals', state, action.payload);
    case types.ORDER_DECRYPT_ORIGINTOTAL_SUCCESS:
      return updateDecryptStateOnSuccess('originTotals', state, action.payload);
    case types.ORDER_DECRYPT_PAYLOAD_SUCCESS:
      return updateDecryptStateOnSuccess('payloads', state, action.payload);

    case types.ORDER_DECRYPT_TOTAL_FAILURE:
      return updateDecryptStateOnFailure('totals', state, action.payload);
    case types.ORDER_DECRYPT_ORIGINTOTAL_FAILURE:
      return updateDecryptStateOnFailure('originTotals', state, action.payload);
    case types.ORDER_DECRYPT_PAYLOAD_FAILURE:
      return updateDecryptStateOnFailure('payloads', state, action.payload);

    default:
      return state;
  }
};

/**
 * Orders slice
 */
const initialOrdersState = {
  collection: {} as Record<OrderTypes.Order['id'], OrderTypes.Order>,
  orderIds: [] as OrderTypes.Order['id'][],
  loading: false,
  error: null as string | null,
  pagination: {} as Pagination,
};

const orderReducer: Reducer<typeof initialOrdersState, Actions> = (
  state = initialOrdersState,
  action,
) => {
  switch (action.type) {
    case types.ORDERS_GET_REQUEST:
    case types.ORDER_DETAILS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.ORDERS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        orderIds: action.payload.map(order => order.id),
        pagination: action.meta.pagination,
      };
    case types.ORDERS_BY_IDS_SUCCESS:
      return {
        ...state,
        collection: { ...state.collection, ...action.payload },
      };
    case types.ORDER_DETAILS_GET_SUCCESS:
    case types.ORDER_EDIT_SUCCESS:
    case types.ORDER_CREATE_BY_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    case types.ORDERS_GET_FAILURE:
    case types.ORDER_DETAILS_GET_FAILURE:
    case types.ORDERS_BY_IDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.ORDER_STATUSES_SET_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };

    default:
      return state;
  }
};

/**
 * Return orders slice
 */
const initialReturnOrdersState = {
  collection: {} as Record<
    OrderTypes.ReturnOrder['id'],
    OrderTypes.ReturnOrder
  >,
  idList: [] as OrderTypes.ReturnOrder['id'][],
  loading: false,
  error: null as string | null,
  pagination: {} as Pagination,
};

const returnOrderReducer: Reducer<
  typeof initialReturnOrdersState,
  ReturnAction
> = (state = initialReturnOrdersState, action) => {
  switch (action.type) {
    case types.RETURNS_GET_REQUEST:
    case types.RETURN_DETAILS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.RETURNS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(order => order.id),
        pagination: action.meta.pagination,
      };
    case types.RETURN_DETAILS_GET_SUCCESS:
    case types.RETURN_EDIT_SUCCESS:
    case types.RETURN_CREATE_BY_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    case types.RETURNS_GET_FAILURE:
    case types.RETURN_DETAILS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.RETURN_STATUSES_SET_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };

    default:
      return state;
  }
};

/**
 * MinTotal slice
 */
const initialMinTotalState = {
  value: 0,
  loading: false,
  error: null as string | null,
};

const minTotalReducer: Reducer<typeof initialMinTotalState, Actions> = (
  state = initialMinTotalState,
  action,
) => {
  switch (action.type) {
    case types.ORDER_MIN_TOTAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.ORDER_MIN_TOTAL_SUCCESS:
      return {
        value: action.payload,
        loading: false,
        error: null,
      };
    case types.ORDER_MIN_TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Combined reducer
 */
const initialState = {
  deliveryDays: initialDeliveryDaysState,
  orders: initialOrdersState,
  [returnOrdersCollectionSliceKey]: initialReturnOrdersState,
  decrypted: initialDecryptedState,
  currentOrderStatuses: initialStatusesState,
  minTotal: initialMinTotalState,
};

export type BranchState = typeof initialState;

const combinedReducer = combineReducers<typeof initialState>({
  currentOrderStatuses: statusesReducer,
  deliveryDays: deliveryDaysReducer,
  orders: withExportsReducer('selling', 'orderIds', orderReducer),
  [returnOrdersCollectionSliceKey]: withExportsReducer(
    'return',
    'idList',
    returnOrderReducer,
  ),
  minTotal: minTotalReducer,
  decrypted: decryptedReducer,
});

export default combinedReducer;
