import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectChannelAdmin } from '../../duck/selectors';
import { ChannelIdentity } from '../../types';

interface Props {
  channelId: ChannelIdentity['channelId'];
}

const AdminInfo: React.FC<Props> = ({ channelId }) => {
  const [t] = useTranslation();
  const adminInfo = useSelector(selectChannelAdmin(channelId));

  if (!adminInfo) {
    return <>{t('chats.support.channelUnassigned')}</>;
  }

  return (
    <>
      {`${adminInfo?.firstName} ${adminInfo?.lastName} (${t(
        `roles.${adminInfo?.role || 'moderator'}`,
      )})`}
    </>
  );
};

export default AdminInfo;
