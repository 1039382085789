import { Grid, TextField } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { FilterMenu } from '@rtt-libs/views';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchParams } from '../../api/employees';
import { CreateStaff } from '../../common/staff';
import CreateEmployeeContainer from './CreateEmployeeContainer';

type Props = {
  onChange(params: SearchParams): void;
  initialFilterValues: Record<string, string>;
};

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = React.memo(
  ({
    onChange,
    initialFilterValues: { isActive = '', roleName = '', search = '' },
  }) => {
    const [t] = useTranslation();
    const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      debounceChange({ search: e.target.value });
    };

    const filters = [
      {
        name: 'isActive',
        title: t('distributor.tables.employees.isActive.label'),
        options: [
          {
            value: '',
            label: t('common.resetOption'),
          },
          {
            value: '1',
            label: t('distributor.tables.employees.isActive.active'),
          },
          {
            value: '0',
            label: t('distributor.tables.employees.isActive.inactive'),
          },
        ],
      },
      {
        name: 'roleName',
        title: t('distributor.tables.employees.role'),
        options: [
          {
            value: '',
            label: t('common.resetOption'),
          },
          {
            value: 'distributor_manager',
            label: t('roles.distributor_manager'),
          },
          { value: 'distributor_driver', label: t('roles.distributor_driver') },
        ],
      },
    ];

    return (
      <>
        <Grid item xs={1}>
          <FilterMenu
            filters={filters}
            onChange={onChange}
            initialValues={{ isActive, roleName }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            defaultValue={search}
            name="search"
            label={t('common.search')}
            onChange={onSearchChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={2} />

        <Grid item xs={3}>
          <CreateStaff container={CreateEmployeeContainer} />
        </Grid>
      </>
    );
  },
);

export default FilterRow;
