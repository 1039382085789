import { Box, Tooltip, Typography } from '@material-ui/core';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { getOr } from 'lodash/fp';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { financialString } from '../../../../../utils';
import { OrderedProduct } from '../../../../types';

type Props = {
  id: string | number | symbol;
  value?: unknown;
  fieldNamePrefix?: string;
  returnedItems?: string[];
};

const ProductTotal: React.FC<Props> = ({
  id,
  value,
  fieldNamePrefix = '',
  returnedItems,
}) => {
  const [t] = useTranslation();

  const productId = id.toString();

  const {
    price = 0,
    saleMeasurement,
    total,
    addedByManager,
  } = value as OrderedProduct;

  return (
    <FormSpy subscription={{ values: true, pristine: true }}>
      {({ values, pristine }) => {
        if (pristine)
          return (
            <InfoIcons
              returned={returnedItems?.includes(String(id))}
              addedByManager={addedByManager}
            >
              {`${financialString(total)}\u00A0${t('common.mainCurrency')}`}
            </InfoIcons>
          );

        const { orderWeight, qty } = getOr(
          {},
          fieldNamePrefix.split('.').concat(productId),
          values,
        ) as OrderedProduct;

        let computedTotal =
          price * (saleMeasurement === 'weight' ? orderWeight : qty) || 0;

        computedTotal = Math.max(computedTotal, 0);

        return (
          <InfoIcons addedByManager={addedByManager}>
            {`${financialString(computedTotal)}\u00A0${t(
              'common.mainCurrency',
            )}`}
          </InfoIcons>
        );
      }}
    </FormSpy>
  );
};

export default ProductTotal;

type InfoIconsProps = {
  returned?: boolean;
  addedByManager?: boolean;
};

const InfoIcons: React.FC<InfoIconsProps> = ({
  addedByManager,
  children,
  returned,
}) => {
  const [t] = useTranslation();

  return (
    <Box position="relative">
      <Typography>{children}</Typography>

      {(returned || addedByManager) && (
        <Box position="absolute" right="0" top="100%" p={0.5}>
          {returned && (
            <Tooltip
              title={t('distributor.orders.details.itemReturned') ?? ''}
              aria-label={t('distributor.orders.details.itemReturned')}
            >
              <AssignmentReturnIcon fontSize="small" color="action" />
            </Tooltip>
          )}

          {addedByManager && (
            <Tooltip
              title={
                t('distributor.orders.details.itemAddedByDistributor') ?? ''
              }
              aria-label={t(
                'distributor.orders.details.itemAddedByDistributor',
              )}
            >
              <PersonAddIcon fontSize="small" color="action" />
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};
