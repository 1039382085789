/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import {
  DataWrapped,
  OrderParam,
  Paginated,
  StatusValue,
} from '@rtt-libs/types';
import snakeCase from 'lodash/snakeCase';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { Area } from './territories';
import { transformSimpleErrorMessage } from './mappers';

type EmployeeId = number;

type AreaId = number;

type CompanyId = number;

type CompanyInfo = {
  id: CompanyId;
  status: StatusValue;
};

type Phone = string;

type Email = string;

type EmployeeRole = 'distributor_manager' | 'distributor_driver';

type FetchedEmployee = {
  id: EmployeeId;
  phone: Phone;
  login: string;
  email: Email;
  first_name: string;
  last_name: string;
  password: string;
  is_active: boolean;
  role: EmployeeRole;
  company: CompanyInfo;
  area?: DataWrapped<Area[]>;
};

export class Employee {
  id: EmployeeId;
  phone: Phone;
  login: string;
  email: Email;
  firstName: string;
  lastName: string;
  password: string;
  isActive: boolean;
  role: EmployeeRole;
  company: CompanyInfo;
  areaId: AreaId[];
  areaTitles: string[];
  /** Added generic key to accept Record<string, unknown> typing  */
  [key: string]: unknown;

  constructor(employee: FetchedEmployee) {
    this.id = employee.id;
    this.phone = employee.phone;
    this.login = employee.login;
    this.email = employee.email;
    this.firstName = employee.first_name;
    this.lastName = employee.last_name;
    this.password = employee.password;
    this.isActive = employee.is_active;
    this.role = employee.role;
    this.company = employee.company;
    // feature/RTT-321 - changed fetched data type,
    // so extracted right here to prevent changing whole code of the duck
    this.areaId = employee.area?.data.map(area => area.id) || [];
    this.areaTitles = employee.area?.data.map(area => area.title) || [];
  }
}

type SortValue =
  | 'email'
  | 'phone'
  | 'login'
  | 'first_name'
  | 'last_name'
  | 'is_active';

type APISearchParams = Partial<{
  role_name: EmployeeRole | 'distributor_driver,distributor_manager';
  search: string;
  sort: SortValue | string;
  order: OrderParam;
  is_active: '0' | '1';
  per_page: number;
  page: number;
}>;

export type SearchParams = Partial<{
  roleName: EmployeeRole;
  search: string;
  orderBy: string;
  sort: SortValue | string;
  order: OrderParam;
  isActive: '1' | '0';
  perPage: number;
  page: number;
}>;

export type CreateEmployeeValues = {
  login: string;
  phone: Phone;
  email: Email;
  firstName: string;
  lastName: string;
  role: EmployeeRole;
};

export type EditEmployeeValues = CreateEmployeeValues & {
  id: EmployeeId;
  password: string;
};

/*
 * Employee API handlers
 */

export const getAllDrivers = () =>
  api
    .get<{ data: FetchedEmployee[] }>(`${ENDPOINTS.employees}/drivers`)
    .then(({ data }) => mapEmployeeArray(data.data));

export const getAllManagers = () =>
  api
    .get<{ data: FetchedEmployee[] }>(`${ENDPOINTS.employees}/managers`)
    .then(({ data }) => mapEmployeeArray(data.data));

export const searchEmployees = (params: SearchParams) =>
  api
    .get<Paginated<FetchedEmployee>>(ENDPOINTS.employees, {
      params: mapAPISearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapEmployeeArray))
    .catch(transformSimpleErrorMessage);

export const createEmployee = (values: CreateEmployeeValues) =>
  api
    .post<FetchedEmployee>(ENDPOINTS.employees, mapCreateEmployeeValues(values))
    .then(({ data }) => new Employee(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateEmployeeErrors);
    });

export const editEmployee = (values: EditEmployeeValues) =>
  api
    .put<FetchedEmployee>(
      `${ENDPOINTS.employees}/${values.id}`,
      mapEditEmployeeValues(values),
    )
    .then(({ data }) => new Employee(data))
    .catch(e => {
      throw formErrorTransform(e, transformEditEmployeeErrors);
    });

export const changeEmployeeStatus = ({
  id,
  status,
}: {
  id: EmployeeId;
  status: boolean;
}) =>
  api
    .post<FetchedEmployee>(
      `${ENDPOINTS.employees}/${id}/${ENDPOINTS.employeesStatusSuffix}`,
      { is_active: status },
    )
    .then(({ data }) => new Employee(data))
    .catch(e => {
      throw collectErrorMessagesToError(
        formErrorTransform(e, transformChangeStatusEmployeeErrors),
      );
    });

/*
 * Employee Mappers
 */

function mapEmployeeArray(employeeArray: FetchedEmployee[]) {
  return employeeArray.map(employee => new Employee(employee));
}

function mapAPISearchParams(params: SearchParams): APISearchParams {
  return {
    role_name: params.roleName || undefined,
    search: params.search || undefined,
    sort: params.orderBy && snakeCase(params.orderBy),
    order: params.order || undefined,
    is_active: params.isActive || undefined,
    per_page: params.perPage,
    page: params.page,
  };
}

type CreateEmployeeAPI = {
  user_type: EmployeeRole;
  login: string;
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
};

type EditEmployeeAPI = CreateEmployeeAPI & {
  password: string;
};

function mapCreateEmployeeValues(
  values: CreateEmployeeValues,
): CreateEmployeeAPI {
  return {
    login: values.login,
    phone: values.phone,
    email: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
    user_type: values.role,
  };
}

function mapEditEmployeeValues({
  password,
  ...values
}: EditEmployeeValues): EditEmployeeAPI {
  return {
    password,
    ...mapCreateEmployeeValues(values),
  };
}

const transformCreateEmployeeErrors: TransformAPIError<
  Record<keyof CreateEmployeeAPI, string | string[]>,
  Record<keyof CreateEmployeeValues, string>
> = function transformCreateEmployeeErrors(e) {
  return {
    login: e.login?.toString(),
    phone: e.phone?.toString(),
    email: e.email?.toString(),
    firstName: e.first_name?.toString(),
    lastName: e.last_name?.toString(),
    role: e.user_type?.toString(),
  };
};

const transformEditEmployeeErrors: TransformAPIError<
  Record<keyof EditEmployeeAPI, string | string[]>,
  Record<keyof EditEmployeeValues, string>
> = function transformEditEmployeeErrors(e) {
  return {
    password: e.password?.toString(),
    ...transformCreateEmployeeErrors(e),
  };
};

const transformChangeStatusEmployeeErrors: TransformAPIError<
  Record<'is_active', string | string[]>,
  Record<'isActive', string>
> = function transformChangeStatusEmployeeErrors(e) {
  return {
    isActive: e.is_active?.toString(),
  };
};

function collectErrorMessagesToError(errors: Record<string, string>) {
  return new Error(Object.values(errors).join(' '));
}
