import { DataWrapped } from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import {
  BrandManagerConnection,
  BrandManagerListItemAPI,
  transformSimpleErrorMessage,
} from './mappers';

export const getAllManagersOfRttAPI = (id: number) =>
  api
    .get<DataWrapped<BrandManagerListItemAPI[]>>(ENDPOINTS.rttManagerList(id))
    .then(({ data }) => data.data.map(item => new BrandManagerConnection(item)))
    .catch(transformSimpleErrorMessage);
