/* eslint-disable @typescript-eslint/camelcase */
import { Grid, Paper } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Profile } from '../../api/profile';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import { EditProfileForm } from './EditProfile';

type Props = {
  profile: Profile;
};

const ProfileInfo: React.FC<Props> = ({ profile }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Grid container spacing={2}>
      <Paper style={{ width: '100%' }}>
        <MakeAsyncFunction
          listener={promiseListener}
          start={types.EDIT_PROFILE_REQUEST}
          resolve={types.EDIT_PROFILE_RESPONSE}
          reject={types.EDIT_PROFILE_FAILURE}
        >
          {onSubmit => (
            <Form
              initialValues={profile as Profile}
              onSubmit={async values => {
                const errors = await onSubmit(values);

                if (errors) {
                  enqueueSnackbar(
                    `${t('distributor.profile.companyDataLabel')}: ${
                      errors[FORM_ERROR]
                    }`,
                    { variant: 'error' },
                  );
                } else {
                  enqueueSnackbar(
                    `${t('distributor.profile.companyDataLabel')}: ${t(
                      'common.snackbars.saveSuccess',
                    )}`,
                    { variant: 'success' },
                  );
                }

                return errors;
              }}
              component={props => (
                <EditProfileForm documents={profile.documents} {...props} />
              )}
            />
          )}
        </MakeAsyncFunction>
      </Paper>
    </Grid>
  );
};

export default ProfileInfo;
