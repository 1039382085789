/* eslint-disable @typescript-eslint/camelcase, @typescript-eslint/no-empty-interface */
import { formErrorTransform, mapPaginatedData } from '@rtt-libs/api-services';
import type {
  DataWrapped,
  FetchedDocumentEditorsWithHistory,
  Measurement,
  Paginated,
  FetchedEditor,
} from '@rtt-libs/types';
import { values } from 'lodash';
import snakeCase from 'lodash/snakeCase';
import trim from 'lodash/trim';
import { RttSelectOption } from '../agreedAssortments/types';
import type * as BalanceTypes from '../balances/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import type { FetchedProduct } from './assortment';
import { ExportStatus, FetchedExportStatusField } from './exports';
import { transformSimpleErrorMessage } from './mappers';
import { EnhancedProduct, Contact } from './orders';
import type { PayloadProduct } from './orders';
import { Editor } from './ordersRefactored';
import { RttInfo } from './rtt';
import type { FetchedRttInfo } from './rtt';
import { GroupedPayload, DecryptedPayload } from '../ordersRefactored/types';
import { PAYLOAD_GROUPED_KEY } from '../ordersRefactored/constants';

interface Balance extends BalanceTypes.Balance {}
interface BalanceProduct extends BalanceTypes.BalanceProduct {}
interface BalanceTableParams extends BalanceTypes.BalanceTableParams {} // TODO: DM not used
interface ProductWithOrderedQty extends BalanceTypes.ProductWithOrderedQty {}
type RecommendedOrderValue = BalanceTypes.RecommendedOrderValue;

type FetchedBalance = {
  id: string;
  rtt_id: number;
  products: FetchedBalanceProduct[];
  rtt: FetchedRttInfo;
  created_at: string;
  sku_qty: number;
} & Partial<FetchedExportStatusField> &
  FetchedDocumentEditorsWithHistory & { manager?: Omit<FetchedEditor, 'role'> };

type CreateBalanceAPI = {
  sku_qty: number;
  products: { manager_id: number; product_list: FetchedBalanceProduct[] }[];
};

type FetchedOrderedQty = {
  ordered?: {
    qty: number | null;
    order_weight: number | null;
  };
};

type FetchedBalanceProduct = FetchedOrderedQty & {
  product_id: string;
  product: {
    src: string | null;
    sku: string;
    title: string;
    sale_measurement?: Measurement;
    weight?: number | null;
    brand_id: number;
  };
};

type FetchedRecommendedProduct = FetchedOrderedQty & {
  product_id: string;
  product: PayloadProduct;
};

type FetchedRecommendedOrder = {
  data: {
    manager_id: number;
    suggest_order_id: string;
    suggest_products: Record<string, FetchedRecommendedProduct>;
  }[];
};

class BalanceProduct {
  constructor({ product_id, product, ordered }: FetchedBalanceProduct) {
    this.productId = product_id;
    this.ordered = {
      qty: ordered?.qty ?? 0,
      orderWeight: ordered?.order_weight ?? 0,
    };
    this.product = {
      sku: product.sku,
      title: product.title,
      imageSrc: product.src ?? undefined,
      saleMeasurement: product.sale_measurement ?? 'weight',
      weight: product.weight ?? null,
      brandId: product.brand_id,
    };
  }
}

class Balance {
  constructor(fetched: FetchedBalance) {
    this.id = fetched.id;
    this.products = fetched.products.map(item => new BalanceProduct(item));
    this.rtt = new RttInfo(fetched.rtt);
    this.rttId = fetched.rtt_id;
    this.createdAt = fetched.created_at;
    this.skuQty = fetched.sku_qty;
    this.exportStatus =
      fetched.export_status && new ExportStatus(fetched.export_status);

    if (fetched.manager) {
      this.manager = {
        ...(new Contact(fetched.manager) as Required<Contact>),
        date: fetched.manager.date,
      };
    }
    this.creator = fetched.creator && new Editor(fetched.creator);
    this.managerList = fetched.manager_list?.map(({ date, ...manager }) => ({
      ...(new Contact(manager) as Required<Contact>),
      date,
    }));
  }
}

type FetchedProductWithOrderedQty = FetchedOrderedQty &
  Pick<
    FetchedProduct,
    | 'id'
    | 'sku'
    | 'is_active'
    | 'is_invalid'
    | 'title'
    | 'description'
    | 'manufacturer'
    | 'weight'
    | 'sale_measurement'
    | 'image'
    | 'brand'
    | 'brand_id'
  >;

class ProductWithOrderedQty {
  constructor(fetched: FetchedProductWithOrderedQty) {
    this.id = fetched.id;
    this.sku = fetched.sku;
    this.isActive = fetched.is_active;
    this.isInvalid = fetched.is_invalid;
    this.title = fetched.title;
    this.description = fetched.description ?? '';
    this.manufacturer = fetched.manufacturer;
    this.weight = fetched.weight;
    this.saleMeasurement = fetched.sale_measurement || 'weight';
    this.image = fetched.image;
    this.ordered = {
      qty: fetched.ordered?.qty ?? 0,
      orderWeight: fetched.ordered?.order_weight ?? 0,
    };
    this.brandId = fetched.brand_id ?? fetched.brand?.id ?? 0;
  }
}

/*
 * Balance API handlers
 */

export const getBalances = (params: Partial<BalanceTableParams> = {}) =>
  api
    .get<Paginated<FetchedBalance>>(ENDPOINTS.balances, {
      params: mapBalanceGetParams(params),
    })
    .then(({ data }) =>
      mapPaginatedData(data, balances =>
        balances.map(balance => new Balance(balance)),
      ),
    );

export const getBalanceById = (id: Balance['id']) =>
  api
    .get<FetchedBalance>(`${ENDPOINTS.balances}/${id}`)
    .then(({ data }) => new Balance(data))
    .catch(transformSimpleErrorMessage);

export const createBalance = (
  rttId: number,
  products: BalanceTypes.BalanceProductListWithManager[],
  skuQty: number,
) =>
  api
    .post<FetchedRecommendedOrder>(
      `${ENDPOINTS.balances}/rtt/${rttId}`,
      mapCreateBalanceValues(products, skuQty),
    )
    .then(({ data }) => mapRecommendedOrder(data))
    .catch(e => {
      throw formErrorTransform(e, errors => ({
        skuQty: errors.sku_qty?.toString(),
        products: errors.products?.toString(),
      }));
    });

export const fetchRttOptions = () =>
  api
    .get<DataWrapped<RttSelectOption[]>>(ENDPOINTS.balanceRttList)
    .then(({ data }) => data.data)
    .catch(e => {
      throw formErrorTransform(e);
    });

export const fetchAgreedProducts = (id: number, productId?: string) =>
  api
    .get<DataWrapped<FetchedProductWithOrderedQty[]>>(
      ENDPOINTS.agreedProducts(id),
      { params: { product_id: productId } },
    )
    .then(({ data }) =>
      data.data.map(product => new ProductWithOrderedQty(product)),
    )
    .catch(transformSimpleErrorMessage);

/*
 * Balance mappers
 */

function mapCreateBalanceValues(
  products: BalanceTypes.BalanceProductListWithManager[],
  skuQty: number,
): CreateBalanceAPI {
  return {
    sku_qty: skuQty,
    products: products.map(({ managerId, productList }) => ({
      manager_id: managerId,
      product_list: productList.map(({ productId, product, ordered }) => ({
        product_id: productId,
        product: {
          sku: product.sku,
          title: product.title,
          weight: product.weight,
          sale_measurement: product.saleMeasurement,
          src: product.imageSrc ?? null,
          brand_id: product.brandId,
        },
        ordered: {
          order_weight: ordered.orderWeight,
          qty: ordered.qty,
        },
      })),
    })),
  };
}

// TODO: Create common params mapper with ?switch-case by Type guards
function mapBalanceGetParams({
  perPage,
  page,
  orderBy,
  order,
  search,
  include,
}: Partial<BalanceTableParams>) {
  return {
    per_page: perPage,
    page,
    sort: typeof orderBy === 'string' ? snakeCase(orderBy) : undefined,
    order,
    search: (typeof search === 'string' && trim(search)) || undefined,
    include,
  };
}

function mapRecommendedOrder(fetched: FetchedRecommendedOrder) {
  // FIXME: change products type to common product
  const products = [] as EnhancedProduct[];
  const orderValues = { [PAYLOAD_GROUPED_KEY]: {} } as GroupedPayload<
    RecommendedOrderValue
  >;

  fetched.data.forEach(({ manager_id, suggest_order_id, suggest_products }) => {
    const recommendedValues: DecryptedPayload<
      RecommendedOrderValue
    >['products'] = {};

    values(suggest_products).forEach(({ product_id, product, ordered }) => {
      // FIXME: change mapper
      const productEnhanced = new EnhancedProduct(product);
      products.push(productEnhanced);

      const productId = product_id;

      recommendedValues[productId] =
        productEnhanced.saleMeasurement === 'unit'
          ? {
              ...productEnhanced,
              qty: ordered?.qty ?? 0,
            }
          : {
              ...productEnhanced,
              orderWeight: ordered?.order_weight ?? 0,
            };
    });

    orderValues[PAYLOAD_GROUPED_KEY][manager_id] = {
      suggestOrderId: suggest_order_id,
      payload: {
        products: recommendedValues,
      },
    };
  });

  return { products, orderValues };
}
