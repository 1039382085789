import { Pagination } from '@rtt-libs/types';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { DiscountBrand, DiscountProduct, Sale } from '../../api/sales';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

const selectSalesCollection: Selector<
  PartialRootState,
  Record<Sale['id'], Sale>
> = get([branchName, 'collection']);

const selectSaleIds: Selector<PartialRootState, Sale['id'][]> = get([
  branchName,
  'idList',
]);

export const selectSalesError: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

const selectSalesLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

const selectSalePagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

const selectEditedSaleId: Selector<PartialRootState, Sale['id'] | null> = get([
  branchName,
  'editedSaleId',
]);

export const selectEditedSale = createSelector(
  [
    selectEditedSaleId,
    selectSalesCollection,
    selectSalesLoading,
    selectSalesError,
  ],
  (id, sales, loading, error) => ({
    data: id === null ? null : sales[id],
    loading,
    error,
  }),
);

export const selectSales: Selector<
  PartialRootState,
  {
    data: Sale[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectSalesCollection,
    selectSaleIds,
    selectSalePagination,
    selectSalesLoading,
    selectSalesError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);

export const selectAllSales: Selector<
  PartialRootState,
  {
    data: Sale[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectSalesCollection,
    selectSalePagination,
    selectSalesLoading,
    selectSalesError,
  ],
  (collection, pagination, loading, error) => ({
    data: Object.values(collection),
    pagination,
    loading,
    error,
  }),
);

export const selectDiscountProducts: Selector<
  PartialRootState,
  Record<DiscountProduct['id'], DiscountProduct>
> = get([branchName, 'discountProducts']);

export const selectDiscountBrands: Selector<
  PartialRootState,
  Record<DiscountBrand['id'], DiscountBrand>
> = get([branchName, 'discountBrands']);
