import { Box, Paper } from '@material-ui/core';
import { FieldWithMessages } from '@rtt-libs/views';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import routes from '../../../pages/routes';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import { ProductList } from '../DetailsCommon';
import CreateFormTopRow from './CreateFormTopRow';

const CreateAgreedAssortmentContainer: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box p={2} component={Paper}>
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.AGREED_ASSORTMENT_CREATE_REQUEST}
        resolve={types.AGREED_ASSORTMENT_CREATE_SUCCESS}
        reject={types.AGREED_ASSORTMENT_CREATE_FAILURE}
      >
        {onSubmit => (
          <Form
            onSubmit={values => {
              return onSubmit(values)
                .then(() => {
                  enqueueSnackbar(
                    t('distributor.agreedAssortments.create.successMessage'),
                    { variant: 'success' },
                  );
                  history.push(routes.agreedAssortments);
                })
                .catch(errors => {
                  enqueueSnackbar(errors[FORM_ERROR], { variant: 'error' });
                  return errors;
                });
            }}
          >
            {({ handleSubmit, form: { change } }) => (
              <form onSubmit={handleSubmit}>
                <CreateFormTopRow />

                <OnChange name="rttId">
                  {(value, previous) => {
                    if (!value || value !== previous) {
                      change('productIdList', undefined);
                    }
                  }}
                </OnChange>

                <FieldWithMessages
                  name="productIdList"
                  component="input"
                  type="hidden"
                  validate={value => {
                    return value && value.length
                      ? undefined
                      : t('validation.valueMissing');
                  }}
                />
                <ProductList
                  emptyMessage={t(
                    'distributor.agreedAssortments.create.emptyProductsMessage',
                  )}
                />
              </form>
            )}
          </Form>
        )}
      </MakeAsyncFunction>
    </Box>
  );
};

export default CreateAgreedAssortmentContainer;
