import type { Pagination } from '@rtt-libs/types';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  changeSupervisorStatus,
  createSupervisor,
  editSupervisor,
  getAvailableManagersForAssign,
  searchSupervisors,
} from '../../api/supervisors';
import type { AssignedManager, Supervisor } from '../types';
import * as actions from './actions';
import * as types from './types';

function* searchWorker({
  payload,
}: ReturnType<typeof actions.searchSupervisorsRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Supervisor[]; meta: { pagination: Pagination } } = yield call(
      searchSupervisors,
      payload,
    );

    yield put(actions.searchSupervisorsSuccess(data, meta));
  } catch (e) {
    yield put(actions.searchSupervisorsFailure(e.message));
  }
}

function* createWorker({
  payload,
}: ReturnType<typeof actions.createSupervisorRequest>) {
  try {
    const supervisor: Supervisor = yield call(createSupervisor, payload);

    yield put(actions.createSupervisorSuccess(supervisor));
  } catch (errors) {
    yield put(actions.createSupervisorFailure(errors));
  }
}

function* editWorker({
  payload,
}: ReturnType<typeof actions.editSupervisorRequest>) {
  try {
    const supervisor: Supervisor = yield call(editSupervisor, payload);

    yield put(actions.editSupervisorSuccess(supervisor));
  } catch (errors) {
    yield put(actions.editSupervisorFailure(errors));
  }
}

function* changeStatusWorker({
  payload,
}: ReturnType<typeof actions.changeStatusSupervisorRequest>) {
  try {
    const supervisor: Supervisor = yield call(changeSupervisorStatus, payload);

    yield put(actions.changeStatusSupervisorSuccess(supervisor));
  } catch (e) {
    yield put(actions.changeStatusSupervisorFailure(e.message));
  }
}

function* getAvailableManagersForAssignWorker() {
  try {
    const managers: AssignedManager[] = yield call(
      getAvailableManagersForAssign,
    );

    yield put(actions.getAvailableManagersForAssignSuccess(managers));
  } catch (e) {
    yield put(actions.getAvailableManagersForAssignFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.SUPERVISORS_SEARCH_REQUEST, searchWorker);
  yield takeLatest(types.SUPERVISORS_CREATE_REQUEST, createWorker);
  yield takeLatest(types.SUPERVISORS_EDIT_REQUEST, editWorker);
  yield takeLatest(types.SUPERVISORS_CHANGE_STATUS_REQUEST, changeStatusWorker);
  yield takeLatest(
    types.SUPERVISORS_GET_AVAILABLE_MANAGERS_REQUEST,
    getAvailableManagersForAssignWorker,
  );
}
