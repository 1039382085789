import { Avatar, CardHeader, CardHeaderProps, Link } from '@material-ui/core';
import type { Contact } from '@rtt-libs/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeAvatarPlaceholder, composeUserName } from '../../../../utils';

interface Props extends CardHeaderProps {
  manager: Contact;
  orderId?: string;
}

const ManagerOrderSliceHeader = ({
  manager,
  orderId = '',
  ...props
}: Props) => {
  const [t] = useTranslation();

  const composeTitle = `${composeUserName(manager)}${
    orderId &&
    ` \u2014 ${t('distributor.returnOrders.details.orderLink')} ${orderId}`
  }`;

  return (
    <CardHeader
      component="header"
      titleTypographyProps={{
        variant: 'h6',
      }}
      avatar={
        <Avatar aria-label="manager avatar">
          {composeAvatarPlaceholder(manager.firstName, manager.lastName)}
        </Avatar>
      }
      title={composeTitle}
      subheader={<Subheader phone={manager.phone} email={manager.email} />}
      {...props}
    />
  );
};

export default ManagerOrderSliceHeader;

type SubheaderProps = {
  phone: string;
  email: string;
};

const Subheader = ({ phone, email }: SubheaderProps) => (
  <>
    <Link href={`tel:${phone}`}>{phone}</Link>
    {' : '}
    <Link href={`mailto:${email}`}>{email}</Link>
  </>
);
