import { Box, Grid } from '@material-ui/core';
import React from 'react';
import type * as OrderTypes from '../../../types';
import DateRangeFilter from './DateRangeFilter';

type Props = {
  onChange(params: OrderTypes.OrderSearchParams): void;
  startElement?: React.ReactNode;
  endElement?: React.ReactNode;
  initialFilterValues: { [key: string]: string };
};

/**
 * Component to handle filtering of table data.
 */
const FilterRowOnlyDates: React.FC<Props> = ({
  onChange,
  startElement,
  endElement,
  initialFilterValues: { startDate = null, endDate = null },
}) => {
  return (
    <Grid
      item
      container
      spacing={2}
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={1}>
        {startElement}
      </Grid>

      <DateRangeFilter
        onChange={onChange}
        initialDate={{
          start: startDate,
          end: endDate,
        }}
      />

      <Box clone textAlign="right">
        <Grid item xs={3}>
          {endElement}
        </Grid>
      </Box>
    </Grid>
  );
};

export default FilterRowOnlyDates;
