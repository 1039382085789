import { Pagination } from '@rtt-libs/types';
import { keyBy, keys, without, omit } from 'lodash/fp';
import { Reducer } from 'redux';
import {
  AgreedAssortment,
  AgreedAssortmentWithItems,
  RttSelectOption,
} from '../types';
import { Actions } from './actions';
import * as types from './types';

type AgreedAssortmentId = AgreedAssortment['id'];

const initialState = {
  loading: false,
  collection: {} as Record<
    AgreedAssortmentId,
    AgreedAssortment | AgreedAssortmentWithItems
  >,
  itemIds: [] as AgreedAssortmentId[],
  pagination: {} as Pagination,
  error: null as string | null,
  rttOptions: [] as RttSelectOption[],
  rttOptionsLoading: false,
  deleting: [] as AgreedAssortmentId[],
  hiddenUnDeletedState: [] as AgreedAssortmentId[],
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.AGREED_ASSORTMENT_GET_LIST_REQUEST:
    case types.AGREED_ASSORTMENT_CREATE_REQUEST:
    case types.AGREED_ASSORTMENT_GET_DETAILS_REQUEST:
    case types.AGREED_ASSORTMENT_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.AGREED_ASSORTMENT_FETCH_RTT_REQUEST:
      return {
        ...state,
        rttOptionsLoading: true,
      };
    case types.AGREED_ASSORTMENT_DELETE_REQUEST:
      return {
        ...state,
        itemIds: without([action.payload], state.itemIds),
        deleting: state.deleting.concat(action.payload),
        hiddenUnDeletedState: state.hiddenUnDeletedState.length
          ? state.hiddenUnDeletedState
          : state.itemIds,
      };

    case types.AGREED_ASSORTMENT_GET_LIST_SUCCESS: {
      const agreedAssortmentDictionary = keyBy('id', action.payload);

      return {
        ...state,
        loading: false,
        collection: agreedAssortmentDictionary,
        itemIds: keys(agreedAssortmentDictionary),
        pagination: action.meta.pagination,
      };
    }
    case types.AGREED_ASSORTMENT_CREATE_SUCCESS:
    case types.AGREED_ASSORTMENT_GET_DETAILS_SUCCESS:
    case types.AGREED_ASSORTMENT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    case types.AGREED_ASSORTMENT_FETCH_RTT_SUCCESS:
      return {
        ...state,
        rttOptionsLoading: false,
        rttOptions: action.payload,
      };
    case types.AGREED_ASSORTMENT_DELETE_SUCCESS:
      return {
        ...state,
        deleting: without([action.payload], state.deleting),
        hiddenUnDeletedState: without(
          [action.payload],
          state.hiddenUnDeletedState,
        ),
        collection: omit(action.payload, state.collection),
      };

    case types.AGREED_ASSORTMENT_GET_LIST_FAILURE:
    case types.AGREED_ASSORTMENT_GET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.AGREED_ASSORTMENT_CREATE_FAILURE:
    case types.AGREED_ASSORTMENT_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.AGREED_ASSORTMENT_FETCH_RTT_FAILURE:
      return {
        ...state,
        rttOptionsLoading: false,
      };
    case types.AGREED_ASSORTMENT_DELETE_FAILURE: {
      const deleting = without([action.payload.id], state.deleting);

      return {
        ...state,
        deleting,
        itemIds: without(deleting, state.hiddenUnDeletedState),
      };
    }
    default:
      return state;
  }
};

export default reducer;
