import { Pagination } from '@rtt-libs/types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createEmployee,
  editEmployee,
  Employee,
  getAllDrivers,
  getAllManagers,
  searchEmployees,
  changeEmployeeStatus,
} from '../../api/employees';
import * as actions from './actions';
import * as types from './types';

function* driversWorker() {
  try {
    const drivers: Employee[] = yield call(getAllDrivers);

    yield all([put(actions.getDriversSuccess(drivers))]);
  } catch (e) {
    yield put(actions.getDriversFailure(e.message));
  }
}

function* managersWorker() {
  try {
    const managers: Employee[] = yield call(getAllManagers);

    yield all([put(actions.getManagersSuccess(managers))]);
  } catch (e) {
    yield put(actions.getManagersFailure(e.message));
  }
}

function* employeesWorker({
  payload,
}: ReturnType<typeof actions.searchEmployeesRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Employee[]; meta: { pagination: Pagination } } = yield call(
      searchEmployees,
      payload,
    );

    yield all([put(actions.searchEmployeesSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.searchEmployeesFailure(e.message));
  }
}

function* createWorker({
  payload,
}: ReturnType<typeof actions.createEmployeeRequest>) {
  try {
    const employee: Employee = yield call(createEmployee, payload);

    yield put(actions.createEmployeeSuccess(employee));
  } catch (errors) {
    yield put(actions.createEmployeeFailure(errors));
  }
}

function* editWorker({
  payload,
}: ReturnType<typeof actions.editEmployeeRequest>) {
  try {
    const employee: Employee = yield call(editEmployee, payload);

    yield put(actions.editEmployeeSuccess(employee));
  } catch (errors) {
    yield put(actions.editEmployeeFailure(errors));
  }
}

function* changeStatusWorker({
  payload,
}: ReturnType<typeof actions.changeStatusEmployeeRequest>) {
  try {
    const employee: Employee = yield call(changeEmployeeStatus, payload);

    yield all([put(actions.changeStatusEmployeeSuccess(employee))]);
  } catch (e) {
    yield put(actions.changeStatusEmployeeFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_DRIVERS_REQUEST, driversWorker);
  yield takeLatest(types.GET_MANAGERS_REQUEST, managersWorker);
  yield takeLatest(types.EMPLOYEES_SEARCH_REQUEST, employeesWorker);
  yield takeLatest(types.EMPLOYEE_CREATE_REQUEST, createWorker);
  yield takeLatest(types.EMPLOYEE_EDIT_REQUEST, editWorker);
  yield takeLatest(types.EMPLOYEE_CHANGE_STATUS_REQUEST, changeStatusWorker);
}
