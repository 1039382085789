import { Typography, FormHelperText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedProduct } from '../../../api/orders';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const ProductInfoCell: React.FC<Props> = ({ value }) => {
  const product = value as EnhancedProduct;
  const [t] = useTranslation();

  return (
    <>
      <Typography variant="caption">
        {t('product.sku')}: {product.sku}
      </Typography>
      <Typography
        variant="h6"
        color={!(product.isBrandOperated ?? true) ? 'textSecondary' : undefined}
      >
        {product.title}
      </Typography>
      {product.price ? (
        <Typography variant="caption">
          {product.price}
          {'\u00A0'}
          {t('common.mainCurrency')} /{' '}
          {t([
            `product.values.${product.saleMeasurement}`,
            'product.values.default',
          ])}
        </Typography>
      ) : (
        <FormHelperText error>{t('product.priceUnavailable')}</FormHelperText>
      )}
    </>
  );
};

export default ProductInfoCell;
