import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import TextFieldAdapter from './TextFieldAdapter';

const useStyles = makeStyles(
  createStyles({
    withoutSteppers: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  }),
);

const NumberFieldAdapter: typeof TextFieldAdapter = ({
  input: { type = 'number', ...restInput },
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextFieldAdapter
      input={{ type, ...restInput }}
      className={classes.withoutSteppers}
      {...props}
    />
  );
};

export default NumberFieldAdapter;
