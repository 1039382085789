import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import { Rtt } from '../../../api/rtt';
import type {
  OrderedQtyValue,
  OrderedWeightValue,
  RecommendedOrderValue,
} from '../../../balances/types';
import { OrderCreateSummary } from '../../../common';
import PromptDirtyForm from '../../../common/PromptDirtyForm';
import routes from '../../../pages/routes';
import { promiseListener } from '../../../setup';
import { PAYLOAD_GROUPED_KEY } from '../../constants';
import { types } from '../../duck';
import type * as OrderTypes from '../../types';
import OrderComments from '../commonOrders/OrderComments';
import OrderTotal from '../commonOrders/OrderTotal';
import ReturnOrderProductsTable from '../commonOrders/ReturnOrderProductsTable';
import ProductReturnOrderSelector from '../commonOrders/ReturnOrderProductsTable/ProductOrderSelector';

interface Props {
  rtt: Rtt;
  changeButton?: React.ReactElement;
  orderId?: string;
  initialValues?: Partial<
    OrderTypes.OrderCreateValues<
      OrderTypes.OrderedProduct | RecommendedOrderValue
    >
  >;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

const productsValidate = (
  values: OrderTypes.OrderCreateValues<
    OrderTypes.OrderedProduct | OrderedQtyValue | OrderedWeightValue
  >,
) => {
  return !values.byGroupId ||
    Object.values(values.byGroupId).some(v => !v.payload.products)
    ? { [PAYLOAD_GROUPED_KEY]: 'no products' }
    : undefined;
};

const OrderCreateDetails: React.FC<Props> = ({
  rtt,
  changeButton,
  initialValues,
  orderId,
}) => {
  const rttId = rtt.id;
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onFulfillment = (response: OrderTypes.Order) => {
    enqueueSnackbar(t('distributor.returnOrders.create.successMessage'), {
      variant: 'success',
    });

    history.push(routes.returns);
    return undefined;
  };

  const onRejection = (
    errors?: Partial<OrderTypes.OrderEditValues & { [FORM_ERROR]: string }>,
  ) => {
    enqueueSnackbar(
      errors?.shippingDate ||
        // TODO: write correct errors selector
        // errors?.managerDescription ||
        // errors?.payload ||
        errors?.[FORM_ERROR] ||
        t('common.errorOccurred'),
      {
        variant: 'error',
      },
    );
    return errors;
  };

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.RETURN_CREATE_REQUEST}
      resolve={types.RETURN_CREATE_SUCCESS}
      reject={types.RETURN_CREATE_FAILURE}
    >
      {onSubmit => (
        <Form
          subscription={{ submitting: true }}
          initialValues={initialValues}
          validate={productsValidate}
          onSubmit={values => onSubmit(values).then(onFulfillment, onRejection)}
        >
          {({ handleSubmit, submitting }) => (
            <Paper className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <PromptDirtyForm />

                <Field
                  component="input"
                  name="rttId"
                  initialValue={rttId}
                  type="hidden"
                />

                <Grid container spacing={3}>
                  <OrderCreateSummary
                    rtt={rtt}
                    changeButton={changeButton}
                    totalElement={<OrderTotal originTotal={0} totalOnly />}
                    parentOrderId={orderId}
                  />

                  <ReturnOrderProductsTable
                    submitting={submitting}
                    showEditButton
                    productSelection={
                      <ProductReturnOrderSelector
                        rttId={rttId}
                        orderId={orderId}
                        disabled={submitting}
                      />
                    }
                    comments={name => (
                      <OrderComments
                        managerDescriptionFieldName={name}
                        readOnly={submitting}
                      />
                    )}
                  />
                </Grid>
              </form>
            </Paper>
          )}
        </Form>
      )}
    </MakeAsyncFunction>
  );
};

export default OrderCreateDetails;
