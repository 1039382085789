import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TableWithHistory } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceType, SearchParams } from '../../api/priceTypes';
import FilterRow from './FilterRow';
import priceTypesTableConfig from './tableConfig';

type Props = {
  prices: PriceType[];
  pagination: Pagination;
  loading: boolean;
  onStateChange: (state: SearchParams) => void;
};

/**
 * Wrapper component to pass PriceType data into common table component
 * Also handles routing to PriceType detail page.
 */
const PriceTypesTable: React.FC<Props> = ({
  prices,
  pagination,
  loading = false,
  onStateChange,
}) => {
  const [t] = useTranslation();

  const columnConfig = priceTypesTableConfig(t);

  const { perPage, currentPage } = pagination;

  const initialParams = useMemo(
    () => ({
      perPage: perPage || table.PER_PAGE,
      page: currentPage || 1,
    }),
    [perPage, currentPage],
  );

  const localeTableLabels = {
    sort: t('admin.tables.common.sort', 'Sort'),
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <TableWithHistory
      filterRow={({ onChange, displayOptions }) => (
        <FilterRow onChange={onChange} initialFilterValues={displayOptions} />
      )}
      loading={loading}
      entries={prices}
      columnsConfig={columnConfig}
      perPageOptions={table.PAGINATION_OPTIONS}
      initial={initialParams}
      totalEntriesCount={pagination.total}
      localeLabels={localeTableLabels}
      onStateChange={onStateChange}
    />
  );
};

export default PriceTypesTable;
