import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DiscountRtt } from '../../api/discounts';
import { getRttRequest } from '../../rtt/duck/actions';
import { selectRtt } from '../../rtt/duck/selectors';
import RttAsyncAutocompleteField from './RttAsyncAutocomplete';

interface Props {
  readOnly?: boolean;
}

const RttField: React.FC<Props> = ({ readOnly }) => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectRtt);
  const fetchOptions = useCallback(() => dispatch(getRttRequest()), [dispatch]);

  return (
    <RttAsyncAutocompleteField
      readOnly={readOnly}
      fetchOptions={fetchOptions}
      options={(data as unknown) as DiscountRtt[]}
      loading={loading}
    />
  );
};

export default RttField;
