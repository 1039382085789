import { Pagination } from '@rtt-libs/types';
import { keyBy, without } from 'lodash/fp';
import { Reducer } from 'redux';
import { DiscountBrand, DiscountProduct, Sale } from '../../api/sales';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  collection: {} as Record<Sale['id'], Sale>,
  error: null as string | null,
  idList: [] as Sale['id'][],
  pagination: {} as Pagination,
  editedSaleId: null as null | number,
  discountProducts: {} as Record<DiscountProduct['id'], DiscountProduct>,
  discountBrands: {} as Record<DiscountBrand['id'], DiscountBrand>,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.SALE_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.SALE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(sale => sale.id),
      };
    case types.SALE_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.SALE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(sale => sale.id),
        pagination: action.meta.pagination,
      };
    case types.SALE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SALE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        editedSaleId: action.payload,
      };
    case types.SALE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        discountProducts: action.meta.products,
        discountBrands: action.meta.brands,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    case types.SALE_EDIT_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    case types.SALE_GET_ALL_FAILURE:
    case types.SALE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SALE_DELETE_SUCCESS:
      return {
        ...state,
        idList: without([action.payload], state.idList),
      };
    default:
      return state;
  }
};

export default reducer;
