import { Pagination } from '@rtt-libs/types';
import { isNil } from 'lodash';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  DiscountBrand,
  DiscountProduct,
  DiscountRtt,
  RttDiscount,
} from '../../api/discounts';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

const selectDiscountsCollection: Selector<
  PartialRootState,
  Record<RttDiscount['id'], RttDiscount>
> = get([branchName, 'collection']);

const selectDiscountIds: Selector<PartialRootState, RttDiscount['id'][]> = get([
  branchName,
  'idList',
]);

export const selectDiscountsError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

const selectDiscountsLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

const selectDiscountPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

const selectEditedDiscountId: Selector<
  PartialRootState,
  RttDiscount['id'] | null
> = get([branchName, 'editedDiscountId']);

export const selectEditedDiscount = createSelector(
  [
    selectEditedDiscountId,
    selectDiscountsCollection,
    selectDiscountsLoading,
    selectDiscountsError,
  ],
  (id, discounts, loading, error) => ({
    data: id === null ? null : discounts[id],
    loading,
    error,
  }),
);

export const selectDiscounts: Selector<
  PartialRootState,
  {
    data: RttDiscount[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectDiscountsCollection,
    selectDiscountIds,
    selectDiscountPagination,
    selectDiscountsLoading,
    selectDiscountsError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);

export const selectAllDiscounts: Selector<
  PartialRootState,
  {
    data: RttDiscount[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectDiscountsCollection,
    selectDiscountPagination,
    selectDiscountsLoading,
    selectDiscountsError,
  ],
  (collection, pagination, loading, error) => ({
    data: Object.values(collection),
    pagination,
    loading,
    error,
  }),
);

export const selectDiscountBrands: Selector<
  PartialRootState,
  Record<DiscountBrand['id'], DiscountBrand>
> = get([branchName, 'discountBrands']);

export const selectDiscountProducts: Selector<
  PartialRootState,
  Record<DiscountProduct['id'], DiscountProduct>
> = get([branchName, 'discountProducts']);

const selectRttOptionsCollection: Selector<
  PartialRootState,
  DiscountRtt[]
> = get([branchName, 'rtts', 'collection']);

const selectRttOptionsWithoutDiscountCollection: Selector<
  PartialRootState,
  DiscountRtt[]
> = createSelector(selectRttOptionsCollection, rtts =>
  rtts.filter(rtt => isNil(rtt.discountId)),
);

export const selectRttDiscountsError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'rtts', 'error']);

const selectRttDiscountsLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'rtts',
  'loading',
]);

export const selectRttOptions = createSelector(
  selectRttOptionsCollection,
  selectRttDiscountsLoading,
  selectRttDiscountsError,
  (data, loading, error) => ({ data, loading, error }),
);

export const selectRttOptionsWithoutDiscount = createSelector(
  selectRttOptionsWithoutDiscountCollection,
  selectRttDiscountsLoading,
  selectRttDiscountsError,
  (data, loading, error) => ({ data, loading, error }),
);
