import Tree, {
  ItemId,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeItem,
} from '@atlaskit/tree';
import {
  Button,
  createStyles,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { categories } from '@rtt-libs/constants';
import React, { useEffect, useState } from 'react';

const PADDING_PER_LEVEL = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    listItem: {
      paddingLeft: theme.spacing(0.25),
      width: 'auto',
      display: 'inline-block',
      '&:hover $stealth, &:focus $stealth, &:focus-within $stealth': {
        opacity: 1,
      },
    },
    stealth: {
      opacity: 0,
      transition: '0.2s',
    },
  }),
);

const getIcon = (
  item: TreeItem,
  onExpand: (itemId: ItemId) => void,
  onCollapse: (itemId: ItemId) => void,
  className: string,
) => {
  if (item.children && item.children.length > 0) {
    return item.isExpanded ? (
      <Button
        size="small"
        type="button"
        className={className}
        onClick={() => onCollapse(item.id)}
      >
        <ExpandLessIcon />
      </Button>
    ) : (
      <Button
        size="small"
        type="button"
        className={className}
        onClick={() => onExpand(item.id)}
      >
        <ExpandMoreIcon />
      </Button>
    );
  }
  return (
    <span
      className={className}
      style={{ padding: '0 0.75rem', textAlign: 'center' }}
    >
      &bull;
    </span>
  );
};

const ItemContentSimple: React.FC<{ item: TreeItem }> = ({ item }) => (
  <Typography component="span">{item.data ? item.data.title : ''}</Typography>
);

export const ItemContentWithIdAndDepth: React.FC<{
  item: TreeItem;
  depth?: number;
}> = ({ item, depth }) => (
  <>
    <Typography component="span">{item.data?.title}</Typography>
    {depth !== undefined && depth >= categories.CATEGORY_DEPTH_THRESHOLD && (
      <Typography component="span" color="textSecondary">
        {` [id: ${item.id}]`}
      </Typography>
    )}
  </>
);

type Props = {
  ItemContent?: React.ComponentType<{ item: TreeItem; depth?: number }>;
  treeData: TreeData;
};

const CategoriesTree: React.FC<Props> = ({
  treeData,
  ItemContent = ItemContentSimple,
}) => {
  const classes = useStyles();
  const [tree, changeTree] = useState<TreeData>(treeData);

  useEffect(() => {
    changeTree(treeData);
  }, [treeData]);

  const renderItem = ({
    item,
    onExpand,
    onCollapse,
    provided,
    depth,
  }: RenderItemParams) => {
    return (
      <div
        ref={provided.innerRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.draggableProps}
      >
        <ListItem
          // button
          dense
          className={classes.listItem}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
        >
          {getIcon(item, onExpand, onCollapse, classes.icon)}
          <ItemContent item={item} depth={depth} />
        </ListItem>
      </div>
    );
  };

  const onExpand = (itemId: ItemId) => {
    changeTree(treeState =>
      mutateTree(treeState, itemId, { isExpanded: true }),
    );
  };

  const onCollapse = (itemId: ItemId) => {
    changeTree(treeState =>
      mutateTree(treeState, itemId, { isExpanded: false }),
    );
  };

  return (
    <Tree
      tree={tree}
      renderItem={renderItem}
      onExpand={onExpand}
      onCollapse={onCollapse}
      offsetPerLevel={PADDING_PER_LEVEL}
    />
  );
};

export default CategoriesTree;
