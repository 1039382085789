import { restrictions } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TablePagination } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ProductSearchParams } from '../../types';
import EntitiesSearch from './EntitiesSearch';

type PaginatedProductSearchProps = {
  loading: boolean;
  error: string | null;
  pagination: Pagination;
  onChange(params: ProductSearchParams): void;
  isEmpty?: boolean;
};

const PaginatedProductSearch: React.FC<PaginatedProductSearchProps> = ({
  loading,
  error,
  pagination,
  onChange,
  isEmpty,
  children,
}) => {
  const [t] = useTranslation();

  const localeLabels = {
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <>
      <EntitiesSearch
        loading={loading}
        error={error}
        onChange={onChange}
        isEmpty={isEmpty}
      >
        {children}
      </EntitiesSearch>

      {!isEmpty && (
        <TablePagination
          count={pagination.total || 0}
          onChange={onChange}
          initialRowsPerPage={restrictions.PRODUCTS_LIST_DISPLAY_COUNT}
          paginationProps={{
            labelDisplayedRows: localeLabels.displayedRows,
            labelRowsPerPage: localeLabels.rowsPerPage,
          }}
        />
      )}
    </>
  );
};

export default PaginatedProductSearch;
