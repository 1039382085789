import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { Pagination } from '@rtt-libs/types';
import {
  EnhancedPaginatedTable,
  EntryItemList,
  EntryItemListType,
  TablePagination,
  TopLoadingProgress,
} from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../api/assortment';
import ProductItemLarge from './ProductItemLarge';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0),
    },
    loader: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1200,
    },
  }),
);

type Props = {
  entries: Product[];
  onCardClick(entryIdKey: number | string): void;
  onStateChange(state: Record<string, string | number>): void;
  loading?: boolean;
  pagination: Pagination;
  initialPage?: number;
};

const EntryItemListTest = EntryItemList as EntryItemListType<Product>;

const ProductDisplay: React.FC<Props> = ({
  entries,
  onCardClick,
  onStateChange,
  loading,
  pagination,
  initialPage,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const onPaginationChange = (state: Record<string, string | number>) => {
    onStateChange(state);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <TopLoadingProgress loading={loading} />
      {entries.length > 0 && (
        <Paper className={classes.root}>
          <EnhancedPaginatedTable onStateChange={onPaginationChange}>
            {({ handleChangePagination }) => (
              <>
                <Grid container spacing={2}>
                  <EntryItemListTest data={entries} onClick={onCardClick}>
                    {({ entry }) => (
                      <Grid key={entry.id} item xs={12}>
                        <ProductItemLarge
                          product={entry}
                          onClick={onCardClick}
                        />
                      </Grid>
                    )}
                  </EntryItemListTest>
                </Grid>
                <TablePagination
                  count={pagination.total || entries.length}
                  onChange={handleChangePagination}
                  initialPage={initialPage}
                  initialRowsPerPage={pagination.perPage}
                  paginationProps={{
                    labelDisplayedRows: ({
                      from,
                      to,
                      count,
                    }: Record<string, number>): React.ReactNode =>
                      t('controls.pagination', { from, to, count }),
                    labelRowsPerPage: t('controls.paginationRowsPerPage'),
                  }}
                />
              </>
            )}
          </EnhancedPaginatedTable>
        </Paper>
      )}
    </>
  );
};

export default ProductDisplay;
