import { Dialog } from '@material-ui/core';
import React from 'react';

type Props = {
  open: boolean;
  onClose(): void;
  FormContainer: React.ComponentType<{ onClose(): void }>;
};

const CreateDialog: React.FC<Props> = ({ open, onClose, FormContainer }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="brand-dialog-title">
      <FormContainer onClose={onClose} />
    </Dialog>
  );
};

export default CreateDialog;
