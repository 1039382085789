import { createStyles, makeStyles, Theme } from '@material-ui/core';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { FileFieldAdapter } from '@rtt-libs/views';
import Image from 'material-ui-image';
import React, { useRef } from 'react';
import { Field, FormSpy } from 'react-final-form';

type Props = {
  src: string | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorImage: {
      width: '120px',
      height: '120px',
    },
    imageHover: {
      display: 'block',
      borderRadius: theme.shape.borderRadius,
      border: `solid 2px transparent`,
      transition: 'border 0.25s',
      '&:hover': {
        border: `solid 2px ${theme.palette.primary.light}`,
      },
    },
  }),
);

const ImageProductDetails: React.FC<Props> = ({ src }) => {
  const fileInputRef = useRef<HTMLInputElement>();
  const classes = useStyles();

  return (
    <>
      <label
        htmlFor="image"
        aria-label="upload image of product"
        className={classes.imageHover}
      >
        <FormSpy>
          {({ values }) => (
            <Image
              errorIcon={<WallpaperIcon className={classes.errorImage} />}
              src={
                (values.image?.length &&
                  URL.createObjectURL(values.image[0])) ||
                src ||
                '/'
              }
              imageStyle={{ height: 'initial' }}
            />
          )}
        </FormSpy>
      </label>
      <Field
        name="image"
        component={FileFieldAdapter}
        id="image"
        inputProps={{
          accept: 'image/*',
          multiple: false,
          ref: fileInputRef,
        }}
      />
    </>
  );
};

export default ImageProductDetails;
