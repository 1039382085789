import type { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import { ProductInfo, ProductSearchParams, ReturnProductInfo } from '../types';
import * as types from './types';

export const searchProductsAsRttRequest = (
  rttId: number,
  params?: ProductSearchParams,
) => ({
  type: types.PRODUCTS_AS_RTT_SEARCH_REQUEST,
  payload: { rttId, params },
});

export const searchProductsAsRttSuccess = (
  payload: ProductInfo[],
  meta: { pagination: Pagination },
) => ({
  type: types.PRODUCTS_AS_RTT_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchProductsAsRttFailure = (
  submissionErrors: ProductSearchParams & { [FORM_ERROR]?: string },
) => ({
  type: types.PRODUCTS_AS_RTT_SEARCH_FAILURE,
  payload: submissionErrors,
});

export const searchProductsPricelessRequest = (
  params?: ProductSearchParams,
) => ({
  type: types.PRODUCTS_PRICELESS_SEARCH_REQUEST,
  payload: params,
});

export const searchProductsPricelessSuccess = (
  payload: ProductInfo[],
  meta: { pagination: Pagination },
) => ({
  type: types.PRODUCTS_PRICELESS_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchProductsPricelessFailure = (
  submissionErrors: ProductSearchParams & { [FORM_ERROR]?: string },
) => ({
  type: types.PRODUCTS_PRICELESS_SEARCH_FAILURE,
  payload: submissionErrors,
});

export const searchReturnProductsRequest = (
  rttId: number,
  params?: ProductSearchParams,
) => ({
  type: types.PRODUCTS_RETURN_SEARCH_REQUEST,
  payload: { rttId, params },
});

export const searchReturnProductsSuccess = (
  payload: ReturnProductInfo[],
  meta: { pagination: Pagination },
) => ({
  type: types.PRODUCTS_RETURN_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchReturnProductsFailure = (
  submissionErrors: ProductSearchParams & { [FORM_ERROR]?: string },
) => ({
  type: types.PRODUCTS_RETURN_SEARCH_FAILURE,
  payload: submissionErrors,
});

export const clearProductsState = () => ({
  type: types.PRODUCTS_CLEAR_STATE,
});

export type Actions = ReturnType<
  | typeof searchProductsAsRttRequest
  | typeof searchProductsAsRttSuccess
  | typeof searchProductsAsRttFailure
  | typeof searchProductsPricelessRequest
  | typeof searchProductsPricelessSuccess
  | typeof searchProductsPricelessFailure
  | typeof searchReturnProductsRequest
  | typeof searchReturnProductsSuccess
  | typeof searchReturnProductsFailure
  | typeof clearProductsState
>;
