/**
 * Handle react-final-form issues
 * @see https://final-form.org/docs/react-final-form/faq#why-cant-i-have-numeric-keys-in-an-object
 */

/**
 * Transforms numeric keys to strings
 */
export function stringifyKeys<T = unknown>(
  /** Part of values, those keys will be enhanced by 'key' string */
  values: Record<number | string, T>,
): Record<string, T> {
  return Object.keys(values).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[`key${key}`] = values[key];
    return result;
  }, {} as Record<string, T>);
}

export function deStringifyKeys<T = unknown>(
  /** Part of values, those keys will be enhanced by 'key' string */
  values: Record<string, T>,
): Record<number, T> {
  return Object.keys(values).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[Number(key.substring(3))] = values[key];
    return result;
  }, {} as Record<number, T>);
}
