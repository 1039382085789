import type { Pagination } from '@rtt-libs/types';
import at from 'lodash/fp/at';
import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import type { Administrator } from '../types';
import { branchName } from './const';
import type { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectAdministratorsCollection: Selector<
  PartialRootState,
  Record<Administrator['id'], Administrator>
> = get([branchName, 'collection']);

const selectAdministratorIdList: Selector<
  PartialRootState,
  Administrator['id'][]
> = get([branchName, 'idList']);

export const selectAdministratorsError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

const selectAdministratorsLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

const selectAdministratorPagination: Selector<
  PartialRootState,
  Pagination
> = get([branchName, 'pagination']);

export const selectAdministrators: Selector<
  PartialRootState,
  {
    data: Administrator[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectAdministratorsCollection,
    selectAdministratorIdList,
    selectAdministratorPagination,
    selectAdministratorsLoading,
    selectAdministratorsError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: at(ids, collection),
    pagination,
    loading,
    error,
  }),
);
