import {
  Box,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { keyBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useClearProducts } from '../../../../productsAddition';
import { selectRttManagerListData } from '../../../../rtt/duck/selectors';
import { PayloadGroupedKey, PAYLOAD_GROUPED_KEY } from '../../../constants';
import {
  CombinedPayloadByManager,
  OrderEditValues,
  ReturnedProduct,
} from '../../../types';
import { FormSubmissionErrors } from '../FormSubmissionError';
import Controls from '../OrderProductsTable/Controls';
import {
  makeGroupedFieldName,
  makeManagerDescriptionFieldName,
  makeShopInfoPayloadFieldNamePrefix,
} from '../OrderProductsTable/generateFieldName';
import OrderSliceProductsWrapper from '../OrderProductsTable/OrderSliceProductsWrapper';
import { ResetManagerOrderSlice } from '../OrderProductsTable/ResetManagerOrderSlice';
import { ShopInfoFields } from '../OrderProductsTable/ShopInfoFields';
import ResetPayloadGroupOnEmptyProducts from '../ResetPayloadGroupOnEmptyProducts';
import { parseOrderGroupIdKey } from '../transformGroupIdKey';
import MultiReturnOrderPayloadFormSliceTable from './MultiReturnOrderPayloadFormSliceTable';

interface Props {
  showEditButton: boolean;
  submitting?: boolean;
  comments: (fieldName: string) => React.ReactNode;
  productSelection?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
    scroll: {
      overflowX: 'auto',
    },
  }),
);

// TODO: reuse with OrderProductsTable
const ReturnOrderProductsTable: React.FC<Props> = ({
  showEditButton,
  productSelection,
  ...restProps
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  useClearProducts();

  const {
    input: { value },
  } = useField<OrderEditValues[PayloadGroupedKey]>(PAYLOAD_GROUPED_KEY, {
    subscription: { value: true },
  });

  const groupIdList = Object.keys(value);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <FormSubmissionErrors />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5">
            {t('distributor.returnOrders.details.productsHeading')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {showEditButton && (
            <Box
              display="flex"
              justifyContent="flex-end"
              height="100%"
              alignItems="center"
              className={classes.buttons}
            >
              {productSelection}

              {/* TODO: add submission error wrapper to display error description why button is disabled */}
              <Controls />
            </Box>
          )}
        </Grid>

        {groupIdList.length ? (
          groupIdList.map(groupId => (
            <ReturnProductTableSection
              key={groupId}
              groupId={groupId}
              showEditButton={showEditButton}
              {...restProps}
            />
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>
              {t('distributor.orders.create.noProductsWarning')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ReturnOrderProductsTable;

type ProductTableSectionProps = {
  groupId: string;
  showEditButton: boolean;
  returnedItems?: string[];
  comments: (fieldName: string) => React.ReactNode;
  submitting?: boolean;
  hideResetSection?: boolean;
};

// TODO: if component is usable for orderProductTable - extract and reuse
const ReturnProductTableSection = React.memo(
  ({
    groupId,
    showEditButton,
    returnedItems,
    comments,
    submitting,
    hideResetSection,
  }: ProductTableSectionProps) => {
    const {
      input: { value },
      // TODO: initial used for details & recommended order
      meta: { initial },
    } = useField<CombinedPayloadByManager>(makeGroupedFieldName(groupId), {
      subscription: { value: true, initial: true },
    });

    const groupedProducts = value.payload?.products;
    const [managerId, orderId] = parseOrderGroupIdKey(groupId);

    const managerList = useSelector(selectRttManagerListData);

    // TODO: move to selectors
    const managerCollection = useMemo(
      () =>
        keyBy(
          'id',
          managerList.map(({ manager }) => manager),
        ),
      [managerList],
    );

    const manager = managerCollection[managerId];

    const productList = useMemo(() => Object.values(groupedProducts || {}), [
      groupedProducts,
    ]);

    const initialIds = useMemo(
      () => Object.keys(initial?.payload.products || {}),
      [initial],
    );

    return (
      <Grid item xs={12} key={groupId}>
        {groupId && manager ? (
          <>
            {!initial?.payload.shopInfo && (
              <ShopInfoFields
                fieldNamePrefix={makeShopInfoPayloadFieldNamePrefix(groupId)}
                managerPhone={manager.phone}
              />
            )}

            <ResetPayloadGroupOnEmptyProducts groupId={groupId} />

            <OrderSliceProductsWrapper
              orderId={orderId}
              manager={manager}
              content={
                <MultiReturnOrderPayloadFormSliceTable
                  groupId={groupId}
                  products={productList as ReturnedProduct[]}
                  returnedItems={returnedItems}
                  initialIds={initialIds}
                  readOnly={!showEditButton || submitting}
                />
              }
              headerProps={{
                action: showEditButton && !hideResetSection && (
                  <ResetManagerOrderSlice
                    groupId={groupId}
                    disabled={submitting}
                  />
                ),
              }}
            >
              <CardContent>
                {comments(makeManagerDescriptionFieldName(groupId))}
              </CardContent>
            </OrderSliceProductsWrapper>
          </>
        ) : (
          <>
            {/* TODO: remove after implementing skeleton for SectionHeader while managerInfo is loading */}
            <MultiReturnOrderPayloadFormSliceTable
              groupId={groupId}
              products={productList as ReturnedProduct[]}
              returnedItems={returnedItems}
              initialIds={initialIds}
              readOnly={!showEditButton || submitting}
            />

            {comments(makeManagerDescriptionFieldName(groupId))}
          </>
        )}
      </Grid>
    );
  },
);
