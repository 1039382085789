import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../../common/useSnackbarError';
import { getBrandsRequest } from '../../duck/actions';
import { selectBrandsData } from '../../duck/selectors';
import { BrandSearchParams } from '../../types';

const useBrands = (searchParams?: Partial<BrandSearchParams>) => {
  const dispatch = useDispatch();

  const dataState = useSelector(selectBrandsData);

  useSnackbarError(dataState.error);

  useEffect(() => {
    dispatch(getBrandsRequest(searchParams));
  }, [dispatch, searchParams]);

  return dataState;
};

export default useBrands;
