export const FINISH_REG_REQUEST = '@rtt[D]/FINISH_REG_REQUEST' as const;
export const FINISH_REG_SUCCESS = '@rtt[D]/FINISH_REG_SUCCESS' as const;
export const FINISH_REG_FAILURE = '@rtt[D]/FINISH_REG_FAILURE' as const;

export const UPDATE_REG_REQUEST = '@rtt[D]/UPDATE_REG_REQUEST' as const;
export const UPDATE_REG_SUCCESS = '@rtt[D]/UPDATE_REG_SUCCESS' as const;
export const UPDATE_REG_FAILURE = '@rtt[D]/UPDATE_REG_FAILURE' as const;

export const FINISH_REG_INITIAL_VALUES_REQUEST = '@rtt[D]/FINISH_REG_INITIAL_VALUES_REQUEST' as const;
export const FINISH_REG_INITIAL_VALUES_SUCCESS = '@rtt[D]/FINISH_REG_INITIAL_VALUES_SUCCESS' as const;
export const FINISH_REG_INITIAL_VALUES_FAILURE = '@rtt[D]/FINISH_REG_INITIAL_VALUES_FAILURE' as const;

export const FINISH_REG_STATUS = '@rtt[D]/FINISH_REG_STATUS' as const;
