/* eslint-disable @typescript-eslint/no-empty-interface , @typescript-eslint/camelcase */

import {
  formErrorTransform,
  someBooleanToInt,
  TransformAPIError,
} from '@rtt-libs/api-services';
import { DataWrapped, OrderParam } from '@rtt-libs/types';
import type {
  BrandEditFormValues,
  BrandFormValues,
  BrandSearchParams,
} from '../brands/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { Brand, FetchedBrandAPI, transformSimpleErrorMessage } from './mappers';

type BrandSearchParamsAPI = Partial<{
  title: string;
  is_active: 0 | 1;
  sort: 'title' | 'is_active';
  order: OrderParam;
}>;

export const getBrandListAPI = (params?: Partial<BrandSearchParams>) =>
  api
    .get<DataWrapped<FetchedBrandAPI[]>>(ENDPOINTS.brands, {
      params: params && mapBrandsSearchParams(params),
    })
    .then(({ data }) => data?.data?.map(brand => new Brand(brand)) ?? [])
    .catch(transformSimpleErrorMessage);

export const getAvailableBrandListAPI = (isActive?: boolean) =>
  api
    .get<DataWrapped<FetchedBrandAPI[]>>(ENDPOINTS.brandsAvailable, {
      params: mapBrandsSearchParams({ isActive }),
    })
    .then(({ data }) => data?.data?.map(brand => new Brand(brand)) ?? [])
    .catch(transformSimpleErrorMessage);

export const createBrandAPI = (values: BrandFormValues) =>
  api
    .post<FetchedBrandAPI>(ENDPOINTS.brands, values)
    .then(({ data }) => new Brand(data))
    .catch(e => {
      throw formErrorTransform(e, transformBrandErrors);
    });

export const editBrandAPI = ({ id, ...values }: BrandEditFormValues) =>
  api
    .put<FetchedBrandAPI>(ENDPOINTS.brandsUpdate(id), values)
    .then(({ data }) => new Brand(data))
    .catch(e => {
      throw formErrorTransform(e, transformBrandErrors);
    });

export const changeBrandStatusAPI = ({
  id,
  isActive,
}: {
  id: number;
  isActive: boolean;
}) =>
  api
    .post<FetchedBrandAPI>(ENDPOINTS.brandsUpdate(id, true), {
      is_active: isActive ? 1 : 0,
    })
    .then(({ data }) => new Brand(data))
    .catch(e => {
      throw collectErrorMessagesToError(
        formErrorTransform(e, transformChangeStatusBrandErrors),
      );
    });

/*
 * Map values
 */

function mapBrandsSearchParams(
  params: Partial<BrandSearchParams>,
): BrandSearchParamsAPI {
  return {
    title: params.title,
    is_active: someBooleanToInt(params.isActive),
    sort:
      params.orderBy && (params.orderBy === 'isActive' ? 'is_active' : 'title'),
    order: params.order,
  };
}

const transformBrandErrors: TransformAPIError<
  Record<'title', string | string[]>,
  Record<keyof BrandFormValues, string>
> = function transformCreateBrandErrors(e) {
  return {
    title: e.title?.toString(),
  };
};

const transformChangeStatusBrandErrors: TransformAPIError<
  Record<'is_active', string | string[]>,
  Record<'isActive', string>
> = function transformChangeStatusBrandErrors(e) {
  return {
    isActive: e.is_active?.toString(),
  };
};

function collectErrorMessagesToError(errors: Record<string, string>) {
  return new Error(Object.values(errors).join(' '));
}
