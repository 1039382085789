import { Box, Grid, TextField } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BalanceTableParams } from '../../../types';

type Props = {
  onChange(params: Partial<BalanceTableParams>): void;
  initialFilterValue: Record<string, string>;
  endElement?: React.ReactNode;
};

/**
 * Component to handle filtering of table data.
 */
const BalanceFilterRow: React.FC<Props> = ({
  onChange,
  initialFilterValue: { search = '' },
  endElement,
}) => {
  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  return (
    <Box clone pb={2}>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            defaultValue={search}
            name="search"
            label={t('common.search')}
            onChange={onSearchChange}
            fullWidth
          />
        </Grid>
        <Box display="flex" justifyContent="flex-end" alignItems="center" clone>
          <Grid item xs={12} md={6}>
            {endElement}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default BalanceFilterRow;
