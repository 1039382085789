import { Reducer } from 'redux';
import { mainReducerKey } from './const';
import * as TYPES from './types';
import { Actions } from './actions';

const initialState = {
  loading: false,
  [mainReducerKey]: [] as unknown,
  error: null as unknown,
};

export type State = typeof initialState;

const reducer: Reducer<State, Actions> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        [mainReducerKey]: payload,
      };
    case TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default reducer;
