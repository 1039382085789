import type { Pagination } from '@rtt-libs/types';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  changeAdministratorStatus,
  createAdministrator,
  editAdministrator,
  searchAdministrators,
} from '../../api/administrators';
import type { Administrator } from '../types';
import * as actions from './actions';
import * as types from './types';

function* searchWorker({
  payload,
}: ReturnType<typeof actions.searchAdministratorsRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Administrator[]; meta: { pagination: Pagination } } = yield call(
      searchAdministrators,
      payload,
    );

    yield put(actions.searchAdministratorsSuccess(data, meta));
  } catch (e) {
    yield put(actions.searchAdministratorsFailure(e.message));
  }
}

function* createWorker({
  payload,
}: ReturnType<typeof actions.createAdministratorRequest>) {
  try {
    const administrator: Administrator = yield call(
      createAdministrator,
      payload,
    );

    yield put(actions.createAdministratorSuccess(administrator));
  } catch (errors) {
    yield put(actions.createAdministratorFailure(errors));
  }
}

function* editWorker({
  payload,
}: ReturnType<typeof actions.editAdministratorRequest>) {
  try {
    const administrator: Administrator = yield call(editAdministrator, payload);

    yield put(actions.editAdministratorSuccess(administrator));
  } catch (errors) {
    yield put(actions.editAdministratorFailure(errors));
  }
}

function* changeStatusWorker({
  payload,
}: ReturnType<typeof actions.changeStatusAdministratorRequest>) {
  try {
    const administrator: Administrator = yield call(
      changeAdministratorStatus,
      payload,
    );

    yield put(actions.changeStatusAdministratorSuccess(administrator));
  } catch (e) {
    yield put(actions.changeStatusAdministratorFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.ADMINISTRATORS_SEARCH_REQUEST, searchWorker);
  yield takeLatest(types.ADMINISTRATORS_CREATE_REQUEST, createWorker);
  yield takeLatest(types.ADMINISTRATORS_EDIT_REQUEST, editWorker);
  yield takeLatest(
    types.ADMINISTRATORS_CHANGE_STATUS_REQUEST,
    changeStatusWorker,
  );
}
