import { Box, FormGroup, FormHelperText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ExportResultType } from '../../../types';
import ExportTypeCheckbox from './ExportTypeCheckbox';

export const MULTI_EXPORT_INITIAL_CHECKED: ExportResultType[] = [
  'selling',
  'return',
  'balance',
];

interface Props {
  fieldName: string;
  disabled?: boolean;
}

const ExportCheckboxList = ({ fieldName, disabled }: Props) => {
  const [t] = useTranslation();

  return (
    <FormGroup row>
      <ExportTypeCheckbox
        label={t('distributor.export.orders.typeLabel')}
        name={fieldName}
        value="selling"
        disabled={disabled}
      />
      <ExportTypeCheckbox
        label={t('distributor.export.returns.typeLabel')}
        name={fieldName}
        value="return"
        disabled={disabled}
      />
      <ExportTypeCheckbox
        label={t('distributor.export.balances.typeLabel')}
        name={fieldName}
        value="balance"
        disabled={disabled}
      />
      <Box clone alignSelf="center">
        <FormHelperText component="span">
          {t('distributor.export.documentTypes.groupDescription')}
        </FormHelperText>
      </Box>
    </FormGroup>
  );
};

export default ExportCheckboxList;
