import { Button, makeStyles, createStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreatePriceTypesDialog from './CreatePriceTypesDialog';

const useStyles = makeStyles(
  createStyles({
    buttonAlign: {
      marginLeft: 'auto',
      display: 'block',
    },
  }),
);

const CreatePriceTypesButton = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [dialogState, changeDialogState] = useState<boolean>(false);

  const handleDialogOpen = () => changeDialogState(true);
  const handleDialogClose = () => changeDialogState(false);

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        color="primary"
        variant="contained"
        className={classes.buttonAlign}
      >
        {t('distributor.priceTypes.add.buttonLabel')}
      </Button>

      <CreatePriceTypesDialog open={dialogState} onClose={handleDialogClose} />
    </>
  );
};

export default CreatePriceTypesButton;
