import type { Pagination } from '@rtt-libs/types';
import at from 'lodash/fp/at';
import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import type { AssignedManager, Supervisor } from '../types';
import { branchName } from './const';
import type { BranchState } from './reducer';

const emptyManagerList: AssignedManager[] = [];

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectSupervisorsCollection: Selector<
  PartialRootState,
  Record<Supervisor['id'], Supervisor>
> = get([branchName, 'collection']);

const selectSupervisorIdList: Selector<
  PartialRootState,
  Supervisor['id'][]
> = get([branchName, 'idList']);

export const selectSupervisorsError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

const selectSupervisorsLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

const selectSupervisorPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectSupervisors: Selector<
  PartialRootState,
  {
    data: Supervisor[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectSupervisorsCollection,
    selectSupervisorIdList,
    selectSupervisorPagination,
    selectSupervisorsLoading,
    selectSupervisorsError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: at(ids, collection),
    pagination,
    loading,
    error,
  }),
);

const selectSupervisorManagerList = createSelector(
  selectSupervisorsCollection,
  (_: PartialRootState, id: Supervisor['id']) => id,
  (supervisors, id) => supervisors[id]?.managerList ?? emptyManagerList,
);

export const selectSupervisorManagerListById = (id?: Supervisor['id']) => (
  state: PartialRootState,
) =>
  id !== undefined ? selectSupervisorManagerList(state, id) : emptyManagerList;

// TODO: copies from employees

const mapToOptions = (
  {
    id,
    firstName,
    lastName,
    isActive,
  }: AssignedManager = {} as AssignedManager,
) => ({
  value: id,
  label: `${firstName} ${lastName}`,
  meta: {
    isActive,
  },
});

const selectAvailableManagers: Selector<
  PartialRootState,
  AssignedManager[]
> = get([branchName, 'availableManagers']);

const selectManagerOptions = createSelector(
  [
    selectAvailableManagers,
    (_: PartialRootState, existed: AssignedManager[]) => existed,
  ],
  (available, existed) => existed.concat(available).map(mapToOptions),
);

export const selectAvailableManagerOptions = (
  existed: AssignedManager[] = emptyManagerList,
) => (state: PartialRootState) => selectManagerOptions(state, existed);

const selectAvailableManagersLoading: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'managersLoading']);

const selectAvailableManagersError: Selector<
  PartialRootState,
  null | string
> = get([branchName, 'managersError']);

export const selectAvailableManagersState = createSelector(
  selectAvailableManagersLoading,
  selectAvailableManagersError,
  (loading, error) => ({ loading, error }),
);
