import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  createdAt: string;
  skuQty?: number;
  headingBadge?: React.ReactNode;
}

const BalanceInfo = ({ id, createdAt, skuQty, headingBadge }: Props) => {
  const [t] = useTranslation();

  const createdAtDateString = t('formattedDateTime', { date: createdAt });

  const renderCaption = (
    <Box component="span" display="flex">
      <Box component="span">
        {t('distributor.balances.createdAt')}: {createdAtDateString}
      </Box>
      {skuQty && (
        <Box ml={2} component="span">
          {t('distributor.balances.create.skuQtyLabel')}: {skuQty}
        </Box>
      )}
    </Box>
  );

  return (
    <BalanceInfoLayout
      heading={`${t('distributor.balances.id')}: ${id}`}
      caption={renderCaption}
      headingBadge={headingBadge}
    />
  );
};

interface BalanceInfoLayoutProps {
  heading: React.ReactNode;
  caption: React.ReactNode;
  headingBadge?: React.ReactNode;
}

const BalanceInfoLayout = ({
  heading,
  caption,
  headingBadge,
}: BalanceInfoLayoutProps) => {
  return (
    <Box mb={1}>
      <Box clone display="flex" alignItems="center">
        <Typography variant="h6">
          {heading}
          {headingBadge}
        </Typography>
      </Box>

      <Typography variant="caption">{caption}</Typography>
    </Box>
  );
};

const Loading = () => (
  <BalanceInfoLayout
    heading={<Skeleton width="75%" />}
    caption={<Skeleton width="50%" />}
  />
);

BalanceInfo.Loading = Loading;

export default BalanceInfo;
