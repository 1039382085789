import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import { LoadingButton } from '@rtt-libs/views';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';

interface Props {
  submitLabel: React.ReactNode;
  onClose(): void;
}

const DialogActionsButtons = ({ submitLabel, onClose }: Props) => {
  const [t] = useTranslation();
  const {
    submitting,
    pristine,
    hasValidationErrors,
    hasSubmitErrors,
    modifiedSinceLastSubmit,
  } = useFormState({
    subscription: {
      submitting: true,
      pristine: true,
      hasValidationErrors: true,
      hasSubmitErrors: true,
      modifiedSinceLastSubmit: true,
    },
  });

  return (
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {t('controls.cancel')}
      </Button>
      <LoadingButton
        type="submit"
        color="primary"
        variant="contained"
        loading={submitting}
        disabled={
          submitting ||
          pristine ||
          hasValidationErrors ||
          (hasSubmitErrors && !modifiedSinceLastSubmit)
        }
      >
        {submitLabel}
      </LoadingButton>
    </DialogActions>
  );
};

export default DialogActionsButtons;
