/* eslint-disable @typescript-eslint/camelcase */
import type {
  DeliveryWeek,
  FixedLengthArray,
  GeoLocation,
} from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import {
  BrandManagerConnection,
  EmployeeInfo,
  FetchedBrandAPI,
  mapBrandManagerValuesToAPIFormat,
} from './mappers';
import type { BrandManagerValues } from '../types';

export type PolygonNode<T extends string | number = number> = {
  lat: T;
  lng: T;
};

export type PriceType = {
  id: number;
  title: string;
  coefficient: number;
  created_at: string;
};

type BrandManagerListItemAPI = {
  id: number;
  area_id: number;
  brand_id: number;
  manager_id: number;
  brand: FetchedBrandAPI;
  manager: EmployeeInfo;
};

export type FetchedTerritory = {
  id: number;
  title: string;
  driver_id: number;
  pricetype_id: number;
  polygon: PolygonNode<string>[];
  is_default_delivery_days: boolean;
  delivery_days: DeliveryWeek;
  created_at: string;
  driver?: EmployeeInfo;
  price_type?: PriceType;
  is_balance_enabled?: boolean;
  manager_list: {
    data: BrandManagerListItemAPI[];
  };
};

export type FetchedTerritoryList = {
  data: FetchedTerritory[];
};

export class Area {
  id: number;
  title: string;
  driverId: number;
  priceTypeId: number;
  paths: { lat: number; lng: number }[];
  isDefaultDeliveryDays: boolean;
  deliveryDays: FixedLengthArray<boolean, 7>;
  createdAt: string;
  isBalanceEnabled?: boolean;
  managerList: BrandManagerConnection[];

  constructor(area: FetchedTerritory) {
    this.id = area.id;
    this.title = area.title;
    this.driverId = area.driver_id;
    this.priceTypeId = area.pricetype_id ?? undefined;
    this.paths = area.polygon.map(({ lat, lng }) => ({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    }));
    this.isDefaultDeliveryDays = area.is_default_delivery_days;
    this.deliveryDays = area.delivery_days;
    this.createdAt = area.created_at;
    this.isBalanceEnabled = area.is_balance_enabled;
    this.managerList = area.manager_list.data
      .filter(v => v.manager_id)
      .map(item => new BrandManagerConnection(item));
  }
}

export const mapAreaArray = (areaArray: FetchedTerritory[]) =>
  areaArray.map(area => new Area(area));

export const getTerritoriesList = () =>
  api
    .get<FetchedTerritoryList>(ENDPOINTS.territories, {
      params: {
        include: 'manager,driver,price_type',
      },
    })
    .then(({ data: { data } }) => mapAreaArray(data));

type ChangedAreaApi = {
  id: number;
  polygon: PolygonNode[];
};

type ChangedArea = {
  id: number;
  paths: PolygonNode[];
};

class AreaApiAccept {
  title: string;
  driver_id: number;
  pricetype_id: number;
  polygon: PolygonNode[];
  is_default_delivery_days: boolean;
  delivery_days?: DeliveryWeek;
  deleted_areas?: number[];
  changed_areas?: ChangedAreaApi[];
  is_balance_enabled?: boolean;
  manager_list: {
    brand_id: number;
    manager_id: number | null;
  }[];

  constructor(values: AreaCreateValues) {
    this.title = values.title;
    this.driver_id = values.driverId;
    this.pricetype_id = values.priceTypeId;
    this.polygon = values.paths;
    this.is_default_delivery_days = values.isDefaultDeliveryDays;
    this.delivery_days = values.deliveryDays;
    this.deleted_areas = values.deletedAreas;
    this.changed_areas =
      values.changedAreas &&
      Object.entries(values.changedAreas).map(([id, polygon]) => ({
        id: parseInt(id, 10),
        polygon,
      }));
    this.is_balance_enabled = values.isBalanceEnabled;

    this.manager_list = mapBrandManagerValuesToAPIFormat(values.managerList);
  }
}

export type AreaCreateValues = {
  title: string;
  driverId: number;
  priceTypeId: number;
  paths: PolygonNode[];
  isDefaultDeliveryDays: boolean;
  deliveryDays?: DeliveryWeek;
  deletedAreas?: number[];
  changedAreas?: Record<number, GeoLocation[]>;
  isBalanceEnabled?: boolean;
} & BrandManagerValues;

export const createArea = async (values: AreaCreateValues) => {
  const valuesMapped = new AreaApiAccept(values);

  const { data } = await api.post<FetchedTerritory>(
    ENDPOINTS.territories,
    valuesMapped,
  );
  return new Area(data);
};

export const editArea = async ({
  id,
  values,
}: {
  id: number;
  values: AreaCreateValues;
}) => {
  const valuesMapped = new AreaApiAccept(values);

  const { data } = await api.put<FetchedTerritory>(
    `${ENDPOINTS.territories}/${id}`,
    valuesMapped,
  );
  return new Area(data);
};

export const deleteArea = (id: number) =>
  api.delete(`${ENDPOINTS.territories}/${id}`);
