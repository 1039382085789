import { RoleRules, ROLES, UserInfoType } from '@rtt-libs/auth';
import { NOT_CONFIRMED_STATUSES } from '@rtt-libs/constants';

export const PERMISSIONS = {
  APP_ACCESS: 'app:access',
  DISPLAY_RTT_MANAGER_LIST: 'display:rttManagerList',
  EDIT_ACTIONS_RTT: 'edit:actionsRtt',
  EDIT_ACTIONS_RTT_MASS_MAIL: 'edit:actionsRttMassMail',
  EDIT_ACTIONS_RTT_PRICE_TYPES: 'edit:actionsRttPriceTypes',
  EDIT_BALANCES_RTT_SETTINGS: 'edit:balancesRttSettings',
  EDIT_RTT_MANAGER_LIST: 'edit:rttManagerList',
  NAV_SUPERVISOR_MANAGERS: 'nav:supervisor_managers',
  NAV_ADMINISTRATORS: 'nav:administrators',
  NAV_AGREED_ASSORTMENT_CREATE: 'nav:agreedAssortmentCreate',
  NAV_AGREED_ASSORTMENT_DETAILS: 'nav:agreedAssortmentDetails',
  NAV_AGREED_ASSORTMENT_EDIT: 'nav:agreedAssortmentEdit',
  NAV_AGREED_ASSORTMENTS_LIST: 'nav:agreedAssortmentsList',
  NAV_AREAS: 'nav:areas',
  NAV_BALANCE_NEW: 'nav:balancesNew',
  NAV_BALANCES: 'nav:balances',
  NAV_BRANDS: 'nav:brands',
  NAV_PROFILE: 'nav:profile',
  NAV_ASSORTMENT: 'nav:assortment',
  NAV_CATEGORIES: 'nav:categories',
  NAV_CHATS: 'nav:chats',
  NAV_MASS_MAIL: 'nav:massMail',
  NAV_EMPLOYEES: 'nav:employees',
  NAV_EXPORTS: 'nav:exports',
  NAV_FINISH_REGISTRATION: 'nav:finishRegistration',
  NAV_ORDER_DETAILS: 'nav:orderDetails',
  NAV_ORDER_NEW: 'nav:orderNew',
  NAV_ORDERS: 'nav:orders',
  NAV_PRICE_TYPES: 'nav:priceTypes',
  NAV_RETURN_DETAILS: 'nav:returnDetails',
  NAV_RETURN_NEW: 'nav:returnNew',
  NAV_RETURNS: 'nav:returns',
  NAV_RTT: 'nav:rtt',
  NAV_RTT_DETAILS: 'nav:rttDetails',
  NAV_DISCOUNTS: 'nav:discounts',
  NAV_DISCOUNTS_CREATE: 'nav:discountsCreate',
  NAV_DISCOUNTS_DETAILS: 'nav:discountsDetails',
  NAV_SALES: 'nav:sales',
  NAV_SALES_CREATE: 'nav:salesCreate',
  NAV_SALES_DETAILS: 'nav:salesDetails',
  NAV_SETTINGS: 'nav:settings',
  NAV_SHOW: 'nav:show',
  NAV_SUPERVISORS: 'nav:supervisors',
  NAV_SUPPORT: 'nav:support',
} as const;

type Permissions = typeof PERMISSIONS[keyof typeof PERMISSIONS];

const checkUserActivity = (user: UserInfoType | null): boolean => {
  const restrictedStatuses = ['disabled'];

  return (
    !restrictedStatuses.includes(user?.company?.status ?? '') &&
    !!user?.is_active
  );
};

const checkRttVisibility = (user: UserInfoType | null): boolean => {
  const availableStatuses = ['active', 'deletion'];
  return availableStatuses.includes(user?.company?.status ?? '');
};

const COMMON_STATIC_PERMISSIONS = [
  PERMISSIONS.NAV_BALANCES,
  PERMISSIONS.NAV_ORDER_DETAILS,
  PERMISSIONS.NAV_ORDERS,
  PERMISSIONS.NAV_RETURN_DETAILS,
  PERMISSIONS.NAV_RETURNS,
  PERMISSIONS.NAV_RTT_DETAILS,
];

const ADMIN_STATIC_PERMISSIONS = [
  PERMISSIONS.DISPLAY_RTT_MANAGER_LIST,
  PERMISSIONS.NAV_AREAS,
  PERMISSIONS.NAV_ASSORTMENT,
  PERMISSIONS.NAV_CATEGORIES,
  PERMISSIONS.NAV_BRANDS,
  PERMISSIONS.NAV_EMPLOYEES,
  PERMISSIONS.NAV_EXPORTS,
  PERMISSIONS.NAV_PRICE_TYPES,
  PERMISSIONS.NAV_SALES,
  PERMISSIONS.NAV_SALES_CREATE,
  PERMISSIONS.NAV_SALES_DETAILS,
  PERMISSIONS.NAV_SETTINGS,
  PERMISSIONS.NAV_PROFILE,
  PERMISSIONS.NAV_SUPERVISORS,
  PERMISSIONS.NAV_SUPPORT,
];

const COMMON_DYNAMIC_PERMISSIONS = {
  [PERMISSIONS.APP_ACCESS]: checkUserActivity,
  [PERMISSIONS.NAV_AGREED_ASSORTMENT_DETAILS]: checkRttVisibility,
  [PERMISSIONS.NAV_AGREED_ASSORTMENTS_LIST]: checkRttVisibility,
  [PERMISSIONS.NAV_BALANCE_NEW]: checkRttVisibility,
  [PERMISSIONS.NAV_ORDER_NEW]: checkRttVisibility,
  [PERMISSIONS.NAV_RETURN_NEW]: checkRttVisibility,
  [PERMISSIONS.NAV_RTT]: checkRttVisibility,
};

const ADMIN_DYNAMIC_PERMISSIONS = {
  [PERMISSIONS.EDIT_ACTIONS_RTT_PRICE_TYPES]: checkRttVisibility,
  [PERMISSIONS.EDIT_ACTIONS_RTT]: checkRttVisibility,
  [PERMISSIONS.EDIT_BALANCES_RTT_SETTINGS]: checkRttVisibility,
  [PERMISSIONS.EDIT_RTT_MANAGER_LIST]: checkRttVisibility,
  [PERMISSIONS.NAV_AGREED_ASSORTMENT_CREATE]: checkRttVisibility,
  [PERMISSIONS.NAV_AGREED_ASSORTMENT_EDIT]: checkRttVisibility,
  [PERMISSIONS.NAV_DISCOUNTS]: checkRttVisibility,
  [PERMISSIONS.NAV_DISCOUNTS_CREATE]: checkRttVisibility,
  [PERMISSIONS.NAV_DISCOUNTS_DETAILS]: checkRttVisibility,
};

const EMPLOYEES_STATIC_PERMISSIONS = [
  PERMISSIONS.NAV_SHOW,
  PERMISSIONS.NAV_CHATS,
  PERMISSIONS.NAV_MASS_MAIL,
];

const EMPLOYEES_DYNAMIC_PERMISSIONS = {
  [PERMISSIONS.EDIT_ACTIONS_RTT_MASS_MAIL]: checkRttVisibility,
};

const rules: RoleRules<Permissions> = {
  [ROLES.DISTR_OWNER]: {
    static: [
      ...COMMON_STATIC_PERMISSIONS,
      ...ADMIN_STATIC_PERMISSIONS,
      PERMISSIONS.NAV_ADMINISTRATORS,
    ],
    dynamic: {
      ...COMMON_DYNAMIC_PERMISSIONS,
      ...ADMIN_DYNAMIC_PERMISSIONS,
      [PERMISSIONS.NAV_FINISH_REGISTRATION]: user =>
        [...NOT_CONFIRMED_STATUSES, undefined].includes(user?.company?.status),
      [PERMISSIONS.NAV_SHOW]: user =>
        !user?.is_company_creation_required && !!user?.company?.status,
    },
  },
  [ROLES.DISTR_ADMIN]: {
    static: [
      ...COMMON_STATIC_PERMISSIONS,
      ...ADMIN_STATIC_PERMISSIONS,
      PERMISSIONS.NAV_SHOW,
    ],
    dynamic: {
      ...COMMON_DYNAMIC_PERMISSIONS,
      ...ADMIN_DYNAMIC_PERMISSIONS,
    },
  },
  [ROLES.DISTR_SUPERVISOR]: {
    static: [
      ...COMMON_STATIC_PERMISSIONS,
      ...EMPLOYEES_STATIC_PERMISSIONS,
      PERMISSIONS.NAV_SUPERVISOR_MANAGERS,
    ],
    dynamic: {
      ...COMMON_DYNAMIC_PERMISSIONS,
      ...EMPLOYEES_DYNAMIC_PERMISSIONS,
    },
  },
  [ROLES.DISTR_MANAGER]: {
    static: [...COMMON_STATIC_PERMISSIONS, ...EMPLOYEES_STATIC_PERMISSIONS],
    dynamic: {
      ...COMMON_DYNAMIC_PERMISSIONS,
      ...EMPLOYEES_DYNAMIC_PERMISSIONS,
    },
  },
};

export default rules;
