import React, { useState } from 'react';
import StaffActionsMenu from './ActionsMenu';
import { EditStaffContext } from './EditContext';
import EditStaffDialog from './EditStaffDialog';
import { Staff } from '../../types';

type Props = {
  id: string | number;
  value?: Staff;
  container: React.ComponentType<{ onClose(): void }>;
  statusActionTypes: Record<'start' | 'resolve' | 'reject', string>;
};

const EditStaffActions: React.FC<Props> = ({
  container,
  statusActionTypes,
  ...props
}) => {
  const { value } = props;
  const [dialogState, changeDialogState] = useState<boolean>(false);

  const handleDialogOpen = () => changeDialogState(true);
  const handleDialogClose = () => changeDialogState(false);

  return (
    <>
      <StaffActionsMenu
        {...props}
        actionTypes={statusActionTypes}
        onEditClick={handleDialogOpen}
      />

      <EditStaffContext.Provider value={value}>
        <EditStaffDialog
          open={dialogState}
          onClose={handleDialogClose}
          FormContainer={container}
        />
      </EditStaffContext.Provider>
    </>
  );
};

export default EditStaffActions;
