import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  FieldWithMessages as Field,
  LoadingButton,
  TextFieldAdapter,
} from '@rtt-libs/views';
import { Config } from 'final-form';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Product, ProductUpdate } from '../../../api/assortment';
import AdditionalProductDetails from './AdditionalProductDetails';
import { validateProductDetails } from './formValidation';
import ImageProductDetails from './ImageProductDetails';
import MainProductDetails from './MainProductDetails';
import OptionalProductDetails from './OptionalProductDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(4),
    },
    badgeLike: {
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(1),
      backgroundColor: '#334D6E',
      borderRadius: 4,
      fontSize: 11,
      color: theme.palette.primary.contrastText,
    },
    input: {
      margin: theme.spacing(1, 0),
    },
    alignRight: {
      marginLeft: 'auto',
    },
  }),
);

type Props = { product?: Product; onSubmit: Config<ProductUpdate>['onSubmit'] };

const ProductDetails: React.FC<Props> = ({ product, onSubmit }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const initialValues: Partial<ProductUpdate> | undefined = useMemo(
    () =>
      product && {
        sku: product.sku,
        title: product.title,
        description: product.description,
        additionalInfo: product.additionalInfo || undefined,
        manufacturer: product.manufacturer,
        categoryId: product.category && product.category.id,
        expirationDays: product.expirationDays,
        price: product.price,
        priceCurrencyId: product.priceCurrency && product.priceCurrency.id,
        saleMeasurement: product.saleMeasurement,
        weight: product.weight,
        isActive: product.isActive,
        isRevocable: product.isRevocable,
        brand: product.brand && {
          id: product.brand.id,
          name: product.brand.title,
          isActive: product.brand.isActive,
        },
      },
    [product],
  );

  const additionalFieldNames = useMemo(
    () => product?.additionalInfo && Object.keys(product.additionalInfo),
    [product],
  );

  const validateBrand = useMemo(() => validateProductDetails(t), [t]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      destroyOnUnregister
      subscription={{
        submitting: true,
        hasSubmitErrors: true,
        dirtySinceLastSubmit: true,
        pristine: true,
      }}
      validate={validateBrand}
    >
      {({
        handleSubmit,
        form,
        submitting,
        hasSubmitErrors,
        dirtySinceLastSubmit,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} justify="flex-end">
            <Grid item>
              <LoadingButton
                loading={submitting}
                disabled={
                  pristine ||
                  submitting ||
                  (hasSubmitErrors && !dirtySinceLastSubmit)
                }
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('controls.save')}
              </LoadingButton>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={4} lg={2}>
                {!product ? (
                  <Field
                    name="sku"
                    component={TextFieldAdapter}
                    required
                    label={t('product.sku')}
                  />
                ) : (
                  <Typography className={classes.badgeLike}>
                    {t('product.sku')}: <strong>{product?.sku}</strong>
                  </Typography>
                )}
                <ImageProductDetails src={product?.image?.src} />
              </Grid>
              <Grid container spacing={4} item xs={12} sm={8} lg={5}>
                <Grid item xs={12}>
                  <MainProductDetails changeInput={form.change} />
                </Grid>
                {additionalFieldNames && (
                  <Grid item xs={12}>
                    <AdditionalProductDetails
                      fieldNames={additionalFieldNames}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={8} lg={5} className={classes.alignRight}>
                <OptionalProductDetails product={product} />
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Form>
  );
};

export default ProductDetails;
