import DayjsUtils from '@date-io/dayjs';
import { Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchDiscountsRequest } from '../../duck/actions';
import { selectDiscounts } from '../../duck/selectors';
import { DiscountsSearchParams } from '../../../api/discounts';
import DiscountsTable from './DiscountsTable';

/**
 * Container to fetch & filter discounts & render them in table.
 */
const DiscountListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { data, pagination, loading, error } = useSelector(selectDiscounts);

  const onTableStateChange = useCallback(
    (options: DiscountsSearchParams) => {
      dispatch(searchDiscountsRequest(options));
    },
    [dispatch],
  );

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <>
        {error && <Typography color="error">{error}</Typography>}
        <DiscountsTable
          onStateChange={onTableStateChange}
          discounts={data}
          pagination={pagination}
          loading={loading}
        />
      </>
    </MuiPickersUtilsProvider>
  );
};

export default DiscountListContainer;
