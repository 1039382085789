import { MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPriceTypesRequest } from '../../duck/actions';
import { getPriceTypesOptionsSel } from '../../duck/selectors';
import SelectField from '../../../common/SelectField';

interface Props {
  options: { label: string; value: number }[];
  additionalLabel?: React.ReactNode;
}

const PriceTypeSelect = ({ options, additionalLabel }: Props) => {
  const [t] = useTranslation();

  const renderPriceTypeAdditionalOption: React.ReactNode = (
    <MenuItem value="">
      <em>{additionalLabel || t('distributor.areas.defaultPriceType')}</em>
    </MenuItem>
  );

  return (
    <SelectField
      name="priceTypeId"
      label={t('distributor.areas.priceTypeLabel')}
      options={options}
      additionalOptions={renderPriceTypeAdditionalOption}
      displayEmpty
      allowNull
      formControlProps={{
        margin: 'dense',
      }}
    />
  );
};

export default PriceTypeSelect;

export const usePriceTypeOptions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPriceTypesRequest());
  }, [dispatch]);

  const priceTypes = useSelector(getPriceTypesOptionsSel);

  return priceTypes;
};
