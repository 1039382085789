import { Dialog } from '@material-ui/core';
import React from 'react';
import CreatePriceTypesContainer from './CreatePriceTypesContainer';

type Props = {
  open: boolean;
  onClose(): void;
};

const CreatePriceTypesDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-priceType-dialog-title"
    >
      <CreatePriceTypesContainer onClose={onClose} />
    </Dialog>
  );
};

export default CreatePriceTypesDialog;
