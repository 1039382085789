import { all, call, put, takeLatest } from 'redux-saga/effects';
import { BrandManagerConnection } from '../../api/mappers';
import {
  changeRttBalance,
  getRtt,
  getRttAssignedBrandManagersAPI,
  getRttDetails,
  setRttAssignedBrandManagersAPI,
} from '../../api/rtt';
import { getAreas } from '../../api/rttAreas';
import { getAllManagersOfRttAPI } from '../../api/rttManagerList';
import * as actions from './actions';
import * as types from './types';

function* rttWorker({ payload }: ReturnType<typeof actions.getRttRequest>) {
  try {
    const data = yield call(getRtt, payload);

    yield all([put(actions.getRttSuccess(data))]);
  } catch (e) {
    yield put(actions.getRttFailure(e.message));
  }
}

function* areasWorker() {
  try {
    const data = yield call(getAreas);

    yield all([put(actions.getAreasSuccess(data))]);
  } catch (e) {
    yield put(actions.getAreasFailure(e.message));
  }
}

function* rttDetailsWorker({
  payload,
}: ReturnType<typeof actions.getRttDetailsRequest>) {
  try {
    const data = yield call(getRttDetails, payload);

    yield all([put(actions.getRttDetailsSuccess(data))]);
  } catch (e) {
    yield put(actions.getRttDetailsFailure(e.message));
  }
}

function* rttBalanceWorker({
  payload,
}: ReturnType<typeof actions.changeRttBalanceRequest>) {
  try {
    yield call(changeRttBalance, payload);

    yield put(actions.changeRttBalanceSuccess(payload));
  } catch (e) {
    yield put(actions.changeRttBalanceFailure(e));
  }
}

/**
 * Get list of managers assigned just to the rtt, without ones assigned to area
 */
function* rttManagerListGetWorker({
  payload,
}: ReturnType<typeof actions.getRttManagerListRequest>) {
  try {
    const managerList: BrandManagerConnection[] = yield call(
      getRttAssignedBrandManagersAPI,
      payload,
    );

    yield put(actions.getRttManagerListSuccess(payload, managerList));
  } catch (e) {
    yield put(actions.getRttManagerListFailure(e.message));
  }
}

function* rttManagerListChangeWorker({
  payload,
}: ReturnType<typeof actions.changeRttManagerListRequest>) {
  try {
    const managerList: BrandManagerConnection[] = yield call(
      setRttAssignedBrandManagersAPI,
      payload,
    );

    yield put(actions.changeRttManagerListSuccess(payload.rttId, managerList));
  } catch (e) {
    yield put(actions.changeRttManagerListFailure(e.message));
  }
}

/**
 * Get list of managers assigned to the rtt and area, where the rtt is situated
 */
function* managersOfRttWorker({
  payload,
}: ReturnType<typeof actions.getAllManagersOfRttRequest>) {
  try {
    const data = yield call(getAllManagersOfRttAPI, payload);

    yield put(actions.getAllManagersOfRttSuccess(payload, data));
  } catch (e) {
    yield put(actions.getAllManagersOfRttFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_RTT_REQUEST, rttWorker);
  yield takeLatest(types.GET_AREAS_REQUEST, areasWorker);
  yield takeLatest(types.GET_RTT_DETAILS_REQUEST, rttDetailsWorker);
  yield takeLatest(types.RTT_CHANGE_BALANCE_STATE_REQUEST, rttBalanceWorker);
  yield takeLatest(types.RTT_MANAGER_LIST_GET_REQUEST, rttManagerListGetWorker);
  yield takeLatest(
    types.RTT_MANAGER_LIST_CHANGE_REQUEST,
    rttManagerListChangeWorker,
  );
  yield takeLatest(types.RTT_MANAGER_LIST_ALL_GET_REQUEST, managersOfRttWorker);
}
