import { Box, Typography } from '@material-ui/core';
import { GoBackButton } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorDisplay: React.FC = ({ children }) => {
  const [t] = useTranslation();

  return (
    <Box m={2} p={1}>
      <Typography variant="h4" component="h2" paragraph>
        {t('common.errorOccurred')}
      </Typography>
      <Typography variant="h5" color="error">
        {children}
      </Typography>
      <Box my={2}>
        <GoBackButton>{t('controls.goBack')}</GoBackButton>
      </Box>
    </Box>
  );
};

export default ErrorDisplay;
