/* eslint-disable @typescript-eslint/camelcase */
import { Grid, Paper } from '@material-ui/core';
import { ChangePassword } from '@rtt-libs/auth';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import { ChangeProfilePasswordForm } from './EditProfile';

const ChangeProfilePassword: React.FC = () => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validatePassword = (values: ChangePassword) => {
    const errors: Partial<ChangePassword> = {};
    if (values.newPassword !== values.newPasswordConfirmation) {
      errors.newPasswordConfirmation = t(
        'distributor.profile.passwordNotEqual',
      );
    }
    return errors;
  };

  return (
    <Grid container spacing={2}>
      <Paper style={{ width: '100%' }}>
        <MakeAsyncFunction
          listener={promiseListener}
          start={types.CHANGE_PASSWORD_REQUEST}
          resolve={types.CHANGE_PASSWORD_RESPONSE}
          reject={types.CHANGE_PASSWORD_FAILURE}
        >
          {onSubmit => (
            <Form
              validate={validatePassword}
              onSubmit={async values => {
                const errors = await onSubmit(values);

                if (errors) {
                  enqueueSnackbar(
                    `${t('distributor.profile.titleChangePassword')}: ${
                      errors[FORM_ERROR]
                    }`,
                    { variant: 'error' },
                  );
                } else {
                  enqueueSnackbar(
                    `${t('distributor.profile.titleChangePassword')}: ${t(
                      'common.snackbars.saveSuccess',
                    )}`,
                    { variant: 'success' },
                  );
                }

                return errors;
              }}
              component={ChangeProfilePasswordForm}
            />
          )}
        </MakeAsyncFunction>
      </Paper>
    </Grid>
  );
};

export default ChangeProfilePassword;
