import { restrictions } from '@rtt-libs/constants';
import { FieldWithMessages, NumberFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SKU_QTY_FIELD_NAME = 'skuQty';

const SkuQtyField = () => {
  const [t] = useTranslation();

  return (
    <FieldWithMessages
      subscription={{
        value: true,
        submitError: true,
        dirtySinceLastSubmit: true,
        error: true,
        touched: true,
      }}
      name={SKU_QTY_FIELD_NAME}
      label={t('distributor.balances.create.skuQtyLabel')}
      component={NumberFieldAdapter}
      required
      fullWidth
      inputProps={{
        'aria-label': 'sku qty',
        step: 1,
        min: 0,
        max: restrictions.PRODUCT_QTY_MAX_VALUE,
      }}
      validate={value => {
        return /^\d*$/.test(value) ? undefined : t('validation.notInteger');
      }}
    />
  );
};

export default SkuQtyField;
