import { Box, Paper } from '@material-ui/core';
import { Config, FORM_ERROR } from 'final-form';
import { mapValues } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import { CreateOrderLocationStateState } from '../../../ordersRefactored/view/OrderCreate/CreateOrderLink';
import routes from '../../../pages/routes';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import { createBalancesSuccess } from '../../duck/actions';
import {
  BalanceCreateFormValues,
  OrderedQtyValue,
  OrderedWeightValue,
} from '../../types';
import { shouldRecommendOrder } from '../../utils/shouldRecommendOrder';
import CreateForm from './CreateForm';

const CreateBalanceContainer: React.FC = () => {
  const [t] = useTranslation();
  // type is added to pass a correct state shape of new order
  const history = useHistory<CreateOrderLocationStateState>();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (
    onSubmit: (
      values: BalanceCreateFormValues,
    ) => Promise<ReturnType<typeof createBalancesSuccess>['payload']>,
  ): Config<BalanceCreateFormValues>['onSubmit'] => {
    return async values => {
      try {
        const { orderValues, rttId, products } = await onSubmit(values);

        const collectedOrderValues: Record<
          string,
          Partial<OrderedQtyValue & OrderedWeightValue>
        > = Object.values(orderValues.byGroupId).reduce(
          (acc, value) => ({ ...acc, ...value.payload.products }),
          {},
        );

        if (shouldRecommendOrder(collectedOrderValues)) {
          enqueueSnackbar(t('distributor.balances.create.successMessage'), {
            variant: 'success',
          });

          const combinedProductValues = mapValues(
            orderValues,
            (orderedValue, key) => ({
              ...products.find(product => product.id === key),
              ...orderedValue,
            }),
          );

          return history.push(routes.orderNew, {
            rttId,
            orderValues: combinedProductValues,
          });
        }

        enqueueSnackbar(t('distributor.balances.create.successEmptyMessage'), {
          variant: 'warning',
        });

        return history.push(routes.balances);
      } catch (errors) {
        enqueueSnackbar(errors[FORM_ERROR], { variant: 'error' });
        return errors;
      }
    };
  };

  return (
    <Box p={2} component={Paper}>
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.BALANCE_CREATE_REQUEST}
        resolve={types.BALANCE_CREATE_SUCCESS}
        reject={types.BALANCE_CREATE_FAILURE}
      >
        {onSubmit => (
          <Form
            // re-initialization is implemented in CreateFormTopRow.tsx
            component={CreateForm}
            subscription={{}}
            onSubmit={handleSubmit(onSubmit)}
          />
        )}
      </MakeAsyncFunction>
    </Box>
  );
};

export default CreateBalanceContainer;
