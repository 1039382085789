import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  Theme,
} from '@material-ui/core';
import { EnhancedPaginatedTable, TableHeadSortable } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMemoizedDecryptedOrders } from '../../../duck/selectors';
import { OrderedProduct } from '../../../types';
import CountCell from '../OrderProductsTable/Cells/CountCell';
import ProductTotal from '../OrderProductsTable/Cells/ProductTotal';
import SwitchAvailableCell from '../OrderProductsTable/Cells/SwitchAvailableCell';
import { makeProductsPayloadFieldNamePrefix } from '../OrderProductsTable/generateFieldName';
import { parseOrderGroupIdKey } from '../transformGroupIdKey';
import returnOrderProductsTableConfig from './returnProductsTableConfig';
import TableRowListCustom from './TableRowListCustom';

interface Props {
  products: OrderedProduct[];
  groupId: string;
  returnedItems?: string[];
  readOnly?: boolean;
  initialIds?: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
    scroll: {
      overflowX: 'auto',
    },
  }),
);

const MultiReturnOrderPayloadFormSliceTable = ({
  products,
  returnedItems,
  groupId,
  readOnly,
  initialIds,
}: Props) => {
  const [t] = useTranslation();
  const { change } = useForm();

  const classes = useStyles();

  const ordersMaxValuesCollection = useSelector(selectMemoizedDecryptedOrders);

  const productColumnsConfig = useMemo(() => {
    const [, orderId] = parseOrderGroupIdKey(groupId);
    const productFieldNamePrefix = makeProductsPayloadFieldNamePrefix(groupId);

    const onRemoveClick = (id: string) => {
      change(`${productFieldNamePrefix}.${id}`);
    };

    return returnOrderProductsTableConfig(t, {
      isAvailable: props => (
        <SwitchAvailableCell
          fieldNamePrefix={productFieldNamePrefix}
          disabled={readOnly}
          {...props}
        />
      ),
      qty: props => {
        const { id } = props;
        return (
          <CountCell
            fieldNamePrefix={productFieldNamePrefix}
            disabled={readOnly}
            onRemoveClick={
              readOnly || initialIds?.includes(id.toString())
                ? undefined
                : onRemoveClick
            }
            maxValue={ordersMaxValuesCollection[orderId]?.[id.toString()]}
            ignoreWeight
            {...props}
          />
        );
      },
      total: props => (
        <ProductTotal
          fieldNamePrefix={productFieldNamePrefix}
          returnedItems={returnedItems}
          {...props}
        />
      ),
    });
  }, [
    readOnly,
    groupId,
    returnedItems,
    t,
    change,
    initialIds,
    ordersMaxValuesCollection,
  ]);

  return (
    <EnhancedPaginatedTable>
      {() => (
        <div className={classes.scroll}>
          <Table>
            <TableHeadSortable
              columns={productColumnsConfig}
              tooltip={(t(''), 'Sort')}
            />
            {/* {decrypting && (
              <LoadingIndicator width={productColumnsConfig.length} />
            )} */}
            <TableBody>
              <TableRowListCustom
                groupId={groupId}
                data={products}
                columnsConfig={productColumnsConfig}
                readOnly={readOnly}
              />
            </TableBody>
          </Table>
        </div>
      )}
    </EnhancedPaginatedTable>
  );
};

export default MultiReturnOrderPayloadFormSliceTable;
