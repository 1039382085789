import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import React from 'react';
import { Employee } from '../../api/employees';
import { EditStaffActions, staffTableConfig } from '../../common/staff';
import { types } from '../duck';
import { EmployeeTerritories } from './Cells';
import EditEmployeeContainer from './EditEmployeeContainer';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const employeesTableConfig = (t: TFunction): ColumnConfig[] => [
  ...staffTableConfig(t),

  {
    id: 'role',
    label: t('distributor.tables.employees.role', 'role'),
    options: {
      sort: false,
      format: (role: string) => t(`roles.${role}`),
    },
  },
  {
    id: 'areaTitles',
    label: t('distributor.tables.employees.territories', 'areas'),
    options: {
      sort: false,
      component: EmployeeTerritories,
    },
  },

  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: Employee) => entry,
      component: ({ id, value }) => (
        <EditStaffActions
          id={String(id)}
          value={value as Employee}
          statusActionTypes={{
            start: types.EMPLOYEE_CHANGE_STATUS_REQUEST,
            resolve: types.EMPLOYEE_CHANGE_STATUS_SUCCESS,
            reject: types.EMPLOYEE_CHANGE_STATUS_FAILURE,
          }}
          container={EditEmployeeContainer}
        />
      ),
      sort: false,
    },
  },
];

export default employeesTableConfig;
