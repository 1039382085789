import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import { ProductUpdate } from '../../api/assortment';
import routes from '../../pages/routes';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import { getOptionsProductRequest } from '../duck/actions';
import ProductDetails from './ProductDetails';

const ProductCreateContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOptionsProductRequest());
  }, [dispatch]);

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <>
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.POST_PRODUCT_REQUEST}
        resolve={types.POST_PRODUCT_SUCCESS}
        reject={types.POST_PRODUCT_FAILURE}
      >
        {onSubmit => (
          <ProductDetails
            onSubmit={values => {
              return onSubmit({ values }).then(
                () => {
                  enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                    variant: 'success',
                  });

                  if (window.history.length <= 2) {
                    history.push(`/${routes.products}`);
                    return undefined;
                  }
                  history.goBack();

                  return undefined;
                },

                (
                  errors: Partial<
                    ProductUpdate & {
                      [FORM_ERROR]: string;
                    }
                  >,
                ) => {
                  enqueueSnackbar(
                    errors[FORM_ERROR] || t('common.snackbars.saveFailed'),
                    { variant: 'error' },
                  );

                  return errors;
                },
              );
            }}
          />
        )}
      </MakeAsyncFunction>
    </>
  );
};

export default ProductCreateContainer;
