import { restrictions } from '@rtt-libs/constants';
import { isNil, pickBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssortmentGetParams } from '../../../api/assortment';
import useSnackbarError from '../../../common/useSnackbarError';
import { searchProductsPricelessRequest } from '../../duck/actions';
import { ProductInfo, ProductSearchParams } from '../../types';
import {
  PaginatedProductSearch,
  ProductListWithClickCallback,
} from '../ProductAddCommon';
import { selectProductsForRtt } from '../../duck/selectors';

const EMPTY_EXISTING_IDS: string[] = [];

type Props = {
  onSubmit(product: ProductInfo): void;
  onClose?(): void;
  existingIds?: ProductInfo['id'][];
  brandId?: number;
};

const AddPricelessProductContainer: React.FC<Props> = ({
  onSubmit,
  onClose,
  existingIds = EMPTY_EXISTING_IDS,
  brandId,
}) => {
  const dispatch = useDispatch();

  const [displayOptions, changeOptions] = useState<
    Partial<AssortmentGetParams>
  >({
    brandId,
    perPage: restrictions.PRODUCTS_LIST_DISPLAY_COUNT,
    isActive: true,
    isInvalid: false,
  });

  useEffect(() => {
    dispatch(searchProductsPricelessRequest(displayOptions));
  }, [dispatch, displayOptions]);

  useEffect(() => {
    changeOptions(prevParams => ({ ...prevParams, brandId }));
  }, [brandId]);

  const handleProductClick = useCallback(
    (product: ProductInfo) => {
      onSubmit(product);

      if (onClose) onClose();
    },
    [onSubmit, onClose],
  );

  const onChange = useCallback((params: Partial<ProductSearchParams>) => {
    changeOptions(prevParams =>
      pickBy({ ...prevParams, page: 1, ...params }, value => !isNil(value)),
    );
  }, []);

  const { data, pagination, loading, error } = useSelector(
    selectProductsForRtt,
  );

  useSnackbarError(error);

  return (
    <PaginatedProductSearch
      isEmpty={!data.length}
      loading={loading}
      error={error}
      pagination={pagination}
      onChange={onChange}
    >
      <ProductListWithClickCallback
        data={data as ProductInfo[]}
        existingIds={existingIds}
        onClick={handleProductClick}
      />
    </PaginatedProductSearch>
  );
};

export default AddPricelessProductContainer;
