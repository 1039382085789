import { Pagination } from '@rtt-libs/types';
import { RttSelectOption } from '../../agreedAssortments/types';
import { Rtt } from '../../api/rtt';
import {
  Balance,
  BalanceCreateFormValues,
  BalanceFormErrors,
  BalanceTableParams,
  ProductWithOrderedQty,
  RecommendedOrderValue,
} from '../types';
import * as types from './types';
import {
  ProductBalanceSearchParams,
  EnhancedProduct,
} from '../../api/balanceProducts';
import { GroupedPayload } from '../../ordersRefactored/types';

export const getBalancesRequest = (params?: Partial<BalanceTableParams>) => ({
  type: types.BALANCE_GET_LIST_REQUEST,
  payload: params,
});

export const getBalancesSuccess = (
  payload: Balance[],
  meta: { pagination: Pagination },
) => ({
  type: types.BALANCE_GET_LIST_SUCCESS,
  payload,
  meta,
});

export const getBalancesFailure = (error: string) => ({
  type: types.BALANCE_GET_LIST_FAILURE,
  payload: error,
});

export const getBalanceDetailsRequest = (id: Balance['id']) => ({
  type: types.BALANCE_GET_DETAILS_REQUEST,
  payload: id,
});

export const getBalanceDetailsSuccess = (payload: Balance) => ({
  type: types.BALANCE_GET_DETAILS_SUCCESS,
  payload,
});

export const getBalanceDetailsFailure = (error: string) => ({
  type: types.BALANCE_GET_DETAILS_FAILURE,
  payload: error,
});

export const createBalancesRequest = (values: BalanceCreateFormValues) => ({
  type: types.BALANCE_CREATE_REQUEST,
  payload: values,
});

export const createBalancesSuccess = (payload: {
  rttId: number;
  products: EnhancedProduct[];
  orderValues: GroupedPayload<RecommendedOrderValue>;
}) => ({
  type: types.BALANCE_CREATE_SUCCESS,
  payload,
});

export const createBalancesFailure = (errors: BalanceFormErrors) => ({
  type: types.BALANCE_CREATE_FAILURE,
  payload: errors,
});

export const fetchBalancesRttRequest = () => ({
  type: types.BALANCE_FETCH_RTT_REQUEST,
});

export const fetchBalancesRttSuccess = (payload: RttSelectOption[]) => ({
  type: types.BALANCE_FETCH_RTT_SUCCESS,
  payload,
});

export const fetchBalancesRttFailure = (errors: BalanceFormErrors) => ({
  type: types.BALANCE_FETCH_RTT_FAILURE,
  payload: errors,
});

export const fetchAgreedProductsRequest = (id: Rtt['id']) => ({
  type: types.BALANCE_AGREED_PRODUCTS_REQUEST,
  payload: id,
});

export const fetchAgreedProductsSuccess = (
  payload: ProductWithOrderedQty[],
) => ({
  type: types.BALANCE_AGREED_PRODUCTS_SUCCESS,
  payload,
});

export const fetchAgreedProductsFailure = (error: string) => ({
  type: types.BALANCE_AGREED_PRODUCTS_FAILURE,
  payload: error,
});

export const searchProductsRequest = (
  id: Rtt['id'],
  params: ProductBalanceSearchParams,
) => ({
  type: types.BALANCE_SEARCH_PRODUCTS_REQUEST,
  payload: { id, params },
});

export const searchProductsSuccess = (
  payload: ProductWithOrderedQty[],
  meta: { pagination: Pagination },
) => ({
  type: types.BALANCE_SEARCH_PRODUCTS_SUCCESS,
  payload,
  meta,
});

export const searchProductsFailure = (error: string) => ({
  type: types.BALANCE_SEARCH_PRODUCTS_FAILURE,
  payload: error,
});

export const getProductsOrderedRequest = (
  id: Rtt['id'],
  productIds: string[],
) => ({
  type: types.BALANCE_GET_PRODUCTS_ORDERED_REQUEST,
  payload: { id, productIds },
});

export const getProductsOrderedSuccess = (payload: ProductWithOrderedQty) => ({
  type: types.BALANCE_GET_PRODUCTS_ORDERED_SUCCESS,
  payload,
});

export const getProductsOrderedFailure = (id: string, error: string) => ({
  type: types.BALANCE_GET_PRODUCTS_ORDERED_FAILURE,
  payload: { [id]: error },
});

export type Actions = ReturnType<
  | typeof getBalancesRequest
  | typeof getBalancesSuccess
  | typeof getBalancesFailure
  | typeof getBalanceDetailsRequest
  | typeof getBalanceDetailsSuccess
  | typeof getBalanceDetailsFailure
  | typeof createBalancesRequest
  | typeof createBalancesSuccess
  | typeof createBalancesFailure
  | typeof fetchBalancesRttRequest
  | typeof fetchBalancesRttSuccess
  | typeof fetchBalancesRttFailure
  | typeof fetchAgreedProductsRequest
  | typeof fetchAgreedProductsSuccess
  | typeof fetchAgreedProductsFailure
  | typeof searchProductsRequest
  | typeof searchProductsSuccess
  | typeof searchProductsFailure
  | typeof getProductsOrderedRequest
  | typeof getProductsOrderedSuccess
  | typeof getProductsOrderedFailure
>;
