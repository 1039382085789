import CryptoJS from 'crypto-js';

class Encryption {
  /**
   * Decrypt string.
   *
   * @param string encryptedString The encrypted string to be decrypt.
   * @param string key The key.
   * @return string Return decrypted string.
   */
  static decrypt(encryptedString: string, key: string): string {
    const json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString)),
    );
    const salt = CryptoJS.enc.Hex.parse(json.salt);
    const iv = CryptoJS.enc.Hex.parse(json.iv);
    const encrypted = json.ciphertext; // no need to base64 decode.
    let iterations = parseInt(json.iterations, 10);
    if (iterations <= 0) {
      iterations = 1;
    }
    const encryptMethodLength = Encryption.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations,
    });
    const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv,
    });

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

    try {
      return decodeURIComponent(decryptedString);
    } catch (e) {
      return decryptedString;
    }
  }

  /**
   * Encrypt string.
   *
   * @param string string The original string to be encrypt.
   * @param string key The key.
   * @return string Return encrypted string.
   */
  static encrypt(string: string, key: string): string {
    const iv = CryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.
    const salt = CryptoJS.lib.WordArray.random(256);
    const iterations = 1;
    const encryptMethodLength = Encryption.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations,
    });
    const encrypted = CryptoJS.AES.encrypt(
      encodeURIComponent(string),
      hashKey,
      {
        mode: CryptoJS.mode.CBC,
        iv,
      },
    );
    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    const output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations,
    };
    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output)),
    );
  }

  /**
   * @let integer Return encrypt method or Cipher method number. (128, 192, 256)
   */
  private static get encryptMethodLength(): number {
    const { encryptMethod } = Encryption;
    const aesMatch = encryptMethod.match(/\d+/);
    const aesNumber = (aesMatch && aesMatch[0]) || '256';
    return parseInt(aesNumber, 10);
  }

  /**
   * @let integer Return cipher method divide by 8. example: AES number 256 will be 256/8 = 32.
   */
  // private static get encryptKeySize(): number {
  //   const aesNumber = Encryption.encryptMethodLength;
  //   return parseInt(`${aesNumber / 8}`, 10);
  // }

  /**
   * @var string Cipher method.
   *              Recommended AES-128-CBC, AES-192-CBC, AES-256-CBC
   *              due to there is no `openssl_cipher_iv_length()` function in JavaScript
   *              and all of these methods are known as 16 in iv_length.
   */
  private static get encryptMethod(): string {
    return 'AES-256-CBC';
  }
}
export default Encryption;
