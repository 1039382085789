import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

type Props = {
  activeStep: number;
  steps?: string[];
};

const stepLabels = [
  'Реєстрація',
  'Підтвердити номер телефону',
  'Заповнення профілю',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      background: 'none',
    },
  }),
);

const RegistrationStepper: React.FC<Props> = ({
  activeStep,
  steps = stepLabels,
}) => {
  const classes = useStyles();

  return (
    <Stepper activeStep={activeStep} className={classes.root}>
      {steps.map(label => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default RegistrationStepper;
