/* eslint-disable @typescript-eslint/no-empty-interface, @typescript-eslint/camelcase */
import { formErrorTransform } from '@rtt-libs/api-services';
import { DataWrapped, OrderParam } from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import type * as ExportTypes from '../exports/types';
import api from './apiSetup';

export interface ExportDocument extends ExportTypes.ExportDocument {}
export interface ExportResult extends ExportTypes.ExportResult {}
export interface ExportStatus extends ExportTypes.ExportStatus {}

// TODO: move to api handler
export type FetchedExportResult = {
  /** UUID */
  id: string;
  type: ExportTypes.ExportResultType;
  is_done: boolean;
  /** Download URL */
  src: string;
  /** DateTime ISO string */
  created_at: string;
};

export class ExportResult {
  constructor(fetched: FetchedExportResult) {
    this.id = fetched.id;
    this.src = fetched.src;
    this.type = fetched.type;
    this.isDone = fetched.is_done;
    this.createdAt = fetched.created_at;
  }
}

export type FetchedExportStatus = {
  /** UUID */
  id: string;
  status: ExportTypes.ExportStatusValue;
  /** DateTime ISO string */
  created_at: string;
  export: FetchedExportResult;
};

export interface FetchedExportStatusField {
  export_status: FetchedExportStatus;
}

interface FetchedExportDocument extends Partial<FetchedExportStatusField> {
  id: string;
  created_at: string;
  type: ExportTypes.ExportResultType;
  rtt: {
    id: number;
    name: string;
  };
}

export class ExportDocument {
  constructor(fetched: FetchedExportDocument) {
    this.id = fetched.id;
    this.createdAt = fetched.created_at;
    this.type = fetched.type;
    this.rtt = fetched.rtt;
    this.exportStatus =
      fetched.export_status && new ExportStatus(fetched.export_status);
  }
}

export class ExportStatus {
  constructor(fetched: FetchedExportStatus) {
    this.id = fetched.id;
    this.status = fetched.status;
    this.createdAt = fetched.created_at;
    this.export = fetched.export && new ExportResult(fetched.export);
  }
}

interface ExportDocumentFilterParamsApi {
  type: ExportTypes.ExportResultType[];
  /* 2020-05-31 23:59 */
  datetime_start: string;
  datetime_end: string;
  sort: 'created_at';
  order: OrderParam;
}

interface MultiExportListItemApi {
  target_type: ExportTypes.ExportResultType;
  target_id: ExportTypes.ExportDocument['id'];
  status: Exclude<ExportTypes.ExportStatusValue, 'processing'>;
}

/*
 * Export status fetch
 */
export const getExportStatusApi = (
  type: ExportTypes.ExportResultType,
  id?: string,
) =>
  api
    .get<FetchedExportResult>(ENDPOINTS.exportDataStatus(type, id))
    .then(data => new ExportResult(data.data))
    .catch(e => {
      throw new Error(e?.response?.data?.message || e.message);
    });

export const getExportDownloadLinkApi = (url: string) =>
  api
    .get<{ id: string; src: string }>(url)
    .then(data => data.data?.src)
    .catch(e => {
      throw new Error(e?.response?.data?.message || e.message);
    });

/*
 * Get all documents in mixed list
 */
export const getMixedExportDocumentsApi = (
  params: Partial<ExportTypes.ExportDocumentFilterParams>,
) =>
  api
    .get<DataWrapped<FetchedExportDocument[]>>(ENDPOINTS.exportData, {
      params: mapExportDocumentFilterParams(params),
    })
    .then(({ data }) => data.data.map(doc => new ExportDocument(doc)))
    .catch(e => {
      throw formErrorTransform(e);
    });

/*
 * Export status changers
 */
export const setExportStatusApi = (
  type: ExportTypes.ExportResultType,
  {
    id,
    isExported,
  }: {
    id: string;
    isExported: boolean;
  },
) =>
  api
    .put<{ status: ExportTypes.ExportStatusValue }>(
      `${ENDPOINTS.exportData}/status/${type}/${id}/status`,
      {
        status: isExported ? 'done' : 'not_exported',
      },
    )
    .then(data => data.data?.status)
    .catch(e => {
      throw new Error(e?.response?.data?.message || e.message);
    });

export const setMultiExportStatusApi = ({
  list,
  isExported,
}: {
  list: ExportDocument[];
  isExported: boolean;
}) =>
  api
    .post(ENDPOINTS.exportMultiStatus, {
      list: mapMultiExportStatusApi(list, isExported),
    })
    .catch(e => {
      throw new Error(e?.response?.data?.message || e.message);
    });

/*
 * Export data
 */
export const startExportApi = (
  type: ExportTypes.ExportResultType,
  channel: ExportTypes.ExportChannel,
) =>
  api
    .post<FetchedExportResult>(
      ENDPOINTS.exportStart(type === 'selling' ? 'orders' : type, channel),
    )
    .then(data => new ExportResult(data.data))
    .catch(e => {
      throw new Error(e?.response?.data?.message || e.message);
    });

/*
 * Export multi data
 */
export const startMultiExportApi = (
  type: ExportTypes.ExportResultType[],
  channel: ExportTypes.ExportChannel,
) =>
  api
    .post<FetchedExportResult>(ENDPOINTS.exportMixed(channel), { type })
    .then(data => new ExportResult(data.data))
    .catch(e => {
      throw new Error(e?.response?.data?.message || e.message);
    });

/*
 * Mappers
 */

const mapExportDocumentFilterParams = (
  params: Partial<ExportTypes.ExportDocumentFilterParams>,
): Partial<ExportDocumentFilterParamsApi> => ({
  type: params.type,
  datetime_start: params.datetimeStart,
  datetime_end: params.datetimeEnd,
  order: params.order,
  sort: params.orderBy,
});

const mapMultiExportStatusApi = (
  list: ExportDocument[],
  isExported: boolean,
): MultiExportListItemApi[] =>
  list.map(({ id, type }) => ({
    target_id: id,
    target_type: type,
    status: isExported ? 'done' : 'not_exported',
  }));
