import { Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DiscountRtt, DiscountsSearchParams } from '../../../api/discounts';
import routes from '../../../pages/routes';
import RttFieldSearch from './RttFieldSearch';

type Props = {
  onChange(params: DiscountsSearchParams): void;
};

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = ({ onChange }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const onSearchChange = (
    e: React.ChangeEvent<{}>,
    value: DiscountRtt | null,
  ) => {
    onChange({ rttId: value?.id });
  };

  const routeToDiscountCreate = () => {
    history.push(routes.discountsNew);
  };

  return (
    <>
      <Grid item xs={6}>
        <RttFieldSearch onChange={onSearchChange} />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={routeToDiscountCreate}
          startIcon={<AddIcon />}
        >
          {t('controls.create')}
        </Button>
      </Grid>
    </>
  );
};

export default FilterRow;
