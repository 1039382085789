import { Typography, Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileRequest } from '../duck/actions';
import { selectProfile } from '../duck/selectors';
import ProfileInfo from './ProfileInfo';
import ChangeProfilePassword from './ChangeProfilePassword';

const ProfileContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { data, error } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(getProfileRequest());
  }, [dispatch]);

  if (!data.id) return <LinearProgress />;

  return (
    <>
      <Grid container spacing={2} alignItems="flex-end" justify="flex-start">
        {error && <Typography color="error">{error}</Typography>}
        <ProfileInfo profile={data} />
        <ChangeProfilePassword />
      </Grid>
    </>
  );
};

export default ProfileContainer;
