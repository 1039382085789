import { Box, Card, CardContent, Link, Typography } from '@material-ui/core';
import { Editor, UserRole, DateTime } from '@rtt-libs/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<Editor, 'role' | 'date'> {
  title: 'manager' | 'creator' | 'lastEditor';
  role?: UserRole;
  date?: DateTime;
}

const EditorCard: React.FC<Props> = ({
  role,
  firstName,
  lastName,
  date,
  email,
  phone,
  title,
}) => {
  const [t] = useTranslation();

  return (
    <Box clone my={0.5}>
      <Card variant="outlined">
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography aria-label="user title">
              {t(`distributor.editors.${title}`)}
            </Typography>

            {role && (
              <Box ml={2}>
                <Typography variant="caption" aria-label="user role">
                  {t(`roles.${role}`)}
                </Typography>
              </Box>
            )}
          </Box>

          <Typography variant="h6" aria-label="user name">
            {firstName} {lastName}
          </Typography>

          <Typography>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Typography>

          <Typography>
            <Link href={`tel:${phone}`}>{phone}</Link>
          </Typography>

          {date && (
            <Box clone display="block" textAlign="right">
              <Typography
                component="time"
                dateTime={date}
                aria-label="changed date"
              >
                {t('formattedDate', { date: new Date(date) })}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditorCard;
