/* eslint-disable @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  someBooleanToInt,
} from '@rtt-libs/api-services';
import {
  AttachmentDocumentType,
  DeliveryWeek,
  ExportableDocumentEditors,
  ExportableDocumentEditorsHistory,
  FetchedContact,
  FetchedDocumentEditorsWithHistory,
  GeoLocation,
  Measurement,
  OrderKey,
  OrderParam,
  Paginated,
  RttKey,
} from '@rtt-libs/types';
import trim from 'lodash/fp/trim';
import { ENDPOINTS } from '../environment';
import { ExportStatusField } from '../exports/types';
import api from './apiSetup';
import { FetchedProduct } from './assortment';
import { ExportStatus, FetchedExportStatusField } from './exports';
import { transformSimpleErrorMessage } from './mappers';
import { Editor } from './ordersRefactored';

export type StatusValue =
  | 'new'
  | 'pending'
  | 'canceled'
  | 'processing'
  | 'rejected'
  | 'requires_confirm'
  | 'approved'
  | 'declined'
  | 'submit';

export type Status = {
  value: StatusValue;
  title: string;
};

type FetchedDistributorInfo = {
  id: number;
  name: string;
  address: string;
  contact?: FetchedContact;
};

type FetchedRttInfo = {
  id: number;
  name: string;
  address: string;
  geo: GeoLocation;
  status: Status;
  created_at: string;
  contact: FetchedContact;
};

type FetchedOrder = {
  id: string;
  distributor_id: number;
  manager_id: number | null;
  rtt_id: number;
  status: Status;
  payload: string;
  total: string;
  origin_total: string;
  shipping_date: string | null;
  manager_description: string | null;
  rtt_description: string | null;
  created_at: string;
  manager?: FetchedContact;
  distributor: FetchedDistributorInfo;
  rtt?: FetchedRttInfo;
  order_id?: string;
} & Partial<FetchedExportStatusField> &
  FetchedDocumentEditorsWithHistory;

type DistributorInfo = {
  id: number;
  name: string;
  address: string;
  contact?: Contact;
};

export class RttInfo {
  id?: number;
  name?: string;
  address?: string;
  geo?: GeoLocation;
  status?: Status;
  createdAt?: string;
  contact?: Contact;

  constructor(rtt?: FetchedRttInfo) {
    this.id = rtt?.id;
    this.name = rtt?.name;
    this.address = rtt?.address;
    this.geo = rtt?.geo;
    this.status = rtt?.status;
    this.createdAt = rtt?.created_at;
    this.contact = rtt?.contact && new Contact(rtt.contact);
  }
}

export class Contact {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;

  constructor(contact: FetchedContact) {
    this.phone = contact.phone;
    this.email = contact.email;
    this.firstName = contact.first_name;
    this.lastName = contact.last_name;
  }
}

interface AbstractOrder extends Partial<ExportStatusField> {
  id: string;
  distributorId: number;
  managerId: number | null;
  rttId: number;
  status: Status;
  shippingDate?: string;
  managerDescription?: string | null;
  rttDescription?: string | null;
  createdAt: string;
  manager?: Contact;
  distributor: DistributorInfo;
  rtt: RttInfo;
  payloadEncrypted: string;
}

export class EncryptedOrder
  implements
    AbstractOrder,
    Partial<ExportableDocumentEditors & ExportableDocumentEditorsHistory> {
  id: string;
  distributorId: number;
  managerId: number | null;
  rttId: number;
  status: Status;
  shippingDate?: string;
  managerDescription?: string | null;
  rttDescription?: string | null;
  createdAt: string;
  manager?: Contact;
  distributor: DistributorInfo;
  payloadEncrypted: string;
  rtt: RttInfo;
  payload: string;
  total: string;
  originTotal: string;
  orderId?: string;
  exportStatus?: ExportStatus;
  creator?: Editor;
  lastEditor?: Editor;
  editorList?: Editor[];
  managerList?: Omit<Editor, 'role'>[];

  constructor(order: FetchedOrder) {
    this.id = order.id;
    this.distributorId = order.distributor_id;
    this.managerId = order.manager_id;
    this.rttId = order.rtt_id;
    this.status = order.status;
    this.shippingDate = order.shipping_date || undefined;
    this.managerDescription = order.manager_description;
    this.rttDescription = order.rtt_description;
    this.createdAt = order.created_at;
    this.manager = order.manager && new Contact(order.manager);
    this.distributor = {
      ...order.distributor,
      contact:
        order.distributor?.contact && new Contact(order.distributor.contact),
    };
    this.rtt = new RttInfo(order.rtt);
    this.payload = order.payload;
    this.payloadEncrypted = order.payload;
    this.total = order.total;
    this.originTotal = order.origin_total;
    this.orderId = order.order_id;
    this.exportStatus =
      order.export_status && new ExportStatus(order.export_status);

    this.creator = order.creator && new Editor(order.creator);
    this.lastEditor = order.last_editor && new Editor(order.last_editor);
    // editorList & managerList currently unused
    this.editorList = order.editor_list?.map(editor => new Editor(editor));
    this.managerList = order.manager_list?.map(({ date, ...manager }) => ({
      ...(new Contact(manager) as Required<Contact>),
      date,
    }));
  }
}

export class PayloadProduct {
  id: string;
  sku: string;
  title: string;
  weight: number | null;
  price: number;
  total: number;
  is_revocable: boolean;
  is_available?: boolean;
  sale_measurement?: Measurement;
  image: AttachmentDocumentType;
  qty: number;
  order_weight: number;
  return_note?: string;
  orders?: string[];
  is_defective?: boolean;
  hash?: string;

  brand_id?: number;

  constructor(product: EnhancedProduct) {
    this.id = product.id;
    this.image = product.image;
    this.is_available = product.isAvailable ?? true;
    this.is_revocable = product.isRevocable ?? true;
    this.order_weight = product.orderWeight;
    this.price = product.price ?? 0;
    this.qty = product.qty;
    this.return_note = product.returnNote;
    this.sale_measurement = product.saleMeasurement ?? 'weight';
    this.sku = product.sku;
    this.title = product.title;
    this.total = product.total;
    this.weight = product.weight;
    this.orders = product.orders;
    this.is_defective = product.isDefective;

    this.brand_id = product.brandId;
  }
}

export class EnhancedProduct {
  id: string;
  image: AttachmentDocumentType;
  isAvailable: boolean;
  isRevocable: boolean;
  orderWeight: number;
  price?: number;
  qty: number;
  returnNote?: string;
  saleMeasurement?: Measurement;
  sku: string;
  title: string;
  total: number;
  weight: number | null;
  orders?: string[];
  manuallyAdded?: boolean;
  maxOrderWeight?: number;
  maxQty?: number;
  orderId?: string;
  isDefective: boolean;

  brandId?: number;
  isBrandOperated?: boolean;

  hash?: string;
  [key: string]: unknown;

  constructor(product: PayloadProduct) {
    this.id = product.id;
    this.image = product.image;
    this.isAvailable = product.is_available ?? true;
    this.isRevocable = product.is_revocable ?? true;
    this.orderWeight = product.order_weight;
    this.price = product.price;
    this.qty = product.qty;
    this.returnNote = product.return_note;
    this.saleMeasurement = product.sale_measurement;
    this.sku = product.sku;
    this.title = product.title;
    this.total = product.total;
    this.weight = product.weight;
    this.isDefective = product.is_defective ?? false;
    // Mapped to fix misconception in different representation of Product
    this.saleMeasurement =
      ((this.saleMeasurement as unknown) as {
        type: Measurement;
      })?.type || this.saleMeasurement;
    this.orders = product.orders;
    this.hash = product.hash;

    this.brandId = product.brand_id;
  }
}

export type ShopInfo = {
  distributor_name: string;
  min_total_order: number;
  manager: {
    phone: string;
  };
};

export type Order = AbstractOrder & {
  id: string;
  distributorId: number;
  managerId: number | null;
  rttId: number;
  status: Status;
  shippingDate?: string;
  managerDescription?: string | null;
  rttDescription?: string | null;
  createdAt: string;
  manager?: Contact;
  distributor: DistributorInfo;
  rtt: RttInfo;
  payload: {
    products: Record<EnhancedProduct['id'], EnhancedProduct>;
    shopInfo: ShopInfo;
    [key: string]: unknown;
  };
  payloadProductIds: string[];
  total: number;
  originTotal: number;
  orderId?: string;
  creator?: Editor;
  lastEditor?: Editor;
  editorList?: Editor[];
  managerList?: Omit<Editor, 'role'>[];
  /** Added generic key to accept Record<string, unknown> typing  */
  [key: string]: unknown;
};

export type ReturnOrder = Order;

export type NewOrder = {
  distributorId: number;
  payloadEncrypted: string;
  shippingDate: string;
  managerDescription?: string;
};

export type CreateOrderValues = {};

type APISearchParams = Partial<{
  start_date: string;
  end_date: string;
  status: StatusValue | string;
  sort: 'status';
  order: OrderParam;
  per_page: number;
  page: number;
  include: 'export_status';
}>;

export type SearchParams = Partial<{
  startDate: string;
  endDate: string;
  orderBy: 'status';
  status: StatusValue | string;
  order: OrderParam;
  perPage: number;
  page: number;
  include: 'export_status';
}>;

type OrderListParams = Partial<{
  idList: string[];
  page: number;
  perPage: number;
}>;

export type SecretCheck = {
  keyId: string;
  secret: number;
  signature: string;
};

type APIUpdateOrder = {
  distributor_id: number;
  key_id: string;
  payload: string;
  secret: number;
  signature: string;
  shipping_date?: string | null;
  manager_description?: string | null;
};

export type APIProductSearchParams = Partial<{
  search: string;
  category_id: number[];
  sort: 'sku' | 'title';
  order: OrderParam;
  page: number;
  per_page: number;
  order_id: string;
  is_active: 0 | 1;
  is_invalid: 0 | 1;
}>;

export type ProductSearchParams = Partial<{
  search: string;
  categoryIds: number[];
  orderBy: 'sku' | 'title';
  order: OrderParam;
  page: number;
  perPage: number;
  orderId: string;
  isActive: boolean;
  isInvalid: boolean;
}>;

export type OrderCreateValues = {
  rttId: number;
  shippingDate: string;
  managerDescription?: string;
  distributorId: number;
  payload: {
    products: Record<EnhancedProduct['id'], EnhancedProduct>;
    shopInfo: ShopInfo;
    [key: string]: unknown;
  };
};

/*
 * Orders API handlers
 */

export const getOrderDetails = (orderId: string) =>
  api
    .get<FetchedOrder>(`${ENDPOINTS.orders}/${orderId}`)
    .then(({ data }) => new EncryptedOrder(data))
    .catch(transformSimpleErrorMessage);

export const getReturnOrderDetails = (returnOrderId: string) =>
  api
    .get<FetchedOrder>(ENDPOINTS.returnOrdersEdit(returnOrderId))
    .then(({ data }) => new EncryptedOrder(data))
    .catch(transformSimpleErrorMessage);

export const getOrders = (params?: SearchParams) =>
  api
    .get<Paginated<FetchedOrder>>(ENDPOINTS.orders, {
      params: params && mapSearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapOrderArray))
    .catch(transformSimpleErrorMessage);

export const getOrdersByIds = (params?: OrderListParams) =>
  api
    .get<Paginated<FetchedOrder>>(ENDPOINTS.ordersByIds, {
      params: params && mapOrderListParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapOrderArray))
    .catch(transformSimpleErrorMessage);

export const getReturnOrders = (params?: SearchParams) =>
  api
    .get<Paginated<FetchedOrder>>(ENDPOINTS.returnOrders, {
      params: params && mapSearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapOrderArray))
    .catch(transformSimpleErrorMessage);

export const getKeys = (orders: string[]) =>
  api
    .post<OrderKey>(ENDPOINTS.keys, { orders })
    .then(data => data.data)
    .catch(transformSimpleErrorMessage);

export const getKeysByRtt = (rtt: string[]) =>
  api
    .post<RttKey>(ENDPOINTS.keysByRtt, { rtt })
    .then(data => data.data)
    .catch(transformSimpleErrorMessage);

export const getAvailableStatuses = (orderId: string) =>
  api
    .get<StatusValue[]>(`${ENDPOINTS.orders}/${orderId}/status`)
    .then(data => data.data)
    .catch(transformSimpleErrorMessage);

export const setReturnOrderStatus = ({
  returnId,
  status,
}: {
  returnId: string;
  status: StatusValue;
}) =>
  api
    .post<FetchedOrder>(ENDPOINTS.returnOrdersStatus(returnId), {
      status,
    })
    .then(({ data }) => new EncryptedOrder(data))
    .catch(transformSimpleErrorMessage);

export const setOrderStatus = ({
  orderId,
  status,
}: {
  orderId: string;
  status: StatusValue;
}) =>
  api
    .post<FetchedOrder>(`${ENDPOINTS.orders}/${orderId}/status`, { status })
    .then(({ data }) => new EncryptedOrder(data))
    .catch(transformSimpleErrorMessage);

export const updateOrder = (order: Order, secrets: SecretCheck) =>
  api
    .put<FetchedOrder>(
      `${ENDPOINTS.orders}/${order.id}`,
      mapUpdateOrder(order, secrets),
    )
    .then(({ data }) => new EncryptedOrder(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const createOrder = (order: NewOrder, secrets: SecretCheck) =>
  api
    .post<FetchedOrder>(ENDPOINTS.orders, mapUpdateOrder(order, secrets))
    .then(({ data }) => new EncryptedOrder(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const updateReturnOrder = (
  returnOrder: ReturnOrder,
  secrets: SecretCheck,
) =>
  api
    .put<FetchedOrder>(
      ENDPOINTS.returnOrdersEdit(returnOrder.id),
      mapUpdateOrder(returnOrder, secrets),
    )
    .then(({ data }) => new EncryptedOrder(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const createReturnOrder = (
  returnOrder: Pick<
    ReturnOrder,
    | 'orderId'
    | 'payloadEncrypted'
    | 'shippingDate'
    | 'managerDescription'
    | 'distributorId'
  >,
  secrets: SecretCheck,
) => {
  if (!returnOrder.orderId)
    throw new Error('You forgot to pass orderId for creating returnOrder');

  return api
    .post<FetchedOrder>(
      ENDPOINTS.returnOrderCreate(returnOrder.orderId),
      mapUpdateOrder(returnOrder, secrets),
    )
    .then(({ data }) => new EncryptedOrder(data))
    .catch(e => {
      throw formErrorTransform(e);
    });
};

export const searchProductsForRtt = (
  rttId: number,
  params?: ProductSearchParams,
) =>
  api
    .get<Paginated<FetchedProduct>>(ENDPOINTS.orderProducts(rttId), {
      params: params && mapProductSearchParams(params),
    })
    .then(data =>
      mapPaginatedData(data.data, products =>
        products.map(
          product =>
            new EnhancedProduct((product as unknown) as PayloadProduct),
        ),
      ),
    )
    .catch(transformSimpleErrorMessage);

export const searchProductsForReturn = (
  rttId: number,
  params?: ProductSearchParams,
) =>
  api
    .get<Paginated<FetchedProduct>>(ENDPOINTS.returnOrderProducts, {
      params: {
        rtt_id: rttId,
        ...(params && mapProductSearchParams(params)),
      },
    })
    .then(data =>
      mapPaginatedData(data.data, products =>
        products.map(
          product =>
            new EnhancedProduct((product as unknown) as PayloadProduct),
        ),
      ),
    )
    .catch(transformSimpleErrorMessage);

export const getDeliveryDaysByRtt = (rttId: number) =>
  api
    .get<{ delivery_days: DeliveryWeek }>(ENDPOINTS.orderDeliveryDays(rttId))
    .then(({ data }) => data.delivery_days)
    .catch(transformSimpleErrorMessage);

// TODO: [settings domain]: move to settings domain after it implementation
export type FetchedSettings = {
  id: number;
  distributor_id: number;
  min_total_order: number | null;
  delivery_days: DeliveryWeek;
  categories: number[];
};

export const getMinTotalOrderSettings = () =>
  api
    .get<FetchedSettings>(ENDPOINTS.settings)
    .then(({ data }) => data.min_total_order ?? 0)
    .catch(transformSimpleErrorMessage);

/*
 * Order Mappers
 */

function mapOrderArray(orders: FetchedOrder[]) {
  try {
    return orders.map(order => new EncryptedOrder(order));
  } catch (e) {
    throw new Error('Order mapping error');
  }
}

function mapSearchParams(params: SearchParams): APISearchParams {
  return {
    start_date: params.startDate,
    end_date: params.endDate,
    status: params.status,
    sort: params.orderBy,
    order: params.order,
    per_page: params.perPage,
    page: params.page,
    include: params.include,
  };
}

function mapOrderListParams(params: OrderListParams) {
  return {
    id_list: params.idList?.join(),
    per_page: params.idList?.length,
    // per_page: params.perPage,
    // page: params.page,
  };
}

function mapProductSearchParams(
  params: ProductSearchParams,
): APIProductSearchParams {
  return {
    sort: params.orderBy,
    order: params.order,
    per_page: params.perPage,
    page: params.page,
    category_id: params.categoryIds,
    search:
      (typeof params.search === 'string' && trim(params.search)) || undefined,
    order_id: params.orderId,
    is_active: someBooleanToInt(params.isActive),
    is_invalid: someBooleanToInt(params.isInvalid),
  };
}

function mapUpdateOrder(
  order: Pick<
    Order,
    'payloadEncrypted' | 'shippingDate' | 'managerDescription' | 'distributorId'
  >,
  secrets: SecretCheck,
): APIUpdateOrder {
  return {
    payload: order.payloadEncrypted,
    shipping_date: order.shippingDate || null,
    manager_description: order.managerDescription,
    distributor_id: order.distributorId,
    key_id: secrets.keyId,
    secret: secrets.secret,
    signature: secrets.signature,
  };
}
