import AddBoxIcon from '@material-ui/icons/AddBox';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ChatIcon from '@material-ui/icons/Chat';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
import ForumIcon from '@material-ui/icons/Forum';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListIcon from '@material-ui/icons/List';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MapIcon from '@material-ui/icons/Map';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RoomIcon from '@material-ui/icons/Room';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useAuth } from '@rtt-libs/auth';
import { selectors } from '@rtt-libs/state-containers';
import {
  Navigation as NavigationExt,
  NavigationConfig,
  NavigationLink,
} from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import routes from '../pages/routes';
import { PERMISSIONS } from '../rules';

const Navigation: React.FC = () => {
  const [t] = useTranslation();
  const { isPermittedTo } = useAuth();

  const { newMessages } = useSelector(selectors.selectNotifications);

  const linkConfig = useMemo<NavigationConfig>(
    () =>
      [
        {
          label: t('navigation.home'),
          link: routes.home,
          icon: <HomeIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_SHOW),
        },
        {
          label: t('navigation.finishRegistration'),
          link: routes.finishRegistration,
          icon: <AssignmentIndIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_FINISH_REGISTRATION),
        },
        {
          label: t('navigation.orders'),
          link: routes.orders,
          icon: <AllInboxIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_ORDERS),
          nested: [
            {
              label: t('navigation.ordersNew'),
              link: `${routes.orders}/${routes.orderFilters.toPerform}`,
              icon: <FiberManualRecordTwoToneIcon color="secondary" />,
              exact: false,
              shown: true,
            },
            {
              label: t('navigation.ordersHistory'),
              link: `${routes.orders}/${routes.orderFilters.history}`,
              icon: <HistoryIcon />,
              exact: false,
              shown: true,
            },
          ],
        },
        {
          label: t('navigation.returns'),
          link: routes.returns,
          icon: <AssignmentReturnIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_ORDERS),
          nested: [
            {
              label: t('navigation.returnsNew'),
              link: `${routes.returns}/${routes.orderFilters.toPerform}`,
              icon: <FiberManualRecordTwoToneIcon color="secondary" />,
              exact: false,
              shown: true,
            },
            {
              label: t('navigation.returnsHistory'),
              link: `${routes.returns}/${routes.orderFilters.history}`,
              icon: <HistoryIcon />,
              exact: false,
              shown: true,
            },
          ],
        },
        {
          label: t('navigation.balances'),
          link: routes.balances,
          icon: <TrendingUpIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_BALANCES),
          exact: false,
        },
        {
          label: t('navigation.staff'),
          link: routes.staff.common,
          icon: <SupervisedUserCircleIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_EMPLOYEES),
          nested: [
            {
              label: t('navigation.administrators'),
              link: routes.staff.administrators,
              icon: <HowToRegIcon />,
              shown: isPermittedTo(PERMISSIONS.NAV_ADMINISTRATORS),
            },
            {
              label: t('navigation.supervisors'),
              link: routes.staff.supervisors,
              icon: <SupervisorAccountIcon />,
              shown: isPermittedTo(PERMISSIONS.NAV_SUPERVISORS),
            },
            {
              label: t('navigation.employees'),
              link: routes.staff.employees,
              icon: <PeopleOutlineIcon />,
              shown: isPermittedTo(PERMISSIONS.NAV_EMPLOYEES),
            },
          ],
        },
        {
          label: t('navigation.supervisorManagers'),
          link: routes.supervisorManagers,
          icon: <PeopleOutlineIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_SUPERVISOR_MANAGERS),
        },
        {
          label: t('navigation.brands'),
          link: routes.brands,
          icon: <BusinessCenterIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_BRANDS),
        },
        {
          label: t('navigation.territories'),
          link: routes.territories,
          icon: <MapIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_AREAS),
        },
        {
          label: t('navigation.export.collectionName'),
          link: routes.importExport.common,
          icon: <CloudDownloadIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_EXPORTS),
          nested: [
            {
              label: t('navigation.export.common'),
              link: routes.importExport.common,
              icon: <ReceiptIcon />,
              shown: true,
            },
            {
              label: t('navigation.export.orders'),
              link: routes.importExport.orders,
              shown: true,
            },
            {
              label: t('navigation.export.returns'),
              link: routes.importExport.returns,
              shown: true,
            },
            {
              label: t('navigation.export.balances'),
              link: routes.importExport.balances,
              shown: true,
            },
            {
              label: t('navigation.uploadAssortment'),
              link: routes.uploadAssortment,
              icon: <ImportExportIcon />,
              shown: true,
            },
          ],
        },
        {
          label: t('navigation.assortment'),
          link: routes.products,
          icon: <DashboardOutlinedIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_ASSORTMENT),
          nested: [
            {
              label: t('navigation.assortmentInactive'),
              link: `${routes.products}/${routes.productFilters.inactive}`,
              icon: <FiberManualRecordTwoToneIcon color="disabled" />,
              shown: true,
            },
            {
              label: t('navigation.assortmentInvalid'),
              link: `${routes.products}/${routes.productFilters.invalid}`,
              icon: <FiberManualRecordTwoToneIcon color="error" />,
              shown: true,
            },
            {
              label: t('navigation.createProduct'),
              link: routes.productNew,
              icon: <AddBoxIcon />,
              shown: true,
            },
          ],
        },
        {
          label: t('navigation.agreedAssortmentsContracted'),
          link: routes.agreedAssortments,
          icon: <OfflinePinIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_AGREED_ASSORTMENTS_LIST),
          exact: false,
        },
        {
          label: t('navigation.discounts'),
          link: routes.discounts,
          icon: <LoyaltyIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_DISCOUNTS),
          exact: false,
        },
        {
          label: t('navigation.sales'),
          link: routes.sales,
          icon: <LocalOfferIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_SALES),
          exact: false,
        },
        {
          label: t('navigation.rtt.main'),
          link: routes.rtt,
          icon: <RoomIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_RTT),
        },
        {
          label: t('navigation.chats'),
          link: routes.chats,
          icon: <ChatIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_CHATS),
          badgeProps: { badgeContent: newMessages },
        },
        {
          label: t('navigation.support'),
          link: routes.support,
          icon: <ChatIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_SUPPORT),
          badgeProps: { badgeContent: newMessages },
        },
        {
          label: t('navigation.massMail'),
          link: routes.massMail,
          icon: <ForumIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_MASS_MAIL),
        },
        {
          label: t('navigation.priceTypes.main'),
          link: routes.priceTypes,
          icon: <AttachMoneyIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_PRICE_TYPES),
        },
        {
          label: t('navigation.categories'),
          link: routes.categories,
          icon: <ListIcon />,
          shown: isPermittedTo(PERMISSIONS.NAV_CATEGORIES),
        },
      ]
        .filter(link => link.shown)
        .map(link =>
          !link.nested
            ? link
            : {
                ...link,
                nested: (link.nested as (NavigationLink & {
                  shown?: boolean;
                })[])?.filter(nestedLink => nestedLink.shown),
              },
        ),
    [t, isPermittedTo, newMessages],
  );

  return <NavigationExt links={linkConfig} />;
};

export default Navigation;
