import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { useFormState } from 'react-final-form';

/**
 * Display submission error of final-form
 */
export const FormSubmissionErrors = () => {
  const { error, submitError } = useFormState({
    subscription: { error: true, submitError: true, touched: true },
  });

  return <FormHelperText error>{error || submitError || ' '}</FormHelperText>;
};
