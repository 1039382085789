import type { Pagination } from '@rtt-libs/types';
import { difference, flatten, keyBy, keys, uniq } from 'lodash/fp';
import { all, call, put, select } from 'redux-saga/effects';
import { getOrdersByIds } from '../../../api/ordersRefactored';
import type { ReturnProductInfo } from '../../../productsAddition/types';
import type * as OrderTypes from '../../types';
import * as orderActions from '../actions';
import { selectOrdersByIds } from '../selectors';

// TODO: [optimize] call this saga after selecting products, not after loading product list
export function* upgradeReturnProductsByOrderInfo(
  products: ReturnProductInfo[],
) {
  const orderIds = products.map(product => {
    return product.orders ?? [];
  });
  const uniqOrderIds = uniq(flatten(orderIds));

  const {
    data: orders,
  }: ReturnType<ReturnType<typeof selectOrdersByIds>> = yield select(
    selectOrdersByIds(uniqOrderIds),
  );

  // Fetch undefined orders
  const undefinedOrderIds = difference(uniqOrderIds, keys(orders));

  if (undefinedOrderIds.length) {
    const {
      data: encryptedOrders,
    }: {
      data: OrderTypes.Order[];
      meta: { pagination: Pagination };
    } = yield call(getOrdersByIds, { idList: undefinedOrderIds });

    const encryptedOrderCollection = keyBy('id', encryptedOrders);

    yield put(orderActions.getOrdersByIdsSuccess(encryptedOrderCollection));

    yield all([
      put(orderActions.decryptOrderTotalRequest(undefinedOrderIds)),
      put(orderActions.decryptOrderPayloadRequest(undefinedOrderIds)),
      put(orderActions.decryptOrderOriginTotalRequest(undefinedOrderIds)),
    ]);
  }
}
