import type { Pagination } from '@rtt-libs/types';
import { isEqual } from 'lodash';
import at from 'lodash/fp/at';
import get from 'lodash/fp/get';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
  Selector,
} from 'reselect';
import type { AgreedAssortmentItem } from '../../agreedAssortments/types';
import type { EnhancedProduct } from '../../api/orders';
import type { ProductInfo, ReturnProductInfo } from '../types';
import { branchName } from './const';
import type { BranchState } from './reducer';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectProductsCollection: Selector<
  PartialRootState,
  | Record<string, ProductInfo>
  | Record<string, AgreedAssortmentItem['product']>
  | Record<string, EnhancedProduct>
> = get([branchName, 'collection']);

export const selectMemoizedProductsCollection = createDeepEqualSelector(
  selectProductsCollection,
  v => v,
);

export const selectReturnProductsCollection: Selector<
  PartialRootState,
  Record<string, ReturnProductInfo>
> = get([branchName, 'returnCollection']);

export const selectMemoizedReturnProductsCollection = createDeepEqualSelector(
  selectReturnProductsCollection,
  v => v,
);

const getProductIdsSel: Selector<PartialRootState, string[]> = get([
  branchName,
  'idList',
]);

const selectReturnProductIds: Selector<PartialRootState, string[]> = get([
  branchName,
  'returnIdList',
]);

const selectProductsError: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

const getProductsLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

const selectProductPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectProductsForRtt = createSelector(
  [
    selectProductsCollection,
    getProductIdsSel,
    selectProductPagination,
    getProductsLoadingSel,
    selectProductsError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);

export const selectReturnProducts = createDeepEqualSelector(
  [
    selectMemoizedReturnProductsCollection,
    selectReturnProductIds,
    selectProductPagination,
    getProductsLoadingSel,
    selectProductsError,
  ],
  combineStoreSliceData,
);

function combineStoreSliceData<T, K extends string | number | symbol>(
  collection: Record<K, T>,
  ids: K[],
  pagination: Pagination,
  loading: boolean,
  error: string | null,
) {
  return {
    data: at<T>(ids, collection),
    pagination,
    loading,
    error,
  };
}
