import { get, keyBy, omit, set, without } from 'lodash/fp';
import { Action, combineReducers, Reducer } from 'redux';
import {
  ExportDocument,
  ExportResult,
  ExportResultType,
  ExportStatusField,
  ExportStatusValue,
} from '../types';
import type { ExportActions } from './actions';
import * as types from './types';

const exportState = () => ({
  loading: [] as string[],
  errors: {} as Record<string, string>,
});

const initialExportEntriesState = {
  selling: exportState(),
  return: exportState(),
  balance: exportState(),
  // TODO: check necessity
  mixed: exportState(),
};

const exportEntriesReducer: Reducer<
  typeof initialExportEntriesState,
  ExportActions
> = (state = initialExportEntriesState, action) => {
  switch (action.type) {
    case types.EXPORTS_STATUS_SET_REQUEST:
      return {
        ...state,
        [action.meta.type]: {
          loading: state[action.meta.type].loading.concat(action.payload.id),
          errors: omit(action.payload.id, state[action.meta.type].errors),
        },
      };

    case types.EXPORTS_STATUS_SET_SUCCESS:
      return {
        ...state,
        [action.meta.type]: {
          ...state[action.meta.type],
          loading: without(
            [action.payload.id],
            state[action.meta.type].loading,
          ),
        },
      };

    case types.EXPORTS_STATUS_SET_FAILURE:
      return {
        ...state,
        [action.meta.type]: {
          loading: without(
            [action.payload.id],
            state[action.meta.type].loading,
          ),
          errors: set(
            action.payload.id,
            action.payload.error,
            state[action.meta.type].errors,
          ),
        },
      };

    default:
      return state;
  }
};

const initialExportStatusState = {
  status: {} as Partial<ExportResult>,
  loading: false,
  error: null as string | null,
  downloadLink: undefined as string | undefined,
};

export type ExportStatusState = typeof initialExportStatusState;

const exportStatusReducer: Reducer<ExportStatusState, ExportActions> = (
  state = initialExportStatusState,
  action,
) => {
  switch (action.type) {
    case types.EXPORTS_STATUS_GET_REQUEST:
    case types.EXPORTS_START_REQUEST:
    case types.EXPORTS_START_MULTI_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.EXPORTS_STATUS_GET_SUCCESS:
    case types.EXPORTS_START_SUCCESS:
    case types.EXPORTS_START_MULTI_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    case types.EXPORTS_STATUS_GET_FAILURE:
    case types.EXPORTS_START_FAILURE:
    case types.EXPORTS_START_MULTI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.EXPORTS_DOWNLOAD_LINK_SET:
      return {
        ...state,
        downloadLink: action.payload,
      };
    case types.EXPORTS_DOWNLOAD_LINK_UPDATE:
      return {
        ...state,
        downloadLink: undefined,
      };
    case types.EXPORTS_DOWNLOAD_LINK_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        downloadLink: undefined,
      };
    case types.EXPORTS_STATUS_CLEAR:
      return initialExportStatusState;
    default:
      return state;
  }
};

const initialExportStatusMultiSetState = {
  loading: false,
  error: undefined as string | undefined,
};

const exportStatusMultiSetReducer: Reducer<
  typeof initialExportStatusMultiSetState,
  ExportActions
> = (state = initialExportStatusMultiSetState, action) => {
  switch (action.type) {
    case types.EXPORTS_STATUS_MULTI_SET_REQUEST:
      return {
        loading: true,
        error: undefined,
      };
    case types.EXPORTS_STATUS_MULTI_SET_SUCCESS:
      return {
        loading: false,
        error: undefined,
      };
    case types.EXPORTS_STATUS_MULTI_SET_FAILURE:
      return {
        loading: false,
        error: action.meta.error,
      };

    default:
      return state;
  }
};

const initialDocumentsState = {
  collection: {} as Record<string, ExportDocument>,
  entryIds: [] as string[],
  loading: false,
  error: null as null | string,
};

const exportDocumentsReducer: Reducer<
  typeof initialDocumentsState,
  ExportActions
> = (state = initialDocumentsState, action) => {
  switch (action.type) {
    case types.EXPORTS_GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: initialDocumentsState.error,
      };

    case types.EXPORTS_GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        entryIds: action.payload.map(entry => entry.id),
      };

    case types.EXPORTS_GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Update state of manually edited status of document
    // TODO: Copied from common reducer wrapper
    case types.EXPORTS_STATUS_SET_SUCCESS:
      return set(
        exportStatusPath(action.payload.id),
        action.payload.exportStatus,
        state,
      );

    case types.EXPORTS_START_MULTI_REQUEST:
      return setStatusFor(
        state,
        state.entryIds,
        ['not_exported', undefined],
        'processing',
      );
    // Used link set action bc on startSuccess status became just processing
    case types.EXPORTS_DOWNLOAD_LINK_SET:
    case types.EXPORTS_STATUS_GET_FAILURE:
      return setStatusFor(state, state.entryIds, 'processing', 'done');

    // workaround case when ftp/email export won't return token src link
    case types.EXPORTS_STATUS_GET_SUCCESS:
      return !action.payload.isDone || action.payload.src
        ? state
        : setStatusFor(state, state.entryIds, 'processing', 'done');

    case types.EXPORTS_START_MULTI_FAILURE:
      return setStatusFor(state, state.entryIds, 'processing', 'not_exported');

    /*
     * Multi export status
     */
    case types.EXPORTS_STATUS_MULTI_SET_REQUEST:
      return setStatusFor(
        state,
        state.entryIds,
        action.payload.isExported ? ['not_exported', undefined] : 'done',
        'processing',
      );
    case types.EXPORTS_STATUS_MULTI_SET_SUCCESS:
      return setStatusFor(
        state,
        action.payload.ids,
        'processing',
        action.payload.isExported ? 'done' : 'not_exported',
      );
    case types.EXPORTS_STATUS_MULTI_SET_FAILURE:
      return setStatusFor(
        state,
        action.payload.ids,
        'processing',
        action.payload.isExported ? 'not_exported' : 'done',
      );

    default:
      return state;
  }
};

export type BranchState = {
  entries: typeof initialExportEntriesState;
  status: ExportStatusState;
  documents: typeof initialDocumentsState;
  multiStatus: typeof initialExportStatusMultiSetState;
};

export default combineReducers({
  entries: exportEntriesReducer,
  status: exportStatusReducer,
  documents: exportDocumentsReducer,
  multiStatus: exportStatusMultiSetReducer,
});

/**
 * Export entries status helpers
 */
const exportStatusPath = (id: string | number) => [
  'collection',
  id,
  'exportStatus',
  'status',
];

type ExportEntriesState<EntryType extends Partial<ExportStatusField>> = {
  collection: Record<string | number, EntryType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
};

const setStatusFor = <
  EntryType extends Partial<ExportStatusField>,
  State extends ExportEntriesState<EntryType>
>(
  state: State,
  ids: (string | number)[],
  filterStatus: ExportStatusValue | (ExportStatusValue | undefined)[],
  newStatus: ExportStatusValue,
) => {
  let updatedState = state;
  ids
    .filter(id => {
      const statusValue = get(exportStatusPath(id), state);
      if (Array.isArray(filterStatus)) {
        return filterStatus.includes(statusValue);
      }
      return get(exportStatusPath(id), state) === filterStatus;
    })
    .forEach(id => {
      updatedState = set(exportStatusPath(id), newStatus, updatedState);
    });

  return updatedState;
};

const exportEntryStatusReducer = <
  EntryType extends Partial<ExportStatusField>,
  State extends ExportEntriesState<EntryType>
>(
  metaType: ExportResultType,
  idsField: string,
) => (state: State | undefined, action: ExportActions) => {
  if (state === undefined) return state;

  switch (action.type) {
    case types.EXPORTS_STATUS_SET_SUCCESS:
      return action.meta.type !== metaType
        ? state
        : set(
            exportStatusPath(action.payload.id),
            action.payload.exportStatus,
            state,
          );

    case types.EXPORTS_START_REQUEST:
      return action.payload.type !== metaType
        ? state
        : setStatusFor(
            state,
            state[idsField],
            ['not_exported', undefined],
            'processing',
          );
    // Used link set action bc on startSuccess status became just processing
    case types.EXPORTS_DOWNLOAD_LINK_SET:
      return action.meta.type !== metaType
        ? state
        : setStatusFor(state, state[idsField], 'processing', 'done');

    // workaround case when ftp/email export won't return token src link
    case types.EXPORTS_STATUS_GET_SUCCESS:
      return !action.payload.isDone || action.payload.src
        ? state
        : setStatusFor(state, state[idsField], 'processing', 'done');

    case types.EXPORTS_START_FAILURE:
      return action.meta.type !== metaType
        ? state
        : setStatusFor(state, state[idsField], 'processing', 'not_exported');

    default:
      return state;
  }
};

export const withExportsReducer = <
  EntryType extends Partial<ExportStatusField>,
  State extends ExportEntriesState<EntryType>,
  Actions extends Action
>(
  metaType: ExportResultType,
  idsField: string,
  wrappedReducer: Reducer<State, Actions>,
) => (state: State | undefined, action: Actions) => {
  const updatedState = exportEntryStatusReducer<EntryType, State>(
    metaType,
    idsField,
  )(state, action);

  return wrappedReducer(updatedState, action);
};
