import { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import {
  CreateSaleValues,
  DiscountBrand,
  DiscountProduct,
  Sale,
  SalesSearchParams,
} from '../../api/sales';
import * as types from './types';

export const getAllSalesRequest = () => ({
  type: types.SALE_GET_ALL_REQUEST,
});

export const getAllSalesSuccess = (payload: Sale[]) => ({
  type: types.SALE_GET_ALL_SUCCESS,
  payload,
});

export const getAllSalesFailure = (error: string) => ({
  type: types.SALE_GET_ALL_FAILURE,
  payload: error,
});

export const searchSalesRequest = (params?: SalesSearchParams) => ({
  type: types.SALE_SEARCH_REQUEST,
  payload: params,
});

export const searchSalesSuccess = (
  payload: Sale[],
  meta: { pagination: Pagination },
) => ({
  type: types.SALE_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchSalesFailure = (error: string) => ({
  type: types.SALE_SEARCH_FAILURE,
  payload: error,
});

export const createSaleRequest = (values: CreateSaleValues) => ({
  type: types.SALE_CREATE_REQUEST,
  payload: values,
});

export const createSaleResponse = (
  submissionErrors?:
    | Partial<CreateSaleValues & { [FORM_ERROR]: string }>
    | { id: Sale['id'] },
) => ({
  type: types.SALE_CREATE_RESPONSE,
  payload: submissionErrors,
});

export const createSaleSuccess = (payload: Sale) => ({
  type: types.SALE_CREATE_SUCCESS,
  payload,
});

export const createSaleFailure = (error: string) => ({
  type: types.SALE_CREATE_FAILURE,
  payload: error,
});

export const getSaleDetailsRequest = (id: Sale['id']) => ({
  type: types.SALE_DETAILS_REQUEST,
  payload: id,
});

export const getSaleDetailsSuccess = (
  payload: Sale,
  meta: {
    products: Record<DiscountProduct['id'], DiscountProduct>;
    brands: Record<DiscountBrand['id'], DiscountBrand>;
  },
) => ({
  type: types.SALE_DETAILS_SUCCESS,
  payload,
  meta,
});

export const getSaleDetailsFailure = (error: string) => ({
  type: types.SALE_DETAILS_FAILURE,
  payload: error,
});

export const editSaleRequest = (id: Sale['id'], values: CreateSaleValues) => ({
  type: types.SALE_EDIT_REQUEST,
  payload: {
    id,
    values,
  },
});

export const editSaleResponse = (
  submissionErrors?: Partial<CreateSaleValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.SALE_EDIT_RESPONSE,
  payload: submissionErrors,
});

export const editSaleSuccess = (payload: Sale) => ({
  type: types.SALE_EDIT_SUCCESS,
  payload,
});

export const editSaleFailure = (error: string) => ({
  type: types.SALE_EDIT_FAILURE,
  payload: error,
});

export const deleteSaleRequest = (id: Sale['id']) => ({
  type: types.SALE_DELETE_REQUEST,
  payload: id,
});

export const deleteSaleSuccess = (id: Sale['id']) => ({
  type: types.SALE_DELETE_SUCCESS,
  payload: id,
});

export const deleteSaleFailure = (error: string) => ({
  type: types.SALE_DELETE_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof getAllSalesRequest
  | typeof getAllSalesSuccess
  | typeof getAllSalesFailure
  | typeof searchSalesRequest
  | typeof searchSalesSuccess
  | typeof searchSalesFailure
  | typeof createSaleRequest
  | typeof createSaleResponse
  | typeof createSaleSuccess
  | typeof createSaleFailure
  | typeof getSaleDetailsRequest
  | typeof getSaleDetailsSuccess
  | typeof getSaleDetailsFailure
  | typeof editSaleRequest
  | typeof editSaleResponse
  | typeof editSaleSuccess
  | typeof editSaleFailure
  | typeof deleteSaleRequest
  | typeof deleteSaleSuccess
  | typeof deleteSaleFailure
>;
