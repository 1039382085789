/* eslint-disable @typescript-eslint/no-empty-interface, @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import { OrderParam, Paginated } from '@rtt-libs/types';
import snakeCase from 'lodash/snakeCase';
import { ENDPOINTS } from '../environment';
import type * as SupervisorsTypes from '../supervisors/types';
import api from './apiSetup';
import { transformSimpleErrorMessage } from './mappers';
import { StaffId } from '../common/staff/types';

export interface Supervisor extends SupervisorsTypes.Supervisor {}
export interface AssignedManager extends SupervisorsTypes.AssignedManager {}

type FetchedAssignedManager = {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  login: string;
  is_active?: boolean;
};

export class AssignedManager {
  constructor(fetched: FetchedAssignedManager) {
    this.id = fetched.id;
    this.phone = fetched.phone;
    this.login = fetched.login;
    this.email = fetched.email;
    this.firstName = fetched.first_name;
    this.lastName = fetched.last_name;
    this.isActive = fetched.is_active ?? false;
  }
}

type FetchedSupervisor = {
  id: number;
  phone: string;
  login: string;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  manager_list?: FetchedAssignedManager[];
};

export class Supervisor {
  constructor(supervisor: FetchedSupervisor) {
    this.id = supervisor.id;
    this.phone = supervisor.phone;
    this.login = supervisor.login;
    this.email = supervisor.email;
    this.firstName = supervisor.first_name;
    this.lastName = supervisor.last_name;
    this.password = supervisor.password;
    this.isActive = supervisor.is_active;
    this.managerList =
      supervisor.manager_list?.map(manager => new AssignedManager(manager)) ??
      [];
  }
}

type SortValueApi =
  | 'email'
  | 'phone'
  | 'login'
  | 'first_name'
  | 'last_name'
  | 'is_active';

type APISearchParams = Partial<{
  search: string;
  sort: SortValueApi | string;
  order: OrderParam;
  is_active: '0' | '1';
  per_page: number;
  page: number;
}>;

type CreateSupervisorValuesAPI = {
  login: string;
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
  manager_list: StaffId[];
  // TODO: temp - will be removed after back-end implementations
  user_type: string;
};

type EditSupervisorValuesAPI = CreateSupervisorValuesAPI & {
  password: string;
};

/*
 * Supervisor API handlers
 */

export const searchSupervisors = (params: SupervisorsTypes.SearchParams) =>
  api
    .get<Paginated<FetchedSupervisor>>(ENDPOINTS.supervisors, {
      params: mapAPISearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapSupervisorArray))
    .catch(transformSimpleErrorMessage);

export const createSupervisor = (values: SupervisorsTypes.SupervisorValues) =>
  api
    .post<FetchedSupervisor>(
      ENDPOINTS.supervisors,
      mapCreateSupervisorValues(values),
    )
    .then(({ data }) => new Supervisor(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateSupervisorErrors);
    });

export const editSupervisor = (values: SupervisorsTypes.EditSupervisorValues) =>
  api
    .put<FetchedSupervisor>(
      `${ENDPOINTS.supervisors}/${values.id}`,
      mapEditSupervisorValues(values),
    )
    .then(({ data }) => new Supervisor(data))
    .catch(e => {
      throw formErrorTransform(e, transformEditSupervisorErrors);
    });

export const changeSupervisorStatus = ({
  id,
  status,
}: {
  id: number;
  status: boolean;
}) =>
  api
    .post<FetchedSupervisor>(
      `${ENDPOINTS.supervisors}/${id}/${ENDPOINTS.employeesStatusSuffix}`,
      { is_active: status },
    )
    .then(({ data }) => new Supervisor(data))
    .catch(e => {
      throw collectErrorMessagesToError(
        formErrorTransform(e, transformChangeStatusSupervisorErrors),
      );
    });

export const getAvailableManagersForAssign = () =>
  api
    .get<FetchedAssignedManager[]>(ENDPOINTS.supervisorAssignmentManagers)
    .then(({ data }) => data.map(manager => new AssignedManager(manager)))
    .catch(transformSimpleErrorMessage);

/*
 * Supervisor Mappers
 */

function mapSupervisorArray(supervisorArray: FetchedSupervisor[]) {
  return supervisorArray.map(supervisor => new Supervisor(supervisor));
}

function mapAPISearchParams(
  params: SupervisorsTypes.SearchParams,
): APISearchParams {
  return {
    search: params.search || undefined,
    sort: params.orderBy && snakeCase(params.orderBy),
    order: params.order,
    is_active: params.isActive || undefined,
    per_page: params.perPage,
    page: params.page,
  };
}

function mapCreateSupervisorValues(
  values: SupervisorsTypes.SupervisorValues,
): CreateSupervisorValuesAPI {
  return {
    login: values.login,
    phone: values.phone,
    email: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
    manager_list: values.managerList,
    // TODO: temp - will be removed after back-end implementations
    user_type: 'distributor_supervisor',
  };
}

function mapEditSupervisorValues({
  password,
  ...values
}: SupervisorsTypes.EditSupervisorValues): EditSupervisorValuesAPI {
  return {
    password,
    ...mapCreateSupervisorValues(values),
  };
}

const transformCreateSupervisorErrors: TransformAPIError<
  Record<keyof CreateSupervisorValuesAPI, string | string[]>,
  Record<keyof SupervisorsTypes.SupervisorValues, string>
> = function transformCreateSupervisorErrors(e) {
  return {
    login: e.login?.toString(),
    phone: e.phone?.toString(),
    email: e.email?.toString(),
    firstName: e.first_name?.toString(),
    lastName: e.last_name?.toString(),
  };
};

const transformEditSupervisorErrors: TransformAPIError<
  Record<keyof EditSupervisorValuesAPI, string | string[]>,
  Record<keyof SupervisorsTypes.EditSupervisorValues, string>
> = function transformEditSupervisorErrors(e) {
  return {
    password: e.password?.toString(),
    ...transformCreateSupervisorErrors(e),
  };
};

const transformChangeStatusSupervisorErrors: TransformAPIError<
  Record<'is_active', string | string[]>,
  Record<'isActive', string>
> = function transformChangeStatusSupervisorErrors(e) {
  return {
    isActive: e.is_active?.toString(),
  };
};

function collectErrorMessagesToError(errors: Record<string, string>) {
  return new Error(Object.values(errors).join(' '));
}
