import type { BalanceSettings, BalanceSettingsFormErrors } from '../types';
import * as types from './types';

export const getBalanceSettingsRequest = () => ({
  type: types.BALANCE_SETTINGS_GET_REQUEST,
});

export const getBalanceSettingsSuccess = (payload: BalanceSettings) => ({
  type: types.BALANCE_SETTINGS_GET_SUCCESS,
  payload,
});

export const getBalanceSettingsFailure = (error: string) => ({
  type: types.BALANCE_SETTINGS_GET_FAILURE,
  payload: error,
});

export const editBalanceSettingsRequest = (payload: BalanceSettings) => ({
  type: types.BALANCE_SETTINGS_EDIT_REQUEST,
  payload,
});

export const editBalanceSettingsSuccess = (payload: BalanceSettings) => ({
  type: types.BALANCE_SETTINGS_EDIT_SUCCESS,
  payload,
});

export const editBalanceSettingsFailure = (
  payload: BalanceSettingsFormErrors,
) => ({
  type: types.BALANCE_SETTINGS_EDIT_FAILURE,
  payload,
});

export type Actions = ReturnType<
  | typeof getBalanceSettingsRequest
  | typeof getBalanceSettingsSuccess
  | typeof getBalanceSettingsFailure
  | typeof editBalanceSettingsRequest
  | typeof editBalanceSettingsSuccess
  | typeof editBalanceSettingsFailure
>;
