import { restrictions } from '@rtt-libs/constants';
import { FieldWithMessages } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInputAdapter } from '../../../common';

interface Props {
  id: string | number | symbol;
  value: unknown;
}

const BalanceInput = ({ id, value }: Props) => {
  const [t] = useTranslation();

  const adornment = t('product.values.unit');

  return (
    <FieldWithMessages
      subscription={{
        value: true,
        error: true,
        touched: true,
        submitError: true,
        dirtySinceLastSubmit: true,
      }}
      name={`balances.${String(id)}`}
      component={NumberInputAdapter}
      parse={v => v ?? null}
      inputProps={{
        'aria-label': 'qty',
        step: 0.001,
        min: 0,
        max: restrictions.PRODUCT_QTY_MAX_VALUE,
        required: true,
      }}
      endAdornment={adornment}
      initialValue={0}
      stepMultiplier={1000}
    />
  );
};

export default React.memo(BalanceInput);
