import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTerritoriesRequest } from '../duck/actions';

const TerritoriesContainer: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTerritoriesRequest());
  }, [dispatch]);

  return <>{children}</>;
};

export default TerritoriesContainer;
