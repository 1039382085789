import { FORM_ERROR, SubmissionErrors } from 'final-form';
import noop from 'lodash/noop';
import { mapKeys, camelCase } from 'lodash/fp';

type Error = {
  message: string;
  response?: {
    data: {
      message: string;
      errors?: {
        [field: string]: string[];
      };
    };
  };
};

type Callback = (message: string | undefined) => void;

const formErrorTransform = (
  e: Error,
  callback: Callback = noop,
): SubmissionErrors => {
  let errors = {};
  if (e.response) {
    callback(e.response.data.message);
    errors = {
      ...(e.response.data.errors && mapKeys(camelCase, e.response.data.errors)),
      [FORM_ERROR]: e.response.data.message,
    };
  } else {
    callback(e.message);
    errors = {
      [FORM_ERROR]: e.message,
    };
  }

  return errors;
};

export default formErrorTransform;
