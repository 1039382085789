import {
  Card,
  CardContent,
  CardHeaderProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import type { Contact } from '@rtt-libs/types';
import React from 'react';
import ManagerOrderSliceHeader from './ManagerOrderSliceHeader';

interface Props {
  content: React.ReactNode;
  manager: Contact;
  headerProps?: CardHeaderProps;
  orderId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      borderTopColor: theme.palette.divider,
      borderTop: '1px solid',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  }),
);

const OrderSliceProductsWrapper: React.FC<Props> = ({
  content,
  manager,
  orderId,
  headerProps,
  children,
}) => {
  const classes = useStyles();

  return (
    <Card component="section">
      <ManagerOrderSliceHeader
        manager={manager}
        orderId={orderId}
        {...headerProps}
      />

      <CardContent classes={classes}>{content}</CardContent>

      {children}
    </Card>
  );
};

export default OrderSliceProductsWrapper;
