/* eslint-disable @typescript-eslint/camelcase */

import { mapPaginatedData, someBooleanToInt } from '@rtt-libs/api-services';
import { PRODUCTS_DEFAULTS } from '@rtt-libs/constants';
import {
  AttachmentDocumentType,
  Measurement,
  OrderParam,
  Paginated,
} from '@rtt-libs/types';
import trim from 'lodash/fp/trim';
import { ENDPOINTS } from '../environment';
import type { Brand } from '../types';
import api from './apiSetup';
import { FetchedProduct } from './assortment';
import { transformSimpleErrorMessage } from './mappers';

class PayloadProduct {
  id: string;
  sku: string;
  title: string;
  weight: number | null;
  price: number;
  total: number;
  is_revocable: boolean;
  is_available?: boolean;
  sale_measurement?: Measurement;
  image: AttachmentDocumentType;
  qty: number;
  order_weight: number;
  return_note?: string;
  orders?: string[];
  is_defective?: boolean;

  brand_id?: Brand['id'];

  constructor(product: EnhancedProduct) {
    this.id = product.id;
    this.image = product.image;
    this.is_available = product.isAvailable ?? true;
    this.is_revocable = product.isRevocable ?? true;
    this.order_weight = product.orderWeight;
    this.price = product.price ?? 0;
    this.qty = product.qty;
    this.return_note = product.returnNote;
    this.sale_measurement = product.saleMeasurement ?? 'weight';
    this.sku = product.sku;
    this.title = product.title;
    this.total = product.total;
    this.weight = product.weight;
    this.orders = product.orders;
    this.is_defective = product.isDefective;

    this.brand_id = product.brandId;
  }
}

export class EnhancedProduct {
  id: string;
  image: AttachmentDocumentType;
  isAvailable: boolean;
  isRevocable: boolean;
  orderWeight: number;
  price?: number;
  qty: number;
  returnNote?: string;
  saleMeasurement?: Measurement;
  sku: string;
  title: string;
  total: number;
  weight: number | null;
  orders?: string[];
  manuallyAdded?: boolean;
  maxOrderWeight?: number;
  maxQty?: number;
  orderId?: string;
  isDefective: boolean;

  brandId?: Brand['id'];
  [key: string]: unknown;

  constructor(product: PayloadProduct) {
    this.id = product.id;
    this.image = product.image;
    this.isAvailable = product.is_available ?? true;
    this.isRevocable = product.is_revocable ?? true;
    this.orderWeight = product.order_weight;
    this.price = product.price;
    this.qty = product.qty;
    this.returnNote = product.return_note;
    this.saleMeasurement = product.sale_measurement;
    this.sku = product.sku;
    this.title = product.title;
    this.total = product.total;
    this.weight = product.weight;
    this.isDefective = product.is_defective ?? false;
    // Mapped to fix misconception in different representation of Product
    this.saleMeasurement =
      ((this.saleMeasurement as unknown) as {
        type: Measurement;
      })?.type || this.saleMeasurement;
    this.orders = product.orders;
    this.brandId = product.brand_id;
  }
}

type APIBalanceProductSearchParams = Partial<{
  search: string;
  category_id: number[];
  sort: 'sku' | 'title';
  order: OrderParam;
  page: number;
  per_page: number;
  order_id: string;
  is_active: 0 | 1;
  is_invalid: 0 | 1;
  is_all: 0 | 1;
}>;

export type ProductBalanceSearchParams = Partial<{
  search: string;
  categoryIds: number[];
  orderBy: 'sku' | 'title';
  order: OrderParam;
  page: number;
  perPage: number;
  orderId: string;
  isActive: boolean;
  isInvalid: boolean;
  withHistoryFilter: boolean;
}>;

/*
 * Balance Products with history API handlers
 */

export const searchBalanceProductsForRtt = (
  rttId: number,
  params?: ProductBalanceSearchParams,
) =>
  api
    .get<Paginated<FetchedProduct>>(ENDPOINTS.balancesProducts(rttId), {
      params: params && mapProductSearchParams(params),
    })
    .then(data =>
      mapPaginatedData(data.data, products =>
        products.map(
          product =>
            new EnhancedProduct((product as unknown) as PayloadProduct),
        ),
      ),
    )
    .catch(transformSimpleErrorMessage);

/*
 * Mappers
 */

function mapProductSearchParams(
  params: ProductBalanceSearchParams,
): APIBalanceProductSearchParams {
  return {
    sort: params.orderBy,
    order: params.order,
    per_page: params.perPage,
    page: params.page,
    category_id: params.categoryIds,
    search:
      (typeof params.search === 'string' && trim(params.search)) || undefined,
    order_id: params.orderId,
    is_active: someBooleanToInt(params.isActive),
    is_invalid: someBooleanToInt(params.isInvalid),
    // inverted value to divide API logic from front-end. `withHistoryFilter` used as checkbox which is checked by default
    is_all: someBooleanToInt(
      !(params.withHistoryFilter ?? PRODUCTS_DEFAULTS.BALANCES_FROM_HISTORY),
    ),
  };
}
