import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  passwordField?: React.ReactNode;
}

const StaffFormFields = ({ passwordField }: Props) => {
  const [t] = useTranslation();

  return (
    <>
      <Field
        autoFocus
        margin="dense"
        name="login"
        type="text"
        fullWidth
        required
        component={TextFieldAdapter}
        inputProps={{
          pattern: '^[a-zA-Z0-9-_]+$',
        }}
        label={t('distributor.employees.add.login.label')}
        placeholder={t('distributor.employees.add.login.placeholder')}
      />

      {passwordField}

      <Field
        component={TextFieldAdapter}
        name="firstName"
        margin="dense"
        type="text"
        fullWidth
        required
        label={t('distributor.employees.add.firstName.label')}
      />
      <Field
        component={TextFieldAdapter}
        name="lastName"
        margin="dense"
        type="text"
        fullWidth
        required
        label={t('distributor.employees.add.lastName.label')}
      />
      <Field
        component={TextFieldAdapter}
        name="email"
        margin="dense"
        type="email"
        fullWidth
        required
        label={t('distributor.employees.add.email.label')}
        placeholder={t('distributor.employees.add.email.placeholder')}
      />
      <Field
        component={TextFieldAdapter}
        name="phone"
        margin="dense"
        type="phone"
        inputProps={{
          pattern: '^(\\+\\d{1,2})?\\d{10}$',
        }}
        fullWidth
        required
        label={t('distributor.employees.add.phone.label')}
        placeholder={t('distributor.employees.add.phone.placeholder')}
      />
    </>
  );
};

export default StaffFormFields;
