import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../../common/useSnackbarError';
import {
  decryptOrderOriginTotalRequest,
  decryptOrderPayloadRequest,
  decryptOrderTotalRequest,
} from '../../duck/actions';
import {
  selectOrderOriginTotal,
  selectOrderPayloadProducts,
  selectOrderTotal,
} from '../../duck/selectors';

export const useDetailsDecryptionState = (orderId: string) => {
  const dispatch = useDispatch();
  const {
    data: products,
    loading: payloadLoading,
    error,
    shopInfo,
  } = useSelector(selectOrderPayloadProducts(orderId));
  const { data: total, loading: totalLoading, error: totalError } = useSelector(
    selectOrderTotal(orderId),
  );
  const {
    data: originTotal,
    loading: originTotalLoading,
    error: originTotalError,
  } = useSelector(selectOrderOriginTotal(orderId));

  const shouldPayloadDecrypt = !products && !payloadLoading;
  const shouldTotalDecrypt =
    total === undefined && !totalLoading && !totalError;
  const shouldOriginTotalDecrypt =
    originTotal === undefined && !originTotalLoading && !originTotalError;

  useEffect(() => {
    if (shouldPayloadDecrypt) {
      dispatch(decryptOrderPayloadRequest([orderId]));
    }
  }, [dispatch, orderId, shouldPayloadDecrypt]);

  useEffect(() => {
    if (shouldTotalDecrypt) {
      dispatch(decryptOrderTotalRequest([orderId]));
    }
  }, [dispatch, orderId, shouldTotalDecrypt]);

  useEffect(() => {
    if (shouldOriginTotalDecrypt) {
      dispatch(decryptOrderOriginTotalRequest([orderId]));
    }
  }, [dispatch, orderId, shouldOriginTotalDecrypt]);

  useSnackbarError(error);

  return {
    products,
    originTotal,
    total,
    payloadLoading,
    shouldTotalDecrypt,
    shouldOriginTotalDecrypt,
    shopInfo,
  };
};
