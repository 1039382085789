import { CheckboxAdapter } from '@rtt-libs/views';
import React from 'react';
import { Field } from 'react-final-form';
import type { ExportResultType } from '../../../types';

interface Props {
  name: string;
  label: string;
  value: ExportResultType;
  disabled?: boolean;
}

const ExportTypeCheckbox = ({ name, value, label, disabled }: Props) => {
  return (
    <Field
      type="checkbox"
      component={CheckboxAdapter}
      name={name}
      value={value}
      label={label}
      disabled={disabled}
    />
  );
};

export default ExportTypeCheckbox;
