import {
  notificationsReducer as chatNotifications,
  reducer as chats,
} from '@rtt-libs/chats';
import { reducer as commonNotifications } from '@rtt-libs/state-containers';
import { Action, combineReducers } from 'redux';
import { reducer as administrators } from '../administrators';
import { reducer as agreedAssortments } from '../agreedAssortments';
import { reducer as assortment } from '../assortment';
import { reducer as balances } from '../balances';
import { reducer as balanceSettings } from '../balanceSettings';
import { reducer as brands } from '../brands';
import { reducer as categories } from '../categories';
import { reducer as discounts } from '../discounts';
import { reducer as employees } from '../employees';
import { reducer as exports } from '../exports';
import { reducer as orders } from '../ordersRefactored';
import { reducer as priceTypes } from '../priceTypes';
import { reducer as products } from '../productsAddition';
import { reducer as profile } from '../profile';
import { reducer as register } from '../registrationFinish';
import { reducer as rtt } from '../rtt/duck';
import { reducer as sales } from '../sales/duck';
import { reducer as sample } from '../sampleDomain';
import { reducer as settings } from '../settings';
import { reducer as supervisorManagers } from '../supervisorManagers';
import { reducer as supervisors } from '../supervisors';
import { reducer as territories } from '../territories';
import { reducer as uploadAssortment } from '../uploadAssortment';

const notifications = (
  state: ReturnType<typeof commonNotifications>,
  action: Action,
) =>
  chatNotifications(
    commonNotifications(
      state,
      action as Parameters<typeof commonNotifications>[1],
    ),
    action as Parameters<typeof chatNotifications>[1],
  );

const rootReducer = combineReducers({
  administrators,
  agreedAssortments,
  assortment,
  balances,
  balanceSettings,
  brands,
  categories,
  discounts,
  employees,
  exports,
  notifications,
  orders,
  priceTypes,
  products,
  register,
  rtt,
  sales,
  sample,
  settings,
  supervisorManagers,
  supervisors,
  territories,
  uploadAssortment,
  profile,
  chats,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
