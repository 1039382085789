import { Reducer } from 'redux';
import { SettingsExport } from '../../api/settingsExport';
import { SettingsOrders } from '../../api/settingsOrders';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: {
    settingsExport: false,
    settingsOrders: false,
  },
  settingsOrders: {} as SettingsOrders,
  settingsExport: {} as SettingsExport,
  error: null as string | null,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.EDIT_SETTINGS_EXPORT_SUCCESS:
    case types.GET_SETTINGS_EXPORT_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, settingsExport: false },
        settingsExport: action.payload,
        error: null,
      };
    }
    case types.GET_SETTINGS_EXPORT_REQUEST: {
      return {
        ...state,
        loading: { ...state.loading, settingsExport: true },
        error: null,
      };
    }
    case types.EDIT_SETTINGS_EXPORT_FAILURE:
    case types.GET_SETTINGS_EXPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, settingsExport: false },
        error: action.payload,
      };
    case types.GET_SETTINGS_ORDERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, settingsOrders: true },
        error: null,
      };
    case types.GET_SETTINGS_ORDERS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, settingsOrders: false },
        settingsOrders: action.payload,
      };
    case types.EDIT_SETTINGS_ORDERS_SUCCESS:
      return {
        ...state,
        settingsOrders: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
