import { Pagination, CategoryItemId, CategoryItem } from '@rtt-libs/types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAssortment,
  getCurrencyList,
  getProduct,
  Product,
  updateProduct,
  Currency,
} from '../../api/assortment';
import { getCategoryList } from '../../api/categories';
import * as actions from './actions';
import * as types from './types';

function* worker({ payload }: ReturnType<typeof actions.getAssortmentRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Product[]; meta: { pagination: Pagination } } = yield call(
      getAssortment,
      payload,
    );

    yield all([put(actions.getAssortmentSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.getAssortmentFailure(e.message));
  }
}

function* productWorker({
  payload,
}: ReturnType<typeof actions.getProductRequest>) {
  try {
    const data: Product = yield call(getProduct, payload);

    yield all([put(actions.getProductSuccess(data))]);
  } catch (e) {
    if (e.response) {
      yield put(actions.getProductFailure(e.response.data.message));
    } else {
      yield put(actions.getProductFailure(e.message));
    }
  }
}

function* updateWorker({
  payload,
}: ReturnType<typeof actions.postProductRequest>) {
  try {
    const data: Product = yield call(updateProduct, payload.values, payload.id);

    yield put(actions.postProductSuccess(data));
  } catch (e) {
    yield put(actions.postProductFailure(e));
  }
}

function* optionsWorker() {
  try {
    const [categoryList, currencyList]: [
      Record<CategoryItemId, CategoryItem>,
      Currency[],
    ] = yield all([call(getCategoryList), call(getCurrencyList)]);

    yield all([
      put(
        actions.getOptionsProductSuccess({
          categoryList,
          currencyList,
        }),
      ),
    ]);
  } catch (e) {
    if (e.response) {
      yield put(actions.getOptionsProductFailure(e.response.data.message));
    }
    yield put(actions.getOptionsProductFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_ASSORTMENT_REQUEST, worker);
  yield takeLatest(types.GET_PRODUCT_REQUEST, productWorker);
  yield takeLatest(types.POST_PRODUCT_REQUEST, updateWorker);
  yield takeLatest(types.GET_OPTIONS_PRODUCT_REQUEST, optionsWorker);
}
