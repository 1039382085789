import {
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { restrictions } from '@rtt-libs/constants';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import { mapValues } from 'lodash';
import omit from 'lodash/fp/omit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Brand } from '../../../../types';
import AddBrandButtonModal from './AddBrandButtonModal';
import AddBrandContainer from './AddBrandContainer';

const useStyles = makeStyles(
  createStyles({
    withoutSteppers: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  }),
);

type Props = {
  messagesDomain: 'discounts' | 'sales';
  discountBrands: Record<number, Brand>;
};

const DiscountBrands: React.FC<Props> = ({
  messagesDomain,
  discountBrands,
}) => {
  const [t] = useTranslation();

  const classes = useStyles();

  const [brandFields, setBrandFields] = useState<Record<string, Brand>>({});

  const formApi = useForm();

  useEffect(() => {
    const { brandDiscounts = {} } = formApi.getState().initialValues || {};

    setBrandFields(prevFields => ({
      ...mapValues(brandDiscounts, (_, key) => {
        const id = +key.substring(3);
        return (
          discountBrands[id] ||
          ({ id: +id, title: id.toString(), isActive: false } as Brand)
        );
      }),
      ...prevFields,
    }));
  }, [formApi, discountBrands]);

  const addItem = useCallback(
    (item: Brand) => {
      setBrandFields(prevBrands => ({
        ...prevBrands,
        [`key${item.id}`]: item,
      }));
    },
    [setBrandFields],
  );

  const existingIds = useMemo(
    () => Object.keys(brandFields).map(v => Number(v.substring(3))),
    [brandFields],
  );

  const handleClickRemoveDiscount = (itemId: string) => {
    setBrandFields(prevBrands => omit([`key${itemId}`], prevBrands));
    formApi.change(`brandDiscounts.key${itemId}`);
  };

  return (
    <>
      <Grid item xs={8}>
        <Typography variant="h6">
          {t(`distributor.${messagesDomain}.brandsTitle`)}
        </Typography>
      </Grid>

      <Grid item>
        <AddBrandButtonModal>
          {onClose => (
            <AddBrandContainer
              existingIds={existingIds}
              onSubmit={addItem}
              onClose={onClose}
            />
          )}
        </AddBrandButtonModal>
      </Grid>

      {!Object.values(brandFields).length && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t(`distributor.${messagesDomain}.add.emptyBrandsMessage`)}
          </Typography>
        </Grid>
      )}

      {Object.values(brandFields).map(item => (
        <Grid item key={item.id} lg={6} xs={12}>
          <Field
            name={`brandDiscounts.key${item.id}`}
            component={TextFieldAdapter}
            label={item.title}
            variant="filled"
            margin="dense"
            type="number"
            fullWidth
            parse={value => Number(value)}
            inputProps={restrictions.DISCOUNT_INPUTS_VALIDATIONS}
            className={classes.withoutSteppers}
            required
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">%</InputAdornment>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t('controls.delete')}
                      onClick={() => {
                        return handleClickRemoveDiscount(item.id.toString());
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Grid>
      ))}
    </>
  );
};

export default DiscountBrands;
