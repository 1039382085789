import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { StaffValues } from '../../types';
import DialogActionsButtons from '../DialogActionsButtons';
import StaffFormFields from './StaffFormFields';

type Props<T> = FormRenderProps<T> & { onClose(): void };

function CreateStaffForm<T extends StaffValues = StaffValues>({
  handleSubmit,
  submitError,
  onClose,
  children,
}: Props<T>) {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="add-employee-dialog-title">
        {t('distributor.employees.add.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('distributor.employees.add.dialogDescription')}
        </DialogContentText>

        <StaffFormFields />

        {children}

        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>

      <DialogActionsButtons onClose={onClose} submitLabel={t('controls.add')} />
    </form>
  );
}

export default CreateStaffForm;
