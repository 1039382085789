import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LoadingButton } from '@rtt-libs/views';
import React, { useRef, useState } from 'react';

type SplitButtonOption = {
  label: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>,
  ) => void;
  disabled?: boolean;
  tooltip?: string;
};

interface Props {
  options: SplitButtonOption[];
  loading?: boolean;
  disabled?: boolean;
  callImmediately?: boolean;
}

const SplitButton = ({
  options,
  loading,
  callImmediately,
  disabled,
}: Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    // eslint-disable-next-line no-unused-expressions
    if (callImmediately) options[index].onClick?.(event);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
      >
        <LoadingButton
          onClick={options[selectedIndex].onClick}
          loading={loading}
          disabled={loading}
        >
          {options[selectedIndex].label}
        </LoadingButton>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Box clone zIndex={500}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => {
                      const renderMenuItem = (
                        <MenuItem
                          key={option.label}
                          disabled={option.disabled}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      );

                      if (option.tooltip && option.disabled)
                        return (
                          <Tooltip title={option.tooltip} placement="left">
                            <div>{renderMenuItem}</div>
                          </Tooltip>
                        );

                      return renderMenuItem;
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default SplitButton;
