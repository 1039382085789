import { Pagination } from '@rtt-libs/types';
import { fromPairs, keyBy, keys, omit } from 'lodash/fp';
import { Reducer } from 'redux';
import { RttSelectOption } from '../../agreedAssortments/types';
import { withExportsReducer } from '../../exports/duck/reducer';
import { Balance, ProductWithOrderedQty } from '../types';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  collection: {} as Record<Balance['id'], Balance>,
  itemIds: [] as Balance['id'][],
  pagination: {} as Pagination,
  error: null as string | null,
  rttOptions: [] as RttSelectOption[],
  rttOptionsLoading: false,
  products: {} as Record<ProductWithOrderedQty['id'], ProductWithOrderedQty>,
  agreedProductIds: [] as ProductWithOrderedQty['id'][],
  agreedProductsLoading: false,
  productsPagination: {} as Pagination,
  productsLoading: false,
  productIds: [] as string[],
  productsOrderedLoading: {} as Record<ProductWithOrderedQty['id'], boolean>,
  productsOrderedErrors: {} as Record<ProductWithOrderedQty['id'], string>,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.BALANCE_GET_LIST_REQUEST:
    case types.BALANCE_GET_DETAILS_REQUEST:
    case types.BALANCE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.BALANCE_FETCH_RTT_REQUEST:
      return {
        ...state,
        rttOptionsLoading: true,
      };
    case types.BALANCE_AGREED_PRODUCTS_REQUEST:
      return {
        ...state,
        agreedProductsLoading: true,
        agreedProductIds: initialState.agreedProductIds,

        productsOrderedLoading: initialState.productsOrderedLoading,
        productsOrderedErrors: initialState.productsOrderedErrors,
        productsPagination: initialState.productsPagination,
        productsLoading: initialState.productsLoading,
        productIds: initialState.productIds,
      };
    case types.BALANCE_SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        error: null,
        productsLoading: true,
      };
    case types.BALANCE_GET_PRODUCTS_ORDERED_REQUEST:
      return {
        ...state,
        productsOrderedLoading: {
          ...state.productsOrderedLoading,
          ...fromPairs(action.payload.productIds.map(id => [id, true])),
        },
        productsOrderedErrors: omit(
          action.payload.productIds,
          state.productsOrderedErrors,
        ),
      };

    case types.BALANCE_GET_LIST_SUCCESS: {
      const BalanceDictionary = keyBy('id', action.payload);

      return {
        ...state,
        loading: false,
        collection: BalanceDictionary,
        itemIds: keys(BalanceDictionary),
        pagination: action.meta.pagination,
      };
    }
    case types.BALANCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.BALANCE_GET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        agreedProductIds: initialState.agreedProductIds,
        products: initialState.products,
      };
    case types.BALANCE_FETCH_RTT_SUCCESS:
      return {
        ...state,
        rttOptionsLoading: false,
        rttOptions: action.payload,
      };
    case types.BALANCE_AGREED_PRODUCTS_SUCCESS:
      return {
        ...state,
        agreedProductsLoading: false,
        agreedProductIds: action.payload.map(({ id }) => id),
        products: { ...state.products, ...keyBy('id', action.payload) },
      };
    case types.BALANCE_SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsLoading: false,
        products: {
          ...state.products,
          ...keyBy(
            'id',
            action.payload.map(product => ({
              ...product,
              ordered: state.products[product.id]?.ordered,
            })),
          ),
        },
        productIds: action.payload.map(item => item.id),
        productsPagination: action.meta.pagination,
      };
    case types.BALANCE_GET_PRODUCTS_ORDERED_SUCCESS:
      return {
        ...state,
        productsOrderedLoading: omit(
          action.payload.id,
          state.productsOrderedLoading,
        ),
        products: {
          ...state.products,
          [action.payload.id]: action.payload,
        },
      };

    case types.BALANCE_GET_LIST_FAILURE:
    case types.BALANCE_GET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.BALANCE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.BALANCE_FETCH_RTT_FAILURE:
      return {
        ...state,
        rttOptionsLoading: false,
      };
    case types.BALANCE_AGREED_PRODUCTS_FAILURE:
      return {
        ...state,
        agreedProductsLoading: false,
        error: action.payload,
      };
    case types.BALANCE_SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        productsLoading: false,
        error: action.payload,
      };
    case types.BALANCE_GET_PRODUCTS_ORDERED_FAILURE:
      return {
        ...state,
        productsOrderedErrors: {
          ...state.productsOrderedErrors,
          ...action.payload,
        },
        productsOrderedLoading: omit(
          keys(action.payload),
          state.productsOrderedLoading,
        ),
      };

    default:
      return state;
  }
};

const metaType = 'balance';

export default withExportsReducer(metaType, 'itemIds', reducer);
