import { Button, ButtonProps } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends ButtonProps<React.FC<LinkProps>> {
  to: LinkProps['to'];
}

const CreateLink: React.FC<Props> = React.forwardRef(({ to, ...rest }, ref) => {
  const [t] = useTranslation();

  return (
    <Button
      ref={ref}
      color="primary"
      variant="contained"
      startIcon={<AddIcon />}
      component={Link}
      to={to}
      {...rest}
    >
      {rest.children || t('controls.create')}
    </Button>
  );
});

export default CreateLink;
