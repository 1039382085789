import { TFunction } from 'i18next';
import { ColumnConfig } from '@rtt-libs/views';
import { AgreedAssortment } from '../../types';
import DeleteCell from './DeleteCell';

const agreedAssortmentsTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'rttId',
    label: t('distributor.tables.rtt.name', 'Name'),
    options: {
      sort: false,
      format: (_: unknown, entry: AgreedAssortment) =>
        entry.rtt?.name || entry.rttId,
    },
  },
  {
    id: 'rttContactPerson',
    label: t('distributor.tables.rtt.contact', 'Contact'),
    options: {
      sort: false,
      format: (_: unknown, entry: AgreedAssortment) => {
        const { firstName = '', lastName = '' } = entry.rtt?.contact || {};
        return `${lastName} ${firstName}`;
      },
    },
  },
  {
    id: 'rttContactPhone',
    label: t('distributor.tables.rtt.phone', 'Phone'),
    options: {
      format: (_: unknown, entry: AgreedAssortment) => entry.rtt?.contact.phone,
      sort: false,
    },
  },
  {
    id: 'qty',
    label: t('distributor.tables.agreedAssortments.qty', 'Qty'),
  },
  {
    id: 'delete',
    label: '',
    options: {
      sort: false,
      component: DeleteCell,
    },
  },
];

export default agreedAssortmentsTableConfig;
