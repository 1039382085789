import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchPriceTypesRequest } from '../duck/actions';
import { selectPriceTypes } from '../duck/selectors';
import { SearchParams } from '../../api/priceTypes';
import PriceTypesTable from './PriceTypesTable';

/**
 * Container to fetch & filter priceTypes & render them in table.
 */
const PriceTypesContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { data, pagination, loading, error } = useSelector(selectPriceTypes);

  const onTableStateChange = useCallback(
    (options: SearchParams) => {
      dispatch(searchPriceTypesRequest(options));
    },
    [dispatch],
  );
  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      <PriceTypesTable
        onStateChange={onTableStateChange}
        prices={data}
        pagination={pagination}
        loading={loading}
      />
    </>
  );
};

export default PriceTypesContainer;
