import Tree, {
  ItemId,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeItem,
} from '@atlaskit/tree';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { categories } from '@rtt-libs/constants';
import { CategoryItem, CategoryItemId } from '@rtt-libs/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCategoryTree } from '../../../categories/duck/selectors';

type Props = {
  open: boolean;
  onClick(id: CategoryItem): void;
  onClose(): void;
  existingIds: CategoryItemId[];
};

const PADDING_PER_LEVEL = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    listItem: {
      paddingLeft: theme.spacing(0.25),
      width: 'auto',
      display: 'inline-block',
      '&:hover $stealth, &:focus $stealth, &:focus-within $stealth': {
        opacity: 1,
      },
    },
    stealth: {
      opacity: 0,
      transition: '0.2s',
    },
    textButton: {
      fontWeight: 500,
      justifyContent: 'flex-start',
    },
  }),
);

const getIcon = (
  item: TreeItem,
  onExpand: (itemId: ItemId) => void,
  onCollapse: (itemId: ItemId) => void,
  className: string,
) => {
  if (item.children && item.children.length > 0) {
    return item.isExpanded ? (
      <Button
        size="small"
        type="button"
        className={className}
        onClick={e => {
          e.stopPropagation();
          return onCollapse(item.id);
        }}
      >
        <ExpandLessIcon />
      </Button>
    ) : (
      <Button
        size="small"
        type="button"
        className={className}
        onClick={e => {
          e.stopPropagation();
          onExpand(item.id);
        }}
      >
        <ExpandMoreIcon />
      </Button>
    );
  }
  return (
    <span
      className={className}
      style={{ padding: '0 0.75rem', textAlign: 'center' }}
    >
      &bull;
    </span>
  );
};

const CategoriesDialog: React.FC<Props> = ({
  open,
  onClick,
  onClose,
  existingIds,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const treeData = useSelector(selectCategoryTree);
  const [tree, changeTree] = useState<TreeData>(treeData);

  useEffect(() => {
    changeTree(treeData);
  }, [treeData]);

  const renderItem = ({
    item,
    onExpand,
    onCollapse,
    provided,
    depth,
  }: RenderItemParams) => {
    const isAssignable = depth >= categories.CATEGORY_DEPTH_THRESHOLD;

    return (
      <div
        ref={provided.innerRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.draggableProps}
      >
        <ListItem
          dense
          className={classes.listItem}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
        >
          {getIcon(item, onExpand, onCollapse, classes.icon)}

          <Button
            type="button"
            disabled={existingIds.includes(item.id.toString())}
            onClick={() => {
              onClick(item as CategoryItem);
              onClose();
            }}
            size="small"
            classes={{
              text: classes.textButton,
            }}
          >
            {item.data?.title ?? ''}
          </Button>

          {isAssignable && (
            <Typography component="span" color="textSecondary">
              {` [id: ${item.id}]`}
            </Typography>
          )}
        </ListItem>
      </div>
    );
  };

  const onExpand = (itemId: ItemId) => {
    if (tree) {
      changeTree(mutateTree(tree, itemId, { isExpanded: true }));
    }
  };

  const onCollapse = (itemId: ItemId) => {
    if (tree) {
      changeTree(mutateTree(tree, itemId, { isExpanded: false }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('product.category')}</DialogTitle>
      <DialogContent>
        <Tree
          tree={tree}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          offsetPerLevel={PADDING_PER_LEVEL}
          isNestingEnabled
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('controls.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoriesDialog;
