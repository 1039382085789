import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';

type DocumentPreviewThumbnailType = {
  thumbSrc: string;
  thumbSize: string;
};

export type DocumentPreviewType = {
  id: string;
  src: string;
  size: string;
  name: string | null;
} & Partial<DocumentPreviewThumbnailType>;

type Props = {
  imgBlob?: File | DocumentPreviewType;
};

const useStyles = makeStyles({
  root: {
    width: 300,
    height: 200,
    cursor: 'pointer',
  },
});

const Logotype: React.FC<Props> = ({ imgBlob }) => {
  const classes = useStyles();
  const src =
    imgBlob instanceof File
      ? URL.createObjectURL(imgBlob)
      : imgBlob?.thumbSrc || imgBlob?.src;

  const fullSizeSrc =
    imgBlob instanceof File ? URL.createObjectURL(imgBlob) : imgBlob?.src;

  return (
    <Avatar
      className={classes.root}
      src={src}
      variant="rounded"
      onClick={() => src && window.open(fullSizeSrc, '_blank')}
    />
  );
};

export default Logotype;
