import { FieldWithMessages } from '@rtt-libs/views';
import { isEmpty } from 'lodash';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Rtt } from '../../../api/rtt';
import PromptDirtyForm from '../../../common/PromptDirtyForm';
import { fetchAgreedProductsRequest } from '../../duck/actions';
import { BalanceCreateFormValues } from '../../types';
import BalanceProductTable from './BalanceProductTable';
import CreateFormTopRow from './CreateFormTopRow';

const CreateForm = ({
  handleSubmit,
  form: { change },
}: FormRenderProps<BalanceCreateFormValues>) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const fetchAgreedProducts = (id: Rtt['id']) =>
    dispatch(fetchAgreedProductsRequest(id));

  return (
    <form onSubmit={handleSubmit}>
      <CreateFormTopRow />

      <PromptDirtyForm />

      <OnChange name="rttId">
        {(value, previous) => {
          if (value && value !== previous) {
            fetchAgreedProducts(value);
          } else if (!value) {
            change('balances', {});
          }
        }}
      </OnChange>

      <BalanceProductTable />

      <FieldWithMessages
        name="balances"
        component="input"
        type="hidden"
        validate={value => {
          return value && !isEmpty(value)
            ? undefined
            : t('validation.valueMissing');
        }}
      />
    </form>
  );
};

export default CreateForm;
