/* eslint-disable @typescript-eslint/camelcase */
import DayjsUtils from '@date-io/dayjs';
import { Button, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { mapKeys } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RecommendedOrderValue } from '../../../balances/types';
import { RttSelectDialog } from '../../../common';
import {
  getAllManagersOfRttRequest,
  getRttDetailsRequest,
} from '../../../rtt/duck/actions';
import {
  PartialRootState,
  selectRttDetailsById,
} from '../../../rtt/duck/selectors';
import { PAYLOAD_GROUPED_KEY } from '../../constants';
import {
  getClosestDeliveryDayRequest,
  getMinTotalOrderRequest,
} from '../../duck/actions';
import type * as OrderTypes from '../../types';
import { isOrderCreationAccessed } from '../../utils/businessLogic';
import { transformOrderGroupIdKey } from '../commonOrders';
import { CreateOrderLocationStateState } from './CreateOrderLink';
import OrderCreateDetails from './OrderCreateDetails';

const OrderCreateContainer: React.FC<CreateOrderLocationStateState> = ({
  rttId,
  orderValues,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [{ id, values }, changeInitialValues] = useState<{
    id?: number;
    values?: Partial<
      OrderTypes.OrderCreateValues<
        OrderTypes.OrderedProduct | RecommendedOrderValue
      >
    >;
  }>(() => {
    // Do not save the history state between page reloads
    history.replace(history.location.pathname, null);
    return {
      id: rttId,
      values: {
        [PAYLOAD_GROUPED_KEY]:
          orderValues?.[PAYLOAD_GROUPED_KEY] &&
          mapKeys(
            managerId => transformOrderGroupIdKey(managerId),
            orderValues[PAYLOAD_GROUPED_KEY],
          ),
      },
    };
  });

  const changeRttId = (updatedRttId: number) => {
    changeInitialValues({ id: updatedRttId });
  };

  const { data } = useSelector((state: PartialRootState) =>
    selectRttDetailsById(state, id),
  );

  const [t] = useTranslation();
  const [dialogState, changeDialogState] = useState<boolean>(false);

  const handleDialogOpen = () => changeDialogState(true);
  const handleDialogClose = () => changeDialogState(false);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getClosestDeliveryDayRequest(id));
      dispatch(getMinTotalOrderRequest());
      dispatch(getAllManagersOfRttRequest(id));
      handleDialogClose();
    } else {
      handleDialogOpen();
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && !data) {
      dispatch(getRttDetailsRequest(id.toString()));
    }
  }, [dispatch, id, data]);

  const renderChangeRttButton = (
    <Button onClick={handleDialogOpen} variant="outlined">
      {t('distributor.orders.create.chooseRtt')}
    </Button>
  );

  return (
    <>
      {id === undefined && renderChangeRttButton}

      {data &&
        (isOrderCreationAccessed(data.orderQty) ? (
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Paper>
              <OrderCreateDetails
                key={data.id}
                rtt={data}
                initialValues={values}
                changeButton={renderChangeRttButton}
              />
            </Paper>
          </MuiPickersUtilsProvider>
        ) : (
          <Alert severity="warning" action={renderChangeRttButton}>
            <AlertTitle>
              {t('distributor.orders.create.createOrderForRttDeniedTitle')}
            </AlertTitle>
            {t('distributor.orders.create.createOrderForRttDenied')}
          </Alert>
        ))}

      <RttSelectDialog
        open={dialogState}
        onChange={changeRttId}
        id={id}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default OrderCreateContainer;
