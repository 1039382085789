import { Box, Grid } from '@material-ui/core';
import React from 'react';

interface Props {
  checkboxes: React.ReactNode;
  button: React.ReactNode;
  description: React.ReactNode;
  checkAll: React.ReactNode;
}

const ExportLayout: React.FC<Props> = ({
  checkboxes,
  button,
  description,
  checkAll,
  children,
}) => {
  return (
    <>
      <Grid item container alignItems="center">
        <Grid item component="form" xs={12} md={9}>
          <Box pl={3.5}>{checkboxes}</Box>
        </Grid>

        <Box clone textAlign="end">
          <Grid item xs={12} md={3}>
            {button}
          </Grid>
        </Box>
      </Grid>

      <Box clone my={1}>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12}>
            {description}
          </Grid>
          <Grid item xs={3}>
            <Box pl={3.5}>{checkAll}</Box>
          </Grid>

          <Grid item xs={9}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ExportLayout;
