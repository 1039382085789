import {
  delOrderEncKeys,
  Encryption,
  genKeyName,
  getOrderEncKeys,
  getRttIdFromKey,
  setOrderEncKeys,
} from '@rtt-libs/auth';
import { ux } from '@rtt-libs/constants';
import type { EncryptionKey, OrderKey, RttKey } from '@rtt-libs/types';
import { keyBy, mapValues } from 'lodash/fp';
import { Action } from 'redux';
import { all, call, put, select } from 'redux-saga/effects';
import {
  getKeys,
  getKeysByRtt,
  ReturnedProduct,
} from '../../../api/ordersRefactored';
import logger from '../../../utils/logger';
import type * as OrderTypes from '../../types';
import * as actions from '../actions';
import { selectOrdersByIds, selectReturnOrdersByIds } from '../selectors';

export function* encryptPayload(
  orderId: OrderTypes.Order['id'],
  payload?: { products: OrderTypes.OrderedPayloadProduct[] },
  keyWorker: (
    idList: string[],
  ) => Generator<unknown, Record<string, EncryptionKey>> = getKeysWorker,
) {
  const keyDict = yield* keyWorker([orderId]);

  const secret = Date.now();

  const signature = Encryption.encrypt(secret.toString(), keyDict[orderId].key);

  const secrets: OrderTypes.SecretCheck = {
    secret,
    signature,
    keyId: keyDict[orderId].id,
  };

  const payloadEncrypted = Encryption.encrypt(
    JSON.stringify(payload),
    keyDict[orderId].key,
  );

  return { secrets, payloadEncrypted };
}

export function* getKeysByRttWorker(managerRttKeys: string[]) {
  let keys = [] as RttKey[];

  try {
    keys = yield getOrderEncKeys(managerRttKeys);
  } catch (e) {
    keys = yield call(getKeysByRtt, managerRttKeys.map(getRttIdFromKey));
    yield setOrderEncKeys(keys);
  }

  return keyBy(
    // eslint-disable-next-line @typescript-eslint/camelcase
    ({ rtt_id, manager_id }) => genKeyName(rtt_id, manager_id),
    keys,
  );
}

export function* getKeysWorker(orderIdList: string[]) {
  let keys = [] as OrderKey[];

  try {
    keys = yield getOrderEncKeys(orderIdList);
  } catch (e) {
    keys = yield call(getKeys, orderIdList);
    yield setOrderEncKeys(keys);
  }

  return keyBy('order_id', keys);
}

export function* decryptTotals({
  payload: orderIds,
}: ReturnType<typeof actions.decryptOrderTotalRequest>) {
  yield* decryptOrderSlice(
    'total',
    {
      success: actions.decryptOrderTotalSuccess,
      failure: actions.decryptOrderTotalFailure,
      empty: actions.decryptOrderTotalRequest,
    },
    parseToNumber,
    orderIds,
  );
}

export function* decryptOriginTotals({
  payload: orderIds,
}: ReturnType<typeof actions.decryptOrderTotalRequest>) {
  yield* decryptOrderSlice(
    'originTotal',
    {
      success: actions.decryptOrderOriginTotalSuccess,
      failure: actions.decryptOrderOriginTotalFailure,
      empty: actions.decryptOrderOriginTotalRequest,
    },
    parseToNumber,
    orderIds,
  );
}

export function* decryptPayloads({
  payload: orderIds,
}: ReturnType<typeof actions.decryptOrderTotalRequest>) {
  yield* decryptOrderSlice(
    'payload',
    {
      success: actions.decryptOrderPayloadSuccess,
      failure: actions.decryptOrderPayloadFailure,
      empty: actions.decryptOrderPayloadRequest,
    },
    parseToPayloadObject,
    orderIds,
  );
}

type ResultActions<T> = {
  success: (data: Record<OrderTypes.Order['id'], T>) => Action;
  failure: (data: Record<OrderTypes.Order['id'], string>) => Action;
  empty: (key: OrderTypes.Order['id'][]) => Action;
};

function* decryptOrderSlice<T>(
  sliceName: 'total' | 'originTotal' | 'payload',
  resultActions: ResultActions<T>,
  parseDecrypted: (value: string) => T,
  orderIds: OrderTypes.Order['id'][],
) {
  try {
    const [orders, returnOrders]: [
      Record<OrderTypes.Order['id'], OrderTypes.Order>,
      Record<OrderTypes.ReturnOrder['id'], OrderTypes.ReturnOrder>,
    ] = yield all([
      select(selectOrdersByIds(orderIds)),
      select(selectReturnOrdersByIds(orderIds)),
    ]);

    const encryptedFieldCollection = mapValues(sliceName, {
      ...orders,
      ...returnOrders,
    });

    const keyDict = yield* getKeysWorker(orderIds);

    yield* iterateDecryption(
      encryptedFieldCollection,
      keyDict,
      parseDecrypted,
      resultActions,
    );
  } catch (e) {
    yield put(actions.getOrdersFailure(e.message));
  }
}

function* iterateDecryption<T>(
  collection: Record<OrderTypes.Order['id'], string>,
  keys: Record<OrderTypes.Order['id'], OrderKey>,
  parseFn: (value: string) => T,
  resultActions: ResultActions<T>,
) {
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in collection) {
    try {
      const decryptedValue = Encryption.decrypt(collection[key], keys[key].key);

      if (decryptedValue === '' && checkAvailableRetry(key)) {
        yield delOrderEncKeys([key]);
        yield put(resultActions.empty([key]));
      } else {
        const encodedValue = parseFn(decryptedValue);

        yield put(resultActions.success({ [key]: encodedValue }));
        resetRetryCounter(key);
      }
    } catch (e) {
      if (checkAvailableRetry(key)) {
        yield delOrderEncKeys([key]);
        yield put(resultActions.empty([key]));
      } else {
        yield put(resultActions.failure({ [key]: e.message }));
      }
    }
  }
}

function parseToNumber(value: string): number {
  const encodedValue = parseFloat(value);

  if (Number.isNaN(encodedValue)) throw new Error('Decryption error');

  return encodedValue;
}

function parseToPayloadObject(value: string): OrderTypes.DecryptedPayload {
  const { products, shop_info: shopInfo, ...payload } = JSON.parse(value) as {
    products: OrderTypes.OrderedPayloadProduct[];
    shop_info: OrderTypes.ShopInfo;
    [key: string]: unknown;
  };

  logger({ products, shopInfo, payload });

  const payloadProducts = keyBy(
    'id',
    products.map(product => new ReturnedProduct(product)),
  );

  return { ...payload, products: payloadProducts, shopInfo };
}

const retryStore = {} as Record<string, number | undefined>;
const checkAvailableRetry = (id: string): boolean => {
  const retryCounter = retryStore[id] || 0;
  retryStore[id] = retryCounter + 1;
  return ux.RETRY_AMOUNT > retryCounter;
};
const resetRetryCounter = (id: string): void => {
  delete retryStore[id];
};
