import Tree, {
  ItemId,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeItem,
} from '@atlaskit/tree';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { categories } from '@rtt-libs/constants';
import { CategoryItemId } from '@rtt-libs/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCategoriesSel } from '../duck/selectors';

type Props = {
  open: boolean;
  onClick(id: CategoryItemId): void;
  onClose(): void;
};

const PADDING_PER_LEVEL = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    listItem: {
      paddingLeft: theme.spacing(0.25),
      width: 'auto',
      display: 'inline-block',
      '&:hover $stealth, &:focus $stealth, &:focus-within $stealth': {
        opacity: 1,
      },
    },
    stealth: {
      opacity: 0,
      transition: '0.2s',
    },
  }),
);

const getIcon = (
  item: TreeItem,
  onExpand: (itemId: ItemId) => void,
  onCollapse: (itemId: ItemId) => void,
  className: string,
) => {
  if (item.children && item.children.length > 0) {
    return item.isExpanded ? (
      <Button
        size="small"
        type="button"
        className={className}
        onClick={e => {
          e.stopPropagation();
          return onCollapse(item.id);
        }}
      >
        <ExpandLessIcon />
      </Button>
    ) : (
      <Button
        size="small"
        type="button"
        className={className}
        onClick={e => {
          e.stopPropagation();
          onExpand(item.id);
        }}
      >
        <ExpandMoreIcon />
      </Button>
    );
  }
  return (
    <span
      className={className}
      style={{ padding: '0 0.75rem', textAlign: 'center' }}
    >
      &bull;
    </span>
  );
};

const CategoriesDialog: React.FC<Props> = ({ open, onClick, onClose }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [tree, changeTree] = useState<TreeData>();

  const treeData = useSelector(getCategoriesSel);
  useEffect(() => {
    changeTree(treeData);
  }, [treeData]);

  const renderItem = ({
    item,
    onExpand,
    onCollapse,
    provided,
    depth,
  }: RenderItemParams) => {
    const isAssignable = depth >= categories.CATEGORY_DEPTH_THRESHOLD;
    const interactProps = isAssignable
      ? {
          button: true,
          onClick() {
            onClick(item.id);
            onClose();
          },
        }
      : undefined;

    return (
      <div
        ref={provided.innerRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.draggableProps}
      >
        <ListItem
          dense
          className={classes.listItem}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
          {...interactProps}
        >
          {getIcon(item, onExpand, onCollapse, classes.icon)}

          <Typography
            component="span"
            color={isAssignable ? 'textPrimary' : 'textSecondary'}
          >
            {item.data?.title ?? ''}
          </Typography>

          {isAssignable && (
            <Typography component="span" color="textSecondary">
              {` [id: ${item.id}]`}
            </Typography>
          )}
        </ListItem>
      </div>
    );
  };

  const onExpand = (itemId: ItemId) => {
    if (tree) {
      changeTree(mutateTree(tree, itemId, { isExpanded: true }));
    }
  };

  const onCollapse = (itemId: ItemId) => {
    if (tree) {
      changeTree(mutateTree(tree, itemId, { isExpanded: false }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('product.category')}</DialogTitle>
      <DialogContent>
        <Typography variant="caption" paragraph>
          {t('distributor.assortment.selectCategoriesDescription', {
            categoryLevel: categories.CATEGORY_DEPTH_THRESHOLD + 1, // starting from 0 - transform to human format
          })}
        </Typography>

        <Tree
          tree={tree}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          offsetPerLevel={PADDING_PER_LEVEL}
          isNestingEnabled
        />
      </DialogContent>
    </Dialog>
  );
};

export default CategoriesDialog;
