import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TableWithHistory } from '@rtt-libs/views';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from '../../../pages/routes';
import { Balance, BalanceTableParams } from '../../types';
import { BalanceFilterRow } from '../shared';
import balancesExportTableConfig from './tableConfig';
import { ExportStatusContainer } from '../../../exports';

type Props = {
  balances: Balance[];
  loading: boolean;
  onStateChange: (state: Partial<BalanceTableParams>) => void;
  pagination: Pagination;
};

const BalanceList: React.FC<Props> = ({
  balances,
  loading,
  onStateChange,
  pagination,
}) => {
  const [t] = useTranslation();
  const history = useHistory();

  const columnsConfig = useMemo(() => balancesExportTableConfig(t), [t]);

  const onRowClick = useCallback(
    (id: string) => {
      history.push(`${routes.balances}/${id}`);
    },
    [history],
  );

  const { perPage = 10, currentPage = 1 } = pagination;

  const initialParams = useMemo(
    () => ({
      page: currentPage,
      perPage,
    }),
    [perPage, currentPage],
  );

  return (
    <TableWithHistory
      filterRow={({ onChange, displayOptions }) => (
        <BalanceFilterRow
          onChange={onChange}
          initialFilterValue={displayOptions}
          endElement={<ExportStatusContainer type="balance" />}
        />
      )}
      loading={loading}
      entries={balances}
      columnsConfig={columnsConfig}
      onStateChange={onStateChange}
      onRowClick={onRowClick}
      perPageOptions={table.PAGINATION_OPTIONS}
      initial={initialParams}
      totalEntriesCount={pagination.total}
    />
  );
};

export default BalanceList;
