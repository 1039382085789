import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  AssortmentStatistics,
  getUploadAssortmentStatistics,
} from '../../api/assortment';
import * as actions from './actions';
import { UPDATE_ASSORTMENT_STATISTICS_DELAY } from './const';
import * as types from './types';

function* worker() {
  try {
    const data: AssortmentStatistics = yield call(
      getUploadAssortmentStatistics,
    );

    yield put(actions.statisticsAssortmentSuccess(data));

    if (data.status === 'in_progress') {
      yield delay(UPDATE_ASSORTMENT_STATISTICS_DELAY);

      yield put(actions.statisticsAssortmentRequest());
    }

    if (data.status === 'error') {
      yield put(
        actions.statisticsAssortmentFailure(
          data.message || 'Get statistics error',
        ),
      );
    }
  } catch (e) {
    yield put(actions.statisticsAssortmentFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.STATISTICS_ASSORTMENT_REQUEST, worker);
}
