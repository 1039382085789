/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox as MuiCheckbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  makeStyles,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Field, FieldProps, FieldRenderProps, FormSpy } from 'react-final-form';

export interface RadioData {
  label: string;
  value: any;
}

export interface RadiosProps {
  label?: string;
  name: string;
  required?: boolean;
  data: RadioData[];
  formLabelProps?: FormLabelProps<'legend'>;
  formControlLabelProps?: FormControlLabelProps;
  fieldProps?: Partial<FieldProps<any, FieldRenderProps<any>>>;
  formControlProps?: FormControlProps;
  radioGroupProps?: RadioGroupProps;
  formHelperTextProps?: FormHelperTextProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      '&$label': {
        borderColor: theme.palette.primary.main,
      },
    },
    label: {
      border: 'solid thin',
      borderColor: 'transparent',
      borderRadius: 4,
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0),
      width: theme.spacing(20),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
    },
  }),
);

export default function Radios(props: RadiosProps) {
  const {
    required,
    label,
    data,
    name,
    formLabelProps,
    fieldProps,
    formControlProps,
    formControlLabelProps,
    radioGroupProps,
    formHelperTextProps,
  } = props;

  const classes = useStyles();

  const [error, setError] = useState<string | null>(null);

  const renderRadioField = (
    <Field
      render={fieldRenderProps => {
        const { meta } = fieldRenderProps;

        const showError =
          ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
          meta.touched;

        setError(showError ? meta.error || meta.submitError : null);

        return <RadioWrapper {...fieldRenderProps} />;
      }}
      type="radio"
      name={name}
      {...fieldProps}
    />
  );

  return (
    <FormControl
      required={required}
      error={!!error}
      margin="normal"
      {...formControlProps}
    >
      {label !== undefined ? (
        <FormLabel component="legend" {...formLabelProps}>
          {label}
        </FormLabel>
      ) : null}
      <FormSpy>
        {({ values }) => (
          <RadioGroup {...radioGroupProps}>
            {data.map((item: RadioData) => (
              <FormControlLabel
                className={clsx(classes.label, {
                  [classes.checked]: values[name] === item.value,
                })}
                key={item.label}
                label={item.label}
                value={item.value}
                control={renderRadioField}
                {...formControlLabelProps}
              />
            ))}
          </RadioGroup>
        )}
      </FormSpy>

      {error ? (
        <FormHelperText {...formHelperTextProps}>{error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

function RadioWrapper(
  props: FieldRenderProps<any, HTMLInputElement> & Partial<RadioProps>,
) {
  const {
    input: { name, checked, onChange, ...restInput },
    meta,
    ...rest
  } = props;

  return (
    <MuiCheckbox
      name={name}
      checked={checked}
      onChange={onChange}
      inputProps={restInput as Record<string, any>}
      {...rest}
    />
  );
}
