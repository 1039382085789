import { DEFAULT_LNG, I18N_LANG_KEY } from '@rtt-libs/constants';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';

const selectLocale = () => {
  const savedLocale = localStorage.getItem(I18N_LANG_KEY);
  const regex = /^((?:ru)|(?:uk))/gi;

  return savedLocale?.match(regex)?.[0];
};

const initDayjsLocales = () => dayjs.locale(selectLocale() || DEFAULT_LNG);

export default initDayjsLocales;
