import { Box, Grid, Link as LinkStyled, Typography } from '@material-ui/core';
import type { OrderStatus } from '@rtt-libs/types';
import { Status } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { RttInfo } from '../../../ordersRefactored/types';
import routes from '../../../pages/routes';

interface Props {
  status: OrderStatus;
  title: string;
  consumer: RttInfo;
  createdAt: string;
  parentOrderId?: string;
  titleBadge?: React.ReactNode;
  totalElement?: React.ReactNode;
}

const OrderSummary: React.FC<Props> = ({
  createdAt,
  status,
  title,
  consumer,
  parentOrderId,
  titleBadge,
  totalElement,
}) => {
  const [t] = useTranslation();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} md={5} container spacing={1}>
        <Grid item>
          <Status value={status} />
        </Grid>
        <Grid item xs={12}>
          <Box clone display="flex" alignItems="center">
            <Typography variant="h5">
              {title}
              {titleBadge}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('formattedDateTime', { date: createdAt })}</Typography>
        </Grid>
        {parentOrderId && (
          <Grid item xs={12}>
            <Typography variant="caption">
              {`${t('distributor.returnOrders.details.orderLink')} `}
              <LinkStyled
                to={`${routes.orders}/${parentOrderId}`}
                component={Link}
              >
                {parentOrderId}
              </LinkStyled>
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={7} md={4}>
        <Typography variant="h6">
          <LinkStyled to={`${routes.rtt}/${consumer.id}`} component={Link}>
            {consumer.name}
          </LinkStyled>
        </Typography>
        <Typography>
          {consumer.contact?.firstName} {consumer.contact?.lastName}
        </Typography>
        <Typography>{consumer.contact?.phone}</Typography>
        <Typography>{consumer.contact?.email}</Typography>
      </Grid>

      <Grid item xs={5} md={3}>
        <Typography variant="h5" align="right">
          {totalElement}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
