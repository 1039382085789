import { keyBy, omit, remove, without } from 'lodash/fp';
import { Reducer } from 'redux';
import { Actions } from './actions';
import { Area } from './model';
import * as types from './types';

const initialState = {
  loading: false,
  collection: {} as Record<Area['id'], Area>,
  order: [] as Area['id'][],
  error: null as string | null,
  deletionIds: [] as Area['id'][],
  deletionError: null as string | null,
  deletionDone: false,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_TERRITORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_TERRITORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: keyBy('id', action.payload),
        order: action.payload.map(area => area.id),
      };
    case types.CREATE_TERRITORIES_RESPONSE:
      return {
        ...state,
        collection: {
          ...omit(action.meta.changed.deleted, state.collection),
          ...Object.entries(action.meta.changed.edited).reduce(
            (acc, [id, paths]) => ({
              ...acc,
              [id]: { ...state.collection[parseInt(id, 10)], paths },
            }),
            {},
          ),
          [action.payload.id]: action.payload,
        },
        order: remove(
          id => action.meta.changed.deleted.includes(id),
          state.order,
        ).concat(action.payload.id),
      };
    case types.EDIT_TERRITORIES_RESPONSE:
      return {
        ...state,
        collection: {
          ...omit(action.meta.changed.deleted, state.collection),
          ...Object.entries(action.meta.changed.edited).reduce(
            (acc, [id, paths]) => ({
              ...acc,
              [id]: { ...state.collection[parseInt(id, 10)], paths },
            }),
            {},
          ),
          [action.payload.id]: action.payload,
        },
        order: remove(
          id => action.meta.changed.deleted.includes(id),
          state.order,
        ),
      };
    case types.GET_TERRITORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Area delete state
    case types.DELETE_TERRITORIES_REQUEST:
      return {
        ...state,
        deletionError: initialState.deletionError,
        deletionIds: state.deletionIds.concat(action.payload),
        deletionDone: initialState.deletionDone,
      };
    case types.DELETE_TERRITORIES_SUCCESS:
      return {
        ...state,
        deletionIds: without([action.payload], state.deletionIds),
        deletionDone: true,
      };
    case types.DELETE_TERRITORIES_FINISH:
      return {
        ...state,
        order: without([action.payload], state.order),
        collection: omit(action.payload, state.collection),
        deletionDone: false,
      };
    case types.DELETE_TERRITORIES_FAILURE:
      return {
        ...state,
        deletionError: action.payload,
        deletionIds: without([action.meta.id], state.deletionIds),
      };

    default:
      return state;
  }
};

export default reducer;
