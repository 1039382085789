export const PRODUCTS_AS_RTT_SEARCH_REQUEST = '@rtt[D]/PRODUCTS_AS_RTT_SEARCH_REQUEST' as const;
export const PRODUCTS_AS_RTT_SEARCH_SUCCESS = '@rtt[D]/PRODUCTS_AS_RTT_SEARCH_SUCCESS' as const;
export const PRODUCTS_AS_RTT_SEARCH_FAILURE = '@rtt[D]/PRODUCTS_AS_RTT_SEARCH_FAILURE' as const;

export const PRODUCTS_PRICELESS_SEARCH_REQUEST = '@rtt[D]/PRODUCTS_PRICELESS_SEARCH_REQUEST' as const;
export const PRODUCTS_PRICELESS_SEARCH_SUCCESS = '@rtt[D]/PRODUCTS_PRICELESS_SEARCH_SUCCESS' as const;
export const PRODUCTS_PRICELESS_SEARCH_FAILURE = '@rtt[D]/PRODUCTS_PRICELESS_SEARCH_FAILURE' as const;

export const PRODUCTS_RETURN_SEARCH_REQUEST = '@rtt[D]/PRODUCTS_RETURN_SEARCH_REQUEST' as const;
export const PRODUCTS_RETURN_SEARCH_SUCCESS = '@rtt[D]/PRODUCTS_RETURN_SEARCH_SUCCESS' as const;
export const PRODUCTS_RETURN_SEARCH_FAILURE = '@rtt[D]/PRODUCTS_RETURN_SEARCH_FAILURE' as const;

export const PRODUCTS_BALANCE_SEARCH_REQUEST = '@rtt[D]/PRODUCTS_BALANCE_SEARCH_REQUEST' as const;
export const PRODUCTS_BALANCE_SEARCH_SUCCESS = '@rtt[D]/PRODUCTS_BALANCE_SEARCH_SUCCESS' as const;
export const PRODUCTS_BALANCE_SEARCH_FAILURE = '@rtt[D]/PRODUCTS_BALANCE_SEARCH_FAILURE' as const;

export const PRODUCTS_CLEAR_STATE = '@rtt[D]/PRODUCTS_CLEAR_STATE' as const;
