import { extractThumbnail } from '@rtt-libs/api-services';
import { FORM_ERROR } from 'final-form';
import type { Product } from './api/assortment';

export function financialString(price?: number, fallbackValue = ''): string {
  if (price === undefined) return fallbackValue;

  return price.toFixed(2);
}

export const getThumbnailSrc = (image: Product['image']) => {
  const fullImageSrc = image?.src;
  return (
    (image?.related && extractThumbnail(image?.related).thumbSrc) ||
    fullImageSrc
  );
};

/**
 * Concatenate first symbols of words into a upper-cased string
 * @param words - list of words, e.g. [firstName, lastName]
 */
export function composeAvatarPlaceholder(...words: string[]) {
  return words
    .map(word => word[0])
    .join('')
    .slice(0, 2);
}

/**
 * Make user name from first name & last name
 * @param user user object slice
 */
export function composeUserName({ firstName = '', lastName = '' }) {
  return `${firstName} ${lastName}`;
}

export function collectTargetErrors(
  errors: Record<string, string | undefined>,
) {
  const errorsForFields = {
    ...errors,
    [FORM_ERROR]: undefined,
  };

  return Object.values(errorsForFields).join('\n');
}
