import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from './CreateDialog';

type Props = {
  container: React.ComponentType<{ onClose(): void }>;
};

const CreateButton = ({ container }: Props) => {
  const [t] = useTranslation();
  const [dialogState, changeDialogState] = useState<boolean>(false);

  const handleDialogOpen = () => changeDialogState(true);
  const handleDialogClose = () => changeDialogState(false);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        onClick={handleDialogOpen}
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
      >
        {t('controls.add')}
      </Button>

      <CreateDialog
        open={dialogState}
        onClose={handleDialogClose}
        FormContainer={container}
      />
    </Box>
  );
};

export default CreateButton;
