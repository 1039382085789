import { Box } from '@material-ui/core';
import { Editor } from '@rtt-libs/types';
import React from 'react';
import EditorCard from './EditorCard';

export interface EditorListProps {
  creator?: Editor;
  lastEditor?: Editor;
  manager?: Omit<Editor, 'role' | 'date'>;
}

const EditorList = ({ creator, lastEditor, manager }: EditorListProps) => {
  return (
    <Box>
      {manager && <EditorCard title="manager" {...manager} />}

      {creator && <EditorCard title="creator" {...creator} />}

      {lastEditor && <EditorCard title="lastEditor" {...lastEditor} />}
    </Box>
  );
};

export default EditorList;
