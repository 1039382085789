import { isNil } from 'lodash';
import { financialString } from '../../utils';
import {
  OrderedQty,
  ProductOnlyWithOrderedQty,
  ProductWithOrderedQty,
} from '../types';

export const calculateSoldOut = (
  input: number,
  { saleMeasurement = 'unit', ordered, weight = 1 }: ProductOnlyWithOrderedQty,
) => {
  if (isNil(input) || !ordered || input < 0) {
    return null;
  }

  const sold =
    saleMeasurement === 'unit'
      ? ordered.qty - input
      : ordered.orderWeight - (weight ?? 1) * input;

  return sold >= 0 ? financialString(sold) : financialString(0);
};

export const calcOrderedValue = (
  qty: number,
  { saleMeasurement, weight }: ProductWithOrderedQty,
): OrderedQty['ordered'] => {
  // weight set as 0 for "bike" as default value, and as 1 for "meat"
  const definedWeight = saleMeasurement === 'unit' ? weight ?? 0 : weight || 1;

  const orderWeight = qty * definedWeight;

  return {
    qty,
    orderWeight,
  };
};
