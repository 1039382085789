import React from 'react';

type Props<T> = {
  data: T[];
  onClick?(id: string | number): void;
  entryIdKey?: string;
  component?: React.ElementType;
  children?(props: { entry: T }): React.ReactElement;
  [prop: string]: unknown;
};

export type EntryItemListType<T = Record<string, unknown>> = React.FC<Props<T>>;

const EntryItemList: EntryItemListType = ({
  data,
  onClick,
  entryIdKey = 'id',
  component: Component = 'div',
  children,
  ...rest
}) => {
  return (
    <>
      {data.map(entry => {
        const key = String(entry[entryIdKey]);
        const handleClick = () => {
          if (typeof onClick === 'function') onClick(key);
        };

        if (typeof children === 'function') {
          return children({
            entry,
          });
        }

        return (
          <Component key={key} onClick={handleClick} entry={entry} {...rest} />
        );
      })}
    </>
  );
};

export default EntryItemList;
