import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { CheckboxAdapter, FieldWithMessages } from '@rtt-libs/views';
import { isEmpty, omitBy } from 'lodash/fp';
import React, { useState } from 'react';
import { Form, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ThumbnailCell } from '../../../../common';
import { ReturnProductInfo } from '../../../../productsAddition/types';
import {
  getOrdersCollectionSel,
  selectOrderPayloadProducts,
} from '../../../duck/selectors';
import type { Order, OrderedProduct } from '../../../types';

interface Props {
  products: ReturnProductInfo[];
  onClose(): void;
  onSubmit(ordersWithProducts: Record<string, string[]>): void;
  checkedOrders?: Record<Order['id'], OrderedProduct['id'][]>;
}

const ChooseOrderOfProduct: React.FC<Props> = ({
  products,
  checkedOrders,
  onClose,
  onSubmit,
}) => {
  const [t] = useTranslation();

  const onProductsSubmit = (values: Record<string, string[]>) => {
    return onSubmit(omitBy(isEmpty, values));
  };

  return (
    <Dialog
      open={!!products.length}
      onClose={onClose}
      keepMounted
      aria-labelledby="modal-title"
      fullWidth
      maxWidth="sm"
    >
      <Form
        onSubmit={onProductsSubmit}
        subscription={{}}
        initialValues={checkedOrders}
      >
        {({ handleSubmit }) => (
          <Box display="flex" flexDirection="column" overflow="hidden" clone>
            <form onSubmit={handleSubmit}>
              <DialogTitle id="modal-title">
                {t('distributor.returnOrders.create.addProductsByOrder')}
              </DialogTitle>
              <DialogContent>
                {products.map(product => (
                  <ProductOrdersList key={product.id} product={product} />
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>{t('controls.cancel')}</Button>
                <Button variant="contained" color="primary" type="submit">
                  {t('controls.add')}
                </Button>
              </DialogActions>
            </form>
          </Box>
        )}
      </Form>
    </Dialog>
  );
};

export default ChooseOrderOfProduct;

type ListProps = {
  product: ReturnProductInfo;
};

const ProductOrdersList: React.FC<ListProps> = ({ product }) => {
  const ordersDict = useSelector(getOrdersCollectionSel);
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List key={product.id}>
      <ListItem button onClick={handleClick} selected>
        <ListItemIcon>
          <ThumbnailCell value={product.image} id={product.id} />
        </ListItemIcon>
        <ListItemText primary={`${product.sku} - ${product.title}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {product.orders?.map(orderId => (
            <OrderList
              key={orderId}
              order={ordersDict[orderId]}
              productId={product.id}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};

type OrderListProps = {
  order: Order;
  productId: string;
};

const OrderList: React.FC<OrderListProps> = ({ order, productId }) => {
  const [t] = useTranslation();
  const { data: products } = useSelector(selectOrderPayloadProducts(order.id));

  const product = products?.[productId];

  const renderPrimary = (
    <Grid container justify="space-between">
      <Typography variant="h6">{order.id}</Typography>
      <Typography color="textSecondary">
        {t('formattedDateTime', { date: order.createdAt })}
      </Typography>
    </Grid>
  );

  const renderSecondary = !product ? null : (
    <Grid container justify="space-between">
      <Typography>
        {t('product.ordered.default')}:{' '}
        {product.saleMeasurement === 'unit'
          ? `${product.qty} ${t('product.values.unit')}`
          : `${product.orderWeight} ${t('product.values.weight')}`}
      </Typography>
      <Typography>
        {t('product.price.default')}: {product.price}
        {'\u00A0'}
        {t('common.mainCurrency')}
      </Typography>
      <Typography>
        {t('distributor.tables.orders.totalShort')}: {product.total}
        {'\u00A0'}
        {t('common.mainCurrency')}
      </Typography>
    </Grid>
  );

  const labelId = `order-label-${order.id}_${productId}`;

  const {
    meta: { initial },
  } = useField<string[]>(order.id, { subscription: { initial: true } });

  const disabled = initial?.includes(productId);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <ListItem dense button disabled={disabled}>
        <ListItemIcon>
          <FieldWithMessages
            label
            type="checkbox"
            component={CheckboxAdapter}
            name={order.id}
            value={productId}
            edge="start"
            tabIndex={-1}
            inputProps={{ 'aria-labelledby': labelId }}
            disabled={disabled}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={renderPrimary}
          secondary={renderSecondary}
          disableTypography
        />
      </ListItem>
    </label>
  );
};
