import type { OrderStatusValue } from '@rtt-libs/types';
import { call, put } from 'redux-saga/effects';
import {
  getAvailableStatuses,
  setOrderStatus,
  setReturnOrderStatus,
} from '../../../api/ordersRefactored';
import { Order, ReturnOrder } from '../../types';
import * as actions from '../actions';

export function* getStatusesWorker({
  payload,
}: ReturnType<typeof actions.getAvailableStatusesRequest>) {
  try {
    const statuses: OrderStatusValue[] = yield call(
      getAvailableStatuses,
      payload,
    );

    yield put(actions.getAvailableStatusesSuccess(statuses));
  } catch (e) {
    yield put(actions.getAvailableStatusesFailure(e.message));
  }
}

export function* setStatusWorker({
  payload,
}: ReturnType<typeof actions.setOrderStatusRequest>) {
  try {
    const order: Order = yield call(setOrderStatus, payload);

    // decrypt if order needs to be decrypted - most of cases isn't
    // const orderDecrypted: Order = yield* decryptOrder(order);

    yield put(actions.setOrderStatusSuccess(order));

    // refetch statuses for the order
    yield put(actions.getAvailableStatusesRequest(payload.orderId));
  } catch (e) {
    yield put(actions.setOrderStatusFailure(e.message));
  }
}

export function* setReturnStatusWorker({
  payload,
}: ReturnType<typeof actions.setReturnOrderStatusRequest>) {
  try {
    const order: ReturnOrder = yield call(setReturnOrderStatus, payload);

    yield put(actions.setReturnOrderStatusSuccess(order));

    // refetch statuses for the order
    yield put(actions.getAvailableStatusesRequest(payload.returnId));
  } catch (e) {
    yield put(actions.setReturnOrderStatusFailure(e.message));
  }
}
