import { Pagination } from '@rtt-libs/types';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createSale,
  deleteSale,
  DiscountBrand,
  DiscountProduct,
  editSale,
  getAllSales,
  getSaleDetails,
  getSales,
  Sale,
} from '../../api/sales';
import * as actions from './actions';
import * as types from './types';
import { selectAllCategories } from '../../categories/duck/selectors';

function* getAllSalesWorker() {
  try {
    const sales: Sale[] = yield call(getAllSales);

    yield all([put(actions.getAllSalesSuccess(sales))]);
  } catch (e) {
    yield put(actions.getAllSalesFailure(e.message));
  }
}

function* searchSalesWorker({
  payload,
}: ReturnType<typeof actions.searchSalesRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: Sale[]; meta: { pagination: Pagination } } = yield call(
      getSales,
      payload,
    );

    yield all([put(actions.searchSalesSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.searchSalesFailure(e.message));
  }
}

function* getSaleDetailsWorker({
  payload,
}: ReturnType<typeof actions.getSaleDetailsRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: Sale;
      meta: {
        products: Record<DiscountProduct['id'], DiscountProduct>;
        brands: Record<DiscountBrand['id'], DiscountBrand>;
      };
    } = yield call(getSaleDetails, payload);

    yield all([put(actions.getSaleDetailsSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.getSaleDetailsFailure(e.message));
  }
}

function* createSalesWorker({
  payload,
}: ReturnType<typeof actions.createSaleRequest>) {
  try {
    const sale: Sale = yield call(createSale, payload);

    yield all([
      put(actions.createSaleSuccess(sale)),
      put(actions.createSaleResponse(sale && { id: sale.id })),
    ]);
  } catch (errors) {
    yield put(actions.createSaleResponse(errors));
  }
}

function* editSaleWorker({
  payload,
}: ReturnType<typeof actions.editSaleRequest>) {
  try {
    const { allCategories } = yield select(selectAllCategories);

    const filterCategoriesIds =
      payload.values.categoryDiscounts &&
      Object.keys(payload.values.categoryDiscounts).filter(
        categoryId => !allCategories[categoryId].is_deleted && categoryId,
      );

    const filterCategoriesData = filterCategoriesIds?.reduce(
      (start, item) => ({
        ...start,
        [item]:
          payload.values.categoryDiscounts &&
          payload?.values?.categoryDiscounts[+item],
      }),
      {},
    );

    const sale: Sale = yield call(editSale, payload.id, {
      ...payload.values,
      categoryDiscounts: filterCategoriesData,
    });

    yield all([
      put(actions.editSaleSuccess(sale)),
      put(actions.editSaleResponse()),
    ]);
  } catch (errors) {
    yield put(actions.editSaleResponse(errors));
  }
}

function* deleteSaleWorker({
  payload,
}: ReturnType<typeof actions.deleteSaleRequest>) {
  try {
    yield call(deleteSale, payload);

    yield all([put(actions.deleteSaleSuccess(payload))]);
  } catch (errors) {
    yield put(actions.deleteSaleFailure(errors));
  }
}

export default function* watcher() {
  yield takeLatest(types.SALE_GET_ALL_REQUEST, getAllSalesWorker);
  yield takeLatest(types.SALE_DETAILS_REQUEST, getSaleDetailsWorker);
  yield takeLatest(types.SALE_SEARCH_REQUEST, searchSalesWorker);
  yield takeLatest(types.SALE_CREATE_REQUEST, createSalesWorker);
  yield takeLatest(types.SALE_EDIT_REQUEST, editSaleWorker);
  yield takeLatest(types.SALE_DELETE_REQUEST, deleteSaleWorker);
}
