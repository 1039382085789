import { chatsRootSaga, massMailSaga } from '@rtt-libs/chats';
import { saga as notifications } from '@rtt-libs/state-containers';
import { all } from 'redux-saga/effects';
import { saga as administrators } from '../administrators/duck';
import { saga as agreedAssortments } from '../agreedAssortments/duck';
import { saga as assortment } from '../assortment/duck';
import { saga as balances } from '../balances/duck';
import { saga as balanceSettings } from '../balanceSettings/duck';
import { saga as brands } from '../brands/duck';
import { saga as categories } from '../categories';
import { saga as discounts } from '../discounts';
import { saga as employees } from '../employees/duck';
import { saga as exports } from '../exports/duck';
import { saga as orders } from '../ordersRefactored/duck';
import { saga as priceTypes } from '../priceTypes/duck';
import { saga as products } from '../productsAddition';
import { saga as profile } from '../profile';
import { saga as register } from '../registrationFinish';
import { saga as rtt } from '../rtt/duck';
import { saga as sales } from '../sales/duck';
import { saga as sample } from '../sampleDomain';
import { saga as settings } from '../settings';
import { saga as supervisorManagers } from '../supervisorManagers';
import { saga as supervisors } from '../supervisors';
import { saga as territories } from '../territories/duck';
import { saga as uploadAssortment } from '../uploadAssortment/duck';

export default function* rootSaga() {
  yield all([
    administrators(),
    agreedAssortments(),
    assortment(),
    balances(),
    balanceSettings(),
    brands(),
    categories(),
    discounts(),
    employees(),
    exports(),
    notifications(),
    orders(),
    priceTypes(),
    products(),
    register(),
    rtt(),
    sales(),
    sample(),
    settings(),
    supervisorManagers(),
    supervisors(),
    territories(),
    uploadAssortment(),
    profile(),
    chatsRootSaga(),
    massMailSaga(),
  ]);
}
