import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { branchName } from './const';
import type { BranchState } from './reducer';
import { BalanceSettings } from '../types';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectBalanceSettingValues: Selector<
  PartialRootState,
  BalanceSettings | undefined
> = get([branchName, 'values']);

export const selectLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const selectError: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const selectBalanceSettingsData: Selector<
  PartialRootState,
  BranchState
> = get(branchName);
