import { CategoriesItemDictionary } from '@rtt-libs/types';
import * as TYPES from './types';

export const getCategoriesRequest = () => ({
  type: TYPES.CATEGORIES_REQUEST,
});

export const getAvailableCategoriesRequest = () => ({
  type: TYPES.CATEGORIES_AVAILABLE_REQUEST,
});

export const getCategoriesSuccess = (payload: CategoriesItemDictionary) => ({
  type: TYPES.CATEGORIES_SUCCESS,
  payload,
});

export const getAllCategoriesRequest = () => ({
  type: TYPES.ALL_CATEGORIES_REQUEST,
});

export const getAllCategoriesSuccess = (payload: CategoriesItemDictionary) => ({
  type: TYPES.ALL_CATEGORIES_SUCCESS,
  payload,
});

export const getAllCategoriesFailure = (error: string) => ({
  type: TYPES.ALL_CATEGORIES_FAILURE,
  payload: error,
});

export const getAvailableCategoriesSuccess = (
  payload: CategoriesItemDictionary,
) => ({
  type: TYPES.CATEGORIES_AVAILABLE_SUCCESS,
  payload,
});

export const getCategoriesFailure = (error: string) => ({
  type: TYPES.CATEGORIES_FAILURE,
  payload: error,
});

export const setCategoriesSearchSuccess = (value: string) => ({
  type: TYPES.SET_CATEGORIES_SEARCH_SUCCESS,
  payload: value,
});

export type Actions = ReturnType<
  | typeof getAllCategoriesRequest
  | typeof getAllCategoriesSuccess
  | typeof getAllCategoriesFailure
  | typeof getCategoriesRequest
  | typeof getAvailableCategoriesRequest
  | typeof getCategoriesSuccess
  | typeof getAvailableCategoriesSuccess
  | typeof getCategoriesFailure
  | typeof setCategoriesSearchSuccess
>;
