import { Pagination } from '@rtt-libs/types';
import at from 'lodash/fp/at';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RttSelectOption } from '../../agreedAssortments/types';
import { Balance, ProductWithOrderedQty } from '../types';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectBalances: Selector<
  PartialRootState,
  Record<Balance['id'], Balance>
> = get([branchName, 'collection']);

export const selectBalanceIds: Selector<
  PartialRootState,
  Balance['id'][]
> = get([branchName, 'itemIds']);

export const selectLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const selectError: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const selectPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectBalancesData: Selector<
  PartialRootState,
  {
    data: Balance[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectBalances,
    selectBalanceIds,
    selectPagination,
    selectLoading,
    selectError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: at(ids, collection),
    pagination,
    loading,
    error,
  }),
);

const selectBalanceById = createSelector(
  [
    selectBalances,
    selectLoading,
    selectError,
    (_: PartialRootState, id: string) => id,
  ],
  (collection, loading, error, id) => ({
    data: get(id, collection),
    loading,
    error,
  }),
);

export const selectBalance = (id: string) => (state: PartialRootState) =>
  selectBalanceById(state, id);

export const selectRttOptions = createSelector<
  PartialRootState,
  RttSelectOption[],
  boolean,
  { data: RttSelectOption[]; loading: boolean }
>(
  [get([branchName, 'rttOptions']), get([branchName, 'rttOptionsLoading'])],
  (data, loading) => ({
    data,
    loading,
  }),
);

export const selectProductCollection: Selector<
  PartialRootState,
  Record<ProductWithOrderedQty['id'], ProductWithOrderedQty>
> = get([branchName, 'products']);

export const selectAgreedProductIds: Selector<
  PartialRootState,
  ProductWithOrderedQty['id'][]
> = get([branchName, 'agreedProductIds']);

export const selectAgreedProductsLoading: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'agreedProductsLoading']);

export const agreedProductsData = createSelector<
  PartialRootState,
  Record<ProductWithOrderedQty['id'], ProductWithOrderedQty>,
  ProductWithOrderedQty['id'][],
  boolean,
  string | null,
  {
    ids: ProductWithOrderedQty['id'][];
    data: ProductWithOrderedQty[];
    loading: boolean;
    error: string | null;
  }
>(
  [
    selectProductCollection,
    selectAgreedProductIds,
    selectAgreedProductsLoading,
    selectError,
  ],
  (collection, ids, loading, error) => ({
    data: at(ids, collection),
    ids,
    loading,
    error,
  }),
);

export const selectAddProducts = createSelector<
  PartialRootState,
  Record<string, ProductWithOrderedQty>,
  string[],
  Pagination,
  boolean,
  string | null,
  {
    data: ProductWithOrderedQty[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
>(
  [
    get([branchName, 'products']),
    get([branchName, 'productIds']),
    get([branchName, 'productsPagination']),
    get([branchName, 'productsLoading']),
    get([branchName, 'error']),
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);

export const selectProductListOrderedState = createSelector<
  PartialRootState,
  Record<string, boolean>,
  Record<string, string>,
  {
    loadings: Record<string, boolean>;
    errors: Record<string, string>;
  }
>(
  [
    get([branchName, 'productsOrderedLoading']),
    get([branchName, 'productsOrderedErrors']),
  ],
  (loadings, errors) => ({
    loadings,
    errors,
  }),
);

const selectProductOrderedStateSel = createSelector<
  PartialRootState,
  string,
  Record<string, boolean>,
  Record<string, string>,
  string,
  {
    loading: boolean;
    error: string | null;
  }
>(
  [
    get([branchName, 'productsOrderedLoading']),
    get([branchName, 'productsOrderedErrors']),
    (_: PartialRootState, productId: string) => productId,
  ],
  (loadings, errors, id) => ({
    loading: loadings[id] ?? false,
    error: errors[id] ?? null,
  }),
);

export const selectProductOrderedState = (id: string) => (
  state: PartialRootState,
) => selectProductOrderedStateSel(state, id);
