import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { AssortmentStatistics } from '../../api/assortment';
import { branchName } from './const';
import { BranchState } from './reducer';

export type PartialRootState = Record<typeof branchName, BranchState>;

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const statisticsDataAssortmentSel: Selector<
  PartialRootState,
  AssortmentStatistics | null
> = get([branchName, 'statistics']);

export const statisticsAssortmentSel: Selector<
  PartialRootState,
  Pick<BranchState, 'statistics' | 'loading' | 'error'>
> = createSelector(
  [statisticsDataAssortmentSel, getLoadingStateSel, getErrorSel],
  (statistics, loading, error) => ({ statistics, loading, error }),
);
