import { TopLoadingProgress } from '@rtt-libs/views';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import { Product, ProductUpdate } from '../../api/assortment';
import routes from '../../pages/routes';
import { promiseListener } from '../../setup';
import { collectTargetErrors } from '../../utils';
import { types } from '../duck';
import { getOptionsProductRequest, getProductRequest } from '../duck/actions';
import { getProductDataSel } from '../duck/selectors';
import ProductDetails from './ProductDetails';

type Props = {
  id: Product['id'];
};

const ProductDetailsContainer: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductRequest(id));
    dispatch(getOptionsProductRequest());
  }, [dispatch, id]);
  const { product, loading } = useSelector(getProductDataSel);

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <>
      <TopLoadingProgress loading={loading} />

      {product ? (
        <MakeAsyncFunction
          listener={promiseListener}
          start={types.POST_PRODUCT_REQUEST}
          resolve={types.POST_PRODUCT_SUCCESS}
          reject={types.POST_PRODUCT_FAILURE}
        >
          {onSubmit => (
            <ProductDetails
              product={product}
              onSubmit={values => {
                return onSubmit({ id: product.id, values }).then(
                  () => {
                    enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                      variant: 'success',
                    });

                    if (window.history.length <= 2) {
                      history.push(`/${routes.products}`);
                      return undefined;
                    }
                    history.goBack();

                    return undefined;
                  },

                  (
                    errors: Partial<
                      Record<keyof ProductUpdate, string | undefined> & {
                        [FORM_ERROR]: string;
                      }
                    >,
                  ) => {
                    enqueueSnackbar(
                      collectTargetErrors(errors) ||
                        errors[FORM_ERROR] ||
                        t('common.snackbars.saveFailed'),
                      { variant: 'error' },
                    );

                    return errors;
                  },
                );
              }}
            />
          )}
        </MakeAsyncFunction>
      ) : null}
    </>
  );
};

export default ProductDetailsContainer;
