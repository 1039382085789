import keyBy from 'lodash/fp/keyBy';
import type { Reducer } from 'redux';
import type { Brand } from '../../types';
import type { BrandActions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  collection: {} as Record<Brand['id'], Brand>,
  error: null as string | null,
  idList: [] as Brand['id'][],
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, BrandActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.BRANDS_GET_REQUEST:
    case types.BRANDS_GET_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.BRANDS_GET_SUCCESS:
    case types.BRANDS_GET_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        idList: action.payload.map(brand => brand.id),
      };
    case types.BRANDS_CREATE_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        idList: [...state.idList, action.payload.id],
      };
    case types.BRANDS_EDIT_SUCCESS:
    case types.BRANDS_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
      };
    case types.BRANDS_GET_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.BRANDS_CLEAN_UP:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
