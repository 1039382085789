import { TableWithHistory } from '@rtt-libs/views';
import { isEqual, isNil, negate, pickBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssortmentGetParams, Product } from '../../api/assortment';
import routes from '../../pages/routes';
import { getAssortmentRequest } from '../duck/actions';
import { getAssortmentDataSel } from '../duck/selectors';
import FilterRow from './FilterRow';
import ProductDisplay from './ProductDisplay';

type Props = {
  initialFilters?: {
    isActive?: boolean;
    isInvalid?: boolean;
  };
  statusGroup?: string;
};

const Assortment: React.FC<Props> = ({ initialFilters, statusGroup }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, changeState] = useState<
    Record<string, string | number | boolean | undefined> | undefined
  >(initialFilters);

  const onTableStateChange = useCallback(
    (options: Partial<AssortmentGetParams>) => {
      dispatch(getAssortmentRequest(options));
    },
    [dispatch],
  );

  const { products, pagination, loading } = useSelector(getAssortmentDataSel);

  const onCardClick = (id: Product['id']) => {
    history.push(`${routes.products}/${id}`);
  };
  const onStateChange = (updatedState: Record<string, string | number>) => {
    changeState(prevState => {
      const newState = pickBy({ ...prevState, ...updatedState }, negate(isNil));
      return isEqual(newState, prevState) ? prevState : newState;
    });
  };
  return (
    <TableWithHistory
      key={statusGroup}
      filterRow={({ onChange, displayOptions }) => (
        <FilterRow onChange={onChange} initialFilterValue={displayOptions} />
      )}
      state={state}
      initial={state}
      onStateChange={onTableStateChange}
      customTable={({ page = 1 }) => (
        <ProductDisplay
          loading={loading}
          entries={products}
          onCardClick={onCardClick}
          onStateChange={onStateChange}
          pagination={pagination}
          initialPage={+page}
        />
      )}
    />
  );
};

export default Assortment;
