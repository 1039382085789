import { Box, Grid, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useAuth } from '@rtt-libs/auth';
import { LoadingButton } from '@rtt-libs/views';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EnhancedProduct } from '../../../api/orders';
import { RttInfo } from '../../../api/rtt';
import { AddProducts, SkeletonButton } from '../../../common';
import { selectProductsCollection } from '../../../productsAddition/duck/selectors';
import { PERMISSIONS } from '../../../rules';
import { AddProductContainer } from '../DetailsCommon';

type Props = {
  rtt: RttInfo;
};

const EditFormTopRow: React.FC<Props> & { Loading: React.FC } = ({ rtt }) => {
  const [t] = useTranslation();
  const { isPermittedTo } = useAuth();

  const renderRttInfo = (
    <RttInfoSection
      name={rtt.name}
      phone={rtt.contact.phone}
      contactName={`${rtt.contact.firstName} ${rtt.contact.lastName}`}
    />
  );

  const renderActions = isPermittedTo(
    PERMISSIONS.NAV_AGREED_ASSORTMENT_EDIT,
  ) ? (
    <>
      <AddProductsButton rttId={rtt.id} />
      <SubmitButton>{t('controls.save')}</SubmitButton>
    </>
  ) : null;

  return (
    <EditFormTopRowLayout
      rttInfo={renderRttInfo}
      actionSection={renderActions}
    />
  );
};

type EditFormTopRowLayoutProps = {
  rttInfo: React.ReactNode;
  actionSection: React.ReactNode;
};

const EditFormTopRowLayout: React.FC<EditFormTopRowLayoutProps> = ({
  rttInfo,
  actionSection,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      {rttInfo}
    </Grid>
    <Box display="flex" justifyContent="flex-end" alignItems="center" clone>
      <Grid item xs={12} md={6}>
        {actionSection}
      </Grid>
    </Box>
  </Grid>
);

type RttInfoSectionProps = {
  name: React.ReactNode;
  phone: React.ReactNode;
  contactName: React.ReactNode;
};

const RttInfoSection: React.FC<RttInfoSectionProps> = ({
  name,
  phone,
  contactName,
}) => (
  <>
    <Typography variant="h6">{name}</Typography>
    <Typography>
      <Link href={`tel:${phone}`}>{phone}</Link>
    </Typography>
    <Typography>{contactName}</Typography>
  </>
);

type AddProductsButtonProps = {
  rttId: RttInfo['id'];
};

const AddProductsButton: React.FC<AddProductsButtonProps> = ({ rttId }) => (
  <FormSpy subscription={{ values: true }}>
    {({ values: { productIdList }, form: { change } }) => {
      const handleAddProducts = (products: Record<string, EnhancedProduct>) =>
        change('productIdList', Object.keys(products));

      return (
        <AddProducts
          initialCheckedIds={productIdList}
          existingProductIds={[]}
          onSubmit={handleAddProducts}
          productsSelector={
            selectProductsCollection as (
              state: unknown,
            ) => Record<string, EnhancedProduct>
          }
        >
          <AddProductContainer rttId={rttId} />
        </AddProducts>
      );
    }}
  </FormSpy>
);

const SubmitButton: React.FC = ({ children }) => (
  <FormSpy
    subscription={{
      hasValidationErrors: true,
      pristine: true,
      submitting: true,
    }}
  >
    {({ hasValidationErrors, pristine, submitting }) => (
      <Box ml={1} width={160} clone>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={submitting}
          disabled={hasValidationErrors || pristine || submitting}
        >
          {children}
        </LoadingButton>
      </Box>
    )}
  </FormSpy>
);

const Loading = () => {
  const renderRttInfo = (
    <RttInfoSection
      name={<Skeleton width="75%" />}
      phone={<Skeleton width="20%" />}
      contactName={<Skeleton width="30%" />}
    />
  );

  const renderActions = (
    <>
      <SkeletonButton mr={1} />
      <SkeletonButton />
    </>
  );

  return (
    <EditFormTopRowLayout
      rttInfo={renderRttInfo}
      actionSection={renderActions}
    />
  );
};

EditFormTopRow.Loading = Loading;

export default EditFormTopRow;
