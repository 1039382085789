import noop from 'lodash/noop';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as TYPES from './types';

function* worker({ payload }: ReturnType<typeof actions.actionCreatorRequest>) {
  try {
    const { data } = yield call(noop, payload);

    yield all([put(actions.actionCreatorSuccess(data))]);
  } catch (e) {
    yield put(actions.actionCreatorFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TYPES.REQUEST, worker);
}
