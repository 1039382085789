import {
  Box,
  CircularProgress,
  createStyles,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { FieldWithMessages, TextFieldAdapter } from '@rtt-libs/views';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../../common/useSnackbarError';
import { cleanUpBrands, getBrandsOptionsRequest } from '../../duck/actions';
import {
  selectBrandsError,
  selectBrandsLoading,
  selectBrandsOptions,
} from '../../duck/selectors';

type Props = {
  /** Apply filter by isActive field of brand */
  filterActive?: boolean;
  /** Do not apply filter for only brands which you can operate. Lead to error for user-roles without permissions  */
  showAll?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deactivated: {
      textDecorationLine: 'line-through',
    },
  }),
);

export const BrandSelectField = ({ filterActive, showAll }: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const brandOptions = useSelector(selectBrandsOptions);

  const loading = useSelector(selectBrandsLoading);
  const error = useSelector(selectBrandsError);

  useSnackbarError(error);

  useEffect(() => {
    dispatch(getBrandsOptionsRequest({ showAll, isActive: filterActive }));

    return () => {
      dispatch(cleanUpBrands());
    };
  }, [dispatch, filterActive, showAll]);

  const loadingIndicator = loading
    ? {
        InputProps: {
          endAdornment: (
            <Box clone marginRight={2}>
              <InputAdornment position="end">
                <CircularProgress size="1.25rem" />
              </InputAdornment>
            </Box>
          ),
        },
      }
    : undefined;

  return (
    <FieldWithMessages
      component={TextFieldAdapter}
      name="brandId"
      margin="dense"
      type="number"
      fullWidth
      label={t('distributor.brands.pageTitle')}
      select
      {...loadingIndicator}
    >
      {brandOptions.map(({ value, label, meta }) => (
        <MenuItem
          key={value}
          value={value}
          className={!meta.isActive ? classes.deactivated : undefined}
          aria-label={!meta.isActive ? 'disabled' : undefined}
        >
          {label}
        </MenuItem>
      ))}
    </FieldWithMessages>
  );
};
