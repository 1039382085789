import {
  Box,
  Checkbox,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import type { DisplayProduct } from '../../types';
import { useClearProducts } from '../useClearProducts';
import { PRODUCT_IDS_FIELD_NAME } from './const';
import ProductAddListForm from './ProductAddList';
import ToggleProductAddModal from './ToggleProductAddModal';

type Props = {
  onSubmit(productIds: string[]): void;
  disabled?: boolean;
  checkedIds?: string[];
};

const ProductSelectorByRtt: React.FC<Props> = ({
  onSubmit,
  disabled,
  checkedIds,
  children,
}) => {
  useClearProducts();

  return (
    <ToggleProductAddModal
      disabled={disabled}
      renderModalContent={closeModal => (
        <ProductAddListForm
          onSubmit={onSubmit}
          closeModal={closeModal}
          checked={checkedIds}
        >
          <DialogContent>{children}</DialogContent>
        </ProductAddListForm>
      )}
    />
  );
};

export default ProductSelectorByRtt;

interface SecondaryProps {
  product: DisplayProduct;
}

const ListItemSecondary: React.FC<SecondaryProps> = ({ product }) => {
  const [t] = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" component="span">
      <Typography component="span">{product.sku}</Typography>
      {product.price && product.saleMeasurement && (
        <Typography color="textPrimary" component="span">
          {product.price}
          {'\u00A0'}
          {t('common.mainCurrency')} /{' '}
          {t(`product.values.${product.saleMeasurement}`)}
        </Typography>
      )}
    </Box>
  );
};

const ProductFieldWithCheckbox = ({
  product,
  disabled,
  keyName = 'id',
}: {
  product: DisplayProduct;
  disabled?: boolean;
  keyName?: 'id' | 'hash';
}) => {
  const field = useField<string>(PRODUCT_IDS_FIELD_NAME, {
    type: 'checkbox',
    value: product[keyName],
  });

  const labelId = `checkbox-list-label-${product.sku}`;

  return (
    <ListItem
      role={undefined}
      dense
      disabled={disabled}
      component="label"
      button
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={field.input.checked}
          tabIndex={-1}
          onChange={field.input.onChange}
          inputProps={{ 'aria-labelledby': labelId }}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={<Typography>{product.title}</Typography>}
        secondary={<ListItemSecondary product={product} />}
      />
    </ListItem>
  );
};

type ProductCheckboxListProps = {
  products: DisplayProduct[];
  disabledIds?: string[];
  keyName?: 'id' | 'hash';
};

export const ProductCheckboxList = ({
  products: data,
  disabledIds,
  keyName = 'id',
}: ProductCheckboxListProps) => (
  <List>
    {data.map(product => (
      <ProductFieldWithCheckbox
        key={product[keyName]}
        product={product}
        disabled={disabledIds?.includes(product[keyName] || '')}
        keyName={keyName}
      />
    ))}
  </List>
);
