import Resumable from 'resumablejs';
import { useRef, useEffect } from 'react';

type SimpleCallback = (resumable: Resumable) => void;

const useResumable = (
  options: Resumable.ConfigurationHash,
  assign: {
    browse?: React.MutableRefObject<HTMLElement | null>;
    drop?: React.MutableRefObject<HTMLElement | null>;
  },
  eventHandlers?: {
    onFileAdded?: (
      event: DragEvent,
      file: Resumable.ResumableFile,
      resumable: Resumable,
    ) => void;
    onFileRetry?: (file: Resumable.ResumableFile, resumable: Resumable) => void;
    onError?: (
      message: string,
      file: Resumable.ResumableFile,
      resumable: Resumable,
    ) => void;
    onProgress?: SimpleCallback;
    onComplete?: SimpleCallback;
    onPause?: SimpleCallback;
    onCancel?: SimpleCallback;
  },
) => {
  const resumableInstanceRef = useRef<Resumable | null>(null);

  // create and store instance
  useEffect(() => {
    resumableInstanceRef.current = new Resumable(options);
  }, [options]);

  // assign browse & dropZone elements
  useEffect(() => {
    if (resumableInstanceRef.current) {
      if (assign.browse && assign.browse.current) {
        resumableInstanceRef.current.assignBrowse(assign.browse.current, false);
      }

      if (assign.drop && assign.drop.current) {
        resumableInstanceRef.current.assignDrop(assign.drop.current);
      }
    }
  }, [options, assign]);

  // add listeners
  useEffect(() => {
    if (resumableInstanceRef.current) {
      const resumableInstance = resumableInstanceRef.current;

      resumableInstance.on('fileAdded', (file, event) => {
        if (eventHandlers && typeof eventHandlers.onFileAdded === 'function') {
          eventHandlers.onFileAdded(event, file, resumableInstance);
          resumableInstance.upload();
        } else {
          resumableInstance.upload();
        }
      });

      resumableInstance.on('fileRetry', file => {
        if (eventHandlers && typeof eventHandlers.onFileRetry === 'function') {
          eventHandlers.onFileRetry(file, resumableInstance);
        }
      });

      resumableInstance.on('progress', () => {
        if (eventHandlers && typeof eventHandlers.onProgress === 'function') {
          eventHandlers.onProgress(resumableInstance);
        }
      });

      resumableInstance.on('complete', () => {
        if (eventHandlers && typeof eventHandlers.onComplete === 'function') {
          eventHandlers.onComplete(resumableInstance);
        }
      });

      resumableInstance.on('error', (message, file) => {
        if (eventHandlers && typeof eventHandlers.onError === 'function') {
          eventHandlers.onError(message, file, resumableInstance);
        }
      });

      resumableInstance.on('pause', () => {
        if (eventHandlers && typeof eventHandlers.onPause === 'function') {
          eventHandlers.onPause(resumableInstance);
        }
      });

      resumableInstance.on('cancel', () => {
        if (eventHandlers && typeof eventHandlers.onCancel === 'function') {
          eventHandlers.onCancel(resumableInstance);
        }
      });
    }
  }, [options, eventHandlers]);

  return {
    resumable: resumableInstanceRef.current,
  };
};

export default useResumable;
