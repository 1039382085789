import { ColumnConfig, Status } from '@rtt-libs/views';
import type { TFunction } from 'i18next';
import type { RttInfo } from '../../types';
import TotalCell from '../commonOrders/OrdersTable/TotalCell';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const ordersTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'status',
    label: t('distributor.tables.orders.status', 'Status'),
    options: {
      component: Status,
    },
  },
  {
    id: 'id',
    label: t('distributor.tables.orders.id', 'ID'),
    options: {
      sort: false,
    },
  },
  {
    id: 'createdAt',
    label: t('distributor.tables.orders.createdAt', 'Created date'),
    options: {
      format(value: string) {
        const date = new Date(value);
        return t('formattedDateTime', { date });
      },
      sort: false,
    },
  },
  {
    id: 'rtt',
    label: t('distributor.tables.orders.customer', 'Customer'),
    options: {
      format: (rtt: RttInfo) => rtt.name,
      sort: false,
    },
  },
  {
    id: 'total',
    label: t('distributor.tables.orders.total', 'Total'),
    options: {
      component: TotalCell,
      sort: false,
    },
  },
];

export default ordersTableConfig;
