import { Chip } from '@material-ui/core';
import React from 'react';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const EmployeeTerritories: React.FC<Props> = ({ id, value }) => {
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((area: string, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Chip key={i} size="small" label={area} variant="outlined" />
        ))}
      </>
    );
  }
  return null;
};

export default EmployeeTerritories;
