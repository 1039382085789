import * as types from './types';
import { AssortmentStatistics } from '../../api/assortment';

export const statisticsAssortmentRequest = () => ({
  type: types.STATISTICS_ASSORTMENT_REQUEST,
});

export const statisticsAssortmentSuccess = (
  payload: AssortmentStatistics | null,
) => ({
  type: types.STATISTICS_ASSORTMENT_SUCCESS,
  payload,
});

export const statisticsAssortmentFailure = (error: string) => ({
  type: types.STATISTICS_ASSORTMENT_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof statisticsAssortmentRequest>
  | ReturnType<typeof statisticsAssortmentSuccess>
  | ReturnType<typeof statisticsAssortmentFailure>;
