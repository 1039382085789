import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { EditStaffForm, useStaffEditData } from '../../common/staff';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import type { EditSupervisorValues, Supervisor } from '../types';
import ManagerListSelectField from './ManagerListSelectField';

type Props = {
  onClose(): void;
};

const EditSupervisorContainer: React.FC<Props> = ({ onClose }) => {
  const supervisor = useStaffEditData() as Supervisor;

  const initialValues = useMemo(
    () => ({
      ...supervisor,
      managerList: supervisor?.managerList?.map(({ id }) => id) || [],
    }),
    [supervisor],
  );

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.SUPERVISORS_EDIT_REQUEST}
      resolve={types.SUPERVISORS_EDIT_SUCCESS}
      reject={types.SUPERVISORS_EDIT_FAILURE}
    >
      {onSubmit => (
        <Form
          initialValues={initialValues}
          onSubmit={async (values: EditSupervisorValues) => {
            try {
              await onSubmit(values);
              onClose();

              enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                variant: 'success',
              });

              return undefined;
            } catch (errors) {
              enqueueSnackbar(t('common.snackbars.saveFailed'), {
                variant: 'error',
              });

              return errors;
            }
          }}
          render={props => (
            <EditStaffForm onClose={onClose} {...props}>
              <ManagerListSelectField supervisorId={supervisor.id} />
            </EditStaffForm>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default EditSupervisorContainer;
