import { Chip, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExportResultType } from '../../../types';

const DOCUMENT_TYPES_CONFIG = {
  balance: '#253AA6',
  selling: '#2173E2',
  return: '#8C35DC',
  mixed: '#767676',
};

const useStyles = makeStyles(
  createStyles({
    root: {
      backgroundColor: (props: { color: string }) => props.color,
      color: '#FFFFFF',
      borderRadius: 4,
      width: '100%',
      fontWeight: 500,
      maxWidth: '200px',
    },
  }),
);

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const DocumentTypeCell: React.FC<Props> = ({ value }) => {
  const [t] = useTranslation();
  const documentType = (value as ExportResultType) || 'mixed';

  const classes = useStyles({ color: DOCUMENT_TYPES_CONFIG[documentType] });

  return value ? (
    <Chip
      className={classes.root}
      label={t(`distributor.export.documentTypes.${documentType}`, ' --- ')}
    />
  ) : null;
};

export default DocumentTypeCell;
