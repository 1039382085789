import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import { getProfile, editProfile, sendChangePassword } from '../../api/profile';

function* getProfileWorker() {
  try {
    const data = yield call(getProfile);

    yield all([put(actions.getProfileSuccess(data))]);
  } catch (e) {
    yield put(actions.getProfileFailure(e.message));
  }
}

function* editProfileWorker({
  payload,
}: ReturnType<typeof actions.editProfileRequest>) {
  try {
    const data = yield call(editProfile, payload);

    yield all([
      put(actions.editProfileSuccess(data)),
      put(actions.editProfileResponse()),
    ]);
  } catch (e) {
    yield put(actions.editProfileResponse(e));
  }
}

function* changePasswordWorker({
  payload,
}: ReturnType<typeof actions.changePasswordRequest>) {
  try {
    yield sendChangePassword(payload);
    yield all([
      put(actions.changePasswordSuccess()),
      put(actions.changePasswordResponse()),
    ]);
  } catch (e) {
    yield put(actions.changePasswordResponse(e));
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_PROFILE_REQUEST, getProfileWorker);
  yield takeLatest(types.EDIT_PROFILE_REQUEST, editProfileWorker);
  yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePasswordWorker);
}
