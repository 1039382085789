import React, { useCallback } from 'react';
import NumberInput from './NumberInput';

type Props = {
  id: string | number | symbol;
  value?: unknown;
  fieldNamePrefix?: string;
  onRemoveClick?: (productId: string) => void;
  disabled?: boolean;
  ignoreWeight?: boolean;
  maxValue?: number;
};

const CountCell: React.FC<Props> = ({
  id,
  value,
  fieldNamePrefix = '',
  onRemoveClick,
  disabled,
  ignoreWeight,
  maxValue,
}) => {
  const handleRemoveItem = useCallback(() => {
    return onRemoveClick?.(id.toString());
  }, [id, onRemoveClick]);

  return (
    <NumberInput
      id={id}
      value={value}
      onRemoveItem={onRemoveClick && handleRemoveItem}
      fieldNamePrefix={fieldNamePrefix}
      disabled={disabled}
      ignoreWeight={ignoreWeight}
      maxValue={maxValue}
    />
  );
};

export default CountCell;
