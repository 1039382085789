import { call, put, takeLatest } from 'redux-saga/effects';
import { searchSupervisorManagers } from '../../api/supervisorManagers';
import type { SupervisorManager } from '../types';
import * as actions from './actions';
import * as types from './types';

function* searchWorker() {
  try {
    const data: SupervisorManager[] = yield call(searchSupervisorManagers);

    yield put(actions.searchSupervisorManagersSuccess(data));
  } catch (e) {
    yield put(actions.searchSupervisorManagersFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.SUPERVISOR_MANAGERS_SEARCH_REQUEST, searchWorker);
}
