import { Rtt, SearchParams } from '../../api/rtt';
import { RttAreas } from '../../api/rttAreas';
import { ChangeRttBalanceErrors } from '../types';
import * as types from './types';
import { BrandManagerConnection } from '../../api/mappers';
import { BrandManagerValues } from '../../types';

export const getRttDetailsRequest = (id: string) => ({
  type: types.GET_RTT_DETAILS_REQUEST,
  payload: id,
});

export const getRttDetailsSuccess = (payload: Rtt) => ({
  type: types.GET_RTT_DETAILS_SUCCESS,
  payload,
});

export const getRttDetailsFailure = (error: string) => ({
  type: types.GET_RTT_DETAILS_FAILURE,
  payload: error,
});

export const setRttSearchSuccess = (value: string) => ({
  type: types.SET_RTT_SEARCH_SUCCESS,
  payload: value,
});

export const getRttRequest = (params?: SearchParams) => ({
  type: types.GET_RTT_REQUEST,
  payload: params,
});

export const getRttSuccess = (payload: Rtt[]) => ({
  type: types.GET_RTT_SUCCESS,
  payload,
});

export const getRttFailure = (error: string) => ({
  type: types.GET_RTT_FAILURE,
  payload: error,
});

export const getAreasRequest = () => ({
  type: types.GET_AREAS_REQUEST,
});

export const getAreasSuccess = (payload: RttAreas[]) => ({
  type: types.GET_AREAS_SUCCESS,
  payload,
});

export const getAreasFailure = (error: string) => ({
  type: types.GET_AREAS_FAILURE,
  payload: error,
});

export const changeRttBalanceRequest = (
  payload: Pick<Rtt, 'id' | 'isBalanceEnabled'>,
) => ({
  type: types.RTT_CHANGE_BALANCE_STATE_REQUEST,
  payload,
});

export const changeRttBalanceSuccess = (
  payload: Pick<Rtt, 'id' | 'isBalanceEnabled'>,
) => ({
  type: types.RTT_CHANGE_BALANCE_STATE_SUCCESS,
  payload,
});

export const changeRttBalanceFailure = (errors: ChangeRttBalanceErrors) => ({
  type: types.RTT_CHANGE_BALANCE_STATE_FAILURE,
  payload: errors,
});

export const getRttManagerListRequest = (payload: Rtt['id']) => ({
  type: types.RTT_MANAGER_LIST_GET_REQUEST,
  payload,
});

export const getRttManagerListSuccess = (
  rttId: Rtt['id'],
  managerList: BrandManagerConnection[],
) => ({
  type: types.RTT_MANAGER_LIST_GET_SUCCESS,
  payload: { rttId, managerList },
});

export const getRttManagerListFailure = (error: string) => ({
  type: types.RTT_MANAGER_LIST_GET_FAILURE,
  payload: error,
});

export const getAllManagersOfRttRequest = (payload: Rtt['id']) => ({
  type: types.RTT_MANAGER_LIST_ALL_GET_REQUEST,
  payload,
});

export const getAllManagersOfRttSuccess = (
  rttId: Rtt['id'],
  managerList: BrandManagerConnection[],
) => ({
  type: types.RTT_MANAGER_LIST_ALL_GET_SUCCESS,
  payload: { rttId, managerList },
});

export const getAllManagersOfRttFailure = (error: string) => ({
  type: types.RTT_MANAGER_LIST_ALL_GET_FAILURE,
  payload: error,
});

export const changeRttManagerListRequest = (
  rttId: Rtt['id'],
  managerList: BrandManagerValues['managerList'],
) => ({
  type: types.RTT_MANAGER_LIST_CHANGE_REQUEST,
  payload: { rttId, managerList },
});

export const changeRttManagerListSuccess = (
  rttId: Rtt['id'],
  managerList: BrandManagerConnection[],
) => ({
  type: types.RTT_MANAGER_LIST_CHANGE_SUCCESS,
  payload: { rttId, managerList },
});

export const changeRttManagerListFailure = (error: string) => ({
  type: types.RTT_MANAGER_LIST_CHANGE_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof getRttSuccess
  | typeof getRttRequest
  | typeof getRttFailure
  | typeof getAreasSuccess
  | typeof getAreasRequest
  | typeof getAreasFailure
  | typeof setRttSearchSuccess
  | typeof getRttDetailsRequest
  | typeof getRttDetailsSuccess
  | typeof getRttDetailsFailure
  | typeof changeRttBalanceSuccess
  | typeof changeRttBalanceRequest
  | typeof changeRttBalanceFailure
  | typeof getRttManagerListSuccess
  | typeof getRttManagerListRequest
  | typeof getRttManagerListFailure
  | typeof getAllManagersOfRttSuccess
  | typeof getAllManagersOfRttRequest
  | typeof getAllManagersOfRttFailure
  | typeof changeRttManagerListSuccess
  | typeof changeRttManagerListRequest
  | typeof changeRttManagerListFailure
>;
