import * as TYPES from './types';

export const actionCreatorRequest = (payload: unknown, meta = {}) => ({
  type: TYPES.REQUEST,
  payload,
  meta,
});

export const actionCreatorSuccess = (payload: unknown) => ({
  type: TYPES.SUCCESS,
  payload,
});

export const actionCreatorFailure = (error: unknown) => ({
  type: TYPES.FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof actionCreatorRequest>
  | ReturnType<typeof actionCreatorSuccess>
  | ReturnType<typeof actionCreatorFailure>;
