import type { OrderStatusValue } from '@rtt-libs/types';
import { FilterMenu, FilterOption, FilterSection } from '@rtt-libs/views';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ORDER_STATUSES_TO_PERFORM } from '../../../../environment';
import routes from '../../../../pages/routes';
import type * as OrderTypes from '../../../types';
import FilterRowOnlyDates from './FilterRowOnlyDates';

type Props = {
  onChange(params: OrderTypes.OrderSearchParams): void;
  statusOptions?: FilterOption<OrderStatusValue | ''>[];
  endElement: React.ReactNode;
  baseRoute: string;
  initialFilterValues: { [key: string]: string };
};

const performingStatuses = ORDER_STATUSES_TO_PERFORM.join(
  ',',
) as OrderStatusValue;

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = ({
  onChange,
  statusOptions,
  endElement,
  baseRoute,
  initialFilterValues,
}) => {
  const [t] = useTranslation();

  const { status = '', statusGroup } = useParams();
  const history = useHistory();

  const onStatusChange = useCallback(
    (value: OrderTypes.OrderSearchParams) => {
      const baseUrl = `${baseRoute}/${statusGroup}`;

      onChange(
        statusGroup !== 'to-perform'
          ? value
          : {
              ...value,
              status: value.status || performingStatuses,
            },
      );

      history.replace(!value.status ? baseUrl : `${baseUrl}/${value.status}`);
    },
    [history, onChange, baseRoute, statusGroup],
  );

  const filters: FilterSection<OrderStatusValue | ''>[] = [
    {
      name: 'status',
      title: t('distributor.tables.orders.filters.status'),
      options: [
        {
          value: '',
          label: t('common.resetOption'),
        },
        ...statusOptions,
      ],
    },
  ];

  const croppedFilters: FilterSection[] = [
    {
      ...filters[0],
      options: filters[0].options.filter(option =>
        ['', ...ORDER_STATUSES_TO_PERFORM].includes(option.value),
      ),
    },
  ];

  const statusFilerRender = (
    <FilterMenu
      filters={
        statusGroup === routes.orderFilters.toPerform ? croppedFilters : filters
      }
      onChange={onStatusChange}
      initialValues={{ status }}
    />
  );

  return (
    <FilterRowOnlyDates
      endElement={endElement}
      initialFilterValues={initialFilterValues}
      onChange={onChange}
      startElement={statusFilerRender}
    />
  );
};

export default FilterRow;
