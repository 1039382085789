import { Box, CardHeader, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectChatOpponent } from '../../duck/selectors';
import { ChannelIdentity, OpponentType } from '../../types';
import { ChatAvatar } from '../ChatList/ChatList';

interface Props extends Partial<Pick<ChannelIdentity, 'channelId'>> {
  opponentType: OpponentType;
}

const ChatHeader = ({ channelId, opponentType }: Props) => {
  const [t] = useTranslation();

  const theme = useTheme();

  const opponentInfo = useSelector(
    selectChatOpponent({ channelId, opponentType }),
  );

  return (
    <Box clone borderBottom={`solid 1px ${theme.palette.divider}`}>
      <CardHeader
        avatar={
          opponentInfo && 'name' in opponentInfo ? (
            <ChatAvatar opponentInfo={opponentInfo} />
          ) : undefined
        }
        title={
          (opponentInfo &&
            ('name' in opponentInfo
              ? opponentInfo?.name
              : `${opponentInfo.firstName} ${opponentInfo.lastName}`)) ||
          t('chats.selectChannel')
        }
      />
    </Box>
  );
};

export default ChatHeader;
