/* eslint-disable @typescript-eslint/camelcase */
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

export type FinalFormResponse = {
  id: number;
  name: string;
  address: string;
  is_verified: boolean;
  contact?: {
    email: string;
    first_name: string;
    last_name: string;
  };
};

const finishReg = (data: FormData) =>
  api.post<FinalFormResponse>(ENDPOINTS.finishReg, data);

export default finishReg;
