import { Box, Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SuspenseIndicator } from '../../common';
import { getAvailableCategoriesRequest } from '../duck/actions';
import { selectCategoriesTreeData } from '../duck/selectors';
import CategoriesTree, { ItemContentWithIdAndDepth } from './CategoriesTree';
import FilterRow from './FilterRow';

const CategoriesContainer: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { data: treeData, loading, error } = useSelector(
    selectCategoriesTreeData,
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    dispatch(getAvailableCategoriesRequest());
  }, [dispatch]);

  //  "root" item will be added after first initialization and stay there forever
  const treeItemsCount = Object.keys(treeData.items).length;

  return (
    <Box clone mb={2}>
      <Grid container justify="center">
        <FilterRow />

        {loading && treeItemsCount === 0 ? (
          <SuspenseIndicator />
        ) : (
          <Grid item xs={12}>
            {Object.keys(treeData.items).length <= 1 && !loading ? (
              <Alert role="alert" severity="info">
                {t('common.searchIsEmpty')}
              </Alert>
            ) : (
              <Box clone padding={2}>
                <Paper>
                  <CategoriesTree
                    treeData={treeData}
                    ItemContent={ItemContentWithIdAndDepth}
                  />
                </Paper>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CategoriesContainer;
