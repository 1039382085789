import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { PRODUCTS_DEFAULTS, ux } from '@rtt-libs/constants';
import { TablePagination } from '@rtt-libs/views';
import { debounce, pickBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProductBalanceSearchParams } from '../../../../api/balanceProducts';
import { ProductAddList } from '../../../../common';
import { searchProductsRequest } from '../../../duck/actions';
import { selectAddProducts } from '../../../duck/selectors';

interface Props {
  rttId?: number;
}

const PRODUCTS_COUNT = 10;

const AddProductContainer: React.FC<Props> = ({ rttId }) => {
  const dispatch = useDispatch();
  const [displayOptions, changeOptions] = useState<ProductBalanceSearchParams>({
    perPage: PRODUCTS_COUNT,
  });

  useEffect(() => {
    if (rttId) {
      dispatch(
        searchProductsRequest(rttId, {
          ...displayOptions,
        }),
      );
    }
  }, [dispatch, rttId, displayOptions]);

  const onChange = useCallback(
    (params: Partial<ProductBalanceSearchParams>) => {
      changeOptions(prevParams =>
        pickBy({ ...prevParams, page: 1, ...params }, v => v !== undefined),
      );
    },
    [],
  );

  const { data, pagination, loading, error } = useSelector(selectAddProducts);

  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  const onHistoryFilterChange = (
    e: React.ChangeEvent<{}>,
    checked: boolean,
  ) => {
    debounceChange({ withHistoryFilter: checked });
  };

  const localeLabels = {
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  if (error) return <FormHelperText error>{error}</FormHelperText>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <TextField
          name="search"
          label={t('distributor.rtt.search', 'Search')}
          onChange={onSearchChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          display="flex"
          justifyContent="flex-end"
          height="100%"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                name="withHistoryFilter"
                defaultChecked={PRODUCTS_DEFAULTS.BALANCES_FROM_HISTORY}
              />
            }
            label={t('distributor.balances.products.fromHistory')}
            onChange={onHistoryFilterChange}
          />
        </Box>
      </Grid>

      {!data.length && !loading && (
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary">
            {t('distributor.orders.add.noProducts')}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Box minHeight={8}>{loading && <LinearProgress />}</Box>

        <ProductAddList data={data} />
        <TablePagination
          count={pagination.total || 0}
          onChange={onChange}
          initialRowsPerPage={PRODUCTS_COUNT}
          paginationProps={{
            labelDisplayedRows: localeLabels.displayedRows,
            labelRowsPerPage: localeLabels.rowsPerPage,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddProductContainer;
