import {
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useAuth, useLogout } from '@rtt-libs/auth';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APK_DISTR_LATEST } from '../environment';
import routes from '../pages/routes';
import { PERMISSIONS } from '../rules';
import LanguageSwitcher from './LanguageSwitcher';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'rgb(51, 77, 110)',
    },
  }),
);

const Menu: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { logout } = useLogout();
  const { isPermittedTo } = useAuth();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => setIsOpen(prevIsOpen => !prevIsOpen);

  const logoutAll = () => {
    logout();
    handleClose();
  };

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-controls={isOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Popper
        open={isOpen}
        anchorEl={buttonRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isOpen} id="menu-list-grow">
                  {isPermittedTo(PERMISSIONS.NAV_SETTINGS) && (
                    <div>
                      {isPermittedTo(PERMISSIONS.NAV_FINISH_REGISTRATION) ? (
                        <Link
                          className={classes.link}
                          to={routes.finishRegistration}
                        >
                          <MenuItem onClick={handleClose}>
                            <Typography>{t('menu.profile')}</Typography>
                          </MenuItem>
                        </Link>
                      ) : (
                        <Link className={classes.link} to={routes.profile}>
                          <MenuItem onClick={handleClose}>
                            <Typography>{t('menu.profile')}</Typography>
                          </MenuItem>
                        </Link>
                      )}
                      <Link className={classes.link} to={routes.settings}>
                        <MenuItem onClick={handleClose}>
                          <Typography>{t('menu.settings')}</Typography>
                        </MenuItem>
                      </Link>
                    </div>
                  )}

                  <a className={classes.link} href={APK_DISTR_LATEST} download>
                    <MenuItem onClick={handleClose}>
                      <Typography>{t('menu.downloadApp')}</Typography>
                    </MenuItem>
                  </a>

                  <MenuItem button={false}>
                    <LanguageSwitcher />
                  </MenuItem>

                  <MenuItem onClick={logoutAll}>
                    <Typography>{t('menu.logout')}</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Menu;
