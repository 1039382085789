export const SUPERVISORS_SEARCH_REQUEST = '@rtt[D]/SUPERVISORS_SEARCH_REQUEST' as const;
export const SUPERVISORS_SEARCH_SUCCESS = '@rtt[D]/SUPERVISORS_SEARCH_SUCCESS' as const;
export const SUPERVISORS_SEARCH_FAILURE = '@rtt[D]/SUPERVISORS_SEARCH_FAILURE' as const;

export const SUPERVISORS_CREATE_REQUEST = '@rtt[D]/SUPERVISORS_CREATE_REQUEST' as const;
export const SUPERVISORS_CREATE_SUCCESS = '@rtt[D]/SUPERVISORS_CREATE_SUCCESS' as const;
export const SUPERVISORS_CREATE_FAILURE = '@rtt[D]/SUPERVISORS_CREATE_FAILURE' as const;

export const SUPERVISORS_EDIT_REQUEST = '@rtt[D]/SUPERVISORS_EDIT_REQUEST' as const;
export const SUPERVISORS_EDIT_SUCCESS = '@rtt[D]/SUPERVISORS_EDIT_SUCCESS' as const;
export const SUPERVISORS_EDIT_FAILURE = '@rtt[D]/SUPERVISORS_EDIT_FAILURE' as const;

export const SUPERVISORS_CHANGE_STATUS_REQUEST = '@rtt[D]/SUPERVISORS_CHANGE_STATUS_REQUEST' as const;
export const SUPERVISORS_CHANGE_STATUS_SUCCESS = '@rtt[D]/SUPERVISORS_CHANGE_STATUS_SUCCESS' as const;
export const SUPERVISORS_CHANGE_STATUS_FAILURE = '@rtt[D]/SUPERVISORS_CHANGE_STATUS_FAILURE' as const;

export const SUPERVISORS_GET_AVAILABLE_MANAGERS_REQUEST = '@rtt[D]/SUPERVISORS_GET_AVAILABLE_MANAGERS_REQUEST' as const;
export const SUPERVISORS_GET_AVAILABLE_MANAGERS_SUCCESS = '@rtt[D]/SUPERVISORS_GET_AVAILABLE_MANAGERS_SUCCESS' as const;
export const SUPERVISORS_GET_AVAILABLE_MANAGERS_FAILURE = '@rtt[D]/SUPERVISORS_GET_AVAILABLE_MANAGERS_FAILURE' as const;
export const SUPERVISORS_GET_AVAILABLE_MANAGERS_CLEANUP = '@rtt[D]/SUPERVISORS_GET_AVAILABLE_MANAGERS_CLEANUP' as const;
