import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { ProductAddModalActionButtons } from './ToggleProductAddModal';
import { PRODUCT_IDS_FIELD_NAME } from './const';

interface Props {
  checked?: string[];
  closeModal: () => void;
  onSubmit: (productIds: string[]) => void;
}

const ProductAddListForm: React.FC<Props> = ({
  checked,
  closeModal,
  onSubmit,
  children,
}) => {
  const initialValues = useMemo(
    () => ({
      [PRODUCT_IDS_FIELD_NAME]: checked,
    }),
    [checked],
  );

  return (
    <Form
      onSubmit={({ productIds }) => {
        onSubmit(productIds);
        closeModal();
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <>
          {children}

          <ProductAddModalActionButtons
            closeModal={closeModal}
            submit={handleSubmit}
          />
        </>
      )}
    </Form>
  );
};

export default ProductAddListForm;
