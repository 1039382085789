import { Grid, TextField } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchParams } from '../../api/priceTypes';
import { CreatePriceType } from './CreatePriceTypes';

type Props = {
  onChange(params: SearchParams): void;
  initialFilterValues: { [key: string]: unknown };
};

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = ({
  onChange,
  initialFilterValues: { title = '' },
}) => {
  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ title: e.target.value });
  };

  return (
    <>
      <Grid item xs={7}>
        <TextField
          defaultValue={title}
          name="title"
          label={t('distributor.priceTypes.search')}
          onChange={onSearchChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={3}>
        <CreatePriceType />
      </Grid>
    </>
  );
};

export default FilterRow;
