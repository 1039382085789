import { TreeData } from '@atlaskit/tree';
import { CategoryItem, CategoryItemId, Pagination } from '@rtt-libs/types';
import { assign, mapValues, keyBy } from 'lodash/fp';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { Currency, Product } from '../../api/assortment';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const getAssortmentSel: Selector<PartialRootState, Product[]> = get([
  branchName,
  'collection',
]);

export const selectProductsDictionary: Selector<
  PartialRootState,
  Record<Product['id'], Product>
> = createSelector(getAssortmentSel, products => keyBy('id', products));

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const getPaginationSel: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const getAssortmentDataSel: Selector<
  PartialRootState,
  {
    products: Product[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [getAssortmentSel, getPaginationSel, getLoadingStateSel, getErrorSel],
  (products, pagination, loading, error) => ({
    products,
    pagination,
    loading,
    error,
  }),
);

export const getProductSel: Selector<PartialRootState, Product | null> = get([
  branchName,
  'current',
]);

export const getProductDataSel: Selector<
  PartialRootState,
  {
    product: Product | null;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [getProductSel, getLoadingStateSel, getErrorSel],
  (product, loading, error) => ({
    product,
    loading,
    error,
  }),
);

export const getCategoryListSel: Selector<
  PartialRootState,
  Record<CategoryItemId, CategoryItem>
> = get([branchName, 'categoryList']);

const withChildrenProps = {
  hasChildren: true,
  isExpanded: false,
};

const addProps = mapValues((value: CategoryItem) => {
  if (value.children.length > 0) {
    const withChildren = assign(withChildrenProps, value);
    // add missing field for root item
    if (value.id === undefined) {
      withChildren.id = 'root';
      withChildren.isExpanded = true;
    }
    return withChildren;
  }
  return value;
});

export const getCategoriesSel: Selector<
  PartialRootState,
  TreeData
> = createSelector(getCategoryListSel, categoryList => {
  return {
    rootId: 'root',
    items: addProps(categoryList),
  };
});

export const getCategoryOptionsSel: Selector<
  PartialRootState,
  Record<CategoryItemId, string>
> = createSelector([getCategoryListSel], categoryList => {
  const options = {} as Record<CategoryItemId, string>;

  Object.values(categoryList).forEach(item => {
    if (item.id !== undefined) {
      options[item.id] = item.data?.title;
    }
  });

  return options;
});

export const getCurrenciesSel: Selector<PartialRootState, Currency[]> = get([
  branchName,
  'currencyList',
]);

export const getCurrencyOptionsSel: Selector<
  PartialRootState,
  { label: string; value: Currency['id'] }[]
> = createSelector([getCurrenciesSel], currencies =>
  currencies.map(currency => ({
    label: currency.sign,
    value: currency.id,
  })),
);
