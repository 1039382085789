/* eslint-disable @typescript-eslint/camelcase */
import { CategoriesItemDictionary, CategoryItem } from '@rtt-libs/types';
import { assign, mapValues } from 'lodash/fp';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

export type SearchList = Record<string, number>;

const withChildrenProps = {
  hasChildren: true,
  isExpanded: false,
};

const addProps = mapValues((value: CategoryItem) => {
  if (value.children.length > 0) {
    const withChildren = assign(withChildrenProps, value);
    // add missing field for root item
    if (value.id === undefined) {
      withChildren.id = 'root';
      withChildren.isExpanded = true;
    }
    return withChildren;
  }
  return value;
});

const getCategories = () =>
  api
    .get<CategoriesItemDictionary>(ENDPOINTS.categories, {
      params: { is_active: 1 },
    })
    .then(({ data }) => addProps(data));

export const getAllCategories = () =>
  api
    .get<CategoriesItemDictionary>(ENDPOINTS.categories, {
      params: { is_active: 0 },
    })
    .then(({ data }) => addProps(data));

export const getCategoryList = () =>
  api
    .get<CategoriesItemDictionary>(ENDPOINTS.listCategories, {
      params: { is_active: 1 },
    })
    .then(({ data }) => addProps(data));

export default getCategories;
