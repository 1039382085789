import { getAcceptLanguageHeader, setupApi } from '@rtt-libs/api-services';
import { getAuthToken } from '@rtt-libs/auth';
import type { LocaleValue } from '@rtt-libs/types';
import { BASE_API_URL, MOCK_API_CALLS } from '../environment';

const token = getAuthToken();

const api = setupApi(token, BASE_API_URL);

if (process.env.NODE_ENV !== 'production' && MOCK_API_CALLS) {
  const applyMocks = async () => {
    const { default: applyApiMock } =
      (await import('./__stubs__/mockSetup')) || {};
    applyApiMock(api);
  };
  applyMocks();
}

export default api;

export const setAcceptLanguageHeader = (lng: LocaleValue) => {
  api.defaults.headers['Accept-language'] = getAcceptLanguageHeader(lng);
};
