import { UserInfoType } from '@rtt-libs/auth';
import { StatusValue } from '@rtt-libs/types';
import { FinishFormValues } from '../types';
import * as TYPES from './types';

export const finishRegRequest = (payload: FinishFormValues, meta = {}) => ({
  type: TYPES.FINISH_REG_REQUEST,
  payload,
  meta,
});

export const finishRegSuccess = (payload: UserInfoType) => ({
  type: TYPES.FINISH_REG_SUCCESS,
  payload,
});

export const finishRegFailure = (error: Partial<FinishFormValues>) => ({
  type: TYPES.FINISH_REG_FAILURE,
  payload: error,
});

export const updateRegRequest = (payload: FinishFormValues) => ({
  type: TYPES.UPDATE_REG_REQUEST,
  payload,
});

export const updateRegSuccess = (payload: UserInfoType) => ({
  type: TYPES.UPDATE_REG_SUCCESS,
  payload,
});

export const updateRegFailure = (errors: Partial<FinishFormValues>) => ({
  type: TYPES.UPDATE_REG_FAILURE,
  payload: errors,
});

export const initialRegValuesRequest = () => ({
  type: TYPES.FINISH_REG_INITIAL_VALUES_REQUEST,
});

export const initialRegValuesSuccess = (
  payload: Partial<{
    values: Partial<FinishFormValues>;
    status: StatusValue;
    notices: string | null;
  }>,
) => ({
  type: TYPES.FINISH_REG_INITIAL_VALUES_SUCCESS,
  payload,
});

export const initialRegValuesFailure = (error: string) => ({
  type: TYPES.FINISH_REG_INITIAL_VALUES_FAILURE,
  payload: error,
});

export const updateCompanyStatus = (payload: string) => ({
  type: TYPES.FINISH_REG_STATUS,
  payload,
});

export type Actions = ReturnType<
  | typeof finishRegRequest
  | typeof finishRegSuccess
  | typeof finishRegFailure
  | typeof updateRegRequest
  | typeof updateRegSuccess
  | typeof updateRegFailure
  | typeof initialRegValuesRequest
  | typeof initialRegValuesSuccess
  | typeof initialRegValuesFailure
  | typeof updateCompanyStatus
>;
