import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import type { Brand } from '../../types';
import { branchName } from './const';
import type { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

const selectBrandsCollection: Selector<
  PartialRootState,
  Record<Brand['id'], Brand>
> = get([branchName, 'collection']);

const selectBrandIds: Selector<PartialRootState, Brand['id'][]> = get([
  branchName,
  'idList',
]);

export const selectBrandsError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

export const selectBrandsLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const selectBrandList: Selector<
  PartialRootState,
  Brand[]
> = createSelector(
  [selectBrandsCollection, selectBrandIds],
  (collection, ids) => ids.map(id => collection[id]),
);

export const selectBrandsData: Selector<
  PartialRootState,
  {
    data: Brand[];
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectBrandsCollection,
    selectBrandIds,
    selectBrandsLoading,
    selectBrandsError,
  ],
  (collection, ids, loading, error) => ({
    data: ids.map(id => collection[id]),
    loading,
    error,
  }),
);

export const selectSortedBrands: Selector<
  PartialRootState,
  { id: number; name: string; isActive: boolean }[]
> = createSelector(selectBrandsCollection, collection =>
  Object.values(collection)
    .map(({ id, title, isActive }) => ({
      id,
      isActive,
      name: title,
    }))
    .sort((a, b) => +b.isActive),
);

export const selectBrandsOptions: Selector<
  PartialRootState,
  { value: number; label: string; meta: { isActive: boolean } }[]
> = createSelector(selectBrandsCollection, collection =>
  Object.values(collection)
    .sort((a, b) => (a.isActive !== b.isActive ? +b.isActive : 0))
    .map(({ id, title, isActive }) => ({
      value: id,
      label: title,
      meta: {
        isActive,
      },
    })),
);
