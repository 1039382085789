import at from 'lodash/fp/at';
import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import type { SupervisorManager } from '../types';
import { branchName } from './const';
import type { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectSupervisorManagersCollection: Selector<
  PartialRootState,
  Record<SupervisorManager['id'], SupervisorManager>
> = get([branchName, 'collection']);

const selectSupervisorManagerIdList: Selector<
  PartialRootState,
  SupervisorManager['id'][]
> = get([branchName, 'idList']);

export const selectSupervisorManagersError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

const selectSupervisorManagersLoading: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'loading']);

export const selectSupervisorManagers: Selector<
  PartialRootState,
  {
    data: SupervisorManager[];
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectSupervisorManagersCollection,
    selectSupervisorManagerIdList,
    selectSupervisorManagersLoading,
    selectSupervisorManagersError,
  ],
  (collection, ids, loading, error) => ({
    data: at(ids, collection),
    loading,
    error,
  }),
);
