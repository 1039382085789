import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Selector, useSelector } from 'react-redux';
import { EnhancedProduct } from '../../../api/orders';
import { selectOrderPayload } from '../../../ordersRefactored/duck/selectors';

interface AddProductsContextObject {
  existingIds: string[];
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
  setExistingIds: (ids: string[]) => void;
  getAddedProducts(): Record<string, EnhancedProduct>;
}

const AddProductsContext = createContext<AddProductsContextObject | null>(null);

const useAddProductsContext = () => {
  const context = useContext(AddProductsContext);
  if (!context) throw new Error("AddProductsContext doesn't initialized");

  return context;
};

type Props = {
  existing: string[];
  initialChecked?: string[];
  productsSelector: Selector<
    { [key: string]: unknown },
    Record<EnhancedProduct['id'], EnhancedProduct>
  >;
  orderId?: string;
  forReturnOrder?: boolean;
};

export const AddProductsProvider: React.FC<Props> = ({
  children,
  existing,
  initialChecked,
  productsSelector,
  orderId,
  forReturnOrder,
}) => {
  const [checked, setChecked] = useState(initialChecked || []);

  useEffect(() => {
    setChecked(initialChecked || []);
  }, [initialChecked]);

  const [existingIds, setExistingIds] = useState(existing);

  useEffect(() => {
    setExistingIds(existing);
  }, [existing]);

  const products = useSelector(productsSelector);
  const { data: payload } = useSelector(selectOrderPayload(orderId || ''));

  const getAddedProducts = useCallback(() => {
    const addedProducts = {} as Record<string, EnhancedProduct>;
    checked.forEach(id => {
      const currentProduct = products[id];

      const orderedProduct = payload?.products?.[currentProduct.id];

      addedProducts[currentProduct.id] = {
        ...currentProduct,
        manuallyAdded: true,
        maxOrderWeight: orderedProduct?.orderWeight,
        maxQty: orderedProduct?.qty,
        price: currentProduct.price || orderedProduct?.price,
        saleMeasurement:
          currentProduct.saleMeasurement || orderedProduct?.saleMeasurement,
        // TODO: [delete] set initial qty value
        // qty: currentProduct.saleMeasurement === 'unit' ? 1 : 0,
        // total: currentProduct.price || 0,
        // orderWeight:
        //   currentProduct.saleMeasurement === 'weight'
        //     ? currentProduct.weight || 1
        //     : 0,
        qty: 0,
        total: 0,
        orderWeight: 0,
      };
    });

    return addedProducts;
  }, [checked, products, payload]);

  const contextValue = {
    checked,
    setChecked,
    existingIds,
    setExistingIds,
    getAddedProducts,
  };

  return (
    <AddProductsContext.Provider value={contextValue}>
      {children}
    </AddProductsContext.Provider>
  );
};

export default useAddProductsContext;
