import {
  AppBar,
  Box,
  CircularProgress,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BalanceSettingsContainer } from '../../balanceSettings';
import { selectors as categoriesSelectors } from '../../categories';
import {
  getAllCategoriesRequest,
  getCategoriesRequest,
} from '../../categories/duck/actions';
import { selectAllCategories } from '../../categories/duck/selectors';
import {
  getSettingsExportRequest,
  getSettingsOrdersRequest,
} from '../duck/actions';
import { selectSettingsExport, selectSettingsOrders } from '../duck/selectors';
import SettingsExportContainer from './SettingsExportContainer';

const SettingsOrdersContainer = React.lazy(() =>
  import('./SettingsOrdersContainer'),
);

enum SettingSections {
  EXPORT,
  ORDERS,
  BALANCES,
}

const SettingsContainer: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [settingsPage, setSettingsPage] = useState<SettingSections>(
    SettingSections.EXPORT,
  );

  const { data, error, loading } = useSelector(selectSettingsExport);
  const { dataOrders } = useSelector(selectSettingsOrders);
  const { allCategories } = useSelector(selectAllCategories);
  const activeCategories = useSelector(
    categoriesSelectors.getRootCategoriesSel,
  );

  const selectedOptions = activeCategories.map(category => ({
    value: category.id,
    title: category.data && category.data.title,
  }));

  useEffect(() => {
    dispatch(getCategoriesRequest());
    dispatch(getAllCategoriesRequest());
    dispatch(getSettingsExportRequest());
    dispatch(getSettingsOrdersRequest());
  }, [dispatch]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Paper>
      <Box clone borderBottom={`1px solid ${theme.palette.divider}`}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={settingsPage}
            onChange={(_, newValue) => {
              setSettingsPage(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              value={SettingSections.EXPORT}
              label={t('distributor.settings.export.exportTitle')}
            />
            <Tab
              value={SettingSections.ORDERS}
              label={t('distributor.settings.orders.ordersTitle')}
            />
            <Tab
              value={SettingSections.BALANCES}
              label={t('distributor.settings.balances.title')}
            />
          </Tabs>
        </AppBar>
      </Box>

      {error && <Typography color="error">{error}</Typography>}

      {settingsPage === SettingSections.EXPORT && (
        <SettingsExportContainer settingsExport={data} />
      )}
      {settingsPage === SettingSections.ORDERS && (
        <Suspense fallback={<CircularProgress />}>
          <SettingsOrdersContainer
            allCategories={allCategories}
            settingsOrders={dataOrders}
            options={selectedOptions}
          />
        </Suspense>
      )}
      {settingsPage === SettingSections.BALANCES && (
        <BalanceSettingsContainer />
      )}
    </Paper>
  );
};

export default SettingsContainer;
