import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BalanceProduct } from '../../../types';

interface Props {
  value: unknown;
}

const ProductTitleCell: React.FC<Props> = ({ value }) => {
  const [t] = useTranslation();
  const product = value as BalanceProduct['product'];

  const displayedWeight =
    product.weight === null
      ? t('product.saleMeasurement.empty')
      : `${product.weight ?? 1} ${t(`product.values.weight`)}`;

  return (
    <>
      <Typography>{product.title}</Typography>
      <Typography color="textSecondary">
        {t('product.skuContracted')} {product.sku} ― {t('product.weight')}:{' '}
        {displayedWeight}
      </Typography>
    </>
  );
};

export default ProductTitleCell;
