import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../common/useSnackbarError';
import { searchSupervisorManagersRequest } from '../duck/actions';
import { selectSupervisorManagers } from '../duck/selectors';
import SupervisorManagersTable from './SupervisorManagersTable';

/**
 * Container to fetch & filter employees & render them in table.
 */
const SupervisorManagersContainer: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchSupervisorManagersRequest());
  }, [dispatch]);
  const { data, loading, error } = useSelector(selectSupervisorManagers);

  useSnackbarError(error);

  return <SupervisorManagersTable entries={data} loading={loading} />;
};

export default SupervisorManagersContainer;
