export const ORDERS_GET_REQUEST = '@rtt[D]/ORDERS_GET_REQUEST' as const;
export const ORDERS_GET_SUCCESS = '@rtt[D]/ORDERS_GET_SUCCESS' as const;
export const ORDERS_GET_FAILURE = '@rtt[D]/ORDERS_GET_FAILURE' as const;

export const ORDER_DETAILS_GET_REQUEST = '@rtt[D]/ORDER_DETAILS_GET_REQUEST' as const;
export const ORDER_DETAILS_GET_SUCCESS = '@rtt[D]/ORDER_DETAILS_GET_SUCCESS' as const;
export const ORDER_DETAILS_GET_FAILURE = '@rtt[D]/ORDER_DETAILS_GET_FAILURE' as const;

export const ORDER_DECRYPT_TOTAL_REQUEST = '@rtt[D]/ORDER_DECRYPT_TOTAL_REQUEST' as const;
export const ORDER_DECRYPT_TOTAL_SUCCESS = '@rtt[D]/ORDER_DECRYPT_TOTAL_SUCCESS' as const;
export const ORDER_DECRYPT_TOTAL_FAILURE = '@rtt[D]/ORDER_DECRYPT_TOTAL_FAILURE' as const;
export const ORDER_DECRYPT_ORIGINTOTAL_REQUEST = '@rtt[D]/ORDER_DECRYPT_ORIGINTOTAL_REQUEST' as const;
export const ORDER_DECRYPT_PAYLOAD_REQUEST = '@rtt[D]/ORDER_DECRYPT_PAYLOAD_REQUEST' as const;
export const ORDER_DECRYPT_ORIGINTOTAL_SUCCESS = '@rtt[D]/ORDER_DECRYPT_ORIGINTOTAL_SUCCESS' as const;
export const ORDER_DECRYPT_PAYLOAD_SUCCESS = '@rtt[D]/ORDER_DECRYPT_PAYLOAD_SUCCESS' as const;
export const ORDER_DECRYPT_ORIGINTOTAL_FAILURE = '@rtt[D]/ORDER_DECRYPT_ORIGINTOTAL_FAILURE' as const;
export const ORDER_DECRYPT_PAYLOAD_FAILURE = '@rtt[D]/ORDER_DECRYPT_PAYLOAD_FAILURE' as const;

export const ORDER_STATUSES_GET_REQUEST = '@rtt[D]/ORDER_STATUSES_GET_REQUEST' as const;
export const ORDER_STATUSES_GET_SUCCESS = '@rtt[D]/ORDER_STATUSES_GET_SUCCESS' as const;
export const ORDER_STATUSES_GET_FAILURE = '@rtt[D]/ORDER_STATUSES_GET_FAILURE' as const;

export const ORDER_STATUSES_SET_REQUEST = '@rtt[D]/ORDER_STATUSES_SET_REQUEST' as const;
export const ORDER_STATUSES_SET_SUCCESS = '@rtt[D]/ORDER_STATUSES_SET_SUCCESS' as const;
export const ORDER_STATUSES_SET_FAILURE = '@rtt[D]/ORDER_STATUSES_SET_FAILURE' as const;

export const ORDER_EDIT_REQUEST = '@rtt[D]/ORDER_EDIT_REQUEST' as const;
export const ORDER_EDIT_SUCCESS = '@rtt[D]/ORDER_EDIT_SUCCESS' as const;
export const ORDER_EDIT_FAILURE = '@rtt[D]/ORDER_EDIT_FAILURE' as const;

export const ORDER_DELIVERY_CLOSES_DAY_REQUEST = '@rtt[D]/ORDER_DELIVERY_CLOSES_DAY_REQUEST' as const;
export const ORDER_DELIVERY_CLOSES_DAY_SUCCESS = '@rtt[D]/ORDER_DELIVERY_CLOSES_DAY_SUCCESS' as const;
export const ORDER_DELIVERY_CLOSES_DAY_FAILURE = '@rtt[D]/ORDER_DELIVERY_CLOSES_DAY_FAILURE' as const;

export const ORDER_MIN_TOTAL_REQUEST = '@rtt[D]/ORDER_MIN_TOTAL_REQUEST' as const;
export const ORDER_MIN_TOTAL_SUCCESS = '@rtt[D]/ORDER_MIN_TOTAL_SUCCESS' as const;
export const ORDER_MIN_TOTAL_FAILURE = '@rtt[D]/ORDER_MIN_TOTAL_FAILURE' as const;

export const ORDER_CREATE_REQUEST = '@rtt[D]/ORDER_CREATE_REQUEST' as const;
export const ORDER_CREATE_BY_MANAGER_SUCCESS = '@rtt[D]/ORDER_CREATE_BY_MANAGER_SUCCESS' as const;
export const ORDER_CREATE_SUCCESS = '@rtt[D]/ORDER_CREATE_SUCCESS' as const;
export const ORDER_CREATE_FAILURE = '@rtt[D]/ORDER_CREATE_FAILURE' as const;

export const ORDERS_BY_IDS_SUCCESS = '@rtt[D]/ORDERS_BY_IDS_SUCCESS' as const;
export const ORDERS_BY_IDS_FAILURE = '@rtt[D]/ORDERS_BY_IDS_FAILURE' as const;

/*
 * Return orders redux types
 */

export const RETURNS_GET_REQUEST = `@rtt[D]/RETURNS_GET_REQUEST` as const;
export const RETURNS_GET_SUCCESS = `@rtt[D]/RETURNS_GET_SUCCESS` as const;
export const RETURNS_GET_FAILURE = `@rtt[D]/RETURNS_GET_FAILURE` as const;

export const RETURN_DETAILS_GET_REQUEST = `@rtt[D]/RETURN_DETAILS_GET_REQUEST` as const;
export const RETURN_DETAILS_GET_SUCCESS = `@rtt[D]/RETURN_DETAILS_GET_SUCCESS` as const;
export const RETURN_DETAILS_GET_FAILURE = `@rtt[D]/RETURN_DETAILS_GET_FAILURE` as const;

export const RETURN_STATUSES_SET_REQUEST = `@rtt[D]/RETURN_STATUSES_SET_REQUEST` as const;
export const RETURN_STATUSES_SET_SUCCESS = `@rtt[D]/RETURN_STATUSES_SET_SUCCESS` as const;
export const RETURN_STATUSES_SET_FAILURE = `@rtt[D]/RETURN_STATUSES_SET_FAILURE` as const;

export const RETURN_EDIT_REQUEST = `@rtt[D]/RETURN_EDIT_REQUEST` as const;
export const RETURN_EDIT_SUCCESS = `@rtt[D]/RETURN_EDIT_SUCCESS` as const;
export const RETURN_EDIT_FAILURE = `@rtt[D]/RETURN_EDIT_FAILURE` as const;

export const RETURN_CREATE_REQUEST = `@rtt[D]/RETURN_CREATE_REQUEST` as const;
export const RETURN_CREATE_SUCCESS = `@rtt[D]/RETURN_CREATE_SUCCESS` as const;
export const RETURN_CREATE_FAILURE = `@rtt[D]/RETURN_CREATE_FAILURE` as const;
export const RETURN_CREATE_BY_MANAGER_SUCCESS = '@rtt[D]/RETURN_CREATE_BY_MANAGER_SUCCESS' as const;
