import { intersectionWith } from 'lodash';
import { at, compact, compose, keyBy, mapValues } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { EmployeeInfo } from '../../../../api/mappers';
import {
  ProductCheckboxList,
  ProductSelectorByRtt,
  selectors as productsSelectors,
  useClearProducts,
} from '../../../../productsAddition';
import { ReturnProductInfo } from '../../../../productsAddition/types';
import AddReturnProductContainer from '../../../../productsAddition/view/Containers/AddReturnProductContainer';
import { selectRttManagerListData } from '../../../../rtt/duck/selectors';
import { Brand, BrandManagerConnection } from '../../../../types';
import { PayloadGroupedKey, PAYLOAD_GROUPED_KEY } from '../../../constants';
import {
  getOrdersCollectionSel,
  selectOrderPayloadCollection,
} from '../../../duck/selectors';
import { OrderEditValues, ReturnedProduct } from '../../../types';
import { makeProductsPayloadFieldNamePrefix } from '../OrderProductsTable/generateFieldName';
import {
  parseOrderGroupIdKey,
  transformOrderGroupIdKey,
} from '../transformGroupIdKey';
import ChooseOrderOfProduct from './ChooseOrderOfProduct';

interface Props {
  rttId: number;
  orderId?: string;
  disabled?: boolean;
}

const ProductReturnOrderSelector = ({ rttId, orderId, disabled }: Props) => {
  useClearProducts();

  const managerList = useSelector(selectRttManagerListData);

  const brandManagerDictionary = useMemo(
    () =>
      compose<
        [BrandManagerConnection[]],
        Record<Brand['id'], BrandManagerConnection>,
        Record<Brand['id'], EmployeeInfo['id']>
      >(
        // brand for the rtt may hold only one manager - take 1-st item
        mapValues('managerId'),
        keyBy<BrandManagerConnection>('brandId'),
      )(managerList),
    [managerList],
  );

  const {
    input: { value },
  } = useField<OrderEditValues[PayloadGroupedKey]>(PAYLOAD_GROUPED_KEY);
  const form = useForm();

  const productCollection = useSelector(
    productsSelectors.selectMemoizedReturnProductsCollection,
  );

  const orderPayloadCollection = useSelector(selectOrderPayloadCollection);

  const [addedProductList, changeProductList] = useState<ReturnProductInfo[]>(
    [],
  );

  const clearProductList = useCallback(() => changeProductList([]), []);

  const orderCollection = useSelector(getOrdersCollectionSel);

  const handleSubmit = useCallback(
    (ordersWithProductIds: Record<string, string[]>) => {
      const ordersUpgraded: Record<
        string,
        ReturnedProduct[]
        // @ts-expect-error Convert method exists in fn lodash. https://github.com/lodash/lodash/wiki/FP-Guide#convert
      > = mapValues.convert({ cap: false })(
        (productIds: string[], orderKey: string) => {
          const payload = orderPayloadCollection[orderKey];

          if (!payload) {
            return [];
          }

          return productIds.map(id => payload.products[id]);
        },
        ordersWithProductIds,
      );

      form.batch(() => {
        Object.entries(ordersUpgraded).forEach(
          ([orderIdGroup, orderProducts]) => {
            orderProducts.forEach(product => {
              const productFieldName = `${makeProductsPayloadFieldNamePrefix(
                // TODO: simplify logic after enough testing - over-engineered while refactoring
                transformOrderGroupIdKey(
                  (brandManagerDictionary[product.brandId || 0] ||
                    orderCollection[orderIdGroup].managerId) ??
                    0,
                  orderIdGroup,
                ),
              )}.${product.id}`;

              const currentFieldState = form.getFieldState(productFieldName);

              // Do not set value if product already exists
              if (currentFieldState?.value) return;

              // XXX: do not add invalid product without brand id field
              if (!product.brandId) return;

              form.registerField(
                productFieldName,
                () => undefined,
                {},
                {
                  defaultValue: product,
                },
              );
            });
          },
        );
      });

      clearProductList();
    },
    [
      orderPayloadCollection,
      clearProductList,
      form,
      brandManagerDictionary,
      orderCollection,
    ],
  );

  const handleAddProducts = useCallback(
    (productIds: string[]) => {
      const addedProducts = compact(at(productIds, productCollection));
      changeProductList(addedProducts);
    },
    [changeProductList, productCollection],
  );

  const checkedProductHashes = useMemo(
    () => collectCheckedProductHashes(value, productCollection),
    [value, productCollection],
  );

  const checkedOrders = useMemo(() => collectCheckedOrders(value), [value]);

  return (
    <>
      <ProductSelectorByRtt
        onSubmit={handleAddProducts}
        checkedIds={checkedProductHashes}
        disabled={disabled}
      >
        <AddReturnProductContainer
          rttId={rttId}
          orderId={orderId}
          renderList={data => (
            <ProductCheckboxList keyName="hash" products={data} />
          )}
        />
      </ProductSelectorByRtt>

      <ChooseOrderOfProduct
        products={addedProductList}
        onClose={clearProductList}
        onSubmit={handleSubmit}
        checkedOrders={checkedOrders}
      />
    </>
  );
};

export default ProductReturnOrderSelector;

const collectCheckedProductHashes = (
  fieldValues: OrderEditValues[PayloadGroupedKey],
  productCollection: Record<string, ReturnProductInfo>,
) => {
  const productCollectionValues = Object.values(productCollection);

  let valueProductHashes: string[] = [];

  Object.entries(fieldValues).forEach(([groupId, groupedValue]) => {
    const [, orderIdKey] = parseOrderGroupIdKey(groupId);

    const listedGroupedProductKeys = groupedValue?.payload?.products
      ? Object.keys(groupedValue.payload.products)
      : [];

    const intersectedProducts = intersectionWith(
      productCollectionValues,
      listedGroupedProductKeys,
      (product, productId) => {
        return product.id === productId && product.orders.includes(orderIdKey);
      },
    );

    valueProductHashes = valueProductHashes.concat(
      intersectedProducts.map(product => product.hash),
    );
  });

  return valueProductHashes;
};

const collectCheckedOrders = (
  fieldValues: OrderEditValues[PayloadGroupedKey],
) =>
  fieldValues &&
  Object.entries(fieldValues).reduce((acc, [groupId, groupedPayload]) => {
    const [, parsedOrderId] = parseOrderGroupIdKey(groupId);

    if (!groupedPayload?.payload.products) {
      return acc;
    }

    const orderedProducts = acc[parsedOrderId] || [];

    return {
      ...acc,
      [parsedOrderId]: orderedProducts.concat(
        Object.keys(groupedPayload.payload.products),
      ),
    };
  }, {} as Record<string, string[]>);
