import type { SupervisorManager } from '../types';
import * as types from './types';

export const searchSupervisorManagersRequest = () => ({
  type: types.SUPERVISOR_MANAGERS_SEARCH_REQUEST,
});

export const searchSupervisorManagersSuccess = (
  payload: SupervisorManager[],
) => ({
  type: types.SUPERVISOR_MANAGERS_SEARCH_SUCCESS,
  payload,
});

export const searchSupervisorManagersFailure = (error: string) => ({
  type: types.SUPERVISOR_MANAGERS_SEARCH_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof searchSupervisorManagersRequest
  | typeof searchSupervisorManagersSuccess
  | typeof searchSupervisorManagersFailure
>;
