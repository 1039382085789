import {
  Box,
  CircularProgress,
  createStyles,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { FieldWithMessages, TextFieldAdapter } from '@rtt-libs/views';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../common/useSnackbarError';
import {
  getAvailableManagersForAssignCleanup,
  getAvailableManagersForAssignRequest,
} from '../duck/actions';
import {
  selectAvailableManagerOptions,
  selectAvailableManagersState,
  selectSupervisorManagerListById,
} from '../duck/selectors';
import type { Supervisor } from '../types';

type Props = {
  supervisorId?: Supervisor['id'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deactivated: {
      textDecorationLine: 'line-through',
    },
  }),
);

const ManagerListSelectField = ({ supervisorId }: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const existedManagers = useSelector(
    selectSupervisorManagerListById(supervisorId),
  );

  const managerOptions = useSelector(
    selectAvailableManagerOptions(existedManagers),
  );

  const { loading, error } = useSelector(selectAvailableManagersState);

  useSnackbarError(error);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAvailableManagersForAssignRequest());

    return () => {
      dispatch(getAvailableManagersForAssignCleanup());
    };
  }, [dispatch]);

  const loadingIndicator = loading
    ? {
        InputProps: {
          endAdornment: (
            <Box clone marginRight={2}>
              <InputAdornment position="end">
                <CircularProgress size="1.25rem" />
              </InputAdornment>
            </Box>
          ),
        },
      }
    : undefined;

  return (
    <FieldWithMessages
      component={TextFieldAdapter}
      name="managerList"
      margin="dense"
      type="number"
      fullWidth
      label={t('distributor.employees.add.managerList.label')}
      select
      multiple
      {...loadingIndicator}
    >
      {managerOptions.map(({ value, label, meta }) => (
        <MenuItem
          key={value}
          value={value}
          className={!meta.isActive ? classes.deactivated : undefined}
          aria-label={!meta.isActive ? 'disabled' : undefined}
        >
          {label}
        </MenuItem>
      ))}
    </FieldWithMessages>
  );
};

export default ManagerListSelectField;
