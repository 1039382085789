import DayjsUtils from '@date-io/dayjs';
import {
  createStyles,
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  FieldWithMessages,
  LoadingButton,
  NumberFieldAdapter,
  RadioSetAdapter,
  TextFieldAdapter,
} from '@rtt-libs/views';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import {
  Field,
  FieldRenderProps,
  FormRenderProps,
  FormSpy,
  FormSpyRenderProps,
} from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SettingsExport } from '../../../api/settingsExport';
import FtpTestCreds from './FtpTestCreds';

dayjs.extend(utc);

type Props = FormRenderProps<SettingsExport>;

const useStyles = makeStyles(
  createStyles({
    column: {
      padding: '10px 25px',
      justifyContent: 'space-between',
    },
    button: {
      padding: '10px 10px',
    },
  }),
);

const EditSettingsExport: React.FC<FormRenderProps<SettingsExport>> = ({
  handleSubmit,
  submitError,
  submitting,
  hasValidationErrors,
  pristine,
  hasSubmitErrors,
  modifiedSinceLastSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const valuesFrequencyRadio = [
    {
      label: t('distributor.settings.export.manual'),
      value: 'manual',
    },
    {
      label: t('distributor.settings.export.daily'),
      value: 'daily',
    },
    {
      label: t('distributor.settings.export.hourly'),
      value: 'hourly',
    },
    {
      label: t('distributor.settings.export.periodically'),
      value: 'periodically',
    },
  ];

  const valuesTypeRadio = [
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'FTP',
      value: 'ftp',
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} md={4} className={classes.column}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h1">
              {t('distributor.settings.export.frequency')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={8}>
            <RadioSetAdapter
              fieldProps={{ required: true }}
              required
              name="frequency"
              data={valuesFrequencyRadio}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={4}>
            <FormSpy>
              {({
                values: { frequency },
                form: { change },
              }: FormSpyRenderProps<SettingsExport>) => {
                if (frequency === 'manual') {
                  change('time', '');
                  change('date', '');
                }
                if (frequency === 'hourly') {
                  change('time', '');
                  change('date', '');
                }
                if (frequency === 'daily') {
                  change('date', '');
                }
                return null;
              }}
            </FormSpy>

            <FormSpy>
              {({
                values: { frequency },
              }: FormSpyRenderProps<SettingsExport>) => {
                return (
                  !['manual', 'hourly'].includes(frequency) && (
                    <>
                      <MuiPickersUtilsProvider utils={DayjsUtils}>
                        <Field
                          allowNull
                          type="datetime-local"
                          required
                          name="time"
                          parse={(date: Dayjs | null) => {
                            return date?.utc().format('YYYY-MM-DDTHH:mm');
                          }}
                          format={(value: string | undefined) => {
                            return (value && dayjs.utc(value).local()) || null;
                          }}
                          validate={(value: string | undefined) => {
                            return !value
                              ? t('validation.valueMissing')
                              : undefined;
                          }}
                        >
                          {({
                            input,
                            meta,
                            ...rest
                          }: FieldRenderProps<Dayjs | null>) => {
                            const showError =
                              ((meta.submitError &&
                                !meta.dirtySinceLastSubmit) ||
                                meta.error) &&
                              meta.touched;

                            return (
                              <KeyboardTimePicker
                                margin="dense"
                                autoOk
                                required
                                fullWidth
                                ampm={false}
                                variant="inline"
                                label={t('distributor.settings.export.time')}
                                value={input.value}
                                onChange={(value, strValue) => {
                                  // do not update value before it with placeholder
                                  if (!strValue?.includes('_')) {
                                    // force update value
                                    // value is string - if rounding to 0< the value will be the same string,
                                    // so we need a force update
                                    input.onChange(dayjs());
                                    input.onChange(value);
                                  }
                                }}
                                helperText={meta.error}
                                error={showError}
                              />
                            );
                          }}
                        </Field>
                      </MuiPickersUtilsProvider>
                      {frequency === 'periodically' && (
                        <>
                          <FieldWithMessages
                            margin="dense"
                            name="period"
                            type="number"
                            placeholder="2-23"
                            fullWidth
                            required
                            inputProps={{
                              min: 2,
                              max: 23,
                              step: 1,
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {t('common.values.hoursAbbr')}
                                </InputAdornment>
                              ),
                            }}
                            component={NumberFieldAdapter}
                            label={t('distributor.settings.export.period')}
                            parse={(value: string | undefined) => {
                              return Number(value) || undefined;
                            }}
                          />
                        </>
                      )}
                    </>
                  )
                );
              }}
            </FormSpy>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={8} className={classes.column}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h1">
              {t('distributor.settings.export.exportTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <RadioSetAdapter
              fieldProps={{ required: true }}
              name="type"
              data={valuesTypeRadio}
            />
          </Grid>
          <Grid container spacing={2} item xs={12} sm={7}>
            <FormSpy>
              {({ values: { type } }: FormSpyRenderProps<SettingsExport>) => {
                if (type === 'email') {
                  return (
                    <FieldWithMessages
                      margin="dense"
                      name="email"
                      type="email"
                      fullWidth
                      required={type === 'email'}
                      component={TextFieldAdapter}
                      label={t('distributor.settings.export.email')}
                    />
                  );
                }
                if (type === 'ftp') {
                  return (
                    <>
                      <Grid item xs={9}>
                        <FieldWithMessages
                          margin="dense"
                          name="ftpCreds.host"
                          type="text"
                          fullWidth
                          inputProps={{
                            pattern:
                              "^[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~/?#[\\]@!\\$&'()*+,;=.]+$",
                            autoComplete: 'off',
                          }}
                          placeholder="ftp.example.com"
                          required={type === 'ftp'}
                          component={TextFieldAdapter}
                          label={t('distributor.settings.export.host')}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FieldWithMessages
                          margin="dense"
                          name="ftpCreds.port"
                          type="number"
                          inputProps={{
                            min: 0,
                            max: 65535,
                          }}
                          fullWidth
                          required={type === 'ftp'}
                          component={TextFieldAdapter}
                          label={t('distributor.settings.export.port')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldWithMessages
                          margin="dense"
                          name="ftpCreds.user"
                          type="text"
                          fullWidth
                          required={type === 'ftp'}
                          component={TextFieldAdapter}
                          label={t('distributor.settings.export.user')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldWithMessages
                          margin="dense"
                          name="ftpCreds.pass"
                          type="password"
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          fullWidth
                          required={type === 'ftp'}
                          component={TextFieldAdapter}
                          label={t('distributor.settings.export.password')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldWithMessages
                          margin="dense"
                          name="ftpCreds.path"
                          type="text"
                          inputProps={{
                            // Do not allow to save to root
                            pattern: '^[^/].*$',
                            autoComplete: 'off',
                          }}
                          placeholder="exports/path"
                          fullWidth
                          component={TextFieldAdapter}
                          label={t('distributor.settings.export.path')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FtpTestCreds />
                      </Grid>
                    </>
                  );
                }
                return null;
              }}
            </FormSpy>
          </Grid>
        </Grid>

        {submitError && <FormHelperText error>{submitError}</FormHelperText>}

        <Grid item xs={12} className={classes.button}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              submitting ||
              hasValidationErrors ||
              pristine ||
              (hasSubmitErrors && !modifiedSinceLastSubmit)
            }
            loading={submitting}
          >
            {t('controls.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
export default EditSettingsExport;
