import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { ChangePassword } from '@rtt-libs/auth';
import {
  FieldWithMessages as Field,
  LoadingButton,
  TextFieldAdapter,
} from '@rtt-libs/views';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

type Props = FormRenderProps<ChangePassword>;

const ChangeProfilePasswordForm: React.FC<Props> = ({
  handleSubmit,
  submitting,
  submitError,
}) => {
  const [t] = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={6}>
        <DialogTitle id="edit-profile-dialog-title">
          {t('distributor.profile.titleChangePassword')}
        </DialogTitle>
        <DialogContent>
          <Field
            margin="dense"
            name="password"
            type="password"
            fullWidth
            required
            component={TextFieldAdapter}
            label={t('distributor.profile.password')}
            inputProps={{
              minLength: 6,
              maxLength: 32,
            }}
          />
          <Field
            component={TextFieldAdapter}
            name="newPassword"
            margin="dense"
            type="password"
            fullWidth
            required
            label={t('distributor.profile.newPassword')}
            inputProps={{
              minLength: 6,
              maxLength: 32,
            }}
          />
          <Field
            component={TextFieldAdapter}
            name="newPasswordConfirmation"
            margin="dense"
            type="password"
            fullWidth
            required
            label={t('distributor.profile.newPasswordConfirmation')}
            inputProps={{
              minLength: 6,
              maxLength: 32,
            }}
          />
          {submitError && <FormHelperText error>{submitError}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={submitting}
          >
            {t('controls.save')}
          </LoadingButton>
        </DialogActions>
      </Grid>
    </form>
  );
};

export default ChangeProfilePasswordForm;
