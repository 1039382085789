import { Reducer } from 'redux';
import { Profile } from '../../api/profile';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: {
    profile: false,
    password: false,
  },
  profile: {} as Profile,
  error: null as string | null,
  errorPassword: null as string | null,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.EDIT_PROFILE_SUCCESS:
    case types.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, profile: false },
        profile: action.payload,
        error: null,
      };
    }
    case types.EDIT_PROFILE_REQUEST:
    case types.GET_PROFILE_REQUEST: {
      return {
        ...state,
        loading: { ...state.loading, profile: true },
        error: null,
      };
    }
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, password: true },
        errorPassword: null,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, password: false },
        errorPassword: null,
      };
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, password: false },
        errorPassword: action.payload,
      };
    case types.EDIT_PROFILE_FAILURE:
    case types.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, profile: false },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
