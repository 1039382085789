import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  close: () => void;
  onClick?: () => void;
  dropZoneRef?: React.RefObject<HTMLDivElement>;
  browseRef?: React.RefObject<HTMLElement>;
  onEntered?: () => void;
  onExited?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: '5rem',
    },
    dropZone: {
      padding: theme.spacing(16),
      textAlign: 'center',
      transition: 'all 250ms',
      border: 'dashed 2px',
      borderRadius: '4px',
      borderColor: ({ dragged }: { dragged: boolean }) =>
        dragged ? theme.palette.primary.light : 'transparent',
      '& > *': {
        userSelect: 'none',
        pointerEvents: 'none',
      },
    },
  }),
);

const UploadDialog: React.FC<Props> = ({
  open = false,
  close,
  onClick,
  browseRef,
  dropZoneRef,
  onEntered,
  onExited,
}) => {
  const [t] = useTranslation();

  const [dragged, setDragged] = useState(false);
  const classes = useStyles({ dragged });

  return (
    <Dialog
      open={open}
      onClose={close}
      onEntered={onEntered}
      onExited={onExited}
    >
      <DialogTitle>
        {t('distributor.assortment.upload.dialogTitle', 'Upload Assortment')}
      </DialogTitle>
      <DialogContent>
        <div
          className={classes.dropZone}
          onDragEnter={() => {
            setDragged(true);
          }}
          onDragLeave={() => {
            setDragged(false);
          }}
          ref={dropZoneRef}
        >
          <CloudUploadIcon className={classes.icon} />
          <DialogContentText>
            {t(
              'distributor.assortment.upload.dialogDescription',
              'Drag here the assortment file',
            )}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('controls.cancel')}</Button>
        <Button
          onClick={onClick}
          innerRef={browseRef}
          variant="contained"
          color="primary"
        >
          {t('controls.selectFile')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
