import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { Profile } from '../../api/profile';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectProfileError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

export const selectPasswordError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'errorPassword']);

export const getProfileSel: Selector<PartialRootState, Profile> = get([
  branchName,
  'profile',
]);

const getProfileLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
  'profile',
]);

const getPasswordLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
  'password',
]);

export const selectProfile: Selector<
  PartialRootState,
  {
    data: Profile;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [getProfileSel, getProfileLoadingSel, selectProfileError],
  (profile, loading, error) => {
    return {
      data: profile,
      loading,
      error,
    };
  },
);

export const selectPassword: Selector<
  PartialRootState,
  {
    loadingPassword: boolean;
    errorPassword: string | null;
  }
> = createSelector(
  [getPasswordLoadingSel, selectPasswordError],
  (loadingPassword, errorPassword) => {
    return {
      loadingPassword,
      errorPassword,
    };
  },
);
