import type { GeoPoint } from '@rtt-libs/types';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { Area } from '../../api/territories';

const TerritoriesInteractionContext = createContext({} as ChildrenProps);

export type ModifiedPolygons = {
  changed?: Record<Area['id'], GeoPoint[]>;
  deleted?: Area['id'][];
};

type State = {
  expandedArea: null | number;
  creation: boolean;
  drawing: boolean;
  paths: GeoPoint[];
  editableId: number | null;
  modifiedPolygons: ModifiedPolygons;
};

type ChildrenProps = {
  expanded: number | null;
  creation: boolean;
  onExpand: (id: number | null) => void;
  onPolygonClick: (id: number | null) => void;
  toggleCreate: (status: boolean) => void;
  onPolygonComplete: (path: GeoPoint[]) => void;
  drawing: boolean;
  paths: GeoPoint[];
  editableId: number | null;
  onEdit: (areaId: number | null) => void;
  setChangedPolygons: (modifiedPolygons: ModifiedPolygons) => void;
  modifiedPolygons: ModifiedPolygons;
  refetchAreas: () => void;
};

const emptyPaths = [] as GeoPoint[];
const emptyModifiedPolygons = {} as ModifiedPolygons;

type Props = {
  refetchAreas: () => void;
};

const TerritoriesInteraction: React.FC<Props> = ({
  children,
  refetchAreas,
}) => {
  const [state, setState] = useState<State>({
    expandedArea: null,
    creation: false,
    drawing: false,
    paths: emptyPaths,
    editableId: null,
    modifiedPolygons: emptyModifiedPolygons,
  });

  const onExpand = (id: number | null) => {
    setState(s => ({ ...s, expandedArea: id, editableId: null }));
  };

  const onPolygonClick = (id: number | null) => {
    setState(s => ({
      ...s,
      expandedArea: id,
      editableId: null,
      creation: false,
    }));
  };

  const toggleCreate = (status: boolean) => {
    setState(s => ({
      ...s,
      creation: status,
      drawing: status,
      paths: status ? s.paths : emptyPaths,
      editableId: null,
      expandedArea: null,
    }));
  };

  const onPolygonComplete = useCallback((path: GeoPoint[]) => {
    setState(s => ({ ...s, drawing: false, paths: path }));
  }, []);

  const onEdit = (areaId: number | null) => {
    setState(s => ({
      ...s,
      editableId: areaId,
      modifiedPolygons: areaId ? s.modifiedPolygons : emptyModifiedPolygons,
      paths: areaId ? s.paths : [],
    }));
  };

  const setChangedPolygons = useCallback(
    (modifiedPolygons: ModifiedPolygons) => {
      setState(s => ({ ...s, modifiedPolygons }));
    },
    [],
  );

  return (
    <TerritoriesInteractionContext.Provider
      value={{
        expanded: state.expandedArea,
        onExpand,
        onPolygonClick,
        toggleCreate,
        creation: state.creation,
        onPolygonComplete,
        drawing: state.drawing,
        paths: state.paths,
        onEdit,
        editableId: state.editableId,
        setChangedPolygons,
        modifiedPolygons: state.modifiedPolygons,
        refetchAreas,
      }}
    >
      {typeof children === 'function'
        ? children({
            expanded: state.expandedArea,
            onExpand,
            onPolygonClick,
            toggleCreate,
            creation: state.creation,
            onPolygonComplete,
            drawing: state.drawing,
            paths: state.paths,
            onEdit,
            editableId: state.editableId,
            setChangedPolygons,
            modifiedPolygons: state.modifiedPolygons,
            refetchAreas,
          } as ChildrenProps)
        : children}
    </TerritoriesInteractionContext.Provider>
  );
};

export const useTerritoryInteraction = () => {
  const context = useContext(TerritoriesInteractionContext);
  return context;
};

export default TerritoriesInteraction;
