import {
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  RadioSetAdapter,
  TextFieldAdapter,
  FieldWithMessages as Field,
} from '@rtt-libs/views';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Product } from '../../../api/assortment';
import CurrencySelect from './CurrencySelect';

type Props = {
  product?: Product;
};

const useStyles = makeStyles(
  createStyles({
    withoutSteppers: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  }),
);

const OptionalProductDetails: React.FC<Props> = ({ product }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" paragraph>
          {t('product.additionalInfoTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioSetAdapter
          required
          name="isActive"
          area-label="is active"
          label={t('product.isActive.legend')}
          formControlProps={{
            fullWidth: true,
            disabled: product?.isInvalid,
          }}
          radioGroupProps={{
            row: true,
          }}
          fieldProps={{
            parse: (value: string) => value === 'true',
            validate: value =>
              value === undefined ? t('validation.valueMissing') : undefined,
          }}
          data={[
            {
              label: t('product.isActive.activeLabel'),
              value: true,
            },
            {
              label: t('product.isActive.inactiveLabel'),
              value: false,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <RadioSetAdapter
          required
          name="saleMeasurement"
          area-label="type of sale"
          label={t('product.saleMeasurement.legend')}
          formControlProps={{
            fullWidth: true,
          }}
          radioGroupProps={{
            row: true,
          }}
          fieldProps={{
            validate: value =>
              value === undefined ? t('validation.valueMissing') : undefined,
          }}
          data={[
            {
              label: t('product.saleMeasurement.unit'),
              value: 'unit',
            },
            {
              label: t('product.saleMeasurement.weight'),
              value: 'weight',
            },
          ]}
        />
      </Grid>

      <Grid item container spacing={8}>
        <Grid container item xs={6} wrap="nowrap" alignItems="baseline">
          <FormSpy>
            {({ values }) => (
              <Field
                name="price"
                label={t([
                  `product.price.${values.saleMeasurement}`,
                  'product.price.default',
                ])}
                type="number"
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
                required
                component={TextFieldAdapter}
                className={classes.withoutSteppers}
              />
            )}
          </FormSpy>

          <CurrencySelect />
        </Grid>
        <Grid container item xs={6} wrap="nowrap" alignItems="baseline">
          <Field
            name="weight"
            label={t('product.weight')}
            type="number"
            inputProps={{
              min: 0,
              step: 0.001,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {t('product.kg')}
                </InputAdornment>
              ),
            }}
            component={TextFieldAdapter}
            className={classes.withoutSteppers}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <RadioSetAdapter
          required
          name="isRevocable"
          area-label="type of revocable"
          label={t('product.isRevocable.legend')}
          formControlProps={{
            fullWidth: true,
          }}
          radioGroupProps={{
            row: true,
          }}
          fieldProps={{
            parse: (value: string) => value === 'true',
            validate: value =>
              value === undefined ? t('validation.valueMissing') : undefined,
          }}
          data={[
            {
              label: t('product.isRevocable.revocableLabel'),
              value: true,
            },
            {
              label: t('product.isRevocable.irrevocableLabel'),
              value: false,
            },
          ]}
        />
      </Grid>

      <Grid item>
        <Field
          name="expirationDays"
          label={t('product.expirationDays')}
          type="number"
          required
          inputProps={{
            min: 0,
            step: 1,
          }}
          validate={value => {
            return /^\d*$/.test(value) ? undefined : t('validation.notInteger');
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {t('product.days')}
              </InputAdornment>
            ),
          }}
          component={TextFieldAdapter}
          className={classes.withoutSteppers}
        />
      </Grid>
    </Grid>
  );
};

export default OptionalProductDetails;
