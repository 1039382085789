import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchRttDiscountRequest } from '../../duck/actions';
import { selectRttOptionsWithoutDiscount } from '../../duck/selectors';
import RttAsyncAutocompleteField from '../RttAsyncAutocomplete';

interface Props {
  readOnly?: boolean;
}

const RttField: React.FC<Props> = ({ readOnly }) => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectRttOptionsWithoutDiscount);
  const fetchOptions = useCallback(() => dispatch(searchRttDiscountRequest()), [
    dispatch,
  ]);

  return (
    <RttAsyncAutocompleteField
      readOnly={readOnly}
      fetchOptions={fetchOptions}
      options={data}
      loading={loading}
    />
  );
};

export default RttField;
