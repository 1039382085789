import type { ColumnConfig, EntryId } from '@rtt-libs/views';
import type { TFunction } from 'i18next';
import { ProductInfoCell, ThumbnailCell } from '../../../../common';
import type { OrderedProduct } from '../../../types';

/**
 * Configure product table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const productsTableConfig = (
  t: TFunction,
  components: Record<
    'isAvailable' | 'qty' | 'total',
    React.ComponentType<{ id: EntryId; value: unknown }>
  >,
): ColumnConfig[] => [
  {
    id: 'image',
    label: '',
    options: {
      sort: false,
      component: ThumbnailCell,
    },
  },
  {
    id: 'title',
    label: t('distributor.tables.orders.title', 'Title'),
    options: {
      sort: false,
      format: (_: unknown, entry: OrderedProduct) => entry,
      component: ProductInfoCell,
    },
  },
  {
    id: 'isAvailable',
    label: t('distributor.tables.orders.isAvailable', 'Is Available'),
    options: {
      sort: false,
      align: 'center',
      component: components.isAvailable,
    },
  },
  {
    id: 'qty',
    label: t('distributor.tables.orders.qty', 'qty'),
    options: {
      sort: false,
      align: 'center',
      format: (_: unknown, entry: OrderedProduct) => entry,
      component: components.qty,
    },
  },
  {
    id: 'total',
    label: t('distributor.tables.orders.total', 'Total'),
    options: {
      sort: false,
      align: 'right',
      format: (_: unknown, entry: OrderedProduct) => entry,
      component: components.total,
    },
  },
];

export default productsTableConfig;
