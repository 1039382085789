import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export const DateField: React.FC<DatePickerProps> = props => {
  const [t] = useTranslation();

  const commonProps = useMemo(
    () => ({
      format: 'DD.MM.YYYY',
      maxDate: new Date(),
      fullWidth: true,
      cancelLabel: t('controls.cancel'),
      clearLabel: t('controls.clear'),
      todayLabel: t('controls.today'),
      emptyLabel: t('validation.emptyLabel'),
      invalidDateMessage: t('validation.invalidDateMessage'),
      invalidLabel: t('validation.invalidLabel'),
      maxDateMessage: t('validation.maxDateMessage'),
      minDateMessage: t('validation.minDateMessage'),
    }),
    [t],
  );

  // eslint-disable-next-line react/destructuring-assignment
  if (props.variant === 'inline') {
    return <DatePicker disableToolbar {...commonProps} {...props} />;
  }

  return <DatePicker clearable {...commonProps} {...props} />;
};

const DateFieldAdapter: React.FC<
  FieldRenderProps<Dayjs | null> & {
    onDateChange?: (date: Dayjs | null) => void;
    inputProps?: { [k: string]: unknown };
  }
> = props => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    inputProps = {},
    onDateChange = onChange,
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const innerInputProps = {
    ...restInput,
    ...inputProps,
  };

  return (
    <DateField
      {...rest}
      value={value || null}
      onAccept={onChange}
      onChange={onDateChange}
      name={name}
      helperText={showError ? meta.error || meta.submitError : ''}
      error={showError}
      inputProps={innerInputProps}
    />
  );
};

export default DateFieldAdapter;
