/* eslint-disable @typescript-eslint/no-empty-interface, @typescript-eslint/camelcase */
import { formErrorTransform, mapPaginatedData } from '@rtt-libs/api-services';
import {
  AttachmentDocumentType,
  DataWrapped,
  Paginated,
} from '@rtt-libs/types';
import { snakeCase, trim } from 'lodash';
import {
  // Set different name of interface to prevent collision with class
  AgreedAssortment as IAgreedAssortment,
  AgreedAssortmentCreateFormValues,
  AgreedAssortmentEditFormValues,
  AgreedAssortmentItem as IAgreedAssortmentItem,
  AgreedAssortmentProductListFormValues,
  AgreedAssortmentTableParams,
  AgreedAssortmentWithItems as IAgreedAssortmentWithItems,
  RttSelectOption,
} from '../agreedAssortments/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { FetchedProduct } from './assortment';
import { Brand, transformSimpleErrorMessage } from './mappers';
import { FetchedRttInfo, RttInfo } from './rtt';

type FetchedAgreedAssortment = {
  id: string;
  rtt_id: number;
  qty: number;
  rtt?: FetchedRttInfo;
};

type FetchedProductEssential = Pick<
  FetchedProduct,
  | 'id'
  | 'sku'
  | 'is_active'
  | 'is_invalid'
  | 'title'
  | 'description'
  | 'manufacturer'
  | 'image'
  | 'brand'
>;

type FetchedAssortmentItem = {
  id: string;
  agreed_assortment_id: FetchedAgreedAssortment['id'];
  product_id: FetchedProduct['id'];
  product: FetchedProductEssential;
};

type FetchedAgreedAssortmentWithItems = FetchedAgreedAssortment & {
  items: DataWrapped<FetchedAssortmentItem[]>;
};

// Renamed interface back to use declaration merging of interface & class AgreedAssortment
interface AgreedAssortment extends IAgreedAssortment {}
interface AgreedAssortmentWithItems extends IAgreedAssortmentWithItems {}
interface AgreedAssortmentItem extends IAgreedAssortmentItem {}

class AgreedAssortment {
  constructor(fetched: FetchedAgreedAssortment) {
    this.id = fetched.id;
    this.rttId = fetched.rtt_id;
    this.qty = fetched.qty;
    this.rtt = fetched.rtt && new RttInfo(fetched.rtt);
  }
}

// TODO: Duplicated Product mapping but without unused fields
export class ProductEssential {
  id: string;
  sku: string;
  isActive: boolean;
  isInvalid: boolean;
  title: string;
  description: string;
  manufacturer: string | null;
  image?: AttachmentDocumentType;
  brand?: Brand;
  brandId: Brand['id'];
  // Added to make compatible to Record<string, unknown> (used in Tables)
  [key: string]: unknown;

  constructor(fetchedProduct: FetchedProductEssential) {
    this.id = fetchedProduct.id;
    this.sku = fetchedProduct.sku;
    this.isActive = fetchedProduct.is_active;
    this.isInvalid = fetchedProduct.is_invalid;
    this.title = fetchedProduct.title;
    this.description = fetchedProduct.description ?? '';
    this.manufacturer = fetchedProduct.manufacturer;
    this.image = fetchedProduct.image;
    this.brand = fetchedProduct.brand
      ? new Brand(fetchedProduct.brand)
      : undefined;
    this.brandId = fetchedProduct.brand?.id ?? 0;
  }
}

class AgreedAssortmentItem {
  constructor(fetched: FetchedAssortmentItem) {
    this.id = fetched.id;
    this.agreedAssortmentId = fetched.agreed_assortment_id;
    this.productId = fetched.product_id;
    this.product = new ProductEssential(fetched.product);
  }
}

class AgreedAssortmentWithItems extends AgreedAssortment {
  constructor(fetched: FetchedAgreedAssortmentWithItems) {
    super(fetched);
    this.items = fetched.items.data.map(item => new AgreedAssortmentItem(item));
  }
}

class AgreedAssortmentProductListBody {
  product_id_list: string[];

  constructor(values: AgreedAssortmentProductListFormValues) {
    this.product_id_list = values.productIdList;
  }
}

class CreateAgreedAssortmentBody extends AgreedAssortmentProductListBody {
  rtt_id: number;

  constructor(values: AgreedAssortmentCreateFormValues) {
    super(values);
    this.rtt_id = values.rttId;
  }
}

/*
 * Agreed Assortments API handlers
 */

export const getAgreedAssortments = (
  params: Partial<AgreedAssortmentTableParams> = {},
) =>
  api
    .get<Paginated<FetchedAgreedAssortment>>(ENDPOINTS.agreedAssortments, {
      params: mapAgreedAssortmentGetParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, bulkMapAgreedAssortment))
    .catch(transformSimpleErrorMessage);

export const getAgreedAssortmentById = (id: AgreedAssortment['id']) =>
  api
    .get<FetchedAgreedAssortmentWithItems>(
      `${ENDPOINTS.agreedAssortments}/${id}`,
      {
        params: { include: 'items' },
      },
    )
    .then(({ data }) => new AgreedAssortmentWithItems(data))
    .catch(transformSimpleErrorMessage);

export const createAgreedAssortment = (
  values: AgreedAssortmentCreateFormValues,
) =>
  api
    .post<FetchedAgreedAssortmentWithItems>(
      ENDPOINTS.agreedAssortments,
      new CreateAgreedAssortmentBody(values),
      {
        params: {
          include: 'items',
        },
      },
    )
    .then(({ data }) => new AgreedAssortmentWithItems(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const deleteAgreedAssortment = (id: AgreedAssortment['id']) =>
  api
    .delete(`${ENDPOINTS.agreedAssortments}/${id}`)
    .catch(transformSimpleErrorMessage);

export const editAgreedAssortment = ({
  id,
  ...values
}: AgreedAssortmentEditFormValues) =>
  api
    .put<FetchedAgreedAssortmentWithItems>(
      `${ENDPOINTS.agreedAssortments}/${id}`,
      new AgreedAssortmentProductListBody(values),
      {
        params: {
          include: 'items',
        },
      },
    )
    .then(({ data }) => new AgreedAssortmentWithItems(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const fetchRttOptions = () =>
  api
    .get<DataWrapped<RttSelectOption[]>>(ENDPOINTS.balanceRttFreeList)
    .then(({ data }) => data.data)
    .catch(e => {
      throw formErrorTransform(e);
    });

/*
 * Agreed Assortments Mappers
 */

function mapAgreedAssortmentGetParams({
  perPage,
  page,
  orderBy,
  order,
  search,
}: Partial<AgreedAssortmentTableParams>) {
  return {
    per_page: perPage,
    page,
    sort: typeof orderBy === 'string' ? snakeCase(orderBy) : undefined,
    order,
    search: (typeof search === 'string' && trim(search)) || undefined,
  };
}

function bulkMapAgreedAssortment(
  distrArray: FetchedAgreedAssortment[],
): AgreedAssortment[] {
  const data = distrArray.map(assortment => new AgreedAssortment(assortment));
  return data;
}
