import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import type { BrandFormValues } from '../types';
import BrandFormFields from './BrandFormFields';
import BrandDetailsForm from './BrandDetailsForm';

type Props = {
  onClose(): void;
};

const CreateBrandContainer: React.FC<Props> = ({ onClose }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.BRANDS_CREATE_REQUEST}
      resolve={types.BRANDS_CREATE_SUCCESS}
      reject={types.BRANDS_CREATE_FAILURE}
    >
      {onSubmit => (
        <Form
          onSubmit={async (values: BrandFormValues) => {
            try {
              await onSubmit(values);
              onClose();

              enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                variant: 'success',
              });

              return undefined;
            } catch (errors) {
              enqueueSnackbar(t('common.snackbars.saveFailed'), {
                variant: 'error',
              });

              return errors;
            }
          }}
          render={props => (
            <BrandDetailsForm
              onClose={onClose}
              heading={t('distributor.brands.add.dialogTitle')}
              submitLabel={t('controls.add')}
              {...props}
            >
              <BrandFormFields />
            </BrandDetailsForm>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default CreateBrandContainer;
