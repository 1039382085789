import { compose, keyBy, mapValues } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useField, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { EmployeeInfo } from '../../../../api/mappers';
import {
  ProductCheckboxList,
  ProductSelectorByRtt,
  selectors as productsSelectors,
  useClearProducts,
} from '../../../../productsAddition';
import { ProductInfo } from '../../../../productsAddition/types';
import AddProductContainer from '../../../../productsAddition/view/Containers/AddProductForRttContainer';
import { selectRttManagerListData } from '../../../../rtt/duck/selectors';
import { Brand, BrandManagerConnection } from '../../../../types';
import { PayloadGroupedKey, PAYLOAD_GROUPED_KEY } from '../../../constants';
import { OrderEditValues, OrderedProduct } from '../../../types';
import {
  NULL_ORDERWEIGHT_DEFAULT_VALUE,
  ORDERED_PRODUCT_QTY_DEFAULT_VALUE,
  ORDERED_PRODUCT_WEIGHT_DEFAULT_VALUE,
  SWITCH_AVAILABLE_DEFAULT_VALUE,
} from '../../../utils/businessLogic';
import { collectProductsFromPayloadByManagerIds } from '../../../utils/orderPayloadTransformers';
import { transformOrderGroupIdKey } from '../transformGroupIdKey';
import { makeProductsPayloadFieldNamePrefix } from './generateFieldName';

interface Props {
  rttId: number;
  disabled?: boolean;
  managerId?: number;
}

const ProductOrderSelector = ({ rttId, managerId, disabled }: Props) => {
  useClearProducts();

  const managerList = useSelector(selectRttManagerListData);

  const brandManagerDictionary = useMemo(
    () =>
      compose<
        [BrandManagerConnection[]],
        Record<Brand['id'], BrandManagerConnection>,
        Record<Brand['id'], EmployeeInfo['id']>
      >(
        // brand for the rtt may hold only one manager - take 1-st item
        mapValues('managerId'),
        keyBy<BrandManagerConnection>('brandId'),
      )(managerList),
    [managerList],
  );

  const {
    input: { value },
  } = useField<OrderEditValues[PayloadGroupedKey]>(PAYLOAD_GROUPED_KEY);
  const form = useForm();

  const productCollection = useSelector(
    productsSelectors.selectMemoizedProductsCollection,
  );

  const valueProductIds = Object.keys(
    collectProductsFromPayloadByManagerIds(value || {}),
  );

  const handleAddProducts = useCallback(
    (productIds: string[]) => {
      const mappedProducts: OrderedProduct[] = productIds.map(productId => {
        const product = productCollection[productId] as ProductInfo;

        const price = product.price ?? 1;
        return {
          ...product,
          price,
          saleMeasurement: product.saleMeasurement ?? 'unit',
          weight: product.weight ?? null,
          isAvailable: SWITCH_AVAILABLE_DEFAULT_VALUE,
          orderWeight: product.weight ?? ORDERED_PRODUCT_WEIGHT_DEFAULT_VALUE,
          qty: ORDERED_PRODUCT_QTY_DEFAULT_VALUE,
          total: product.weight
            ? price
            : price * NULL_ORDERWEIGHT_DEFAULT_VALUE,
          isBrandOperated: true,
        };
      });

      // TODO: implement deleting value if product was unchecked in modal

      form.batch(() => {
        mappedProducts.forEach(product => {
          const productFieldName = `${makeProductsPayloadFieldNamePrefix(
            transformOrderGroupIdKey(
              brandManagerDictionary[product.brandId || 0],
            ),
          )}.${product.id}`;

          const currentFieldState = form.getFieldState(productFieldName);

          if (currentFieldState?.value || currentFieldState?.initial) return;

          form.registerField(
            productFieldName,
            () => undefined,
            {},
            {
              defaultValue: product,
            },
          );
        });
      });
    },
    [productCollection, form, brandManagerDictionary],
  );

  return (
    <ProductSelectorByRtt
      onSubmit={handleAddProducts}
      checkedIds={valueProductIds}
      disabled={disabled}
    >
      <AddProductContainer
        rttId={rttId}
        managerId={managerId}
        renderList={data => (
          <ProductCheckboxList products={data} disabledIds={valueProductIds} />
        )}
      />
    </ProductSelectorByRtt>
  );
};

export default ProductOrderSelector;
