import { Pagination } from '@rtt-libs/types';
import { keyBy } from 'lodash/fp';
import { Reducer } from 'redux';
import { Employee } from '../../api/employees';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: {
    drivers: false,
    managers: false,
    employees: false,
  },
  collection: {} as Record<Employee['id'], Employee>,
  error: null as string | null,
  managerIds: [] as Employee['id'][],
  driverIds: [] as Employee['id'][],
  listIds: [] as Employee['id'][],
  pagination: {} as Pagination,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_MANAGERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, managers: true },
        error: null,
      };
    case types.GET_DRIVERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, drivers: true },
        error: null,
      };
    case types.EMPLOYEES_SEARCH_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, employees: true },
        error: null,
      };
    case types.EMPLOYEES_SEARCH_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, employees: false },
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        listIds: action.payload.map(employee => employee.id),
        pagination: action.meta.pagination,
      };
    case types.GET_MANAGERS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, managers: false },
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        managerIds: action.payload.map(employee => employee.id),
      };
    case types.GET_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, drivers: false },
        collection: { ...state.collection, ...keyBy('id', action.payload) },
        driverIds: action.payload.map(employee => employee.id),
      };
    case types.EMPLOYEES_SEARCH_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, employees: false },
        error: action.payload,
      };
    case types.GET_MANAGERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, managers: false },
        error: action.payload,
      };
    case types.GET_DRIVERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, drivers: false },
        error: action.payload,
      };
    case types.EMPLOYEE_CREATE_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload,
        },
        listIds: [action.payload.id, ...state.listIds],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case types.EMPLOYEE_EDIT_SUCCESS:
    case types.EMPLOYEE_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          // Not replaced because returned value of Employee doesn't contain area info. And there is instanceof check in code.
          [action.payload.id]: Object.assign(action.payload, {
            areaTitles: state.collection[action.payload.id].areaTitles,
            areaId: state.collection[action.payload.id].areaId,
          }),
        },
      };
    default:
      return state;
  }
};

export default reducer;
