import {
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { restrictions } from '@rtt-libs/constants';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import { mapValues } from 'lodash';
import omit from 'lodash/fp/omit';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DiscountProduct } from '../../../api/discounts';
import {
  AddAssortmentProductButtonModal,
  AddAssortmentProductContainer,
  useClearProducts,
} from '../../../productsAddition';
import { selectDiscountProducts } from '../../duck/selectors';

const useStyles = makeStyles(
  createStyles({
    withoutSteppers: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  }),
);

const DiscountProducts: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const discountProducts = useSelector(selectDiscountProducts);

  const [productFields, setProductFields] = useState<
    Record<DiscountProduct['id'], DiscountProduct>
  >({});

  const formApi = useForm();

  useEffect(() => {
    const { productDiscounts = {} } = formApi.getState().initialValues || {};
    setProductFields(prevFields => ({
      ...mapValues(
        productDiscounts,
        (_, id) =>
          discountProducts[id] || ({ id, title: id } as DiscountProduct),
      ),
      ...prevFields,
    }));
  }, [formApi, discountProducts]);

  const existingIds = useMemo(() => Object.keys(productFields), [
    productFields,
  ]);

  const handleClickRemoveDiscount = (itemId: DiscountProduct['id']) => {
    setProductFields(prevProducts => omit([itemId], prevProducts));
    formApi.change(`productDiscounts.${itemId}`);
  };

  const handleAddProduct = (product: DiscountProduct) => {
    setProductFields(prevProducts => ({
      ...prevProducts,
      [product.id]: product,
    }));
  };

  useClearProducts();

  return (
    <>
      <Grid item xs={8}>
        <Typography variant="h6">
          {t('distributor.discounts.productsTitle')}
        </Typography>
      </Grid>

      <Grid item>
        <AddAssortmentProductButtonModal>
          {onClose => (
            <AddAssortmentProductContainer
              existingIds={existingIds}
              onSubmit={handleAddProduct}
              onClose={onClose}
            />
          )}
        </AddAssortmentProductButtonModal>
      </Grid>

      {!Object.values(productFields).length && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t('distributor.discounts.add.emptyProductsMessage')}
          </Typography>
        </Grid>
      )}

      {Object.values(productFields).map(item => (
        <Grid item key={item.id} lg={6} xs={12}>
          <Field
            name={`productDiscounts.${item.id}`}
            component={TextFieldAdapter}
            label={`${t('product.skuContracted')} ${item.sku} - ${item.title}`}
            variant="filled"
            margin="dense"
            type="number"
            parse={value => Number(value)}
            fullWidth
            inputProps={restrictions.DISCOUNT_INPUTS_VALIDATIONS}
            className={classes.withoutSteppers}
            required
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">%</InputAdornment>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t('controls.delete')}
                      onClick={() => handleClickRemoveDiscount(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Grid>
      ))}
    </>
  );
};

export default DiscountProducts;
