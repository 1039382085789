import * as jsts from 'jsts';
import { MutableRefObject } from 'react';
import { Area } from '../../api/territories';
import { GeometryFactoryTypeUpdated } from '../../types';
import { ModifiedPolygons } from './TerritoriesInteraction';

type Path = google.maps.MVCArray<google.maps.LatLng>;

export const createHandlePolygonEdit = (
  path: Path,
  callback: (path: google.maps.LatLngLiteral[]) => void,
) => () => {
  const pathArray = path.getArray().map(coord => ({
    lat: coord.lat(),
    lng: coord.lng(),
  }));

  pathArray.push(pathArray[0]);

  callback(pathArray);
};

const POLYGON_EVENTS = ['set_at', 'insert_at', 'remove_at'];

const geometryFactory = new jsts.geom.GeometryFactory() as GeometryFactoryTypeUpdated;

export const addPathListeners = (
  onChange: (
    path: google.maps.LatLngLiteral[],
    modified: ModifiedPolygons,
  ) => void,
  areasWithJsts: MutableRefObject<
    (Area & {
      polygon: jsts.geom.Polygon;
    })[]
  >,
  callback?: (
    polygon?: google.maps.Polygon,
    listener?: ReturnType<typeof createHandlePolygonEdit>,
  ) => void,
) => (polygon: google.maps.Polygon) => {
  const path = polygon.getPath();

  const handlePolygonEdit = () => {
    const coordinates = [] as jsts.geom.Coordinate[];
    const pathArray = path.getArray().map(coord => {
      coordinates.push(new jsts.geom.Coordinate(coord.lat(), coord.lng()));

      return {
        lat: coord.lat(),
        lng: coord.lng(),
      };
    });

    pathArray.push(pathArray[0]);
    coordinates.push(coordinates[0]);

    const polygonEnhanced = geometryFactory.createPolygon(coordinates);
    const changedAreas = {} as Record<Area['id'], google.maps.LatLngLiteral[]>;

    areasWithJsts.current.forEach(({ id, polygon: polyJsts }) => {
      const isIntersects = polyJsts.intersects(polygonEnhanced);

      if (isIntersects) {
        try {
          const difference = polyJsts.difference(polygonEnhanced);

          changedAreas[id] = difference.getCoordinates().map(coord => ({
            lat: coord.x,
            lng: coord.y,
          }));
        } catch (e) {
          // TODO: handle error.
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    });

    const changedCount = Object.keys(changedAreas).length;

    let deleted = undefined as number[] | undefined;
    let changed = undefined as
      | Record<number, google.maps.LatLngLiteral[]>
      | undefined;

    if (changedCount) {
      Object.entries(changedAreas).forEach(([keyString, value]) => {
        const key = parseInt(keyString, 10);
        if (value.length) {
          changed = changed || {};
          changed[key] = value;
        } else {
          deleted = deleted ? deleted.concat(key) : [key];
        }
      });
    }

    onChange(pathArray, { changed, deleted });
  };

  const eventListeners = [] as google.maps.MapsEventListener[];

  POLYGON_EVENTS.forEach(event => {
    const listener = path.addListener(event, handlePolygonEdit);
    eventListeners.push(listener);
  });

  const removeListeners = () => {
    eventListeners.forEach(listener => listener.remove());
  };

  if (typeof callback === 'function') callback(polygon, removeListeners);
};
