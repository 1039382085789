import { Box, Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ExportStatusValue } from '../types';

type Props = {
  disabled?: boolean;
  onChange: (value: boolean) => void;
  status?: ExportStatusValue;
  error?: string;
  indeterminate?: boolean;
  uncontrolled?: boolean;
  loadingTooltip?: string;
};

const ExportCell: React.FC<Props> = ({
  disabled,
  onChange,
  status,
  error,
  indeterminate,
  uncontrolled,
  loadingTooltip,
}) => {
  const [t] = useTranslation();

  if (status === 'processing')
    return (
      <Box p={1}>
        <Tooltip
          title={
            (loadingTooltip || t('distributor.export.status.processing')) ?? ''
          }
          placement="right"
        >
          <CircularProgress size={20} />
        </Tooltip>
      </Box>
    );

  const isExported = status === 'done';

  const handleChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked);
    },
    uncontrolled ? 250 : 0,
  );

  const renderCheckbox = (
    <Checkbox
      onChange={handleChange}
      onClick={e => e.stopPropagation()}
      defaultChecked={uncontrolled ? isExported : undefined}
      checked={uncontrolled ? undefined : isExported}
      disabled={disabled}
      color={error ? 'default' : 'primary'}
      inputProps={{ 'aria-label': t('distributor.export.checkboxLabel') }}
      indeterminate={indeterminate}
    />
  );

  return (
    <Box
      onClick={e => {
        if (disabled) {
          e.stopPropagation();
        }
      }}
    >
      {error ? (
        <Tooltip title={error} placement="right">
          {renderCheckbox}
        </Tooltip>
      ) : (
        renderCheckbox
      )}
    </Box>
  );
};

export default ExportCell;
