import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import {
  ColumnConfig,
  PropsForFilterRow,
  TableWithHistory,
} from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type * as OrderTypes from '../../../types';

type Props = {
  orders: OrderTypes.Order[] | OrderTypes.ReturnOrder[];
  pagination: Pagination;
  loading: boolean;
  onRowClick: (orderId: string) => void;
  columnConfig: ColumnConfig[];
  initialStatus?: OrderTypes.OrderSearchParams;
  statusGroup?: string;
  onStateChange: (state: OrderTypes.OrderSearchParams) => void;
  filterRow?(props: PropsForFilterRow): React.ReactElement;
};

/**
 * Wrapper component to pass orders data into common table component
 * Also handles routing to Order detail page.
 */
const OrdersTable: React.FC<Props> = ({
  onRowClick,
  orders,
  pagination,
  columnConfig,
  loading = false,
  initialStatus,
  statusGroup,
  onStateChange,
  filterRow,
}) => {
  const [t] = useTranslation();

  const { currentPage = 1 } = pagination;

  const initialParams = useMemo(
    () => ({
      perPage: table.PER_PAGE,
      page: currentPage,
      status: initialStatus?.status,
    }),
    [currentPage, initialStatus],
  );

  const localeTableLabels = {
    sort: t('admin.tables.common.sort', 'Sort'),
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <TableWithHistory
      key={statusGroup}
      filterRow={filterRow}
      statusGroup={statusGroup}
      loading={loading}
      entries={(orders as unknown) as Record<string, unknown>[]}
      columnsConfig={columnConfig}
      onRowClick={onRowClick}
      perPageOptions={table.PAGINATION_OPTIONS}
      initial={initialParams}
      totalEntriesCount={pagination.total}
      localeLabels={localeTableLabels}
      onStateChange={onStateChange}
      status={initialStatus?.status}
    />
  );
};

export default OrdersTable;
