import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { Brand } from '../../../../types';

type BrandListWithClickCallbackProps = {
  data: Brand[];
  existingIds: number[];
  onClick(brand: Brand): void;
};

const BrandListWithClickCallback = ({
  data,
  existingIds,
  onClick,
}: BrandListWithClickCallbackProps) => {
  return (
    <List>
      {data.map(brand => {
        const labelId = `brand-${brand.id}`;

        return (
          <ListItem
            key={brand.id}
            role={undefined}
            dense
            button
            onClick={() => onClick(brand)}
            disabled={existingIds.includes(brand.id)}
          >
            <ListItemText id={labelId} primary={brand.title} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default BrandListWithClickCallback;
