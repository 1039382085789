import { FORM_ERROR } from 'final-form';
import type { Brand } from '../../types';
import type {
  BrandChangeStatusValues,
  BrandEditFormValues,
  BrandFormValues,
  BrandSearchParams,
} from '../types';
import * as types from './types';

export const getBrandsRequest = (params?: Partial<BrandSearchParams>) => ({
  type: types.BRANDS_GET_REQUEST,
  payload: params,
});

export const getBrandsSuccess = (payload: Brand[]) => ({
  type: types.BRANDS_GET_SUCCESS,
  payload,
});

export const getBrandsFailure = (error: string) => ({
  type: types.BRANDS_GET_FAILURE,
  payload: error,
});

export const getBrandsOptionsRequest = (params: {
  isActive?: boolean;
  showAll?: boolean;
}) => ({
  type: types.BRANDS_GET_OPTIONS_REQUEST,
  payload: params,
});

export const getBrandsOptionsSuccess = (payload: Brand[]) => ({
  type: types.BRANDS_GET_OPTIONS_SUCCESS,
  payload,
});

export const getBrandsOptionsFailure = (error: string) => ({
  type: types.BRANDS_GET_OPTIONS_FAILURE,
  payload: error,
});

export const createBrandRequest = (payload: BrandFormValues) => ({
  type: types.BRANDS_CREATE_REQUEST,
  payload,
});

export const createBrandSuccess = (payload: Brand) => ({
  type: types.BRANDS_CREATE_SUCCESS,
  payload,
});

export const createBrandFailure = (
  submissionErrors?: Partial<BrandFormValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.BRANDS_CREATE_FAILURE,
  payload: submissionErrors,
});

export const editBrandRequest = (payload: BrandEditFormValues) => ({
  type: types.BRANDS_EDIT_REQUEST,
  payload,
});

export const editBrandSuccess = (payload: Brand) => ({
  type: types.BRANDS_EDIT_SUCCESS,
  payload,
});

export const editBrandFailure = (
  submissionErrors?: Partial<BrandEditFormValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.BRANDS_EDIT_FAILURE,
  payload: submissionErrors,
});

export const changeStatusBrandRequest = (payload: BrandChangeStatusValues) => ({
  type: types.BRANDS_CHANGE_STATUS_REQUEST,
  payload,
});

export const changeStatusBrandSuccess = (payload: Brand) => ({
  type: types.BRANDS_CHANGE_STATUS_SUCCESS,
  payload,
});

export const changeStatusBrandFailure = (error: string) => ({
  type: types.BRANDS_CHANGE_STATUS_FAILURE,
  payload: error,
});

export const cleanUpBrands = () => ({
  type: types.BRANDS_CLEAN_UP,
});

export type BrandActions = ReturnType<
  | typeof getBrandsRequest
  | typeof getBrandsSuccess
  | typeof getBrandsFailure
  | typeof getBrandsOptionsRequest
  | typeof getBrandsOptionsSuccess
  | typeof getBrandsOptionsFailure
  | typeof createBrandRequest
  | typeof createBrandSuccess
  | typeof createBrandFailure
  | typeof editBrandRequest
  | typeof editBrandSuccess
  | typeof editBrandFailure
  | typeof changeStatusBrandRequest
  | typeof changeStatusBrandSuccess
  | typeof changeStatusBrandFailure
  | typeof cleanUpBrands
>;
