import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CategoryItemId } from '@rtt-libs/types';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCategoryOptionsSel } from '../../duck/selectors';
import CategoriesDialog from '../CategoriesDialog';
import BrandAutocompleteField from './BrandAutocompleteField';

type Props = {
  changeInput: FormApi['change'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(4),
    },
    badgeLike: {
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(1),
      backgroundColor: '#334D6E',
      borderRadius: 4,
      fontSize: 11,
      color: theme.palette.primary.contrastText,
    },
    input: {
      margin: theme.spacing(1, 0),
    },
  }),
);

const MainProductDetails: React.FC<Props> = ({ changeInput }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [categoryOpen, setCategoryOpen] = useState(false);
  const handleCategoriesTreeOpen = () => {
    setCategoryOpen(true);
  };
  const handleCategoriesTreeClose = () => {
    setCategoryOpen(false);
  };

  const categoryOptions = useSelector(getCategoryOptionsSel);

  return (
    <>
      <Typography variant="h5" paragraph>
        {t('product.mainInfoTitle')}
      </Typography>
      <Field
        name="title"
        label={t('product.title')}
        multiline
        required
        component={TextFieldAdapter}
        fullWidth
        className={classes.input}
      />
      <Grid container justify="space-between" alignItems="flex-end">
        <Grid item>
          <Field
            name="categoryId"
            label={t('product.category')}
            required
            component={TextFieldAdapter}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            className={classes.input}
            format={(id: CategoryItemId) => categoryOptions[id] || ''}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            type="button"
            onClick={handleCategoriesTreeOpen}
            variant="outlined"
          >
            {t('product.chooseCategory')}
          </Button>
        </Grid>
        <CategoriesDialog
          open={categoryOpen}
          onClick={(id: CategoryItemId) => {
            changeInput('categoryId', id);
          }}
          onClose={handleCategoriesTreeClose}
        />
      </Grid>

      <BrandAutocompleteField />

      <Field
        name="manufacturer"
        label={t('product.manufacturer')}
        required
        component={TextFieldAdapter}
        fullWidth
        className={classes.input}
      />
      <Field
        name="description"
        label={t('product.description')}
        multiline
        variant="outlined"
        rows={3}
        component={TextFieldAdapter}
        fullWidth
        className={classes.input}
      />
    </>
  );
};

export default MainProductDetails;
