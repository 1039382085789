import { Paper } from '@material-ui/core';
import { EmptyAlert, EnhancedPaginatedTable } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SupervisorManager } from '../types';
import supervisorManagersTableConfig from './tableConfig';

type Props = {
  entries: SupervisorManager[];
  loading?: boolean;
};

/**
 * Wrapper component to pass SupervisorManager data into common table component
 */
const SupervisorManagersTable: React.FC<Props> = ({ entries, loading }) => {
  const [t] = useTranslation();

  const columnConfig = supervisorManagersTableConfig(t);

  return (
    <Paper>
      <EnhancedPaginatedTable
        loading={loading}
        entries={(entries as unknown) as Record<string, unknown>[]}
        columnsConfig={columnConfig}
        withoutPagination
        emptyMessage={<EmptyAlert />}
      />
    </Paper>
  );
};

export default SupervisorManagersTable;
