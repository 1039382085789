export const GET_PRICE_TYPES_REQUEST = `@rtt[D]/GET_PRICE_TYPES_REQUEST` as const;
export const GET_PRICE_TYPES_SUCCESS = `@rtt[D]/GET_PRICE_TYPES_SUCCESS` as const;
export const GET_PRICE_TYPES_FAILURE = `@rtt[D]/GET_PRICE_TYPES_FAILURE` as const;

export const CREATE_PRICE_TYPES_REQUEST = `@rtt[D]/CREATE_PRICE_TYPES_REQUEST` as const;
export const CREATE_PRICE_TYPES_RESPONSE = `@rtt[D]/CREATE_PRICE_TYPES_RESPONSE` as const;
export const CREATE_PRICE_TYPES_SUCCESS = `@rtt[D]/CREATE_PRICE_TYPES_SUCCESS` as const;
export const CREATE_PRICE_TYPES_FAILURE = `@rtt[D]/CREATE_PRICE_TYPES_FAILURE` as const;

export const DELETE_PRICE_TYPES_REQUEST = `@rtt[D]/DELETE_PRICE_TYPES_REQUEST` as const;
export const DELETE_PRICE_TYPES_RESPONSE = `@rtt[D]/DELETE_PRICE_TYPES_RESPONSE` as const;
export const DELETE_PRICE_TYPES_SUCCESS = `@rtt[D]/DELETE_PRICE_TYPES_SUCCESS` as const;
export const DELETE_PRICE_TYPES_FAILURE = `@rtt[D]/DELETE_PRICE_TYPES_FAILURE` as const;

export const EDIT_PRICE_TYPES_REQUEST = `@rtt[D]/EDIT_PRICE_TYPES_REQUEST` as const;
export const EDIT_PRICE_TYPES_RESPONSE = `@rtt[D]/EDIT_PRICE_TYPES_RESPONSE` as const;
export const EDIT_PRICE_TYPES_SUCCESS = `@rtt[D]/EDIT_PRICE_TYPES_SUCCESS` as const;
export const EDIT_PRICE_TYPES_FAILURE = `@rtt[D]/EDIT_PRICE_TYPES_FAILURE` as const;

export const SEARCH_PRICE_TYPES_REQUEST = `@rtt[D]/SEARCH_PRICE_TYPES_REQUEST` as const;
export const SEARCH_PRICE_TYPES_SUCCESS = `@rtt[D]/SEARCH_PRICE_TYPES_SUCCESS` as const;
export const SEARCH_PRICE_TYPES_FAILURE = `@rtt[D]/SEARCH_PRICE_TYPES_FAILURE` as const;

export const SET_RTT_PRICE_TYPES_REQUEST = '@rtt[D]/SET_RTT_PRICE_TYPES_REQUEST' as const;
export const SET_RTT_PRICE_TYPES_SUCCESS = '@rtt[D]/SET_RTT_PRICE_TYPES_SUCCESS' as const;
export const SET_RTT_PRICE_TYPES_FAILURE = '@rtt[D]/SET_RTT_PRICE_TYPES_FAILURE' as const;
