import { SwitchAdapter } from '@rtt-libs/views';
import React from 'react';
import { Field } from 'react-final-form-html5-validation';

type Props = {
  id: string | number | symbol;
  value?: unknown;
  fieldNamePrefix?: string;
  disabled?: boolean;
};

const SwitchAvailableCell: React.FC<Props> = ({
  id,
  fieldNamePrefix = '',
  disabled,
}) => {
  const productId = id.toString();

  return (
    <Field
      name={`${fieldNamePrefix}.${productId}.isAvailable`}
      component={SwitchAdapter}
      disabled={disabled}
      type="checkbox"
    />
  );
};

export default SwitchAvailableCell;
