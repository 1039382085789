import { Grid, InputAdornment, TextField, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ux } from '@rtt-libs/constants';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AssortmentGetParams } from '../../api/assortment';
import CreateLink from '../../common/orders/CreateLink';
import routes from '../../pages/routes';

type Props = {
  onChange(params: Partial<AssortmentGetParams>): void;
  initialFilterValue: Record<string, string>;
};

/**
 * Component to handle filtering of products data.
 */
const FilterRow: React.FC<Props> = ({ onChange, initialFilterValue }) => {
  const { search = '' } = initialFilterValue;

  const [t] = useTranslation();

  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({
      ...initialFilterValue,
      search: e.target.value || undefined,
      page: 1,
    });
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <TextField
          defaultValue={search}
          name="search"
          type="search"
          label={t('common.search', 'Search')}
          placeholder={t(
            'distributor.assortment.searchPlaceholder',
            'Search by title, manufacturer or sku',
          )}
          onChange={onSearchChange}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        alignSelf="center"
        clone
      >
        <Grid item xs={12} md={4}>
          <Box clone width={160}>
            <CreateLink to={routes.productNew} />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default FilterRow;
