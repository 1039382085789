import { getAuthToken } from '@rtt-libs/auth';
import { Reducer, useMemo, useReducer, useRef } from 'react';
import { BASE_API_URL } from '../../environment';
import useResumable from './useResumable';

const initialUploadState = {
  error: undefined as [string, string] | undefined,
  progress: null as null | number,
  isCompleted: false,
};

type UploadAction =
  | {
      type: 'processing';
      progress: number;
    }
  | {
      type: 'completed' | 'reset';
    }
  | {
      type: 'failure';
      error: [string, string];
    };

const uploadReducer: Reducer<typeof initialUploadState, UploadAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'processing':
      return state.error
        ? {
            progress: null,
            isCompleted: false,
            error: state.error,
          }
        : {
            isCompleted: false,
            progress: action.progress,
            error: undefined,
          };
    case 'completed':
      return {
        isCompleted: true,
        progress: null,
        error: undefined,
      };
    case 'failure':
      return {
        progress: null,
        isCompleted: false,
        error: action.error,
      };
    case 'reset':
      return state.error ? initialUploadState : state;

    default:
      throw new Error('wrong action type');
  }
};

const useUploadFile = <T extends HTMLElement = HTMLElement>(
  target: string | undefined,
) => {
  const browseRef = useRef<HTMLElement>(null);
  const dropZoneRef = useRef<T>(null);

  // variables wrapped by useMemo to prevent updates in useEffects in useResumable.
  const assignRefs = useMemo(
    () => ({
      browse: browseRef,
      drop: dropZoneRef,
    }),
    [browseRef, dropZoneRef],
  );

  const options = useMemo(
    () => ({
      target: target && `${BASE_API_URL}/${target}`,
      maxFiles: 1,
      maxChunkRetries: 5,
      // Currently backend doesn't handle chunk check.
      testChunks: false,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      // Do not set greater then 3MB, backend cant handle it.
      chunkSize: 3 * 1024 * 1024,
      forceChunkSize: true,
      // added 422 status code to handle backend complexity to throw 415
      permanentErrors: [400, 404, 409, 415, 422, 500, 501],
      fileType: ['application/vnd.ms-excel', 'xls', 'xlsx'],
      // Added file extension if system can't determine fileType & backend has validation on the field
      query(file: Resumable.ResumableFile) {
        return {
          resumableType:
            file.file.type /* is default value */ ||
            file.fileName.substr(file.fileName.lastIndexOf('.') + 1),
        };
      },
    }),
    [target],
  );

  const [state, dispatch] = useReducer(uploadReducer, initialUploadState);

  // Added to handle onComplete callback fired after error
  const errorOccurred = useRef<boolean>(false);

  const eventHandlers = useMemo<Parameters<typeof useResumable>[2]>(() => {
    return {
      onProgress(resumable) {
        if (!errorOccurred.current) {
          dispatch({
            type: 'processing',
            progress: Math.floor(resumable.progress() * 100),
          });
        }
      },
      onFileAdded() {
        dispatch({ type: 'reset' });
        errorOccurred.current = false;
      },
      onComplete() {
        if (!errorOccurred.current) {
          dispatch({ type: 'completed' });
        }
      },
      onError(e, file) {
        dispatch({
          type: 'failure',
          error: [file.fileName, e],
        });
        errorOccurred.current = true;
      },
      onFileRetry() {
        errorOccurred.current = false;
      },
    };
  }, [dispatch, errorOccurred]);

  useResumable(options, assignRefs, eventHandlers);

  return {
    browseRef,
    dropZoneRef,
    ...state,
  };
};

export default useUploadFile;
