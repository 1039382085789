export const GET_SETTINGS_EXPORT_REQUEST = `@rtt[D]/GET_SETTINGS_EXPORT_REQUEST` as const;
export const GET_SETTINGS_EXPORT_SUCCESS = `@rtt[D]/GET_SETTINGS_EXPORT_SUCCESS` as const;
export const GET_SETTINGS_EXPORT_FAILURE = `@rtt[D]/GET_SETTINGS_EXPORT_FAILURE` as const;

export const GET_SETTINGS_ORDERS_REQUEST = `@rtt[D]/GET_SETTINGS_ORDERS_REQUEST` as const;
export const GET_SETTINGS_ORDERS_SUCCESS = `@rtt[D]/GET_SETTINGS_ORDERS_SUCCESS` as const;
export const GET_SETTINGS_ORDERS_FAILURE = `@rtt[D]/GET_SETTINGS_ORDERS_FAILURE` as const;

export const EDIT_SETTINGS_EXPORT_REQUEST = `@rtt[D]/EDIT_SETTINGS_EXPORT_REQUEST` as const;
export const EDIT_SETTINGS_EXPORT_SUCCESS = `@rtt[D]/EDIT_SETTINGS_EXPORT_SUCCESS` as const;
export const EDIT_SETTINGS_EXPORT_RESPONSE = `@rtt[D]/EDIT_SETTINGS_EXPORT_RESPONSE` as const;
export const EDIT_SETTINGS_EXPORT_FAILURE = `@rtt[D]/EDIT_SETTINGS_EXPORT_FAILURE` as const;

export const EDIT_SETTINGS_ORDERS_REQUEST = `@rtt[D]/EDIT_SETTINGS_ORDERS_REQUEST` as const;
export const EDIT_SETTINGS_ORDERS_SUCCESS = `@rtt[D]/EDIT_SETTINGS_ORDERS_SUCCESS` as const;
export const EDIT_SETTINGS_ORDERS_RESPONSE = `@rtt[D]/EDIT_SETTINGS_ORDERS_RESPONSE` as const;
export const EDIT_SETTINGS_ORDERS_FAILURE = `@rtt[D]/EDIT_SETTINGS_ORDERS_FAILURE` as const;
