import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { AssignedManager } from '../../types';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const AssignedManagersCell = ({ value }: Props) => {
  const managerList = value as AssignedManager[];

  return (
    <List dense>
      {managerList.map(({ id, firstName, lastName, isActive }) => (
        <ListItem key={id} disabled={!isActive}>
          <ListItemText>{`${firstName}\u00A0${lastName}`}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default AssignedManagersCell;
