import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useAuth } from '@rtt-libs/auth';
import type { OrderStatusValue } from '@rtt-libs/types';
import { FilterOption } from '@rtt-libs/views';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSnackbarError from '../../../common/useSnackbarError';
import routes from '../../../pages/routes';
import { PERMISSIONS } from '../../../rules';
import { getReturnOrdersRequest } from '../../duck/actions';
import { selectReturns } from '../../duck/selectors';
import type { OrderSearchParams } from '../../types';
import { OrdersTable } from '../commonOrders';
import FilterRow from '../commonOrders/OrdersTable/FilterRow';
import CreateReturnOrderLink from '../ReturnOrderCreate/CreateReturnOrderLink';
import returnOrdersTableConfig from './tableConfig';

type Props = {
  initialParams?: OrderSearchParams;
  statusGroup?: string;
};

/**
 * Container to fetch & filter ReturnOrders & render them in table.
 */
const ReturnOrderListContainer: React.FC<Props> = ({
  initialParams,
  statusGroup,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPermittedTo } = useAuth();

  const { data, pagination, loading, error } = useSelector(selectReturns);

  useSnackbarError(error);

  const onTableStateChange = useCallback(
    (options: OrderSearchParams) => {
      dispatch(getReturnOrdersRequest(options));
    },
    [dispatch],
  );

  const onRowClick = (orderId: string) => {
    history.push(`${routes.returns}/${orderId}`);
  };

  const [t] = useTranslation();
  const columnConfig = useMemo(() => returnOrdersTableConfig(t), [t]);

  const statusOptions: FilterOption<OrderStatusValue>[] = [
    {
      value: 'new',
      label: t('distributor.tables.orders.filters.new'),
    },
    {
      value: 'processing',
      label: t('distributor.tables.orders.filters.processing'),
    },
    {
      value: 'canceled',
      label: t('distributor.tables.orders.filters.canceled'),
    },
    {
      value: 'rejected',
      label: t('distributor.tables.orders.filters.rejected'),
    },
    {
      value: 'submit',
      label: t('distributor.tables.orders.filters.submit'),
    },
  ];

  const renderEndElement = isPermittedTo(PERMISSIONS.NAV_RETURN_NEW) ? (
    <CreateReturnOrderLink />
  ) : null;

  return (
    <OrdersTable
      filterRow={({ onChange, displayOptions }) => (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <FilterRow
            baseRoute={routes.returns}
            endElement={renderEndElement}
            onChange={onChange}
            statusOptions={statusOptions}
            initialFilterValues={displayOptions}
          />
        </MuiPickersUtilsProvider>
      )}
      initialStatus={initialParams}
      statusGroup={statusGroup}
      orders={data}
      pagination={pagination}
      loading={loading}
      onRowClick={onRowClick}
      columnConfig={columnConfig}
      onStateChange={onTableStateChange}
    />
  );
};

export default ReturnOrderListContainer;
