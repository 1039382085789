import React from 'react';
import { RecommendedOrderValue } from '../../../balances/types';
import CreateLink from '../../../common/orders/CreateLink';
import routes from '../../../pages/routes';
import { GroupedPayload } from '../../types';

export type CreateOrderLocationStateState = {
  rttId?: number;
  orderValues?: GroupedPayload<RecommendedOrderValue>;
};

const CreateOrderLink: React.FC<CreateOrderLocationStateState> = ({
  rttId,
  orderValues,
  ...rest
}) => {
  return (
    <CreateLink
      to={{ pathname: routes.orderNew, state: { rttId, orderValues } }}
      {...rest}
    />
  );
};

export default CreateOrderLink;
