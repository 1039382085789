import { CategoryItem } from '@rtt-libs/types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import getCategories, {
  getAllCategories,
  getCategoryList,
} from '../../api/categories';
import * as actions from './actions';
import * as TYPES from './types';

function* worker() {
  try {
    const data: Record<string | number, CategoryItem> = yield call(
      getCategories,
    );

    yield all([put(actions.getCategoriesSuccess(data))]);
  } catch (e) {
    yield put(actions.getCategoriesFailure(e.message));
  }
}

function* getAllCategoriesWorker() {
  try {
    const data: Record<string | number, CategoryItem> = yield call(
      getAllCategories,
    );

    yield all([put(actions.getAllCategoriesSuccess(data))]);
  } catch (e) {
    yield put(actions.getAllCategoriesFailure(e.message));
  }
}

function* availableWorker() {
  try {
    const data: Record<string | number, CategoryItem> = yield call(
      getCategoryList,
    );

    yield all([put(actions.getAvailableCategoriesSuccess(data))]);
  } catch (e) {
    yield put(actions.getCategoriesFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TYPES.CATEGORIES_REQUEST, worker);
  yield takeLatest(TYPES.ALL_CATEGORIES_REQUEST, getAllCategoriesWorker);
  yield takeLatest(TYPES.CATEGORIES_AVAILABLE_REQUEST, availableWorker);
}
