import { DeliveryWeek } from '@rtt-libs/types';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export function deriveClosestDay(
  /** Day of Week (Monday as 0, Sunday as 6) by default for uk/ru locales */
  schema: DeliveryWeek,
): string {
  // Sun 0, Mon - 1
  const todayDayOfWeek = dayjs().day();

  const nextWeekday = findNextDay(schema, todayDayOfWeek);

  return dayjs().weekday(nextWeekday).format('YYYY-MM-DD');
}

function findNextDay(
  deliveryWeek: DeliveryWeek,
  currentWeekday: number,
): number {
  const currentWeekdayStartedMon = (currentWeekday + 6) % 7;

  // if deliveryWeek is filled by false set as tomorrow
  if (deliveryWeek.every(v => !v)) {
    return currentWeekdayStartedMon + 1;
  }

  const doubleWeek = deliveryWeek.concat(deliveryWeek);
  // make unavailable for deliver to past and today
  doubleWeek.fill(false, 0, currentWeekdayStartedMon);

  const nextWeekDay = doubleWeek.findIndex(value => value);

  // handle unexpected behavior when all values ov doubledWeek set to false
  if (nextWeekDay === -1) {
    return currentWeekdayStartedMon + 1;
  }

  return nextWeekDay;
}
