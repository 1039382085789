import { OrderStatusValue } from '@rtt-libs/types';
import { FormApi } from 'final-form';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { StatusValue } from '../../../api/orders';
import useSnackbarError from '../../../common/useSnackbarError';
import { isUnableToSetOrderSubmitStatus } from '../../utils/businessLogic';
import { OrderEditValues } from '../../types';

type HoistedFormContextType<T extends object> = {
  form: FormApi<T> | undefined;
  setForm: React.Dispatch<React.SetStateAction<FormApi<T> | undefined>>;
};

const HoistedFormContext = React.createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HoistedFormContextType<any> | undefined
>(undefined);

export const useHoistedForm = () => {
  const hoisted = useContext(HoistedFormContext);

  if (!hoisted) {
    throw new Error('useHoistedForm: empty context');
  }

  return hoisted;
};

export const HoistedFormProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [form, setForm] = useState<FormApi<any> | undefined>(undefined);

  return (
    <HoistedFormContext.Provider value={{ form, setForm }}>
      {children}
    </HoistedFormContext.Provider>
  );
};

export const FormHoister = () => {
  const form = useForm();
  const { setForm } = useHoistedForm();

  setForm(form);

  return null;
};

export const useSubmitBeforeAction = (
  actionFn: (status: StatusValue) => void,
) => {
  const [t] = useTranslation();
  const { form } = useHoistedForm();
  const displayError = useSnackbarError();

  const [confirmState, setConfirmState] = useState<{
    open: boolean;
    message?: string;
    onConfirm?: () => void;
    loading: boolean;
  }>(() => ({ open: false, loading: false }));

  useEffect(() => {
    return form?.subscribe(
      newState => {
        setConfirmState(prev => ({ ...prev, loading: newState.submitting }));
      },
      { submitting: true },
    );
  }, [form, setConfirmState]);

  const onClose = useCallback(() => {
    setConfirmState(prev => ({ ...prev, open: false }));
  }, [setConfirmState]);

  // XXX: here is a place to upgrade logic of disabled statuses based on form state.
  // Change to array of status values to handle multiple statuses
  const [disabledOrderStatus, setDisabledOrderStatus] = useState<
    OrderStatusValue | undefined
  >();

  useEffect(() => {
    return form?.subscribe(
      newState => {
        setDisabledOrderStatus(
          (newState.errors as Partial<OrderEditValues>).shippingDate
            ? 'submit'
            : undefined,
        );
      },
      { errors: true },
    );
  }, [form, setDisabledOrderStatus]);

  const onChange = useCallback(
    (status: StatusValue) => {
      const formState = form?.getState();

      if (!['submit', 'rejected'].includes(status)) {
        actionFn(status);
        return undefined;
      }

      const isSubmitStatusInvalid =
        status === 'submit' &&
        formState &&
        isUnableToSetOrderSubmitStatus(formState.errors);

      if (isSubmitStatusInvalid) {
        // call submit to highlight validation errors
        // eslint-disable-next-line no-unused-expressions
        form?.submit();
        onClose();
        displayError(
          t('distributor.orders.statusChange.cantChangeStatusError'),
        );
        return undefined;
      }

      const onConfirm = () => {
        actionFn(status);
        onClose();
      };

      if (formState?.hasValidationErrors) {
        setConfirmState({
          onConfirm,
          open: true,
          message: t('distributor.orders.statusChange.invalidFormMessage'),
          loading: false,
        });

        // call submit to highlight validation errors
        // eslint-disable-next-line no-unused-expressions
        form?.submit();
        return undefined;
      }

      if (formState?.dirtySinceLastSubmit || formState?.dirty) {
        const onDirtyConfirm = () => {
          setConfirmState(state => ({ ...state, loading: true }));
          // eslint-disable-next-line no-unused-expressions
          form?.submit()?.then(errors => {
            if (errors) {
              setConfirmState({
                onConfirm,
                open: true,
                message: t(
                  'distributor.orders.statusChange.formSaveErrorMessage',
                ),
                loading: false,
              });
            } else {
              onConfirm();
            }
          });
        };

        setConfirmState({
          onConfirm: onDirtyConfirm,
          open: true,
          message: t('distributor.orders.statusChange.dirtyFormMessage'),
          loading: false,
        });

        return undefined;
      }

      actionFn(status);
      return undefined;
    },
    [t, setConfirmState, form, actionFn, onClose, displayError],
  );

  return {
    form,
    onChange,
    onClose,
    confirmState,
    disabledOrderStatus,
  };
};
