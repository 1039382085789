import isEqual from 'lodash/isEqual';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { PAYLOAD_GROUPED_KEY } from '../../../constants';
import { makeGroupedFieldName } from './generateFieldName';

const DeepEqualCheckFields = () => {
  const { initialValues } = useFormState();
  return (
    <>
      {/* XXX: Prevent dirty state when nested object values changed */}
      <Field
        name={PAYLOAD_GROUPED_KEY}
        component="input"
        type="hidden"
        isEqual={isEqual}
      />

      {Object.keys(initialValues[PAYLOAD_GROUPED_KEY]).map(key => {
        return (
          <Field
            name={makeGroupedFieldName(key)}
            component="input"
            type="hidden"
            isEqual={isEqual}
          />
        );
      })}
    </>
  );
};

export default DeepEqualCheckFields;
