/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { filter, flow } from 'lodash/fp';
import type {
  Brand as IBrand,
  BrandManagerConnection as IBrandManagerConnection,
  BrandManagerValues,
} from '../types';

export { transformSimpleErrorMessage } from '@rtt-libs/api-services';

export interface Brand extends IBrand {}
export interface BrandManagerConnection extends IBrandManagerConnection {}

export interface FetchedBrandAPI {
  id: number;
  title: string;
  is_active: boolean;
}

export type EmployeeInfo = {
  id: number;
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
};

export type BrandManagerListItemAPI = {
  id: number;
  area_id: number;
  brand_id: number;
  manager_id: number;
  brand: FetchedBrandAPI;
  manager: EmployeeInfo;
};

export class Brand {
  constructor(fetched: FetchedBrandAPI) {
    this.id = fetched.id;
    this.title = fetched.title;
    this.isActive = fetched.is_active;
  }
}

export class BrandManagerConnection {
  constructor({
    id,
    brand_id,
    manager_id,
    manager,
    brand,
  }: BrandManagerListItemAPI) {
    this.id = id;
    this.brandId = brand_id;
    this.managerId = manager_id;
    this.manager = {
      id: manager.id ?? manager_id,
      email: manager.email,
      phone: manager.phone,
      firstName: manager.first_name,
      lastName: manager.last_name,
    };
    this.brand = new Brand(brand);
  }
}

export type ManagerListEditItemAPI = {
  brand_id: number;
  manager_id: number | null;
};

export const mapBrandManagerValuesToAPIFormat = (
  managerList: BrandManagerValues['managerList'] = [],
) =>
  managerList.reduce((prev, manager_id, brand_id) => {
    return prev.concat({ brand_id, manager_id: manager_id || null });
  }, [] as ManagerListEditItemAPI[]);

export const mapBrandManagerValuesToAPIFormatOnlyAssigned = flow(
  mapBrandManagerValuesToAPIFormat,
  filter<ManagerListEditItemAPI>('manager_id'),
);
