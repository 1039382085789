import type {
  ExportChannel,
  ExportResult,
  ExportResultType,
  ExportStatusValue,
  ExportDocumentFilterParams,
} from '../types';
import * as types from './types';
import { ExportDocument } from '../../api/exports';

/*
 * Export statuses for single item
 */
export const setEntryExportStatusRequest = (
  payload: {
    id: string;
    isExported: boolean;
  },
  meta: { type: ExportResultType },
) => ({
  type: types.EXPORTS_STATUS_SET_REQUEST,
  payload,
  meta,
});

export const setEntryExportStatusSuccess = (
  payload: {
    id: string;
    exportStatus: ExportStatusValue;
  },
  meta: { type: ExportResultType },
) => ({
  type: types.EXPORTS_STATUS_SET_SUCCESS,
  payload,
  meta,
});

export const setEntryExportStatusFailure = (
  payload: {
    id: string;
    error: string;
  },
  meta: { type: ExportResultType },
) => ({
  type: types.EXPORTS_STATUS_SET_FAILURE,
  payload,
  meta,
});

/*
 * Multi set export status
 */
export const setMultiExportStatusRequest = (
  list: ExportDocument[],
  isExported: boolean,
) => ({
  type: types.EXPORTS_STATUS_MULTI_SET_REQUEST,
  payload: { list, isExported },
});

export const setMultiExportStatusSuccess = (
  ids: ExportDocument['id'][],
  isExported: boolean,
) => ({
  type: types.EXPORTS_STATUS_MULTI_SET_SUCCESS,
  payload: { ids, isExported },
});

export const setMultiExportStatusFailure = (
  error: string,
  ids: ExportDocument['id'][],
  isExported: boolean,
) => ({
  type: types.EXPORTS_STATUS_MULTI_SET_FAILURE,
  payload: { ids, isExported },
  meta: { error },
});

/*
 * Current export status
 */
export const getExportStatusRequest = (
  type: ExportResultType,
  id?: string,
  saveFile?: boolean,
) => ({
  type: types.EXPORTS_STATUS_GET_REQUEST,
  payload: type,
  meta: { id, saveFile },
});

export const getExportStatusSuccess = (payload: ExportResult) => ({
  type: types.EXPORTS_STATUS_GET_SUCCESS,
  payload,
});

export const getExportStatusFailure = (error: string) => ({
  type: types.EXPORTS_STATUS_GET_FAILURE,
  payload: error,
});

export const clearExportStatus = () => ({
  type: types.EXPORTS_STATUS_CLEAR,
});

/*
 * Export file link
 */
export const setExportDownloadLink = (
  link: string,
  meta: { type: ExportResultType },
) => ({
  type: types.EXPORTS_DOWNLOAD_LINK_SET,
  payload: link,
  meta,
});

export const updateExportDownloadLink = (
  url: string,
  meta: { type: ExportResultType; saveFile?: boolean },
) => ({
  type: types.EXPORTS_DOWNLOAD_LINK_UPDATE,
  payload: url,
  meta,
});

export const setExportDownloadLinkFailure = (error: string) => ({
  type: types.EXPORTS_DOWNLOAD_LINK_UPDATE_FAILURE,
  payload: error,
});

/*
 * Export start
 */
export const startExportRequest = (
  type: ExportResultType,
  channel: ExportChannel,
) => ({
  type: types.EXPORTS_START_REQUEST,
  payload: { type, channel },
});

export const startExportSuccess = (payload: ExportResult) => ({
  type: types.EXPORTS_START_SUCCESS,
  payload,
});

export const startExportFailure = (
  error: string,
  meta: { type: ExportResultType },
) => ({
  type: types.EXPORTS_START_FAILURE,
  payload: error,
  meta,
});

/*
 * Multi Export start
 */
export const startMultiExportRequest = (
  type: ExportResultType[],
  channel: ExportChannel,
) => ({
  type: types.EXPORTS_START_MULTI_REQUEST,
  payload: { type, channel },
});

export const startMultiExportSuccess = (payload: ExportResult) => ({
  type: types.EXPORTS_START_MULTI_SUCCESS,
  payload,
});

export const startMultiExportFailure = (
  error: string,
  meta: { type: ExportResultType[] },
) => ({
  type: types.EXPORTS_START_MULTI_FAILURE,
  payload: error,
  meta,
});

/*
 * Get export documents
 */
export const getExportDocumentsRequest = (
  params: Partial<ExportDocumentFilterParams>,
) => ({
  type: types.EXPORTS_GET_DOCUMENTS_REQUEST,
  payload: params,
});

export const getExportDocumentsSuccess = (documents: ExportDocument[]) => ({
  type: types.EXPORTS_GET_DOCUMENTS_SUCCESS,
  payload: documents,
});

// TODO: add types of error
export const getExportDocumentsFailure = (error: string) => ({
  type: types.EXPORTS_GET_DOCUMENTS_FAILURE,
  payload: error,
});

export type ExportActions = ReturnType<
  | typeof setEntryExportStatusRequest
  | typeof setEntryExportStatusSuccess
  | typeof setEntryExportStatusFailure
  | typeof setMultiExportStatusRequest
  | typeof setMultiExportStatusSuccess
  | typeof setMultiExportStatusFailure
  | typeof getExportStatusRequest
  | typeof getExportStatusSuccess
  | typeof getExportStatusFailure
  | typeof clearExportStatus
  | typeof setExportDownloadLink
  | typeof updateExportDownloadLink
  | typeof setExportDownloadLinkFailure
  | typeof startExportRequest
  | typeof startExportSuccess
  | typeof startExportFailure
  | typeof startMultiExportRequest
  | typeof startMultiExportSuccess
  | typeof startMultiExportFailure
  | typeof getExportDocumentsRequest
  | typeof getExportDocumentsSuccess
  | typeof getExportDocumentsFailure
>;
