import {
  Chip,
  createStyles,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { WEEKDAYS } from '@rtt-libs/constants';
import { CategoriesItemDictionary, CategoryItemId } from '@rtt-libs/types';
import {
  CheckboxAdapter,
  FieldWithMessages,
  LoadingButton,
  NumberFieldAdapter,
  SelectAdapter,
} from '@rtt-libs/views';
import React from 'react';
import { Field, FieldRenderProps, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SettingsOrders } from '../../../api/settingsOrders';

type Props = FormRenderProps<SettingsOrders> & {
  allCategories: CategoriesItemDictionary;
  options: {
    value: CategoryItemId;
    title: string;
  }[];
};

const useStyles = makeStyles(
  createStyles({
    form: {
      padding: '10px 25px',
    },
    resetWidth: {
      width: 'initial',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    menuItem: {
      whiteSpace: 'pre-wrap',
    },
  }),
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      top: '300px',
      right: '100px',
    },
  },
};

const EditSettingsOrders: React.FC<Props> = ({
  handleSubmit,
  submitError,
  submitting,
  hasValidationErrors,
  pristine,
  hasSubmitErrors,
  modifiedSinceLastSubmit,
  allCategories,
  options,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const getChipLabel = (value: CategoryItemId) => {
    const category = allCategories[value];
    if (category) {
      return allCategories[value].is_deleted
        ? allCategories[value].data.title +
            t('distributor.settings.orders.deleted')
        : allCategories[value].data.title;
    }
    return '';
  };

  const multipleRequired = (value: unknown[]) => {
    return value && value.length > 0
      ? undefined
      : t('distributor.settings.orders.minCategory');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid className={classes.form} container item spacing={3} xs={12}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h1">
            {t('distributor.settings.orders.deliveryDays')}
          </Typography>
          <FormControl component="fieldset">
            <FormGroup row>
              {WEEKDAYS.map((day, i) => (
                <FieldWithMessages
                  key={day}
                  name={`deliveryDays[${i}]`}
                  component={CheckboxAdapter}
                  label={t(`common.weekdays.short.${day}`)}
                  type="checkbox"
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h1">
            {t('distributor.settings.orders.minTotalOrder')}
          </Typography>
          <FieldWithMessages
            name="minTotalOrder"
            component={NumberFieldAdapter}
            label={t('distributor.settings.orders.minTotalOrderLabel')}
            type="number"
            inputProps={{ min: 0, max: 999_999_999_999_999, step: 0.5 }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t('common.mainCurrency')}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h1">
            {t('distributor.settings.orders.categories')}
          </Typography>
          <Field
            name="categories"
            formControlProps={{
              fullWidth: true,
            }}
            validate={multipleRequired}
            component={
              SelectAdapter as React.FC<
                FieldRenderProps<CategoryItemId[], HTMLElement>
              >
            }
            label={t('registration.finish.categories')}
            autoWidth
            selectProps={{
              multiple: true,
            }}
            renderValue={(selected: CategoryItemId[]) => {
              return (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={getChipLabel(value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              );
            }}
            MenuProps={MenuProps}
          >
            {options.map(({ value, title }) => (
              <MenuItem className={classes.menuItem} key={value} value={value}>
                {title}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        {submitError && (
          <Grid xs={12}>
            <FormHelperText error>{submitError}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={6}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              submitting ||
              hasValidationErrors ||
              pristine ||
              (hasSubmitErrors && !modifiedSinceLastSubmit)
            }
            loading={submitting}
          >
            {t('controls.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
export default EditSettingsOrders;
