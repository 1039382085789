import {
  Button,
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { restrictions } from '@rtt-libs/constants';
import { CategoryItem, CategoryItemId } from '@rtt-libs/types';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import omit from 'lodash/fp/omit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCategories } from '../../../categories';
import {
  selectAllCategories,
  selectAvailableCategory,
} from '../../../categories/duck/selectors';
import CategoriesDialog from './CategoriesDialog';

const useStyles = makeStyles(
  createStyles({
    withoutSteppers: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  }),
);

type Props = {
  messagesDomain: 'discounts' | 'sales';
};

const DiscountCategories: React.FC<Props> = ({ messagesDomain }) => {
  useCategories();
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const [categoryFields, setCategoryFields] = useState<
    Record<CategoryItemId, CategoryItem>
  >({});

  const formApi = useForm();

  const categories = useSelector(selectAvailableCategory);
  const { allCategories } = useSelector(selectAllCategories);

  const { categoryDiscounts } = formApi.getState().initialValues || {};

  const categoriesKey = useMemo(() => Object.keys(categoryDiscounts || {}), [
    categoryDiscounts,
  ]);

  useEffect(() => {
    setCategoryFields(prevFields => ({
      ...categoriesKey.reduce((acc, key) => {
        const id = key.substring(3);
        const category = categories[id];
        const checkingCategory = allCategories[id];
        let title = '';
        if (checkingCategory) {
          title = !checkingCategory.is_deleted
            ? checkingCategory.data.title
            : checkingCategory.data.title +
              t('distributor.tables.rtt.deletedCategory');
        }
        return {
          ...acc,
          [id]: category || {
            id,
            data: { title },
          },
        } as Record<string, CategoryItem>;
      }, {}),
    }));
  }, [categoriesKey, categories, allCategories, t]);

  const addItem = useCallback(
    (item: CategoryItem) => {
      setCategoryFields(prevCategories => ({
        ...prevCategories,
        [item.id]: item,
      }));
    },
    [setCategoryFields],
  );

  const existingIds = useMemo(() => Object.keys(categoryFields), [
    categoryFields,
  ]);

  const handleClickRemoveDiscount = (itemId: CategoryItemId) => {
    setCategoryFields(prevCategories => omit([itemId], prevCategories));
    formApi.change(`categoryDiscounts.key${itemId}`);
  };

  return (
    <>
      <Grid item xs={8}>
        <Typography variant="h6">
          {t(`distributor.${messagesDomain}.categoriesTitle`)}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={() => setOpen(true)} startIcon={<AddIcon />}>
          {t('controls.add')}
        </Button>
      </Grid>
      <CategoriesDialog
        open={open}
        onClick={addItem}
        onClose={() => setOpen(false)}
        existingIds={existingIds}
      />
      {!Object.values(categoryFields).length && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t(`distributor.${messagesDomain}.add.emptyCategoriesMessage`)}
          </Typography>
        </Grid>
      )}

      {Object.values(categoryFields).map(item => (
        <Grid item key={item.id} lg={6} xs={12}>
          <Field
            name={`categoryDiscounts.key${item.id}`}
            component={TextFieldAdapter}
            label={item.data.title}
            variant="filled"
            margin="dense"
            type="number"
            fullWidth
            parse={value => Number(value)}
            inputProps={restrictions.DISCOUNT_INPUTS_VALIDATIONS}
            className={classes.withoutSteppers}
            required
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">%</InputAdornment>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t('controls.delete')}
                      onClick={() => handleClickRemoveDiscount(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Grid>
      ))}
    </>
  );
};

export default DiscountCategories;
