import { FORM_ERROR } from 'final-form';
import { SettingsExport, EditSettingsExport } from '../../api/settingsExport';
import { SettingsOrders, EditSettingsOrders } from '../../api/settingsOrders';
import * as types from './types';

export const getSettingsExportRequest = () => ({
  type: types.GET_SETTINGS_EXPORT_REQUEST,
});

export const getSettingsExportSuccess = (payload: SettingsExport) => ({
  type: types.GET_SETTINGS_EXPORT_SUCCESS,
  payload,
});

export const getSettingsExportFailure = (error: string) => ({
  type: types.GET_SETTINGS_EXPORT_FAILURE,
  payload: error,
});

export const getSettingsOrdersRequest = () => ({
  type: types.GET_SETTINGS_ORDERS_REQUEST,
});

export const getSettingsOrdersSuccess = (payload: SettingsOrders) => ({
  type: types.GET_SETTINGS_ORDERS_SUCCESS,
  payload,
});

export const getSettingsOrdersFailure = (error: string) => ({
  type: types.GET_SETTINGS_ORDERS_FAILURE,
  payload: error,
});

export const editSettingsExportRequest = (values: EditSettingsExport) => ({
  type: types.EDIT_SETTINGS_EXPORT_REQUEST,
  payload: values,
});

export const editSettingsExportResponse = (
  submissionErrors?: Partial<SettingsExport & { [FORM_ERROR]: string }>,
) => ({
  type: types.EDIT_SETTINGS_EXPORT_RESPONSE,
  payload: submissionErrors,
});

export const editSettingsExportSuccess = (payload: SettingsExport) => ({
  type: types.EDIT_SETTINGS_EXPORT_SUCCESS,
  payload,
});

export const editSettingsExportFailure = (error: string) => ({
  type: types.EDIT_SETTINGS_EXPORT_FAILURE,
  payload: error,
});

export const editSettingsOrdersRequest = (values: EditSettingsOrders) => ({
  type: types.EDIT_SETTINGS_ORDERS_REQUEST,
  payload: values,
});

export const editSettingsOrdersResponse = (
  submissionErrors?: Partial<SettingsOrders & { [FORM_ERROR]: string }>,
) => ({
  type: types.EDIT_SETTINGS_ORDERS_RESPONSE,
  payload: submissionErrors,
});

export const editSettingsOrdersSuccess = (payload: SettingsOrders) => ({
  type: types.EDIT_SETTINGS_ORDERS_SUCCESS,
  payload,
});

export const editSettingsOrdersFailure = (error: string) => ({
  type: types.EDIT_SETTINGS_ORDERS_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof getSettingsExportSuccess>
  | ReturnType<typeof getSettingsExportRequest>
  | ReturnType<typeof getSettingsExportFailure>
  | ReturnType<typeof getSettingsOrdersSuccess>
  | ReturnType<typeof getSettingsOrdersRequest>
  | ReturnType<typeof getSettingsOrdersFailure>
  | ReturnType<typeof editSettingsExportSuccess>
  | ReturnType<typeof editSettingsExportRequest>
  | ReturnType<typeof editSettingsExportFailure>
  | ReturnType<typeof editSettingsExportResponse>
  | ReturnType<typeof editSettingsOrdersSuccess>
  | ReturnType<typeof editSettingsOrdersRequest>
  | ReturnType<typeof editSettingsOrdersFailure>
  | ReturnType<typeof editSettingsOrdersResponse>;
