import { UseAutocompleteProps } from '@material-ui/lab';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DiscountRtt } from '../../../api/discounts';
import { searchRttDiscountRequest } from '../../duck/actions';
import { selectRttOptions } from '../../duck/selectors';
import { TranslatedAutocomplete } from '../RttAsyncAutocomplete';

type Props = {
  onChange: UseAutocompleteProps<DiscountRtt, false, false, false>['onChange'];
};

const RttFieldSearch: React.FC<Props> = ({ onChange }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { data, loading } = useSelector(selectRttOptions);
  const fetchOptions = useCallback(() => dispatch(searchRttDiscountRequest()), [
    dispatch,
  ]);

  const [open, setOpen] = useState(false);

  const preventFetch = useRef(false);

  useEffect(() => {
    if (open && !preventFetch.current) {
      fetchOptions();
      // Do not initiate fetch while loading
      preventFetch.current = true;
    }
  }, [fetchOptions, open]);

  useEffect(() => {
    if (!loading) {
      preventFetch.current = false;
    }
  }, [loading]);

  return (
    <TranslatedAutocomplete
      onChange={onChange}
      options={data}
      loading={loading}
      open={open}
      label={t('distributor.discounts.searchRtt')}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option: DiscountRtt, value: DiscountRtt) => {
        return option.name === value.name;
      }}
      getOptionLabel={(option: DiscountRtt) => option.name}
    />
  );
};

export default RttFieldSearch;
