import { Pagination } from '@rtt-libs/types';
import type { WithSnackbarProps } from 'notistack';
import {
  AgreedAssortment,
  AgreedAssortmentCreateFormValues,
  AgreedAssortmentEditFormValues,
  AgreedAssortmentFormErrors,
  AgreedAssortmentTableParams,
  AgreedAssortmentWithItems,
  RttSelectOption,
} from '../types';
import * as types from './types';

export const getAgreedAssortmentsRequest = (
  params?: Partial<AgreedAssortmentTableParams>,
) => ({
  type: types.AGREED_ASSORTMENT_GET_LIST_REQUEST,
  payload: params,
});

export const getAgreedAssortmentsSuccess = (
  payload: AgreedAssortment[],
  meta: { pagination: Pagination },
) => ({
  type: types.AGREED_ASSORTMENT_GET_LIST_SUCCESS,
  payload,
  meta,
});

export const getAgreedAssortmentsFailure = (error: string) => ({
  type: types.AGREED_ASSORTMENT_GET_LIST_FAILURE,
  payload: error,
});

export const getAgreedAssortmentDetailsRequest = (
  id: AgreedAssortment['id'],
) => ({
  type: types.AGREED_ASSORTMENT_GET_DETAILS_REQUEST,
  payload: id,
});

export const getAgreedAssortmentDetailsSuccess = (
  payload: AgreedAssortmentWithItems,
) => ({
  type: types.AGREED_ASSORTMENT_GET_DETAILS_SUCCESS,
  payload,
});

export const getAgreedAssortmentDetailsFailure = (error: string) => ({
  type: types.AGREED_ASSORTMENT_GET_DETAILS_FAILURE,
  payload: error,
});

export const createAgreedAssortmentsRequest = (
  values: AgreedAssortmentCreateFormValues,
) => ({
  type: types.AGREED_ASSORTMENT_CREATE_REQUEST,
  payload: values,
});

export const createAgreedAssortmentsSuccess = (
  payload: AgreedAssortmentWithItems,
) => ({
  type: types.AGREED_ASSORTMENT_CREATE_SUCCESS,
  payload,
});

export const createAgreedAssortmentsFailure = (
  errors: AgreedAssortmentFormErrors,
) => ({
  type: types.AGREED_ASSORTMENT_CREATE_FAILURE,
  payload: errors,
});

export const editAgreedAssortmentsRequest = (
  values: AgreedAssortmentEditFormValues,
) => ({
  type: types.AGREED_ASSORTMENT_EDIT_REQUEST,
  payload: values,
});

export const editAgreedAssortmentsSuccess = (
  payload: AgreedAssortmentWithItems,
) => ({
  type: types.AGREED_ASSORTMENT_EDIT_SUCCESS,
  payload,
});

export const editAgreedAssortmentsFailure = (
  errors: AgreedAssortmentFormErrors,
) => ({
  type: types.AGREED_ASSORTMENT_EDIT_FAILURE,
  payload: errors,
});

export const deleteAgreedAssortmentRequest = (
  id: AgreedAssortment['id'],
  cb?: WithSnackbarProps['enqueueSnackbar'],
  messages?: {
    success?: string;
    error?: string;
  },
) => ({
  type: types.AGREED_ASSORTMENT_DELETE_REQUEST,
  payload: id,
  meta: { cb, messages },
});

export const deleteAgreedAssortmentSuccess = (id: AgreedAssortment['id']) => ({
  type: types.AGREED_ASSORTMENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteAgreedAssortmentFailure = (
  id: AgreedAssortment['id'],
  error: string,
) => ({
  type: types.AGREED_ASSORTMENT_DELETE_FAILURE,
  payload: { id, error },
});

export const fetchAgreedAssortmentsRttRequest = () => ({
  type: types.AGREED_ASSORTMENT_FETCH_RTT_REQUEST,
});

export const fetchAgreedAssortmentsRttSuccess = (
  payload: RttSelectOption[],
) => ({
  type: types.AGREED_ASSORTMENT_FETCH_RTT_SUCCESS,
  payload,
});

export const fetchAgreedAssortmentsRttFailure = (
  errors: AgreedAssortmentFormErrors,
) => ({
  type: types.AGREED_ASSORTMENT_FETCH_RTT_FAILURE,
  payload: errors,
});

export type Actions = ReturnType<
  | typeof getAgreedAssortmentsRequest
  | typeof getAgreedAssortmentsSuccess
  | typeof getAgreedAssortmentsFailure
  | typeof getAgreedAssortmentDetailsRequest
  | typeof getAgreedAssortmentDetailsSuccess
  | typeof getAgreedAssortmentDetailsFailure
  | typeof createAgreedAssortmentsRequest
  | typeof createAgreedAssortmentsSuccess
  | typeof createAgreedAssortmentsFailure
  | typeof editAgreedAssortmentsRequest
  | typeof editAgreedAssortmentsSuccess
  | typeof editAgreedAssortmentsFailure
  | typeof deleteAgreedAssortmentRequest
  | typeof deleteAgreedAssortmentSuccess
  | typeof deleteAgreedAssortmentFailure
  | typeof fetchAgreedAssortmentsRttRequest
  | typeof fetchAgreedAssortmentsRttSuccess
  | typeof fetchAgreedAssortmentsRttFailure
>;
