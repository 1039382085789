import { useSnackbar } from 'notistack';
import { useEffect, useCallback } from 'react';

const useSnackbarError = (error?: string | null) => {
  const { enqueueSnackbar } = useSnackbar();

  const displayError = useCallback(
    (errorValue: React.ReactNode) => {
      enqueueSnackbar(errorValue, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (error) {
      displayError(error);
    }
  }, [displayError, error]);

  return displayError;
};

export default useSnackbarError;
