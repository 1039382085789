import React from 'react';
import { Form } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import CreatePriceTypesForm from './CreatePriceTypesForm';
import { CreatePriceTypeValues } from '../../../api/priceTypes';

type Props = {
  onClose(): void;
};

const CreatePriceTypesContainer: React.FC<Props> = ({ onClose }) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.CREATE_PRICE_TYPES_REQUEST}
      resolve={types.CREATE_PRICE_TYPES_RESPONSE}
      reject={types.CREATE_PRICE_TYPES_FAILURE}
    >
      {onSubmit => (
        <Form
          onSubmit={async (values: CreatePriceTypeValues) => {
            const errors = await onSubmit(values);
            if (!errors) {
              onClose();
            }
            return errors;
          }}
          component={props => (
            <CreatePriceTypesForm onClose={onClose} {...props} />
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default CreatePriceTypesContainer;
