import { Pagination } from '@rtt-libs/types';
import { compose, groupBy, keyBy, map, mapValues, toPairs } from 'lodash/fp';
import at from 'lodash/fp/at';
import keys from 'lodash/keys';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { RttSelectOption } from '../../agreedAssortments/types';
import {
  EnhancedProduct,
  searchBalanceProductsForRtt,
} from '../../api/balanceProducts';
import {
  createBalance,
  fetchAgreedProducts,
  fetchRttOptions,
  getBalanceById,
  getBalances,
} from '../../api/balances';
import { getAllManagersOfRttRequest } from '../../rtt/duck/actions';
import { selectRttManagerListData } from '../../rtt/duck/selectors';
import { RTT_MANAGER_LIST_ALL_GET_SUCCESS } from '../../rtt/duck/types';
import { BrandManagerConnection } from '../../types';
import { getThumbnailSrc } from '../../utils';
import {
  Balance,
  BalanceProduct,
  BalanceProductListWithManager,
  ProductWithOrderedQty,
  RecommendedOrderValue,
} from '../types';
import { calcOrderedValue } from '../utils/orderedCalc';
import * as actions from './actions';
import { selectProductCollection } from './selectors';
import * as types from './types';
import { GroupedPayload } from '../../ordersRefactored/types';

function* listWorker({
  payload,
}: ReturnType<typeof actions.getBalancesRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: Balance[];
      meta: { pagination: Pagination };
    } = yield call(getBalances, payload);

    yield put(actions.getBalancesSuccess(data, meta));
  } catch (e) {
    yield put(actions.getBalancesFailure(e.message));
  }
}

function* detailsWorker({
  payload,
}: ReturnType<typeof actions.getBalanceDetailsRequest>) {
  try {
    const data: Balance = yield call(getBalanceById, payload);

    yield put(actions.getBalanceDetailsSuccess(data));
  } catch (e) {
    yield put(actions.getBalanceDetailsFailure(e.message));
  }
}

function* resolveManagersForRtt(rttId: number) {
  yield put(getAllManagersOfRttRequest(rttId));

  yield take(RTT_MANAGER_LIST_ALL_GET_SUCCESS);
  const brandManagerConnections: BrandManagerConnection[] = yield select(
    selectRttManagerListData,
  );

  return brandManagerConnections;
}

function* groupProductsByManager(
  rttId: number,
  balanceProducts: BalanceProduct[],
) {
  const brandManagerConnections: BrandManagerConnection[] = yield resolveManagersForRtt(
    rttId,
  );

  // XXX: [business rule] brand value could not be repeated in connections,
  // no need to collect managers for brand
  const brandsDictionary: Record<
    BrandManagerConnection['brandId'],
    BrandManagerConnection['managerId']
  > = compose<
    [BrandManagerConnection[]],
    Record<BrandManagerConnection['brandId'], BrandManagerConnection[]>,
    Record<
      BrandManagerConnection['brandId'],
      BrandManagerConnection['managerId']
    >
  >(
    mapValues('managerId'),
    keyBy<BrandManagerConnection[]>('brandId'),
  )(brandManagerConnections);

  return compose(
    map(([managerId, productList]) => ({
      managerId,
      productList,
    })),
    toPairs,
    groupBy<BalanceProduct>(
      product => brandsDictionary[product.product.brandId],
    ),
  )(balanceProducts);
}

function* createWorker({
  payload: { rttId, balances, skuQty },
}: ReturnType<typeof actions.createBalancesRequest>) {
  try {
    const productIds = keys(balances);

    const productCollection: Record<
      ProductWithOrderedQty['id'],
      ProductWithOrderedQty
    > = yield select(selectProductCollection);

    const balanceProducts: BalanceProduct[] = at(
      productIds,
      productCollection,
    ).map(product => ({
      productId: product.id,
      product: {
        sku: product.sku,
        saleMeasurement: product.saleMeasurement || 'unit',
        weight: product.weight ?? null,
        title: product.title,
        imageSrc: getThumbnailSrc(product.image),
        brandId: product.brandId,
      },
      ordered: calcOrderedValue(balances[product.id], product),
    }));

    const groupedProductsByManager: BalanceProductListWithManager[] = yield groupProductsByManager(
      rttId,
      balanceProducts,
    );

    const recommendedOrder: {
      products: EnhancedProduct[];
      orderValues: GroupedPayload<RecommendedOrderValue>;
    } = yield call(createBalance, rttId, groupedProductsByManager, skuQty);

    yield put(actions.createBalancesSuccess({ rttId, ...recommendedOrder }));
  } catch (e) {
    yield put(actions.createBalancesFailure(e));
  }
}

function* fetchRttWorker() {
  try {
    const data: RttSelectOption[] = yield call(fetchRttOptions);

    yield put(actions.fetchBalancesRttSuccess(data));
  } catch (e) {
    yield put(actions.fetchBalancesRttFailure(e));
  }
}

function* fetchAgreedProductsWorker({
  payload,
}: ReturnType<typeof actions.fetchAgreedProductsRequest>) {
  try {
    const data: ProductWithOrderedQty[] = yield call(
      fetchAgreedProducts,
      payload,
    );

    yield put(actions.fetchAgreedProductsSuccess(data));
  } catch (e) {
    yield put(actions.fetchAgreedProductsFailure(e.message));
  }
}

function* searchProductsWorker({
  payload,
}: ReturnType<typeof actions.searchProductsRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: ProductWithOrderedQty[];
      meta: { pagination: Pagination };
    } = yield call(searchBalanceProductsForRtt, payload.id, payload.params);

    yield put(actions.searchProductsSuccess(data, meta));
  } catch (e) {
    yield put(actions.searchProductsFailure(e.message));
  }
}

function* orderedProductsWorker({
  payload,
}: ReturnType<typeof actions.getProductsOrderedRequest>) {
  try {
    const orderedSeq = payload.productIds.map(id =>
      call(fetchAgreedProducts, payload.id, id),
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const getOrdered of orderedSeq) {
      try {
        const [orderedProduct]: ProductWithOrderedQty[] = yield getOrdered;
        yield put(actions.getProductsOrderedSuccess(orderedProduct));
      } catch (e) {
        const index = orderedSeq.indexOf(getOrdered);
        yield put(
          actions.getProductsOrderedFailure(
            payload.productIds[index],
            e.message,
          ),
        );
      }
    }
  } catch (error) {
    yield put(actions.searchProductsFailure(error.message));
  }
}

export default function* watcher() {
  yield takeLatest(types.BALANCE_GET_LIST_REQUEST, listWorker);
  yield takeLatest(types.BALANCE_GET_DETAILS_REQUEST, detailsWorker);
  yield takeLatest(types.BALANCE_CREATE_REQUEST, createWorker);
  yield takeLatest(types.BALANCE_FETCH_RTT_REQUEST, fetchRttWorker);
  yield takeLatest(
    types.BALANCE_AGREED_PRODUCTS_REQUEST,
    fetchAgreedProductsWorker,
  );
  yield takeLatest(types.BALANCE_SEARCH_PRODUCTS_REQUEST, searchProductsWorker);
  yield takeLatest(
    types.BALANCE_GET_PRODUCTS_ORDERED_REQUEST,
    orderedProductsWorker,
  );
}
