import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Pages from './Pages';

const RouterWrapper: React.FC = () => (
  <Router>
    <Pages />
  </Router>
);

export default RouterWrapper;
