import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { RttDiscount } from '../../../api/discounts';
import DiscountListActions from './DiscountListActions';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const employeesTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'rttId',
    label: t('distributor.discounts.rttName', 'RTT name'),
    options: {
      format: (_: unknown, entry: RttDiscount) => entry.rttName,
    },
  },
  {
    id: 'discount',
    label: t('distributor.discounts.discount', 'Discount'),
    options: {
      format: (value: number) => `${value}%`,
    },
  },
  {
    id: 'actions',
    label: '',
    options: {
      align: 'right',
      format: (_: unknown, entry: RttDiscount) => entry,
      component: DiscountListActions,
      sort: false,
    },
  },
];

export default employeesTableConfig;
