import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { ThumbnailCell } from '../../../common';
import { BalanceProduct, ProductWithOrderedQty } from '../../types';
import { ProductTitleCell } from '../shared';
import BalanceInput from './BalanceInput';
import RemoveCell from './RemoveCell';
import SoldCell from './SoldCell';

const productTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'productId',
    label: '',
    options: {
      sort: false,
      format: (_: unknown, entry: ProductWithOrderedQty) => entry.image,
      component: ThumbnailCell,
    },
  },
  {
    id: 'product',
    label: t('distributor.balances.products.title'),
    options: {
      sort: false,
      format: (_: unknown, entry: ProductWithOrderedQty) => entry,
      component: ProductTitleCell,
    },
  },
  {
    id: 'input',
    label: t('distributor.balances.products.balance'),
    options: {
      align: 'center',
      sort: false,
      format: (_: unknown, entry: ProductWithOrderedQty) => entry,
      component: BalanceInput,
    },
  },
  {
    id: 'ordered',
    label: t('distributor.balances.products.sold'),
    options: {
      align: 'right',
      sort: false,
      format: (_: BalanceProduct['ordered'], entry: ProductWithOrderedQty) =>
        entry,
      component: SoldCell,
    },
  },
  {
    id: 'remove',
    label: '',
    options: {
      format: (_: BalanceProduct['ordered'], entry: ProductWithOrderedQty) =>
        entry,
      component: RemoveCell,
    },
  },
];

export default productTableConfig;
