import { Box, Grid, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { LoadingButton } from '@rtt-libs/views';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EnhancedProduct } from '../../../api/orders';
import { AddProducts, SkeletonButton } from '../../../common';
import { selectProductsCollection } from '../../../productsAddition/duck/selectors';
import { AddProductContainer } from '../DetailsCommon';
import RttField from './RttField';

const CreateFormTopRow: React.FC & { Loading: React.FC } = () => {
  const [t] = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <RttField />
      </Grid>
      <Box display="flex" justifyContent="flex-end" alignItems="center" clone>
        <Grid item xs={12} md={8}>
          <FormSpy subscription={{ values: true }}>
            {({ values: { rttId }, form: { change } }) => {
              const handleAddProducts = (
                products: Record<string, EnhancedProduct>,
              ) => change('productIdList', Object.keys(products));

              return (
                <AddProducts
                  existingProductIds={[]}
                  onSubmit={handleAddProducts}
                  productsSelector={
                    selectProductsCollection as (
                      state: unknown,
                    ) => Record<string, EnhancedProduct>
                  }
                  disabled={!rttId}
                >
                  <AddProductContainer rttId={rttId} />
                </AddProducts>
              );
            }}
          </FormSpy>
          <FormSpy
            subscription={{
              hasValidationErrors: true,
              pristine: true,
              submitting: true,
            }}
          >
            {({ hasValidationErrors, pristine, submitting }) => (
              <Box ml={1} width={160} clone>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={submitting}
                  disabled={hasValidationErrors || pristine || submitting}
                >
                  {t('controls.create')}
                </LoadingButton>
              </Box>
            )}
          </FormSpy>
        </Grid>
      </Box>
    </Grid>
  );
};

const Loading = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Box borderRadius={theme.shape.borderRadius} clone>
          <Skeleton variant="rect" height={52} />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="flex-end" alignItems="center" clone>
        <Grid item xs={12} md={8}>
          <SkeletonButton mr={1} />
          <SkeletonButton />
        </Grid>
      </Box>
    </Grid>
  );
};

CreateFormTopRow.Loading = Loading;

export default CreateFormTopRow;
