export const MARKER_SVG_COLOR = {
  active: '#0B8208',
  deactivated: '#767676',
  alert: '#b01d0c',
};

export const POLYGON_COLORS = {
  view: '#C0CAAD',
  draw: '#A1C084',
  changed: '#CC5A3B',
  deleted: '#654C4F',
  drawStroke: '#000000',
  viewStroke: '#9DA9A0',
};
