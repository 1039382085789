import { ColumnConfig, Status } from '@rtt-libs/views';
import type { TFunction } from 'i18next';
import type { RttInfo } from '../../types';
import TotalCell from '../commonOrders/OrdersTable/TotalCell';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const returnOrdersTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'status',
    label: t('distributor.tables.returnOrders.status', 'Status'),
    options: {
      component: Status,
    },
  },
  {
    id: 'id',
    label: t('distributor.tables.returnOrders.id', 'ID'),
    options: {
      sort: false,
    },
  },
  {
    id: 'createdAt',
    label: t('distributor.tables.returnOrders.createdAt', 'Created date'),
    options: {
      format: (value: string) => t('formattedDateTime', { date: value }),
      sort: false,
    },
  },
  {
    id: 'rtt',
    label: t('distributor.tables.returnOrders.customer', 'Customer'),
    options: {
      format: (rtt: RttInfo) => rtt.name,
      sort: false,
    },
  },
  {
    id: 'total',
    label: t('distributor.tables.returnOrders.total', 'Total'),
    options: {
      component: TotalCell,
      sort: false,
    },
  },
];

export default returnOrdersTableConfig;
