import type { Reducer } from 'redux';
import type { BalanceSettings } from '../types';
import type { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  values: undefined as BalanceSettings | undefined,
  error: null as string | null,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.BALANCE_SETTINGS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.BALANCE_SETTINGS_GET_SUCCESS:
    case types.BALANCE_SETTINGS_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        values: action.payload,
      };

    case types.BALANCE_SETTINGS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
