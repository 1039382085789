import { Pagination } from '@rtt-libs/types';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createDiscount,
  deleteDiscount,
  DiscountBrand,
  DiscountProduct,
  DiscountRtt,
  editDiscount,
  getAllDiscounts,
  getDiscountDetails,
  getDiscountRttList,
  getDiscounts,
  RttDiscount,
} from '../../api/discounts';
import { selectAllCategories } from '../../categories/duck/selectors';
import * as actions from './actions';
import * as types from './types';

function* getAllDiscountsWorker() {
  try {
    const discounts: RttDiscount[] = yield call(getAllDiscounts);

    yield all([put(actions.getAllDiscountsSuccess(discounts))]);
  } catch (e) {
    yield put(actions.getAllDiscountsFailure(e.message));
  }
}

function* searchDiscountsWorker({
  payload,
}: ReturnType<typeof actions.searchDiscountsRequest>) {
  try {
    const {
      data,
      meta,
    }: { data: RttDiscount[]; meta: { pagination: Pagination } } = yield call(
      getDiscounts,
      payload,
    );

    yield all([put(actions.searchDiscountsSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.searchDiscountsFailure(e.message));
  }
}

function* getDiscountDetailsWorker({
  payload,
}: ReturnType<typeof actions.getDiscountDetailsRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: RttDiscount;
      meta: {
        products: Record<DiscountProduct['id'], DiscountProduct>;
        brands: Record<DiscountBrand['id'], DiscountBrand>;
      };
    } = yield call(getDiscountDetails, payload);

    yield put(actions.getDiscountDetailsSuccess(data, meta));
  } catch (e) {
    yield put(actions.getDiscountDetailsFailure(e.message));
  }
}

function* createDiscountsWorker({
  payload,
}: ReturnType<typeof actions.createDiscountRequest>) {
  try {
    const discount: RttDiscount = yield call(createDiscount, payload);

    yield all([
      put(actions.createDiscountSuccess(discount)),
      put(actions.createDiscountResponse(discount && { id: discount?.id })),
    ]);
  } catch (errors) {
    yield put(actions.createDiscountResponse(errors));
  }
}

function* editDiscountWorker({
  payload,
}: ReturnType<typeof actions.editDiscountRequest>) {
  try {
    const { allCategories } = yield select(selectAllCategories);

    const filterCategoriesIds =
      payload.values.categoryDiscounts &&
      Object.keys(payload.values.categoryDiscounts).filter(
        categoryId => !allCategories[categoryId].is_deleted && categoryId,
      );

    const filterCategoriesData = filterCategoriesIds?.reduce(
      (start, item) => ({
        ...start,
        [item]:
          payload.values.categoryDiscounts &&
          payload?.values?.categoryDiscounts[+item],
      }),
      {},
    );

    const discount: RttDiscount = yield call(editDiscount, payload.id, {
      ...payload.values,
      categoryDiscounts: filterCategoriesData,
    });

    yield all([
      put(actions.editDiscountSuccess(discount)),
      put(actions.editDiscountResponse()),
    ]);
  } catch (errors) {
    yield put(actions.editDiscountResponse(errors));
  }
}

function* deleteDiscountWorker({
  payload,
}: ReturnType<typeof actions.deleteDiscountRequest>) {
  try {
    yield call(deleteDiscount, payload);

    yield all([put(actions.deleteDiscountSuccess(payload))]);
  } catch (errors) {
    yield put(actions.deleteDiscountFailure(errors));
  }
}

function* searchDiscountRttWorker({
  payload,
}: ReturnType<typeof actions.searchRttDiscountRequest>) {
  try {
    const rttOptions: DiscountRtt[] = yield call(getDiscountRttList, payload);

    yield all([put(actions.searchRttDiscountSuccess(rttOptions))]);
  } catch (errors) {
    yield put(actions.searchRttDiscountFailure(errors));
  }
}

export default function* watcher() {
  yield takeLatest(types.DISCOUNT_GET_ALL_REQUEST, getAllDiscountsWorker);
  yield takeLatest(types.DISCOUNT_DETAILS_REQUEST, getDiscountDetailsWorker);
  yield takeLatest(types.DISCOUNT_SEARCH_REQUEST, searchDiscountsWorker);
  yield takeLatest(types.DISCOUNT_CREATE_REQUEST, createDiscountsWorker);
  yield takeLatest(types.DISCOUNT_EDIT_REQUEST, editDiscountWorker);
  yield takeLatest(types.DISCOUNT_DELETE_REQUEST, deleteDiscountWorker);
  yield takeLatest(types.DISCOUNT_RTT_REQUEST, searchDiscountRttWorker);
}
