import { ColumnConfig, Status } from '@rtt-libs/views';
import type { TFunction } from 'i18next';
import { StaffName, StaffContacts } from '../../common/staff';
import type { SupervisorManager } from '../types';

/**
 * Configure table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const supervisorManagersTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'lastName',
    label: t('distributor.tables.staff.name', 'Name'),
    options: {
      sort: false,
      format: (_: unknown, entry: SupervisorManager) =>
        `${entry.lastName} ${entry.firstName}`,
      component: StaffName,
    },
  },
  {
    id: 'contacts',
    label: t('distributor.tables.staff.contacts', 'Contacts'),
    options: {
      format: (_: unknown, entry: SupervisorManager) => entry,
      sort: false,
      component: StaffContacts,
    },
  },
  {
    id: 'login',
    label: t('distributor.tables.staff.login', 'login'),
    options: {
      sort: false,
    },
  },
  {
    id: 'isActive',
    label: t('distributor.tables.staff.isActive.label', 'isActive'),
    options: {
      sort: false,
      align: 'center',
      format: value => {
        const status = value ? 'active' : 'deactivated';
        return { value: status, title: t(`status.${status}`) };
      },
      component: Status,
    },
  },
];

export default supervisorManagersTableConfig;
