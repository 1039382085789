/* eslint-disable @typescript-eslint/camelcase, @typescript-eslint/no-empty-interface */
import { formErrorTransform, TransformAPIError } from '@rtt-libs/api-services';
import type * as BalanceSettingsTypes from '../balanceSettings/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { transformSimpleErrorMessage } from './mappers';

// Use Declaration Merging
interface BalanceSettings extends BalanceSettingsTypes.BalanceSettings {}

interface BalanceSettingsAPI {
  coef_w_balance: number;
  coef_wo_balance: number;
  /** Time format HH:MM */
  time_notification: string;
  product_list_history_days: number;
}

class BalanceSettingsAPI {
  constructor(settings: BalanceSettings) {
    this.coef_w_balance = settings.coefWBalance;
    this.coef_wo_balance = settings.coefWoBalance;
    this.time_notification = settings.timeNotification;
    this.product_list_history_days = settings.productListHistoryDays;
  }
}

class BalanceSettings {
  constructor(fetched: BalanceSettingsAPI) {
    this.coefWBalance = fetched.coef_w_balance;
    this.coefWoBalance = fetched.coef_wo_balance;
    this.timeNotification = fetched.time_notification;
    this.productListHistoryDays = fetched.product_list_history_days;
  }
}

interface FetchedBalanceSettings extends BalanceSettingsAPI {
  id: number;
  distributor_id: number;
}

export const getBalanceSettings = () =>
  api
    .get<FetchedBalanceSettings>(ENDPOINTS.balanceSettings)
    .then(({ data }) => new BalanceSettings(data))
    .catch(transformSimpleErrorMessage);

export const editBalanceSettings = (values: BalanceSettings) =>
  api
    .put<FetchedBalanceSettings>(
      ENDPOINTS.balanceSettings,
      new BalanceSettingsAPI(values),
    )
    .then(({ data }) => new BalanceSettings(data))
    .catch(e => {
      throw formErrorTransform(e, transformBalanceSettingsErrors);
    });

const transformBalanceSettingsErrors: TransformAPIError<
  Record<keyof BalanceSettingsAPI, string>,
  Record<keyof BalanceSettings, string>
> = function transformBalanceSettingsErrors(e) {
  return {
    coefWBalance: e.coef_w_balance,
    coefWoBalance: e.coef_wo_balance,
    timeNotification: e.time_notification,
    productListHistoryDays: e.product_list_history_days,
  };
};
