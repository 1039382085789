import React from 'react';
import { Avatar } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { AttachmentDocumentType } from '@rtt-libs/types';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const ThumbnailCell: React.FC<Props> = ({ value }) => {
  const thumbnail =
    (value as AttachmentDocumentType)?.related?.data?.[0].src ||
    (value as AttachmentDocumentType)?.src ||
    // On adding products to return order thumbnail image equal link,
    // not AttachmentDocumentType
    (value as string);

  return (
    <Avatar variant="rounded" src={thumbnail}>
      <ImageIcon />
    </Avatar>
  );
};

export default ThumbnailCell;
