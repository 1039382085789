import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyAlert = () => {
  const [t] = useTranslation();

  return (
    <Alert role="alert" severity="info">
      <AlertTitle>{t('common.emptyTable')}</AlertTitle>
      {t('common.emptyTableDescription')}
    </Alert>
  );
};

export default EmptyAlert;
