/* eslint-disable @typescript-eslint/camelcase */

import { formErrorTransform, someBooleanToInt } from '@rtt-libs/api-services';
import type { DataWrapped } from '@rtt-libs/types';
import type { Contact, FetchedContact, GeoPoint } from '@rtt-libs/types/types';
import { ENDPOINTS } from '../environment';
import type { BrandManagerValues } from '../types';
import api from './apiSetup';
import {
  BrandManagerConnection,
  BrandManagerListItemAPI,
  mapBrandManagerValuesToAPIFormatOnlyAssigned,
  transformSimpleErrorMessage,
} from './mappers';
import { FetchedRttPriceTypeField, PriceType } from './priceTypes';

type RttId = number;

type ContactInfo = {
  data: {
    id: number;
    fullName: 'string';
    department: 'string';
    phone: 'string';
  }[];
};

export type FetchedRttInfo = Pick<
  FetchedRtt,
  'id' | 'name' | 'contact' | 'contact_info'
>;

export interface RttInfo {
  id: number;
  name: string;
  contact: Contact;
  contactInfo: ContactInfo;
}

export class RttInfo {
  constructor(rtt: FetchedRttInfo) {
    this.id = rtt.id;
    this.name = rtt.name;
    this.contact = {
      phone: rtt.contact.phone,
      email: rtt.contact.email,
      firstName: rtt.contact.first_name,
      lastName: rtt.contact.last_name,
    };
    this.contactInfo = rtt.contact_info;
  }
}

type FetchedRtt = {
  id: RttId;
  name: string;
  address: string;
  geo: GeoPoint;
  created_at: string;
  contact: FetchedContact;
  contact_info: {
    data: {
      id: number;
      fullName: 'string';
      department: 'string';
      phone: 'string';
    }[];
  };
  categories: number[];
  order_qty: number;
  order_last_date: string;
  is_active: boolean;
  is_balance_enabled: boolean | null;
  is_balance_enabled_area: boolean | null;
} & FetchedRttPriceTypeField & { channel_id?: string };

export class Rtt {
  id: RttId;
  name: string;
  address: string;
  geo: GeoPoint;
  createdAt: string;
  categories: number[];
  contact: Contact;
  contactInfo: ContactInfo;
  orderQty: number;
  orderLastDate: string;
  [key: string]: unknown;
  isActive: boolean;
  isBalanceEnabled: boolean | null;
  isBalanceEnabledArea: boolean | null;
  priceType?: PriceType;
  channelId?: string;

  constructor(rtt: FetchedRtt) {
    this.id = rtt.id;
    this.name = rtt.name;
    this.address = rtt.address;
    this.geo = rtt.geo;
    this.createdAt = rtt.created_at;
    this.contact = {
      phone: rtt.contact.phone,
      email: rtt.contact.email,
      firstName: rtt.contact.first_name,
      lastName: rtt.contact.last_name,
    };
    this.contactInfo = rtt.contact_info;
    this.categories = rtt.categories;
    this.orderQty = rtt.order_qty;
    this.orderLastDate = rtt.order_last_date;
    this.isActive = rtt.is_active;
    this.isBalanceEnabled = rtt.is_balance_enabled;
    this.isBalanceEnabledArea = rtt.is_balance_enabled_area;

    this.priceType =
      rtt.price_type?.price_type && new PriceType(rtt.price_type.price_type);

    this.channelId = rtt.channel_id;
  }
}

type APISearchParams = Partial<{
  is_active: '0' | '1';
  search: string;
  only_has_channel: 0 | 1;
}>;

export type SearchList = {
  [key: string]: number;
};

export type SearchParams = Partial<{
  isActive: '1' | '0';
  search: string;
  hasChannels: boolean;
}>;

type Search<T> = {
  data: T[];
};

/*
 * Rtt API handlers
 */

export const getRtt = (params?: SearchParams) =>
  api
    .get<Search<FetchedRtt>>(ENDPOINTS.rtt, {
      params: params && mapAPISearchParams(params),
    })
    .then(({ data }) => mapRttArray(data.data));

export const getRttDetails = (id: string) =>
  api
    .get<FetchedRtt>(`${ENDPOINTS.rtt}/${id}`)
    .then(({ data }) => new Rtt(data))
    .catch(transformSimpleErrorMessage);

export const changeRttBalance = ({
  id,
  ...values
}: Pick<Rtt, 'id' | 'isBalanceEnabled'>) =>
  api.put(ENDPOINTS.balanceRtt(id), mapBalanceValues(values)).catch(e => {
    throw formErrorTransform(e);
  });

export const getRttAssignedBrandManagersAPI = (id: Rtt['id']) =>
  api
    .get<DataWrapped<BrandManagerListItemAPI[]>>(
      ENDPOINTS.brandManagersByRtt(id),
    )
    .then(({ data }) => data.data.map(item => new BrandManagerConnection(item)))
    .catch(transformSimpleErrorMessage);

export const setRttAssignedBrandManagersAPI = ({
  rttId: id,
  managerList: values,
}: {
  rttId: Rtt['id'];
} & BrandManagerValues) =>
  api
    .post<DataWrapped<BrandManagerListItemAPI[]>>(
      ENDPOINTS.brandManagersByRtt(id),
      { manager_list: mapBrandManagerValuesToAPIFormatOnlyAssigned(values) },
    )
    .then(({ data }) => data.data.map(item => new BrandManagerConnection(item)))
    .catch(transformSimpleErrorMessage);

/*
 * Rtt Mappers
 */

function mapRttArray(rttArray: FetchedRtt[]) {
  return rttArray.map(rtt => new Rtt(rtt));
}

function mapAPISearchParams(params: SearchParams): APISearchParams {
  return {
    is_active: params.isActive,
    search: params.search,
    only_has_channel: someBooleanToInt(params.hasChannels),
  };
}

function mapBalanceValues(values: { isBalanceEnabled: boolean | null }) {
  return {
    is_balance_enabled: values.isBalanceEnabled,
  };
}
