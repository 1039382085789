import { DialogContent, DialogTitle, FormHelperText } from '@material-ui/core';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { BrandFormValues } from '../types';
import DialogActionsButtons from './DialogActionsButtons';

type Props<T> = FormRenderProps<T> & {
  onClose(): void;
  heading: React.ReactNode;
  submitLabel: string;
};

function BrandDetailsForm<T extends BrandFormValues = BrandFormValues>({
  handleSubmit,
  submitError,
  onClose,
  heading,
  submitLabel,
  children,
}: Props<T>) {
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="brand-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        {children}

        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>

      <DialogActionsButtons onClose={onClose} submitLabel={submitLabel} />
    </form>
  );
}

export default BrandDetailsForm;
