import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from '../../../pages/routes';
import { getOrdersRequest } from '../../duck/actions';
import { selectOrders } from '../../duck/selectors';
import type * as OrderTypes from '../../types';
import { OrdersTable } from '../commonOrders';
import ordersTableConfig from './tableConfig';
import FilterRowOnlyDates from '../commonOrders/OrdersTable/FilterRowOnlyDates';
import { ExportStatusContainer } from '../../../exports';

/**
 * Container to fetch & filter Order Exports & render them in table.
 */
const OrderExportListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { data, pagination, loading, error } = useSelector(selectOrders);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const onTableStateChange = useCallback(
    (options: OrderTypes.OrderSearchParams) => {
      dispatch(
        getOrdersRequest({
          ...options,
          status: 'submit',
          include: 'export_status',
        }),
      );
    },
    [dispatch],
  );

  const onRowClick = (orderId: string) => {
    history.push(`${routes.orders}/${orderId}`);
  };

  const [t] = useTranslation();
  const columnConfig = useMemo(() => ordersTableConfig(t), [t]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <OrdersTable
        filterRow={({ onChange, displayOptions }) => (
          <FilterRowOnlyDates
            onChange={onChange}
            initialFilterValues={displayOptions}
            endElement={<ExportStatusContainer type="selling" />}
          />
        )}
        orders={data}
        pagination={pagination}
        loading={loading}
        onRowClick={onRowClick}
        columnConfig={columnConfig}
        onStateChange={onTableStateChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export default OrderExportListContainer;
