import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';
import { ExportDocument } from '../../api/exports';
import { ExportResultType, ExportStatusValue } from '../types';
import { branchName } from './const';
import type { BranchState, ExportStatusState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

type EntryParam = { type: ExportResultType; id: string };

export const selectExportEntriesLoadings: ParametricSelector<
  PartialRootState,
  EntryParam,
  string[]
> = (state, { type }) => get([branchName, 'entries', type, 'loading'], state);

export const selectExportEntriesErrors: ParametricSelector<
  PartialRootState,
  EntryParam,
  Record<string, string | undefined>
> = (state, { type }) => get([branchName, 'entries', type, 'errors'], state);

export const selectExportOrderState = createSelector<
  PartialRootState,
  EntryParam,
  string[],
  Record<string, string | undefined>,
  string,
  { loading: boolean; error: string | undefined }
>(
  [selectExportEntriesLoadings, selectExportEntriesErrors, (_, { id }) => id],
  (loadings, errors, id) => ({
    loading: loadings.includes(id),
    error: errors[id],
  }),
);

export const selectExportEntriesStateById = (
  type: ExportResultType,
  id: string,
) => (state: PartialRootState) => selectExportOrderState(state, { type, id });

export const selectExportStatus: Selector<
  PartialRootState,
  ExportStatusState
> = get([branchName, 'status']);

const selectExportDocumentCollection: Selector<
  BranchState,
  Record<string, ExportDocument>
> = get([branchName, 'documents', 'collection']);
const selectExportDocumentIds: Selector<BranchState, string[]> = get([
  branchName,
  'documents',
  'entryIds',
]);
const selectExportDocumentLoading: Selector<BranchState, boolean> = get([
  branchName,
  'documents',
  'loading',
]);
const selectExportDocumentError: Selector<BranchState, string | null> = get([
  branchName,
  'documents',
  'error',
]);

export const selectExportDocumentsData = createSelector(
  [
    selectExportDocumentCollection,
    selectExportDocumentIds,
    selectExportDocumentLoading,
    selectExportDocumentError,
  ],
  (collection, ids, loading, error) => ({
    loading,
    error,
    data: ids.map(id => collection[id]),
  }),
);

const selectExportMultiStatusLoading: Selector<BranchState, boolean> = get([
  branchName,
  'multiStatus',
  'loading',
]);
const selectExportMultiStatusError: Selector<
  BranchState,
  string | undefined
> = get([branchName, 'multiStatus', 'error']);

export const selectExportMultiStatusState = createSelector(
  [
    selectExportDocumentCollection,
    selectExportDocumentIds,
    selectExportMultiStatusLoading,
    selectExportMultiStatusError,
  ],
  (collection, ids, loading, error) => {
    const data = ids.map(id => collection[id]);
    const lists = {
      done: data.filter(
        ({ exportStatus: { status } = {} }) => status === 'done',
      ),
      notExported: data.filter(
        ({ exportStatus: { status } = {} }) =>
          status === 'not_exported' || status === undefined,
      ),
    };

    let status: ExportStatusValue | undefined;

    if (lists.done.length || lists.notExported.length) {
      status = lists.done.length ? 'done' : 'not_exported';
    }

    if (loading) {
      status = 'processing';
    }

    return {
      lists,
      status,
      loading,
      error: error ?? undefined,
    };
  },
);
