import {
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import {
  FieldWithMessages as Field,
  TextFieldAdapter,
  LoadingButton,
  ImagePreview,
  LogotypeDownload,
  Logotype,
} from '@rtt-libs/views';
import React, { useRef } from 'react';
import { FormRenderProps, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Profile, Documents } from '../../../api/profile';

type Props = FormRenderProps<Profile> & {
  documents: Documents[];
};

const EditProfileForm: React.FC<Props> = ({
  handleSubmit,
  submitError,
  submitting,
  documents,
}) => {
  const [t] = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>();

  const chooseLogo = () => {
    (fileInputRef.current as HTMLInputElement).click();
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <DialogContent>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Field name="id" component="input" hidden />
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              item
              xs={12}
            >
              <FormSpy>
                {({ values: { logo } }) => (
                  <Logotype key="LogotypeKey-0" imgBlob={logo} />
                )}
              </FormSpy>

              <Field
                name="logo"
                component={LogotypeDownload}
                id="logo"
                inputProps={{
                  accept: 'image/*',
                  ref: fileInputRef,
                }}
              />

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={chooseLogo}
                >
                  {t('distributor.profile.logoButton')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Field
                  margin="dense"
                  name="name"
                  type="text"
                  fullWidth
                  required
                  component={TextFieldAdapter}
                  label={t('distributor.profile.name')}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextFieldAdapter}
                  name="firstName"
                  margin="dense"
                  type="text"
                  fullWidth
                  required
                  label={t('distributor.profile.firstName')}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextFieldAdapter}
                  name="lastName"
                  margin="dense"
                  type="text"
                  fullWidth
                  required
                  label={t('distributor.profile.lastName')}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextFieldAdapter}
                  name="email"
                  margin="dense"
                  type="email"
                  fullWidth
                  required
                  label={t('distributor.profile.email')}
                  placeholder={t('distributor.employees.add.email.placeholder')}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  margin="dense"
                  name="address"
                  type="text"
                  fullWidth
                  required
                  component={TextFieldAdapter}
                  label={t('distributor.profile.address')}
                />
              </Grid>
              {submitError && (
                <FormHelperText error>{submitError}</FormHelperText>
              )}
            </Grid>
            <DialogActions>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={submitting}
              >
                {t('controls.save')}
              </LoadingButton>
            </DialogActions>
          </Grid>
          <Grid item xs={6} md={5}>
            <Typography variant="subtitle2">
              {t('distributor.profile.documents')}
            </Typography>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {documents &&
                  documents.map((img: Documents) => (
                    <Grid item key={img.id || `${img.name}-${img.thumbSize}`}>
                      <ImagePreview
                        imgBlob={{
                          src: img.src,
                          id: img.id,
                          size: img.thumbSize,
                          name: img.name,
                        }}
                        onClick={() => ''}
                        readOnly
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </form>
  );
};

export default EditProfileForm;
