/* eslint-disable @typescript-eslint/camelcase */
import type { Pagination } from '@rtt-libs/types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  searchProductsForReturnAPI,
  searchProductsForRtt,
  searchProductsPricelessAPI,
} from '../../api/productsAddition';
import type { ProductInfo, ReturnProductInfo } from '../types';
import * as actions from './actions';
import * as types from './types';
import { upgradeReturnProductsByOrderInfo } from '../../ordersRefactored/duck/saga';

function* searchProductsAsRttWorker({
  payload,
}: ReturnType<typeof actions.searchProductsAsRttRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: ProductInfo[];
      meta: { pagination: Pagination };
    } = yield call(searchProductsForRtt, payload.rttId, payload.params);

    yield all([put(actions.searchProductsAsRttSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.searchProductsAsRttFailure(e.message));
  }
}

function* searchProductsPricelessWorker({
  payload,
}: ReturnType<typeof actions.searchProductsPricelessRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: ProductInfo[];
      meta: { pagination: Pagination };
    } = yield call(searchProductsPricelessAPI, payload);

    yield all([put(actions.searchProductsPricelessSuccess(data, meta))]);
  } catch (e) {
    yield put(actions.searchProductsPricelessFailure(e.message));
  }
}

function* searchProductsForReturnWorker({
  payload,
}: ReturnType<typeof actions.searchReturnProductsRequest>) {
  try {
    const {
      data,
      meta,
    }: {
      data: ReturnProductInfo[];
      meta: { pagination: Pagination };
    } = yield call(searchProductsForReturnAPI, payload.rttId, payload.params);

    // TODO: add fetching orders, decrypting them and add values to ReturnedProduct
    yield* upgradeReturnProductsByOrderInfo(data);

    yield put(actions.searchReturnProductsSuccess(data, meta));
  } catch (e) {
    yield put(actions.searchReturnProductsFailure(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(
    types.PRODUCTS_AS_RTT_SEARCH_REQUEST,
    searchProductsAsRttWorker,
  );
  yield takeLatest(
    types.PRODUCTS_PRICELESS_SEARCH_REQUEST,
    searchProductsPricelessWorker,
  );
  yield takeLatest(
    types.PRODUCTS_RETURN_SEARCH_REQUEST,
    searchProductsForReturnWorker,
  );
}
