export const ADMINISTRATORS_SEARCH_REQUEST = '@rtt[D]/ADMINISTRATORS_SEARCH_REQUEST' as const;
export const ADMINISTRATORS_SEARCH_SUCCESS = '@rtt[D]/ADMINISTRATORS_SEARCH_SUCCESS' as const;
export const ADMINISTRATORS_SEARCH_FAILURE = '@rtt[D]/ADMINISTRATORS_SEARCH_FAILURE' as const;

export const ADMINISTRATORS_CREATE_REQUEST = '@rtt[D]/ADMINISTRATORS_CREATE_REQUEST' as const;
export const ADMINISTRATORS_CREATE_SUCCESS = '@rtt[D]/ADMINISTRATORS_CREATE_SUCCESS' as const;
export const ADMINISTRATORS_CREATE_FAILURE = '@rtt[D]/ADMINISTRATORS_CREATE_FAILURE' as const;

export const ADMINISTRATORS_EDIT_REQUEST = '@rtt[D]/ADMINISTRATORS_EDIT_REQUEST' as const;
export const ADMINISTRATORS_EDIT_SUCCESS = '@rtt[D]/ADMINISTRATORS_EDIT_SUCCESS' as const;
export const ADMINISTRATORS_EDIT_FAILURE = '@rtt[D]/ADMINISTRATORS_EDIT_FAILURE' as const;

export const ADMINISTRATORS_CHANGE_STATUS_REQUEST = '@rtt[D]/ADMINISTRATORS_CHANGE_STATUS_REQUEST' as const;
export const ADMINISTRATORS_CHANGE_STATUS_SUCCESS = '@rtt[D]/ADMINISTRATORS_CHANGE_STATUS_SUCCESS' as const;
export const ADMINISTRATORS_CHANGE_STATUS_FAILURE = '@rtt[D]/ADMINISTRATORS_CHANGE_STATUS_FAILURE' as const;
