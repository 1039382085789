import { restrictions } from '@rtt-libs/constants';
import { pickBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../../common/useSnackbarError';
import { searchProductsAsRttRequest } from '../../duck/actions';
import { selectProductsForRtt } from '../../duck/selectors';
import type { ProductSearchParams, DisplayProduct } from '../../types';
import { PaginatedProductSearch } from '../ProductAddCommon';

interface Props {
  rttId?: number;
  managerId?: number;
  renderList: (data: DisplayProduct[]) => React.ReactNode;
}

const AddProductContainer: React.FC<Props> = ({
  rttId,
  managerId,
  renderList,
}) => {
  const dispatch = useDispatch();
  const [displayOptions, changeOptions] = useState<ProductSearchParams>({
    perPage: restrictions.PRODUCTS_LIST_DISPLAY_COUNT,
    managerId,
  });

  useEffect(() => {
    if (rttId) {
      dispatch(searchProductsAsRttRequest(rttId, displayOptions));
    }
  }, [dispatch, rttId, displayOptions]);

  const onChange = useCallback((params: Partial<ProductSearchParams>) => {
    changeOptions(prevParams => pickBy({ ...prevParams, page: 1, ...params }));
  }, []);

  const { data, pagination, loading, error } = useSelector(
    selectProductsForRtt,
  );
  useSnackbarError(error);

  return (
    <PaginatedProductSearch
      isEmpty={!data.length}
      loading={loading}
      error={error}
      pagination={pagination}
      onChange={onChange}
    >
      {renderList(data)}
    </PaginatedProductSearch>
  );
};

export default AddProductContainer;
