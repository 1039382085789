import React, { useState } from 'react';
import { PriceType } from '../../../api/priceTypes';
import { EditPriceTypesContext } from './EditPriceTypesContext';
import EditPriceTypesDialog from './EditPriceTypesDialog';
import PriceTypesActionsMenu from './PriceTypesActionsMenu';

type Props = {
  /** id of current entry */
  id: string | number | symbol;
  /** Value received from format fn in tableConfig  */
  value?: unknown;
};

const EditPriceTypesActions: React.FC<Props> = props => {
  const [dialogState, changeDialogState] = useState<boolean>(false);

  const handleDialogOpen = () => changeDialogState(true);
  const handleDialogClose = () => changeDialogState(false);

  // eslint-disable-next-line react/destructuring-assignment
  const priceType = props.value instanceof PriceType ? props.value : undefined;

  return (
    <>
      <PriceTypesActionsMenu {...props} onEditClick={handleDialogOpen} />

      <EditPriceTypesContext.Provider value={priceType}>
        <EditPriceTypesDialog open={dialogState} onClose={handleDialogClose} />
      </EditPriceTypesContext.Provider>
    </>
  );
};

export default EditPriceTypesActions;
