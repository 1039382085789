import { ColumnConfig } from '@rtt-libs/views';
import { TFunction } from 'i18next';
import { ThumbnailCell } from '../../../common';
import { BalanceProduct } from '../../types';
import { ProductTitleCell } from '../shared';

const productTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'productId',
    label: '',
    options: {
      sort: false,
      format: (_: unknown, entry: BalanceProduct) => entry.product.imageSrc,
      component: ThumbnailCell,
    },
  },
  {
    id: 'product',
    label: t('distributor.balances.products.title'),
    options: {
      sort: false,
      component: ProductTitleCell,
    },
  },
  {
    id: 'ordered',
    label: t('distributor.balances.products.ordered'),
    options: {
      sort: false,
      format: (_: BalanceProduct['ordered'], entry: BalanceProduct) => {
        const measurement = entry.product.saleMeasurement;
        const value =
          measurement === 'unit'
            ? entry.ordered.qty
            : entry.ordered.orderWeight;

        return `${value} ${t(`product.values.${measurement}`)}`;
      },
    },
  },
];

export default productTableConfig;
