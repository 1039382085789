import { formErrorTransform } from '@rtt-libs/api-services';
import { ENDPOINTS } from '../environment';
import { MassMailFormValues } from '../massMail/types';
import api from './apiSetup';
import { mapMassMailErrors, mapMassMailFormValues } from './mappers';

export const sendMassMailAPI = (values: MassMailFormValues) =>
  api.post(ENDPOINTS.massMail, mapMassMailFormValues(values)).catch(e => {
    throw formErrorTransform(e, mapMassMailErrors);
  });
