import { ColumnConfig } from '@rtt-libs/views';
import type { TFunction } from 'i18next';
import type { RttInfo } from '../../types';
import TotalCell from '../commonOrders/OrdersTable/TotalCell';
import ExportCell from './ExportCell';

/**
 * Configure Order Exports table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const orderExportsTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'exportStatus',
    label: t('distributor.tables.orders.exportStatus', 'Exported'),
    options: {
      sort: false,
      component: ExportCell,
    },
  },
  {
    id: 'id',
    label: t('distributor.tables.orders.id', 'ID'),
    options: {
      sort: false,
    },
  },
  {
    id: 'createdAt',
    label: t('distributor.tables.orders.createdAt', 'Created date'),
    options: {
      format: (value: string) => t('formattedDateTime', { date: value }),
      sort: false,
    },
  },
  {
    id: 'rtt',
    label: t('distributor.tables.orders.customer', 'Customer'),
    options: {
      format: (rtt: RttInfo) => rtt.name,
      sort: false,
    },
  },
  {
    id: 'total',
    label: t('distributor.tables.orders.total', 'Total'),
    options: {
      component: TotalCell,
      sort: false,
    },
  },
];

export default orderExportsTableConfig;
