import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchParams } from '../../api/employees';
import useSnackbarError from '../../common/useSnackbarError';
import { searchEmployeesRequest } from '../duck/actions';
import { selectEmployees } from '../duck/selectors';
import EmployeesTable from './EmployeesTable';

/**
 * Container to fetch & filter employees & render them in table.
 */
const EmployeesContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { data, pagination, loading, error } = useSelector(selectEmployees);

  useSnackbarError(error);

  const onTableStateChange = useCallback(
    (options: SearchParams) => {
      dispatch(searchEmployeesRequest(options));
    },
    [dispatch],
  );

  return (
    <EmployeesTable
      employees={data}
      pagination={pagination}
      loading={loading}
      onStateChange={onTableStateChange}
    />
  );
};

export default EmployeesContainer;
