export const GET_TERRITORIES_REQUEST = '@rtt[D]/GET_TERRITORIES_REQUEST' as const;
export const GET_TERRITORIES_SUCCESS = '@rtt[D]/GET_TERRITORIES_SUCCESS' as const;
export const GET_TERRITORIES_FAILURE = '@rtt[D]/GET_TERRITORIES_FAILURE' as const;

export const CREATE_TERRITORIES_REQUEST = '@rtt[D]/CREATE_TERRITORIES_REQUEST' as const;
export const CREATE_TERRITORIES_SUCCESS = '@rtt[D]/CREATE_TERRITORIES_SUCCESS' as const;
export const CREATE_TERRITORIES_FAILURE = '@rtt[D]/CREATE_TERRITORIES_FAILURE' as const;
export const CREATE_TERRITORIES_RESPONSE = '@rtt[D]/CREATE_TERRITORIES_RESPONSE' as const;

export const EDIT_TERRITORIES_REQUEST = '@rtt[D]/EDIT_TERRITORIES_REQUEST' as const;
export const EDIT_TERRITORIES_SUCCESS = '@rtt[D]/EDIT_TERRITORIES_SUCCESS' as const;
export const EDIT_TERRITORIES_FAILURE = '@rtt[D]/EDIT_TERRITORIES_FAILURE' as const;
export const EDIT_TERRITORIES_RESPONSE = '@rtt[D]/EDIT_TERRITORIES_RESPONSE' as const;

export const DELETE_TERRITORIES_REQUEST = '@rtt[D]/DELETE_TERRITORIES_REQUEST' as const;
export const DELETE_TERRITORIES_SUCCESS = '@rtt[D]/DELETE_TERRITORIES_SUCCESS' as const;
export const DELETE_TERRITORIES_FINISH = '@rtt[D]/DELETE_TERRITORIES_FINISH' as const;
export const DELETE_TERRITORIES_FAILURE = '@rtt[D]/DELETE_TERRITORIES_FAILURE' as const;
