import { useCallback } from 'react';
import logoutHere from './logoutHere';
import logoutEverywhere from './logoutEverywhere';

const noop = (): void => undefined;

type ReturnType = {
  logout: typeof noop;
  logoutAll: typeof noop;
};

/**
 * Remove cookies and send command to API to logout.
 */
const useLogout = (callback: typeof noop = noop): ReturnType => {
  const logout = useCallback(() => {
    logoutHere();
    callback();
  }, [callback]);

  const logoutAll = useCallback(() => {
    logoutEverywhere();
    callback();
  }, [callback]);

  return {
    logout,
    logoutAll,
  };
};

export default useLogout;
