const logger = (
  value: unknown,
  env:
    | 'production'
    | 'staging'
    | 'develop'
    | 'local'
    | string
    | undefined = process.env.REACT_APP_ENVIRONMENT,
): void => {
  if (env !== 'production' && env !== 'staging') {
    // eslint-disable-next-line no-console
    console.log(value);
  }
};

export default logger;
