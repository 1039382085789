import { CircularProgress, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, Value } from '@material-ui/lab';
import { FieldWithMessages as Field } from '@rtt-libs/views';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FieldRenderProps, UseFieldConfig, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { DiscountRtt } from '../../api/discounts';

interface TranslatedAutocompleteProps<
  T = DiscountRtt, // FIXME: make this more cross-typed
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>
  extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'multiple' | 'disableClearable' | 'freeSolo'
  > {
  multiple?: Multiple;
  disableClearable?: DisableClearable;
  freeSolo?: FreeSolo;

  helperText?: string;
  error?: boolean;
  label?: string;
  textFieldProps?: TextFieldProps;
}

export function TranslatedAutocomplete<
  T = DiscountRtt, // FIXME: make this more cross-typed
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  helperText,
  error,
  label,
  textFieldProps,
  ...rest
}: TranslatedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const [t] = useTranslation();

  const translatedStringProps = useMemo(
    () => ({
      clearText: t('controls.autocomplete.clearText', 'Clear'),
      closeText: t('controls.autocomplete.closeText', 'Close'),
      loadingText: t('controls.autocomplete.loadingText', 'Loading…'),
      noOptionsText: t('controls.autocomplete.noOptionsText', 'No options'),
      openText: t('controls.autocomplete.openText', 'Open'),
    }),
    [t],
  );

  return (
    <Autocomplete
      {...translatedStringProps}
      {...rest}
      renderInput={params => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label}
          fullWidth
          variant="outlined"
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            endAdornment: !textFieldProps?.InputProps?.readOnly && (
              <>
                {rest.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

interface RttAsyncAutocompleteProps<
  T = DiscountRtt, // FIXME: make this more cross-typed
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>
  extends FieldRenderProps<Value<T, Multiple, DisableClearable, FreeSolo>>,
    Partial<
      Omit<
        AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        'renderInput' | 'multiple' | 'disableClearable' | 'freeSolo'
      >
    > {
  multiple?: Multiple;
  disableClearable?: DisableClearable;
  freeSolo?: FreeSolo;

  textFieldProps?: TextFieldProps;
}

function RttAsyncAutocomplete<
  T = DiscountRtt, // FIXME: make this more cross-typed
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  input,
  meta,
  textFieldProps,
  options = [],
  ...rest
}: RttAsyncAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { onChange, value, name, ...restInput } = input;

  const [t] = useTranslation();

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const dummyValue = { name: '' };
  const emptySingleValue = restInput.freeSolo ? '' : dummyValue;
  const emptyValue = ((restInput.multiple
    ? []
    : emptySingleValue) as unknown) as Value<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >;

  return (
    <TranslatedAutocomplete
      {...rest}
      options={options}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      value={value || emptyValue}
      helperText={showError ? meta.error || meta.submitError : ''}
      error={showError}
      textFieldProps={{ name, ...textFieldProps }}
      label={t('distributor.discounts.rttName')}
      {...restInput}
    />
  );
}

interface Props extends UseFieldConfig<number | string> {
  readOnly?: boolean;
  fetchOptions: () => void;
  options: { id: number | string; name: string }[];
  loading?: boolean;
  name?: string;
}

const RttAsyncAutocompleteField: React.FC<Props> = ({
  readOnly,
  fetchOptions,
  options,
  loading,
  name = 'rtt',
  ...fieldProps
}) => {
  const [open, setOpen] = useState(false);
  const { pauseValidation, resumeValidation } = useForm();

  const preventFetch = useRef(false);

  // Fixes validation of required field, bc it invokes before actually filling input
  useEffect(() => {
    if (open) {
      pauseValidation();
    } else {
      resumeValidation();
    }
  }, [pauseValidation, resumeValidation, open]);

  useEffect(() => {
    if (open && !preventFetch.current) {
      fetchOptions();
      // Do not initiate fetch while loading
      preventFetch.current = true;
    }
  }, [fetchOptions, open]);

  useEffect(() => {
    if (!loading) {
      preventFetch.current = false;
    }
  }, [loading]);

  return (
    <Field
      name={name}
      component={RttAsyncAutocomplete}
      open={open}
      onOpen={() => {
        setOpen(!readOnly);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option: DiscountRtt, value: DiscountRtt) => {
        return option.name === value.name;
      }}
      getOptionLabel={(option: DiscountRtt) => option.name}
      options={options}
      loading={loading}
      textFieldProps={{ required: true, InputProps: { readOnly } }}
      {...fieldProps}
    />
  );
};

export default RttAsyncAutocompleteField;
