import type { Config } from 'final-form';
import type { TFunction } from 'i18next';
import type { ProductUpdate } from '../../../api/assortment';

export const validateProductDetails = (
  t: TFunction,
): Config<ProductUpdate>['validate'] => ({ brand, isActive }) => {
  if (isActive && !brand?.isActive) {
    const error = t('validation.activeProductDeactivatedBrand');

    return {
      isActive: error,
      brand: error,
    };
  }

  return undefined;
};
