import {
  AttachmentDocumentType,
  CategoryItemId,
  StatusValue,
} from '@rtt-libs/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

type Status = {
  value: StatusValue;
  title: string;
};

type Contact = {
  email: string;
  first_name: string;
  last_name: string;
};

export type DistributorInfoResponse = {
  id: number;
  name: string;
  address: string;
  status: Status;
  contact?: Contact;
  documents?: AttachmentDocumentType[];
  categories?: CategoryItemId[];
  notices?: {
    notices: string;
    status: Status;
  };
};

const getDistributorInfo = () =>
  api.get<DistributorInfoResponse>(
    `${ENDPOINTS.distributor}?include=contact,documents,categories`,
  );

export default getDistributorInfo;
