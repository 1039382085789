import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import dayjs from 'dayjs';

import { DataRtt } from '@rtt-libs/types';
import { MARKER_SVG_COLOR } from './constant';
import InfoContainer from './InfoContainer';

type Props = {
  dataRtt: DataRtt[];
};

const getTemplateSvg = (color: string) => `<?xml version="1.0"?>
<svg width="34px" height="34px" class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" version="1.1" xmlns="http://www.w3.org/2000/svg">
<path fill="${color}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
</svg>`;

const Markers: React.FC<Props> = ({ dataRtt }) => {
  const [isOpen, setIsOpen] = React.useState<number>(0);

  const handleToggleOpen = (id = 0) => setIsOpen(id);

  const renderMarkers = () => {
    let svg = getTemplateSvg(MARKER_SVG_COLOR.active);
    return (
      <>
        {dataRtt.map(({ geo, id, orderQty, orderLastDate }, index) => {
          const today = dayjs();
          const lastOrderDate = dayjs(orderLastDate);
          const monthsFromLastOrder = today.diff(lastOrderDate, 'month');

          svg = getTemplateSvg(MARKER_SVG_COLOR.active);

          if (!orderQty) {
            svg = getTemplateSvg(MARKER_SVG_COLOR.alert);
          } else if (monthsFromLastOrder >= 2) {
            svg = getTemplateSvg(MARKER_SVG_COLOR.deactivated);
          }

          return (
            <Marker
              key={`MarkerKey-${id}`}
              options={{
                icon: {
                  url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                    svg,
                  )}`,
                },
              }}
              position={geo}
              onClick={() => handleToggleOpen(id)}
            >
              {isOpen === id && (
                <InfoWindow position={geo} onCloseClick={handleToggleOpen}>
                  <InfoContainer {...dataRtt[index]} />
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </>
    );
  };

  return renderMarkers();
};

export default Markers;
