import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import CreateLink from '../../../common/orders/CreateLink';
import routes from '../../../pages/routes';

export type CreateReturnOrderLocationStateState = {
  orderId?: string;
  rttId?: number;
};

const CreateReturnOrderLink: React.FC<
  CreateReturnOrderLocationStateState &
    ButtonProps<React.FC<Partial<LinkProps>>>
> = ({ orderId, rttId, ...rest }) => {
  return (
    <CreateLink
      to={{ pathname: routes.returnsNew, state: { orderId, rttId } }}
      {...rest}
    />
  );
};

export default CreateReturnOrderLink;
