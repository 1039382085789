import { COMBINE_IDS_SEPARATOR } from '@rtt-libs/constants';

export const genKeyName = (
  rttId: string | number,
  managerId: string | number,
) => `${rttId}${COMBINE_IDS_SEPARATOR}${managerId}`;

export const parseKeyName = (key: string) => key.split(COMBINE_IDS_SEPARATOR);

export const getRttIdFromKey = (key: string) => parseKeyName(key)[0];
