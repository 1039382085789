import { SubmissionErrors } from 'final-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useSaveSubmitSnackbar = () => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (errors: SubmissionErrors) => {
    if (!errors) {
      enqueueSnackbar(t('common.snackbars.saveSuccess'), {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(t('common.snackbars.saveFailed'), {
        variant: 'error',
      });
    }
  };
};
