export const AGREED_ASSORTMENT_GET_LIST_REQUEST = `@rtt[D]/AGREED_ASSORTMENT_GET_LIST_REQUEST` as const;
export const AGREED_ASSORTMENT_GET_LIST_SUCCESS = `@rtt[D]/AGREED_ASSORTMENT_GET_LIST_SUCCESS` as const;
export const AGREED_ASSORTMENT_GET_LIST_FAILURE = `@rtt[D]/AGREED_ASSORTMENT_GET_LIST_FAILURE` as const;

export const AGREED_ASSORTMENT_CREATE_REQUEST = `@rtt[D]/AGREED_ASSORTMENT_CREATE_REQUEST` as const;
export const AGREED_ASSORTMENT_CREATE_SUCCESS = `@rtt[D]/AGREED_ASSORTMENT_CREATE_SUCCESS` as const;
export const AGREED_ASSORTMENT_CREATE_FAILURE = `@rtt[D]/AGREED_ASSORTMENT_CREATE_FAILURE` as const;

export const AGREED_ASSORTMENT_DELETE_REQUEST = `@rtt[D]/AGREED_ASSORTMENT_DELETE_REQUEST` as const;
export const AGREED_ASSORTMENT_DELETE_SUCCESS = `@rtt[D]/AGREED_ASSORTMENT_DELETE_SUCCESS` as const;
export const AGREED_ASSORTMENT_DELETE_FAILURE = `@rtt[D]/AGREED_ASSORTMENT_DELETE_FAILURE` as const;

export const AGREED_ASSORTMENT_GET_DETAILS_REQUEST = `@rtt[D]/AGREED_ASSORTMENT_GET_DETAILS_REQUEST` as const;
export const AGREED_ASSORTMENT_GET_DETAILS_SUCCESS = `@rtt[D]/AGREED_ASSORTMENT_GET_DETAILS_SUCCESS` as const;
export const AGREED_ASSORTMENT_GET_DETAILS_FAILURE = `@rtt[D]/AGREED_ASSORTMENT_GET_DETAILS_FAILURE` as const;

export const AGREED_ASSORTMENT_EDIT_REQUEST = `@rtt[D]/AGREED_ASSORTMENT_EDIT_REQUEST` as const;
export const AGREED_ASSORTMENT_EDIT_SUCCESS = `@rtt[D]/AGREED_ASSORTMENT_EDIT_SUCCESS` as const;
export const AGREED_ASSORTMENT_EDIT_FAILURE = `@rtt[D]/AGREED_ASSORTMENT_EDIT_FAILURE` as const;

export const AGREED_ASSORTMENT_FETCH_RTT_REQUEST = `@rtt[D]/AGREED_ASSORTMENT_FETCH_RTT_REQUEST` as const;
export const AGREED_ASSORTMENT_FETCH_RTT_SUCCESS = `@rtt[D]/AGREED_ASSORTMENT_FETCH_RTT_SUCCESS` as const;
export const AGREED_ASSORTMENT_FETCH_RTT_FAILURE = `@rtt[D]/AGREED_ASSORTMENT_FETCH_RTT_FAILURE` as const;
