export const EXPORTS_STATUS_SET_REQUEST = '@rtt[D]/EXPORTS_STATUS_SET_REQUEST' as const;
export const EXPORTS_STATUS_SET_SUCCESS = '@rtt[D]/EXPORTS_STATUS_SET_SUCCESS' as const;
export const EXPORTS_STATUS_SET_FAILURE = '@rtt[D]/EXPORTS_STATUS_SET_FAILURE' as const;

export const EXPORTS_STATUS_MULTI_SET_REQUEST = '@rtt[D]/EXPORTS_STATUS_MULTI_SET_REQUEST' as const;
export const EXPORTS_STATUS_MULTI_SET_SUCCESS = '@rtt[D]/EXPORTS_STATUS_MULTI_SET_SUCCESS' as const;
export const EXPORTS_STATUS_MULTI_SET_FAILURE = '@rtt[D]/EXPORTS_STATUS_MULTI_SET_FAILURE' as const;

export const EXPORTS_STATUS_GET_REQUEST = '@rtt[D]/EXPORTS_STATUS_GET_REQUEST' as const;
export const EXPORTS_STATUS_GET_SUCCESS = '@rtt[D]/EXPORTS_STATUS_GET_SUCCESS' as const;
export const EXPORTS_STATUS_GET_FAILURE = '@rtt[D]/EXPORTS_STATUS_GET_FAILURE' as const;
export const EXPORTS_STATUS_CLEAR = '@rtt[D]/EXPORTS_STATUS_CLEAR' as const;

export const EXPORTS_DOWNLOAD_LINK_SET = '@rtt[D]/EXPORTS_DOWNLOAD_LINK_SET' as const;
export const EXPORTS_DOWNLOAD_LINK_UPDATE = '@rtt[D]/EXPORTS_DOWNLOAD_LINK_UPDATE' as const;
export const EXPORTS_DOWNLOAD_LINK_UPDATE_FAILURE = '@rtt[D]/EXPORTS_DOWNLOAD_LINK_UPDATE_FAILURE' as const;

export const EXPORTS_START_REQUEST = '@rtt[D]/EXPORTS_START_REQUEST' as const;
export const EXPORTS_START_SUCCESS = '@rtt[D]/EXPORTS_START_SUCCESS' as const;
export const EXPORTS_START_FAILURE = '@rtt[D]/EXPORTS_START_FAILURE' as const;

export const EXPORTS_START_MULTI_REQUEST = '@rtt[D]/EXPORTS_START_MULTI_REQUEST' as const;
export const EXPORTS_START_MULTI_SUCCESS = '@rtt[D]/EXPORTS_START_MULTI_SUCCESS' as const;
export const EXPORTS_START_MULTI_FAILURE = '@rtt[D]/EXPORTS_START_MULTI_FAILURE' as const;

export const EXPORTS_GET_DOCUMENTS_REQUEST = '@rtt[D]/EXPORTS_GET_DOCUMENTS_REQUEST' as const;
export const EXPORTS_GET_DOCUMENTS_SUCCESS = '@rtt[D]/EXPORTS_GET_DOCUMENTS_SUCCESS' as const;
export const EXPORTS_GET_DOCUMENTS_FAILURE = '@rtt[D]/EXPORTS_GET_DOCUMENTS_FAILURE' as const;
