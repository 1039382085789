import { useGoogleMap } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Area } from '../duck/model';
import { getAllAreasSel } from '../duck/selectors';
import PolygonDisplay from './PolygonDisplay';
import { useTerritoryInteraction } from './TerritoriesInteraction';

const extendBoundByPaths = (bounds: google.maps.LatLngBounds, area: Area) => {
  area.paths.forEach(path => bounds.extend(path));
};

const Polygons: React.FC = () => {
  const areas = useSelector(getAllAreasSel);

  const map = useGoogleMap();

  const {
    onPolygonClick,
    expanded,
    editableId,
    onPolygonComplete,
    modifiedPolygons,
  } = useTerritoryInteraction();

  /**
   * Pan & zoom to polygon which was expanded in panel.
   * Pan & zoom to all polygons if there aren't any expanded panel.
   */
  useEffect(() => {
    if (map && areas.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      if (expanded !== null) {
        const expandedArea = areas.find(area => area.id === expanded);
        if (expandedArea) {
          extendBoundByPaths(bounds, expandedArea);
        }
      } else {
        areas.forEach(({ paths }) =>
          paths.forEach(path => bounds.extend(path)),
        );
      }
      map.fitBounds(bounds);
    }
  }, [areas, expanded, map]);

  const handleClick = (area: Area) => () => {
    onPolygonClick(area.id);
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      extendBoundByPaths(bounds, area);
      map.fitBounds(bounds);
    }
  };

  const editedAreas: (Area & {
    changed?: boolean;
    deleted?: boolean;
  })[] = areas.map(area => {
    if (modifiedPolygons.changed && modifiedPolygons.changed[area.id]) {
      return {
        ...area,
        paths: modifiedPolygons.changed[area.id],
        changed: true,
      };
    }
    if (
      modifiedPolygons.deleted &&
      modifiedPolygons.deleted.includes(area.id)
    ) {
      return { ...area, deleted: true };
    }
    return area;
  });

  return (
    <>
      {editedAreas.map(area => {
        const isEditable = editableId === area.id;

        return (
          <PolygonDisplay
            onClick={handleClick(area)}
            editable={isEditable}
            onPolygonChange={onPolygonComplete}
            area={area}
            key={area.id}
            changed={area.changed}
            deleted={area.deleted}
            selected={area.id === expanded}
          />
        );
      })}
    </>
  );
};

export default Polygons;
