import React from 'react';
import { Selector } from 'react-redux';
import { EnhancedProduct } from '../../../api/orders';
import AddProductButton from './AddProductButton';
import { AddProductsProvider } from './AddProductsContext';

type Props = {
  existingProductIds: string[];
  initialCheckedIds?: string[];
  onSubmit(products: { [key: string]: EnhancedProduct }): void;
  productsSelector: Selector<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    Record<EnhancedProduct['id'], EnhancedProduct>
  >;
  orderId?: string;
  disabled?: boolean;
};

const AddProducts: React.FC<Props> = ({
  existingProductIds,
  initialCheckedIds,
  onSubmit,
  productsSelector,
  children,
  orderId,
  disabled,
}) => {
  return (
    <AddProductsProvider
      existing={existingProductIds}
      initialChecked={initialCheckedIds}
      productsSelector={productsSelector}
      orderId={orderId}
    >
      <AddProductButton onSubmit={onSubmit} disabled={disabled}>
        {children}
      </AddProductButton>
    </AddProductsProvider>
  );
};

export default AddProducts;
