import get from 'lodash/fp/get';
import type { Selector } from 'react-redux';
import { createSelector, ParametricSelector } from 'reselect';
import type { Rtt } from '../../api/rtt';
import type { RttAreas } from '../../api/rttAreas';
import { branchName } from './const';
import type { BranchState } from './reducer';
import type { BrandManagerConnection } from '../../types';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const getRttCollectionSel: Selector<
  PartialRootState,
  Record<Rtt['id'], Rtt>
> = get([branchName, 'collection']);

export const getAreasCollectionSel: Selector<
  PartialRootState,
  Record<RttAreas['id'], RttAreas>
> = get([branchName, 'areas']);

export const selectRttError: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

const getRttLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

const getAreasIdsSel: Selector<PartialRootState, RttAreas['id'][]> = get([
  branchName,
  'listIdsAreas',
]);

const getSearchValue: Selector<PartialRootState, string> = get([
  branchName,
  'searchValue',
]);

export const getRttDetailsSel: Selector<PartialRootState, Rtt | null> = get([
  branchName,
  'currentRtt',
]);

export const selectSearchValue: Selector<
  PartialRootState,
  string
> = createSelector([getSearchValue], searchValue => searchValue);

const getSearchList: Selector<
  PartialRootState,
  { [key: string]: number }
> = get([branchName, 'byPhoneName']);

export const selectSearch: Selector<
  PartialRootState,
  number[]
> = createSelector(
  [getSearchList, getSearchValue],
  (searchArray, searchValue) => {
    const idsArray = [] as number[];
    Object.entries(searchArray).forEach(
      ([key, value]) =>
        key.includes(searchValue.toLowerCase()) && idsArray.push(value),
    );
    return idsArray;
  },
);

export const selectRtt: Selector<
  PartialRootState,
  {
    data: Rtt[];
    loading: boolean;
    error: string | null;
    idList: number[];
  }
> = createSelector(
  [getRttCollectionSel, selectSearch, getRttLoadingSel, selectRttError],
  (collection, dataSearch, loading, error) => {
    return {
      data: dataSearch.map(id => collection[id]),
      loading,
      error,
      idList: dataSearch,
    };
  },
);

export const selectAreas: Selector<
  PartialRootState,
  {
    dataAreas: RttAreas[];
  }
> = createSelector(
  [getAreasCollectionSel, getAreasIdsSel, getRttLoadingSel, selectRttError],
  (areas, ids) => ({
    dataAreas: ids.map(id => areas[id]),
  }),
);

export const selectDetails: Selector<
  PartialRootState,
  {
    data: Rtt | null;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [getRttDetailsSel, getRttLoadingSel, selectRttError],
  (data, loading, error) => ({
    data,
    loading,
    error,
  }),
);

export const selectRttDetailsById: ParametricSelector<
  PartialRootState,
  number | undefined,
  {
    data: Rtt | null;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    getRttCollectionSel,
    getRttLoadingSel,
    selectRttError,
    (_: PartialRootState, id: number | undefined) => id,
  ],
  (data, loading, error, id) => ({
    data: id ? data[id] : null,
    loading,
    error,
  }),
);

export const selectRttDetailsByIdWrapped = (id: number | undefined) => (
  state: PartialRootState,
) => selectRttDetailsById(state, id);

export const selectRttManagerListData: Selector<
  PartialRootState,
  BrandManagerConnection[]
> = get([branchName, 'managerList']);

export const selectRttManagerListLoading: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'managerListLoading']);

export const selectRttManagerListError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'managerListError']);
