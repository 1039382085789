import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { GoBackButton } from '../Buttons';

const HeadingWithBackButton: React.FC = ({ children }) => {
  return (
    <Box mb={2}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <GoBackButton />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h1">
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeadingWithBackButton;
