import React from 'react';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
  makeGroupedFieldName,
  makeProductsPayloadFieldNamePrefix,
} from './OrderProductsTable/generateFieldName';

interface Props {
  groupId: string;
}

const ResetPayloadGroupOnEmptyProducts = ({ groupId }: Props) => {
  const form = useForm();

  return (
    <OnChange name={makeProductsPayloadFieldNamePrefix(groupId)}>
      {value => {
        if (value) return;

        const payloadFieldName = makeGroupedFieldName(groupId);

        form.batch(() => {
          form.change(payloadFieldName);
          form.resetFieldState(payloadFieldName);
        });
      }}
    </OnChange>
  );
};

export default ResetPayloadGroupOnEmptyProducts;
