import { Pagination } from '@rtt-libs/types';
import at from 'lodash/fp/at';
import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  AgreedAssortment,
  RttSelectOption,
  AgreedAssortmentWithItems,
} from '../types';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectAgreedAssortments: Selector<
  PartialRootState,
  Record<AgreedAssortment['id'], AgreedAssortment | AgreedAssortmentWithItems>
> = get([branchName, 'collection']);

export const selectAgreedAssortmentIds: Selector<
  PartialRootState,
  AgreedAssortment['id'][]
> = get([branchName, 'itemIds']);

export const selectLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const selectError: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const selectPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'pagination',
]);

export const selectAgreedAssortmentsData: Selector<
  PartialRootState,
  {
    data: AgreedAssortment[];
    pagination: Pagination;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    selectAgreedAssortments,
    selectAgreedAssortmentIds,
    selectPagination,
    selectLoading,
    selectError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: at(ids, collection),
    pagination,
    loading,
    error,
  }),
);

const selectAgreedAssortmentById = createSelector(
  [
    selectAgreedAssortments,
    selectLoading,
    selectError,
    (_: PartialRootState, id: string) => id,
  ],
  (collection, loading, error, id) => ({
    data: get(id, collection),
    loading,
    error,
  }),
);

export const selectAgreedAssortment = (id: string) => (
  state: PartialRootState,
) => selectAgreedAssortmentById(state, id);

export const selectRttOptions = createSelector<
  PartialRootState,
  RttSelectOption[],
  boolean,
  { data: RttSelectOption[]; loading: boolean }
>(
  [get([branchName, 'rttOptions']), get([branchName, 'rttOptionsLoading'])],
  (data, loading) => ({
    data,
    loading,
  }),
);
