import { Box, List, Paper, Typography } from '@material-ui/core';
import { useAuth } from '@rtt-libs/auth';
import at from 'lodash/at';
import React from 'react';
import { useField, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ProductEssential } from '../../../api/agreedAssortments';
import { selectProductsCollection } from '../../../productsAddition/duck/selectors';
import { PERMISSIONS } from '../../../rules';
import ProductItem from './ProductItem';

const PRODUCT_ID_LIST_FIELD_NAME = 'productIdList';

type ProductListProps = {
  emptyMessage?: React.ReactNode;
};

const ProductList: React.FC<ProductListProps> & {
  Loading: React.FC<LoadingProps>;
} = ({ emptyMessage }) => {
  const { isPermittedTo } = useAuth();
  const products = useSelector(selectProductsCollection);
  const { change } = useForm();
  const { input } = useField(PRODUCT_ID_LIST_FIELD_NAME, {
    subscription: { value: true, error: true },
  });

  if (!input.value.length)
    return (
      <Box mt={2}>
        <Typography color="textSecondary">{emptyMessage}</Typography>
      </Box>
    );

  const productsData = at(products, input.value);

  const onDelete = (id: string) => {
    change(
      PRODUCT_ID_LIST_FIELD_NAME,
      input.value.filter((existingId: string) => existingId !== id),
    );
  };

  return (
    <ProductListLayout>
      {productsData.map(product => {
        if (!product) return null;
        return (
          <ProductItem
            key={product.id}
            product={(product as unknown) as ProductEssential}
            onDelete={
              isPermittedTo(PERMISSIONS.NAV_AGREED_ASSORTMENT_EDIT)
                ? onDelete
                : undefined
            }
          />
        );
      })}
    </ProductListLayout>
  );
};

const ProductListLayout: React.FC = ({ children }) => (
  <Box mt={2}>
    <Paper variant="outlined">
      <List disablePadding>{children}</List>
    </Paper>
  </Box>
);

type LoadingProps = {
  count?: number;
};

const Loading = ({ count = 5 }: LoadingProps) => (
  <ProductListLayout>
    {[...Array(count).keys()].map(id => {
      return <ProductItem.Loading key={id} />;
    })}
  </ProductListLayout>
);

ProductList.Loading = Loading;

export default ProductList;
