import { CircularProgress, Paper, LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React from 'react';
import { DataAreas, DataRtt, GeoLocation } from '@rtt-libs/types';
import { GOOGLE_MAP_API_KEY } from '../environment';
import Markers from './Markers';
import Polygons from './Polygons';

const useStyles = makeStyles(() =>
  createStyles({
    fillContainer: {
      height: 'calc(100vh - 210px)',
      width: '100%',
    },
  }),
);

const MAP_LIBRARIES = ['drawing'];

const MAP_INITIAL_CENTER = {
  lat: 47.834,
  lng: 35.11,
};

type Props = {
  dataRtt?: DataRtt[];
  dataAreas?: DataAreas[];
  initialCenter?: GeoLocation;
  zoom?: number;
  loading?: boolean;
};

const Map: React.FC<Props> = ({
  loading,
  dataRtt,
  dataAreas,
  initialCenter = MAP_INITIAL_CENTER,
  zoom = 12,
}) => {
  const classes = useStyles();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const renderMap = () => {
    return (
      <Paper className={classes.fillContainer}>
        {loading && <LinearProgress />}
        <GoogleMap
          id="data-example"
          zoom={zoom}
          center={initialCenter}
          mapContainerClassName={classes.fillContainer}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          {dataRtt && <Markers dataRtt={dataRtt} />}
          {dataAreas && <Polygons dataAreas={dataAreas} />}
        </GoogleMap>
      </Paper>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <CircularProgress />;
};

export default Map;
