import { Reducer } from 'redux';
import { Actions } from './actions';
import * as TYPES from './types';
import { FinishFormValues } from '../types';

const initialState = {
  loading: false,
  error: null as string | null,
  initialValuesLoading: false,
  initialValues: undefined as Partial<FinishFormValues> | undefined,
  status: undefined as string | undefined,
  notices: null as string | null,
};

export type State = typeof initialState;

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FINISH_REG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TYPES.FINISH_REG_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'new',
      };
    case TYPES.FINISH_REG_INITIAL_VALUES_REQUEST:
      return {
        ...state,
        initialValuesLoading: true,
      };
    case TYPES.FINISH_REG_INITIAL_VALUES_SUCCESS:
      return {
        ...state,
        initialValuesLoading: false,
        initialValues: action.payload.values,
        status: action.payload.status,
        notices: action.payload.notices ?? null,
      };
    case TYPES.FINISH_REG_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case TYPES.FINISH_REG_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TYPES.FINISH_REG_INITIAL_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        initialValuesLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
