import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductEssential } from '../../../api/agreedAssortments';

interface Props {
  product: ProductEssential;
  onDelete?: (id: ProductEssential['id']) => void;
}

const ProductItem: React.FC<Props> & { Loading: React.FC } = ({
  onDelete,
  product,
}) => {
  const [t] = useTranslation();
  const thumbnailSrc =
    product.image?.related.data[0].src || product?.image?.src;

  const renderThumbnail = (
    <ListItemAvatar>
      <Avatar variant="rounded" src={thumbnailSrc}>
        <ImageIcon />
      </Avatar>
    </ListItemAvatar>
  );

  return (
    <ProductItemLayout
      thumbnail={renderThumbnail}
      primary={product.title}
      secondary={`${t('product.skuContracted')} ${product?.sku}`}
      action={
        onDelete ? <DeleteButton onClick={() => onDelete(product.id)} /> : null
      }
    />
  );
};

type ProductItemLayoutProps = {
  thumbnail: React.ReactElement;
  primary: React.ReactNode;
  secondary: React.ReactNode;
  action: React.ReactElement | null;
};

const ProductItemLayout: React.FC<ProductItemLayoutProps> = ({
  thumbnail,
  primary,
  secondary,
  action,
}) => (
  <ListItem className="MuiListItem-button">
    <ListItemAvatar>{thumbnail}</ListItemAvatar>
    <ListItemText primary={primary} secondary={secondary} />
    {action && <ListItemSecondaryAction>{action}</ListItemSecondaryAction>}
  </ListItem>
);

type DeleteButtonProps = {
  onClick?: () => void;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => (
  <IconButton
    edge="end"
    aria-label="delete"
    disabled={!onClick}
    onClick={onClick}
  >
    <DeleteIcon />
  </IconButton>
);

const Loading: React.FC = () => {
  const renderThumbnail = (
    <Box clone bgcolor="transparent">
      <Avatar variant="rounded">
        <Skeleton variant="rect" width="100%" height="100%" />
      </Avatar>
    </Box>
  );

  return (
    <ProductItemLayout
      thumbnail={renderThumbnail}
      primary={<Skeleton width="60%" />}
      secondary={<Skeleton width="20%" />}
      action={<DeleteButton />}
    />
  );
};

ProductItem.Loading = Loading;

export default ProductItem;
