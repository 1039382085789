import { ChangePassword } from '@rtt-libs/auth';
import { FORM_ERROR } from 'final-form';
import { EditProfile, Profile } from '../../api/profile';
import * as types from './types';

export const changePasswordRequest = (values: ChangePassword) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload: values,
});

export const changePasswordSuccess = () => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordResponse = (
  submissionErrors?: Partial<EditProfile & { [FORM_ERROR]: string }>,
) => ({
  type: types.CHANGE_PASSWORD_RESPONSE,
  payload: submissionErrors,
});

export const changePasswordFailure = (error: string) => ({
  type: types.CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const getProfileRequest = () => ({
  type: types.GET_PROFILE_REQUEST,
});

export const getProfileSuccess = (payload: Profile) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileFailure = (error: string) => ({
  type: types.GET_PROFILE_FAILURE,
  payload: error,
});

export const editProfileRequest = (values: EditProfile) => ({
  type: types.EDIT_PROFILE_REQUEST,
  payload: values,
});

export const editProfileResponse = (
  submissionErrors?: Partial<EditProfile & { [FORM_ERROR]: string }>,
) => ({
  type: types.EDIT_PROFILE_RESPONSE,
  payload: submissionErrors,
});

export const editProfileSuccess = (payload: Profile) => ({
  type: types.EDIT_PROFILE_SUCCESS,
  payload,
});

export const editProfileFailure = (error: string) => ({
  type: types.EDIT_PROFILE_FAILURE,
  payload: error,
});

export type Actions =
  | ReturnType<typeof getProfileSuccess>
  | ReturnType<typeof getProfileRequest>
  | ReturnType<typeof getProfileFailure>
  | ReturnType<typeof changePasswordSuccess>
  | ReturnType<typeof changePasswordRequest>
  | ReturnType<typeof changePasswordResponse>
  | ReturnType<typeof changePasswordFailure>
  | ReturnType<typeof editProfileSuccess>
  | ReturnType<typeof editProfileRequest>
  | ReturnType<typeof editProfileFailure>
  | ReturnType<typeof editProfileResponse>;
