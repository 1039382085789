import React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { useFormState } from 'react-final-form';

type PromptProps = {
  message: string;
  when?: boolean;
};

type PromptWithMessage = Partial<PromptProps>;

export const PromptWithMessage = (props: PromptWithMessage) => {
  const [t] = useTranslation();

  const message = t('common.leavePromptMessage');

  return <Prompt message={message} {...props} />;
};

const PromptDirtyForm = (props: PromptWithMessage) => {
  const [t] = useTranslation();
  const { dirty, submitting } = useFormState({
    subscription: { dirty: true, submitting: true },
  });

  const message = t('common.leaveDirtyFormPromptMessage');

  return (
    <PromptWithMessage
      message={message}
      when={dirty && !submitting}
      {...props}
    />
  );
};

export default PromptDirtyForm;
