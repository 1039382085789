import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { isEmpty, mapKeys } from 'lodash/fp';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import { Rtt } from '../../../api/rtt';
import { RecommendedOrderValue } from '../../../balances/types';
import { OrderCreateSummary } from '../../../common';
import PromptDirtyForm from '../../../common/PromptDirtyForm';
import routes from '../../../pages/routes';
import { promiseListener } from '../../../setup';
import reduceOrderTotal from '../../../utils/reduceTotalPrice';
import { PAYLOAD_GROUPED_KEY } from '../../constants';
import { types } from '../../duck';
import { selectMinTotal } from '../../duck/selectors';
import type * as OrderTypes from '../../types';
import { collectProductsFromPayloadByManagerIds } from '../../utils/orderPayloadTransformers';
import { parseOrderGroupIdKey, ShippingDateField } from '../commonOrders';
import {
  OrderCommentField,
  OrderCommentsWrapper,
} from '../commonOrders/OrderComments';
import OrderProductsTable from '../commonOrders/OrderProductsTable';
import ProductOrderSelector from '../commonOrders/OrderProductsTable/ProductOrderSelector';
import OrderTotal from '../commonOrders/OrderTotal';

interface Props {
  rtt: Rtt;
  changeButton?: React.ReactElement;
  initialValues?: Partial<
    OrderTypes.OrderCreateValues<
      OrderTypes.OrderedProduct | RecommendedOrderValue
    >
  >;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

const OrderCreateDetails: React.FC<Props> = ({
  rtt,
  changeButton,
  initialValues,
}) => {
  const rttId = rtt.id;
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { value: minTotal } = useSelector(selectMinTotal);

  const isRecommendedOrderValue = (
    value:
      | OrderTypes.GroupedPayload<OrderTypes.OrderedProduct>
      | OrderTypes.GroupedPayload<RecommendedOrderValue>,
  ): value is OrderTypes.GroupedPayload<RecommendedOrderValue> => {
    return Object.values(
      (value as OrderTypes.GroupedPayload<OrderTypes.OrderedProduct>)[
        PAYLOAD_GROUPED_KEY
      ],
    ).some(
      entry =>
        !!entry.payload?.products &&
        Object.values(entry.payload?.products).some(product => !product.id),
    );
  };

  const validateMinTotal = useCallback(
    (
      values: Partial<
        | OrderTypes.OrderCreateValues<OrderTypes.OrderedProduct>
        | OrderTypes.OrderCreateValues<RecommendedOrderValue>
      >,
    ) => {
      const errors = {} as Record<string, string>;

      if (!values[PAYLOAD_GROUPED_KEY])
        return { products: t('validation.valueMissing') };

      const valuesWithPayload = values as Required<
        | OrderTypes.OrderCreateValues<OrderTypes.OrderedProduct>
        | OrderTypes.OrderCreateValues<RecommendedOrderValue>
      >;

      const orderProducts = isRecommendedOrderValue(valuesWithPayload)
        ? {}
        : collectProductsFromPayloadByManagerIds(
            valuesWithPayload[PAYLOAD_GROUPED_KEY],
          );

      const orderTotal = Object.values(orderProducts).reduce(
        reduceOrderTotal(orderProducts),
        0,
      );

      if (orderTotal < minTotal) {
        errors[FORM_ERROR] = t(
          'distributor.orders.validation.minTotalUnderflow',
        );
      }

      if (!orderProducts) {
        errors.products = t('validation.valueMissing');
      }

      return isEmpty(errors) ? undefined : errors;
    },
    [t, minTotal],
  );

  const onFulfillment = (response: OrderTypes.Order) => {
    enqueueSnackbar(t('distributor.orders.details.successEditMessage'), {
      variant: 'success',
    });

    history.push(routes.orders);
    return undefined;
  };

  const onRejection = (
    errors?: Partial<OrderTypes.OrderEditValues & { [FORM_ERROR]: string }>,
  ) => {
    enqueueSnackbar(
      errors?.shippingDate ||
        // TODO: write correct errors selector
        // errors?.managerDescription ||
        // errors?.payload ||
        errors?.[FORM_ERROR] ||
        t('common.errorOccurred'),
      {
        variant: 'error',
      },
    );
    return errors;
  };

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.ORDER_CREATE_REQUEST}
      resolve={types.ORDER_CREATE_SUCCESS}
      reject={types.ORDER_CREATE_FAILURE}
    >
      {onSubmit => (
        <Form
          initialValues={initialValues}
          validate={validateMinTotal}
          subscription={{ submitting: true }}
          onSubmit={values => {
            const withParsedKeys = mapKeys(
              groupKey => parseOrderGroupIdKey(groupKey)[0],
              values[PAYLOAD_GROUPED_KEY],
            );

            return onSubmit({
              ...values,
              [PAYLOAD_GROUPED_KEY]: withParsedKeys,
            }).then(onFulfillment, onRejection);
          }}
        >
          {({ handleSubmit, submitting }) => (
            <Paper className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <PromptDirtyForm />

                <Field
                  component="input"
                  name="rttId"
                  initialValue={rttId}
                  type="hidden"
                />

                <Field component="input" name="suggestOrderId" type="hidden" />

                <Grid container spacing={3}>
                  <OrderCreateSummary
                    rtt={rtt}
                    changeButton={changeButton}
                    totalElement={
                      <OrderTotal
                        originTotal={0}
                        totalOnly
                        minTotal={minTotal}
                      />
                    }
                  />

                  <Grid item xs={6}>
                    <ShippingDateField required disabled={submitting} />
                  </Grid>

                  <OrderProductsTable
                    showEditButton
                    submitting={submitting}
                    productSelection={
                      <ProductOrderSelector
                        rttId={rttId}
                        disabled={submitting}
                      />
                    }
                    comments={name => (
                      <OrderCommentsWrapper>
                        <Grid item xs={12} md={6}>
                          <OrderCommentField
                            name={name}
                            {...(submitting && {
                              InputProps: {
                                readOnly: true,
                              },
                            })}
                          />
                        </Grid>
                      </OrderCommentsWrapper>
                    )}
                  />
                </Grid>
              </form>
            </Paper>
          )}
        </Form>
      )}
    </MakeAsyncFunction>
  );
};

export default OrderCreateDetails;
