import { Paper } from '@material-ui/core';
import { EmptyAlert, EnhancedPaginatedTable } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Brand } from '../../types';
import { BrandSearchParams } from '../types';
import FilterRow from './FilterRow';
import brandsTableConfig from './tableConfig';

type Props = {
  brands: Brand[];
  loading?: boolean;
  onStateChange: (state: Partial<BrandSearchParams>) => void;
};

/**
 * Wrapper component to pass brands data into common table component
 * Also handles routing to Brand detail page.
 */
const BrandsTable: React.FC<Props> = ({ brands, loading, onStateChange }) => {
  const [t] = useTranslation();

  const columnConfig = brandsTableConfig(t);

  const localeTableLabels = {
    sort: t('admin.tables.common.sort', 'Sort'),
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <>
      <FilterRow onChange={onStateChange} />

      <Paper>
        <EnhancedPaginatedTable
          onStateChange={onStateChange}
          loading={loading}
          // TODO: change typings of table entries to accept different subtypes of Record
          entries={(brands as unknown) as Record<string, unknown>[]}
          columnsConfig={columnConfig}
          localeLabels={localeTableLabels}
          withoutPagination
          emptyMessage={<EmptyAlert />}
        />
      </Paper>
    </>
  );
};

export default BrandsTable;
