import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { deStringifyKeys } from '@rtt-libs/views';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { useHistory } from 'react-router-dom';
import { CreateDiscountValues } from '../../../api/discounts';
import routes from '../../../pages/routes';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import DiscountCreate from './DiscountCreate';

const TypedForm = (props: FormProps<CreateDiscountValues>) => Form(props);

const DiscountCreateContainer: React.FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.DISCOUNT_CREATE_REQUEST}
        resolve={types.DISCOUNT_CREATE_RESPONSE}
        reject={types.DISCOUNT_CREATE_FAILURE}
      >
        {onSubmit => (
          <TypedForm
            onSubmit={values => {
              return onSubmit({
                ...values,
                categoryDiscounts:
                  values.categoryDiscounts &&
                  deStringifyKeys(values.categoryDiscounts),
                brandDiscounts:
                  values.brandDiscounts &&
                  deStringifyKeys(values.brandDiscounts),
              }).then(response => {
                if (!response.id) {
                  enqueueSnackbar(response[FORM_ERROR], { variant: 'error' });
                  return response;
                }
                enqueueSnackbar(t('distributor.discounts.createSucceeded'), {
                  variant: 'success',
                });
                history.push(`${routes.discounts}/${response.id}`);
                return undefined;
              });
            }}
            render={DiscountCreate}
          />
        )}
      </MakeAsyncFunction>
    </MuiPickersUtilsProvider>
  );
};

export default DiscountCreateContainer;
