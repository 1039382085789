import { OrderedQtyValue, OrderedWeightValue } from '../types';

export const shouldRecommendOrder = (
  orderValues: Record<string, Partial<OrderedQtyValue & OrderedWeightValue>>,
) => {
  return !!Object.values(orderValues).reduce(
    (acc, { qty = 0, orderWeight = 0 }) => acc + qty + orderWeight,
    0,
  );
};
