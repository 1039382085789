import { Pagination } from '@rtt-libs/types';
import { keyBy, omit } from 'lodash/fp';
import { Reducer } from 'redux';
import { PriceType } from '../../api/priceTypes';
import { Actions } from './actions';
import * as types from './types';

const initialState = {
  loading: false,
  collection: {} as Record<PriceType['id'], PriceType>,
  searchCollection: {} as Record<PriceType['id'], PriceType>,
  listIds: [] as PriceType['id'][],
  pagination: {} as Pagination,
  error: null as string | null,
};

export type BranchState = typeof initialState;

const reducer: Reducer<BranchState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.DELETE_PRICE_TYPES_REQUEST:
    case types.EDIT_PRICE_TYPES_REQUEST:
    case types.SEARCH_PRICE_TYPES_REQUEST:
    case types.GET_PRICE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        collection: keyBy('id', action.payload),
      };
    case types.GET_PRICE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SEARCH_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchCollection: {
          ...state.searchCollection,
          ...keyBy('id', action.payload),
        },
        listIds: action.payload.map(price => price.id),
        pagination: action.meta.pagination,
      };
    case types.CREATE_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchCollection: {
          ...state.searchCollection,
          [action.payload.id]: action.payload,
        },
        listIds: [action.payload.id, ...state.listIds],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case types.EDIT_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchCollection: {
          ...state.searchCollection,
          [action.payload.id]: action.payload,
        },
      };
    case types.DELETE_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchCollection: {
          ...omit(action.payload, state.searchCollection),
        },
        listIds: state.listIds.filter(id => id !== action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
