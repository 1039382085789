import { Grid, TextField, makeStyles, createStyles } from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setCategoriesSearchSuccess } from '../duck/actions';

const useStyles = makeStyles(
  createStyles({
    search: {
      marginBottom: '10px',
    },
  }),
);

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const onChangeDispatch = (value: string) => {
    dispatch(setCategoriesSearchSuccess(value));
  };

  const debounceChange = debounce(onChangeDispatch, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange(e.target.value);
  };

  return (
    <Grid item xs={12} className={classes.search}>
      <TextField
        name="search"
        label={t('distributor.rtt.search', 'Search')}
        onChange={onSearchChange}
        fullWidth
      />
    </Grid>
  );
};

export default FilterRow;
