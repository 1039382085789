import type { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import type {
  Administrator,
  AdministratorValues,
  SearchParams,
  EditAdministratorValues,
} from '../types';
import * as types from './types';

type AdministratorErrors = Partial<
  Record<keyof AdministratorValues, string>
> & {
  [FORM_ERROR]: string;
};

export const searchAdministratorsRequest = (params: SearchParams) => ({
  type: types.ADMINISTRATORS_SEARCH_REQUEST,
  payload: params,
});

export const searchAdministratorsSuccess = (
  payload: Administrator[],
  meta: { pagination: Pagination },
) => ({
  type: types.ADMINISTRATORS_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchAdministratorsFailure = (error: string) => ({
  type: types.ADMINISTRATORS_SEARCH_FAILURE,
  payload: error,
});

export const createAdministratorRequest = (values: AdministratorValues) => ({
  type: types.ADMINISTRATORS_CREATE_REQUEST,
  payload: values,
});

export const createAdministratorSuccess = (payload: Administrator) => ({
  type: types.ADMINISTRATORS_CREATE_SUCCESS,
  payload,
});

export const createAdministratorFailure = (
  submissionErrors?: AdministratorErrors,
) => ({
  type: types.ADMINISTRATORS_CREATE_FAILURE,
  payload: submissionErrors,
});

export const editAdministratorRequest = (values: EditAdministratorValues) => ({
  type: types.ADMINISTRATORS_EDIT_REQUEST,
  payload: values,
});

export const editAdministratorFailure = (
  submissionErrors?: AdministratorErrors,
) => ({
  type: types.ADMINISTRATORS_EDIT_FAILURE,
  payload: submissionErrors,
});

export const editAdministratorSuccess = (payload: Administrator) => ({
  type: types.ADMINISTRATORS_EDIT_SUCCESS,
  payload,
});

export const changeStatusAdministratorRequest = (
  id: Administrator['id'],
  status: boolean,
) => ({
  type: types.ADMINISTRATORS_CHANGE_STATUS_REQUEST,
  payload: {
    id,
    status,
  },
});

export const changeStatusAdministratorSuccess = (payload: Administrator) => ({
  type: types.ADMINISTRATORS_CHANGE_STATUS_SUCCESS,
  payload,
});

export const changeStatusAdministratorFailure = (error: string) => ({
  type: types.ADMINISTRATORS_CHANGE_STATUS_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof searchAdministratorsRequest
  | typeof searchAdministratorsSuccess
  | typeof searchAdministratorsFailure
  | typeof createAdministratorRequest
  | typeof createAdministratorSuccess
  | typeof createAdministratorFailure
  | typeof editAdministratorRequest
  | typeof editAdministratorSuccess
  | typeof editAdministratorFailure
  | typeof changeStatusAdministratorRequest
  | typeof changeStatusAdministratorSuccess
  | typeof changeStatusAdministratorFailure
>;
