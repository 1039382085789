import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExportCheckbox } from '../../../exports';
import { setEntryExportStatusRequest } from '../../../exports/duck/actions';
import { selectExportEntriesStateById } from '../../../exports/duck/selectors';
import { ExportStatus } from '../../../exports/types';

const SELLING_META_TYPE = { type: 'selling' as const };

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const ExportCell: React.FC<Props> = ({ id, value }) => {
  const exportStatus = value as ExportStatus | undefined;
  const orderId = String(id);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(
    selectExportEntriesStateById('selling', orderId),
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`${orderId}: ${error}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, error, orderId]);
  const onChange = (checked: boolean) => {
    dispatch(
      setEntryExportStatusRequest(
        { id: orderId, isExported: checked },
        SELLING_META_TYPE,
      ),
    );
  };

  return (
    <ExportCheckbox
      onChange={onChange}
      status={exportStatus?.status}
      disabled={loading}
      error={error}
    />
  );
};

export default ExportCell;
