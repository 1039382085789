import { Box, Typography } from '@material-ui/core';
import { PrivateRoute, useAuth } from '@rtt-libs/auth';
import { ChatNotificationSwitch, useSystemChannel } from '@rtt-libs/chats';
import { ORDER_STATUSES } from '@rtt-libs/constants';
import { actions as notificationActions } from '@rtt-libs/state-containers';
import { Layout, Logo } from '@rtt-libs/views';
import React, { Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Navigation, SuspenseIndicator } from '../common';
import Menu from '../common/Menu';
import { LOGIN_PAGE, ROUTE_WITHOUT_PRIVILEGES } from '../environment';
import { PERMISSIONS } from '../rules';
import routes from './routes';

const APP_INITIAL_PAGE = '/';

const AdministratorsPage = React.lazy(() => import('./AdministratorsPage'));
const AgreedAssortmentCreatePage = React.lazy(() =>
  import('./AgreedAssortmentCreatePage'),
);
const AgreedAssortmentEditPage = React.lazy(() =>
  import('./AgreedAssortmentEditPage'),
);
const AgreedAssortmentListPage = React.lazy(() =>
  import('./AgreedAssortmentListPage'),
);
const AssortmentPage = React.lazy(() => import('./AssortmentPage'));
const BalanceListPage = React.lazy(() => import('./BalanceListPage'));
const BalanceDetailsPage = React.lazy(() => import('./BalanceDetailsPage'));
const BalanceCreatePage = React.lazy(() => import('./BalanceCreatePage'));
const BrandsPage = React.lazy(() => import('./BrandsPage'));
const CategoriesPage = React.lazy(() => import('./CategoriesPage'));
const ChatsWithRttPage = React.lazy(() => import('./ChatsWithRttPage'));
const MassMailPage = React.lazy(() => import('./MassMailPage'));
const EmployeesPage = React.lazy(() => import('./EmployeesPage'));
const ExportPage = React.lazy(() => import('./ExportPage'));
const ExportOrdersPage = React.lazy(() => import('./ExportOrdersPage'));
const ExportReturnOrdersPage = React.lazy(() =>
  import('./ExportReturnOrdersPage'),
);
const ExportBalancesPage = React.lazy(() => import('./ExportBalancesPage'));
const FinishRegistrationPage = React.lazy(() =>
  import('./FinishRegistrationPage'),
);
const OrderDetails = React.lazy(() => import('./OrderDetailsPage'));
const OrderNew = React.lazy(() => import('./OrderNewPage'));
const Orders = React.lazy(() => import('./OrdersPage'));
const PriceTypes = React.lazy(() => import('./PriceTypesPage'));
const ProductCreatePage = React.lazy(() => import('./ProductCreatePage'));
const ProductDetailsPage = React.lazy(() => import('./ProductDetailsPage'));
const ReturnOrderDetails = React.lazy(() => import('./ReturnOrderDetailsPage'));
const ReturnOrderNew = React.lazy(() => import('./ReturnOrderNewPage'));
const ReturnOrders = React.lazy(() => import('./ReturnOrdersPage'));
const Rtt = React.lazy(() => import('./Rtt'));
const RttDetailsPage = React.lazy(() => import('./RttDetailsPage'));
const DiscountList = React.lazy(() => import('./DiscountListPage'));
const DiscountCreate = React.lazy(() => import('./DiscountCreatePage'));
const DiscountDetails = React.lazy(() => import('./DiscountDetailsPage'));
const SaleList = React.lazy(() => import('./SaleListPage'));
const SaleCreate = React.lazy(() => import('./SaleCreatePage'));
const SaleDetails = React.lazy(() => import('./SaleDetailsPage'));
const SettingsPage = React.lazy(() => import('./SettingsPage'));
const SupervisorManagersPage = React.lazy(() =>
  import('./SupervisorManagersPage'),
);
const SupervisorsPage = React.lazy(() => import('./SupervisorsPage'));
const SupportPage = React.lazy(() => import('./SupportPage'));
const TerritoriesPage = React.lazy(() => import('./TerritoriesPage'));
const UploadAssortmentPage = React.lazy(() => import('./UploadAssortmentPage'));
const ProfilePage = React.lazy(() => import('./ProfilePage'));

const HeaderContent: React.FC = () => {
  const { isPermittedTo, user } = useAuth();
  const [t] = useTranslation();

  useSystemChannel(user?.id);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h6" component="h2" noWrap>
        {t('distributor.appBar.title')}
        {isPermittedTo(PERMISSIONS.NAV_FINISH_REGISTRATION) &&
          ` - ${t('distributor.appBar.registrationApproveRequired')}`}
        {user?.phone}
        {' - '}
        {user?.company?.status &&
          `${t('status.filterTitle')}: ${t(`status.${user?.company?.status}`)}`}
      </Typography>

      <Box display="flex" alignItems="center">
        <Box clone paddingRight={2}>
          <ChatNotificationSwitch />
        </Box>

        <Menu />
      </Box>
    </div>
  );
};

const routeWithOrdersFilter = `${routes.orders}/:statusGroup(${
  routes.orderFilters.toPerform
}|${routes.orderFilters.history})/:status(${ORDER_STATUSES.join('|')})?`;

const routeWithReturnOrdersFilter = `${routes.returns}/:statusGroup(${
  routes.orderFilters.toPerform
}|${routes.orderFilters.history})/:status(${ORDER_STATUSES.join('|')})?`;

const Pages: React.FC = () => {
  const { isPermittedTo, user } = useAuth();

  // redirects to finish registration if no access to
  const routeRegistrationChecked = (route = ROUTE_WITHOUT_PRIVILEGES) =>
    isPermittedTo(PERMISSIONS.NAV_FINISH_REGISTRATION)
      ? routes.finishRegistration
      : route;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(notificationActions.notificationsGetRequest());
  }, [dispatch]);

  // redirects to finishRegistration on first unregistered render.
  const registrationFormVisited = useRef(false);
  const history = useHistory();
  const location = useLocation();
  if (
    !registrationFormVisited.current &&
    isPermittedTo(PERMISSIONS.NAV_FINISH_REGISTRATION) &&
    location.pathname !== routes.finishRegistration
  ) {
    registrationFormVisited.current = true;
    history.replace(routes.finishRegistration);
  }

  // redirects to login if company disabled or employee deactivated
  if (!isPermittedTo(PERMISSIONS.APP_ACCESS)) {
    window.location.href = LOGIN_PAGE;
    return null;
  }

  return (
    <Layout
      header={<HeaderContent />}
      navigation={<Navigation />}
      hideNav={!isPermittedTo(PERMISSIONS.NAV_SHOW)}
      logo={
        <Logo title={user?.company?.name} imgSrc={user?.company?.logo?.src} />
      }
    >
      <Suspense fallback={<SuspenseIndicator />}>
        <Switch>
          <PrivateRoute
            exact
            path={routes.territories}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_AREAS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={TerritoriesPage}
          />
          <PrivateRoute
            exact
            path={routes.agreedAssortmentCreate}
            checkAccess={isPermittedTo(
              PERMISSIONS.NAV_AGREED_ASSORTMENT_CREATE,
            )}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={AgreedAssortmentCreatePage}
          />
          <PrivateRoute
            exact
            path={`${routes.agreedAssortments}/:id([\\w\\-]+)`}
            checkAccess={isPermittedTo(
              PERMISSIONS.NAV_AGREED_ASSORTMENT_DETAILS,
            )}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={AgreedAssortmentEditPage}
          />
          <PrivateRoute
            exact
            path={routes.agreedAssortments}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_AGREED_ASSORTMENTS_LIST)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={AgreedAssortmentListPage}
          />
          <PrivateRoute
            exact
            path={routes.balanceCreate}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_BALANCE_NEW)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={BalanceCreatePage}
          />
          <PrivateRoute
            exact
            path={`${routes.balances}/:id([\\w\\-]+)`}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_BALANCES)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={BalanceDetailsPage}
          />
          <PrivateRoute
            exact
            path={routes.balances}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_BALANCES)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={BalanceListPage}
          />
          <PrivateRoute
            exact
            path={routes.brands}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_BRANDS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={BrandsPage}
          />
          <PrivateRoute
            exact
            path={routes.importExport.common}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_EXPORTS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={ExportPage}
          />
          <PrivateRoute
            exact
            path={routes.importExport.orders}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_EXPORTS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={ExportOrdersPage}
          />
          <PrivateRoute
            exact
            path={routes.importExport.returns}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_EXPORTS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={ExportReturnOrdersPage}
          />
          <PrivateRoute
            exact
            path={routes.importExport.balances}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_EXPORTS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={ExportBalancesPage}
          />
          <PrivateRoute
            exact
            path={`${routes.products}/:filter(${Object.values(
              routes.productFilters,
            ).join('|')})?`}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ASSORTMENT)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={AssortmentPage}
          />
          <PrivateRoute
            exact
            path={routes.uploadAssortment}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ASSORTMENT)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={UploadAssortmentPage}
          />
          <PrivateRoute
            exact
            path={routes.productNew}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ASSORTMENT)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={ProductCreatePage}
          />
          <PrivateRoute
            exact
            path={`${routes.products}/:id`}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ASSORTMENT)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={ProductDetailsPage}
          />
          <PrivateRoute
            exact
            path={routes.categories}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_CATEGORIES)}
            component={CategoriesPage}
          />
          <PrivateRoute
            exact
            path={routes.chats}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_CHATS)}
            component={ChatsWithRttPage}
          />
          <PrivateRoute
            exact
            path={routes.support}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SUPPORT)}
            component={SupportPage}
          />
          <PrivateRoute
            exact
            path={`${routes.massMail}`}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_MASS_MAIL)}
            component={MassMailPage}
          />
          <PrivateRoute
            exact
            path={routes.staff.common}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_EMPLOYEES)}
            component={() => <Redirect to={routes.staff.employees} />}
          />
          <PrivateRoute
            exact
            path={routes.staff.administrators}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ADMINISTRATORS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={AdministratorsPage}
          />
          <PrivateRoute
            exact
            path={routes.staff.supervisors}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SUPERVISORS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={SupervisorsPage}
          />
          <PrivateRoute
            exact
            path={routes.staff.employees}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_EMPLOYEES)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={EmployeesPage}
          />

          <PrivateRoute
            exact
            path={routes.supervisorManagers}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SUPERVISOR_MANAGERS)}
            redirectPath={routeRegistrationChecked(APP_INITIAL_PAGE)}
            component={SupervisorManagersPage}
          />

          <PrivateRoute
            exact
            path={routes.finishRegistration}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_FINISH_REGISTRATION)}
            redirectPath={ROUTE_WITHOUT_PRIVILEGES}
            render={() => {
              registrationFormVisited.current = true;
              return <FinishRegistrationPage companyId={user?.company?.id} />;
            }}
          />
          <PrivateRoute
            exact
            path={routes.orders}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ORDERS)}
            component={() => (
              <Redirect
                to={`${routes.orders}/${routes.orderFilters.toPerform}`}
              />
            )}
          />
          <PrivateRoute
            exact
            path={routeWithOrdersFilter}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ORDERS)}
            component={Orders}
          />
          <PrivateRoute
            exact
            path={routes.orderNew}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ORDER_NEW)}
            component={OrderNew}
          />
          <PrivateRoute
            exact
            path={`${routes.orders}/:id`}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ORDER_DETAILS)}
            component={OrderDetails}
          />
          <PrivateRoute
            exact
            path={routes.returns}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_RETURNS)}
            component={() => (
              <Redirect
                to={`${routes.returns}/${routes.orderFilters.toPerform}`}
              />
            )}
          />
          <PrivateRoute
            exact
            path={routeWithReturnOrdersFilter}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_RETURNS)}
            component={ReturnOrders}
          />
          <PrivateRoute
            exact
            path={routes.returnsNew}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_RETURN_NEW)}
            component={ReturnOrderNew}
          />
          <PrivateRoute
            exact
            path={`${routes.returns}/:id`}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_RETURN_DETAILS)}
            component={ReturnOrderDetails}
          />
          <PrivateRoute
            exact
            path={routes.home}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_ORDERS)}
            component={Orders}
          />
          <PrivateRoute
            exact
            path={`${routes.rtt}/:id`}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_RTT_DETAILS)}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            component={RttDetailsPage}
          />
          <PrivateRoute
            exact
            path={routes.rtt}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_RTT)}
            component={Rtt}
          />
          <PrivateRoute
            exact
            path={routes.discounts}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_DISCOUNTS)}
            component={DiscountList}
          />
          <PrivateRoute
            exact
            path={routes.discountsNew}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_DISCOUNTS_CREATE)}
            component={DiscountCreate}
          />
          <PrivateRoute
            exact
            path={`${routes.discounts}/:id`}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_DISCOUNTS_DETAILS)}
            component={DiscountDetails}
          />
          <PrivateRoute
            exact
            path={routes.sales}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SALES)}
            component={SaleList}
          />
          <PrivateRoute
            exact
            path={routes.salesNew}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SALES_CREATE)}
            component={SaleCreate}
          />
          <PrivateRoute
            exact
            path={`${routes.sales}/:id`}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SALES_DETAILS)}
            component={SaleDetails}
          />
          <PrivateRoute
            exact
            path={routes.settings}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_SETTINGS)}
            component={SettingsPage}
          />
          <PrivateRoute
            exact
            path={routes.priceTypes}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_PRICE_TYPES)}
            component={PriceTypes}
          />
          <PrivateRoute
            exact
            path={routes.profile}
            redirectPath={routeRegistrationChecked(LOGIN_PAGE)}
            checkAccess={isPermittedTo(PERMISSIONS.NAV_PROFILE)}
            component={ProfilePage}
          />
          <Route render={() => <Redirect to={routes.home} />} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default Pages;
