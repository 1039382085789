import { LoadingButton } from '@rtt-libs/views';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SettingsExport, testFtpCreds } from '../../../api/settingsExport';

const FtpTestCreds = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const {
    values: { ftpCreds },
    invalid,
  } = useFormState<SettingsExport>({
    subscription: { values: true, invalid: true },
  });

  const [state, setState] = useState<'ready' | 'loading' | 'success' | 'error'>(
    'ready',
  );

  const testFtp = async () => {
    setState('loading');

    try {
      await testFtpCreds(ftpCreds);

      setState('success');
      enqueueSnackbar(t('common.success'));
    } catch (e) {
      setState('error');
      enqueueSnackbar(e.response?.data?.message || e.message, {
        variant: 'error',
      });
    }
  };

  return (
    <LoadingButton
      loading={state === 'loading'}
      disabled={invalid || state === 'loading'}
      onClick={testFtp}
      variant="outlined"
      size="small"
    >
      {t('controls.test')}
    </LoadingButton>
  );
};

export default FtpTestCreds;
