/* eslint-disable @typescript-eslint/no-empty-interface, @typescript-eslint/camelcase */

import {
  formErrorTransform,
  mapPaginatedData,
  TransformAPIError,
} from '@rtt-libs/api-services';
import { OrderParam, Paginated } from '@rtt-libs/types';
import snakeCase from 'lodash/snakeCase';
import { SearchParams } from '../administrators/types';
import type * as AdministratorsTypes from '../administrators/types';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { transformSimpleErrorMessage } from './mappers';

export interface Administrator extends AdministratorsTypes.Administrator {}

type FetchedAdministrator = {
  id: number;
  phone: string;
  login: string;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
};

export class Administrator {
  constructor(administrator: FetchedAdministrator) {
    this.id = administrator.id;
    this.phone = administrator.phone;
    this.login = administrator.login;
    this.email = administrator.email;
    this.firstName = administrator.first_name;
    this.lastName = administrator.last_name;
    this.password = administrator.password;
    this.isActive = administrator.is_active;
  }
}

type SortValueApi =
  | 'email'
  | 'phone'
  | 'login'
  | 'first_name'
  | 'last_name'
  | 'is_active';

type APISearchParams = Partial<{
  search: string;
  sort: SortValueApi | string;
  order: OrderParam;
  is_active: '0' | '1';
  per_page: number;
  page: number;
}>;

type CreateAdministratorValuesAPI = {
  login: string;
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
};

type EditAdministratorValuesAPI = CreateAdministratorValuesAPI & {
  password: string;
};

/*
 * Administrator API handlers
 */

export const searchAdministrators = (params: SearchParams) =>
  api
    .get<Paginated<FetchedAdministrator>>(ENDPOINTS.administrators, {
      params: mapAPISearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapAdministratorArray))
    .catch(transformSimpleErrorMessage);

export const createAdministrator = (
  values: AdministratorsTypes.AdministratorValues,
) =>
  api
    .post<FetchedAdministrator>(
      ENDPOINTS.administrators,
      mapCreateAdministratorValues(values),
    )
    .then(({ data }) => new Administrator(data))
    .catch(e => {
      throw formErrorTransform(e, transformCreateAdministratorErrors);
    });

export const editAdministrator = (
  values: AdministratorsTypes.EditAdministratorValues,
) =>
  api
    .put<FetchedAdministrator>(
      `${ENDPOINTS.administrators}/${values.id}`,
      mapEditAdministratorValues(values),
    )
    .then(({ data }) => new Administrator(data))
    .catch(e => {
      throw formErrorTransform(e, transformEditAdministratorErrors);
    });

export const changeAdministratorStatus = ({
  id,
  status,
}: {
  id: number;
  status: boolean;
}) =>
  api
    .post<FetchedAdministrator>(
      `${ENDPOINTS.administrators}/${id}/${ENDPOINTS.employeesStatusSuffix}`,
      { is_active: status },
    )
    .then(({ data }) => new Administrator(data))
    .catch(e => {
      throw collectErrorMessagesToError(
        formErrorTransform(e, transformChangeStatusAdministratorErrors),
      );
    });

/*
 * Administrator Mappers
 */

function mapAdministratorArray(administratorArray: FetchedAdministrator[]) {
  return administratorArray.map(
    administrator => new Administrator(administrator),
  );
}

function mapAPISearchParams(params: SearchParams): APISearchParams {
  return {
    search: params.search || undefined,
    sort: params.orderBy && snakeCase(params.orderBy),
    order: params.order,
    is_active: params.isActive || undefined,
    per_page: params.perPage,
    page: params.page,
  };
}

function mapCreateAdministratorValues(
  values: AdministratorsTypes.AdministratorValues,
): CreateAdministratorValuesAPI {
  return {
    login: values.login,
    phone: values.phone,
    email: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
  };
}

function mapEditAdministratorValues({
  password,
  ...values
}: AdministratorsTypes.EditAdministratorValues): EditAdministratorValuesAPI {
  return {
    password,
    ...mapCreateAdministratorValues(values),
  };
}

const transformCreateAdministratorErrors: TransformAPIError<
  Record<keyof CreateAdministratorValuesAPI, string | string[]>,
  Record<keyof AdministratorsTypes.AdministratorValues, string>
> = function transformCreateAdministratorErrors(e) {
  return {
    login: e.login?.toString(),
    phone: e.phone?.toString(),
    email: e.email?.toString(),
    firstName: e.first_name?.toString(),
    lastName: e.last_name?.toString(),
  };
};

const transformEditAdministratorErrors: TransformAPIError<
  Record<keyof EditAdministratorValuesAPI, string | string[]>,
  Record<keyof AdministratorsTypes.EditAdministratorValues, string>
> = function transformEditAdministratorErrors(e) {
  return {
    password: e.password?.toString(),
    ...transformCreateAdministratorErrors(e),
  };
};

const transformChangeStatusAdministratorErrors: TransformAPIError<
  Record<'is_active', string | string[]>,
  Record<'isActive', string>
> = function transformChangeStatusAdministratorErrors(e) {
  return {
    isActive: e.is_active?.toString(),
  };
};

function collectErrorMessagesToError(errors: Record<string, string>) {
  return new Error(Object.values(errors).join(' '));
}
