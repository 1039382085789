import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createArea,
  deleteArea,
  editArea,
  getTerritoriesList,
} from '../../api/territories';
import * as actions from './actions';
import { Area } from './model';
import { getAreaById, getManagersByAreaIds } from './selectors';
import * as types from './types';

function* worker() {
  try {
    const data: Area[] = yield call(getTerritoriesList);

    yield all([put(actions.getTerritoriesSuccess(data))]);
  } catch (e) {
    yield put(actions.getTerritoriesFailure(e.message));
  }
}

function* createWorker({
  payload,
}: ReturnType<typeof actions.createTerritoriesRequest>) {
  try {
    const data: Area = yield call(createArea, payload);

    const managers = yield select(state =>
      getManagersByAreaIds(state, payload.deletedAreas || []),
    );

    const changed = {
      managers,
      edited: payload.changedAreas || {},
      deleted: payload.deletedAreas || [],
    };

    yield all([
      put(actions.createTerritoriesSuccess()),
      put(actions.createTerritoriesResponse(data, changed)),
    ]);
  } catch (e) {
    if (e.response?.data?.errors) {
      const submissionErrors = mapSubmissionErrors(e.response.data);

      yield put(actions.createTerritoriesSuccess(submissionErrors));
    } else {
      yield put(
        actions.createTerritoriesFailure(
          e.response?.data ? e.response.data.message : e.message,
        ),
      );
    }
  }
}

function* editWorker({
  payload,
}: ReturnType<typeof actions.editTerritoriesRequest>) {
  try {
    const data: Area = yield call(editArea, payload);

    const managers = yield select(state =>
      getManagersByAreaIds(state, [
        payload.id,
        ...(payload.values.deletedAreas || []),
      ]),
    );
    const changed = {
      managers,
      edited: payload.values.changedAreas || {},
      deleted: payload.values.deletedAreas || [],
    };

    yield all([
      put(actions.editTerritoriesSuccess()),
      put(actions.editTerritoriesResponse(data, changed)),
    ]);
  } catch (e) {
    if (e.response?.data?.errors) {
      const submissionErrors = mapSubmissionErrors(e.response.data);

      yield put(actions.editTerritoriesSuccess(submissionErrors));
    } else {
      yield put(
        actions.editTerritoriesFailure(
          e.response?.data ? e.response.data.message : e.message,
        ),
      );
    }
  }
}

function* deleteWorker({
  payload,
}: ReturnType<typeof actions.deleteTerritoriesRequest>) {
  try {
    yield call(deleteArea, payload);

    const { managerId } = yield select(state => getAreaById(state, payload));
    const changed = {
      manager: managerId,
    };

    yield all([put(actions.deleteTerritoriesSuccess(payload, changed))]);
  } catch (e) {
    yield put(
      actions.deleteTerritoriesFailure(e.response?.data?.message || e.message, {
        id: payload,
      }),
    );
  }
}

export default function* watcher() {
  yield takeLatest(types.GET_TERRITORIES_REQUEST, worker);
  yield takeLatest(types.CREATE_TERRITORIES_REQUEST, createWorker);
  yield takeLatest(types.EDIT_TERRITORIES_REQUEST, editWorker);
  yield takeLatest(types.DELETE_TERRITORIES_REQUEST, deleteWorker);
}

function mapSubmissionErrors({
  errors,
  message,
}: {
  errors: Partial<Record<string, string[]>>;
  message: string;
}): SubmissionErrors {
  let submissionErrors: SubmissionErrors = {};

  if (errors) {
    submissionErrors = {
      title: errors.title?.join(', '),
      managerId: errors.manager_id?.join(', '),
      driverId: errors.driver_id?.join(', '),
      priceTypeId: errors.price_type_id?.join(', '),
      paths: errors.paths?.join(', '),
      isDefaultDeliveryDays: errors.is_default_delivery_days?.join(', '),
      deliveryDays: errors.delivery_days?.join(', '),
      isBalanceEnabled: errors.is_balance_enabled?.join(', '),
    };
  }
  submissionErrors[FORM_ERROR] = message;

  return submissionErrors;
}
