import { Grid, OutlinedInputProps, Typography } from '@material-ui/core';
import { TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { Field } from 'react-final-form-html5-validation';
import { useTranslation } from 'react-i18next';

type OrderCommentFieldProps = {
  name?: string;
  label?: string;
  InputProps?: Partial<OutlinedInputProps>;
  placeholder?: string;
};

export const OrderCommentField: React.FC<OrderCommentFieldProps> = props => {
  const [t] = useTranslation();

  return (
    <Field
      subscription={{ value: true }}
      component={TextFieldAdapter}
      variant="outlined"
      rows="6"
      multiline
      fullWidth
      name="managerDescription"
      label={t('distributor.orders.details.comments.managerLabel')}
      placeholder={t('distributor.orders.details.comments.managerPlaceholder')}
      {...props}
    />
  );
};

export const OrderCommentsWrapper: React.FC = ({ children }) => {
  const [t] = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('distributor.orders.details.comments.heading')}
          </Typography>
        </Grid>

        {children}
      </Grid>
    </Grid>
  );
};

interface Props {
  readOnly?: boolean;
  managerDescriptionFieldName?: string;
}

const OrderComments: React.FC<Props> = ({
  readOnly,
  managerDescriptionFieldName,
}) => {
  const [t] = useTranslation();

  return (
    <OrderCommentsWrapper>
      <Grid item xs={12} md={6}>
        <OrderCommentField
          name="rttDescription"
          InputProps={{
            readOnly: true,
          }}
          label={t('distributor.orders.details.comments.rttLabel')}
          placeholder={t('distributor.orders.details.comments.rttPlaceholder')}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <OrderCommentField
          InputProps={{
            readOnly,
          }}
          name={managerDescriptionFieldName}
        />
      </Grid>
    </OrderCommentsWrapper>
  );
};

export default OrderComments;
