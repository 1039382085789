import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedProduct } from '../../../api/orders';
import useAddProductsContext from './AddProductsContext';

type DisplayProduct = Pick<
  EnhancedProduct,
  'id' | 'title' | 'sku' | 'price' | 'saleMeasurement' | 'hash'
>;

interface SecondaryProps {
  product: DisplayProduct;
}

const ListItemSecondary: React.FC<SecondaryProps> = ({ product }) => {
  const [t] = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" component="span">
      <Typography component="span">{product.sku}</Typography>
      {product.price && product.saleMeasurement && (
        <Typography color="textPrimary" component="span">
          {product.price}
          {'\u00A0'}
          {t('common.mainCurrency')} /{' '}
          {t(`product.values.${product.saleMeasurement}`)}
        </Typography>
      )}
    </Box>
  );
};

interface Props {
  data: DisplayProduct[];
  uidKey?: 'id' | 'hash';
}

const ProductAddList: React.FC<Props> = ({ data, uidKey = 'id' }) => {
  const { checked, setChecked, existingIds } = useAddProductsContext();

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List>
      {data.map(product => {
        const labelId = `checkbox-list-label-${product.sku}`;

        const uid: string = product[uidKey] || product.id;

        return (
          <ListItem
            key={uid}
            role={undefined}
            dense
            button
            onClick={handleToggle(uid)}
            disabled={existingIds.includes(uid)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={
                  checked.indexOf(uid) !== -1 || existingIds.includes(uid)
                }
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={<Typography>{product.title}</Typography>}
              secondary={<ListItemSecondary product={product} />}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ProductAddList;
