import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import { isEqual } from 'lodash';
import React from 'react';
import { useField, useForm } from 'react-final-form';
import { makeGroupedFieldName } from './generateFieldName';

type ResetManagerOrderSliceProps = {
  groupId: string;
  disabled?: boolean;
};

export const ResetManagerOrderSlice = ({
  groupId,
  disabled,
}: ResetManagerOrderSliceProps) => {
  const { change } = useForm();
  const {
    input: { value },
    meta: { initial },
  } = useField(makeGroupedFieldName(groupId), {
    subscription: {
      value: true,
      initial: true,
    },
  });

  // Full manager section could be deleted only if it doesn't have initial products
  const isAbleToDelete = !initial?.payload?.products;

  if (isAbleToDelete) {
    return (
      <IconButton
        aria-label="clear section"
        onClick={() => {
          change(makeGroupedFieldName(groupId));
        }}
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  return (
    <IconButton
      aria-label="reset section"
      onClick={() => {
        change(makeGroupedFieldName(groupId), initial);
      }}
      disabled={disabled || isEqual(initial, value)}
    >
      <RestoreIcon />
    </IconButton>
  );
};
