import { CircularProgress, InputAdornment } from '@material-ui/core';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Dayjs, { ConfigType, Dayjs as DayjsType } from 'dayjs';
import { memoize } from 'lodash/fp';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Field, FieldRenderProps, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectClosestDeliveryDateData } from '../../duck/selectors';
import { DateFilterInput } from './OrdersTable/DateRangeFilter';

interface Props {
  value: DayjsType | null;
  onChange: (date: DayjsType | null) => void;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
  error?: boolean;
  loading?: boolean;
}

const ShippingDate: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  helperText,
  required,
  error,
  loading,
}) => {
  const [t] = useTranslation();

  return (
    <DateFilterInput
      disablePast={!disabled}
      readOnly={disabled}
      variant="inline"
      value={value}
      onChange={onChange}
      label={t('distributor.tables.orders.shippingDate')}
      maxDate={undefined}
      inputVariant="outlined"
      fullWidth={false}
      helperText={helperText}
      required={required}
      error={error}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {loading ? <CircularProgress size="1rem" /> : <LocalShipping />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export { ShippingDate };

const dateStringToDayjs = (dateValue: ConfigType) => Dayjs(dateValue);
const memoizeDayjsDate = memoize(dateStringToDayjs);

const SHIPPING_DATE_FIELD_NAME = 'shippingDate';

type FieldProps = {
  disabled?: boolean;
  required?: boolean;
};

const ShippingDateField: React.FC<FieldProps> = props => {
  const { disabled } = props;
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { date: deliveryDate, loading, error } = useSelector(
    selectClosestDeliveryDateData,
  );

  const form = useForm();

  const [deliveryDateDerived, setDeliveryDate] = useState<string | undefined>();

  useEffect(() => {
    const shippingFieldState = form.getFieldState(SHIPPING_DATE_FIELD_NAME);
    const initialValue: string | undefined = shippingFieldState?.initial;
    const isInitialInPast =
      !!initialValue && Dayjs().diff(initialValue, 'day') > 0;

    if (deliveryDate && (isInitialInPast || !initialValue) && !disabled) {
      form.change(SHIPPING_DATE_FIELD_NAME, deliveryDate);
      setDeliveryDate(deliveryDate);
    }
  }, [form, deliveryDate, disabled]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'warning' });
    }
  }, [enqueueSnackbar, error]);

  return (
    <Field
      subscription={{
        value: true,
        initial: true,
        touched: true,
        submitError: true,
        dirtySinceLastSubmit: true,
        error: true,
      }}
      defaultValue={deliveryDateDerived}
      name={SHIPPING_DATE_FIELD_NAME}
      parse={(date: DayjsType | null) => date?.format('YYYY-MM-DD')}
      format={(value: string | undefined) => {
        if (value === deliveryDateDerived) {
          setDeliveryDate(undefined);
        }
        return (value && memoizeDayjsDate(value)) || null;
      }}
      validate={(value: string | undefined) => {
        return !value ? t('validation.valueMissing') : undefined;
      }}
      allowNull
    >
      {({ input, meta }: FieldRenderProps<DayjsType | null>) => {
        const showError =
          ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
          meta.touched;

        const replacementDate = meta.initial || deliveryDateDerived;

        return (
          <ShippingDate
            value={
              input.value ||
              (replacementDate ? memoizeDayjsDate(replacementDate) : null)
            }
            onChange={input.onChange}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            loading={loading}
            {...props}
          />
        );
      }}
    </Field>
  );
};

export default React.memo(ShippingDateField);
