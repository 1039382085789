import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { branchName } from './const';
import { State } from './reducer';
import { FinishFormValues } from '../types';

type PartialRootState = {
  [branchName]: State;
};

export const getLoadingStateSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
]);

export const getErrorSel: Selector<PartialRootState, string | null> = get([
  branchName,
  'error',
]);

export const getInitialValuesSel: Selector<
  PartialRootState,
  Partial<FinishFormValues> | undefined
> = get([branchName, 'initialValues']);

export const getCompanyStatusSel: Selector<
  PartialRootState,
  string | undefined
> = get([branchName, 'status']);

export const getAdminNoticesSel: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'notices']);

export const getInitialValuesLoadingSel: Selector<
  PartialRootState,
  boolean
> = get([branchName, 'initialValuesLoading']);
