import { FormControlProps, MenuItem } from '@material-ui/core';
import { SelectAdapter } from '@rtt-libs/views';
import React from 'react';
import { Field } from 'react-final-form';

type Props = {
  label: React.ReactNode;
  name: string;
  options: { label: string; value: number }[];
  selectProps?: Record<string, unknown>;
  additionalOptions?: React.ReactNode;
  formControlProps?: FormControlProps;
  [k: string]: unknown;
};

const SelectField: React.FC<Props> = ({
  label,
  name,
  options,
  selectProps,
  additionalOptions,
  formControlProps,
  ...rest
}) => {
  return (
    <Field
      name={name}
      formControlProps={{
        fullWidth: true,
        ...formControlProps,
      }}
      component={SelectAdapter}
      label={label}
      autoWidth
      selectProps={selectProps}
      fullWidth
      type="number"
      {...rest}
    >
      {additionalOptions}
      {options.map(({ value, label: optionLabel }) => (
        <MenuItem key={value} value={value}>
          {optionLabel}
        </MenuItem>
      ))}
    </Field>
  );
};

export default SelectField;
