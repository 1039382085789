import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import RttField from '../../discounts/view/RttField';

interface Props {
  open: boolean;
  onChange: (rttId: number) => void;
  onClose?: () => void;
  id?: number;
}

const RttSelectDialog: React.FC<Props> = ({ open, onClose, onChange, id }) => {
  const [t] = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      aria-labelledby="choose-rtt-dialog-title"
    >
      <Form onSubmit={({ rtt }) => onChange(rtt?.id)}>
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="choose-rtt-dialog-title">
              {t('distributor.orders.create.dialogTitleRtt')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('distributor.orders.create.dialogDescriptionRtt')}
              </DialogContentText>

              <RttField />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                {t('controls.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={id === values.rtt?.id}
              >
                {t('controls.select')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default RttSelectDialog;
