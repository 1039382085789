import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAgreedProductIds } from '../../duck/selectors';

interface Props {
  id: string | number | symbol;
  value: unknown;
}

const RemoveCell = ({ id, value }: Props) => {
  const [t] = useTranslation();
  const { change } = useForm();
  const agreedProductIds = useSelector(selectAgreedProductIds);

  if (agreedProductIds.includes(String(id))) return null;
  return (
    <Tooltip title={t('controls.delete') ?? ''}>
      <IconButton
        onClick={() => change(`balances.${String(id)}`)}
        type="button"
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(RemoveCell);
