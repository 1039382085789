import React from 'react';
import { Form } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { EditPriceTypeValues } from '../../../api/priceTypes';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import { usePriceTypeEditData } from './EditPriceTypesContext';
import EditPriceTypesForm from './EditPriceTypesForm';

type Props = {
  onClose(): void;
};

const EditPriceTypesContainer: React.FC<Props> = ({ onClose }) => {
  const priceType = usePriceTypeEditData();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.EDIT_PRICE_TYPES_REQUEST}
      resolve={types.EDIT_PRICE_TYPES_RESPONSE}
      reject={types.EDIT_PRICE_TYPES_FAILURE}
    >
      {onSubmit => (
        <Form
          initialValues={priceType as EditPriceTypeValues}
          onSubmit={async (values: EditPriceTypeValues) => {
            const errors = await onSubmit(values);
            if (!errors) {
              onClose();
            }
            return errors;
          }}
          component={props => (
            <EditPriceTypesForm onClose={onClose} {...props} />
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default EditPriceTypesContainer;
