import { DrawingManager } from '@react-google-maps/api';
import * as jsts from 'jsts';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Area } from '../../api/territories';
import { GeometryFactoryTypeUpdated } from '../../types';
import { getAllAreasWithPolygon } from '../duck/selectors';
import { POLYGON_COLORS } from './constants';
import { useTerritoryInteraction } from './TerritoriesInteraction';

const DrawingPolygon: React.FC = () => {
  const polygonRef = useRef<google.maps.Polygon>();
  const {
    drawing,
    onPolygonComplete,
    creation,
    setChangedPolygons,
  } = useTerritoryInteraction();

  const areas = useSelector(getAllAreasWithPolygon);

  useEffect(() => {
    if (!creation && polygonRef.current) {
      polygonRef.current.getPaths().clear();
      setChangedPolygons({});
    }
  }, [creation, setChangedPolygons]);

  const drawingOptions = {
    drawingMode: !drawing
      ? null
      : ('polygon' as google.maps.drawing.OverlayType),
    drawingControl: false,
    polygonOptions: {
      fillColor: POLYGON_COLORS.draw,
      fillOpacity: 0.1,
      strokeWeight: 5,
      clickable: false,
      editable: true,
      zIndex: 1,
    },
  };

  const handlePolygonComplete = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon;

    const paths = polygon.getPath();

    const geometryFactory = new jsts.geom.GeometryFactory() as GeometryFactoryTypeUpdated;

    const handlePolygonEdit = () => {
      const coordinates = [] as jsts.geom.Coordinate[];
      const path = paths.getArray().map(coord => {
        coordinates.push(new jsts.geom.Coordinate(coord.lat(), coord.lng()));

        return {
          lat: coord.lat(),
          lng: coord.lng(),
        };
      });

      path.push(path[0]);

      coordinates.push(coordinates[0]);

      onPolygonComplete(path);

      if (coordinates.length <= 3) return;

      const polygonEnhanced = geometryFactory.createPolygon(coordinates);
      const changedAreas = {} as Record<
        Area['id'],
        google.maps.LatLngLiteral[]
      >;

      areas.forEach(({ id, polygon: polyJsts }) => {
        const isIntersects = polyJsts.intersects(polygonEnhanced);

        if (isIntersects) {
          const difference = polyJsts.difference(polygonEnhanced);

          changedAreas[id] = difference.getCoordinates().map(coord => {
            return {
              lat: coord.x,
              lng: coord.y,
            };
          });
        }
      });

      const changedCount = Object.keys(changedAreas).length;

      let deleted = undefined as number[] | undefined;
      let changed = undefined as
        | Record<number, google.maps.LatLngLiteral[]>
        | undefined;

      if (changedCount) {
        Object.entries(changedAreas).forEach(([keyString, value]) => {
          const key = parseInt(keyString, 10);
          if (value.length) {
            changed = changed || {};
            changed[key] = value;
          } else {
            deleted = deleted ? deleted.concat(key) : [key];
          }
        });
      }

      setChangedPolygons({ changed, deleted });
    };

    // TODO: Check on memory leak when path removes from DrawingOverlay.
    paths.addListener('set_at', handlePolygonEdit);
    paths.addListener('insert_at', handlePolygonEdit);
    paths.addListener('remove_at', handlePolygonEdit);

    handlePolygonEdit();
  };

  return (
    <DrawingManager
      options={drawingOptions}
      onPolygonComplete={handlePolygonComplete}
    />
  );
};

export default DrawingPolygon;
