import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbarError from '../../common/useSnackbarError';
import { searchAdministratorsRequest } from '../duck/actions';
import { selectAdministrators } from '../duck/selectors';
import { SearchParams } from '../types';
import AdministratorsTable from './AdministratorsTable';

/**
 * Container to fetch & filter employees & render them in table.
 */
const AdministratorsContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { data, pagination, loading, error } = useSelector(
    selectAdministrators,
  );

  useSnackbarError(error);

  const onTableStateChange = useCallback(
    (options: SearchParams) => {
      dispatch(searchAdministratorsRequest(options));
    },
    [dispatch],
  );

  return (
    <AdministratorsTable
      administrators={data}
      pagination={pagination}
      loading={loading}
      onStateChange={onTableStateChange}
    />
  );
};

export default AdministratorsContainer;
