export const GET_PROFILE_REQUEST = `@rtt[D]/GET_PROFILE_REQUEST` as const;
export const GET_PROFILE_SUCCESS = `@rtt[D]/GET_PROFILE_SUCCESS` as const;
export const GET_PROFILE_FAILURE = `@rtt[D]/GET_PROFILE_FAILURE` as const;

export const CHANGE_PASSWORD_REQUEST = `@rtt[D]/CHANGE_PASSWORD_REQUEST` as const;
export const CHANGE_PASSWORD_SUCCESS = `@rtt[D]/CHANGE_PASSWORD_SUCCESS` as const;
export const CHANGE_PASSWORD_RESPONSE = `@rtt[D]/CHANGE_PASSWORD_RESPONSE` as const;
export const CHANGE_PASSWORD_FAILURE = `@rtt[D]/CHANGE_PASSWORD_FAILURE` as const;

export const EDIT_PROFILE_REQUEST = `@rtt[D]/EDIT_PROFILE_REQUEST` as const;
export const EDIT_PROFILE_SUCCESS = `@rtt[D]/EDIT_PROFILE_SUCCESS` as const;
export const EDIT_PROFILE_RESPONSE = `@rtt[D]/EDIT_PROFILE_RESPONSE` as const;
export const EDIT_PROFILE_FAILURE = `@rtt[D]/EDIT_PROFILE_FAILURE` as const;
