import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { debounce, memoize } from 'lodash/fp';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getExportDocumentsRequest } from '../../../duck/actions';
import { selectExportDocumentsData } from '../../../duck/selectors';
import { ExportDocumentFilterParams, ExportResultType } from '../../../types';
import { ExportTypesRow } from '../ExportTypesRow';
import ExportDocumentsTable from './ExportDocumentsTable';
import ordersTableConfig from './tableConfig';

const wrapStringWithArray = memoize(<T extends string = string>(value: T) => [
  value,
]);

/**
 * Used because query-string package in TableWithHistory component
 * by default writes array as repeating of param name.
 * But when set only one param it read it as single value, not array.
 * TODO: this also may be fixed by setting different default `arrayFormat` config property
 */
const handleSingleTypeQueryString = <T extends string = string>(
  type: T | T[],
) => (typeof type === 'string' ? wrapStringWithArray(type) : type);

const OrderExportListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useSelector(selectExportDocumentsData);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const onTableStateChange = useCallback(
    (options: Partial<ExportDocumentFilterParams>) => {
      if (options.type?.length) {
        dispatch(
          getExportDocumentsRequest({
            ...options,
            type: handleSingleTypeQueryString(options.type),
          }),
        );
      }
    },
    [dispatch],
  );

  const onTableStateChangeDebounced = useCallback(
    debounce(100, onTableStateChange),
    [onTableStateChange],
  );

  const [t] = useTranslation();
  const columnConfig = useMemo(() => ordersTableConfig(t), [t]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <ExportDocumentsTable
        filterRow={({ onChange, displayOptions }) => (
          <ExportTypesRow
            disabled={loading}
            onChange={
              (onChange as unknown) as (values: {
                type: ExportResultType[];
              }) => void
            }
            initial={displayOptions}
            initialType={
              (handleSingleTypeQueryString(
                displayOptions.type,
              ) as unknown) as ExportResultType[]
            }
          />
        )}
        entries={(data as unknown) as Record<string, unknown>[]}
        loading={loading}
        columnConfig={columnConfig}
        onStateChange={onTableStateChangeDebounced}
      />
    </MuiPickersUtilsProvider>
  );
};

export default OrderExportListContainer;
