import { ColumnConfig } from '@rtt-libs/views';
import type { TFunction } from 'i18next';
import { RttInfo } from '../../../../ordersRefactored/types';
import DocumentTypeCell from './DocumentTypeCell';
import ExportCell from './ExportCell';

/**
 * Configure Order Exports table appearance.
 * Set ids of table data, its labels & other options.
 * @param t i18next translation function
 */
const orderExportsTableConfig = (t: TFunction): ColumnConfig[] => [
  {
    id: 'exportStatus',
    label: t('distributor.export.columns.exportStatus', 'Exported'),
    options: {
      sort: false,
      format: (_, entry) => entry,
      component: ExportCell,
    },
  },
  {
    id: 'type',
    label: t('distributor.export.columns.documentType', 'Doc type'),
    options: {
      sort: false,
      component: DocumentTypeCell,
    },
  },
  {
    id: 'id',
    label: t('distributor.export.columns.documentId', 'ID'),
    options: {
      sort: false,
    },
  },
  {
    id: 'createdAt',
    label: t('distributor.export.columns.createdAt', 'Created date'),
    options: {
      format(value: string) {
        return t('formattedDateTime', { date: value });
      },
      sort: false,
    },
  },
  {
    id: 'rtt',
    label: t('distributor.tables.orders.customer', 'Customer'),
    options: {
      format: (rtt: RttInfo) => rtt.name,
      sort: false,
    },
  },
];

export default orderExportsTableConfig;
