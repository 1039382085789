import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { selectMinTotal } from '../../../duck/selectors';

type Props = {
  managerPhone: string;
  fieldNamePrefix?: string;
};

export const ShopInfoFields = ({
  managerPhone,
  fieldNamePrefix = '',
}: Props) => {
  const { value: minTotal } = useSelector(selectMinTotal);

  return (
    <>
      <Field
        component="input"
        name={`${fieldNamePrefix}.min_total_order`}
        defaultValue={minTotal}
        type="hidden"
      />
      <Field
        component="input"
        name={`${fieldNamePrefix}.manager.phone`}
        defaultValue={managerPhone}
        type="hidden"
      />
    </>
  );
};
