import type { MassMailFormErrors, MassMailFormValues } from '../types';
import * as types from './types';

export const sendMassMailRequest = (payload: MassMailFormValues) => ({
  type: types.CHATS_MASS_MAIL_REQUEST,
  payload,
});

export const sendMassMailSuccess = () => ({
  type: types.CHATS_MASS_MAIL_SUCCESS,
});

export const sendMassMailFailure = <
  T extends Record<string, string | undefined> = {}
>(
  errors: MassMailFormErrors<T>,
) => ({
  type: types.CHATS_MASS_MAIL_FAILURE,
  payload: errors,
});

export type MassMailActions = ReturnType<
  | typeof sendMassMailRequest
  | typeof sendMassMailSuccess
  | typeof sendMassMailFailure
>;
