import { Grid } from '@material-ui/core';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const DateFilterInput: React.FC<DatePickerProps> = props => {
  const [t] = useTranslation();

  const commonProps = useMemo(
    () => ({
      format: 'DD.MM.YYYY',
      maxDate: new Date(),
      fullWidth: true,
      emptyLabel: t('validation.emptyLabel'),
      invalidDateMessage: t('validation.invalidDateMessage'),
      invalidLabel: t('validation.invalidLabel'),
      maxDateMessage: t('validation.maxDateMessage'),
      minDateMessage: t('validation.minDateMessage'),
    }),
    [t],
  );

  const toolbarLabels = useMemo(
    () => ({
      cancelLabel: t('controls.cancel'),
      clearLabel: t('controls.clear'),
      todayLabel: t('controls.today'),
    }),
    [t],
  );

  // eslint-disable-next-line react/destructuring-assignment
  if (props.variant === 'inline') {
    return <DatePicker disableToolbar {...commonProps} {...props} />;
  }

  return (
    <DatePicker clearable {...commonProps} {...toolbarLabels} {...props} />
  );
};

interface Props {
  onChange(params: { startDate?: string; endDate?: string }): void;
  startDateLabel?: string;
  endDateLabel?: string;
  maxDate?: Dayjs;
  initialDate?: Record<'start' | 'end', string | null>;
}

const DateRangeFilter: React.FC<Props> = ({
  onChange,
  // TODO: Next arguments added for extending. Better to extract common login to another component
  startDateLabel,
  endDateLabel,
  maxDate,
  initialDate,
}) => {
  const [t] = useTranslation();

  const handleDateChange = (paramKey: 'startDate' | 'endDate') => (
    date: Dayjs | null,
  ) => onChange({ [paramKey]: date?.format('YYYY-MM-DD') });

  const [dates, changeDates] = useState<
    Record<'start' | 'end', Dayjs | string | null>
  >(() => initialDate || { start: null, end: null });

  const handleDateStateChange = (dateKey: keyof typeof dates) => (
    date: Dayjs | null,
  ) => changeDates(prevDates => ({ ...prevDates, [dateKey]: date }));

  return (
    <Grid item container spacing={1} xs={8} md={4}>
      <Grid item xs={6}>
        <DateFilterInput
          value={dates.start}
          onChange={handleDateStateChange('start')}
          label={
            startDateLabel || t('distributor.tables.orders.filters.dateStart')
          }
          onAccept={handleDateChange('startDate')}
          maxDate={dates.end || undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <DateFilterInput
          maxDate={maxDate}
          value={dates.end}
          onChange={handleDateStateChange('end')}
          label={endDateLabel || t('distributor.tables.orders.filters.dateEnd')}
          onAccept={handleDateChange('endDate')}
          minDate={dates.start || undefined}
        />
      </Grid>
    </Grid>
  );
};

export default DateRangeFilter;
