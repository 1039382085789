import { Polygon, useGoogleMap } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Area } from '../../api/territories';
import { getAllAreasWithPolygon } from '../duck/selectors';
import AreaLabel from './AreaLabel';
import { POLYGON_COLORS } from './constants';
import { addPathListeners } from './mapUtils';
import { useTerritoryInteraction } from './TerritoriesInteraction';

const REPRESENT_POLYGON_PROPS = {
  fillColor: POLYGON_COLORS.view,
  strokeColor: POLYGON_COLORS.viewStroke,
  fillOpacity: 0.25,
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  geodesic: false,
  zIndex: 1,
};

const SELECTED_POLYGON_PROPS = {
  ...REPRESENT_POLYGON_PROPS,
  strokeWeight: 3,
};

const EDITABLE_POLYGON_PROPS = {
  ...REPRESENT_POLYGON_PROPS,
  fillColor: POLYGON_COLORS.draw,
  strokeColor: POLYGON_COLORS.drawStroke,
  fillOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
};

const CHANGED_POLYGON_PROPS = {
  ...REPRESENT_POLYGON_PROPS,
  fillColor: POLYGON_COLORS.changed,
  strokeWeight: 2,
  fillOpacity: 0.5,
};

const DELETED_POLYGON_PROPS = {
  ...REPRESENT_POLYGON_PROPS,
  fillColor: POLYGON_COLORS.deleted,
  strokeWeight: 0,
  fillOpacity: 0.5,
};

const getOptions = (
  editable: boolean,
  changed?: boolean,
  deleted?: boolean,
  selected?: boolean,
) => {
  if (editable) {
    return EDITABLE_POLYGON_PROPS;
  }
  if (changed) {
    return CHANGED_POLYGON_PROPS;
  }
  if (deleted) {
    return DELETED_POLYGON_PROPS;
  }
  if (selected) {
    return SELECTED_POLYGON_PROPS;
  }
  return REPRESENT_POLYGON_PROPS;
};

const extendBoundByPaths = (bounds: google.maps.LatLngBounds, area: Area) => {
  area.paths.forEach(path => bounds.extend(path));
};

const getCenter = (mapInstance: google.maps.Map | null, areaToCenter: Area) => {
  if (mapInstance) {
    const bounds = new window.google.maps.LatLngBounds();
    extendBoundByPaths(bounds, areaToCenter);

    return bounds.getCenter();
  }

  return areaToCenter.paths[0];
};

type Props = {
  area: Area;
  editable: boolean;
  onPolygonChange: (path: google.maps.LatLngLiteral[]) => void;
  onClick: () => void;
  changed?: boolean;
  deleted?: boolean;
  selected?: boolean;
};

const PolygonDisplay: React.FC<Props> = ({
  area,
  editable,
  onPolygonChange,
  onClick,
  changed,
  deleted,
  selected,
}) => {
  const areaId = area.id;
  /**
   * Removed last item because in DB it is a copy of first
   * Need to prevent increase duplication of nodes on map
   * Leads to stop handling events
   * paths={area.paths.slice(0, -1)}
   */
  const slicedPath = useMemo(() => area.paths.slice(0, -1), [area]);
  const map = useGoogleMap();
  const polygonRef = useRef<google.maps.Polygon | undefined>();
  const removeListenersRef = useRef<() => void>();

  const { setChangedPolygons } = useTerritoryInteraction();

  // remove listeners on unmount
  useEffect(() => {
    return () => {
      if (removeListenersRef.current) {
        removeListenersRef.current();
      }
    };
  }, []);

  const areasWithPolygons = useSelector(getAllAreasWithPolygon);
  const areasWithPolygonsRef = useRef<
    (Area & {
      polygon: jsts.geom.Polygon;
    })[]
  >([]);

  useEffect(() => {
    areasWithPolygonsRef.current = areasWithPolygons.filter(
      areaWithPolygons => areaWithPolygons.id !== areaId,
    );
  }, [areasWithPolygons, areasWithPolygonsRef, areaId]);

  useEffect(() => {
    if (polygonRef.current) {
      polygonRef.current.setPath(slicedPath);
      if (removeListenersRef.current) {
        removeListenersRef.current();
        removeListenersRef.current = undefined;
      }
      addPathListeners(
        (path, modified) => {
          onPolygonChange(path);
          setChangedPolygons(modified);
        },
        areasWithPolygonsRef,
        (polygon, removeListeners) => {
          polygonRef.current = polygon;
          removeListenersRef.current = removeListeners;
        },
      )(polygonRef.current);
    }
  }, [onPolygonChange, setChangedPolygons, slicedPath, editable]);

  const onLoad = useCallback(
    addPathListeners(
      (path, modified) => {
        onPolygonChange(path);
        setChangedPolygons(modified);
      },

      areasWithPolygonsRef,
      (polygon, removeListeners) => {
        polygonRef.current = polygon;
        removeListenersRef.current = removeListeners;
      },
    ),
    [onPolygonChange],
  );

  const options = getOptions(editable, changed, deleted, selected);

  return (
    <>
      <Polygon
        onLoad={onLoad}
        editable={editable}
        paths={editable ? slicedPath : area.paths}
        onClick={onClick}
        options={options}
      />
      <AreaLabel position={getCenter(map, area)}>{area.title}</AreaLabel>
    </>
  );
};

export default PolygonDisplay;
