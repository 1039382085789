import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Product } from '../../api/assortment';
import type { Brand } from '../../types';
import { financialString } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cover: {
      width: 151,
      height: 151,
    },
    card: {
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: 0,
    },
    cardArea: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexWrap: 'wrap',
    },
    main: {
      flex: '1 1 200px',
      alignItems: 'flex-start',
    },
    info: {
      display: 'flex',
      flex: '1 0 240px',
    },
    properties: {
      flex: '1 0 120px',
      padding: theme.spacing(0, 2),
    },
    price: {
      flex: '1 0 120px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    category: {
      margin: theme.spacing(0, 2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  }),
);

type Props = {
  product: Product;
  onClick(id: Product['id']): void;
};

const ProductItemLarge: React.FC<Props> = ({ product, onClick }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea
          className={classes.cardArea}
          // href={product.id}
          onClick={() => {
            onClick(product.id);
          }}
        >
          <CardMedia
            component={product.image ? 'img' : WallpaperIcon}
            className={classes.cover}
            image={product.image?.src}
            title="Live from space album cover"
          />

          <CardContent className={classes.main}>
            <Typography variant="h6" component="h3">
              {product.title}
            </Typography>
            <Typography variant="subtitle1">
              {t('product.manufacturer', 'Manufacturer')}:{' '}
              {product.manufacturer}
            </Typography>
            <Typography>{product.description}</Typography>
            <Typography variant="caption">{product.sku}</Typography>
            <Chip
              className={classes.category}
              label={
                product.category ? product.category.data.title : 'no category'
              }
            />
            <Typography variant="caption">
              {t('product.saleMeasurementType', 'Sale measurement type')}:{' '}
              {t([
                `product.saleMeasurement.${product.saleMeasurement}`,
                'product.saleMeasurement.empty',
              ])}
            </Typography>
          </CardContent>

          <CardContent className={classes.info}>
            <div className={classes.properties}>
              <Typography>{t('product.brand')}:</Typography>
              <BrandChip brand={product.brand} />

              <Typography>
                {t('product.expirationDays', 'Expiration days')}:{' '}
                {product.expirationDays ?? '-'}
                {'\u00A0'}
                {t('product.units.days', 'days')}
              </Typography>
              <Typography>
                {t('product.weight', 'Weight')}:{' '}
                {`${product.weight ?? ' - '}\u00A0${t('product.kg')}` || '-'}
              </Typography>
            </div>
            <div className={classes.price}>
              <Typography variant="h6">
                {t('product.price.default', 'Price')}
              </Typography>
              <Typography variant="h5" component="div">
                {`${financialString(product.price, t('product.price.empty'))} ${
                  product.priceCurrency?.sign || ' - '
                }`}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>

      <Divider className={classes.divider} variant="middle" />
    </>
  );
};

export default ProductItemLarge;

type BrandChipProps = {
  brand?: Brand;
};

const BrandChip = ({ brand }: BrandChipProps) => {
  const [t] = useTranslation();

  if (!brand)
    return <Chip variant="outlined" label={t('product.noBrandLabel')} />;

  if (brand.isActive) return <Chip color="primary" label={brand.title} />;

  return (
    <Tooltip title={t<string>('common.disabled')}>
      <span>
        <Chip
          color="primary"
          label={brand.title}
          disabled
          icon={<WorkOffIcon />}
        />
      </span>
    </Tooltip>
  );
};
