import DayjsUtils from '@date-io/dayjs';
import { FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { deStringifyKeys, stringifyKeys } from '@rtt-libs/views';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Form, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import {
  CreateDiscountValues,
  DiscountRtt,
  RttDiscount,
} from '../../../api/discounts';
import { getAllCategoriesRequest } from '../../../categories/duck/actions';
import { SuspenseIndicator } from '../../../common';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import { getDiscountDetailsRequest } from '../../duck/actions';
import { selectEditedDiscount } from '../../duck/selectors';
import DiscountCreate from './DiscountCreate';

const TypedForm = (props: FormProps<CreateDiscountValues>) => Form(props);

type Props = {
  id: RttDiscount['id'];
};

const DiscountEditContainer: React.FC<Props> = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDiscountDetailsRequest(id));
    dispatch(getAllCategoriesRequest());
  }, [dispatch, id]);

  const { data, loading, error } = useSelector(selectEditedDiscount);

  const initialValues = data ? mapDiscountToInitialValues(data) : undefined;

  if (loading) return <SuspenseIndicator />;

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.DISCOUNT_EDIT_REQUEST}
        resolve={types.DISCOUNT_EDIT_RESPONSE}
        reject={types.DISCOUNT_EDIT_FAILURE}
      >
        {onSubmit => (
          <TypedForm
            onSubmit={values => {
              return onSubmit({
                id,
                values: {
                  ...values,
                  categoryDiscounts:
                    values.categoryDiscounts &&
                    deStringifyKeys(values.categoryDiscounts),
                  brandDiscounts:
                    values.brandDiscounts &&
                    deStringifyKeys(values.brandDiscounts),
                },
              }).then(errors => {
                if (errors) {
                  enqueueSnackbar(errors[FORM_ERROR], { variant: 'error' });
                  return errors;
                }
                enqueueSnackbar(t('distributor.discounts.editSucceeded'), {
                  variant: 'success',
                });
                return undefined;
              });
            }}
            render={DiscountCreate}
            initialValues={initialValues}
          />
        )}
      </MakeAsyncFunction>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </MuiPickersUtilsProvider>
  );
};

export default DiscountEditContainer;

function mapDiscountToInitialValues(
  rttDiscount: RttDiscount,
): CreateDiscountValues {
  const {
    rttId,
    discount,
    categoryDiscounts,
    productDiscounts,
    brandDiscounts,
    rttName,
    id,
  } = rttDiscount;

  return {
    rtt: { id: rttId, name: rttName, discountId: id } as DiscountRtt,
    discount,
    categoryDiscounts: stringifyKeys(
      categoryDiscounts.reduce(
        (acc, categoryDiscount) => ({
          ...acc,
          [categoryDiscount.targetId]: categoryDiscount.discount,
        }),
        {},
      ),
    ),
    productDiscounts: productDiscounts.reduce(
      (acc, productDiscount) => ({
        ...acc,
        [productDiscount.targetId]: productDiscount.discount,
      }),
      {},
    ),
    brandDiscounts: stringifyKeys(
      brandDiscounts.reduce(
        (acc, brandDiscount) => ({
          ...acc,
          [brandDiscount.targetId]: brandDiscount.discount,
        }),
        {},
      ),
    ),
  };
}
