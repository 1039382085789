/* eslint-disable @typescript-eslint/camelcase */
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

type SettingId = number;

type SettingDistributorId = number;

type ExportSettingType = 'email' | 'ftp';
type ExportSettingFrequency = 'manual' | 'daily' | 'hourly' | 'periodically';

export type FtpCreds = {
  host: string;
  user: string;
  pass: string;
  port: string;
  path: string;
};

type Email = string;

type FetchedSettingsExport = {
  id: SettingId;
  distributor_id: SettingDistributorId;
  type: ExportSettingType;
  email?: Email;
  frequency: ExportSettingFrequency;
  date?: string;
  time?: string;
  period?: number;
  ftp_creds?: FtpCreds;
};

type FetchedSettingsOrders = {
  id: SettingId;
  distributor_id: SettingDistributorId;
  min_total_order: number;
  delivery_days: boolean[];
  categories: number[];
};

export class SettingsExport {
  id: SettingId;
  distributorId: SettingDistributorId;
  type: ExportSettingType;
  email?: Email;
  frequency: ExportSettingFrequency;
  date?: string;
  // Time should be concatenated with date value
  time?: string;
  period?: number;
  ftpCreds: FtpCreds;

  constructor(settings: FetchedSettingsExport) {
    this.id = settings.id;
    this.distributorId = settings.distributor_id;
    this.type = settings.type;
    this.email = settings.email;
    this.frequency = settings.frequency;
    this.date = settings.date;
    this.time = `${settings.date || new Date().toISOString().split('T')[0]}T${
      settings.time
    }`;
    this.ftpCreds = settings.ftp_creds || ({} as FtpCreds);
    this.period = settings.period;
  }
}

export type EditSettingsExport = {
  type: string;
  email: Email;
  frequency: string;
  date: string;
  // Time should be concatenated with date value
  time: string;
  ftpCreds: FtpCreds;
  period: number;
};

/*
 * Settings API handlers
 */

export const getSettingsExport = () =>
  api
    .get<FetchedSettingsExport>(ENDPOINTS.settingsExport)
    .then(({ data }) => new SettingsExport(data));

export const testFtpCreds = (ftpCreds: FtpCreds) =>
  api
    .post<{ status: string }>(ENDPOINTS.settingsExportTestFtp, {
      // eslint-disable-next-line @typescript-eslint/camelcase
      ftp_creds: ftpCreds,
    })
    .then(data => data.data);

export const editSettingsExport = (values: EditSettingsExport) =>
  api
    .put<FetchedSettingsExport>(
      ENDPOINTS.settingsExport,
      mapEditSettingsValues(values),
    )
    .then(({ data }) => new SettingsExport(data));

const mapEditSettingsValues = (values: EditSettingsExport) => {
  const [date = undefined, time = undefined] = values.time?.split('T');
  return {
    type: values.type,
    email: values.email && values.email,
    frequency: values.frequency,
    period: values.period || undefined,
    date,
    time,
    ...(values.type === 'ftp'
      ? {
          ftp_creds: {
            host: values.ftpCreds.host,
            user: values.ftpCreds.user,
            pass: values.ftpCreds.pass,
            port: values.ftpCreds.port,
            path: values.ftpCreds.path,
          },
        }
      : null),
  };
};
