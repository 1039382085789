import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEntryExportStatusRequest } from '../../../duck/actions';
import { selectExportEntriesStateById } from '../../../duck/selectors';
import type { ExportDocument, ExportResultType } from '../../../types';
import ExportCheckbox from '../../ExportCheckbox';

const META_TYPES: Record<ExportResultType, { type: ExportResultType }> = {
  balance: { type: 'balance' as const },
  selling: { type: 'selling' as const },
  return: { type: 'return' as const },
  mixed: { type: 'mixed' as const },
};

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const ExportCell: React.FC<Props> = ({ id, value }) => {
  const { type, exportStatus } = value as ExportDocument;
  const entryId = String(id);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(
    selectExportEntriesStateById(type, entryId),
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`${entryId}: ${error}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, error, entryId]);
  const onChange = (checked: boolean) => {
    dispatch(
      setEntryExportStatusRequest(
        { id: entryId, isExported: checked },
        META_TYPES[type],
      ),
    );
  };

  return (
    <ExportCheckbox
      onChange={onChange}
      status={exportStatus?.status}
      disabled={loading}
      error={error}
    />
  );
};

export default ExportCell;
