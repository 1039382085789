/* eslint-disable @typescript-eslint/camelcase, @typescript-eslint/no-empty-interface */

import {
  formErrorTransform,
  mapPaginatedData,
  someBooleanToInt,
} from '@rtt-libs/api-services';
import type {
  Contact as IContact,
  DeliveryWeek,
  Editor as IEditor,
  FetchedContact,
  FetchedDocumentEditorsWithHistory,
  FetchedEditor,
  GeoLocation,
  GeoPoint,
  Measurement,
  OrderKey,
  OrderParam,
  OrderStatus,
  OrderStatusValue,
  Paginated,
  RttKey,
  UserStatus,
} from '@rtt-libs/types';
import trim from 'lodash/fp/trim';
import { ENDPOINTS } from '../environment';
import type * as OrderTypes from '../ordersRefactored/types';
import api from './apiSetup';
import { FetchedProduct } from './assortment';
import { ExportStatus, FetchedExportStatusField } from './exports';
import { transformSimpleErrorMessage } from './mappers';

export interface Order extends OrderTypes.Order {}
export interface ReturnOrder extends OrderTypes.ReturnOrder {}
export interface OrderedPayloadProduct
  extends OrderTypes.OrderedPayloadProduct {}
export interface ReturnedPayloadProduct
  extends OrderTypes.ReturnedPayloadProduct {}
export interface OrderedProduct extends OrderTypes.OrderedProduct {}

type FetchedDistributorInfo = OrderTypes.DistributorInfo<FetchedContact>;

type FetchedRttInfo = {
  id: number;
  name: string;
  address: string;
  geo: GeoPoint;
  status: UserStatus;
  created_at: string;
  contact: FetchedContact;
};

type FetchedOrder = {
  id: string;
  distributor_id: number;
  manager_id: number | null;
  rtt_id: number;
  status: OrderStatus;
  payload: string;
  total: string;
  origin_total: string;
  shipping_date: string | null;
  manager_description: string | null;
  rtt_description: string | null;
  created_at: string;
  manager?: FetchedContact;
  distributor?: FetchedDistributorInfo;
  rtt?: FetchedRttInfo;
  returned_items: string[];
} & Partial<FetchedExportStatusField> &
  FetchedDocumentEditorsWithHistory;

type FetchedReturnOrder = FetchedOrder & {
  order_id: string;
};

export class RttInfo {
  id?: number;
  name?: string;
  address?: string;
  geo?: GeoLocation;
  status?: UserStatus;
  createdAt?: string;
  contact?: Contact;

  constructor(rtt?: FetchedRttInfo) {
    this.id = rtt?.id;
    this.name = rtt?.name;
    this.address = rtt?.address;
    this.geo = rtt?.geo;
    this.status = rtt?.status;
    this.createdAt = rtt?.created_at;
    this.contact = rtt?.contact && new Contact(rtt.contact);
  }
}

interface Contact extends IContact {}

class Contact {
  constructor(contact: FetchedContact) {
    this.phone = contact.phone;
    this.email = contact.email;
    this.firstName = contact.first_name;
    this.lastName = contact.last_name;
  }
}

export interface Editor extends IEditor {}

export class Editor extends Contact {
  constructor(fetched: FetchedEditor) {
    super(fetched);
    this.date = fetched.date;
    this.role = fetched.role;
  }
}

export class Order {
  constructor(fetched: FetchedOrder) {
    this.id = fetched.id;
    this.distributorId = fetched.distributor_id;
    this.managerId = fetched.manager_id;
    this.rttId = fetched.rtt_id;
    this.status = fetched.status;
    this.shippingDate = fetched.shipping_date ?? undefined;
    this.managerDescription = fetched.manager_description ?? undefined;
    this.rttDescription = fetched.rtt_description ?? undefined;
    this.createdAt = fetched.created_at;
    this.manager = fetched.manager && new Contact(fetched.manager);
    this.distributor = fetched.distributor && {
      ...fetched.distributor,
      contact:
        fetched.distributor.contact && new Contact(fetched.distributor.contact),
    };
    this.rtt = new RttInfo(fetched.rtt);
    this.payload = fetched.payload;
    this.total = fetched.total;
    this.originTotal = fetched.origin_total;
    this.exportStatus =
      fetched.export_status && new ExportStatus(fetched.export_status);
    this.returnedItems = fetched.returned_items;
    this.creator = fetched.creator && new Editor(fetched.creator);
    this.lastEditor = fetched.last_editor && new Editor(fetched.last_editor);
    // editorList & managerList currently unused
    this.editorList = fetched.editor_list?.map(editor => new Editor(editor));
    this.managerList = fetched.manager_list?.map(({ date, ...manager }) => ({
      ...new Contact(manager),
      date,
    }));
  }
}

export class ReturnOrder extends Order {
  constructor(fetched: FetchedReturnOrder) {
    super(fetched);
    this.orderId = fetched.order_id;
  }
}

export class OrderedPayloadProduct {
  constructor(product: OrderTypes.OrderedProduct) {
    this.id = product.id;
    this.image = product.image;
    this.is_available = product.isAvailable ?? true;
    this.is_revocable = product.isRevocable ?? true;
    this.order_weight = product.orderWeight;
    this.price = product.price ?? 0;
    this.qty = product.qty;
    this.sale_measurement = product.saleMeasurement ?? 'weight';
    this.sku = product.sku;
    this.title = product.title;
    this.total = product.total;
    this.weight = product.weight;
    this.added_by_manager = product.addedByManager;
    this.brand_id = product.brandId;
    this.is_brand_operated_by_you = product.isBrandOperated;
  }
}

export class ReturnedPayloadProduct extends OrderedPayloadProduct {
  constructor(product: OrderTypes.ReturnedProduct) {
    super(product);
    this.return_note = product.returnNote;
    this.is_defective = product.isDefective;
  }
}

export class OrderedProduct {
  constructor(product: OrderedPayloadProduct) {
    this.id = product.id;
    this.image = product.image;
    this.isAvailable = product.is_available ?? true;
    this.isRevocable = product.is_revocable ?? true;
    this.orderWeight = product.order_weight;
    this.price = product.price;
    this.qty = product.qty;
    this.saleMeasurement = product.sale_measurement ?? 'weight';
    this.sku = product.sku;
    this.title = product.title;
    this.total = product.total;
    this.weight = product.weight;
    // Mapped to fix misconception in different representation of Product
    this.saleMeasurement =
      ((this.saleMeasurement as unknown) as {
        type: Measurement;
      })?.type || this.saleMeasurement;
    this.addedByManager = product.added_by_manager;
    this.isBrandOperated = product.is_brand_operated_by_you ?? true;
    this.brandId = product.brand_id;
  }
}

export class ReturnedProduct extends OrderedProduct
  implements OrderTypes.ReturnedProduct {
  returnNote?: string;
  isDefective?: boolean;

  constructor(product: ReturnedPayloadProduct) {
    super(product);
    this.returnNote = product.return_note;
    this.isDefective = product.is_defective;
  }
}

type ApiSearchParams = Partial<{
  start_date: string;
  end_date: string;
  status: OrderStatusValue | string;
  sort: 'status';
  order: OrderParam;
  per_page: number;
  page: number;
  include: string;
}>;

type ApiUpdateOrder = {
  distributor_id: number;
  key_id: string;
  payload: string;
  secret: number;
  signature: string;
  shipping_date?: string | null;
  manager_description?: string | null;
  suggest_order_id?: string;
};

export type ApiProductSearchParams = Partial<{
  search: string;
  category_id: number[];
  sort: 'sku' | 'title';
  order: OrderParam;
  page: number;
  per_page: number;
  order_id: string;
  is_active: 0 | 1;
  is_invalid: 0 | 1;
}>;

/*
 * Orders API handlers
 */

export const getOrderDetails = (orderId: string) =>
  api
    .get<FetchedOrder>(`${ENDPOINTS.orders}/${orderId}`)
    .then(({ data }) => new Order(data))
    .catch(transformSimpleErrorMessage);

export const getReturnOrderDetails = (returnOrderId: string) =>
  api
    .get<FetchedReturnOrder>(ENDPOINTS.returnOrdersEdit(returnOrderId))
    .then(({ data }) => new ReturnOrder(data))
    .catch(transformSimpleErrorMessage);

export const getOrders = (params?: OrderTypes.OrderSearchParams) =>
  api
    .get<Paginated<FetchedOrder>>(ENDPOINTS.orders, {
      params: params && mapSearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapOrderArray))
    .catch(transformSimpleErrorMessage);

export const getOrdersByIds = (params?: OrderTypes.OrderListParams) =>
  api
    .get<Paginated<FetchedOrder>>(ENDPOINTS.ordersByIds, {
      params: params && mapOrderListParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapOrderArray))
    .catch(transformSimpleErrorMessage);

export const getReturnOrders = (params?: OrderTypes.OrderSearchParams) =>
  api
    .get<Paginated<FetchedReturnOrder>>(ENDPOINTS.returnOrders, {
      params: params && mapSearchParams(params),
    })
    .then(({ data }) => mapPaginatedData(data, mapReturnOrderArray))
    .catch(transformSimpleErrorMessage);

export const getKeys = (orders: string[]) =>
  api
    .post<OrderKey>(ENDPOINTS.keys, { orders })
    .then(data => data.data)
    .catch(transformSimpleErrorMessage);

export const getKeysByRtt = (rtt: string[]) =>
  api
    .post<RttKey>(ENDPOINTS.keysByRtt, { rtt })
    .then(data => data.data)
    .catch(transformSimpleErrorMessage);

export const getAvailableStatuses = (orderId: string) =>
  api
    .get<OrderStatusValue[]>(`${ENDPOINTS.orders}/${orderId}/status`)
    .then(data => data.data)
    .catch(transformSimpleErrorMessage);

export const setOrderStatus = ({
  orderId,
  status,
}: {
  orderId: string;
  status: OrderStatusValue;
}) =>
  api
    .post<FetchedOrder>(`${ENDPOINTS.orders}/${orderId}/status`, { status })
    .then(({ data }) => new Order(data))
    .catch(transformSimpleErrorMessage);

export const setReturnOrderStatus = ({
  returnId,
  status,
}: {
  returnId: string;
  status: OrderStatusValue;
}) =>
  api
    .post<FetchedReturnOrder>(ENDPOINTS.returnOrdersStatus(returnId), {
      status,
    })
    .then(({ data }) => new ReturnOrder(data))
    .catch(transformSimpleErrorMessage);

export const updateOrder = (
  id: string,
  order: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
) =>
  api
    .put<FetchedOrder>(
      `${ENDPOINTS.orders}/${id}`,
      mapUpdateOrder(order, secrets),
    )
    .then(({ data }) => new Order(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const createOrder = (
  order: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
) =>
  api
    .post<FetchedOrder>(ENDPOINTS.orders, mapUpdateOrder(order, secrets))
    .then(({ data }) => new Order(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const updateReturnOrder = (
  id: string,
  returnOrder: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
) =>
  api
    .put<FetchedReturnOrder>(
      ENDPOINTS.returnOrdersEdit(id),
      mapUpdateOrder(returnOrder, secrets),
    )
    .then(({ data }) => new ReturnOrder(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const createReturnOrder = (
  orderId: string,
  returnOrder: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
) =>
  api
    .post<FetchedReturnOrder>(
      ENDPOINTS.returnOrderCreate(orderId),
      mapUpdateOrder(returnOrder, secrets),
    )
    .then(({ data }) => new ReturnOrder(data))
    .catch(e => {
      throw formErrorTransform(e);
    });

export const searchProductsForRtt = (
  rttId: number,
  params?: OrderTypes.ProductSearchParams,
) =>
  api
    .get<Paginated<FetchedProduct>>(ENDPOINTS.orderProducts(rttId), {
      params: params && mapProductSearchParams(params),
    })
    .then(data =>
      mapPaginatedData(data.data, products =>
        products.map(
          product =>
            new OrderedProduct({
              ...product,
              price: product.price ?? 0,
              total: 0,
              qty: 0,
              order_weight: 0,
            }),
        ),
      ),
    )
    .catch(transformSimpleErrorMessage);

export const getDeliveryDaysByRtt = (rttId: number) =>
  api
    .get<{ delivery_days: DeliveryWeek }>(ENDPOINTS.orderDeliveryDays(rttId))
    .then(({ data }) => data.delivery_days)
    .catch(transformSimpleErrorMessage);

/*
 * Order Mappers
 */

function mapOrderArray(orders: FetchedOrder[]) {
  try {
    return orders.map(order => new Order(order));
  } catch (e) {
    throw new Error('Order mapping error');
  }
}
function mapReturnOrderArray(orders: FetchedReturnOrder[]) {
  try {
    return orders.map(order => new ReturnOrder(order));
  } catch (e) {
    throw new Error('Order mapping error');
  }
}

function mapSearchParams(
  params: OrderTypes.OrderSearchParams,
): ApiSearchParams {
  return {
    start_date: params.startDate,
    end_date: params.endDate,
    status: params.status,
    sort: params.orderBy,
    order: params.order,
    per_page: params.perPage,
    page: params.page,
    // TODO: Add additional `join(',')` if there will be multiple includes
    include: params.include,
  };
}

function mapOrderListParams(params: OrderTypes.OrderListParams) {
  return {
    id_list: params.idList?.join(),
    per_page: params.idList?.length,
    // per_page: params.perPage,
    // page: params.page,
  };
}

function mapProductSearchParams(
  params: OrderTypes.ProductSearchParams,
): ApiProductSearchParams {
  return {
    sort: params.orderBy,
    order: params.order,
    per_page: params.perPage,
    page: params.page,
    category_id: params.categoryIds,
    search:
      (typeof params.search === 'string' && trim(params.search)) || undefined,
    order_id: params.orderId,
    is_active: someBooleanToInt(params.isActive),
    is_invalid: someBooleanToInt(params.isInvalid),
  };
}

function mapUpdateOrder(
  order: OrderTypes.NewOrder,
  secrets: OrderTypes.SecretCheck,
): ApiUpdateOrder {
  return {
    payload: order.payload,
    shipping_date: order.shippingDate || null,
    manager_description: order.managerDescription,
    distributor_id: order.distributorId,
    suggest_order_id: order.suggestOrderId,
    key_id: secrets.keyId,
    secret: secrets.secret,
    signature: secrets.signature,
  };
}

/**
 * Additional handlers
 */

// TODO: [settings domain]: move to settings domain after it implementation
export type FetchedSettings = {
  id: number;
  distributor_id: number;
  min_total_order: number | null;
  delivery_days: DeliveryWeek;
  categories: number[];
};

export const getMinTotalOrderSettings = () =>
  api
    .get<FetchedSettings>(ENDPOINTS.settings)
    .then(({ data }) => data.min_total_order ?? 0)
    .catch(transformSimpleErrorMessage);
