import {
  CircularProgress,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { omit } from 'lodash/fp';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { statisticsAssortmentSel } from '../duck/selectors';

/*
 * TODO: this component (and its dependents) has at least 2 problems
 * When modal opens drop & browse refs the `current`s are set with actual DOMElements only after closing modal
 * So to bypass this the whole option object will be updated by setting new target value.
 * This 'bypass' leads to next problem. In useResumable hook in useEffect can't be returned cleanup function
 * with `cancel()` actual resumable. It will cleanup, and cancel uploading, if there are more then one chunk.
 */

const StatisticsSection: React.FC = () => {
  const [t] = useTranslation();
  const { statistics, loading, error } = useSelector(statisticsAssortmentSel);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  if (!statistics) return null;

  const showLoading = !error && (loading || statistics.status !== 'finished');

  const renderListItems = Object.entries(
    omit(['status', 'message'], statistics),
  ).map(([key, value = 0]) => (
    <ListItem key={key}>
      <ListItemText
        primary={`${t(`distributor.assortment.statistics.${key}`)}: ${value}`}
      />
    </ListItem>
  ));

  return (
    <Grid container className="MuiCardContent-root">
      <Grid item xs={12} md={6}>
        <Typography variant="h5">
          {t('distributor.assortment.statistics.title')}
          <Fade in={showLoading} unmountOnExit>
            <CircularProgress size="1em" />
          </Fade>
        </Typography>
        {error ? (
          <Typography variant="subtitle1" color="error">
            {error}
          </Typography>
        ) : (
          <List>{renderListItems}</List>
        )}
      </Grid>
    </Grid>
  );
};

export default StatisticsSection;
