import {
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import { without } from 'lodash/fp';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  fieldNames: string[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(1, 0),
    },
  }),
);

const AdditionalProductDetails: React.FC<Props> = ({ fieldNames }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [additionalFields, setAdditionalFields] = useState<string[]>(
    fieldNames,
  );

  useEffect(() => {
    setAdditionalFields(fieldNames);
  }, [fieldNames]);

  const handleRemoveOption = (id: string) => {
    setAdditionalFields(prev => without([id], prev));
  };

  if (!additionalFields.length) return null;

  return (
    <>
      <Typography variant="h6">{t('product.additionalInfoLegend')}</Typography>
      {additionalFields.map(key => (
        <Field
          name={`additionalInfo.${key}`}
          type="text"
          fullWidth
          key={key}
          label={key}
          component={TextFieldAdapter}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <RemoveFieldButton id={key} onClick={handleRemoveOption} />
            ),
          }}
        />
      ))}
    </>
  );
};

type RemoveFieldButtonType = {
  id: string;
  onClick: (id: string) => void;
};

const RemoveFieldButton = ({ id, onClick }: RemoveFieldButtonType) => (
  <InputAdornment position="end">
    <IconButton aria-label="remove option" onClick={() => onClick(id)}>
      <DeleteIcon />
    </IconButton>
  </InputAdornment>
);

export default AdditionalProductDetails;
