import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import { ActionsMenu, LoadingButton } from '@rtt-libs/views';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { promiseListener } from '../../setup';
import { Brand } from '../../types';
import CreateDialog from './CreateDialog';
import EditBrandContainer from './EditBrandContainer';

/*
 * TODO: this component should be refactored to reduce copy-paste from Employees duck.
 * Extract common modals for create/edit, form wrapper, action buttons & filter row.
 * Probably move that components to libs
 */

type Props = {
  id: string | number;
  value?: Brand;
  actionTypes: Record<'start' | 'resolve' | 'reject', string>;
};

/**
 * Menu for Brand with available status options & confirmation dialog
 */
const BrandActionsMenu: React.FC<Props> = ({ id, value, actionTypes }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errorMessage, setError] = useState<string | null>(null);
  const [loading, changeLoading] = useState(false);

  const handleOpen = () => setConfirmOpen(true);
  const handleClose = () => {
    setConfirmOpen(false);
    setError(null);
  };

  /*
   * Edit modal
   */
  const [editOpen, changeEditOpen] = useState(false);

  const handleEditOpen = () => changeEditOpen(true);
  const handleEditClose = () => changeEditOpen(false);

  const editInitialValues = useMemo(
    () =>
      value && {
        id: value.id,
        title: value.title,
      },
    [value],
  );

  if (!value) return null;

  const changeStatusOption = {
    label: value.isActive
      ? t(`distributor.employees.change.deactivateLabel`)
      : t(`distributor.employees.change.activateLabel`),
    onClick: () => handleOpen(),
  };

  const optionsAssigned = [
    {
      label: t(`distributor.employees.change.editLabel`),
      onClick: handleEditOpen,
    },
  ];

  const options = optionsAssigned.concat(changeStatusOption);

  return (
    <>
      <ActionsMenu id={id} options={options} />

      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        onExited={() => changeLoading(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
      >
        <DialogTitle id="confirm-dialog-title">
          {t('distributor.employees.change.confirmStatusTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t(
              `distributor.brands.confirmStatusDescription${
                value.isActive ? 'Inactive' : 'Active'
              }`,
            )}
          </DialogContentText>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            {t('controls.cancel')}
          </Button>

          <MakeAsyncFunction listener={promiseListener} {...actionTypes}>
            {onClick => (
              <LoadingButton
                onClick={() => {
                  changeLoading(true);
                  onClick({ id: value.id, isActive: !value.isActive })
                    .then(() => {
                      enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                        variant: 'success',
                      });
                      handleClose();
                    })
                    .catch(error => {
                      enqueueSnackbar(t('common.snackbars.saveFailed'), {
                        variant: 'error',
                      });
                      changeLoading(false);
                      setError(error);
                    });
                }}
                loading={loading}
                color="primary"
                autoFocus
              >
                {t('controls.agree')}
              </LoadingButton>
            )}
          </MakeAsyncFunction>
        </DialogActions>
      </Dialog>

      {editInitialValues && (
        <CreateDialog
          open={editOpen}
          onClose={handleEditClose}
          FormContainer={props => (
            <EditBrandContainer initialValues={editInitialValues} {...props} />
          )}
        />
      )}
    </>
  );
};

export default BrandActionsMenu;
