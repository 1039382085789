import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import { FieldWithMessages as Field, SelectAdapter } from '@rtt-libs/views';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrencyOptionsSel } from '../../duck/selectors';

const useStyles = makeStyles(
  createStyles({
    selectInput: {
      minWidth: 60,
    },
  }),
);

const CurrencySelect: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();

  const currencyOptions = useSelector(getCurrencyOptionsSel);

  return (
    <Field
      name="priceCurrencyId"
      label={t('product.currency')}
      formControlProps={{
        className: classes.selectInput,
        required: true,
      }}
      component={SelectAdapter}
      autoWidth
      type="text"
      required
      validate={value => (value ? undefined : t('validation.valueMissing'))}
    >
      {currencyOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default CurrencySelect;
