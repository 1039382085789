/* eslint-disable @typescript-eslint/camelcase */
import { formErrorTransform } from '@rtt-libs/api-services';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';
import { transformSimpleErrorMessage } from './mappers';

type SettingId = number;

type SettingDistributorId = number;

type FetchedSettingsOrders = {
  id: SettingId;
  distributor_id: SettingDistributorId;
  min_total_order: number;
  delivery_days: boolean[];
  categories: number[];
};

export type EditSettingsOrders = {
  minTotalOrder: number;
  deliveryDays: boolean[];
  categories: number[];
};

export class SettingsOrders {
  id: SettingId;
  distributorId: SettingDistributorId;
  minTotalOrder: number;
  deliveryDays: boolean[];
  categories: number[];

  constructor(settings: FetchedSettingsOrders) {
    this.id = settings.id;
    this.distributorId = settings.distributor_id;
    this.minTotalOrder = settings.min_total_order ?? 0;
    this.deliveryDays = settings.delivery_days;
    this.categories = settings.categories;
  }
}

/*
 * Settings API handlers
 */

export const getSettingsOrders = () =>
  api
    .get<FetchedSettingsOrders>(ENDPOINTS.settingsOrders)
    .then(({ data }) => new SettingsOrders(data))
    .catch(transformSimpleErrorMessage);

export const editSettingsOrders = (values: EditSettingsOrders) =>
  api
    .put<FetchedSettingsOrders>(
      ENDPOINTS.settingsOrders,
      mapEditSettingsValues(values),
    )
    .then(({ data }) => new SettingsOrders(data))
    .catch(e => {
      throw formErrorTransform(e, errors => ({
        categories: errors.categories?.toString(),
        deliveryDays: errors.delivery_days?.toString(),
        minTotalOrder: errors.min_total_order?.toString(),
      }));
    });

const mapEditSettingsValues = (values: EditSettingsOrders) => {
  return {
    min_total_order: values.minTotalOrder ? +values.minTotalOrder : 0,
    delivery_days: values.deliveryDays,
    categories: values.categories,
  };
};
