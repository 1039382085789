import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearProductsState } from '../duck/actions';

/**
 * Clear redux state slice of Products for adding to orders, returns etc.
 * after unmount a component
 */
export const useClearProducts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearProductsState());
    };
  }, [dispatch]);
};

export default useClearProducts;
