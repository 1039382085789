import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAgreedAssortmentRequest } from '../../duck/actions';
import { selectAgreedAssortment } from '../../duck/selectors';

interface Props {
  id: string | number | symbol;
}

const DeleteCell = ({ id }: Props) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useSelector(selectAgreedAssortment(String(id)));

  const rttName = data.rtt?.name || data.rttId;

  const messages = {
    success: t('distributor.agreedAssortments.delete.notification.success', {
      rttName,
    }),
    error: t('distributor.agreedAssortments.delete.notification.error', {
      rttName,
    }),
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      deleteAgreedAssortmentRequest(String(id), enqueueSnackbar, messages),
    );
  };

  return (
    <Tooltip title={t('controls.delete') ?? ''}>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteCell;
