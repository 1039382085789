import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import type { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import type { StaffEditValues } from '../../types';
import StaffFormFields from '../CreateStaff/StaffFormFields';
import DialogActionsButtons from '../DialogActionsButtons';

type Props<T> = FormRenderProps<T> & { onClose(): void };

function EditStaffForm<T extends StaffEditValues = StaffEditValues>({
  handleSubmit,
  submitError,
  onClose,
  children,
}: Props<T>) {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="edit-employee-dialog-title">
        {t('distributor.employees.change.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('distributor.employees.change.dialogDescription')}
        </DialogContentText>
        <Field name="id" component="input" hidden />

        <StaffFormFields
          passwordField={
            <Field
              margin="dense"
              name="password"
              type="text"
              fullWidth
              required
              component={TextFieldAdapter}
              inputProps={{
                minLength: 6,
                maxLength: 32,
              }}
              label={t('distributor.employees.change.passwordLabel')}
              placeholder={t(
                'distributor.employees.change.passwordPlaceholder',
              )}
            />
          }
        />

        {children}

        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>

      <DialogActionsButtons
        onClose={onClose}
        submitLabel={t('controls.save')}
      />
    </form>
  );
}

export default EditStaffForm;
