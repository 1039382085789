import { GeoLocation } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import { AreaCreateValues } from '../../api/territories';
import { Area } from './model';
import * as types from './types';

export const getTerritoriesRequest = () => ({
  type: types.GET_TERRITORIES_REQUEST,
});

export const getTerritoriesSuccess = (payload: Area[]) => ({
  type: types.GET_TERRITORIES_SUCCESS,
  payload,
});

export const getTerritoriesFailure = (error: string) => ({
  type: types.GET_TERRITORIES_FAILURE,
  payload: error,
});

export const createTerritoriesRequest = (values: AreaCreateValues) => ({
  type: types.CREATE_TERRITORIES_REQUEST,
  payload: values,
});

export const createTerritoriesSuccess = (
  payload?: Partial<Area> & { [FORM_ERROR]?: unknown },
) => ({
  type: types.CREATE_TERRITORIES_SUCCESS,
  payload,
});

export const createTerritoriesResponse = (
  payload: Area,
  changed: {
    managers: number | number[];
    edited: Record<Area['id'], GeoLocation[]>;
    deleted: number[];
  },
) => ({
  type: types.CREATE_TERRITORIES_RESPONSE,
  payload,
  meta: { changed },
});

export const createTerritoriesFailure = (error?: string) => ({
  type: types.CREATE_TERRITORIES_FAILURE,
  payload: error,
});

type EditValues = { id: number; values: AreaCreateValues };

export const editTerritoriesRequest = ({ id, values }: EditValues) => ({
  type: types.EDIT_TERRITORIES_REQUEST,
  payload: { id, values },
});

export const editTerritoriesSuccess = (
  payload?: Partial<Area> & { [FORM_ERROR]?: unknown },
) => ({
  type: types.EDIT_TERRITORIES_SUCCESS,
  payload,
});

export const editTerritoriesResponse = (
  payload: Area,
  changed: {
    managers: number | number[];
    edited: Record<Area['id'], GeoLocation[]>;
    deleted: number[];
  },
) => ({
  type: types.EDIT_TERRITORIES_RESPONSE,
  payload,
  meta: { changed },
});

export const editTerritoriesFailure = (error?: string) => ({
  type: types.EDIT_TERRITORIES_FAILURE,
  payload: error,
});

export const deleteTerritoriesRequest = (id: Area['id']) => ({
  type: types.DELETE_TERRITORIES_REQUEST,
  payload: id,
});

export const deleteTerritoriesSuccess = (
  id: Area['id'],
  changed: { manager: number },
) => ({
  type: types.DELETE_TERRITORIES_SUCCESS,
  payload: id,
  meta: { changed },
});

export const deleteTerritoriesFinish = (id: Area['id']) => ({
  type: types.DELETE_TERRITORIES_FINISH,
  payload: id,
});

export const deleteTerritoriesFailure = (
  error: string,
  meta: { id: Area['id'] },
) => ({
  type: types.DELETE_TERRITORIES_FAILURE,
  payload: error,
  meta,
});

export type Actions = ReturnType<
  | typeof getTerritoriesRequest
  | typeof getTerritoriesSuccess
  | typeof getTerritoriesFailure
  | typeof createTerritoriesRequest
  | typeof createTerritoriesSuccess
  | typeof createTerritoriesResponse
  | typeof createTerritoriesFailure
  | typeof editTerritoriesRequest
  | typeof editTerritoriesSuccess
  | typeof editTerritoriesResponse
  | typeof editTerritoriesFailure
  | typeof deleteTerritoriesRequest
  | typeof deleteTerritoriesSuccess
  | typeof deleteTerritoriesFinish
  | typeof deleteTerritoriesFailure
>;
