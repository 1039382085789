/* eslint-disable @typescript-eslint/camelcase */
import { formErrorTransform, TransformAPIError } from '@rtt-libs/api-services';
import { changePassword, ChangePassword } from '@rtt-libs/auth';
import { FetchedContact, Status } from '@rtt-libs/types/types';
import { camelCase, keysIn } from 'lodash';
import { ENDPOINTS } from '../environment';
import api from './apiSetup';

function mapErrorsToCamelCase(
  errors: Partial<Record<string, string | string[]>>,
): Record<string, string> {
  const camelCasedErrors = {} as Record<string, string>;
  keysIn(errors).forEach(k => {
    const errorMessages = errors[k];
    if (errorMessages) {
      camelCasedErrors[camelCase(k)] = errorMessages.toString();
    }
  });

  return camelCasedErrors;
}

type ProfileId = number;

type Phone = string;

type Email = string;

export type Documents = {
  id: string;
  name: string;
  isThumbnail: boolean;
  thumbSize: string;
  type: string;
  src: string;
  related: {
    data: {
      id: string;
      name: string;
      isThumbnail: boolean;
      thumbSize: string;
      type: string;
      src: string;
    }[];
  };
};

export type FetchedDocuments = {
  id: string;
  name: string;
  is_thumbnail?: boolean;
  thumb_size: string;
  src: string;
};

type FetchedProfile = {
  id: ProfileId;
  name: string;
  address: string;
  status: Status;
  created_at: string;
  logo: Documents | null;
  documents: Documents[];
  contact: FetchedContact;
};

export type EditProfile = {
  id: ProfileId;
  name: string;
  address: string;
  logo: Documents | {} | null;
  email: Email;
  firstName: string;
  lastName: string;
};

export class Profile {
  id: ProfileId;
  name: string;
  address: string;
  status: Status;
  createdAt: string;
  documents: Documents[];
  logo: Documents | null;
  phone: Phone;
  email: Email;
  firstName: string;
  lastName: string;

  constructor(profile: FetchedProfile) {
    this.id = profile.id;
    this.name = profile.name;
    this.address = profile.address;
    this.createdAt = profile.created_at;
    this.phone = profile.contact.phone;
    this.email = profile.contact.email;
    this.firstName = profile.contact.first_name;
    this.lastName = profile.contact.last_name;
    this.logo = profile.logo;
    this.status = profile.status;
    this.documents = profile.documents;
  }
}

/*
 * Profile API handlers
 */

export const getProfile = () =>
  api
    .get<FetchedProfile>(`${ENDPOINTS.profile}?include=contact,documents`)
    .then(({ data }) => new Profile(data));

export const editProfile = (values: EditProfile) =>
  api
    .post<FetchedProfile>(
      `${ENDPOINTS.profile}/${values.id}/profile?include=contact,documents`,
      mapEditProfileValues(values),
    )
    .then(({ data }) => new Profile(data))
    .catch(e => {
      throw formErrorTransform(e, mapErrorsToCamelCase);
    });

export const sendChangePassword = (values: ChangePassword) =>
  changePassword(values).catch(error => {
    throw formErrorTransform(error, transformChangePasswordErrors);
  });

function mapEditProfileValues(values: EditProfile) {
  const data = new FormData();
  const mappedPayload = {
    id: values.id,
    name: values.name,
    address: values.address,
    logo: (values.logo as Documents)?.id ? '' : values.logo || '',
    email: values.email,
    first_name: values.firstName,
    last_name: values.lastName,
  };

  Object.entries(mappedPayload).forEach(([key, value]) => {
    data.append(key, value as string | Blob);
  });

  return data;
}

const transformChangePasswordErrors: TransformAPIError<
  Record<
    'password' | 'new_password' | 'new_password_confirmation',
    string | string[]
  >,
  Record<keyof ChangePassword, string>
> = function transformEditModeratorErrors(e) {
  return {
    password: e.password?.toString(),
    newPassword: e.new_password?.toString(),
    newPasswordConfirmation: e.new_password_confirmation?.toString(),
  };
};
