import { MenuItem } from '@material-ui/core';
import { SelectAdapter } from '@rtt-libs/views';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const EmployeeRoleField = () => {
  const [t] = useTranslation();

  const roleOptions = [
    { value: 'distributor_driver', label: t('roles.distributor_driver') },
    { value: 'distributor_manager', label: t('roles.distributor_manager') },
  ];

  return (
    <Field
      name="role"
      label={t('distributor.employees.add.role.label')}
      formControlProps={{
        required: true,
        fullWidth: true,
      }}
      component={SelectAdapter}
      type="text" // Added because Mui SelectInput doesn't pass "required" prop to input.
      validate={value => (value ? undefined : t('validation.valueMissing'))}
    >
      {roleOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default EmployeeRoleField;
