import { Button, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ENDPOINTS } from '../../environment';
import {
  statisticsAssortmentRequest,
  statisticsAssortmentSuccess,
} from '../duck/actions';
import UploadDialog from './UploadDialog';
import UploadProgress from './UploadProgress';
import useUploadFile from './useUploadFile';

type AssortmentAction = 'upload' | 'update' | undefined | null;

const UploadSection: React.FC = () => {
  const [t] = useTranslation();

  const [opened, setOpened] = useState<AssortmentAction>();
  const [target, setTarget] = useState<string>();

  const {
    browseRef,
    dropZoneRef,
    progress,
    isCompleted,
    error,
  } = useUploadFile<HTMLDivElement>(target);

  // Update target onEntered event for updating state in useUploadFile hook
  // after internal refs update their `current`s.
  const onEntered = useCallback(() => {
    setOpened(openedState => {
      if (openedState === 'upload') {
        setTarget(ENDPOINTS.uploadAssortment);
      }
      if (openedState === 'update') {
        setTarget(ENDPOINTS.updateAssortment);
      }
      return openedState;
    });
  }, [setTarget, setOpened]);

  const closeModal = useCallback(() => setOpened(undefined), [setOpened]);

  // Reset target for handle case when user open same modal which was closed before.
  // (target's state will not be updated and internal refs too).
  const onExited = useCallback(() => {
    setTarget(undefined);
  }, [setTarget]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`${error[0]}: ${error[1]}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  // Close modal after uploading process starts.
  useEffect(() => {
    if (progress !== null) {
      setOpened(undefined);
    }
  }, [progress]);

  // Get statistics on complete uploading file
  const dispatch = useDispatch();
  useEffect(() => {
    if (isCompleted) {
      dispatch(statisticsAssortmentRequest());
    } else {
      dispatch(statisticsAssortmentSuccess(null));
    }
  }, [isCompleted, dispatch]);

  const descriptions = [
    {
      id: 'upload' as const,
      description: t(
        'distributor.assortment.upload.description.fullAssortment',
      ),
      button: t('controls.selectFile'),
      target: ENDPOINTS.uploadAssortment,
    },
    {
      id: 'update' as const,
      description: t(
        'distributor.assortment.upload.description.partialAssortment',
      ),
      button: t('controls.selectFile'),
      target: ENDPOINTS.updateAssortment,
    },
  ];

  const renderDescriptions = descriptions.map(entry => (
    <Grid
      key={entry.id}
      container
      spacing={2}
      item
      xs={12}
      md={6}
      direction="column"
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography>{entry.description}</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpened(entry.id);
          }}
          disabled={progress !== null}
        >
          {entry.button}
        </Button>
      </Grid>
    </Grid>
  ));

  return (
    <Grid container spacing={4}>
      {renderDescriptions}

      <Grid item xs={12}>
        {!!error && (
          <Alert severity="error">{`${error[0]}: ${error[1]}`}</Alert>
        )}
        <UploadProgress value={progress} />
      </Grid>

      <UploadDialog
        open={!!opened}
        close={closeModal}
        browseRef={browseRef}
        dropZoneRef={dropZoneRef}
        onEntered={onEntered}
        onExited={onExited}
      />
    </Grid>
  );
};

export default UploadSection;
