import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RttAsyncAutocompleteField from '../../../discounts/view/RttAsyncAutocomplete';
import { fetchBalancesRttRequest } from '../../duck/actions';
import { selectRttOptions } from '../../duck/selectors';

interface Props {
  readOnly?: boolean;
}

const RttField: React.FC<Props> = ({ readOnly }) => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectRttOptions);
  const fetchOptions = useCallback(() => dispatch(fetchBalancesRttRequest()), [
    dispatch,
  ]);

  return (
    <RttAsyncAutocompleteField
      fetchOptions={fetchOptions}
      options={data}
      loading={loading}
      name="rttId"
      format={value => data.find(option => option.id === value)}
      parse={option => option?.id}
      subscription={{ value: true, error: true }}
    />
  );
};

export default RttField;
