import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TableWithHistory, EmptyAlert } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterRow from './FilterRow';
import administratorsTableConfig from './tableConfig';
import type { SearchParams, Administrator } from '../types';

type Props = {
  administrators: Administrator[];
  pagination: Pagination;
  loading?: boolean;
  onStateChange: (state: SearchParams) => void;
};

/**
 * Wrapper component to pass administrators data into common table component
 * Also handles routing to Employee detail page.
 */
const AdministratorsTable: React.FC<Props> = ({
  administrators,
  pagination,
  loading,
  onStateChange,
}) => {
  const [t] = useTranslation();

  const columnConfig = administratorsTableConfig(t);

  const { perPage = 10, currentPage } = pagination;

  const initialParams = useMemo(
    () => ({
      perPage,
      page: currentPage || 1,
      isActive: '1' as const,
    }),
    [perPage, currentPage],
  );

  const localeTableLabels = {
    sort: t('admin.tables.common.sort', 'Sort'),
    displayedRows: ({ from, to, count }: Record<string, number>) =>
      t('admin.tables.common.displayedRows', {
        from,
        to,
        count,
      }),
    rowsPerPage: t('admin.tables.common.rowsPerPage', 'per page'),
  };

  return (
    <TableWithHistory
      filterRow={({ onChange, displayOptions }) => (
        <FilterRow onChange={onChange} initialFilterValues={displayOptions} />
      )}
      loading={loading}
      entries={(administrators as unknown) as Record<string, unknown>[]}
      columnsConfig={columnConfig}
      perPageOptions={table.PAGINATION_OPTIONS}
      initial={initialParams}
      totalEntriesCount={pagination.total}
      localeLabels={localeTableLabels}
      onStateChange={onStateChange}
      emptyMessage={<EmptyAlert />}
    />
  );
};

export default AdministratorsTable;
