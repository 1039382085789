import React, { useCallback, useState } from 'react';
import { useBrands } from '../../../../brands';
import { BrandSearchParams } from '../../../../brands/types';
import { EntitiesSearch } from '../../../../productsAddition/view/ProductAddCommon';
import { Brand } from '../../../../types';
import BrandListWithClickCallback from './BrandListWithClickCallback';

type Props = {
  existingIds: Brand['id'][];
  onSubmit(brand: Brand): void;
  onClose(): void;
};

const AddBrandContainer: React.FC<Props> = ({
  existingIds,
  onSubmit,
  onClose,
}) => {
  const [searchParams, changeSearchParams] = useState<
    Partial<BrandSearchParams> | undefined
  >();

  const { data, loading, error } = useBrands(searchParams);

  const handleBrandClick = useCallback(
    (brand: Brand) => {
      onSubmit(brand);
      onClose();
    },
    [onSubmit, onClose],
  );

  const onChange = useCallback(
    (params: { search?: string }) => {
      const { search } = params;
      const nextParams = search ? { title: search } : undefined;
      changeSearchParams(nextParams);
    },
    [changeSearchParams],
  );

  return (
    <EntitiesSearch
      isEmpty={!data.length}
      loading={loading}
      error={error}
      onChange={onChange}
    >
      <BrandListWithClickCallback
        data={data}
        existingIds={existingIds}
        onClick={handleBrandClick}
      />
    </EntitiesSearch>
  );
};

export default AddBrandContainer;
