import { Box, Button, Grid, TextField } from '@material-ui/core';
import { useAuth } from '@rtt-libs/auth';
import { ux } from '@rtt-libs/constants';
import debounce from 'lodash/debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateLink from '../../../common/orders/CreateLink';
import routes from '../../../pages/routes';
import { PERMISSIONS } from '../../../rules';
import { AgreedAssortmentTableParams } from '../../types';

type Props = {
  onChange(params: Partial<AgreedAssortmentTableParams>): void;
  initialFilterValue: Record<string, string>;
};

/**
 * Component to handle filtering of table data.
 */
const FilterRow: React.FC<Props> = ({
  onChange,
  initialFilterValue: { search = '' },
}) => {
  const [t] = useTranslation();
  const { isPermittedTo } = useAuth();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  return (
    <Box pb={2} mx={1} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            defaultValue={search}
            name="search"
            label={t('common.search')}
            onChange={onSearchChange}
            fullWidth
          />
        </Grid>

        {isPermittedTo(PERMISSIONS.NAV_AGREED_ASSORTMENT_CREATE) && (
          <>
            <Grid item xs={6} md={3}>
              <CreateLink to={routes.agreedAssortmentCreate} fullWidth />
            </Grid>

            <Grid item xs={6} md={3}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled
                fullWidth
              >
                {t('controls.edit')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default FilterRow;
