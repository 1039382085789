import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { CreateEmployeeValues } from '../../api/employees';
import { CreateStaffForm } from '../../common/staff';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import EmployeeRoleField from './EmployeeRoleField';

const INITIAL_VALUES = { phone: '+380' };

type Props = {
  onClose(): void;
};

const CreateEmployeeContainer: React.FC<Props> = ({ onClose }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.EMPLOYEE_CREATE_REQUEST}
      resolve={types.EMPLOYEE_CREATE_SUCCESS}
      reject={types.EMPLOYEE_CREATE_FAILURE}
    >
      {onSubmit => (
        <Form
          initialValues={INITIAL_VALUES}
          onSubmit={async (values: CreateEmployeeValues) => {
            try {
              await onSubmit(values);
              onClose();

              enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                variant: 'success',
              });

              return undefined;
            } catch (errors) {
              enqueueSnackbar(t('common.snackbars.saveFailed'), {
                variant: 'error',
              });

              return errors;
            }
          }}
          render={props => (
            <CreateStaffForm onClose={onClose} {...props}>
              <EmployeeRoleField />
            </CreateStaffForm>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default CreateEmployeeContainer;
