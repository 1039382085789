/* eslint-disable @typescript-eslint/camelcase */

import { ENDPOINTS } from '../environment';
import api from './apiSetup';

type AreaId = number;

type Polygon = {
  lat: number;
  lng: number;
};

type FetchedAreas = {
  id: AreaId;
  title: string;
  polygon: Polygon[];
};

export class RttAreas {
  id: AreaId;
  title: string;
  polygon: Polygon[];
  [key: string]: unknown;

  constructor(areas: FetchedAreas) {
    this.id = areas.id;
    this.title = areas.title;
    this.polygon = areas.polygon;
  }
}

type Search<T> = {
  data: T[];
};

/*
 * Areas API handlers
 */

export const getAreas = () =>
  api
    .get<Search<FetchedAreas>>(ENDPOINTS.mapRtt)
    .then(({ data }) => mapAreasArray(data.data));

/*
 * Areas Mappers
 */

function mapAreasArray(areaArray: FetchedAreas[]) {
  return areaArray.map(rtt => new RttAreas(rtt));
}
