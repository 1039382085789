import React from 'react';
import {
  createStyles,
  makeStyles,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DataRtt } from '@rtt-libs/types';

const useStyles = makeStyles(
  createStyles({
    card: {
      minWidth: 275,
    },
    title: {
      fontWeight: 'bold',
      display: 'inline-block',
    },
    text: {
      fontWeight: 'normal',
      display: 'inline-block',
    },
  }),
);

const InfoContainer: React.FC<DataRtt> = ({
  name,
  contact: { phone, firstName, lastName },
  address,
  orderQty,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title}>
            {t('distributor.tables.rtt.name', 'Name')}
          </Typography>
          <Typography className={classes.text}>: {name}</Typography>
          <br />
          <Typography className={classes.title}>
            {t('distributor.tables.rtt.contact', 'Contact')}
          </Typography>
          <Typography className={classes.text}>
            : {`${firstName} ${lastName}`}
          </Typography>
          <br />
          <Typography className={classes.title}>
            {t('distributor.tables.rtt.address', 'Address')}
          </Typography>
          <Typography className={classes.text}>: {address}</Typography>
          <br />
          <Typography className={classes.title}>
            {t('distributor.tables.rtt.phone', 'Phone')}
          </Typography>
          <Typography className={classes.text}>: {phone}</Typography>
          <br />
          <Typography className={classes.title}>
            {t('distributor.tables.rtt.order_qty', 'Order')}
          </Typography>
          <Typography className={classes.text}>: {orderQty}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default InfoContainer;
