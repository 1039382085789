import { Box, Paper } from '@material-ui/core';
import { FieldWithMessages } from '@rtt-libs/views';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MakeAsyncFunction from 'react-redux-promise-listener';
import PromptDirtyForm from '../../../common/PromptDirtyForm';
import { promiseListener } from '../../../setup';
import { types } from '../../duck';
import { getAgreedAssortmentDetailsRequest } from '../../duck/actions';
import { selectAgreedAssortment } from '../../duck/selectors';
import { AgreedAssortmentWithItems } from '../../types';
import { ProductList } from '../DetailsCommon';
import EditFormTopRow from './EditFormTopRow';

type Props = {
  id: AgreedAssortmentWithItems['id'];
};

const EditAgreedAssortmentContainer: React.FC<Props> = ({ id }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: agreedAssortment, loading, error } = useSelector(
    selectAgreedAssortment(id),
  );

  const initialValues = useMemo(
    () => ({
      productIdList: (agreedAssortment as AgreedAssortmentWithItems)?.items?.map(
        item => item.productId,
      ),
      id: agreedAssortment?.id,
    }),
    [agreedAssortment],
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!agreedAssortment || !agreedAssortment.items) {
      dispatch(getAgreedAssortmentDetailsRequest(id));
    }
  }, [agreedAssortment, dispatch, id]);

  const emptyProductsMessage = t(
    'distributor.agreedAssortments.edit.emptyProductsMessage',
  );

  return (
    <Box p={2} component={Paper}>
      <MakeAsyncFunction
        listener={promiseListener}
        start={types.AGREED_ASSORTMENT_EDIT_REQUEST}
        resolve={types.AGREED_ASSORTMENT_EDIT_SUCCESS}
        reject={types.AGREED_ASSORTMENT_EDIT_FAILURE}
      >
        {onSubmit => (
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              return onSubmit(values)
                .then(() => {
                  enqueueSnackbar(
                    t('distributor.agreedAssortments.edit.successMessage'),
                    { variant: 'success' },
                  );
                })
                .catch(errors => {
                  enqueueSnackbar(errors[FORM_ERROR], { variant: 'error' });
                  return errors;
                });
            }}
          >
            {({ handleSubmit, form: { change } }) => (
              <form onSubmit={handleSubmit}>
                <Field name="id" component="input" type="hidden" />

                <PromptDirtyForm />

                {!agreedAssortment || !agreedAssortment.rtt ? (
                  <EditFormTopRow.Loading />
                ) : (
                  <EditFormTopRow rtt={agreedAssortment.rtt} />
                )}

                <FieldWithMessages
                  name="productIdList"
                  component="input"
                  type="hidden"
                  validate={value => {
                    return value && value.length
                      ? undefined
                      : t('validation.valueMissing');
                  }}
                />

                {loading && !agreedAssortment?.items ? (
                  <ProductList.Loading count={agreedAssortment?.qty} />
                ) : (
                  <>
                    <ProductList emptyMessage={emptyProductsMessage} />
                  </>
                )}
              </form>
            )}
          </Form>
        )}
      </MakeAsyncFunction>
    </Box>
  );
};

export default EditAgreedAssortmentContainer;
