export const BALANCE_GET_LIST_REQUEST = `@rtt[D]/BALANCE_GET_LIST_REQUEST` as const;
export const BALANCE_GET_LIST_SUCCESS = `@rtt[D]/BALANCE_GET_LIST_SUCCESS` as const;
export const BALANCE_GET_LIST_FAILURE = `@rtt[D]/BALANCE_GET_LIST_FAILURE` as const;

export const BALANCE_CREATE_REQUEST = `@rtt[D]/BALANCE_CREATE_REQUEST` as const;
export const BALANCE_CREATE_SUCCESS = `@rtt[D]/BALANCE_CREATE_SUCCESS` as const;
export const BALANCE_CREATE_FAILURE = `@rtt[D]/BALANCE_CREATE_FAILURE` as const;

export const BALANCE_GET_DETAILS_REQUEST = `@rtt[D]/BALANCE_GET_DETAILS_REQUEST` as const;
export const BALANCE_GET_DETAILS_SUCCESS = `@rtt[D]/BALANCE_GET_DETAILS_SUCCESS` as const;
export const BALANCE_GET_DETAILS_FAILURE = `@rtt[D]/BALANCE_GET_DETAILS_FAILURE` as const;

export const BALANCE_FETCH_RTT_REQUEST = `@rtt[D]/BALANCE_FETCH_RTT_REQUEST` as const;
export const BALANCE_FETCH_RTT_SUCCESS = `@rtt[D]/BALANCE_FETCH_RTT_SUCCESS` as const;
export const BALANCE_FETCH_RTT_FAILURE = `@rtt[D]/BALANCE_FETCH_RTT_FAILURE` as const;

export const BALANCE_AGREED_PRODUCTS_REQUEST = `@rtt[D]/BALANCE_AGREED_PRODUCTS_REQUEST` as const;
export const BALANCE_AGREED_PRODUCTS_SUCCESS = `@rtt[D]/BALANCE_AGREED_PRODUCTS_SUCCESS` as const;
export const BALANCE_AGREED_PRODUCTS_FAILURE = `@rtt[D]/BALANCE_AGREED_PRODUCTS_FAILURE` as const;

export const BALANCE_SEARCH_PRODUCTS_REQUEST = `@rtt[D]/BALANCE_SEARCH_PRODUCTS_REQUEST` as const;
export const BALANCE_SEARCH_PRODUCTS_SUCCESS = `@rtt[D]/BALANCE_SEARCH_PRODUCTS_SUCCESS` as const;
export const BALANCE_SEARCH_PRODUCTS_FAILURE = `@rtt[D]/BALANCE_SEARCH_PRODUCTS_FAILURE` as const;

export const BALANCE_GET_PRODUCTS_ORDERED_REQUEST = `@rtt[D]/BALANCE_GET_PRODUCTS_ORDERED_REQUEST` as const;
export const BALANCE_GET_PRODUCTS_ORDERED_SUCCESS = `@rtt[D]/BALANCE_GET_PRODUCTS_ORDERED_SUCCESS` as const;
export const BALANCE_GET_PRODUCTS_ORDERED_FAILURE = `@rtt[D]/BALANCE_GET_PRODUCTS_ORDERED_FAILURE` as const;
