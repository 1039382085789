/* eslint-disable @typescript-eslint/no-empty-interface, @typescript-eslint/camelcase */

import { ENDPOINTS } from '../environment';
import type * as SupervisorManagersTypes from '../supervisorManagers/types';
import api from './apiSetup';
import { transformSimpleErrorMessage } from './mappers';

export interface SupervisorManager
  extends SupervisorManagersTypes.SupervisorManager {}

type FetchedSupervisorManager = {
  id: number;
  phone: string;
  login: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
};

export class SupervisorManager {
  constructor(supervisorManager: FetchedSupervisorManager) {
    this.id = supervisorManager.id;
    this.phone = supervisorManager.phone;
    this.login = supervisorManager.login;
    this.email = supervisorManager.email;
    this.firstName = supervisorManager.first_name;
    this.lastName = supervisorManager.last_name;
    this.isActive = supervisorManager.is_active;
  }
}

/*
 * SupervisorManager API handlers
 */

export const searchSupervisorManagers = () =>
  api
    .get<FetchedSupervisorManager[]>(ENDPOINTS.supervisorManagers)
    .then(({ data }) => data.map(entry => new SupervisorManager(entry)))
    .catch(transformSimpleErrorMessage);
