import { ButtonGroup, FormHelperText, Grid } from '@material-ui/core';
import { OrderStatusValue } from '@rtt-libs/types';
import { LoadingButton } from '@rtt-libs/views';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusValue } from '../../../api/orders';
import ChangeStatusConfirm from './ConfirmStatusChangeDialog';

const exceptConfirm = (status: StatusValue) => status !== 'requires_confirm';

type Props = {
  error: string | undefined;
  onStatusChange: (status: StatusValue) => void;
  availableStatuses: StatusValue[];
  loading?: boolean;
  disabledStatus?: OrderStatusValue;
};

/**
 * Button group with Action dialog to change order status
 */
const ChangeStatusButtons: React.FC<Props> = ({
  error,
  loading,
  onStatusChange,
  availableStatuses,
  disabledStatus,
}) => {
  const [t] = useTranslation();

  const [applyingStatus, setApplyingStatus] = useState<StatusValue | undefined>(
    undefined,
  );

  const onClick = (statusNew: StatusValue | undefined) => () => {
    setApplyingStatus(statusNew);
  };

  const onClose = () => {
    setApplyingStatus(undefined);
  };

  const onChangeStatus = () => {
    if (applyingStatus) {
      onStatusChange(applyingStatus);
    }
    onClose();
  };

  return (
    <Grid item xs={12} container justify="center">
      <Grid item>
        <ButtonGroup color="primary" aria-label="change status">
          {loading && !availableStatuses.length && (
            <LoadingButton
              loading={loading && !availableStatuses.length}
              disabled
            >
              {t('common.loading')}
            </LoadingButton>
          )}
          {availableStatuses.map(statusValue =>
            exceptConfirm(statusValue) ? (
              <LoadingButton
                key={statusValue}
                onClick={onClick(statusValue)}
                loading={loading}
                disabled={disabledStatus === statusValue || loading}
              >
                {t(`status.change.${statusValue}`)}
              </LoadingButton>
            ) : null,
          )}
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <FormHelperText error={!!error}>{error || ' '}</FormHelperText>
      </Grid>

      <ChangeStatusConfirm
        onClose={onClose}
        onConfirm={onChangeStatus}
        isOpen={!!applyingStatus}
      />
    </Grid>
  );
};

export default ChangeStatusButtons;
