import {
  Box,
  FormHelperText,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { ux } from '@rtt-libs/constants';
import { debounce } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

type EntitiesListSearchProps = {
  loading: boolean;
  error: string | null;
  onChange(params: { search?: string }): void;
  isEmpty?: boolean;
};

const EntitiesSearch: React.FC<EntitiesListSearchProps> = ({
  loading,
  error,
  onChange,
  isEmpty,
  children,
}) => {
  const [t] = useTranslation();
  const debounceChange = debounce(onChange, ux.DEBOUNCE_DELAY);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ search: e.target.value });
  };

  return (
    <>
      <TextField
        name="search"
        label={t('distributor.rtt.search', 'Search')}
        onChange={onSearchChange}
        fullWidth
      />

      <Box minHeight={8}>{loading && <LinearProgress />}</Box>

      {error && <FormHelperText error>{error}</FormHelperText>}

      {children}

      {isEmpty && !loading && (
        <Typography variant="h6" color="textSecondary">
          {t('distributor.orders.add.noItems')}
        </Typography>
      )}
    </>
  );
};

export default EntitiesSearch;
