import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core';
import { FieldWithMessages as Field, TextFieldAdapter } from '@rtt-libs/views';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EditPriceTypeValues } from '../../../api/priceTypes';

type Props = FormRenderProps<EditPriceTypeValues> & { onClose(): void };

const EditPriceTypesForm: React.FC<Props> = ({
  handleSubmit,
  submitError,
  onClose,
}) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="edit-priceType-dialog-title">
        {t('distributor.priceTypes.change.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('distributor.priceTypes.change.dialogDescription')}
        </DialogContentText>
        <Field name="id" component="input" hidden />
        <Field
          autoFocus
          margin="dense"
          name="title"
          type="text"
          fullWidth
          required
          component={TextFieldAdapter}
          label={t('distributor.priceTypes.add.name')}
          placeholder={t('distributor.priceTypes.add.name')}
        />
        <Field
          component={TextFieldAdapter}
          name="coefficient"
          margin="dense"
          type="number"
          inputProps={{
            step: 0.5,
            min: 0,
          }}
          fullWidth
          required
          label={t('distributor.priceTypes.add.percent')}
        />
        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('controls.cancel')}
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {t('controls.save')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default EditPriceTypesForm;
