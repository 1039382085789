import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';

const SuspenseIndicator: React.FC = () => (
  <Backdrop open invisible>
    <CircularProgress />
  </Backdrop>
);

export default SuspenseIndicator;
