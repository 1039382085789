import { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import {
  CreateDiscountValues,
  DiscountBrand,
  DiscountProduct,
  DiscountRtt,
  DiscountsSearchParams,
  RttDiscount,
} from '../../api/discounts';
import * as types from './types';

export const getAllDiscountsRequest = () => ({
  type: types.DISCOUNT_GET_ALL_REQUEST,
});

export const getAllDiscountsSuccess = (payload: RttDiscount[]) => ({
  type: types.DISCOUNT_GET_ALL_SUCCESS,
  payload,
});

export const getAllDiscountsFailure = (error: string) => ({
  type: types.DISCOUNT_GET_ALL_FAILURE,
  payload: error,
});

export const searchDiscountsRequest = (params?: DiscountsSearchParams) => ({
  type: types.DISCOUNT_SEARCH_REQUEST,
  payload: params,
});

export const searchDiscountsSuccess = (
  payload: RttDiscount[],
  meta: { pagination: Pagination },
) => ({
  type: types.DISCOUNT_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchDiscountsFailure = (error: string) => ({
  type: types.DISCOUNT_SEARCH_FAILURE,
  payload: error,
});

export const createDiscountRequest = (values: CreateDiscountValues) => ({
  type: types.DISCOUNT_CREATE_REQUEST,
  payload: values,
});

export const createDiscountResponse = (
  submissionErrors?:
    | Partial<CreateDiscountValues & { [FORM_ERROR]: string }>
    | { id: RttDiscount['id'] },
) => ({
  type: types.DISCOUNT_CREATE_RESPONSE,
  payload: submissionErrors,
});

export const createDiscountSuccess = (payload: RttDiscount) => ({
  type: types.DISCOUNT_CREATE_SUCCESS,
  payload,
});

export const createDiscountFailure = (error: string) => ({
  type: types.DISCOUNT_CREATE_FAILURE,
  payload: error,
});

export const getDiscountDetailsRequest = (id: RttDiscount['id']) => ({
  type: types.DISCOUNT_DETAILS_REQUEST,
  payload: id,
});

export const getDiscountDetailsSuccess = (
  payload: RttDiscount,
  meta: {
    products: Record<DiscountProduct['id'], DiscountProduct>;
    brands: Record<DiscountBrand['id'], DiscountBrand>;
  },
) => ({
  type: types.DISCOUNT_DETAILS_SUCCESS,
  payload,
  meta,
});

export const getDiscountDetailsFailure = (error: string) => ({
  type: types.DISCOUNT_DETAILS_FAILURE,
  payload: error,
});

export const editDiscountRequest = (
  id: RttDiscount['id'],
  values: CreateDiscountValues,
) => ({
  type: types.DISCOUNT_EDIT_REQUEST,
  payload: {
    id,
    values,
  },
});

export const editDiscountResponse = (
  submissionErrors?: Partial<CreateDiscountValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.DISCOUNT_EDIT_RESPONSE,
  payload: submissionErrors,
});

export const editDiscountSuccess = (payload: RttDiscount) => ({
  type: types.DISCOUNT_EDIT_SUCCESS,
  payload,
});

export const editDiscountFailure = (error: string) => ({
  type: types.DISCOUNT_EDIT_FAILURE,
  payload: error,
});

export const deleteDiscountRequest = (id: RttDiscount['id']) => ({
  type: types.DISCOUNT_DELETE_REQUEST,
  payload: id,
});

export const deleteDiscountSuccess = (id: RttDiscount['id']) => ({
  type: types.DISCOUNT_DELETE_SUCCESS,
  payload: id,
});

export const deleteDiscountFailure = (error: string) => ({
  type: types.DISCOUNT_DELETE_FAILURE,
  payload: error,
});

export const searchRttDiscountRequest = (query?: string) => ({
  type: types.DISCOUNT_RTT_REQUEST,
  payload: query,
});

export const searchRttDiscountSuccess = (payload: DiscountRtt[]) => ({
  type: types.DISCOUNT_RTT_SUCCESS,
  payload,
});

export const searchRttDiscountFailure = (error: string) => ({
  type: types.DISCOUNT_RTT_FAILURE,
  payload: error,
});

export type Actions = ReturnType<
  | typeof getAllDiscountsRequest
  | typeof getAllDiscountsSuccess
  | typeof getAllDiscountsFailure
  | typeof searchDiscountsRequest
  | typeof searchDiscountsSuccess
  | typeof searchDiscountsFailure
  | typeof createDiscountRequest
  | typeof createDiscountResponse
  | typeof createDiscountSuccess
  | typeof createDiscountFailure
  | typeof getDiscountDetailsRequest
  | typeof getDiscountDetailsSuccess
  | typeof getDiscountDetailsFailure
  | typeof editDiscountRequest
  | typeof editDiscountResponse
  | typeof editDiscountSuccess
  | typeof editDiscountFailure
  | typeof deleteDiscountRequest
  | typeof deleteDiscountSuccess
  | typeof deleteDiscountFailure
  | typeof searchRttDiscountRequest
  | typeof searchRttDiscountSuccess
  | typeof searchRttDiscountFailure
>;
