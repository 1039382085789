import { Pagination } from '@rtt-libs/types';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import * as types from './types';
import {
  PriceType,
  SearchParams,
  CreatePriceTypeValues,
  EditPriceTypeValues,
  DeletePriceTypeValues,
} from '../../api/priceTypes';
import { RttPriceTypeSetValue } from '../types';

export const getPriceTypesRequest = () => ({
  type: types.GET_PRICE_TYPES_REQUEST,
});

export const getPriceTypesSuccess = (payload: PriceType[]) => ({
  type: types.GET_PRICE_TYPES_SUCCESS,
  payload,
});

export const getPriceTypesFailure = (error: string) => ({
  type: types.GET_PRICE_TYPES_FAILURE,
  payload: error,
});

export const searchPriceTypesRequest = (params: SearchParams) => ({
  type: types.SEARCH_PRICE_TYPES_REQUEST,
  payload: params,
});

export const searchPriceTypesSuccess = (
  payload: PriceType[],
  meta: { pagination: Pagination },
) => ({
  type: types.SEARCH_PRICE_TYPES_SUCCESS,
  payload,
  meta,
});

export const searchPriceTypesFailure = (error: string) => ({
  type: types.SEARCH_PRICE_TYPES_FAILURE,
  payload: error,
});

export const createPriceTypeRequest = (values: CreatePriceTypeValues) => ({
  type: types.CREATE_PRICE_TYPES_REQUEST,
  payload: values,
});

export const createPriceTypeResponse = (
  submissionErrors?: Partial<CreatePriceTypeValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.CREATE_PRICE_TYPES_RESPONSE,
  payload: submissionErrors,
});

export const createPriceTypeSuccess = (payload: PriceType) => ({
  type: types.CREATE_PRICE_TYPES_SUCCESS,
  payload,
});

export const createPriceTypeFailure = (error: string) => ({
  type: types.CREATE_PRICE_TYPES_FAILURE,
  payload: error,
});

export const editPriceTypeRequest = (values: EditPriceTypeValues) => ({
  type: types.EDIT_PRICE_TYPES_REQUEST,
  payload: values,
});

export const editPriceTypeResponse = (
  submissionErrors?: Partial<EditPriceTypeValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.EDIT_PRICE_TYPES_RESPONSE,
  payload: submissionErrors,
});

export const editPriceTypeSuccess = (payload: PriceType) => ({
  type: types.EDIT_PRICE_TYPES_SUCCESS,
  payload,
});

export const editPriceTypeFailure = (error: string) => ({
  type: types.EDIT_PRICE_TYPES_FAILURE,
  payload: error,
});

export const deletePriceTypeRequest = (values: DeletePriceTypeValues) => ({
  type: types.DELETE_PRICE_TYPES_REQUEST,
  payload: values,
});

export const deletePriceTypeResponse = (
  submissionErrors?: Partial<DeletePriceTypeValues & { [FORM_ERROR]: string }>,
) => ({
  type: types.DELETE_PRICE_TYPES_RESPONSE,
  payload: submissionErrors,
});

export const deletePriceTypeSuccess = (id: number) => ({
  type: types.DELETE_PRICE_TYPES_SUCCESS,
  payload: id,
});

export const deletePriceTypeFailure = (error: string) => ({
  type: types.DELETE_PRICE_TYPES_FAILURE,
  payload: error,
});

export const setRttPriceTypeRequest = (values: RttPriceTypeSetValue[]) => ({
  type: types.SET_RTT_PRICE_TYPES_REQUEST,
  payload: values,
});

export const setRttPriceTypeSuccess = (
  rttUpdate: Record<number, { priceType: PriceType | null }>,
) => ({
  type: types.SET_RTT_PRICE_TYPES_SUCCESS,
  payload: rttUpdate,
});

export const setRttPriceTypeFailure = (errors: SubmissionErrors) => ({
  type: types.SET_RTT_PRICE_TYPES_FAILURE,
  payload: errors,
});

export type Actions =
  | ReturnType<typeof getPriceTypesRequest>
  | ReturnType<typeof getPriceTypesSuccess>
  | ReturnType<typeof getPriceTypesFailure>
  | ReturnType<typeof searchPriceTypesSuccess>
  | ReturnType<typeof searchPriceTypesRequest>
  | ReturnType<typeof searchPriceTypesFailure>
  | ReturnType<typeof createPriceTypeFailure>
  | ReturnType<typeof createPriceTypeRequest>
  | ReturnType<typeof createPriceTypeSuccess>
  | ReturnType<typeof createPriceTypeResponse>
  | ReturnType<typeof editPriceTypeRequest>
  | ReturnType<typeof editPriceTypeFailure>
  | ReturnType<typeof editPriceTypeResponse>
  | ReturnType<typeof editPriceTypeSuccess>
  | ReturnType<typeof deletePriceTypeRequest>
  | ReturnType<typeof deletePriceTypeFailure>
  | ReturnType<typeof deletePriceTypeResponse>
  | ReturnType<typeof deletePriceTypeSuccess>
  | ReturnType<typeof setRttPriceTypeRequest>
  | ReturnType<typeof setRttPriceTypeSuccess>
  | ReturnType<typeof setRttPriceTypeFailure>;
