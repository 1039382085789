import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAddProductsContext from './AddProductsContext';

type Props = {
  onSubmit(productIds: string[]): void;
  disabled?: boolean;
};

const AddProductButton: React.FC<Props> = ({
  onSubmit,
  disabled,
  children,
}) => {
  const [t] = useTranslation();

  const [opened, changeModalState] = useState(false);

  const { getAddedProductIds } = useAddProductsContext();

  const handleSubmit = () => {
    const products = getAddedProductIds();
    onSubmit(products);
    changeModalState(false);
  };

  return (
    <>
      <Box clone width={160}>
        <Button
          disabled={disabled || opened}
          onClick={() => {
            changeModalState(true);
          }}
          startIcon={<AddIcon />}
        >
          {t('controls.add')}
        </Button>
      </Box>

      <Dialog
        open={opened}
        onClose={() => changeModalState(false)}
        aria-labelledby="modal-title"
        maxWidth="md"
      >
        <DialogTitle id="modal-title">
          {t('distributor.orders.details.addProductTitle')}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={() => changeModalState(false)}>
            {t('controls.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('controls.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProductButton;
