import { Typography, CircularProgress } from '@material-ui/core';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectProductOrderedState } from '../../duck/selectors';
import { ProductWithOrderedQty, ProductOnlyWithOrderedQty } from '../../types';
import { calculateSoldOut } from '../../utils/orderedCalc';

interface Props {
  id: string | number | symbol;
  value: unknown;
}

const SoldCell = ({ id, value }: Props) => {
  const [t] = useTranslation();
  const product = value as ProductWithOrderedQty;

  const { loading, error } = useSelector(selectProductOrderedState(product.id));

  if (loading) return <CircularProgress size={24} />;

  if (error) return <Typography>{error}</Typography>;

  return (
    <FormSpy>
      {({ values }) => {
        let sold: string | null = null;
        if (product.ordered) {
          sold = calculateSoldOut(
            values.balances?.[id],
            product as ProductOnlyWithOrderedQty,
          );
        }

        if (!sold) return sold;

        return `${sold} ${t(`product.values.${product.saleMeasurement}`)}`;
      }}
    </FormSpy>
  );
};

export default SoldCell;
