import get from 'lodash/fp/get';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { SettingsExport } from '../../api/settingsExport';
import { SettingsOrders } from '../../api/settingsOrders';
import { branchName } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const selectSettingsExportError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'error']);

export const getSettingsExportSel: Selector<
  PartialRootState,
  SettingsExport
> = get([branchName, 'settingsExport']);

export const getSettingsOrdersSel: Selector<
  PartialRootState,
  SettingsOrders
> = get([branchName, 'settingsOrders']);

const getSettingsExportLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'loading',
  'settingsExport',
]);

export const selectSettingsExport: Selector<
  PartialRootState,
  {
    data: SettingsExport;
    loading: boolean;
    error: string | null;
  }
> = createSelector(
  [
    getSettingsExportSel,
    getSettingsExportLoadingSel,
    selectSettingsExportError,
  ],
  (settingsExport, loading, error) => {
    return {
      data: settingsExport,
      loading,
      error,
    };
  },
);

export const selectSettingsOrders: Selector<
  PartialRootState,
  {
    dataOrders: SettingsOrders;
  }
> = createSelector([getSettingsOrdersSel], settingsOrders => ({
  dataOrders: settingsOrders,
}));

export const isExportSettingsUnset = createSelector(
  getSettingsExportSel,
  ({ type, email, ftpCreds }) => {
    if (type === 'email') return !email;

    if (type === 'ftp') return !ftpCreds;

    return true;
  },
);
