import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalancesRequest } from '../../duck/actions';
import { selectBalancesData } from '../../duck/selectors';
import type { BalanceTableParams } from '../../types';
import BalanceList from './BalanceList';

const BalanceExportContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const onStateChange = useCallback(
    (options: Partial<BalanceTableParams>) => {
      dispatch(getBalancesRequest({ ...options, include: 'export_status' }));
    },
    [dispatch],
  );

  const { data, loading, error, pagination } = useSelector(selectBalancesData);

  if (error && !loading) {
    enqueueSnackbar(error, {
      variant: 'error',
    });
  }

  return (
    <BalanceList
      balances={data}
      loading={loading}
      pagination={pagination}
      onStateChange={onStateChange}
    />
  );
};

export default BalanceExportContainer;
