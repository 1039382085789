import { Box, Grid, Link as LinkStyled, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Rtt } from '../../../api/rtt';
import routes from '../../../pages/routes';

interface Props {
  rtt: Rtt;
  changeButton?: React.ReactElement;
  totalElement?: React.ReactElement;
  parentOrderId?: string;
}

const OrderCreateSummary: React.FC<Props> = ({
  rtt,
  changeButton,
  totalElement,
  parentOrderId,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <Grid item xs={9}>
        <Typography variant="h6">
          <LinkStyled to={`${routes.rtt}/${rtt.id}`} component={Link}>
            {rtt.name}
          </LinkStyled>
        </Typography>
        <Typography>
          {rtt.contact.firstName} {rtt.contact.lastName}
        </Typography>
        <Typography>{rtt.contact.phone}</Typography>
        <Typography>{rtt.contact.email}</Typography>

        {parentOrderId && (
          <Typography variant="caption">
            {`${t('distributor.returnOrders.details.orderLink')} `}
            <LinkStyled
              to={`${routes.orders}/${parentOrderId}`}
              component={Link}
            >
              {parentOrderId}
            </LinkStyled>
          </Typography>
        )}
      </Grid>

      <Box clone textAlign="right">
        <Grid item xs={3}>
          {changeButton}
          <Typography variant="h5" align="right">
            {totalElement}
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default OrderCreateSummary;
