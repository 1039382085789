import { FormControlLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ExportCheckbox from '../ExportCheckbox';
import { setMultiExportStatusRequest } from '../../duck/actions';
import { selectExportMultiStatusState } from '../../duck/selectors';

const ExportStatusMultiChange = () => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { status, lists, loading, error } = useSelector(
    selectExportMultiStatusState,
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const onChange = (checked: boolean) => {
    const selectedList = lists[checked ? 'notExported' : 'done'];

    if (selectedList.length) {
      dispatch(
        setMultiExportStatusRequest(
          lists[checked ? 'notExported' : 'done'],
          checked,
        ),
      );
    }
  };

  return (
    <FormControlLabel
      control={
        <ExportCheckbox
          key={status}
          onChange={onChange}
          status={status}
          disabled={loading || !status}
          error={error}
          uncontrolled
          loadingTooltip={t('distributor.export.status.change')}
        />
      }
      label={t('distributor.export.changeStatusAllLabel')}
    />
  );
};

export default ExportStatusMultiChange;
