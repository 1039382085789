import {
  call,
  delay,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  getExportDownloadLinkApi,
  getExportStatusApi,
  setExportStatusApi,
  startExportApi,
  startMultiExportApi,
  ExportDocument,
  getMixedExportDocumentsApi,
  setMultiExportStatusApi,
} from '../../api/exports';
import type { ExportResult, ExportStatusValue } from '../types';
import * as actions from './actions';
import * as types from './types';

function* exportEntriesStatusWorker({
  payload,
  meta,
}: ReturnType<typeof actions.setEntryExportStatusRequest>) {
  try {
    const exportStatus: ExportStatusValue = yield call(
      setExportStatusApi,
      meta.type,
      payload,
    );

    yield put(
      actions.setEntryExportStatusSuccess(
        { id: payload.id, exportStatus },
        meta,
      ),
    );
  } catch (e) {
    yield put(
      actions.setEntryExportStatusFailure(
        { id: payload.id, error: e.message },
        meta,
      ),
    );
  }
}

function* exportMultiStatusWorker({
  payload,
}: ReturnType<typeof actions.setMultiExportStatusRequest>) {
  const changedStatusIds = payload.list.map(({ id }) => id);
  try {
    yield call(setMultiExportStatusApi, payload);

    yield put(
      actions.setMultiExportStatusSuccess(changedStatusIds, payload.isExported),
    );
  } catch (e) {
    yield put(
      actions.setMultiExportStatusFailure(
        e,
        changedStatusIds,
        payload.isExported,
      ),
    );
  }
}

function* exportStatusWorker({
  payload,
  meta,
}: ReturnType<typeof actions.getExportStatusRequest>) {
  try {
    const exportStatus: ExportResult = yield call(
      getExportStatusApi,
      payload,
      meta.id,
    );

    yield put(actions.getExportStatusSuccess(exportStatus));

    yield* exportStatusPollingWorker(exportStatus, meta.saveFile);

    if (exportStatus.src) {
      yield put(
        actions.updateExportDownloadLink(exportStatus.src, {
          type: payload,
          saveFile: meta.saveFile,
        }),
      );
    }
  } catch (e) {
    yield put(actions.getExportStatusFailure(e.message));
  }
}

function* exportDocumentGetWorker({
  payload,
}: ReturnType<typeof actions.getExportDocumentsRequest>) {
  try {
    const documents: ExportDocument[] = yield call(
      getMixedExportDocumentsApi,
      payload,
    );

    yield put(actions.getExportDocumentsSuccess(documents));
  } catch (e) {
    yield put(actions.getExportDocumentsFailure(e));
  }
}

function* exportStartWorker({
  payload,
}: ReturnType<typeof actions.startExportRequest>) {
  try {
    const exportStatus: ExportResult = yield call(
      startExportApi,
      payload.type,
      payload.channel,
    );

    yield* exportStatusPollingWorker(
      exportStatus,
      payload.channel === 'download',
    );

    if (exportStatus.src) {
      yield put(
        actions.updateExportDownloadLink(exportStatus.src, {
          type: payload.type,
          saveFile: payload.channel === 'download',
        }),
      );
    }

    yield put(actions.startExportSuccess(exportStatus));
  } catch (e) {
    yield put(actions.startExportFailure(e.message, { type: payload.type }));
  }
}

// TODO: Reuse common logic with `exportStartWorker` after implementing all login on back-end
function* exportMultiStartWorker({
  payload,
}: ReturnType<typeof actions.startMultiExportRequest>) {
  try {
    const exportStatus: ExportResult = yield call(
      startMultiExportApi,
      payload.type,
      payload.channel,
    );

    yield* exportStatusPollingWorker(
      exportStatus,
      payload.channel === 'download',
    );

    if (exportStatus.src) {
      yield put(
        actions.updateExportDownloadLink(exportStatus.src, {
          type: 'mixed',
          saveFile: payload.channel === 'download',
        }),
      );
    }

    yield put(actions.startMultiExportSuccess(exportStatus));
  } catch (e) {
    yield put(
      actions.startMultiExportFailure(e.message, { type: payload.type }),
    );
  }
}

const API_POLLING_DELAY_MS = 5000;

/**
 * Poll the API to get last done status while is not canceled
 */
function* exportStatusPollingWorker(
  exportStatus: ExportResult,
  saveFile?: boolean,
) {
  if (exportStatus.isDone === false) {
    const [cancel]: [
      ReturnType<typeof actions.clearExportStatus> | undefined,
    ] = yield race([
      take(types.EXPORTS_STATUS_CLEAR),
      delay(API_POLLING_DELAY_MS),
    ]);

    if (!cancel) {
      // this approach will cause re-rendering
      yield put(
        actions.getExportStatusRequest(
          exportStatus.type,
          exportStatus.id,
          saveFile,
        ),
      );
    }
  }
}

function* exportDownloadLinkWorker({
  payload,
  meta,
}: ReturnType<typeof actions.updateExportDownloadLink>) {
  try {
    const exportDownloadLink: string = yield call(
      getExportDownloadLinkApi,
      payload,
    );

    yield put(actions.setExportDownloadLink(exportDownloadLink, meta));

    if (meta.saveFile) {
      window.open(exportDownloadLink, '_blank', 'noopener noreferrer');

      // refresh download link, it will brake after first open
      yield put(actions.updateExportDownloadLink(payload, { type: meta.type }));
    }
  } catch (e) {
    yield put(actions.setExportDownloadLinkFailure(e.message));
  }
}

export default function* watcher() {
  yield takeEvery(types.EXPORTS_STATUS_SET_REQUEST, exportEntriesStatusWorker);
  yield takeEvery(
    types.EXPORTS_STATUS_MULTI_SET_REQUEST,
    exportMultiStatusWorker,
  );
  yield takeLatest(types.EXPORTS_STATUS_GET_REQUEST, exportStatusWorker);
  yield takeLatest(types.EXPORTS_START_REQUEST, exportStartWorker);
  yield takeLatest(types.EXPORTS_START_MULTI_REQUEST, exportMultiStartWorker);
  yield takeLatest(
    types.EXPORTS_GET_DOCUMENTS_REQUEST,
    exportDocumentGetWorker,
  );
  yield takeLatest(
    types.EXPORTS_DOWNLOAD_LINK_UPDATE,
    exportDownloadLinkWorker,
  );
}
