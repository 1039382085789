import React from 'react';

type Props = {
  id: string | number | symbol;
  value?: unknown;
};

const StaffContacts: React.FC<Props> = ({ value }) => {
  if (typeof value === 'object' && value !== null) {
    const { phone, email } = value as Record<string, unknown>;

    return (
      <>
        <div>{typeof phone === 'string' && phone}</div>
        <div>{typeof email === 'string' && email}</div>
      </>
    );
  }
  return null;
};

export default StaffContacts;
