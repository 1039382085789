import { EnhancedPaginatedTable } from '@rtt-libs/views';
import React, { useMemo } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { OrderedProduct } from '../../../types';
import CountCell from './Cells/CountCell';
import ProductTotal from './Cells/ProductTotal';
import SwitchAvailableCell from './Cells/SwitchAvailableCell';
import { makeProductsPayloadFieldNamePrefix } from './generateFieldName';
import columnConfig from './productsTableConfig';

type MultiOrderPayloadFormSliceTableProps = {
  products: OrderedProduct[];
  groupId: string | number;
  returnedItems?: string[];
  initialIds?: string[];
  readOnly?: boolean;
};

export const MultiOrderPayloadFormSliceTable = ({
  products,
  returnedItems,
  initialIds,
  groupId,
  readOnly,
}: MultiOrderPayloadFormSliceTableProps) => {
  const [t] = useTranslation();
  const { change } = useForm();

  const columnsConfig = useMemo(() => {
    const productFieldNamePrefix = makeProductsPayloadFieldNamePrefix(groupId);

    const onRemoveClick = (id: string) => {
      change(`${productFieldNamePrefix}.${id}`);
    };

    return columnConfig(t, {
      isAvailable: props => (
        <SwitchAvailableCell
          fieldNamePrefix={productFieldNamePrefix}
          disabled={readOnly}
          {...props}
        />
      ),
      qty: props => {
        const { id } = props;

        return (
          <CountCell
            fieldNamePrefix={productFieldNamePrefix}
            disabled={readOnly}
            onRemoveClick={
              readOnly || initialIds?.includes(id.toString())
                ? undefined
                : onRemoveClick
            }
            {...props}
          />
        );
      },
      total: props => (
        <ProductTotal
          fieldNamePrefix={productFieldNamePrefix}
          returnedItems={returnedItems}
          {...props}
        />
      ),
    });
  }, [readOnly, groupId, returnedItems, t, change, initialIds]);

  return (
    <EnhancedPaginatedTable
      loading={false}
      entries={(products as unknown) as Record<string, unknown>[]}
      columnsConfig={columnsConfig}
      withoutPagination
    />
  );
};
