import { FormHelperText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { restrictions } from '@rtt-libs/constants';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { financialString } from '../../../utils';
import { PAYLOAD_GROUPED_KEY } from '../../constants';
import { OrderCreateValues } from '../../types';
import { collectProductsFromPayloadByManagerIds } from '../../utils/orderPayloadTransformers';

type Props = {
  value?: number;
  warnOnly?: boolean;
  originTotal: number;
  totalOnly?: boolean;
  minTotal?: number;
};

const OrderTotal: React.FC<Props> = ({
  value,
  warnOnly,
  originTotal,
  totalOnly,
  minTotal,
}) => {
  const [t] = useTranslation();

  return (
    <FormSpy>
      {({ values, pristine }) => {
        if (pristine && !warnOnly) {
          if (value === undefined) {
            return <Skeleton />;
          }

          return `${financialString(value)}\u00A0${t('common.mainCurrency')}`;
        }

        const availableProducts = Object.values(
          collectProductsFromPayloadByManagerIds(
            (values as OrderCreateValues)[PAYLOAD_GROUPED_KEY] || {},
          ),
        ).filter(product => product.isAvailable);

        const orderTotal = availableProducts.reduce(
          (total, { price = 0, saleMeasurement, orderWeight, qty }) => {
            if (orderWeight < 0 || qty < 0) return total;

            return (
              total +
              (price * (saleMeasurement === 'weight' ? orderWeight : qty) || 0)
            );
          },
          0,
        );

        const minTotalDiff =
          minTotal && minTotal > orderTotal && minTotal - orderTotal;

        return (
          <>
            {!warnOnly &&
              `${financialString(orderTotal)}\u00A0${t('common.mainCurrency')}`}
            {!totalOnly &&
              Math.abs(orderTotal - originTotal) >=
                originTotal * restrictions.ORDER_TOTAL_THRESHOLD && (
                <FormHelperText error>
                  {t('distributor.orders.warnPriceThreshold')}
                </FormHelperText>
              )}
            {!!minTotalDiff && (
              <FormHelperText>
                {t('distributor.orders.details.minTotalDiff', {
                  diff: financialString(minTotalDiff),
                })}
              </FormHelperText>
            )}
          </>
        );
      }}
    </FormSpy>
  );
};

export default OrderTotal;
