import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import React, { useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ExportResultType } from '../../../types';
import ExportLayout from '../ExportLayout';
import ExportStatusMultiChange from '../ExportStatusMultiChange';
import DateTimeFields, { aDayCalculator } from './DateTimeFields';
import ExportCheckboxList from './ExportCheckboxList';
import ExportMultiButton from './ExportMultiButton';

const ExportDescription = () => {
  const [t] = useTranslation();

  return (
    <Typography paragraph>
      {t('distributor.export.mixedTableDescription')}
    </Typography>
  );
};

const typeFieldName = 'type';

type InitialParentValues = Partial<{
  type: ExportResultType[];
  datetimeStart: string;
  datetimeEnd: string;
}>;

interface Props {
  onChange(values: InitialParentValues): void;
  initialType?: ExportResultType[];
  disabled?: boolean;
  initial: InitialParentValues;
}

/**
 * Export orders, returns and balances from one page
 *
 * Uses form with checkboxes and wraps with its context child export button & documents table
 */
const ExportTypeContainer = ({
  onChange,
  initialType,
  initial,
  disabled = false,
}: Props) => {
  const initialValues = useMemo(
    () => ({
      datetimeEnd: initial.datetimeEnd
        ? dayjs.utc(initial.datetimeEnd).local()
        : dayjs(),
      datetimeStart: initial.datetimeStart
        ? dayjs.utc(initial.datetimeStart).local()
        : dayjs().subtract(1, 'day'),
      type: initialType,
    }),
    [initialType, initial.datetimeEnd, initial.datetimeStart],
  );

  return (
    <Form
      onSubmit={noop}
      subscription={{}}
      initialValues={initialValues}
      decorators={[aDayCalculator]}
    >
      {() => (
        <ExportLayout
          checkboxes={
            <ExportCheckboxList fieldName={typeFieldName} disabled={disabled} />
          }
          button={<ExportMultiButton />}
          description={<ExportDescription />}
          checkAll={<ExportStatusMultiChange />}
        >
          <DateTimeFields />

          <FormSpy
            subscription={{
              valid: true,
              values: true,
            }}
            onChange={({
              values: { type, datetimeEnd, datetimeStart },
              valid,
            }) => {
              if (valid) {
                onChange({
                  type,
                  datetimeEnd: dayjs(datetimeEnd)
                    .utc()
                    .format('YYYY-MM-DD HH:mm'),
                  datetimeStart: dayjs(datetimeStart)
                    .utc()
                    .format('YYYY-MM-DD HH:mm'),
                });
              }
            }}
          />
        </ExportLayout>
      )}
    </Form>
  );
};

export default ExportTypeContainer;
