import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { EditEmployeeValues } from '../../api/employees';
import { EditStaffForm, useStaffEditData } from '../../common/staff';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import EmployeeRoleField from './EmployeeRoleField';

type Props = {
  onClose(): void;
};

const EditEmployeeContainer: React.FC<Props> = ({ onClose }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const employee = useStaffEditData();

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={types.EMPLOYEE_EDIT_REQUEST}
      resolve={types.EMPLOYEE_EDIT_SUCCESS}
      reject={types.EMPLOYEE_EDIT_FAILURE}
    >
      {onSubmit => (
        <Form
          initialValues={employee as EditEmployeeValues}
          onSubmit={async (values: EditEmployeeValues) => {
            try {
              await onSubmit(values);
              onClose();

              enqueueSnackbar(t('common.snackbars.saveSuccess'), {
                variant: 'success',
              });

              return undefined;
            } catch (errors) {
              enqueueSnackbar(t('common.snackbars.saveFailed'), {
                variant: 'error',
              });

              return errors;
            }
          }}
          render={props => (
            <EditStaffForm onClose={onClose} {...props}>
              <EmployeeRoleField />
            </EditStaffForm>
          )}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default EditEmployeeContainer;
