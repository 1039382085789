import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import React from 'react';
import EditorList, { EditorListProps } from './EditorList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      maxWidth: 'none',
      background: theme.palette.background.default,
    },
  }),
);

const EditorsBadge = (props: EditorListProps) => {
  const classes = useStyles();

  const { creator, lastEditor, manager } = props;

  if (!creator && !lastEditor && !manager) return null;

  return (
    <Box clone mx={2}>
      <Tooltip classes={classes} title={<EditorList {...props} />} interactive>
        <RecentActorsIcon fontSize="inherit" color="primary" />
      </Tooltip>
    </Box>
  );
};

export default EditorsBadge;
