import { call, put, takeLatest } from 'redux-saga/effects';
import {
  editBalanceSettings,
  getBalanceSettings,
} from '../../api/balanceSettings';
import type { BalanceSettings } from '../types';
import * as actions from './actions';
import * as types from './types';

function* getBalanceSettingsWorker() {
  try {
    const balanceSettings: BalanceSettings = yield call(getBalanceSettings);

    yield put(actions.getBalanceSettingsSuccess(balanceSettings));
  } catch (e) {
    yield put(actions.getBalanceSettingsFailure(e.message));
  }
}

function* editBalanceSettingsWorker({
  payload,
}: ReturnType<typeof actions.editBalanceSettingsRequest>) {
  try {
    const data: BalanceSettings = yield call(editBalanceSettings, payload);

    yield put(actions.editBalanceSettingsSuccess(data));
  } catch (e) {
    yield put(actions.editBalanceSettingsFailure(e));
  }
}

export default function* watcher() {
  yield takeLatest(
    types.BALANCE_SETTINGS_GET_REQUEST,
    getBalanceSettingsWorker,
  );
  yield takeLatest(
    types.BALANCE_SETTINGS_EDIT_REQUEST,
    editBalanceSettingsWorker,
  );
}
