import type { Pagination } from '@rtt-libs/types';
import { FORM_ERROR } from 'final-form';
import type {
  Supervisor,
  SupervisorValues,
  SearchParams,
  EditSupervisorValues,
  AssignedManager,
} from '../types';
import * as types from './types';

type SupervisorErrors = Partial<Record<keyof SupervisorValues, string>> & {
  [FORM_ERROR]: string;
};

export const searchSupervisorsRequest = (params: SearchParams) => ({
  type: types.SUPERVISORS_SEARCH_REQUEST,
  payload: params,
});

export const searchSupervisorsSuccess = (
  payload: Supervisor[],
  meta: { pagination: Pagination },
) => ({
  type: types.SUPERVISORS_SEARCH_SUCCESS,
  payload,
  meta,
});

export const searchSupervisorsFailure = (error: string) => ({
  type: types.SUPERVISORS_SEARCH_FAILURE,
  payload: error,
});

export const createSupervisorRequest = (values: SupervisorValues) => ({
  type: types.SUPERVISORS_CREATE_REQUEST,
  payload: values,
});

export const createSupervisorSuccess = (payload: Supervisor) => ({
  type: types.SUPERVISORS_CREATE_SUCCESS,
  payload,
});

export const createSupervisorFailure = (
  submissionErrors?: SupervisorErrors,
) => ({
  type: types.SUPERVISORS_CREATE_FAILURE,
  payload: submissionErrors,
});

export const editSupervisorRequest = (values: EditSupervisorValues) => ({
  type: types.SUPERVISORS_EDIT_REQUEST,
  payload: values,
});

export const editSupervisorFailure = (submissionErrors?: SupervisorErrors) => ({
  type: types.SUPERVISORS_EDIT_FAILURE,
  payload: submissionErrors,
});

export const editSupervisorSuccess = (payload: Supervisor) => ({
  type: types.SUPERVISORS_EDIT_SUCCESS,
  payload,
});

export const changeStatusSupervisorRequest = (
  id: Supervisor['id'],
  status: boolean,
) => ({
  type: types.SUPERVISORS_CHANGE_STATUS_REQUEST,
  payload: {
    id,
    status,
  },
});

export const changeStatusSupervisorSuccess = (payload: Supervisor) => ({
  type: types.SUPERVISORS_CHANGE_STATUS_SUCCESS,
  payload,
});

export const changeStatusSupervisorFailure = (error: string) => ({
  type: types.SUPERVISORS_CHANGE_STATUS_FAILURE,
  payload: error,
});

export const getAvailableManagersForAssignRequest = () => ({
  type: types.SUPERVISORS_GET_AVAILABLE_MANAGERS_REQUEST,
});

export const getAvailableManagersForAssignSuccess = (
  payload: AssignedManager[],
) => ({
  type: types.SUPERVISORS_GET_AVAILABLE_MANAGERS_SUCCESS,
  payload,
});

export const getAvailableManagersForAssignFailure = (error: string) => ({
  type: types.SUPERVISORS_GET_AVAILABLE_MANAGERS_FAILURE,
  payload: error,
});

export const getAvailableManagersForAssignCleanup = () => ({
  type: types.SUPERVISORS_GET_AVAILABLE_MANAGERS_CLEANUP,
});

export type Actions = ReturnType<
  | typeof searchSupervisorsRequest
  | typeof searchSupervisorsSuccess
  | typeof searchSupervisorsFailure
  | typeof createSupervisorRequest
  | typeof createSupervisorSuccess
  | typeof createSupervisorFailure
  | typeof editSupervisorRequest
  | typeof editSupervisorSuccess
  | typeof editSupervisorFailure
  | typeof changeStatusSupervisorRequest
  | typeof changeStatusSupervisorSuccess
  | typeof changeStatusSupervisorFailure
  | typeof getAvailableManagersForAssignRequest
  | typeof getAvailableManagersForAssignSuccess
  | typeof getAvailableManagersForAssignFailure
  | typeof getAvailableManagersForAssignCleanup
>;
