/* eslint-disable @typescript-eslint/camelcase */
import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-final-form';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { SettingsExport } from '../../api/settingsExport';
import { useSaveSubmitSnackbar } from '../../common';
import { promiseListener } from '../../setup';
import { types } from '../duck';
import { EditSettingsExport } from './EditSettings';

type Props = {
  settingsExport: SettingsExport;
};

const SettingsExportContainer: React.FC<Props> = ({ settingsExport }) => {
  const notifySubmitResult = useSaveSubmitSnackbar();

  return (
    <Box clone p={1}>
      <Paper>
        <MakeAsyncFunction
          listener={promiseListener}
          start={types.EDIT_SETTINGS_EXPORT_REQUEST}
          resolve={types.EDIT_SETTINGS_EXPORT_RESPONSE}
          reject={types.EDIT_SETTINGS_EXPORT_FAILURE}
        >
          {onSubmit => (
            <Form
              initialValues={settingsExport as SettingsExport}
              onSubmit={async values => {
                const errors = await onSubmit(values);

                notifySubmitResult(errors);

                return errors;
              }}
              component={EditSettingsExport}
            />
          )}
        </MakeAsyncFunction>
      </Paper>
    </Box>
  );
};

export default SettingsExportContainer;
