import { Box, Grid, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RttInfo } from '../../../api/rtt';
import { SkeletonButton } from '../../../common';
import routes from '../../../pages/routes';

type Props = {
  rtt: RttInfo;
};

const RttDetailsTopRow: React.FC<Props> & { Loading: React.FC } = ({ rtt }) => {
  const renderRttInfo = (
    <RttInfoSection
      name={rtt.name}
      phone={rtt.contact.phone}
      contactName={`${rtt.contact.firstName} ${rtt.contact.lastName}`}
      rttLink={`${routes.rtt}/${rtt.id}`}
    />
  );

  return <RttDetailsTopRowLayout rttInfo={renderRttInfo} />;
};

type RttDetailsTopRowLayoutProps = {
  rttInfo: React.ReactNode;
  actionSection?: React.ReactNode;
};

const RttDetailsTopRowLayout: React.FC<RttDetailsTopRowLayoutProps> = ({
  rttInfo,
  actionSection,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      {rttInfo}
    </Grid>
    <Box display="flex" justifyContent="flex-end" alignItems="center" clone>
      <Grid item xs={12} md={6}>
        {actionSection}
      </Grid>
    </Box>
  </Grid>
);

type RttInfoSectionProps = {
  name: React.ReactNode;
  phone: React.ReactNode;
  contactName: React.ReactNode;
  rttLink?: string;
};

const RttInfoSection: React.FC<RttInfoSectionProps> = ({
  name,
  phone,
  contactName,
  rttLink,
}) => (
  <>
    <Typography variant="h6">
      {rttLink ? (
        <Link to={rttLink} component={RouterLink}>
          {name}
        </Link>
      ) : (
        name
      )}
    </Typography>
    <Typography>
      <Link href={`tel:${phone}`}>{phone}</Link>
    </Typography>
    <Typography>{contactName}</Typography>
  </>
);

const Loading = () => {
  const renderRttInfo = (
    <RttInfoSection
      name={<Skeleton width="75%" />}
      phone={<Skeleton width="20%" />}
      contactName={<Skeleton width="30%" />}
    />
  );

  return (
    <RttDetailsTopRowLayout
      rttInfo={renderRttInfo}
      actionSection={<SkeletonButton />}
    />
  );
};

RttDetailsTopRow.Loading = Loading;

export default RttDetailsTopRow;
