import { OrderStatusValue, Pagination } from '@rtt-libs/types';
import { isEqual, pick } from 'lodash';
import { at, get, mapValues } from 'lodash/fp';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
  Selector,
} from 'reselect';
import type * as OrderTypes from '../types';
import { branchName, returnOrdersCollectionSliceKey } from './const';
import { BranchState } from './reducer';

export interface PartialRootState {
  [branchName]: BranchState;
}

export const getOrdersCollectionSel: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], OrderTypes.Order>
> = get([branchName, 'orders', 'collection']);

const getOrderIdsSel: Selector<
  PartialRootState,
  OrderTypes.Order['id'][]
> = get([branchName, 'orders', 'orderIds']);

export const selectOrdersError: Selector<
  PartialRootState,
  string | null
> = get([branchName, 'orders', 'error']);

const getOrdersLoadingSel: Selector<PartialRootState, boolean> = get([
  branchName,
  'orders',
  'loading',
]);

const selectOrderPagination: Selector<PartialRootState, Pagination> = get([
  branchName,
  'orders',
  'pagination',
]);

export const selectOrders = createSelector(
  [
    getOrdersCollectionSel,
    getOrderIdsSel,
    selectOrderPagination,
    getOrdersLoadingSel,
    selectOrdersError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: ids.map(id => collection[id]),
    pagination,
    loading,
    error,
  }),
);

const selectOrdersByIdsSelector = createSelector(
  [getOrdersCollectionSel, (_state: PartialRootState, ids: string[]) => ids],
  (collection, ids) => pick(collection, ids),
);

export const selectOrdersByIds = (ids: string[]) => (state: PartialRootState) =>
  selectOrdersByIdsSelector(state, ids);

const selectSingleOrderSelector = createSelector(
  [
    getOrdersCollectionSel,
    getOrdersLoadingSel,
    selectOrdersError,
    (_state: PartialRootState, id: string) => id,
  ],
  (collection, loading, error, id) => ({
    data: get(id, collection),
    loading,
    error,
  }),
);

export const selectSingleOrder = (id: string) => (state: PartialRootState) =>
  selectSingleOrderSelector(state, id);

const selectOrderTotalCollection: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], number | undefined>
> = get([branchName, 'decrypted', 'collections', 'totals']);

const selectOrderTotalProcessing: Selector<
  PartialRootState,
  OrderTypes.Order['id'][]
> = get([branchName, 'decrypted', 'processing', 'totals']);

const selectOrderTotalErrors: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], string>
> = get([branchName, 'decrypted', 'errors', 'totals']);

const selectOrderTotalSelector = createSelector(
  [
    selectOrderTotalCollection,
    selectOrderTotalProcessing,
    selectOrderTotalErrors,
    (_state: PartialRootState, id: string) => id,
  ],
  (collection, processing, errors, id) => ({
    data: collection[id],
    loading: processing.includes(id),
    error: errors[id],
  }),
);

export const selectOrderTotal = (id: string) => (state: PartialRootState) =>
  selectOrderTotalSelector(state, id);

export const selectOrderPayloadCollection: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], OrderTypes.DecryptedPayload | undefined>
> = get([branchName, 'decrypted', 'collections', 'payloads']);

const selectOrderProductCollection = (
  state: PartialRootState,
  id: string,
): OrderTypes.DecryptedPayload['products'] | undefined =>
  get([branchName, 'decrypted', 'collections', 'payloads', id, 'products'])(
    state,
  );

export const selectOrderShopInfoCollection = (
  state: PartialRootState,
  id: string,
): OrderTypes.DecryptedPayload['shopInfo'] =>
  get([branchName, 'decrypted', 'collections', 'payloads', id, 'shopInfo'])(
    state,
  );

const selectOrderPayloadProcessing: Selector<
  PartialRootState,
  OrderTypes.Order['id'][]
> = get([branchName, 'decrypted', 'processing', 'payloads']);

const selectOrderPayloadErrors: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], string>
> = get([branchName, 'decrypted', 'errors', 'payloads']);

const selectOrderPayloadSelector = createSelector(
  [
    selectOrderPayloadCollection,
    selectOrderPayloadProcessing,
    selectOrderPayloadErrors,
    (_state: PartialRootState, id: string) => id,
  ],
  (collection, processing, errors, id) => ({
    data: collection[id],
    loading: processing.includes(id),
    error: errors[id],
  }),
);

export const selectOrderPayloadCollectionSelector = createSelector(
  [
    selectOrderPayloadCollection,
    selectOrderPayloadProcessing,
    selectOrderPayloadErrors,
  ],
  (collection, processing, errors) => ({
    data: collection,
    processing,
    errors,
  }),
);

export const selectOrderPayload = (id: string) => (state: PartialRootState) =>
  selectOrderPayloadSelector(state, id);

const selectOrderPayloadProductsSelector = createSelector(
  [
    selectOrderProductCollection,
    selectOrderPayloadProcessing,
    selectOrderPayloadErrors,
    selectOrderShopInfoCollection,
    (_state: PartialRootState, id: string) => id,
  ],
  (collection, processing, errors, shopInfo, id) => ({
    data: collection,
    loading: processing.includes(id),
    error: errors[id],
    shopInfo,
  }),
);
export const selectOrderPayloadProducts = (id: string) => (
  state: PartialRootState,
) => selectOrderPayloadProductsSelector(state, id);

const selectOrderOriginTotalCollection: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], number | undefined>
> = get([branchName, 'decrypted', 'collections', 'originTotals']);

const selectOrderOriginTotalProcessing: Selector<
  PartialRootState,
  OrderTypes.Order['id'][]
> = get([branchName, 'decrypted', 'processing', 'originTotals']);

const selectOrderOriginTotalErrors: Selector<
  PartialRootState,
  Record<OrderTypes.Order['id'], string>
> = get([branchName, 'decrypted', 'errors', 'originTotals']);

const selectOrderOriginTotalSelector = createSelector(
  [
    selectOrderOriginTotalCollection,
    selectOrderOriginTotalProcessing,
    selectOrderOriginTotalErrors,
    (_state: PartialRootState, id: string) => id,
  ],
  (collection, processing, errors, id) => ({
    data: collection[id],
    loading: processing.includes(id),
    error: errors[id],
  }),
);

export const selectOrderOriginTotal = (id: string) => (
  state: PartialRootState,
) => selectOrderOriginTotalSelector(state, id);

export const selectOrderDetails = createSelector(
  [
    getOrdersCollectionSel,
    getOrdersLoadingSel,
    selectOrdersError,
    (_: PartialRootState, { id }: { id?: string }) => id,
  ],
  (collection, loading, error, id) => ({
    data: id ? collection[id] : undefined,
    loading,
    error,
  }),
);

export const selectOrderDetailsById = (id?: string) => (
  state: PartialRootState,
) => selectOrderDetails(state, { id });

export const selectAvailableStatuses: Selector<
  PartialRootState,
  { loading: boolean; data: OrderStatusValue[]; error: string | null }
> = get([branchName, 'currentOrderStatuses']);

export const selectClosestDeliveryDate: Selector<
  PartialRootState,
  string | undefined
> = get([branchName, 'deliveryDays', 'date']);

export const selectClosestDeliveryDateData: Selector<
  PartialRootState,
  { date: string | undefined; loading: boolean; error: string | null }
> = get([branchName, 'deliveryDays']);

export const selectMinTotal: Selector<
  PartialRootState,
  { value: number; loading: boolean; error: string | null }
> = get([branchName, 'minTotal']);

/*
 * Return orders selectors
 */

export const selectReturnOrdersCollection: Selector<
  PartialRootState,
  Record<OrderTypes.ReturnOrder['id'], OrderTypes.ReturnOrder>
> = get([branchName, returnOrdersCollectionSliceKey, 'collection']);

const selectReturnOrdersIdList: Selector<
  PartialRootState,
  OrderTypes.ReturnOrder['id'][]
> = get([branchName, returnOrdersCollectionSliceKey, 'idList']);

export const selectReturnOrdersError: Selector<
  PartialRootState,
  string | null
> = get([branchName, returnOrdersCollectionSliceKey, 'error']);

const selectReturnOrdersLoading: Selector<PartialRootState, boolean> = get([
  branchName,
  returnOrdersCollectionSliceKey,
  'loading',
]);

const selectReturnOrdersPagination: Selector<
  PartialRootState,
  Pagination
> = get([branchName, returnOrdersCollectionSliceKey, 'pagination']);

export const selectReturns = createSelector(
  [
    selectReturnOrdersCollection,
    selectReturnOrdersIdList,
    selectReturnOrdersPagination,
    selectReturnOrdersLoading,
    selectReturnOrdersError,
  ],
  (collection, ids, pagination, loading, error) => ({
    data: at(ids, collection),
    pagination,
    loading,
    error,
  }),
);

export const selectReturnDetails = createSelector(
  [
    selectReturnOrdersCollection,
    selectReturnOrdersLoading,
    selectReturnOrdersError,
    (_: PartialRootState, { id }: { id: string }) => id,
  ],
  (collection, loading, error, id) => ({
    data: collection[id],
    loading,
    error,
  }),
);

const returnOrdersByIdsSelector = createSelector(
  [
    selectReturnOrdersCollection,
    (_state: PartialRootState, ids: string[]) => ids,
  ],
  (collection, ids) => pick(collection, ids),
);

export const selectReturnOrdersByIds = (ids: string[]) => (
  state: PartialRootState,
) => returnOrdersByIdsSelector(state, ids);

export const selectReturnOrderDetails = createSelector(
  [
    selectReturnOrdersCollection,
    selectReturnOrdersLoading,
    selectReturnOrdersError,
    (_: PartialRootState, { id }: { id?: string }) => id,
  ],
  (collection, loading, error, id) => ({
    data: id ? collection[id] : undefined,
    loading,
    error,
  }),
);

export const selectReturnOrderDetailsById = (id?: string) => (
  state: PartialRootState,
) => selectReturnOrderDetails(state, { id });

const getOrderedProductMaxValue = (product: OrderTypes.OrderedProduct) => {
  const fieldPart =
    product.saleMeasurement === 'weight' ? 'orderWeight' : 'qty';

  return product[fieldPart];
};

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// use the new "selector creator" to create a selector
export const selectMemoizedDecryptedOrders = createDeepEqualSelector(
  selectOrderPayloadCollection,
  mapValues(value => mapValues(getOrderedProductMaxValue, value?.products)),
);
