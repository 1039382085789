import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@rtt-libs/views';

type Props = {
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: () => void;
  isOpen: boolean;
  message?: string;
  loading?: boolean;
};

/**
 * Dialog for confirm chosen status of the order
 */
const ConfirmStatusChangeDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  isOpen,
  message,
  loading,
}) => {
  const [t] = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        {message || t('admin.tables.common.dialogChangeStatus')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t('controls.cancel')}
        </Button>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          onClick={onConfirm}
          loading={loading}
        >
          {t('controls.agree')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmStatusChangeDialog;
