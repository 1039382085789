import { useAuth } from '@rtt-libs/auth';
import { table } from '@rtt-libs/constants';
import { Pagination } from '@rtt-libs/types';
import { TableWithHistory } from '@rtt-libs/views';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from '../../../pages/routes';
import { PERMISSIONS } from '../../../rules';
import { AgreedAssortment, AgreedAssortmentTableParams } from '../../types';
import FilterRow from './FilterRow';
import agreedAssortmentsTableConfig from './tableConfig';

type Props = {
  assortments: AgreedAssortment[];
  loading: boolean;
  onStateChange: (state: Partial<AgreedAssortmentTableParams>) => void;
  pagination: Pagination;
};

const AgreedAssortmentList: React.FC<Props> = ({
  assortments,
  loading,
  onStateChange,
  pagination,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { isPermittedTo } = useAuth();

  const { perPage = 10, currentPage = 1 } = pagination;

  const initialParams = useMemo(
    () => ({
      page: currentPage,
      perPage,
      isActive: '1' as const,
    }),
    [perPage, currentPage],
  );

  const columnsConfig = useMemo(() => {
    const tableConfig = agreedAssortmentsTableConfig(t);
    if (!isPermittedTo(PERMISSIONS.NAV_AGREED_ASSORTMENT_EDIT)) {
      // remove delete button for items
      return tableConfig.slice(0, -1);
    }

    return tableConfig;
  }, [t, isPermittedTo]);

  const onRowClick = useCallback(
    (id: string) => {
      history.push(`${routes.agreedAssortments}/${id}`);
    },
    [history],
  );

  return (
    <TableWithHistory
      filterRow={({ onChange, displayOptions }) => (
        <FilterRow onChange={onChange} initialFilterValue={displayOptions} />
      )}
      loading={loading}
      entries={assortments}
      columnsConfig={columnsConfig}
      onStateChange={onStateChange}
      onRowClick={onRowClick}
      perPageOptions={table.PAGINATION_OPTIONS}
      initial={initialParams}
      totalEntriesCount={pagination.total}
    />
  );
};

export default AgreedAssortmentList;
