/* eslint-disable @typescript-eslint/camelcase */
import type { ClientPagination } from '@rtt-libs/types';
import dayjs from 'dayjs';
import { all, call, put } from 'redux-saga/effects';
import {
  getDeliveryDaysByRtt,
  getMinTotalOrderSettings,
  getOrderDetails,
  getOrders,
  getReturnOrderDetails,
  getReturnOrders,
} from '../../../api/ordersRefactored';
import type * as OrderTypes from '../../types';
import * as actions from '../actions';
import { deriveClosestDay } from './utils/deliveryDate';

export function* ordersGetWorker({
  payload,
}: ReturnType<typeof actions.getOrdersRequest>) {
  try {
    const { data, meta }: ClientPagination<OrderTypes.Order[]> = yield call(
      getOrders,
      payload,
    );

    yield put(actions.getOrdersSuccess(data, meta));

    yield put(actions.decryptOrderTotalRequest(data.map(order => order.id)));
  } catch (e) {
    yield put(actions.getOrdersFailure(e.message));
  }
}

export function* orderDetailsWorker({
  payload,
}: ReturnType<typeof actions.getOrderDetailsRequest>) {
  try {
    const order: OrderTypes.Order = yield call(getOrderDetails, payload);

    yield put(actions.getOrderDetailsSuccess(order));

    yield all([
      put(actions.decryptOrderTotalRequest([order.id])),
      put(actions.decryptOrderPayloadRequest([order.id])),
      put(actions.decryptOrderOriginTotalRequest([order.id])),
    ]);
  } catch (e) {
    yield put(actions.getOrderDetailsFailure(e.message));
  }
}

export function* closestDeliveryDayWorker({
  payload,
}: ReturnType<typeof actions.getClosestDeliveryDayRequest>) {
  try {
    const deliveryDays = yield call(getDeliveryDaysByRtt, payload);

    const closestDeliveryDay = deriveClosestDay(deliveryDays);

    yield put(actions.getClosestDeliveryDaySuccess(closestDeliveryDay));
  } catch (e) {
    yield put(
      actions.getClosestDeliveryDayFailure(
        dayjs().format('YYYY-MM-DD'),
        e.message,
      ),
    );
  }
}

export function* minTotalWorker() {
  try {
    const minTotalOrder: number = yield call(getMinTotalOrderSettings);

    yield put(actions.getMinTotalOrderSuccess(minTotalOrder));
  } catch (e) {
    yield put(actions.getMinTotalOrderFailure(e.message));
  }
}

export function* returnOrdersGetWorker({
  payload,
}: ReturnType<typeof actions.getReturnOrdersRequest>) {
  try {
    const {
      data,
      meta,
    }: ClientPagination<OrderTypes.ReturnOrder[]> = yield call(
      getReturnOrders,
      payload,
    );

    yield put(actions.getReturnOrdersSuccess(data, meta));

    yield put(
      actions.decryptOrderTotalRequest(data.map(returnOrder => returnOrder.id)),
    );
  } catch (e) {
    yield put(actions.getReturnOrdersFailure(e.message));
  }
}

export function* returnOrderDetailsWorker({
  payload,
}: ReturnType<typeof actions.getReturnOrderDetailsRequest>) {
  try {
    const returnOrder: OrderTypes.ReturnOrder = yield call(
      getReturnOrderDetails,
      payload,
    );

    yield put(actions.getReturnOrderDetailsSuccess(returnOrder));

    yield all([
      put(actions.decryptOrderTotalRequest([returnOrder.id])),
      put(actions.decryptOrderPayloadRequest([returnOrder.id])),
      put(actions.decryptOrderOriginTotalRequest([returnOrder.id])),
    ]);
  } catch (e) {
    yield put(actions.getReturnOrderDetailsFailure(e.message));
  }
}
