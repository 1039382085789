import { Box, BoxProps, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const SkeletonButton = ({ height = 42, width = 160, ...props }: BoxProps) => {
  const theme = useTheme();

  return (
    <Box borderRadius={theme.shape.borderRadius} clone {...props}>
      <Skeleton variant="rect" height={height} width={width} />
    </Box>
  );
};

export default SkeletonButton;
