import { Measurement } from '@rtt-libs/types';
import { Product } from '../api/assortment';

const reduceOrderTotal = <
  T extends {
    price?: number;
    saleMeasurement?: Measurement;
    id: string;
  } = Product
>(
  productsQty?: Record<string, Partial<{ orderWeight: number; qty: number }>>,
) => (total: number, { price = 0, saleMeasurement, id }: T) => {
  const { orderWeight = 0, qty = 0 } = productsQty?.[id] || {};
  if (orderWeight < 0 && qty < 0) return total;

  return (
    total + price * ((saleMeasurement === 'weight' ? orderWeight : qty) || 0)
  );
};

export default reduceOrderTotal;
