import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { without } from 'lodash/fp';

interface AddProductsContextObject {
  existingIds: string[];
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
  setExistingIds: (ids: string[]) => void;
  removeChecked: (id: string) => void;
  getAddedProductIds(): string[];
  returnedItems?: string[];
}

const AddProductsContext = createContext<AddProductsContextObject | null>(null);

const useAddProductsContext = () => {
  const context = useContext(AddProductsContext);
  if (!context) throw new Error("AddProductsContext doesn't initialized");

  return context;
};

const emptyArray = [] as string[];

type Props = {
  existing?: string[];
  initialChecked?: string[];
  removeId?: (id: string) => void;
  returnedItems?: string[];
};

export const AddProductsProvider: React.FC<Props> = ({
  children,
  existing = emptyArray,
  initialChecked = emptyArray,
  removeId,
  returnedItems,
}) => {
  const [checked, setChecked] = useState(initialChecked);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const [existingIds, setExistingIds] = useState(existing);

  useEffect(() => {
    setExistingIds(existing);
  }, [existing]);

  const getAddedProductIds = useCallback(() => {
    return checked;
  }, [checked]);

  const removeChecked = useCallback(
    (id: string) => {
      setChecked(prevChecked => without([id], prevChecked));
      // eslint-disable-next-line no-unused-expressions
      removeId?.(id);
    },
    [removeId, setChecked],
  );

  const contextValue = {
    checked,
    setChecked,
    existingIds,
    setExistingIds,
    getAddedProductIds,
    removeChecked,
    returnedItems,
  };

  return (
    <AddProductsContext.Provider value={contextValue}>
      {children}
    </AddProductsContext.Provider>
  );
};

export default useAddProductsContext;
