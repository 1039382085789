import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableCategoriesRequest,
  getCategoriesRequest,
} from '../duck/actions';
import {
  getRootCategoriesSel,
  selectAllAvailableCategories,
  selectAvailableCategories,
} from '../duck/selectors';

type CategoriesHookParams = {
  all?: boolean;
  root?: boolean;
};

const useCategories = ({ all, root }: CategoriesHookParams = {}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (all) {
      dispatch(getCategoriesRequest());
    } else {
      dispatch(getAvailableCategoriesRequest());
    }
  }, [dispatch, all]);

  const rootCategories = useSelector(getRootCategoriesSel);
  const allCategories = useSelector(selectAllAvailableCategories);
  const availableCategories = useSelector(selectAvailableCategories);

  if (root) {
    return rootCategories;
  }

  return all ? allCategories : availableCategories;
};

export default useCategories;
