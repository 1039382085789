import { Box, IconButton, Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExportResult } from '../types';

interface Props {
  status?: Partial<ExportResult>;
}

const ExportStatusDisplay = ({ status }: Props) => {
  const [t] = useTranslation();

  let statusMessage = t('common.processing');
  let IconComponent = HourglassEmptyIcon;

  if (!status?.createdAt) {
    statusMessage = t('common.noData');
    IconComponent = ErrorOutlineIcon;
  }

  if (status?.isDone) {
    statusMessage = t('common.done');
    IconComponent = CheckCircleOutlineIcon;
  }

  return (
    <Box clone px={0.5}>
      <Tooltip title={statusMessage}>
        <IconButton aria-label="export status info" size="small">
          <IconComponent fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ExportStatusDisplay;
