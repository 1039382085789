import { Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateExportDownloadLink } from '../duck/actions';
import type { ExportResultType } from '../types';

interface Props {
  tokenUrl?: string;
  href?: string;
  type: ExportResultType;
}

/**
 * Export download link
 * Should update link after every usage - link expires
 */
const ExportDownloadLink = ({ href, tokenUrl, type }: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  if (!(href && tokenUrl)) return null;

  const updateLinkOnClick = () => {
    dispatch(updateExportDownloadLink(tokenUrl, { type }));
  };

  return (
    <Typography component="span" variant="caption">
      <Link
        href={href}
        title={`${t('common.download')} ${t('controls.export')}`}
        target="_blank"
        rel="noopener noreferrer"
        onAuxClick={updateLinkOnClick}
        onClick={updateLinkOnClick}
      >
        {t('common.download')}
      </Link>
    </Typography>
  );
};

export default ExportDownloadLink;
